// Images Schulung
import ImageSchulung1 from "../../assets/free-at-home/Schulung/seminare.webp";
import ImageSchulung2 from "../../assets/free-at-home/Schulung/techniktalk.webp";
import ImageSchulung3 from "../../assets/welcome/2021/schulung/3.webp";
import ImageSchulung4 from "../../assets/welcome/2021/schulung/4.webp";
import ImageSchulung5 from "../../assets/welcome/2021/schulung/vertriebsmusterWelcomeMinizentrale.webp";
import ImageSchulung6 from "../../assets/welcome/2021/schulung/vertriebsmusterWelcomeVideo.webp";
import ImageSchulung7 from "../../assets/free-at-home/Schulung/community.webp";

// Images Marketing
import ImageMarketing1 from "../../assets/free-at-home/Marketing/sortimentsbroschuere.webp";
import ImageMarketing2 from "../../assets/free-at-home/Marketing/systemhandbuch.webp";
import ImageMarketing3 from "../../assets/welcome/2021/marketing/mailing.webp";
import ImageMarketing4 from "../../assets/welcome/2021/marketing/tueranhaenger.webp";

export const buschWelcomeUnterstuetzungSchulung = {
  title: "Schulung",
  items: [
    {
      title: "Seminare",
      img: ImageSchulung1,
      link: {
        href: "https://www.busch-jaeger.de/busch-jaeger/termine",
        target: "_blank",
      },
    },
    {
      title: "Technik-Talk",
      img: ImageSchulung2,
      link: {
        href: "https://www.youtube.com/playlist?list=PLW1uFXqHQgufhvVQ2v0kmm6xvIsjlF2--",
        target: "_blank",
      },
    },
    {
      title: "Busch-Jaeger Community",
      img: ImageSchulung7,
      link: {
        href: "https://community.busch-jaeger.de/",
        target: "_blank",
      },
    },
  ],
};
export const buschWelcomeUnterstuetzungMarketing = {
  title: "Marketing",
  items: [
    {
      title: "Vertriebsmuster",
      img: ImageSchulung5,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/E2431/product/4090",
        target: "_blank",
      },
    },
    {
      title: "Vertriebsmuster",
      img: ImageSchulung6,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/C1FF6/product/5241",
        target: "_blank",
      },
    },
    {
      title: "Mustertafel",
      img: ImageSchulung3,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/44FB6/product/1897",
        target: "_blank",
      },
    },
    {
      title: "Musterkoffer",
      img: ImageSchulung4,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/44FB6/product/1623",
        target: "_blank",
      },
    },
    {
      title: "Mailing",
      img: ImageMarketing3,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/E9F7B/product/1622",
        target: "_blank",
      },
    },
    {
      title: "Türanhänger",
      img: ImageMarketing4,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/21C45/product/1902",
        target: "_blank",
      },
    },
    {
      title: "Sortimentsbroschüre",
      img: ImageMarketing1,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/05D71/product/4068",
        target: "_blank",
      },
    },
    {
      title: "Systemhandbuch",
      img: ImageMarketing2,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/6EB0E/product/4012",
        target: "_blank",
      },
    },
  ],
};
