import { Grid } from "@material-ui/core";
import Slide from "../Slide";
import QHeader from "../QHeader";
import ChapterCard from "../ChapterCard";

// Images
import ImageMarketing from "../../assets/_defaultAssets/Playbook/Unterstuetzung/Marketing/marketing.webp";
import ImageSchulung from "../../assets/_defaultAssets/Playbook/Unterstuetzung/Schulung/schulung.webp";

export default function Unterstuetzung({ anchor, items, headline }) {
  const intro = (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <QHeader title={headline} subTitle="Unterstützung" />
        <Grid item>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {items.map((e, i) => {
                  if (
                    items.filter((item) => item.title === e.title).length !== 0
                  ) {
                    return (
                      <Grid item xs={6}>
                        <ChapterCard
                          smallPadding
                          chapter={{
                            title: e.title,
                            img:
                              e.title === "Schulung"
                                ? ImageSchulung
                                : ImageMarketing,
                            link: {
                              href: `#${anchor}/${i + 1}`,
                              target: "_self",
                            },
                          }}
                          cardWidth="auto"
                        />
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );

  const slideArray = items.map((e, i) => {
    return (
      <Slide key={i} background={{ width: "40%" }} actions={e.actions}>
        <Grid container direction="column" spacing={2} wrap="nowrap">
          <Grid item>
            <QHeader title={e.title} />
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              {e.items.map((ee, i) => {
                return (
                  <Grid
                    item
                    xs={e.items.length <= 6 ? 4 : e.items.length <= 8 ? 3 : 2}
                    xl={e.items.length <= 8 ? 3 : 2}
                  >
                    <ChapterCard smallPadding chapter={ee} cardWidth="auto" />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Slide>
    );
  });

  return [intro, ...slideArray];
}
