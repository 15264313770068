import { h } from "preact";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const StyledTableCell = withStyles((theme) => ({
  root: {
    borderBottom: (props) => (props.lines ? "1px solid #e0e0e0" : "none"),
    padding: "12px",
  },
  head: {
    backgroundColor: "var(--grey-20)",
    color: theme.palette.common.black,
    fontFamily: "Averta-Bold",
  },
  body: {
    fontSize: 14,
    fontFamily: (props) => props.fontFamily,
    backgroundColor: (props) => props.backgroundColor,
    padding: (props) => props.padding,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: (props) =>
        props.odd ? theme.palette.action.hover : null,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    margin: (props) => props.margin,
  },
});

export default function QTable({
  header,
  content,
  margin,
  odd,
  lines,
  stickyHeader,
  maxHeight,
}) {
  const classes = useStyles({ margin });

  const rows = content.map((data) => (
    <StyledTableRow key={data.name} odd={odd}>
      {data.values.map((entry, i) => (
        <StyledTableCell
          key={`value-${i}`}
          align={entry.align}
          colspan={entry.colspan}
          fontFamily={entry.fontFamily}
          backgroundColor={entry.backgroundColor}
          lines={lines}
          padding={data.padding}
          style={{ width: entry.width }}
        >
          {entry.label}
        </StyledTableCell>
      ))}
    </StyledTableRow>
  ));

  return (
    <TableContainer style={{ maxHeight: maxHeight }}>
      <Table
        className={classes.table}
        aria-label="tabelle"
        stickyHeader={stickyHeader}
      >
        {typeof header === "undefined" ? null : (
          <TableHead>
            <TableRow>
              {header.map((head, i) => (
                <StyledTableCell
                  key={`headerRender-${i}`}
                  align={head.align}
                  colspan={head.colspan}
                  lines={lines}
                >
                  {head.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
}

QTable.defaultProps = {
  margin: 0,
  odd: true,
  lines: true,
  stickyHeader: false,
  maxHeight: "inherit",
};
