import { Box, Grid } from "@material-ui/core";

import Slide from "../../../../../../../components/Slide";
import QBodyText from "../../../../../../../components/QBodyText";
import QHeader from "../../../../../../../components/QHeader";
import { useModal } from "mui-modal-provider";
import QDialog from "../../../../../../../components/QDialog";

import ApplicationController from "../_GlobalSlides/ApplicationController";
import QSwiperJS from "../../../../../../../components/QSwiperJS";

import KellerHeizkreisregelung from "./KellerHeizkreisregelung";
import Anwendungsbeschreibung from "./rtr-und-stellenantrieb/Anwendungsbeschreibung";
import Einzelraumtemperaturregelung from "./rtr-und-stellenantrieb/Einzelraumtemperaturregelung";
import HeizKuehlkreisController from "./KellerHeizkreisregelung/HeizUndKuehlkreisController";
import WaermeKaelteErzeugung from "./KellerHeizkreisregelung/WaermeKaelteErzeuger";
import Startpage from "../_GlobalSlides/ApplicationController/Startpage";
import Intro from "../../../ClimaEcoGesamtuebersicht";

// Images
import WasIstClimaECO from "../../../../../../../assets/abbknx/Anwendungen/ClimaEco/was-ist-climaeco.webp"
import Musterplanung from "./Musterplanung";

export default function ClimaECO() {
  const { showModal } = useModal();
  const dialogEinzelraumregelung = () => {
    const d = showModal(QDialog, {
      children: (
        <QSwiperJS>
          <Anwendungsbeschreibung />
          <Einzelraumtemperaturregelung />
        </QSwiperJS>
      ),
      onConfirm: () => {
        d.hide();
      },
      onCancel: () => {
        d.hide();
      },
    });
  };
  const dialogAnzeigenUndBedienen = () => {
    const d = showModal(QDialog, {
      children: <ApplicationController />,
      onConfirm: () => {
        d.hide();
      },
      onCancel: () => {
        d.hide();
      },
    });
  };
  const dialogHeizkreisregelung = () => {
    const d = showModal(QDialog, {
      children: (
        <QSwiperJS>
          <KellerHeizkreisregelung />
          <Startpage openInDialog />
          <HeizKuehlkreisController />
          <WaermeKaelteErzeugung />
        </QSwiperJS>
      ),
      onConfirm: () => {
        d.hide();
      },
      onCancel: () => {
        d.hide();
      },
    });
  };

  const dialogGesamtloesung = () => {
    const dialog = showModal(QDialog, {
      children: <Intro />,
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };

  const dialogMusterplanung = () => {
    const dialog = showModal(QDialog, {
      children: <Musterplanung />,
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };

  return (
    <Slide
      background={{
        alignment: "left",
      }}
      actions={[
        {
          type: "button",
          label: "Gesamtlösung",
          onClick: dialogGesamtloesung,
        },
        {
          type: "button",
          label: "Musterplanung",
          onClick: dialogMusterplanung,
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="ClimaECO" />
        </Grid>
        <Grid item xs={8}>
          <QBodyText marginBottom="8px" fontWeight="bold">
            Was ist ClimaECO?
          </QBodyText>
          <QBodyText marginBottom="0px">
            Dazu gehören neben der Einzelraum-Temperaturreglung die Komponenten
            für die gesamte Heizungssteuerung, inkl. der Messwerterfassung,
            Regelung und Ansteuerung von Mischer und Pumpen auf KNX Basis.
          </QBodyText>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              transform: "scale(1)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg style={{height: "54vh", width: "100%"}} viewBox="0 0 783 400" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <g id="was-ist-climaeco-hotspot" clip-path="url(#clip0_220_259)">
                <rect id="was-ist-climaeco 1" width="783" height="400" fill="url(#was-ist-climaeco-pattern)"/>
                <rect className="pointer" onClick={() => dialogEinzelraumregelung()} id="reinzelraumregelung" x="29" y="19" width="231" height="361" rx="10" fill="black" fill-opacity="0"/>
                <rect className="pointer" onClick={() => dialogAnzeigenUndBedienen()} id="automatisierungscontroller" x="275" y="19" width="231" height="361" rx="10" fill="black" fill-opacity="0"/>
                <rect className="pointer" onClick={() => dialogHeizkreisregelung()} id="kellerheizkreisregelung" x="521" y="19" width="231" height="361" rx="10" fill="black" fill-opacity="0"/>
              </g>
              <defs>
              <pattern id="was-ist-climaeco-pattern" patternContentUnits="objectBoundingBox" width="1" height="1">
                <use xlinkHref="#image0_220_259" transform="scale(0.000425894 0.000833688)"/>
              </pattern>
              <clipPath id="clip0_220_259">
                <rect width="782.609" height="400" fill="white"/>
              </clipPath>
                <image id="image0_220_259" data-name="was-ist-climaeco.png" width="2348" height="1200" xlinkHref={WasIstClimaECO}/>
              </defs>
            </svg>
          </div>
        </Grid>
      </Grid>
    </Slide>
  );
}
