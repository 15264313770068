import { Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";

// Images
import backgroundIMG from "../../../../../../assets/_backgrounds/neuheiten-2024/12.webp";

function ABBAbility() {
  return (
    <Slide
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://new.abb.com/ability-building-solutions/de/building-analyzer",
          target: "_blank",
        },
      ]}
      background={{
        src: backgroundIMG,
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="ABB Abillity™ Building Analyzer" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                title="Eigenschaften"
                headline="Eigenschaften"
                items={[
                  "Cloudbasiertes Energie & Gebäude Monitoring System",
                  "Grafische Darstellung der Energieverbrauchswerte und Gebäudeparameter",
                  "Automatische Datenanalyse und Reporting",
                  "Aufschalten von Wetterdaten wie z.B. Jahresvergleich witterungsbereinigt",
                  "Übersichtlich durch individuelle Menüstruktur: z.B. Strom-, Gas-, und Wasserverbräuche, Wärmemengen Gebäude und Etagen, Gebäudebelegung, Betriebsstunden",
                  "BAFA gefördert",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default ABBAbility;
