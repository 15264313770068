// Images Schulung
import ImageSchulung1 from "../../assets/beruehrungsloses-schalten/Schulung-Marketing/1.webp";
import ImageSchulung2 from "../../assets/beruehrungsloses-schalten/Schulung-Marketing/bemusterungskatalog.webp";
import ImageSchulung3 from "../../assets/beruehrungsloses-schalten/Schulung-Marketing/5.webp";
import ImageSchulung4 from "../../assets/beruehrungsloses-schalten/Schulung/6.webp";
import ImageSchulung5 from "../../assets/beruehrungsloses-schalten/Schulung/7.webp";

// Images Marketing
import ImageMarketing1 from "../../assets/beruehrungsloses-schalten/Schulung-Marketing/4.webp";
import ImageMarketing2 from "../../assets/beruehrungsloses-schalten/Schulung-Marketing/9.webp";
import ImageMarketing3 from "../../assets/beruehrungsloses-schalten/Schulung-Marketing/10.webp";
import ImageMarketing4 from "../../assets/beruehrungsloses-schalten/Schulung-Marketing/11.webp";
import ImageMarketing5 from "../../assets/beruehrungsloses-schalten/Schulung-Marketing/12.webp";
import ImageMarketing6 from "../../assets/beruehrungsloses-schalten/Schulung-Marketing/5.webp";

export const beruehrungslosesschaltenSchulungItems = {
  title: "Schulung",
  items: [
    {
      title: "Busch-Präsenzmelder-App",
      img: ImageSchulung1,
      link: {
        href: "https://www.busch-jaeger.de/mediathek?tx_solr%5Bfilter%5D%5B0%5D=media_type%3Aapps",
        target: "_blank",
      },
    },
    {
      title: "Bemusterungskatalog",
      img: ImageSchulung2,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/21D5D/product/1901",
        target: "_blank",
      },
    },
    {
      title: "Florpost",
      img: ImageSchulung3,
      link: {
        href: "https://busch-jaeger-excellence.de/45/req-ns/8BA1E/product/1744",
        target: "_blank",
      },
    },
    {
      title: "Podcast Blindleistung",
      img: ImageSchulung4,
      link: {
        href: "https://stromkompass.de/blindleistung/bewegungsmelder",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          ElektroSpicker 26
          <br />
          Bewegungsmelder
        </>
      ),
      img: ImageSchulung5,
      link: {
        href: "https://search.abb.com/library/Download.aspx?DocumentID=9AKK107992A7794&LanguageCode=de&DocumentPartId=&Action=Launch",
        target: "_blank",
      },
    },
  ],
};

export const beruehrungslosesschaltenMarketingItems = {
  title: "Marketing",
  items: [
    {
      title: "Muster KNX-Präsenzmelder",
      img: ImageMarketing1,
      link: {
        href: "https://busch-jaeger-excellence.de/45/req-ns/8959A/product/2576",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          Tischaufsteller
          <br />
          (Flyer (inkl.)/Visitenkarten)
        </>
      ),
      img: ImageMarketing2,
      link: {
        href: "https://busch-jaeger-excellence.de/45/req-ns/5DC2C/product/1771",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          Broschüre
          <br />
          Bewegungsmelder
        </>
      ),
      img: ImageMarketing3,
      link: {
        href: "https://busch-jaeger-excellence.de/45/req-ns/5DC2C/product/5276",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          Broschüre
          <br />
          Busch-Präsenzmelder
        </>
      ),
      img: ImageMarketing4,
      link: {
        href: "https://busch-jaeger-excellence.de/45/req-ns/42A47/product/4091",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          Flyer
          <br />
          Sicherheit mit Komfort
        </>
      ),
      img: ImageMarketing5,
      link: {
        href: "https://www.busch-jaeger.de/mediathek?tx_solr%5Bfilter%5D%5B0%5D=media_type%3Akataloge-broschueren",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          Anwendungshandbuch
          <br />
          Bewegungsmelder
        </>
      ),
      img: ImageMarketing6,
      link: {
        href: "https://www.flipedia.com/de/5075",
        target: "_blank",
      },
    },
  ],
};
