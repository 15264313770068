import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../components/QHeader";
import QGalery from "../../../../../../../../components/QGalery";
import QDialog from "../../../../../../../../components/QDialog";
import { useModal } from "mui-modal-provider";
import TemperaturfuehlerImage from "../../../../../../../../assets/abbknx/Anwendungen/ClimaEco/heizkreisregelung/heiz-kuehlkreis-controller/temperaturfuehler.webp";
import TemperaturfuehlerImage2 from "../../../../../../../../assets/abbknx/Anwendungen/ClimaEco/heizkreisregelung/heiz-kuehlkreis-controller/temperaturfuehler-2.webp";

import BCI from "./files/BCI.webp";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import DetailansichtPumpe from "./DetailansichtPumpe/index";
import { useState } from "preact/hooks";
import clsx from "clsx";

import WaermeerzeugerBuderus from "../../../../../../../../assets/abbknx/Anwendungen/ClimaEco/heizkreisregelung/buderus-waermeerzeuger.webp";

export default function WaermeKaelteErzeugung({ swiperRef }) {
  const { showModal } = useModal();
  const [activeLine, setActiveLine] = useState("")
  const id = "waerme-kaelte-erzeuger-svg";

  const dialogTemperaturfuehler = () => {
    const dialog = showModal(QDialog, {
      children: (
        <QGalery
          loop={true}
          images={[
            {
              src: TemperaturfuehlerImage,
              alt: "Temperaturfühler",
            },
            {
              src: TemperaturfuehlerImage2,
              alt: "Heizungsanlage",
            },
          ]}
          closeHandler={() => dialog.hide()}
        />
      ),
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };
  const dialogPumpe = () => {
    const dialog = showModal(QDialog, {
      children: (
        <TransformWrapper>
          <TransformComponent>
            <Box
              width={"calc(100dvw - 2rem)"}
              height={"calc(100dvh - 64px - 2rem)"}
            >
              <DetailansichtPumpe />
            </Box>
          </TransformComponent>
        </TransformWrapper>
      ),
      alt: "Detailansicht Pumpe",
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };
  const dialogWaermeerzeuger = () => {
    const dialog = showModal(QDialog, {
      children: (
        <QGalery
          loop={true}
          images={[
            {
              src: WaermeerzeugerBuderus,
              alt: "Buderus Wärmeerzeuger",
            },
          ]}
          closeHandler={() => dialog.hide()}
        />
      ),
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };

  const handleLineChange = (e) => {
    const id = e.target.id;
    setActiveLine(activeLine === id ? "" : id);
  };

  return (
    <Slide
      actions={[
        {
          type: "icon",
          img: "laptop",
          target: "_blank",
          link: "https://new.abb.com/products/de/2CDG110222R0011/bci-s1-1-1",
        },
      ]}
    >
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title="Wärme-/Kälteerzeuger Interface"
            subTitle="BCI/S 1.1.1"
          />
        </Grid>
        <Grid item xs={12}>
          <svg
            style={{ height: "75dvh", width: "100%" }}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            id={id}
            viewBox="0 0 661.53 295.65"
          >
            <defs>
              <style>
                {`
                #${id} g, #${id} text, #${id} image, #${id} polyline, #${id} path, #${id} rect { pointer-events: none; }
                #${id} rect.hotspot, #${id} rect.click-me { pointer-events: all; }
                #${id} .cls-34x { fill: black; }
                #${id} .active-stroke { stroke-width: 3px; }
                #${id} text { font-weight: initial !important; font-family: Averta-Regular !important; } #${id} use { pointer-events: none; }
                #${id} .cls-1{letter-spacing:-.02em;}#${id} .cls-1,#${id} .cls-2,#${id} .cls-3,#${id} .cls-4,#${id} .cls-5,#${id} .cls-6,#${id} .cls-7,#${id} .cls-8,#${id} .cls-9,#${id} .cls-10,#${id} .cls-11,#${id} .cls-12,#${id} .cls-13,#${id} .cls-14,#${id} .cls-15{font-family:Averta-Regular, 'Averta-Regular';}#${id} .cls-25,#${id} .cls-7,#${id} .cls-26{fill:#696969;}#${id} .cls-27,#${id} .cls-28,#${id} .cls-29,#${id} .cls-30{fill:#fff;}#${id} .cls-31{fill:#a55503;}#${id} .cls-32{fill:#1b7ac1;}#${id} .cls-7,#${id} .cls-13{font-size:4px;}#${id} .cls-28{filter:url(#drop-shadow-1);}#${id} .cls-28,#${id} .cls-29,#${id} .cls-30{stroke:#000;}#${id} .cls-28,#${id} .cls-29,#${id} .cls-30,#${id} .cls-33,#${id} .cls-34,#${id} .cls-35{stroke-linecap:round;}#${id} .cls-28,#${id} .cls-29,#${id} .cls-30,#${id} .cls-33,#${id} .cls-34,#${id} .cls-35,#${id} .cls-26{stroke-miterlimit:10;}#${id} .cls-29{filter:url(#drop-shadow-2);}#${id} .cls-30{filter:url(#drop-shadow-3);}#${id} .cls-36{letter-spacing:-.02em;}#${id} .cls-19{font-size:12px;}#${id} .cls-22{font-size:14px;}#${id} .cls-33{stroke:#a55503;}#${id} .cls-33,#${id} .cls-34,#${id} .cls-35{fill:none;}#${id} .cls-34{stroke:#1b7ac1;}#${id} .cls-35,#${id} .cls-26{stroke:#000;}#${id} .cls-9{letter-spacing:-.02em;}#${id} .cls-10{letter-spacing:0em;}#${id} .cls-12{letter-spacing:-.02em;}#${id} .cls-24{letter-spacing:0em;}#${id} .cls-39{font-size:10px;}#${id} .cls-14{letter-spacing:-.07em;}#${id} .cls-15{letter-spacing:-.02em;}`}
              </style>
              <filter id="drop-shadow-1" filterUnits="userSpaceOnUse">
                <feOffset dx="0" dy="2" />
                <feGaussianBlur result="blur" stdDeviation="3" />
                <feFlood flood-color="#000" flood-opacity=".45" />
                <feComposite in2="blur" operator="in" />
                <feComposite in="SourceGraphic" />
              </filter>
              <filter id="drop-shadow-2" filterUnits="userSpaceOnUse">
                <feOffset dx="0" dy="2" />
                <feGaussianBlur result="blur-2" stdDeviation="3" />
                <feFlood flood-color="#000" flood-opacity=".45" />
                <feComposite in2="blur-2" operator="in" />
                <feComposite in="SourceGraphic" />
              </filter>
              <filter id="drop-shadow-3" filterUnits="userSpaceOnUse">
                <feOffset dx="0" dy="2" />
                <feGaussianBlur result="blur-3" stdDeviation="3" />
                <feFlood flood-color="#000" flood-opacity=".45" />
                <feComposite in2="blur-3" operator="in" />
                <feComposite in="SourceGraphic" />
              </filter>
            </defs>
            <rect
              className="cls-25"
              x="597.51"
              y="113.8"
              width="64"
              height="16.09"
            />
            <polygon
              className="cls-25"
              points="661.53 163.98 632.53 164.03 632.52 149.93 661.52 149.88 661.53 163.98"
            />
            <rect
              className="cls-25"
              x="621.39"
              y="31.96"
              width="3"
              height="49.49"
            />
            <rect
              className="cls-25"
              x="642.52"
              y="31.95"
              width="3"
              height="117.56"
            />
            <rect
              className="cls-25"
              x="621.4"
              y="95.84"
              width="3"
              height="17.67"
            />
            <circle className="cls-27" cx="633.39" cy="26.37" r="11.5" />
            <path
              className="cls-27"
              d="m633.38,10.87c-8.55,0-15.5,6.96-15.49,15.51,0,8.54,6.96,15.5,15.51,15.49s15.5-6.97,15.49-15.51-6.97-15.5-15.51-15.49Zm0,28c-6.9,0-12.5-5.61-12.5-12.5,0-6.89,5.6-12.5,12.5-12.5s12.5,5.61,12.5,12.5c0,6.89-5.61,12.5-12.5,12.5Z"
            />
            <path
              className="cls-25"
              d="m633.38,9.87c-9.1,0-16.5,7.41-16.49,16.51,0,9.1,7.41,16.5,16.51,16.49s16.5-7.41,16.49-16.51-7.42-16.5-16.51-16.49Zm.01,32c-8.55,0-15.5-6.95-15.51-15.49,0-8.55,6.94-15.5,15.49-15.51s15.5,6.94,15.51,15.49-6.95,15.5-15.49,15.51Z"
            />
            <circle className="cls-27" cx="633.39" cy="26.37" r="11.5" />
            <path
              className="cls-25"
              d="m633.39,13.87c-6.9,0-12.5,5.61-12.5,12.5,0,6.89,5.6,12.5,12.5,12.5s12.5-5.61,12.5-12.5c0-6.89-5.61-12.5-12.5-12.5Zm0,24c-6.34,0-11.5-5.16-11.5-11.5s5.16-11.5,11.5-11.5,11.5,5.16,11.5,11.5-5.16,11.5-11.5,11.5Z"
            />
            <rect
              className="cls-25"
              x="619.41"
              y="111.84"
              width="7"
              height="2"
            />
            <rect
              className="cls-25"
              x="640.6"
              y="148.02"
              width="7"
              height="2"
            />
            <rect
              className="cls-25"
              x="640.52"
              y="96.02"
              width="7"
              height="2"
            />
            <rect
              className="cls-25"
              x="640.52"
              y="79.02"
              width="7"
              height="2"
            />
            <rect className="cls-25" x="619.4" y="94.84" width="7" height="2" />
            <rect
              className="cls-25"
              x="619.39"
              y="80.84"
              width="7"
              height="2"
            />
            <path
              className="cls-25"
              d="m617.69,53.96c-.24-1.77-1.76-3.14-3.6-3.14-2,0-3.64,1.63-3.64,3.64s1.64,3.64,3.64,3.64c1.84,0,3.36-1.37,3.6-3.14h7.58s0-1,0-1h-7.58Zm-3.6,3.14c-1.45,0-2.64-1.19-2.64-2.64s1.19-2.64,2.64-2.64,2.64,1.18,2.64,2.64-1.18,2.64-2.64,2.64Z"
            />
            <path
              className="cls-25"
              d="m638.69,53.95c-.24-1.77-1.76-3.14-3.6-3.14-2,0-3.64,1.63-3.64,3.64s1.64,3.64,3.64,3.64c1.84,0,3.36-1.37,3.6-3.14h7.58s0-1,0-1h-7.58Zm-3.6,3.14c-1.45,0-2.64-1.19-2.64-2.64s1.19-2.64,2.64-2.64,2.64,1.18,2.64,2.64-1.18,2.64-2.64,2.64Z"
            />
            <path
              className="cls-25"
              d="m617.75,88.52c-.24-1.77-1.76-3.14-3.6-3.14-2,0-3.64,1.63-3.64,3.64s1.64,3.64,3.64,3.64c1.84,0,3.36-1.37,3.6-3.14h6.58s0-1,0-1h-6.58Zm-3.6,3.14c-1.45,0-2.64-1.19-2.64-2.64s1.19-2.64,2.64-2.64,2.64,1.18,2.64,2.64-1.18,2.64-2.64,2.64Z"
            />
            <rect
              className="cls-25"
              x="630.9"
              y="87.27"
              width="13.56"
              height="3"
            />
            <rect
              className="cls-25"
              x="627.07"
              y="87.79"
              width="7"
              height="2"
              transform="translate(719.14 -541.81) rotate(89.98)"
            />
            <rect
              className="cls-25"
              x="633.07"
              y="87.79"
              width="7"
              height="2"
              transform="translate(725.13 -547.81) rotate(89.98)"
            />
            <polygon
              className="cls-27"
              points="625.93 82.78 622.95 87.88 619.97 82.78 625.93 82.78"
            />
            <polygon
              className="cls-27"
              points="625.93 94.97 619.97 94.97 622.95 89.87 625.93 94.97"
            />
            <polygon
              className="cls-27"
              points="629.04 85.9 629.04 91.85 623.94 88.88 629.04 85.9"
            />
            <path
              className="cls-27"
              d="m623.94,88.88l5.1,2.97v-5.95s-5.1,2.98-5.1,2.98Zm0,0l5.1,2.97v-5.95s-5.1,2.98-5.1,2.98Z"
            />
            <path
              className="cls-25"
              d="m630.04,84.16l-5.7,3.33,3.33-5.71h-9.44s4.04,6.92,4.04,6.92l.1.18-.11.18-4.03,6.91h9.44s-3.32-5.7-3.32-5.7l5.69,3.32v-9.43Zm-10.07-1.38h5.96s-2.98,5.1-2.98,5.1l-2.98-5.1Zm5.96,12.19h-5.96s2.98-5.1,2.98-5.1l2.98,5.1Zm3.11-3.12l-5.1-2.97,5.1-2.98v5.95Z"
            />
            <path
              className="cls-25"
              d="m626.4,65.28v-1.63s-7,0-7,0v1.63c-1.51,1.09-2.5,2.86-2.5,4.87s.99,3.78,2.5,4.87v1.63s7,0,7,0v-1.63c1.51-1.09,2.5-2.86,2.5-4.87s-.99-3.78-2.5-4.87Zm.86,7.3c-.49.9-1.25,1.62-2.18,2.07h-.06c-.65.31-1.37.48-2.13.48s-1.48-.17-2.13-.48h-.03s-.09-.04-.13-.08c-.11-.04-.22-.1-.32-.18-.2-.12-.4-.26-.58-.42-.09-.06-.17-.13-.24-.21-.12-.1-.22-.21-.31-.32-.02-.02-.04-.04-.06-.07-.15-.16-.28-.34-.39-.52-.13-.19-.25-.4-.34-.62-.05-.11-.1-.21-.14-.32-.12-.31-.2-.64-.26-.97-.03-.17-.05-.34-.05-.51-.01-.05-.01-.1-.01-.15v-.13c0-.12,0-.23,0-.35-.01-.01-.01-.02,0-.03,0-.12.03-.23.04-.35.05-.38.15-.75.3-1.1.24-.64.61-1.21,1.08-1.68.16-.18.34-.34.53-.47.09-.08.19-.15.29-.21.1-.07.2-.13.3-.18.6-.34,1.27-.56,1.99-.63.15-.01.3-.02.45-.02.2,0,.39,0,.58.04.58.06,1.12.22,1.61.48.06.03.11.06.16.09.24.11.48.27.69.44.1.08.2.16.29.24.09.07.17.15.25.23s.16.17.24.26c.13.15.26.32.37.5.21.32.39.66.52,1.03.03.09.07.19.09.29.04.13.07.26.1.39.07.33.11.68.11,1.03,0,.51-.08,1.01-.23,1.47-.1.34-.24.66-.41.96Z"
            />
            <path
              className="cls-27"
              d="m627.9,70.13c0,.52-.08,1.02-.22,1.49-.1.34-.24.66-.41.96-.51.9-1.29,1.63-2.24,2.07-.65.31-1.37.48-2.13.48s-1.48-.17-2.13-.48c-.06-.02-.11-.05-.16-.08-.11-.04-.22-.1-.32-.18-.2-.12-.4-.26-.58-.42-.09-.06-.17-.13-.24-.21-.12-.1-.22-.21-.31-.32-.02-.02-.04-.04-.06-.07-.15-.16-.28-.34-.39-.52-.13-.19-.25-.4-.34-.62-.05-.11-.1-.21-.14-.32-.12-.31-.2-.64-.26-.97-.03-.17-.05-.34-.05-.51-.01-.05-.01-.1-.01-.15v-.13c0-.12,0-.23,0-.35-.01-.01-.01-.02,0-.03,0-.12.03-.23.04-.35.04-.24.1-.48.17-.71.04-.14.08-.26.13-.39.03-.08.07-.15.1-.22.24-.54.57-1.04.98-1.46.17-.17.34-.33.53-.47.09-.07.19-.14.29-.21.1-.06.2-.13.3-.18.6-.34,1.27-.56,1.99-.63.15-.01.3-.02.45-.02.2,0,.39,0,.58.04.56.07,1.1.24,1.59.48h.02c.06.03.11.06.16.09.13.07.26.14.38.23.11.07.21.13.31.21.1.08.2.16.29.24.09.07.17.15.25.23s.16.17.24.26c.13.15.26.32.37.5.21.32.39.66.52,1.03.03.09.07.19.09.29.04.13.07.26.1.39.06.33.1.66.1,1.01Z"
            />
            <polygon
              className="cls-25"
              points="617.69 70.85 617.04 70.09 622.92 64.97 628.77 70.09 628.11 70.84 622.92 66.29 617.69 70.85"
            />
            <path
              className="cls-25"
              d="m579.27,163.23l-87.88.03v-6s87.88-.03,87.88-.03c2.76,0,5-2.24,5-5v-22.67c-.01-6.07,4.92-11,10.99-11h2.79s0,6,0,6h-2.79c-2.76,0-5,2.25-5,5v22.67c.01,6.07-4.92,11-10.99,11Z"
            />
            <rect
              className="cls-25"
              x="571.79"
              y="159.2"
              width="9"
              height="2"
              transform="translate(736.3 -416.14) rotate(89.98)"
            />
            <rect
              className="cls-25"
              x="582.67"
              y="148.88"
              width="9"
              height="2"
              transform="translate(1174.4 299.55) rotate(179.98)"
            />
            <rect
              className="cls-25"
              x="582.67"
              y="130.88"
              width="9"
              height="2"
              transform="translate(1174.38 263.55) rotate(179.98)"
            />
            <rect
              className="cls-25"
              x="592.43"
              y="120.55"
              width="9"
              height="2"
              transform="translate(718.27 -475.43) rotate(89.98)"
            />
            <path
              className="cls-25"
              d="m612.56,198.3l-120.88.04v-6s120.88-.04,120.88-.04c2.76,0,5-2.24,5-5v-22.67c-.01-6.07,4.92-11,10.99-11h3.79s0,6,0,6h-3.79c-2.76,0-5,2.25-5,5v22.67c.01,6.07-4.92,11-10.99,11Z"
            />
            <rect
              className="cls-25"
              x="604.81"
              y="194.26"
              width="9"
              height="2"
              transform="translate(804.36 -414.11) rotate(89.98)"
            />
            <rect
              className="cls-25"
              x="616.08"
              y="184.38"
              width="9"
              height="2"
              transform="translate(1241.23 370.53) rotate(179.98)"
            />
            <rect
              className="cls-25"
              x="616.07"
              y="166.38"
              width="9"
              height="2"
              transform="translate(1241.21 334.53) rotate(179.98)"
            />
            <rect
              className="cls-25"
              x="627.33"
              y="155.6"
              width="9"
              height="2"
              transform="translate(788.21 -475.28) rotate(89.98)"
            />
            <text
              className="cls-7"
              transform="translate(612.99 55.9) rotate(-.02)"
            >
              <tspan x="0" y="0">
                T
              </tspan>
            </text>
            <text
              className="cls-7"
              transform="translate(633.81 55.9) rotate(-.02)"
            >
              <tspan x="0" y="0">
                T
              </tspan>
            </text>
            <text
              className="cls-7"
              transform="translate(612.4 90.63) rotate(-.02)"
            >
              <tspan x="0" y="0">
                M
              </tspan>
            </text>
            <rect id="rec-1" x="448" y="112" width="50" height="142" rx="5" fill="white" stroke="#B8B8B8" stroke-width="1"/>
            <rect id="rec-2" x="504" y="112" width="18" height="142" rx="5" fill="white" stroke="#B8B8B8" stroke-width="1"/>
            <rect id="rec-3" x="528" y="112" width="22" height="142" rx="5" fill="white" stroke="#B8B8B8" stroke-width="1"/>
            <path d="m515.62,150.54c0-2-1.63-3.64-3.64-3.64s-3.64,1.64-3.64,3.64c0,1.84,1.37,3.36,3.14,3.6v9.11s.01,1.47.01,1.47h1v-7.47s-.01-3.11-.01-3.11c1.77-.24,3.14-1.76,3.14-3.6Zm-3.64,2.64c-1.45,0-2.64-1.18-2.64-2.64s1.19-2.64,2.64-2.64,2.64,1.19,2.64,2.64-1.18,2.64-2.64,2.64Z" />
            <path d="m511.99,182.9c-2,0-3.64,1.64-3.63,3.64,0,1.84,1.37,3.36,3.14,3.6v10.58h1v-10.58c1.77-.24,3.14-1.76,3.14-3.6-.01-2-1.64-3.64-3.65-3.64Zm.01,6.28c-1.45,0-2.64-1.18-2.64-2.64s1.18-2.64,2.63-2.64,2.65,1.19,2.65,2.64-1.18,2.64-2.64,2.64Z" />
            <polygon points="559.46 157.24 559.46 163.24 535.39 163.24 532.66 163.25 511.49 163.25 493.29 163.26 491.38 163.26 491.38 157.26 493.29 157.26 511.48 157.25 534.99 157.25 540.63 157.24 559.46 157.24" />
            <rect
              x="487.79"
              y="159.23"
              width="9"
              height="2"
              transform="translate(652.36 -332.12) rotate(89.98)"
            />
            <polygon points="512.5 192.33 559.46 192.32 559.46 198.32 512.5 198.33 511.5 198.33 493.3 198.34 491.67 198.34 491.67 192.34 493.3 192.34 511.5 192.33 512.5 192.33" />
            <rect
              x="487.81"
              y="194.3"
              width="9"
              height="2"
              transform="translate(687.44 -297.07) rotate(89.98)"
            />
            <rect
              className="cls-26"
              x="453.07"
              y="120.42"
              width="38.7"
              height="126.3"
            />
            <text
              className="cls-13"
              transform="translate(510.92 152.1) rotate(-.02)"
            >
              <tspan x="0" y="0">
                T
              </tspan>
            </text>
            <text
              className="cls-13"
              transform="translate(510.93 188.1) rotate(-.02)"
            >
              <tspan x="0" y="0">
                T
              </tspan>
            </text>
            <path d="m544.03,156.47c-1.09-1.51-2.86-2.5-4.87-2.5s-3.78.99-4.87,2.5h-1.63v7h1.63c1.09,1.51,2.86,2.5,4.87,2.5s3.78-.99,4.87-2.5h1.63v-7h-1.63Zm-.5,1.05c.34.6.56,1.27.63,1.99.01.1.01.19.01.29.01.05.01.11.01.16,0,.08,0,.15-.01.22,0,.12-.01.24-.03.36-.06.58-.22,1.12-.48,1.61-.03.06-.06.11-.09.16-.04.06-.07.13-.11.19-.05.12-.13.23-.21.34-.04.05-.08.11-.12.16-.07.08-.13.17-.2.24-.01.02-.03.03-.04.05-.07.09-.15.17-.23.25-.08.08-.17.16-.26.24-.15.13-.32.26-.5.37-.32.21-.66.39-1.03.52-.04.02-.09.04-.14.05s-.1.03-.15.04c-.13.04-.26.07-.39.1-.04.01-.08.02-.13.02-.12.03-.24.05-.37.06-.16.02-.34.03-.51.03s-.34-.01-.5-.02c-.09-.01-.18-.02-.26-.04-.16-.02-.31-.05-.45-.09-.09-.02-.19-.05-.28-.08-.34-.1-.66-.24-.96-.41-.22-.12-.44-.26-.64-.42-.21-.16-.4-.33-.57-.52-.06-.05-.1-.1-.13-.15-.3-.33-.54-.69-.73-1.09v-.05c-.31-.65-.48-1.37-.48-2.13s.17-1.48.48-2.13v-.04s.04-.08.08-.12c.04-.11.1-.22.18-.32.02-.04.04-.07.07-.11.1-.16.22-.32.35-.47.06-.09.13-.17.21-.24.05-.06.1-.11.16-.17.04-.05.1-.1.16-.15.02-.02.04-.04.07-.06.16-.15.34-.28.52-.39.09-.06.19-.13.3-.18.1-.06.21-.12.32-.16.11-.05.21-.1.32-.14.31-.12.64-.2.97-.26.1-.02.19-.03.29-.04.07-.01.15-.01.22-.01.05-.01.1-.01.15-.01h.13c.12,0,.23,0,.35.01,0-.01.01-.01.02,0,0,0,0-.01.01,0,.12.01.23.03.35.04.05.01.1.01.15.03.25.03.5.1.73.19.07.03.15.05.22.08.08.03.15.07.22.1.28.11.54.25.78.43.12.07.24.16.35.26.11.09.23.19.33.29.17.17.33.34.47.53.07.09.14.19.21.29,0,.01.01.01.01.02.06.09.12.19.17.28Z" />
            <path
              className="cls-27"
              d="m539.18,164.97c-.52,0-1.02-.08-1.49-.22-.34-.1-.66-.24-.96-.41-.9-.51-1.63-1.29-2.07-2.24-.31-.65-.48-1.37-.48-2.13s.17-1.48.48-2.13c.02-.06.05-.11.08-.16.04-.11.1-.22.18-.32.12-.2.26-.4.42-.58.06-.09.13-.17.21-.24.1-.12.21-.22.32-.31.02-.02.04-.04.07-.06.16-.15.34-.28.52-.39.19-.13.4-.25.62-.34.11-.05.21-.1.32-.14.31-.12.64-.2.97-.26.17-.03.34-.05.51-.05.05-.01.1-.01.15-.01h.13c.12,0,.23,0,.35,0,.01-.01.02-.01.03,0,.12,0,.23.03.35.04.24.04.48.1.71.17.14.04.26.08.39.13.08.03.15.07.22.1.54.24,1.04.57,1.46.98.17.17.33.34.47.53.07.09.14.19.21.29.06.1.13.2.18.3.34.6.56,1.27.63,1.99.01.15.02.3.02.45,0,.2,0,.39-.04.58-.07.56-.24,1.1-.48,1.59v.02c-.03.06-.06.11-.09.16-.07.13-.14.26-.23.38-.07.11-.13.21-.21.31-.08.1-.16.2-.24.29-.07.09-.15.17-.23.25s-.17.16-.26.24c-.15.13-.32.26-.5.37-.32.21-.66.39-1.03.52-.09.03-.19.07-.29.09-.13.04-.26.07-.39.1-.33.06-.66.1-1.01.1Z"
            />
            <polygon points="538.47 154.76 539.22 154.11 544.35 159.99 539.22 165.84 538.47 165.18 543.02 159.99 538.47 154.76" />
            <text
              fill="white"
              className="cls-19"
              transform="translate(476.57 228.39) rotate(-90)"
            >
              <tspan className="cls-38" x="0" y="0">
                Wärmeerzeuger
              </tspan>
            </text>
            <image
              id="BCI_S_1.1.1"
              width="589"
              height="497"
              transform="translate(186.96 88.11) scale(.38)"
              xlinkHref={BCI}
            />
            <polyline
              className={activeLine === "hotspot-vorlauftemperatur" ? clsx("cls-34", "active-stroke") : "cls-34"}
              points="512.01 163.22 512.01 174.01 506.07 174.04 506.07 263.11 428.05 263.11 428.05 61.85 362.74 61.85 362.74 103.55"
            />
            <polyline
              className={activeLine === "hotspot-ruecklauftemparatur" ? clsx("cls-34", "active-stroke") : "cls-34"}
              points="512.02 198.4 512.02 271.39 419.95 271.32 419.95 69.6 388.58 69.6 388.58 102.22"
            />
            <polyline
              className={activeLine === "hotspot-ruecklauftemparatur" ? clsx("cls-34", "active-stroke") : "cls-34"}
              points="388.58 69.6 375.12 69.6 375.12 103.9"
            />
            <polyline
              className={activeLine === "hotspot-vorlauftemperatur" ? clsx("cls-34", "active-stroke") : "cls-34"}
              points="362.74 61.85 349.26 61.85 349.26 102.41"
            />
            <polyline
              className={activeLine === "hotspot-sollwert-waermeerzeuger" ? clsx("cls-34", "active-stroke") : "cls-34"}
              points="461.53 120.41 461.53 51.81 337.12 51.81 337.12 102.88"
            />
            <polyline
              className={activeLine === "hotspot-freigabe-waermeerzeuger" ? clsx("cls-34", "active-stroke") : "cls-34"}
              points="479.12 120.41 479.12 41.76 247.37 41.76 247.37 103.71"
            />
            <polyline
              className={activeLine === "hotspot-sollwert-waermeerzeuger" ? clsx("cls-34", "active-stroke") : "cls-34"}
              points="337.12 51.81 323.21 51.81 323.21 102.6"
            />
            <polyline
              className={activeLine === "hotspot-freigabe-waermeerzeuger" ? clsx("cls-34", "active-stroke") : "cls-34"}
              points="247.37 41.76 234.28 41.76 234.28 101.85"
            />
            <polyline
              className={activeLine === "hotspot-pumpenfreigabe" ? clsx("cls-35", "active-stroke") : "cls-35"}
              points="536.47 154.76 536.47 24.18 222.09 24.18 222.09 102.04"
            />
            <polyline
              className="cls-35"
              points="543.14 156.93 543.14 7.48 196.98 7.48"
            />
            <polyline
              className={activeLine === "hotspot-pumpenfreigabe" ? clsx("cls-35", "active-stroke") : "cls-35"}
              points="207.86 102.88 207.86 24.18 196.98 24.18"
            />
            {/* Betriebsmeldung Wärmeerzeuger */}
            <path d="M472.58 246.87V282.04H336.95" 
              className={activeLine === "hotspot-betriebsmeldung-waermeerzeuger" || activeLine === "hotspot-stoermeldung-waermeerzeuger" ? clsx("cls-33", "active-stroke") : "cls-33"}/>
            <path d="M323.39 260.83V282.04H336.95" 
              className={activeLine === "hotspot-betriebsmeldung-waermeerzeuger" ? clsx("cls-33", "active-stroke") : "cls-33"}/>
            <path d="M337 261V282" 
              className={activeLine === "hotspot-stoermeldung-waermeerzeuger" ? clsx("cls-33", "active-stroke") : "cls-33"}/>
            <path d="M323.39 282.04H310.28V261.11"
              className={activeLine === "hotspot-betriebsmeldung-waermeerzeuger" ? clsx("cls-33", "active-stroke") : "cls-33"}/>
            {/* Störmelder Pumpe */}
            <path d="M539.22 165.84V290.13H234.81"   
              className={activeLine === "hotspot-betriebsmeldung-pumpe" || activeLine === "hotspot-stoermeldung-pumpe" ? clsx("cls-33", "active-stroke") : "cls-33"}/>
            <path d="M234.81 290.13H221.7V260.55"  
              className={activeLine === "hotspot-betriebsmeldung-pumpe" ? clsx("cls-33", "active-stroke") : "cls-33"}/>
            <path d="M234.7 290.13V260.55"  
              className={activeLine === "hotspot-stoermeldung-pumpe" ? clsx("cls-33", "active-stroke") : "cls-33"}/>
            <path d="M221.7 290.13H208.7V260.55" 
              className={activeLine === "hotspot-betriebsmeldung-pumpe" ? clsx("cls-33", "active-stroke") : "cls-33"}/>
            <text className="cls-22" transform="translate(183.02 11.97)">
              <tspan x="0" y="0">
                N
              </tspan>
            </text>
            <text className="cls-22" transform="translate(185.02 30.97)">
              <tspan x="0" y="0">
                L
              </tspan>
            </text>
            <text className="cls-39" transform="translate(0 100)">
              <tspan className="cls-23">
                Legende:
              </tspan>
              <tspan className={activeLine === "hotspot-pumpenfreigabe" ? clsx("cls-23", "averta-bold") : "cls-23"} x="0" y="30">
                1/2 Pumpenfreigabe
              </tspan>
              <tspan className={activeLine === "hotspot-freigabe-waermeerzeuger" ? clsx("cls-32", "averta-bold") : "cls-32"} x="0" y="48">
                3/4 Freigabe Wärmeerzeuger
              </tspan>
              <tspan className={activeLine === "hotspot-sollwert-waermeerzeuger" ? clsx("cls-32", "averta-bold") : "cls-32"} x="0" y="66">
                5/6 Sollwert Wärmeerzeuger
              </tspan>
              <tspan className={activeLine === "hotspot-vorlauftemperatur" ? clsx("cls-32", "averta-bold") : "cls-32"} x="0" y="84">
                7/8 Vorlauftemperatur
              </tspan>
              <tspan className={activeLine === "hotspot-ruecklauftemparatur" ? clsx("cls-32", "averta-bold") : "cls-32"} x="0" y="102">
                9/10 Rücklauftemperatur
              </tspan>
              <tspan className={activeLine === "hotspot-betriebsmeldung-pumpe" ? clsx("cls-31", "averta-bold") : "cls-31"} x="0" y="120">
                11/12 Betriebsmeldung Pumpe
              </tspan>
              <tspan className={activeLine === "hotspot-stoermeldung-pumpe" ? clsx("cls-31", "averta-bold") : "cls-31"} x="0" y="138">
                13 Störmeldung Pumpe
              </tspan>
              <tspan className={activeLine === "hotspot-betriebsmeldung-waermeerzeuger" ? clsx("cls-31", "averta-bold") : "cls-31"} x="0" y="156">
                15/16 Betriebsmeldung Wärmeerzeuger
              </tspan>
              <tspan className={activeLine === "hotspot-stoermeldung-waermeerzeuger" ? clsx("cls-31", "averta-bold") : "cls-31"} x="0" y="174">
                17 Störmeldung Wärmeerzeuger
              </tspan>
            </text>
            <g id="grafik-waerme-kaelteerzeuger-interface 2">
              <rect onClick={handleLineChange} className="click-me pointer" id="hotspot-pumpenfreigabe" y="116" width="102" height="17" fill="black" fill-opacity="0"/>
              <rect onClick={handleLineChange} className="click-me pointer" id="hotspot-freigabe-waermeerzeuger" y="133" width="145" height="19" fill="black" fill-opacity="0"/>
              <rect onClick={handleLineChange} className="click-me pointer" id="hotspot-sollwert-waermeerzeuger" y="152" width="144" height="18" fill="black" fill-opacity="0"/>
              <rect onClick={handleLineChange} className="click-me pointer" id="hotspot-vorlauftemperatur" y="170" width="112" height="17" fill="black" fill-opacity="0"/>
              <rect onClick={handleLineChange} className="click-me pointer" id="hotspot-ruecklauftemparatur" y="187" width="128" height="18" fill="black" fill-opacity="0"/>
              <rect onClick={handleLineChange} className="click-me pointer" id="hotspot-betriebsmeldung-pumpe" y="205" width="152" height="18" fill="black" fill-opacity="0"/>
              <rect onClick={handleLineChange} className="click-me pointer" id="hotspot-stoermeldung-pumpe" y="223" width="122" height="19" fill="black" fill-opacity="0"/>
              <rect onClick={handleLineChange} className="click-me pointer" id="hotspot-betriebsmeldung-waermeerzeuger" y="242" width="187" height="17" fill="black" fill-opacity="0"/>
              <rect onClick={handleLineChange} className="click-me pointer" id="hotspot-stoermeldung-waermeerzeuger" y="259" width="161" height="19" fill="black" fill-opacity="0"/>
            </g>
            <g>
              <rect onClick={dialogWaermeerzeuger} className="hotspot hotspotHover" x="447" y="111" width="52" height="145" rx="6" fill="black" fill-opacity="0"/>
              <rect onClick={dialogTemperaturfuehler} className="hotspot hotspotHover" x="503" y="111" width="20" height="145" rx="6" fill="black" fill-opacity="0"/>
              <rect onClick={dialogPumpe} className="hotspot hotspotHover" x="527" y="111" width="24" height="145" rx="6" fill="black" fill-opacity="0"/>
            </g>
          </svg>
        </Grid>
      </Grid>
    </Slide>
  );
}
