import { Box, Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import BetterImage from "../../../../../../components/BetterImage";
import ImageComponent from "../../../../../../components/ImageComponent";
import QBodyText from "../../../../../../components/QBodyText";
import QAccordion from "../../../../../../components/QAccordion";

import RTRImage from "../../../../../../assets/neuheiten-2024/bje/elektr-rtr/RTR.webp";

export default function ElektrischeRaumtemperaturregler() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Elektrische Raumtemperaturregler" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  "Neue elektronische Raumtemperaturregler für das Entry-, Mid- , und Premium-Segment",
                  "Durch Drehbedienung einfach und intuitiv zu nutzen",
                  "Mit Display BT für Vor-Ort-Bedienung als auch für smarte Einstellung per App",
                  "Mit Display WL für die Vernetzung und Anbindung an Busch-free@home®",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}>
              <Box
                height="70dvh"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <BetterImage
                  align="center"
                  height="auto"
                  width="80%"
                  src={RTRImage}
                  alt="Elektrische Raumtemperaturregler"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
