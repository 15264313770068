import { h } from "preact";
import { Grid, TextField, makeStyles } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});

export default function QDateAction({
  date,
  note,
  dateID,
  noteID,
  setSetupField,
}) {
  const classes = useStyles();

  const handleDateChange = (v) => {
    setSetupField({
      id: "seminarAppointment",
      value: v,
    });
  };

  const handleFieldChange = (e) => {
    setSetupField({
      id: e.currentTarget.id,
      value: e.currentTarget.value,
    });
  };

  return (
    <Grid container direction="row" justify="space-between">
      <Grid item xs={6}>
        <DatePicker
          label="Datum"
          id={dateID}
          value={date}
          onChange={handleDateChange}
          cancelLabel="Abbrechen"
          format="dd.MM.yyyy"
          animateYearScrolling
          InputProps={{ classes }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          onChange={handleFieldChange}
          id={noteID}
          value={note}
          fullWidth
          label="Anmerkung"
          InputProps={{ classes }}
        />
      </Grid>
    </Grid>
  );
}
