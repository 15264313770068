import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Markdown from "preact-markdown";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1303",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function ChangeLogModal() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => {
    setOpen((v) => !v);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        style={{ marginLeft: "1rem" }}
        onClick={toggleOpen}
      >
        Admin
      </Button>
      <Modal
        aria-labelledby="changelog-modal-title"
        aria-describedby="changelog-modal-description"
        className={classes.modal}
        open={open}
        onClose={toggleOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="changelog-modal-title">Changelog</h2>
            <p>Commithash: {COMMITHASH}</p>
            <p>Builddate: {BUILDATE}</p>
            <p id="changelog-modal-description">
              <Markdown markdown={CHANGELOG} />
            </p>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
