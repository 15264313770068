import {
  Grid,
  Paper,
  makeStyles,
  Box,
  CardMedia,
  CardContent,
  Typography,
  CardActionArea,
  Card,
  Link,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { getCategoriesData } from "../../redux/modules/categoriesReducer";
import { getActiveChapter } from "../../redux/modules/consultingReducer";
import Section from "../Section";
import { chapters } from "../../static/data";
import Slide from "../Slide";
import QProductGroup from "../QProductGroup";
import { useEffect, useState } from "preact/hooks";
import { getCustomCategories } from "../../redux/modules/settingsReducer";
import ChapterCard from "../ChapterCard";
import { reBuild } from "../../static/fullpageHelpers";
import { route } from "preact-router";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation, Pagination } from "swiper";
// Import Swiper styles

const useStyles = makeStyles(() => ({
  chapterPaper: {
    padding: "1.4rem",
    fontSize: "1rem",
    minHeight: "1.2rem",
    margin: "0.5rem",
  },
  chapterActive: {
    filter: "drop-shadow(0 0 0.09rem rgb(0, 0, 0))",
    fontFamily: "Averta-SemiBold",
  },
  sectionStyle: {
    borderTop: "2px solid var(--grey-30)",
  },
}));

export default function NextChapter({ section }) {
  const classes = useStyles();
  const categoriesData = useSelector((state) => getCategoriesData(state));
  const activeChapterId = useSelector((state) => getActiveChapter(state));
  const customCategories = useSelector((state) => getCustomCategories(state));
  const [activeTab, setActiveTab] = useState("favs");
  const [chapterData, setChapterData] = useState([]);
  const [chapterCounter, setChapterCounter] = useState(0);
  const categories = categoriesData.categories;

  useEffect(() => {
    if (customCategories.length === 0) {
      setActiveTab("chaps");
    }
  }, [customCategories]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setTimeout(() => {
      reBuild();
    }, 200);
  };

  const routeToChapter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    route(chapters[e.target.attributes.alt.value].link, true);
  };

  const favData = Object.keys(categories).map((category) => {
    if (categories[category] && typeof chapters[category] !== "undefined") {
      const activeChapter = chapters[category].chapterID === activeChapterId;
      if (customCategories.indexOf(category) !== -1) {
        return (
          <ChapterCard
            onCardClick={routeToChapter}
            chapter={chapters[category]}
            active={activeChapter}
          />
        );
      }
    }
  });

  useEffect(() => {
    let tempArray = [];
    const slidesArray = [];
    const outputArray = [];
    let counter = 0;

    let activeCategories = Object.keys(categories).filter(
      (cat) => categories[cat]
    );

    activeCategories.forEach((chapter) => {
      if (tempArray.length >= 9) {
        slidesArray.push(tempArray);
        tempArray = [];
      }
      tempArray.push(chapter);

      if (
        slidesArray.length * 9 + tempArray.length ===
        activeCategories.length
      ) {
        slidesArray.push(tempArray);
      }
    });
    slidesArray.forEach((chapterArray) => {
      const cardArray = [];
      chapterArray.map((chaptername) => {
        if (typeof chapters[chaptername] !== "undefined") {
          counter++;
          cardArray.push(
            <Grid item xs={4}>
              <Link href={chapters[chaptername].link}>
                <Paper
                  className={`${classes.chapterPaper} ${
                    chapters[chaptername].chapterID === activeChapterId &&
                    classes.chapterActive
                  }`}
                  elevation={3}
                >
                  {chapters[chaptername].label}
                </Paper>
              </Link>
            </Grid>
          );
        }
      });

      outputArray.push(
        <SwiperSlide className="nextChapterSliderSlide">
          <Grid container style={{ padding: "0px 50px" }}>
            {cardArray}
          </Grid>
        </SwiperSlide>
      );
    });

    setChapterCounter(counter);
    setChapterData(outputArray);
  }, [categories, activeChapterId, classes]);

  return (
    <Section
      selector={`${section} fp-auto-height`}
      className={classes.sectionStyle}
    >
      <Slide
        background={{
          width: "100%",
          height: "100%",
        }}
        bottomPadding
      >
        <Grid container m={0} justifyContent="center" alignItems="stretch">
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                gap: 20,
                mb: 3,
              }}
            >
              {customCategories.length > 0 ? (
                <Typography
                  variant="h4"
                  display="inline"
                  style={{
                    fontFamily:
                      activeTab === "favs" ? "Averta-SemiBold" : "Averta-Light",
                  }}
                  onClick={() => handleTabChange("favs")}
                >
                  Favoriten
                </Typography>
              ) : null}
              <Typography
                variant="h4"
                display="inline"
                style={{
                  fontFamily:
                    activeTab === "chaps" ? "Averta-SemiBold" : "Averta-Light",
                }}
                onClick={() => handleTabChange("chaps")}
              >
                Kapitel ({chapterCounter})
              </Typography>
            </Box>
            {activeTab === "favs" && (
              <Box
                component="ul"
                sx={{
                  display: "flex",
                  gap: 20,
                  p: 2,
                  m: 0,
                  overflow: "scroll",
                  maxWidth: "100%",
                }}
              >
                {favData}
              </Box>
            )}
            {activeTab === "chaps" && (
              <Swiper
                modules={[Pagination, Navigation]}
                pagination={true}
                navigation={true}
                className="nextChapterSlider"
              >
                {chapterData}
              </Swiper>
            )}
          </Grid>
        </Grid>
      </Slide>
    </Section>
  );
}
