import { Grid } from "@material-ui/core";
import QHeader from "../../../../../components/QHeader";
import Slide from "../../../../../components/Slide";
import QBoxList from "../../../../../components/QBoxList";
import QSwiperJS from "../../../../../components/QSwiperJS";
// Beleuchtungssteuerung
import AnwendungsbeschreibungBeleuchtungssteuerung from "./slides/Beleuchtungssteuerung/Anwendungsbeschreibung";
import DasSystem from "./slides/Beleuchtungssteuerung/DasSystem";
import TentonBeleuchtungssteuerung from "./slides/Beleuchtungssteuerung/Tenton";
import Sensorik from "./slides/Beleuchtungssteuerung/Sensorik";
import Reg from "./slides/Beleuchtungssteuerung/Reg";

// Images
import TageslichtabhaengigeBeleuchtungssteuerungImage from "../../../../../assets/abbknx/raum.webp";
import ClimaEcoImage from "../../../../../assets/abbknx/ClimaECO.webp";

// Einzelraumregelung
import ClimaECO from "./slides/Einzelraumregelung/ClimaECO";
import ChapterCard from "../../../../../components/ChapterCard";

export const itemList = [
  {
    title: (
      <>
        Tageslichtabhängige
        <br />
        Beleuchtungssteuerung
      </>
    ),
    img: TageslichtabhaengigeBeleuchtungssteuerungImage,
    menuOverlay: (
      <QSwiperJS>
        <AnwendungsbeschreibungBeleuchtungssteuerung />
        <DasSystem />
        <TentonBeleuchtungssteuerung />
        <Sensorik />
        <Reg />
      </QSwiperJS>
    ),
  },
  {
    title: "ClimaECO",
    img: ClimaEcoImage,
    menuOverlay: <ClimaECO />,
  },
];

export default function Anwendungen() {
  return (
    <Slide
      background={{
        alignment: "left",
        height: 1,
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                ABB i-bus<sup>®</sup> KNX
              </>
            }
            subTitle="Anwendungen | Lösungen"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {itemList.map((e, i) => {
              return (
                <Grid item xs={3}>
                  <ChapterCard chapter={e} cardWidth="auto" />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
