import {h} from 'preact'

import {useDispatch, useSelector} from 'react-redux'
import {
  setSeminarAppointment,
  setBuschflexNotes,
  getBuschflexData,
  setSeminarNote,
  setAsset as setDispatchAsset,
} from '../../../redux/modules/chapterBuschFlex'
import {Grid} from '@material-ui/core'
import QIncrementGroup from '../../QIncrementGroup'
import QActionPaper from '../../QActionPaper'
import QDateAction from '../../QDateAction'
import QTextFieldAction from '../../QTextFieldAction'

export default function BuschflexPage({context}) {
  const dispatch = useDispatch()

  const {
    seminarAppointment: seminarAppointmentData,
    notes: notesData,
    seminarNote: seminarNoteData,
    assetKatalog: assetKatalogData,
    assetPlanungshandbuch: assetPlanungshandbuchData,
    assetSBBuschWaechterFlex: assetSBBuschWaechterFlexData,
    assetFlexTronicSortimentsbroschuere:
      assetFlexTronicSortimentsbroschuereData,
  } = useSelector(getBuschflexData)

  const seminarAppointment = seminarAppointmentData.value
  const notes = notesData.value
  const seminarNote = seminarNoteData.value
  const assetKatalog = assetKatalogData.value
  const assetPlanungshandbuch = assetPlanungshandbuchData.value
  const assetSBBuschWaechterFlex = assetSBBuschWaechterFlexData.value
  const assetFlexTronicSortimentsbroschuere =
    assetFlexTronicSortimentsbroschuereData.value

  const setSetupField = valueSet => {
    switch (valueSet.id) {
      case 'seminarNote':
        {
          dispatch(setSeminarNote(valueSet.value))
        }
        break
      case 'seminarAppointment':
        {
          dispatch(setSeminarAppointment(valueSet.value))
        }
        break
      case 'notes':
        {
          dispatch(setBuschflexNotes(valueSet.value))
        }
        break
    }
  }

  const setAsset = (id, value) => {
    dispatch(setDispatchAsset(id, value))
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <QActionPaper title="Seminare" context={context}>
          <QDateAction
            date={seminarAppointment}
            dateID="seminarAppointment"
            note={seminarNote}
            noteID="seminarNote"
            setSetupField={setSetupField}
          />
        </QActionPaper>
      </Grid>
      <Grid item>
        <QActionPaper title="Werbung" context={context}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetKatalog}
                valueID="assetKatalog"
                label={assetKatalogData.description}
                mode={1}
                setAsset={setAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetPlanungshandbuch}
                valueID="assetPlanungshandbuch"
                label={assetPlanungshandbuchData.description}
                mode={1}
                setAsset={setAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetSBBuschWaechterFlex}
                valueID="assetSBBuschWaechterFlex"
                label={assetSBBuschWaechterFlexData.description}
                mode={1}
                setAsset={setAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetFlexTronicSortimentsbroschuere}
                valueID="assetFlexTronicSortimentsbroschuere"
                label={assetFlexTronicSortimentsbroschuereData.description}
                mode={1}
                setAsset={setAsset}
              />
            </Grid>
          </Grid>
        </QActionPaper>
      </Grid>
      <Grid item>
        <QActionPaper title="Notizen" context={context}>
          <QTextFieldAction
            value={notes}
            valueID="notes"
            setSetupField={setSetupField}
          />
        </QActionPaper>
      </Grid>
    </Grid>
  )
}
