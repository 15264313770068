import { h } from "preact";
import { useDispatch, useSelector } from "react-redux";
import {
  setSeminarAppointment,
  setWelcomeNotes,
  getWelcomeData,
  setSeminarNote,
  setAsset,
} from "../../../redux/modules/chapterWelcome";
import { Grid } from "@material-ui/core";
import QActionPaper from "../../QActionPaper";
import QIncrementGroup from "../../QIncrementGroup";
import QTextFieldAction from "../../QTextFieldAction";
import QDateAction from "../../QDateAction";
import QActionCheckbox from "../../QActionCheckbox";

export default function WelcomePage({ context }) {
  const dispatch = useDispatch();
  const welcomeData = useSelector((state) => getWelcomeData(state));
  const {
    seminarAppointment,
    notes,
    seminarNote,
    assetSortimentsbroschüre,
    assetSystemhandbuch,
    assetSondermustertafel,
    assetMusterkofferWelcome,
    assetBuschWelcomeFlyerDINLang,
  } = welcomeData;

  const handleSetSetupField = (valueSet) => {
    switch (valueSet.id) {
      case "seminarNote":
        {
          dispatch(setSeminarNote(valueSet.value));
        }
        break;
      case "seminarAppointment":
        {
          dispatch(setSeminarAppointment(valueSet.value));
        }
        break;
      case "notes":
        {
          dispatch(setWelcomeNotes(valueSet.value));
        }
        break;
    }
  };

  const handleSetAsset = (id, value) => {
    dispatch(setAsset(id, value));
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <QActionPaper title="Busch-Jaeger Seminare" context={context}>
          <QDateAction
            date={seminarAppointment.value}
            dateID="seminarAppointment"
            note={seminarNote.value}
            noteID="seminarNote"
            setSetupField={handleSetSetupField}
          />
        </QActionPaper>
      </Grid>
      <Grid item>
        <QActionPaper title="Unterlagen" context={context}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetSortimentsbroschüre.value}
                valueID="assetSortimentsbroschüre"
                label="Sortimentsbroschüre"
                mode={1}
                setAsset={handleSetAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetSystemhandbuch.value}
                valueID="assetSystemhandbuch"
                label="Systemhandbuch"
                mode={1}
                setAsset={handleSetAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetBuschWelcomeFlyerDINLang.value}
                valueID="assetBuschWelcomeFlyerDINLang"
                label="Busch-Welcome Flyer DIN Lang"
                mode={1}
                setAsset={handleSetAsset}
              />
            </Grid>
          </Grid>
        </QActionPaper>
      </Grid>
      <Grid item>
        <QActionPaper title="Notizen" context={context}>
          <QTextFieldAction
            value={notes.value}
            valueID="notes"
            setSetupField={handleSetSetupField}
          />
        </QActionPaper>
      </Grid>
    </Grid>
  );
}
