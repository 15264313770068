import { h } from "preact";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function InfoCircle(props) {
  return (
    <SvgIcon {...props}>
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.002 0.00500488C15.52 0.00500488 20 4.485 20 10.002C20 15.52 15.52 20 10.002 20C4.485 20 0.00500488 15.52 0.00500488 10.002C0.00500488 4.485 4.485 0.00500488 10.002 0.00500488ZM10.002 1.505C5.312 1.505 1.505 5.312 1.505 10.002C1.505 14.692 5.312 18.5 10.002 18.5C14.692 18.5 18.5 14.692 18.5 10.002C18.5 5.312 14.692 1.505 10.002 1.505ZM10.002 8.005C9.58801 8.005 9.252 8.34101 9.252 8.755V14.255C9.252 14.669 9.58801 15.005 10.002 15.005C10.416 15.005 10.752 14.669 10.752 14.255V8.755C10.752 8.34101 10.416 8.005 10.002 8.005ZM10 5.005C10.552 5.005 11 5.453 11 6.005C11 6.557 10.552 7.005 10 7.005C9.448 7.005 9 6.557 9 6.005C9 5.453 9.448 5.005 10 5.005Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
}
