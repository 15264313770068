import { Box, Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";

// Images
import ImageAppControl from "../../../../../../assets/neuheiten-2024/bje/knx-app-control/knx-app-control.webp";
import BetterImage from "../../../../../../components/BetterImage";

export default function KNXAppControl() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="KNX App-Control" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  "Neue Applikationen im Vergleich zu ControlTouch",
                  "Zugang zum ProService Portal, so haben Sie den kompletten Überblick über Ihre Kunden",
                  "KNX Fernprogrammierung",
                  "KNX Offline-Telegrammaufzeichnung",
                  "Auto-Update-Funktion",
                  "Fernzugriff auf das Gerät",
                  "KNX IP-Router-Funktion",
                  "Implementierung von Modbus TCP und BACnet",
                  "Erleichterte Inbetriebnahme durch direkte Gruppenadressenanbindung",
                  "Energie-Management",
                  "Grafischer Logik-Editor",
                  "OCPP-Integration",
                  "Nutzung von Online-Wetterdaten",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}>
              <Box
                height="60dvh"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <BetterImage
                  align="center"
                  height="auto"
                  width="30dvw"
                  src={ImageAppControl}
                  alt="KNX App-Control"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
