import { h, Component } from "preact";
import { connect } from "react-redux";
import {
  setSeminarAppointment,
  setComfortLineNotes,
  getComfortLineData,
  setSeminarNote,
  setAsset,
} from "../../../redux/modules/chapterComfortLine";
import { Grid } from "@material-ui/core";
import QActionPaper from "../../QActionPaper";
import QDateAction from "../../QDateAction";
import QIncrementGroup from "../../QIncrementGroup";
import QTextFieldAction from "../../QTextFieldAction";

class KnxPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      seminarAppointment,
      notes,
      seminarNote,
      assetSmarterHomeBroschuere,
      assetFutureCatalog,
      assetFlyer2019,
      assetComfortplanerBroschuere,
      context,
    } = this.props;

    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <QActionPaper title="ABB Seminare" context={context}>
            <QDateAction
              date={seminarAppointment}
              dateID="seminarAppointment"
              note={seminarNote}
              noteID="seminarNote"
              setSetupField={this.props.setSetupField}
            />
          </QActionPaper>
        </Grid>
        <Grid item>
          <QActionPaper title="Unterlagen" context={context}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <QIncrementGroup
                  value={assetSmarterHomeBroschuere}
                  valueID="assetSmarterHomeBroschuere"
                  label="Broschüre Smarter Home"
                  mode={1}
                  setAsset={this.props.setAsset}
                />
              </Grid>
              <Grid item xs={6}>
                <QIncrementGroup
                  value={assetFutureCatalog}
                  valueID="assetFutureCatalog"
                  label="Katalog Zukunft von Anfang an"
                  mode={1}
                  setAsset={this.props.setAsset}
                />
              </Grid>
              <Grid item xs={6}>
                <QIncrementGroup
                  value={assetFlyer2019}
                  valueID="assetFlyer2019"
                  label="Kleiner Verteilerberater - Flyer"
                  mode={1}
                  setAsset={this.props.setAsset}
                />
              </Grid>
              <Grid item xs={6}>
                <QIncrementGroup
                  value={assetComfortplanerBroschuere}
                  valueID="assetComfortplanerBroschuere"
                  label="Comfortplaner - Broschüre"
                  mode={1}
                  setAsset={this.props.setAsset}
                />
              </Grid>
            </Grid>
          </QActionPaper>
        </Grid>
        <Grid item>
          <QActionPaper title="Notizen" context={context}>
            <QTextFieldAction
              value={notes}
              valueID="notes"
              setSetupField={this.props.setSetupField}
            />
          </QActionPaper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    seminarAppointment,
    notes,
    seminarNote,
    assetSmarterHomeBroschuere,
    assetFutureCatalog,
    assetFlyer2019,
    assetComfortplanerBroschuere,
  } = getComfortLineData(state);
  return {
    seminarAppointment: seminarAppointment.value,
    notes: notes.value,
    seminarNote: seminarNote.value,
    assetSmarterHomeBroschuere: assetSmarterHomeBroschuere.value,
    assetFutureCatalog: assetFutureCatalog.value,
    assetFlyer2019: assetFlyer2019.value,
    assetComfortplanerBroschuere: assetComfortplanerBroschuere.value,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSetupField(valueSet) {
    switch (valueSet.id) {
      case "seminarNote":
        {
          dispatch(setSeminarNote(valueSet.value));
        }
        break;
      case "seminarAppointment":
        {
          dispatch(setSeminarAppointment(valueSet.value));
        }
        break;
      case "notes":
        {
          dispatch(setComfortLineNotes(valueSet.value));
        }
        break;
    }
  },
  setAsset(id, value) {
    dispatch(setAsset(id, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(KnxPage);
