import {
  makeStyles,
  CardMedia,
  CardContent,
  Typography,
  CardActionArea,
  Card,
  styled,
  useTheme,
  useMediaQuery,
  Box,
} from "@material-ui/core";
import clsx from "clsx";
import { useModal } from "mui-modal-provider";
import QDialog from "../QDialog";
import { useState } from "preact/hooks";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCustomCategories } from "../../redux/modules/settingsReducer";
import { ToggleButton } from "@material-ui/lab";
import root from "window-or-global";
import BetterPDFViewer from "../BetterPDFViewer";
import { downloadFile } from "../../utils/downloadFunction";

const useStyles = makeStyles(() => ({
  root: {
    overflow: "initial",
    height: "100%",
  },
  cardMedia: {
    borderRadius: "4px 4px 0px 0px",
  },
  cardContent: {
    height: "100%",
    padding: ".8rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  card: {
    overflow: "initial",
  },
  cardActionAreaFullWidth: {
    width: "100% !important",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    alignItems: "stretch",
  },
  cardActionArea: {
    width: "250px",
    alignItems: "stretch",
  },
  cardText: {
    fontFamily: "Averta-Semibold",
    fontSize: "clamp(15px, 1.2vw, 1.5rem)",
    hyphens: "auto",
    wordWrap: "anywhere",
  },
  subText: {
    fontFamily: "Averta-Regular",
    fontSize: "clamp(15px, 1.2vw, 1.5rem)",
    hyphens: "auto",
  },
  chapterActive: {
    filter: "drop-shadow(0 0 0.09rem rgb(0, 0, 0))",
  },
  cardTextActive: {
    fontFamily: "Averta-SemiBold",
  },
  cardTextRegular: {
    fontFamily: "Averta-Regular",
  },
  favorite: {
    padding: 0,
  },
}));

export default function ChapterCard({
  chapter,
  active,
  regularFont = false,
  cardWidth,
  favorite,
  onCardClick,
  onIconClick,
  noDialog,
}) {
  const [pdfDialog, setPDFDialog] = useState(false);
  const classes = useStyles();
  const { showModal } = useModal();
  const customCategories = useSelector((state) => getCustomCategories(state));
  const theme = useTheme();

  const MuiToggleButton = styled(ToggleButton)({
    border: "none",
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
  });

  const dialog = (children) => {
    const d = showModal(QDialog, {
      children,
      onConfirm: () => {
        d.hide();
      },
      onCancel: () => {
        d.hide();
      },
    });
  };
  const openPDFDialog = () => {
    setPDFDialog(true);
  };

  const closePDFDialog = () => {
    setPDFDialog(false);
  };

  return (
    <>
      {chapter?.pdf && (
        <BetterPDFViewer
          onCancel={closePDFDialog}
          open={pdfDialog}
          path={chapter.pdf}
        />
      )}
      <Card
        style={{ display: chapter.placeholder ? "none" : "block" }}
        className={clsx(
          classes.root,
          active ? classes.chapterActive : null,
          chapter.new && "new-product"
        )}
      >
        <CardActionArea
          style={cardWidth ? { width: cardWidth } : null}
          className={
            cardWidth ? classes.cardActionAreaFullWidth : classes.cardActionArea
          }
          onClick={
            onCardClick
              ? onCardClick
              : chapter.menuOverlay && !noDialog
              ? () => dialog(chapter.menuOverlay)
              : chapter.pdf
              ? openPDFDialog
              : chapter.link
              ? chapter.link.download
                ? () => downloadFile(chapter.link.href)
                : () =>
                    root.open(
                      chapter.link.href,
                      chapter.link.target ? chapter.link.target : "_blank",
                      "noopener"
                    )
              : undefined
          }
        >
          <CardMedia
            component="img"
            image={"imgSmall" in chapter ? chapter.imgSmall : chapter.img}
            alt={chapter.slug}
            className={classes.cardMedia}
          />

          <CardContent className={classes.cardContent}>
            <Box display="flex" flexDirection="column">
              <Typography
                className={clsx(
                  classes.cardText,
                  active
                    ? classes.cardTextActive
                    : regularFont
                    ? classes.cardTextRegular
                    : null
                )}
                variant="h5"
              >
                {chapter.label ? chapter.label : chapter.title}
              </Typography>
              {chapter.subTitle && (
                <Typography className={classes.subText} variant="h5">
                  {chapter.subTitle}
                </Typography>
              )}
            </Box>

            {typeof favorite !== "undefined" ? (
              <MuiToggleButton
                value={chapter.slug}
                selected={favorite}
                onChange={onIconClick}
              >
                {customCategories.indexOf(chapter.slug) !== -1 ? (
                  <StarIcon />
                ) : (
                  <StarBorderIcon />
                )}
              </MuiToggleButton>
            ) : null}
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
}
