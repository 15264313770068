import ImageComponent from "../ImageComponent";

export default function CenteredImage({ shadow, containerWidth }) {
  const absoluteContainer = {
    margin: "0px auto",
    display: "flex",
    zIndex: 10,
    width: this.props.containerWidth
      ? this.props.containerWidth
      : "fit-content",
    height: this.props.containerHeight ? this.props.containerHeight : "83vh",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    left: this.props.left ? this.props.left : "0",
    right: this.props.right ? this.props.right : "0",
    top: this.props.top ? this.props.top : "0",
    bottom: this.props.bottom ? this.props.bottom : "0",
    maxHeight: this.props.maxHeight,
    maxWidth: this.props.maxWidth,
  };

  const switchArrowStyle = {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    height: `calc(${this.props.height} * 0.6)`,
    zIndex: 11,
    marginLeft: `calc(${
      this.props.width ? this.props.width : this.props.height
    } * 0.6)`,
    userSelect: "none",
    pointerEvents: "none",
  };

  const bluetoothStyle = {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    height: `calc(${this.props.height} * 0.16)`,
    zIndex: 11,
    marginLeft: `calc(${
      this.props.width ? this.props.width : this.props.height
    } * 0.7)`,
    marginBottom: `calc(${this.props.height} * 0.65)`,
    userSelect: "none",
    pointerEvents: "none",
  };

  const switchArrow = (
    <svg
      style={switchArrowStyle}
      viewBox="0 0 109 490"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Busch-flex"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Artboard"
          transform="translate(-616.000000, -169.000000)"
          fill="#363636"
          fill-rule="nonzero"
        >
          <path
            id="Line-4"
            d="M616.185359,169.111411 L666.061003,191.775704 L650.352483,204.495804 C700.36304,272.885018 725.285018,342.754452 724.999964,414.018 C724.71518,485.213948 699.743599,555.019438 650.197211,623.353435 L665.960816,636.002718 L616.187101,658.889993 L627.74151,605.338656 L643.168174,617.715344 C691.483505,550.95816 715.723668,483.073907 716.000036,413.982 C716.276507,344.864153 692.098586,276.953778 643.348479,210.165247 L627.979311,222.610502 L616.185359,169.111411 Z"
          ></path>
        </g>
      </g>
    </svg>
  );

  const bluetooth = (
    <svg
      style={bluetoothStyle}
      viewBox="0 0 256 348"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid"
    >
      <g>
        <path
          d="M142.029889,85.6873567 L170.723389,114.380856 L142.047492,143.050885 L142.029889,85.6873567 L142.029889,85.6873567 L142.029889,85.6873567 Z M142.029889,261.333822 L170.723389,232.646191 L142.047492,203.970294 L142.029889,261.333822 L142.029889,261.333822 L142.029889,261.333822 Z M111.394151,173.51059 L49.3657284,111.323737 L67.3446411,93.3506922 L116.757312,142.786834 L116.757312,24.3161273 L206.622536,114.175484 L147.28743,173.51059 L206.634272,232.863299 L116.76318,322.722655 L116.76318,204.24608 L67.3387733,253.68809 L49.3598606,235.70331 L111.394151,173.51059 L111.394151,173.51059 L111.394151,173.51059 Z M128.011736,347.04465 C203.788393,347.04465 256,311.04575 256,173.516457 C256,35.993032 203.788393,-2.84217094e-14 128.011736,-2.84217094e-14 C52.2409462,-2.84217094e-14 2.84217094e-14,35.9988998 2.84217094e-14,173.516457 C7.57512692e-15,311.04575 52.2350784,347.04465 128.011736,347.04465 L128.011736,347.04465 L128.011736,347.04465 Z"
          fill="#0060A9"
        ></path>
      </g>
    </svg>
  );

  return (
    <div style={absoluteContainer}>
      {this.props.switchArrow !== undefined ? switchArrow : null}
      {this.props.bluetooth !== undefined ? bluetooth : null}
      <ImageComponent
        images={this.props.images}
        thumbnail={this.props.thumbnail}
        src={this.props.src}
        alt={this.props.alt}
        wrapperHeight={this.props.height}
        wrapperWidth={this.props.width}
        previewImgMaxHeight={this.props.maxHeight}
        previewImgMaxWidth={this.props.maxWidth}
        shadow={this.props.shadow}
        noWrapperHeight={this.props.noWrapperHeight}
      />
    </div>
  );
}
