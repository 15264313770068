import { createStore, compose, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import reducer from "../modules/reducers";
import * as Sentry from "@sentry/react";
import createSentryMiddleware from "redux-sentry-middleware";

// #region createStore : enhancer
const enhancer = compose(
  applyMiddleware(
    thunkMiddleware,
    createSentryMiddleware(Sentry, {
      // Optionally pass some options here.
    })
  )
);
// #endregion

// #region persisted reducer
const persistConfig = {
  key: "root",
  version: "2.0.2",
  storage,
  migrate: (state) => {
    if (state._persist.version !== persistConfig.version) {
      // eslint-disable-next-line no-console
      
      if (state._persist.version < "2.0.1") {
        if (state?.categories?.categories?.uk600) {
          state.categories.categories.uk600ak600 = state.categories.categories.uk600
          delete(state.categories.categories.uk600)
        }
      }
      
      const consultationStore = Object.prototype.hasOwnProperty.call(
        state,
        "consultationStore"
      )
        ? state.consultationStore
        : null;

      state = undefined;
      if (consultationStore !== null) {
        state = { consultationStore };
      }
    }
    return Promise.resolve(state);
  },
};

const persistedReducer = persistReducer(persistConfig, reducer);
// #endregion

// #region store initialization
export default function configureStore(initialState) {
  const store = createStore(persistedReducer, initialState, enhancer);
  const persistor = persistStore(store);
  return { store, persistor };
}
// #endregion
