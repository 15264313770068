import { Grid } from "@material-ui/core";
import Slide from "../../../../../components/Slide";
import QHeader from "../../../../../components/QHeader";
import ChapterCard from "../../../../../components/ChapterCard";

// Images
import Image1 from "../../../../../assets/neuheiten-2024/bje/kacheln/1.webp";
import Image2 from "../../../../../assets/neuheiten-2024/bje/kacheln/2.webp";
import Image3 from "../../../../../assets/neuheiten-2024/bje/kacheln/3.webp";
import Image4 from "../../../../../assets/neuheiten-2024/bje/kacheln/4.webp";
import Image5 from "../../../../../assets/neuheiten-2024/bje/kacheln/5.webp";
import Image6 from "../../../../../assets/neuheiten-2024/bje/kacheln/6.webp";
import Image7 from "../../../../../assets/neuheiten-2024/bje/kacheln/7.webp";
import Image8 from "../../../../../assets/neuheiten-2024/bje/kacheln/8.webp";
import Image9 from "../../../../../assets/neuheiten-2024/bje/kacheln/9.webp";
import Image10 from "../../../../../assets/neuheiten-2024/bje/kacheln/10.webp";
import Image11 from "../../../../../assets/neuheiten-2024/bje/kacheln/11.webp";
import Image12 from "../../../../../assets/neuheiten-2024/bje/kacheln/12.webp";
import Image13 from "../../../../../assets/neuheiten-2024/bje/kacheln/13.webp";
import Image14 from "../../../../../assets/neuheiten-2024/bje/kacheln/14.webp";
import Image15 from "../../../../../assets/neuheiten-2024/bje/kacheln/15.webp";

export default function Overview() {
  const items = [
    {
      title: "Busch-art linear®",
      img: Image1,
      link: {
        href: "#busch-jaeger/1",
        target: "_self",
      },
    },
    {
      title: "Elektrische Raumtemperaturregler",
      img: Image2,
      link: {
        href: "#busch-jaeger/2",
        target: "_self",
      },
    },
    {
      title: "Busch-Wächter® PRO",
      img: Image3,
      link: {
        href: "#busch-jaeger/3",
        target: "_self",
      },
    },
    {
      title: "VDI Multimediaeinsätze",
      img: Image4,
      link: {
        href: "#busch-jaeger/4",
        target: "_self",
      },
    },
    {
      title: "Smart-Switch wireless",
      img: Image5,
      link: {
        href: "#busch-jaeger/5",
        target: "_self",
      },
    },
    {
      title: (
        <>
          Sensor/Schaltaktor 1/1-fach
          <br />
          UP, wireless
        </>
      ),
      img: Image6,
      link: {
        href: "#busch-jaeger/6",
        target: "_self",
      },
    },
    {
      title: "Busch-flexTronics® wireless",
      img: Image7,
      link: {
        href: "#busch-jaeger/7",
        target: "_self",
      },
    },
    {
      title: "Busch-Welcome® (2 Draht)",
      img: Image8,
      link: {
        href: "#busch-jaeger/8",
        target: "_self",
      },
    },
    {
      title: "Busch-Welcome® Innenstation 4.3 Basic",
      img: Image9,
      link: {
        href: "#busch-jaeger/9",
        target: "_self",
      },
    },
    {
      title: 'Busch-OneTouch 7"',
      img: Image10,
      link: {
        href: "#busch-jaeger/10",
        target: "_self",
      },
    },
    {
      title: "Busch-Welcome® IP 2.0",
      img: Image11,
      link: {
        href: "#busch-jaeger/11",
        target: "_self",
      },
    },
    {
      title: "Busch VoiceControl mit Matter Gateway",
      img: Image15,
      link: {
        href: "#busch-jaeger/12",
        target: "_self",
      },
    },
    {
      title: "ComfortPlaner",
      img: Image12,
      link: {
        href: "#busch-jaeger/13",
        target: "_self",
      },
    },
    {
      title: "KNX App-Control",
      img: Image13,
      link: {
        href: "#busch-jaeger/14",
        target: "_self",
      },
    },
    {
      title: "KNX HF Präsenzmelder",
      img: Image14,
      link: {
        href: "#busch-jaeger/15",
        target: "_self",
      },
    },
  ];

  return (
    <Slide background>
      <Grid container direction="column" wrap="nowrap" spacing={4}>
        <Grid item>
          <QHeader title="Neuheiten 2024" subTitle="Busch-Jaeger" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {items.map((e, i) => {
              return (
                <Grid item xs={2}>
                  <ChapterCard chapter={e} cardWidth="auto" />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
