import { h } from "preact";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { sectionStart } from "../../static/fullpageHelpers";
import clsx from "clsx";
import FilledArrowLeft from "../SvgIcons/FilledArrowLeft";
import ArrowBack from "../ArrowBack";

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "Averta-Bold",
    marginBottom: "0.1em"
  },
  subTitle: {
    fontFamily: "Averta-Light",
    marginBottom: "0.6em"
  },
  arrowWrap: {
    position: "absolute",
    left: "8px",
    marginTop: "3px",
    top: "-.9em",
    cursor: "pointer",
  },
}));

export default function QHeader({
  title,
  subTitle,
  arrow,
  arrowOverride,
  size,
  colWidth,
  fontSize,
  color,
  arrowFix,
  ...props
}) {
  const classes = useStyles();
  let subTitleSize;
  switch (size) {
    case "xlarge":
      {
        subTitleSize = classes.xlargeSubTitle;
      }
      break;
    case "large":
      {
        subTitleSize = classes.largeSubTitle;
      }
      break;
    case "small":
      {
        subTitleSize = classes.smallSubTitle;
      }
      break;
    default: {
      subTitleSize = classes.largeSubTitle;
    }
  }

  return (
    <Grid container direction="column">
      <Grid item xs={colWidth}>
        <Typography
          variant="h3"
          className={classes.title}
          style={{
            fontSize: fontSize,
            marginTop: "1rem",
            color: color,
          }}
          gutterBottom={!subTitle}
          {...props}
          onClick={sectionStart}
        >
          {(arrow || arrowOverride) && (
            <ArrowBack
              onClick={arrowOverride === null ? sectionStart : arrowOverride}
            />
          )}
          {title}
        </Typography>
      </Grid>
      {subTitle ? (
        <Grid item>
          <Typography
            variant="h5"
            className={clsx(classes.subTitle, subTitleSize)}
            style={{ color: color}}
            gutterBottom
            {...props}
            onClick={sectionStart}
          >
            {subTitle}
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
}

QHeader.defaultProps = {
  arrow: false,
  arrowOverride: null,
  size: "large",
  colWidth: 9,
  fontSize: "clamp(1.1rem, 4.3vw, 4.2rem)",
  color: "black",
};
