import { Box, Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import ImageComponent from "../../../../../../components/ImageComponent";
import QBodyText from "../../../../../../components/QBodyText";
import QAccordion from "../../../../../../components/QAccordion";

// Images
import S800PImage from "../../../../../../assets/neuheiten-2024/abb/s800p/s800p.webp";
import BetterImage from "../../../../../../components/BetterImage";

const itemlist = [
  {
    title: "Hohe Leistungsfähigkeit",
    content: (
      <QBodyText>
        Schaltleistung bis zu 50 kA und Nennstrom bis zu 125 A. Die erste Wahl
        für industrielle Anwendungen in der Schwerindustrie weltweit.
      </QBodyText>
    ),
  },
  {
    title: "Kompaktlösung",
    content: (
      <QBodyText>
        S800P ist das kompakteste Gerät, das Leitungen bis zu 50 kA schützen
        kann. Mit 1,5 Modulen pro Pol, 95 mm Höhe und 82,5 mm Tiefe besteht die
        Möglichkeit, das Gerät auf der DIN-Schiene zu montieren, ohne dass ein
        Adapter erforderlich ist.
      </QBodyText>
    ),
  },
  {
    title: "Ausbaufähigkeit",
    content: (
      <QBodyText>
        Es besteht die Möglichkeit zur Aufrüstung Ihrer Anwendungen mit einer
        breiten Palette an verfügbarem Zubehör.
      </QBodyText>
    ),
  },
  {
    title: "Weltweit zertifiziert",
    content: (
      <Box>
        <QBodyText>
          Zertifiziert nach den wichtigsten globalen und spezifischen
          einschlägigen Normen
        </QBodyText>
        <QList
          items={[
            "IEC60947-2",
            "EN45545 für Feuer und Rauch",
            "IEC61373 für Vibration und Schock",
          ]}
        />
      </Box>
    ),
  },
];
function Hochleistungssicherungsautomat() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            colWidth={12}
            title="Hochleistungs-Sicherungsautomat S800P"
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Der S800P ist die richtige Lösung, wenn sowohl Größe als auch
                Leistung gefragt sind. Dank seiner Eigenschaften und zusammen
                mit einer breiten Palette an verfügbarem Zubehör ist der
                Hochleistungs-Sicherungsautomat (HPCB) eine geeignete Lösung für
                verschiedene Arten von Anwendungen, einschließlich Bahn,
                Rechenzentrum, Telekommunikation, erneuerbare Energien und
                allgemeine Industrieanlagen.
              </QBodyText>
              <QAccordion items={itemlist} />
            </Grid>
            <Grid item xs={6}>
              <Box
                height="63dvh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <BetterImage
                  src={S800PImage}
                  height="28dvh"
                  alt="Hochleistungs-Sicherungsautomat S800P"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default Hochleistungssicherungsautomat;
