import { h } from "preact";

import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import QActionPaper from "../../QActionPaper";
import QTextFieldAction from "../../QTextFieldAction";
import { getJahresgespraech2024Data, setJG24Notes } from "../../../redux/modules/chapterJahresgespraech2024";

export default function Jahresgespraech2024Page({ context }) {
  const dispatch = useDispatch();

  const { notes: notesData } = useSelector(getJahresgespraech2024Data);
  
  const notes = notesData.value;

  const setSetupField = (valueSet) => {
    dispatch(setJG24Notes(valueSet.value));
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <QActionPaper title="Notizen" context={context}>
          <QTextFieldAction
            value={notes}
            valueID="notes"
            setSetupField={setSetupField}
            rowsMin="8"
          />
        </QActionPaper>
      </Grid>
    </Grid>
  );
}
