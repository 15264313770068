// Images Schulung
import ImageSchulung1 from "../../assets/free-at-home/Schulung/techniktalk.webp";
import ImageSchulung2 from "../../assets/comfortline/3.webp";
import ImageSchulung3 from "../../assets/comfortline/2.webp";
import ImageSchulung4 from "../../assets/comfortline/1.webp";
import ImageSchulung5 from "../../assets/comfortline/5.webp";
import ImageSchulung6 from "../../assets/comfortline/comfortLine.webp";

// Images Marketing
import ImageMarketing1 from "../../assets/comfortline/02-flyer-titel.webp";
import ImageMarketing2 from "../../assets/comfortline/03-katalog.webp";
import ImageMarketing3 from "../../assets/comfortline/04-hauptkatalog.webp";
import ImageMarketing4 from "../../assets/comfortline/05-flyer.webp";
import ImageMarketing5 from "../../assets/comfortline/06.webp";

export const comfortlineSchulungItems = {
  title: "Schulung",
  items: [
    {
      title: "Technik-Talk",
      img: ImageSchulung1,
      link: {
        href: "https://search.abb.com/library/Download.aspx?DocumentID=9AKK107680A2660&LanguageCode=de&DocumentPartId=&Action=Launch",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          Schulung
          <br />
          Produktvorstellung
        </>
      ),
      img: ImageSchulung2,
      link: {
        href: "https://www.youtube.com/watch?v=FsapTfie6VM",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          Schulung
          <br />
          ComfortLine
        </>
      ),
      img: ImageSchulung3,
      link: {
        href: "https://www.youtube.com/watch?v=mPArvDOBpBY",
        target: "_blank",
      },
    },
    {
      title: "Software",
      img: ImageSchulung4,
      link: {
        href: "https://new.abb.com/low-voltage/de/support/software-und-anwendungen/e-design",
        target: "_blank",
      },
    },
    {
      title: "Produktvorstellung (Video)",
      img: ImageSchulung5,
      link: {
        href: "https://www.youtube.com/watch?v=pzOV_zMjacY#",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          Striebel und John
          <br />
          ComfortLine
        </>
      ),
      img: ImageSchulung6,
      link: {
        href: "https://www.striebelundjohn.com/comfortline",
        target: "_blank",
      },
    },
  ],
};

export const comfortlineMarketingItems = {
  title: "Marketing",
  items: [
    {
      title: "Broschüre",
      img: ImageMarketing1,
      href: "https://search.abb.com/library/Download.aspx?DocumentID=2CKA000001A1744&LanguageCode=de&DocumentPartId=&Action=Launch",
      target: "_blank",
    },
    {
      title: "Katalog",
      img: ImageMarketing2,
      href: "https://search.abb.com/library/Download.aspx?DocumentID=2CKA000001A1743&LanguageCode=de&DocumentPartId=&Action=Launch",
      target: "_blank",
    },
    {
      title: "Hauptkatalog",
      img: ImageMarketing3,
      href: "http://search.abb.com/library/Download.aspx?DocumentID=2CPC000002C0101&LanguageCode=de&DocumentPartId=1&Action=Launch",
      target: "_blank",
    },
    {
      title: "Flyer",
      img: ImageMarketing4,
      href: "https://search.abb.com/library/Download.aspx?DocumentID=2CKA000001A1842&LanguageCode=de&DocumentPartId=&Action=Launch",
      target: "_blank",
    },
    {
      title: (
        <>
          Online Katalog
          <br />
          Striebel & John
        </>
      ),
      img: ImageMarketing5,
      href: "https://www.striebelundjohn.com/produkte/zaehlerschraenkewandschraenke-comfortline-ab/komplettschraenke",
      target: "_blank",
    },
  ],
};
