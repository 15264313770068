import { chapters } from "../../../static/data";
import ChapterWrapper from "../../../components/ChapterWrapper";
import Unterstuetzung from "../../../components/Unterstuetzung";

import Intro from "./sections/Intro";
import BJEOverview from "./sections/BJEOverview";
import ABBOverview from "./sections/ABBOverview";
import StriebelOverview from "./sections/StriebelOverview";
import { Helmet } from "react-helmet";

export default function Neuheiten2024() {
  const chapterData = chapters.neuheiten2024;
  return (
    <>
      <Helmet>
        <title>ADM Playbook | Neuheiten 2024</title>
      </Helmet>
      <ChapterWrapper chapterData={chapterData}>
        <Intro anchor="intro" />
        <ABBOverview anchor="abb" />
        <BJEOverview anchor="busch-jaeger" />
        <StriebelOverview anchor="striebel-john" />
        <Unterstuetzung
          anchor="unterstuetzung"
          headline="Neuheiten 2024"
          items={chapters.neuheiten2024.unterstuetzung}
        />
      </ChapterWrapper>
    </>
  );
}
