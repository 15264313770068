import {h} from 'preact'

import {useDispatch, useSelector} from 'react-redux'
import {Grid} from '@material-ui/core'
import QIncrementGroup from '../../QIncrementGroup'
import QActionPaper from '../../QActionPaper'
import QTextFieldAction from '../../QTextFieldAction'
import {
  getEnergieMonitoringData,
  setEnergieMonitoringNotes,
  setAsset as setDispatchAsset,
} from '../../../redux/modules/chapterEnergiemontoring'
import QActionCheckbox from '../../QActionCheckbox'

export default function EnergieMonitoringPage({context}) {
  const dispatch = useDispatch()

  const {
    notes: notesData,
    assetElektroSpicker: assetElektroSpickerData,
    assetUnterverteilung: assetUnterverteilungData,
    assetB23_312: assetB23_312Data,
  } = useSelector(getEnergieMonitoringData)

  const notes = notesData.value
  const assetElektroSpicker = assetElektroSpickerData.value
  const assetUnterverteilung = assetUnterverteilungData.value
  const assetB23_312 = assetB23_312Data.value

  const setSetupField = valueSet => {
    switch (valueSet.id) {
      case 'notes':
        {
          dispatch(setEnergieMonitoringNotes(valueSet.value))
        }
        break
    }
  }

  const setAsset = (id, value) => {
    dispatch(setDispatchAsset(id, value))
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <QActionPaper title="Werbung" context={context}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetElektroSpicker}
                valueID="assetElektroSpicker"
                label={assetElektroSpickerData.description}
                mode={1}
                setAsset={setAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetUnterverteilung}
                valueID="assetUnterverteilung"
                label={assetUnterverteilungData.description}
                mode={1}
                setAsset={setAsset}
              />
            </Grid>
          </Grid>
        </QActionPaper>
      </Grid>
      <Grid item>
        <QActionPaper title="Notizen" context={context}>
          <QTextFieldAction
            value={notes}
            valueID="notes"
            setSetupField={setSetupField}
          />
        </QActionPaper>
      </Grid>
    </Grid>
  )
}
