import { Box, Grid } from "@material-ui/core";
import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import ImageComponent from "../../../../../../components/ImageComponent";

// Image
import TriLineImage from "../../../../../../assets/neuheiten-2024/suj/TriLine/TriLine.webp";
import BetterImage from "../../../../../../components/BetterImage";

export default function TriLine() {
  return (
    <Slide
      background
      actions={[
        {
          type: "icon",
          link: "https://www.striebelundjohn.com/service-und-downloads/news/triline-c-reihenschaltschraenke",
          target: "_blank",
          img: "laptop",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="TriLine C Reihenschaltschränke" />
        </Grid>
        <Grid item container>
          <Grid item xs={6}>
            <QList
              headline="Eigenschaften"
              items={[
                "Reihenschaltschrank-Gerüst",
                "Volle Zugängigkeit von allen Seiten",
                "Beplankungsteile individuell anpassbar",
                "Bis zu IP54",
                "Individueller Ausbau mit CombiLine, Montageplatte oder Traversensystem",
              ]}
              dense
            />
            <QList
              headline="Neu in 2024"
              items={[
                "Anpassung an das bekannte TwinLine System",
                "Deck- und Bodenbleche für TwinLine-Flansche verfügbar",
                "Schlossteile analog TwinLine",
                "Designanpassungen von Sockel und Glastüren",
              ]}
              dense
            />
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <BetterImage
                align="center"
                height="auto"
                width="60%"
                src={TriLineImage}
                alt="TriLine C Reihenschaltschränke"
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
