import { Grid, Typography } from "@material-ui/core";
import Slide from "../../../../../../../components/Slide";
import QHeader from "../../../../../../../components/QHeader";
import ChapterCard from "../../../../../../../components/ChapterCard";

// Images
import Image1 from "../../../../../../../assets/abbknx/Anwendungen/ClimaEco/musterplanung/standverteiler-1.webp";
import Image2 from "../../../../../../../assets/abbknx/Anwendungen/ClimaEco/musterplanung/standverteiler-2.webp";
import Image3 from "../../../../../../../assets/abbknx/Anwendungen/ClimaEco/musterplanung/msr-schrank.webp";
import Image4 from "../../../../../../../assets/abbknx/Anwendungen/ClimaEco/musterplanung/abb-verteilungsstueckliste.webp";
import Image5 from "../../../../../../../assets/abbknx/Anwendungen/ClimaEco/musterplanung/busch-jaeger-verteilungsstueckliste.webp";
import Image6 from "../../../../../../../assets/abbknx/Anwendungen/ClimaEco/musterplanung/funktionsbeschreibung.webp";

import Image7 from "../../../../../../../assets/abbknx/Anwendungen/ClimaEco/musterplanung/x81.webp";
import Image8 from "../../../../../../../assets/abbknx/Anwendungen/ClimaEco/musterplanung/x83.webp";
import Image9 from "../../../../../../../assets/abbknx/Anwendungen/ClimaEco/musterplanung/e-design.webp";

export default function Musterplanung() {
  const beispielItems = [
    {
      title: "Standverteiler UV-Elt 1",
      img: Image1,
      pdf: "../../../../../../../assets/pdf/knx/ClimaECO-1/Musterplanung/Beispiele/Musterplanung ClimaECO Bürogebäude_UV Elt 1_CAD.pdf",
    },
    {
      title: "Standverteiler UV-Elt 2",
      img: Image2,
      pdf: "../../../../../../../assets/pdf/knx/ClimaECO-1/Musterplanung/Beispiele/Musterplanung ClimaECO Bürogebäude_UV Elt 2_CAD.pdf",
    },
    {
      title: "MSR Schrank",
      img: Image3,
      pdf: "../../../../../../../assets/pdf/knx/ClimaECO-1/Musterplanung/Beispiele/Musterplanung ClimaECO Bürogebäude_MSR Schrank_CAD.pdf",
    },
    {
      title: (
        <>
          ABB
          <br />
          Verteilungsstückliste
        </>
      ),
      img: Image4,
      pdf: "../../../../../../../assets/pdf/knx/ClimaECO-1/Musterplanung/Beispiele/Verteilungsstückliste.pdf",
    },
    {
      title: (
        <>
          Busch-Jaeger
          <br />
          Bürogebäude ClimaECO
        </>
      ),
      img: Image5,
      pdf: "../../../../../../../assets/pdf/knx/ClimaECO-1/Musterplanung/Beispiele/Bürogebäude ClimaECO.pdf",
    },
    {
      placeholder: true,
    },
    {
      title: "Funktionsbeschreibung",
      img: Image6,
      pdf: "../../../../../../../assets/pdf/knx/ClimaECO-1/Musterplanung/Beispiele/Funktionsbeschreibung ClimaECO.pdf",
    },
  ];
  const arbeitsdateienItems = [
    {
      title: (
        <>
          Bürogebäude mit
          <br />
          ClimaECO (.x81)
        </>
      ),
      img: Image7,
      link: {
        href: "../../../../../../../assets/pdf/knx/ClimaECO-1/Musterplanung/Arbeitsdateien/Bürogebäude mit ClimaECO.x81",
        target: "_blank",
        download: true,
      },
    },
    {
      title: (
        <>
          Bürogebäude mit
          <br />
          ClimaECO (.x83)
        </>
      ),
      img: Image8,
      link: {
        href: "../../../../../../../assets/pdf/knx/ClimaECO-1/Musterplanung/Arbeitsdateien/Bürogebäude mit ClimaECO.x83",
        target: "_blank",
        download: true,
      },
    },
    {
      title: (
        <>
          Musterplanung
          <br />
          ClimaECO (.e-design)
        </>
      ),
      img: Image9,
      link: {
        href: "../../../../../../../assets/pdf/knx/ClimaECO-1/Musterplanung/Arbeitsdateien/Musterplanung ClimaECO Bürogebäude.e-design",
        target: "_blank",
        download: true,
      },
    },
  ];
  return (
    <>
      <Slide
        background
        actions={[
          {
            type: "button",
            label: "alles herunterladen (.zip)",
            link: {
              href: "../../../../../../../assets/pdf/knx/ClimaECO-1/Musterplanung/Musterplanung-ClimaECO.zip",
              target: "_blank",
              download: true,
            },
          },
        ]}
      >
        <Grid container direction="column" spacing={2} wrap="nowrap">
          <QHeader title="Musterplanung" />
          <Grid container spacing={0}>
            <Grid item xs={5} lg={7} xl={4}>
              <Typography variant="h5">Beispiele</Typography>

              <Grid container spacing={2}>
                {beispielItems.map((e, i) => {
                  return (
                    <Grid item xs={4}>
                      <ChapterCard noDialog chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={3} lg={2} xl={4}></Grid>
            <Grid item xs={4} lg={3} xl={4}>
              <Typography variant="h5">Arbeitsdateien</Typography>

              <Grid container spacing={2}>
                {arbeitsdateienItems.map((e, i) => {
                  return (
                    <Grid item xs={6} lg={8} xl={6}>
                      <ChapterCard noDialog chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </>
  );
}
