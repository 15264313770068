import { h } from "preact";
import {
  Button,
  ButtonGroup,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    "& button": {
      borderColor: "transparent",
      fontSize: "18px",
    },
  },
  label: {
    fontSize: "18px",
  },
}));

export default function QIncrementGroup({
  value,
  valueID,
  mode,
  label,
  setAsset,
}) {
  const classes = useStyles();

  const decrease = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (value <= 0) {
      setAsset(valueID, 0);
    } else {
      setAsset(
        valueID,
        value === 1 ? value - 1 : value === 5 ? 1 : value - mode
      );
    }
  };

  const increase = (event) => {
    event.stopPropagation();
    event.preventDefault();

    setAsset(
      valueID,
      value === 1 && mode !== 1 ? value + mode - 1 : value + mode
    );
  };

  const reset = (event) => {
    event.stopPropagation();
    event.preventDefault();

    setAsset(valueID, 0);
  };

  return (
    <Grid container alignItems="center" spacing={2} wrap="nowrap">
      <Grid item>
        <ButtonGroup className={classes.root} color="primary" id={valueID}>
          <Button
            onTouchStart={decrease}
            onClick={decrease}
            className={`stepperField decrease`}
          >
            -
          </Button>
          <Button
            onTouchStart={reset}
            onClick={reset}
            className={`stepperField valueField`}
          >
            {value}
          </Button>
          <Button
            onTouchStart={increase}
            onClick={increase}
            className={`stepperField increase`}
          >
            +
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item>
        <Typography className={classes.label}>{label}</Typography>
      </Grid>
    </Grid>
  );
}
