import { h } from "preact";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";

const useStyles = makeStyles({
  listItem: {
    fontSize: (props) => props.fontSize,
  },
  headline: {
    fontFamily: "Averta-Bold",
    marginBottom: "0",
    fontSize: "clamp(1.04rem, 1.5vw, 1.5rem)",
  },
  liDisc: {
    "&::marker": {
      fontSize: "1.4em",
    },
  },
  liNumber: {
    "&::marker": {
      fontSize: "1em",
      fontWeight: "bold",
    },
  },
  liArrow: {
    "&::marker": {
      fontSize: ".8em",
      fontWeight: "bold",
    },
  },
});
export default function QList({
  items,
  variant,
  dense,
  divider,
  headline,
  fontSize,
  color,
  headlineColor,
  paddingLeft,
  hyphens,
  subItem,
  marginBottom,
  marginTop,
}) {
  const classes = useStyles({ fontSize });

  function GenerateListItem(item, i) {
    if (Array.isArray(item)) {
      return <QList items={item} subItem={true} dense={dense} />;
    }
    return (
      <li
        alignItems="flex-start"
        key={`listItem-${i}`}
        className={variant === "disc" ? classes.liDisc : variant === "arrow" ? classes.liArrow : classes.liNumber}
        style={{
          color: color !== undefined ? color : "black",
          hyphens: hyphens ? "auto" : "initial",
          paddingBottom: (subItem ? 0 : 4) + (dense ? 0 : 8) + "px",
          marginBottom: subItem ? "0px" : "0px",
        }}
      >
        {item}
      </li>
    );
  }

  const listItems = items.map((item, i) => [
    GenerateListItem(item, i),
    divider && i !== items.length - 1 && <Divider key={`divider-${i}`} />,
  ]);

  if (paddingLeft) {
    if (subItem) {
      paddingLeft = "4rem";
    } else {
      paddingLeft = "2rem";
    }
  } else {
    if (subItem) {
      paddingLeft = "2rem";
    } else {
      paddingLeft = "5px";
    }
  }

  return (
    <>
      {headline && (
        <p
          style={{ color: headlineColor !== undefined ? headlineColor : "black", marginTop: marginTop || ".5em" }}
          className={classes.headline}
        >
          {headline}
        </p>
      )}
      <ul
        style={{
          fontSize: fontSize === "small" ? "clamp(.5rem, 1.4vw, 1.2rem)" : "clamp(1.04rem, 1.5vw, 1.5rem)",
          paddingLeft: paddingLeft,
          paddingTop: subItem ? "0px" : "4px",
          marginTop: subItem ? "-6px" : "0px",
          marginBottom: marginBottom ? marginBottom : "14px",
          listStyleType: variant,
          listStyleImage: variant !== "arrow" ? "inital" : `url("data:image/svg+xml,%3Csvg width='.65em' height='.65em' viewBox='0 0 187 249' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Smarter-Mobility' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-8381.000000, -513.000000)' fill='%23000000'%3E%3Cg id='arrow' transform='translate(8381.000000, 513.000000)'%3E%3Crect id='Rectangle' fill-opacity='0' x='0' y='0' width='186' height='249'%3E%3C/rect%3E%3Cg id='»' transform='translate(0.162598, 0.503418)' fill-rule='nonzero'%3E%3Cpath d='M0.640136719,188.200195 L49.5039062,188.200195 L102.421875,94.9536133 L48.6503906,0 L0,0 L53.9848633,94.9536133 L0.640136719,188.200195 Z M84.0712891,188.200195 L132.935059,188.200195 L185.853027,94.9536133 L132.081543,0 L83.4311523,0 L137.416016,94.9536133 L84.0712891,188.200195 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        }}
      >
        {listItems}
      </ul>
    </>
  );
}

QList.defaultProps = {
  items: [],
  variant: "disc",
  dense: true,
  headline: "",
  divider: false,
  fontSize: "regular",
  lineHeight: "1.5",
  subItem: false,
  paddingLeft: true,
};
