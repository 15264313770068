const SET_NOTES = "playbook/abbSmarterMobility/SET_NOTES";

export const initialState = {
  notes: {
    description: "Notizen für das Kapitel",
    value: "",
    type: "info",
    orderID: null,
  },
};

const abbSmarterMobility = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTES: {
      return {
        ...state,
        notes: {
          ...state.notes,
          value: action.notes,
        },
      };
    }
    default:
      return state;
  }
};

//actions
export const setAbbSmarterMobilityNotes = (notes) => ({
  type: SET_NOTES,
  notes,
});

//selector
export const getAbbSmarterMobilityNotes = (state) => state.abbSmarterMobility.notes;
export const getAbbSmarterMobilityData = (state) => state.abbSmarterMobility;

export default abbSmarterMobility;
