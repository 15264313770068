import { useState } from "preact/hooks";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Markdown from "preact-markdown";
import { Box, Divider } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowLeft } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    "& .MuiListItemText-multiline": {
      display: "flex",
      flexDirection: "column-reverse",
    },
    "& .MuiListItemText-secondary": {
      fontSize: 14,
      color: "#000",
    },
    "& .MuiListItemText-primary": {
      fontSize: 18,
      color: "#000",
    },
    "& .MuiListItem-button": {
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  titleNews: {
    color: "black",
    paddingLeft: "10px",
    fontSize: "20px",
  },
  date: {
    fontSize: ".9rem",
  },
  open: {},
}));

export default function NewsCollapse({ data }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const dateToString = (date) =>
    `${`0${date.getDate()}`.slice(-2)}.${`0${date.getMonth() + 1}`.slice(
      -2
    )}.${date.getFullYear()}`;
  const strDate = dateToString(new Date(data.timestamp));

  return (
    <List className={`${classes.root} ${open ? classes.open : ""}`}>
      <ListItem button onClick={handleClick}>
        <ListItemText secondary={strDate} primary={data.title} />
        {open ? <KeyboardArrowDown /> : <KeyboardArrowLeft />}
      </ListItem>
      <Collapse in={open} timeout="200" unmountOnExit>
        <Box p="12px" marginTop="-1em" marginBottom="1em">
          <Markdown markdown={data.msg} />
        </Box>
      </Collapse>
      <Divider />
    </List>
  );
}
