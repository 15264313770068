import { useState, useEffect } from "preact/hooks";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { chapters } from "../../../static/data";
import { useSelector, useStore } from "react-redux";
import { getCategoriesData } from "../../../redux/modules/categoriesReducer";
import { getActiveChapter } from "../../../redux/modules/consultingReducer";
import Notes from "./Notes";
import { getSetupData } from "../../../redux/modules/setupReducer";
import * as R from "ramda";
import CheckIcon from "@material-ui/icons/Check";
import { Box, Button, Grid, ListItemSecondaryAction } from "@material-ui/core";
import QDialog from "../../QDialog";
import SetupPage from "../Setup/SetupPage";
import { useModal } from "mui-modal-provider";
import FilledArrowLeft from "../../SvgIcons/FilledArrowLeft";
import FilledArrowRight from "../../SvgIcons/FilledArrowRight";
import clsx from "clsx";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "var(--grey-10)",
  },
  tabPanel: {
    height: "100%",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: "var(--grey-10)",
    width: `calc(100% - ${drawerWidth}px)`,
    height: "100vh",
    marginLeft: drawerWidth,
  },
  contentClosed: {
    flexGrow: 1,
    backgroundColor: "var(--grey-10)",
    height: "100vh",
    marginLeft: 25,
    width: `calc(100% - 25)`,
  },
  activeChapter: {
    "& span": {
      fontFamily: "Averta-Bold",
    },
  },
  green: {
    "& span": {
      color: theme.palette.success.main,
    },
  },
  drawerPaper: {
    top: "auto",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    top: "auto",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 20,
  },
  drawerButton: {
    position: "fixed",
    zIndex: 9999,
    top: "10%",
  },
  openDrawer: {
    position: "fixed",
    left: drawerWidth - 20,
  },
  closedDrawer: {
    position: "fixed",
    left: 0,
  },
  listItem: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.grey[200],
      transition: theme.transitions.create("background-color", {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.short,
      }),
    },
  },
}));

export default function ConsultingPage({ show }) {
  const classes = useStyles();
  const [value, setValue] = useState("welcome");
  const [open, setOpen] = useState(true);
  const [showConsultingEdit, setShowConsultingEdit] = useState(false);
  const store = useStore();
  const customerData = useSelector((state) => getSetupData(state));
  const { showModal } = useModal();

  const activeChapterID = useSelector((state) => getActiveChapter(state));
  const categoriesData = useSelector((state) => getCategoriesData(state));

  const chaptersToFilterOut = [9, 10];

  const categories = Object.keys(categoriesData.categories).filter(
    (k) =>
      categoriesData.categories[k] &&
      !chaptersToFilterOut.includes(chapters[k].chapterID)
  );

  useEffect(() => {
    if (activeChapterID !== null) {
      categories.forEach((chapter) => {
        if (activeChapterID === chapters[chapter].chapterID) {
          setValue(chapters[chapter].slug);
        }
      });
    } else {
      setValue(chapters[categories[0]].slug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setChapter = (e, value) => {
    setValue(value);
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  let tabs = [];

  categories.forEach((category) => {
    let active = false;
    let diffrences = 0;
    if (
      Object.prototype.hasOwnProperty.call(chapters[category], "getData") &&
      chapters[category] !== null
    ) {
      const data = chapters[category].getData(store.getState());
      if (!R.equals(chapters[category].initialState, data)) {
        active = true;
        Object.keys(chapters[category].initialState).forEach((entry) => {
          if (
            Object.prototype.hasOwnProperty.call(
              chapters[category].initialState[entry],
              "type"
            ) &&
            (chapters[category].initialState[entry].type === "order" ||
              typeof chapters[category].initialState[entry].value ===
                "number" ||
              typeof chapters[category].initialState[entry].value ===
                "boolean") &&
            chapters[category].initialState[entry].value !== data[entry].value
          ) {
            diffrences++;
          }
        });
      }
    }
    tabs.push(
      <ListItem
        className={classes.listItem}
        key={chapters[category].slug}
        value={chapters[category].slug}
        {...a11yProps(chapters[category].slug)}
        onClick={(event) => setChapter(event, chapters[category].slug)}
      >
        <ListItemText
          primary={chapters[category].label}
          secondary={
            active &&
            diffrences !== 0 &&
            `${diffrences} ${diffrences === 1 ? "Bestellung" : "Bestellungen"}`
          }
          className={clsx(
            value === chapters[category].slug ? classes.activeChapter : {},
            diffrences !== 0 ? classes.green : {}
          )}
        />
      </ListItem>
    );
  });

  let tabPanels = categories.map((category) => (
    <TabPanel
      key={`tabPanel-${category}`}
      value={value}
      index={chapters[category].slug}
    >
      <div className={classes.tabPanel}>{chapters[category].actionPage}</div>
    </TabPanel>
  ));

  tabs.push(<Divider variant="body2" component="li" />);

  //notes
  tabs.push(
    <ListItem
      key="notizen"
      value="notizen"
      {...a11yProps("notizen")}
      onClick={(event) => setChapter(event, "notizen")}
    >
      <ListItemText
        primary="Notizen"
        className={value === "notizen" ? classes.activeChapter : ""}
      />
    </ListItem>
  );

  tabPanels.push(
    <TabPanel value={value} index="notizen">
      <div className={classes.tabPanel}>
        <Notes />
      </div>
    </TabPanel>
  );

  const openSetupPage = (e, customer) => {
    if (customer) {
      const d = showModal(QDialog, {
        children: (
          <Box width="100vw" height="100vh">
            <Box p={2}>
              <SetupPage
                id={customer.id}
                company={customer.company}
                salutation={customer.salutation}
                firstname={customer.firstname}
                lastname={customer.lastname}
                email={customer.email}
                callback={customer.callback}
                handleDialogClose={() => d.hide()}
                updateCustomer
              />
            </Box>
          </Box>
        ),
        onConfirm: () => {
          d.hide();
        },
        onCancel: () => {
          d.hide();
        },
      });
    } else {
      const d = showModal(QDialog, {
        children: (
          <Box width="100vw" height="100vh">
            <Box p={2}>
              <SetupPage />
            </Box>
          </Box>
        ),
        onConfirm: () => {
          d.hide();
        },
        onCancel: () => {
          d.hide();
        },
      });
    }
  };
  const toggleShowConsultingEdit = () => {
    setShowConsultingEdit((v) => !v);
  };

  return (
    <Grid container alignItems="flex-start" xs={{ height: "100%" }}>
      {show ? (
        <SetupPage
          id={customerData.id}
          company={customerData.company}
          salutation={customerData.salutation}
          firstname={customerData.firstname}
          lastname={customerData.lastname}
          email={customerData.email}
          callback={customerData.callback}
          updateCustomer
        />
      ) : (
        <>
          {open && (
            <Grid item xs={3}>
              <List
                dense
                style={{
                  height: "calc(100vh - 16rem)",
                  overflow: "scroll",
                  position: "sticky",
                  top: 48,
                }}
              >
                {tabs}
              </List>
            </Grid>
          )}
          <Grid
            item
            xs={open ? 9 : 12}
            style={{
              height: "calc(100vh - 16rem)",
              overflowX: "hidden",
              overflowY: "scroll",
            }}
          >
            <Box
              onClick={toggleOpen}
              position="absolute"
              sx={{ transform: "translateX(-1em) translateY(1em)", cursor: "pointer" }}
            >
              {open ? <FilledArrowLeft /> : <FilledArrowRight />}
            </Box>
            {tabPanels}
          </Grid>
        </>
      )}
    </Grid>
  );
}
