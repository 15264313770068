import {h} from 'preact'

import {useDispatch, useSelector} from 'react-redux'
import {Grid} from '@material-ui/core'
import QIncrementGroup from '../../QIncrementGroup'
import QActionPaper from '../../QActionPaper'
import QDateAction from '../../QDateAction'
import QTextFieldAction from '../../QTextFieldAction'
import {
  setSeminarAppointment,
  setUk600AK600Notes,
  setSeminarNote,
  setAsset as setDispatchAsset,
  getUK600AK600Data,
} from '../../../redux/modules/chapterUk600AK600'

export default function Uk600AK600Page({context}) {
  const dispatch = useDispatch()

  const {
    seminarAppointment: seminarAppointmentData,
    notes: notesData,
    seminarNote: seminarNoteData,
    assetUnterputzStromkreisverteiler: assetUnterputzStromkreisverteilerData,
    assetAbbPoster: assetAbbPosterData,
    assetBroschuereUk600: assetBroschuereUk600Data,
    assetRegalpraesemterUk600: assetRegalpraesemterUk600Data,
  } = useSelector(getUK600AK600Data)

  const seminarAppointment = seminarAppointmentData.value
  const notes = notesData.value
  const seminarNote = seminarNoteData.value
  const assetUnterputzStromkreisverteiler =
    assetUnterputzStromkreisverteilerData.value
  const assetAbbPoster = assetAbbPosterData.value
  const assetBroschuereUk600 = assetBroschuereUk600Data.value
  const assetRegalpraesemterUk600 = assetRegalpraesemterUk600Data.value

  const setSetupField = valueSet => {
    switch (valueSet.id) {
      case 'seminarNote':
        {
          dispatch(setSeminarNote(valueSet.value))
        }
        break
      case 'seminarAppointment':
        {
          dispatch(setSeminarAppointment(valueSet.value))
        }
        break
      case 'notes':
        {
          dispatch(setUk600AK600Notes(valueSet.value))
        }
        break
    }
  }

  const setAsset = (id, value) => {
    dispatch(setDispatchAsset(id, value))
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <QActionPaper title="Seminare" context={context}>
          <QDateAction
            date={seminarAppointment}
            dateID="seminarAppointment"
            note={seminarNote}
            noteID="seminarNote"
            setSetupField={setSetupField}
          />
        </QActionPaper>
      </Grid>
      <Grid item>
        <QActionPaper title="Werbung" context={context}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetUnterputzStromkreisverteiler}
                valueID="assetUnterputzStromkreisverteiler"
                label={assetUnterputzStromkreisverteilerData.description}
                mode={1}
                setAsset={setAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetAbbPoster}
                valueID="assetAbbPoster"
                label={assetAbbPosterData.description}
                mode={1}
                setAsset={setAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetBroschuereUk600}
                valueID="assetBroschuereUk600"
                label={assetBroschuereUk600Data.description}
                mode={1}
                setAsset={setAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetRegalpraesemterUk600}
                valueID="assetRegalpraesemterUk600"
                label={assetRegalpraesemterUk600Data.description}
                mode={1}
                setAsset={setAsset}
              />
            </Grid>
          </Grid>
        </QActionPaper>
      </Grid>
      <Grid item>
        <QActionPaper title="Notizen" context={context}>
          <QTextFieldAction
            value={notes}
            valueID="notes"
            setSetupField={setSetupField}
          />
        </QActionPaper>
      </Grid>
    </Grid>
  )
}
