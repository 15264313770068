import { h } from "preact";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function FilledHome(props) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_23)">
          <path
            d="M22 44C9.84974 44 0 34.1503 0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44Z"
            fill="black"
          />
          <path
            d="M31 22V32H25V26H19V32H13V22H10L22 10L34 22H31ZM30 16.093V11H27V13.093L30 16.093Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_23">
            <rect width="44" height="44" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
