// Components
import { Box, Grid } from "@material-ui/core";
import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import ChapterCard from "../../../../../../components/ChapterCard";

// Anwendungen
import EinfamilienhausAudio from "./EinfamilienhausAudio";
import EinfamilienhausVideo from "./EinfamilienhausVideo";
import AnbindungSmartphone from "./AnbindungSmartphone";
import EinfamilienhausSmartphone from "./EinfamilienhausSmartphone";
import MehrfamilienhausAudio from "./MehrfamilienhausAudio";
import MehrfamilienhausVideo from "./MehrfamilienhausVideo";

// Images
import Image1 from "../../../../../../assets/welcome/2021/0206/anwendung1.webp"
import Image2 from "../../../../../../assets/welcome/2021/0206/anwendung2.webp"
import Image3 from "../../../../../../assets/welcome/2021/0206/anwendung3.webp"
import Image4 from "../../../../../../assets/welcome/2021/0206/anwendung4.webp"
import Image5 from "../../../../../../assets/welcome/2021/0206/anwendung5.webp"
import Image6 from "../../../../../../assets/welcome/2021/0206/anwendung6.webp"

export const welcomeApplications = [
  {
    title: "Einfamilienhaus Audio",
    img: Image1,
    link: {
      href: "/welcome#anwendungen/1",
      target: "_self",
    },
    menuOverlay: <EinfamilienhausAudio />,
  },
  {
    title: "Einfamilienhaus Video",
    img: Image2,
    link: {
      href: "/welcome#anwendungen/2",
      target: "_self",
    },
    menuOverlay: <EinfamilienhausVideo />,
  },
  {
    title: "Smartphoneanbindung Var. 1",
    img: Image3,
    link: {
      href: "/welcome#anwendungen/3",
      target: "_self",
    },
    menuOverlay: <AnbindungSmartphone />,
  },
  {
    title: "Smartphoneanbindung Var. 2",
    img: Image4,
    link: {
      href: "/welcome#anwendungen/4",
      target: "_self",
    },
    menuOverlay: <EinfamilienhausSmartphone />,
  },
  {
    title: "Mehrfamilienhaus Audio",
    img: Image5,
    link: {
      href: "/welcome#anwendungen/5",
      target: "_self",
    },
    menuOverlay: <MehrfamilienhausAudio />,
  },
  {
    title: "Mehrfamilienhaus Video",
    img: Image6,
    link: {
      href: "/welcome#anwendungen/6",
      target: "_self",
    },
    menuOverlay: <MehrfamilienhausVideo />,
  },
];

export default function Anwendungen() {
  return (
    <Slide
      background={{
        alignment: "left",
        width: "45%",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <QHeader
          title={
            <>
              Busch-Welcome<sup>®</sup>
            </>
          }
          subTitle="Anwendungen"
        />

        <Box px={10}>
          <Grid container spacing={2}>
            {welcomeApplications.map((e, i) => {
              return (
                <Grid item xs={4} lg={3} xl={2}>
                  <ChapterCard noDialog chapter={e} cardWidth="auto" />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Grid>
    </Slide>
  );
}
