import { h } from "preact";
import { useState } from "preact/hooks";
import { Box, Grid } from "@material-ui/core";

import Slide from "../../../../../../../components/Slide";
import QTabs from "../../../../../../../components/QTabs";
import QHeader from "../../../../../../../components/QHeader";
import QTable from "../../../../../../../components/QTable";
import ImageComponent from "../../../../../../../components/ImageComponent";
import PreisbeispielBuschTenton from "./dialog/PreisbeispielBuschTenton";
import PreisbeispielTasterBA from "./dialog/PreisbeispielTasterBA";
import PreisbeispielUniversalschnittstelle from "./dialog/PreisbeispielUniversalschnittstelle";
import PreisbeispielTastensensorSolo from "./dialog/PreisbeispielTastensensorSolo";

import tutImage from "../../../../../../../assets/abbknx/Anwendung_Loesung/tipps-und-tricks.webp";

export default function DasSystem() {
  const [activeTab, setActiveTab] = useState(0);
  const schaltplanImg =
    "../../../assets/abbknx/Anwendung_Loesung/schaltplan.svg";

  const qTabsCallback = (a) => {
    setActiveTab(a);
  };

  let actions;
  switch (activeTab) {
    case 0:
      {
        //Busch-Tenton
        actions = [
          {
            type: "pdf",
            img: "preisbeispiel",
            path: "../../../../../../../assets/pdf/preisbeispiele/KNX/Beleuchtungssteuerung/Preisbeispiel KNX - Busch-Tenton.pdf",
            label: "Preisbeispiel"
          },
          {
            id: "schaltplan",
            type: "galery",
            img: "info",
            label: "Schaltplan",
            images: [
              {
                src: schaltplanImg,
                alt: "Schaltplan",
              },
            ],
          },
          {
            id: "tut",
            type: "galery",
            img: "info",
            label: "Tipps und Tricks",
            images: [
              {
                src: tutImage,
                alt: "Tipps & Tricks",
              },
            ],
          },
        ];
      }
      break;
    case 1:
      {
        //Tasterankopplung
        actions = [
          {
            type: "pdf",
            img: "preisbeispiel",
            path: "../../../../../../../assets/pdf/preisbeispiele/KNX/Beleuchtungssteuerung/Preisbeispiel KNX - Taster BA.pdf",
            label: "Preisbeispiel"
          },
          {
            id: "schaltplan",
            type: "galery",
            img: "info",
            label: "Schaltplan",
            images: [
              {
                src: schaltplanImg,
                alt: "Schaltplan",
              },
            ],
          },
          {
            id: "tut",
            type: "galery",
            img: "info",
            label: "Tipps und Tricks",
            images: [
              {
                src: tutImage,
                alt: "Tipps & Tricks",
              },
            ],
          },
        ];
      }
      break;
    case 2:
      {
        //Universalschnittstelle
        actions = [
          {
            type: "pdf",
            img: "preisbeispiel",
            path: "../../../../../../../assets/pdf/preisbeispiele/KNX/Beleuchtungssteuerung/Preisbeispiel KNX - Binäreingang.pdf",
            label: "Preisbeispiel"
          },
          {
            id: "schaltplan",
            type: "galery",
            img: "info",
            label: "Schaltplan",
            images: [
              {
                src: schaltplanImg,
                alt: "Schaltplan",
              },
            ],
          },
          {
            id: "tut",
            type: "galery",
            img: "info",
            label: "Tipps und Tricks",
            images: [
              {
                src: tutImage,
                alt: "Tipps & Tricks",
              },
            ],
          },
        ];
      }
      break;
    case 3:
      {
        //Tastsensor solo
        actions = [
          {
            type: "pdf",
            img: "preisbeispiel",
            path: "../../../../../../../assets/pdf/preisbeispiele/KNX/Beleuchtungssteuerung/Preisbeispiel KNX - Tastensensor solo.pdf",
            label: "Preisbeispiel"
          },
          {
            id: "schaltplan",
            type: "galery",
            img: "info",
            label: "Schaltplan",
            images: [
              {
                src: schaltplanImg,
                alt: "Schaltplan",
              },
            ],
          },
          {
            id: "tut",
            type: "galery",
            img: "info",
            label: "Tipps und Tricks",
            images: [
              {
                src: tutImage,
                alt: "Tipps & Tricks",
              },
            ],
          },
        ];
      }
      break;
  }

  return (
    <Slide actions={actions}>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                ABB i-bus<sup>®</sup> KNX
              </>
            }
            subTitle="Das System"

          />
        </Grid>
        <Grid item container>
          <Grid item xs={12}>
            <QTabs
              callback={qTabsCallback}
              tabs={[
                {
                  head: "Busch-Tenton",
                  content: (
                    <>
                      <ImageComponent
                        src="../../../assets/abbknx/Anwendung_Loesung/neuheiten-2021/tenton.webp"
                        alt="tenton"
                        wrapperHeight="60vh"
                      />
                    </>
                  ),
                },
                {
                  head: "Tasterankopplung",
                  content: (
                    <>
                      <ImageComponent
                        src="../../../assets/abbknx/Anwendung_Loesung/neuheiten-2021/tasterankopplung.webp"
                        alt="tasterankopplung"
                        wrapperHeight="60vh"
                      />
                    </>
                  ),
                },
                {
                  head: "Universalschnittstelle",
                  content: (
                    <>
                      <ImageComponent
                        src="../../../assets/abbknx/Anwendung_Loesung/neuheiten-2021/universalschnittstelle.webp"
                        alt="universalschnittstelle"
                        wrapperHeight="60vh"
                      />
                    </>
                  ),
                },
                {
                  head: "Tastensensor solo",
                  content: (
                    <>
                      <ImageComponent
                        src="../../../assets/abbknx/Anwendung_Loesung/neuheiten-2021/tastsensor-solo.webp"
                        alt="tastsensor-solo"
                        wrapperHeight="60vh"
                      />
                    </>
                  ),
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
