import SechsWegeVenil from "./files/SechsWegeVentil.webp";
import Schaltaktor from "./files/Schaltaktor.webp";

export default function SechsWegeVentilSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="425"
      height="645"
      viewBox="0 0 425 645"
      fill="none"
    >
      <g id="sechs-wege-ventil">
        <g id="Group 2" clip-path="url(#clip0_26_1495)">
          <rect
            id="Image 1"
            x="81.468"
            width="342.906"
            height="447.333"
            fill="url(#patter-6-wege-ventil)"
          />
          <rect
            id="Image 1_2"
            y="358.459"
            width="276.702"
            height="285.879"
            fill="url(#patter-schaltaktor)"
          />
          <path
            id="Line"
            d="M102.205 163.677C102.205 163.677 1.90988 187.377 48.0464 270.802C81.468 331.237 57.1977 404.273 57.1977 404.273"
            stroke="#1B1B1D"
            stroke-width="6"
            stroke-linecap="round"
            stroke-dasharray="12 12"
          />
          <text
            id="0 - 10 V"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Bold"
            font-size="12"
            letter-spacing="0px"
          >
            <tspan x="73" y="213.898">
              0 - 10 V
            </tspan>
          </text>
        </g>
      </g>
      <defs>
        <pattern
          id="patter-6-wege-ventil"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_26_1495"
            transform="scale(0.00323625 0.00248139)"
          />
        </pattern>
        <pattern
          id="patter-schaltaktor"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image1_26_1495ooi"
            transform="scale(0.0026738 0.00259067)"
          />
        </pattern>
        <clipPath id="clip0_26_1495">
          <rect width="424.374" height="644.338" fill="white" />
        </clipPath>
        <image
          id="image0_26_1495"
          width="309"
          height="403"
          xlinkHref={SechsWegeVenil}
        />
        <image
          id="image1_26_1495ooi"
          width="374"
          height="386"
          xlinkHref={Schaltaktor}
        />
      </defs>
    </svg>
  );
}
