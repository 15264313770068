const SET_SEMINAR_APPOINTMENT = "playbook/freeathome/SET_SEMINAR_APPOINTMENT";
const SET_NOTES = "playbook/freeathome/SET_NOTES";
const SET_SEMINAR_NOTE = "playbook/freeathome/SET_SEMINAR_NOTE";
const SET_ASSET = "playbook/freeathome/SET_ASSET";

export const initialState = {
  seminarAppointment: {
    description: "Termin für das Seminar",
    value: null,
    type: "info",
    orderID: null,
  },
  assetSortimentsbroschüre: {
    description: "Sortimentsbroschüre",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1576",
  },
  assetSystemhandbuch: {
    description: "Systemhandbuch",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1632",
  },
  assetKomplettübersicht: {
    description: "Komplettübersicht",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1721",
  },
  assetFlorPostHeizkörperthermostate: {
    description: "Florpost Heizkörperthermostate",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1710",
  },
  assetVermarktungspaket: {
    description: "Vermarktungspaket",
    value: 0,
    type: "info",
    orderID: null,
  },
  assetWerbebanner: {
    description: "Werbebanner 18/1",
    value: 0,
    type: "info",
    orderID: null,
  },
  assetSondermustertafel: {
    description: "Sondermustertafel",
    value: false,
    type: "order",
    orderID: "2CKA000002A1390",
  },
  assetMusterkoffer: {
    description: "Musterkoffer",
    value: false,
    type: "order",
    orderID: "2CKA000002A1347",
  },
  assetFlorpostBJE: {
    description: "Florpost Busch-free@home",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1426",
  },
  seminarNote: {
    description: "Notizen für das Seminar",
    value: "",
    type: "info",
    orderID: null,
  },
  notes: {
    description: "Notizen für das Kapitel",
    value: "",
    type: "info",
    orderID: null,
  },
};

const freeathome = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEMINAR_APPOINTMENT: {
      return {
        ...state,
        seminarAppointment: {
          ...state.seminarAppointment,
          value: action.seminarAppointment,
        },
      };
    }
    case SET_NOTES: {
      return {
        ...state,
        notes: {
          ...state.notes,
          value: action.notes,
        },
      };
    }
    case SET_SEMINAR_NOTE: {
      return {
        ...state,
        seminarNote: {
          ...state.seminarNote,
          value: action.note,
        },
      };
    }
    case SET_ASSET: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          value: action.value,
        },
      };
    }
    default:
      return state;
  }
};

//actions
export const setSeminarAppointment = (seminarAppointment) => ({
  type: SET_SEMINAR_APPOINTMENT,
  seminarAppointment,
});

export const setAsset = (id, value) => ({
  type: SET_ASSET,
  id,
  value,
});

export const setFreeAtHomeNotes = (notes) => ({
  type: SET_NOTES,
  notes,
});

export const setSeminarNote = (note) => ({
  type: SET_SEMINAR_NOTE,
  note,
});

//selector
export const getSeminarAppointment = (state) =>
  state.freeathome.seminarAppointment;
export const getFreeAtHomeNotes = (state) => state.freeathome.notes;
export const getSeminarNote = (state) => state.freeathome.seminarNote;
export const getAssetSortimentsbroschüre = (state) =>
  state.freeathome.assetSortimentsbroschüre;
export const getAssetSystemhandbuch = (state) =>
  state.freeathome.assetSystemhandbuch;
export const getAssetKomplettübersicht = (state) =>
  state.freeathome.assetKomplettübersicht;
export const getAssetFlorPostHeizkörperthermostate = (state) =>
  state.freeathome.assetFlorPostHeizkörperthermostate;
export const getAssetVermarktungspaket = (state) =>
  state.freeathome.assetVermarktungspaket;
export const getAssetWerbebanner = (state) => state.freeathome.assetWerbebanner;
export const getAssetSonderustertafel = (state) =>
  state.freeathome.assetSondermustertafel;
export const getAssetMusterkoffer = (state) =>
  state.freeathome.assetMusterkoffer;
export const getFreeAtHomeData = (state) => state.freeathome;

export default freeathome;
