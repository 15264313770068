import { h, Component } from "preact";
import { forwardRef } from "preact/compat";
import { route } from "preact-router";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { Dots } from "react-activity";
import {
  fetchUserLogout,
  isLoggedIn,
} from "../../../redux/modules/userReducer";
import Cookies from "js-cookie";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import AddBox from "@material-ui/icons/AddBox";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import SearchIcon from "../../../components/SvgIcons/SearchIcon";
import ViewColumn from "@material-ui/icons/ViewColumn";
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  Box,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { toast } from "react-toastify";
import { loadConsultation } from "../../../redux/modules/reducers";
import { Offline, Online } from "react-detect-offline";
import {
  getConsultationStore,
  deletePausedConsultation,
} from "../../../redux/modules/consultationStore";
import {
  getConsultingStarted,
  setConsultingTopic,
  setConsultingHasStarted,
  setInternalID,
  getConsultingTopic,
  getConsultingInternalID,
  setConsultingHasStartedDate,
} from "../../../redux/modules/consultingReducer";
import { makeStyles } from "@material-ui/core/styles";
import OptionsForConsultation from "./Components/OptionsForConsultation";
import OptionsForPausedConsultation from "./Components/OptionsForPausedConsultation";
import {
  setPreparedConsultations,
  getPreparedConsultations,
} from "../../../redux/modules/preparedConsultations";
import {
  setEmail,
  setFirstname,
  setLastname,
  setCompany,
  getEmail,
  getCompany,
  setSalutation,
} from "../../../redux/modules/setupReducer";
import APIService from "../../../api/index";
import QDialogTitle from "../../../components/QDialogTitle";
import { id } from "date-fns/locale";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  startPausedButton: {
    color: "white",
    backgroundColor: "rgba(0, 159, 227, 1)",
    "&:hover, &:focus": {
      color: "rgba(0, 159, 227, 1)",
      border: "1px solid rgba(0, 159, 227, 1)",
    },
  },
  formControl: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  eDialogButton: {
    color: "rgba(0, 159, 227, 1)",
    border: "1px solid rgba(0, 159, 227, 1)",
    textTransform: "inherit !important",
  },
}));

class ConsultationsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consultations: [],
      isFetching: true,
      openSalutation: false,
    };
    this.fetchData = this.fetchData.bind(this);
    this.fetchConsultations = this.fetchConsultations.bind(this);
    this.fetchPreparedConsultations =
      this.fetchPreparedConsultations.bind(this);
    this.fetchConsultation = this.fetchConsultation.bind(this);
    this.adjustData = this.adjustData.bind(this);
    this.adjustDataForStoredConsultations =
      this.adjustDataForStoredConsultations.bind(this);
    this.adjustDataForPreparedConsultations =
      this.adjustDataForPreparedConsultations.bind(this);
    this.startNewConsulting = this.startNewConsulting.bind(this);
    this.loadConsultation = this.loadConsultation.bind(this);
    this.deleteRemoteConsultation = this.deleteRemoteConsultation.bind(this);
    this.deleteRemoteConsultationAsync =
      this.deleteRemoteConsultationAsync.bind(this);
    this.deletePausedConsultation = this.deletePausedConsultation.bind(this);
    this.startPreparedConsultation = this.startPreparedConsultation.bind(this);
    this.continueConsultation = this.continueConsultation.bind(this);
    this.handleSalutationTrueClose = this.handleSalutationTrueClose.bind(this);
    this.handleSelectChangeSalutation =
      this.handleSelectChangeSalutation.bind(this);
  }

  async componentDidMount() {
    if (navigator.onLine && this.props.isLoggedin) {
      await this.fetchData();
    } else {
      this.setState({
        isFetching: false,
      });
    }
  }

  fetchData() {
    const that = this;
    Promise.all([
      this.fetchConsultations(),
      this.fetchPreparedConsultations(),
    ]).then(() => {
      that.setState({
        isFetching: false,
      });
    });
  }

  async fetchConsultations() {
    await APIService.get("api/consultations/adm/bje", (status, data) => {
      this.setState({ consultations: data });
    });
  }

  async fetchPreparedConsultations() {
    await APIService.get("api/prepared/consultation/adm", (status, data) => {
      this.props.setPreparedConsultations(data);
    });
  }

  async fetchConsultation(e) {
    await APIService.get(`api/consultation/${id}`, (status, data) => {
      this.props.loadConsultation(data);
      toast.success("Beratung geladen.");
    });
  }

  loadConsultation(data) {
    this.props.loadConsultation(data);
    this.continueConsultation();
  }

  async deleteRemoteConsultationAsync(id) {
    if (navigator.onLine && this.props.isLoggedin) {
      let del = await this.deleteRemoteConsultation(id);
      if (del) {
        await this.fetchConsultations();
        this.setState({
          isFetching: false,
        });
      }
    }
  }

  async deleteRemoteConsultation(id) {
    return await APIService.delete(
      `api/consultations/adm/bje/${id}`,
      (status, resolve) => {
        if (status === 200) {
          toast.success("Beratung gelöscht.");
          return Promise.resolve(true);
        }
      }
    );
  }

  async setConsultationStatus(id) {
    return await APIService.post(
      `api/consultations/adm/bje/setstatus`,
      { id, status: 1 },
      "application/json",
      (status, resolve) => {
        if (status === 200) {
          toast.success("Beratung aktualisiert.");
          this.fetchConsultations();
        }
      }
    );
  }

  deletePausedConsultation(id) {
    this.props.deletePausedConsultation(id);
  }

  adjustData(data) {
    const resultLink = (id) => process.env.PREACT_APP_PLAYBOOKMICROSITE + id;
    const pdfLink = (id) =>
      `${process.env.PREACT_APP_PLAYBOOKAPI}api/consultation/${id}/pdf`;

    let res = data.reverse().map((d) => {
      const dateAppointment = new Date(d.appointmentDate);
      return {
        id: d.id,
        company: d.company,
        email: d.customerEmail,
        appointmentDate: dateAppointment,
        note: d.topic,
        progress: "",
        status: d.status,
        customer: d.customer,
        action: [
          <Online
            polling={{
              url: `${process.env.PREACT_APP_PLAYBOOKAPI}ping`,
              interval: 60000,
            }}
          >
            <OptionsForConsultation
              eDialog={resultLink(d.id)}
              pdf={pdfLink(d.id)}
              id={d.id}
              deleteRemoteConsultation={this.deleteRemoteConsultationAsync}
            />
          </Online>,
          <Offline
            polling={{
              url: `${process.env.PREACT_APP_PLAYBOOKAPI}ping`,
              interval: 60000,
            }}
          >
            <OptionsForConsultation disabled />
          </Offline>,
        ],
      };
    });
    return res;
  }

  adjustDataForStoredConsultations(data) {
    let res = data.reverse().map((d) => {
      const dateAppointment = new Date(d.consulting.consultingStarted);
      return {
        company: d.setup.company,
        email: d.setup.email,
        appointmentDate: dateAppointment,
        note: d.consulting.topic,
        progress: (
          <Typography variant="subtitle2" color="primary" gutterBottom>
            Pausiert
          </Typography>
        ),
        action: (
          <OptionsForPausedConsultation
            loadConsultation={this.loadConsultation}
            deletePausedConsultation={this.deletePausedConsultation}
            data={d}
            disabled={this.props.consultingStarted !== null}
          />
        ),
        id: d.consulting.internalID,
      };
    });
    return res;
  }

  adjustDataForPreparedConsultations(data) {
    let res = data.reverse().map((d) => {
      const dateAppointment = new Date(d.consultation.appointment);
      return {
        company: d.customer.company,
        email: d.customer.email,
        appointmentDate: dateAppointment,
        note: d.consultation.note,
        progress: "",
        action: (
          <Button
            variant="contained"
            color="primary"
            disabled={this.props.consultingStarted !== null}
            onClick={() => this.startPreparedConsultation(d)}
          >
            Starten
          </Button>
        ),
        id: d.id,
      };
    });
    return res;
  }

  startNewConsulting() {
    this.props.switchConsultingState(true);
  }

  startPreparedConsultation(preparedConsultation) {
    let salutation = preparedConsultation.customer.salutation;
    // in der Anrede kann auch Herrn stehen, daher die Überprüfung
    if (salutation && salutation.includes("Herr")) {
      this.props.setSalutation("Herr");
    } else if (salutation && salutation.includes("Frau")) {
      this.props.setSalutation("Frau");
    }
    this.props.setFirstname(preparedConsultation.customer.firstname);
    this.props.setLastname(preparedConsultation.customer.lastname);
    this.props.setEmail(preparedConsultation.customer.email);
    this.props.setCompany(preparedConsultation.customer.company);

    this.props.setConsultingTopic(preparedConsultation.consultation.note);

    this.props.setInternalID(preparedConsultation.id);

    this.props.setConsultingHasStartedDate(
      new Date(preparedConsultation.consultation.appointment)
    );

    if (!salutation) {
      this.setState({ openSalutation: true });
    } else {
      this.continueConsultation();
    }
  }

  continueConsultation(e) {
    this.props.handleDialogClose(e);
    route("/", true);
  }

  handleSalutationTrueClose() {
    this.props.setSalutation(document.getElementById("salutation").value);
    this.setState({ openSalutation: false });

    this.continueConsultation();
  }

  handleSelectChangeSalutation(e) {
    this.props.setSalutation(e.target.value);
  }

  render() {
    const classes = useStyles();
    const { isFetching, consultations } = this.state;
    const {
      consultingStarted,
      storedConsultations,
      preparedConsultations,
      isLoggedin,
      customerMail,
      customerCompany,
      topic,
      internalID,
    } = this.props;

    if (isFetching) {
      return <Dots />;
    }

    let data1 = [];
    if (consultations !== null) {
      data1 = this.adjustData(consultations);
    }

    let data2 = [];
    if (
      storedConsultations.consultations !== null &&
      storedConsultations.consultations.length !== 0
    ) {
      data2 = this.adjustDataForStoredConsultations(
        storedConsultations.consultations
      );
    }

    let data3 = [];
    if (
      preparedConsultations.consultations !== null &&
      preparedConsultations.consultations.length !== 0
    ) {
      data3 = this.adjustDataForPreparedConsultations(
        preparedConsultations.consultations
      );
      //filterout paused prepared consultations
      data2.forEach((p) => {
        data3 = data3.filter((pc) => pc.id !== p.id);
      });

      if (
        consultingStarted !== null &&
        internalID !== null &&
        internalID !== ""
      ) {
        data3 = data3.filter((pc) => pc.id !== internalID);
      }
    }

    let data = [...data1, ...data2, ...data3];

    if (consultingStarted !== null) {
      data.push({
        company: customerCompany,
        email: customerMail,
        appointmentDate: new Date(),
        note: topic,
        progress: (
          <Typography variant="subtitle2" color="primary" gutterBottom>
            Aktiv
          </Typography>
        ),
        action: (
          <Button
            variant="contained"
            className="plaintext"
            color="primary"
            onClick={this.continueConsultation}
          >
            Fortsetzen
          </Button>
        ),
      });
    }

    data.sort((a, b) => {
      if (a.appointmentDate > b.appointmentDate) return -1;
      if (a.appointmentDate < b.appointmentDate) return 1;
      return 0;
    });

    const cellpadding = "12px";

    return (
      <>
        <Box
          height="100%"
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
        >
          <MaterialTable
            columns={[
              {
                cellStyle: { padding: cellpadding },
                title: "Kunde",
                field: "customer",
                render: (rowData) => (
                  <div>
                    <Typography variant="subtitle2" gutterBottom>
                      <strong>{rowData.company}</strong>
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {rowData.email}
                    </Typography>
                  </div>
                ),
                customFilterAndSearch: (term, rowData) =>
                  `${rowData.company.toLowerCase()} ${rowData.email.toLowerCase()}`.indexOf(
                    term.toLowerCase()
                  ) != -1,
              },
              {
                cellStyle: { padding: cellpadding },
                title: "Datum",
                field: "appointmentDate",
                type: "date",
              },
              {
                cellStyle: { padding: cellpadding },
                title: "Besuchsthema",
                field: "note",
                render: (rowData) => (
                  <Typography variant="subtitle2" gutterBottom mb={0}>
                    {rowData.note}
                  </Typography>
                ),
                customFilterAndSearch: (term, rowData) =>
                  rowData.note.toLowerCase().indexOf(term.toLowerCase()) != -1,
              },
              {
                cellStyle: { padding: cellpadding },
                title: "",
                field: "progress",
              },
              {
                cellStyle: { padding: cellpadding },
                title: "",
                field: "action",
              },
              {
                cellStyle: { padding: cellpadding },
                title: "Status",
                field: "status",
                render: (rowData) => (
                  <Tooltip
                    disableFocusListener
                    title={
                      rowData.status === 2
                        ? "Stammdaten fehlerhaft"
                        : "in Prüfung"
                    }
                  >
                    <ErrorOutlineIcon
                      onClick={(e) =>
                        rowData.status === 2 &&
                        this.props.openSetupPage(e, {
                          ...rowData.customer,
                          id: rowData.id,
                          callback: (id) => this.setConsultationStatus(id),
                        })
                      }
                      style={{
                        cursor: rowData.status === 2 ? "pointer" : "default",
                        color: rowData.status === 1 ? orange[500] : red[500],
                        display: rowData.status > 0 ? "block" : "none",
                        visibility: rowData.status > 0 ? "visible" : "hidden",
                      }}
                    />
                  </Tooltip>
                ),
              },
            ]}
            data={data}
            title={
              <QDialogTitle
                title="Ihre Beratungen"
                subTitle={
                  <Offline
                    polling={{
                      url: `${process.env.PREACT_APP_PLAYBOOKAPI}ping`,
                      interval: 60000,
                    }}
                  >
                    Offlinemodus: Liste eventuell nicht vollständig.
                  </Offline>
                }
              />
            }
            icons={tableIcons}
            localization={{
              toolbar: {
                searchPlaceholder: "Suche ...",
              },
              pagination: {
                labelRowsSelect: "Zeilen",
                labelRowsPerPage: "Zeilen pro Seite:",
                firstAriaLabel: "Erste Seite",
                firstTooltip: "Erste Seite",
                previousAriaLabel: "Vorherige Seite",
                previousTooltip: "Vorherige Seite",
                nextAriaLabel: "Nächste Seite",
                nextTooltip: "Nächste Seite",
                lastAriaLabel: "Letzte Seite",
                lastTooltip: "Letzte Seite",
                labelDisplayedRows: "{from}-{to} von {count}",
              },
              body: {
                emptyDataSourceMessage: [
                  <Online
                    polling={{
                      url: `${process.env.PREACT_APP_PLAYBOOKAPI}ping`,
                      interval: 60000,
                    }}
                  >
                    {!isLoggedin ? (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        href={`${process.env.PREACT_APP_SSOURL}authorize?response_type=code&client_id=${process.env.PREACT_APP_SSOCLIENTID}&popup=1`}
                      >
                        Anmelden um Beratungen zu laden
                      </Button>
                    ) : (
                      <Typography>Keine Beratungen</Typography>
                    )}
                  </Online>,
                ],
              },
            }}
            options={{
              headerStyle: {
                color: "#000",
                fontFamily: "Averta-Bold",
                padding: cellpadding,
              },
              rowStyle: {
                color: "black",
              },
              searchFieldStyle: {
                backgroundColor: "#f2f2f2",
                paddingLeft: "1em",
                borderRadius: 0,
                paddingTop: 6,
                paddingBottom: 6,
                flexDirection: "row-reverse",
              },
            }}
          />
          {consultingStarted == null && (
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={this.startNewConsulting}
              >
                Neue Beratung
              </Button>
            </Box>
          )}
        </Box>
        <Dialog
          open={this.state.openSalutation}
          // onClose={this.handleSalutationClose}
          aria-labelledby="draggable-dialog-title"
          reason={{ backdropClick: false }}
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Anrede fehlt
          </DialogTitle>
          <DialogContent>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{ background: "#ffffff" }}
                htmlFor="salutation"
                id="salutation-select-outlined-label"
              >
                Anrede
              </InputLabel>
              <Select
                native
                labelId="salutation-select-outlined-label"
                id="salutation"
                onChange={this.handleSelectChangeSalutation}
                inputProps={{
                  name: "salutation",
                  id: "salutation",
                }}
              >
                <option value="Herr">Herr</option>
                <option value="Frau">Frau</option>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleSalutationTrueClose}
              color="primary"
              className="plaintext"
            >
              Fertig
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  storedConsultations: getConsultationStore(state),
  preparedConsultations: getPreparedConsultations(state),
  isLoggedin: isLoggedIn(state),
  consultingStarted: getConsultingStarted(state),
  customerMail: getEmail(state),
  customerCompany: getCompany(state),
  topic: getConsultingTopic(state),
  internalID: getConsultingInternalID(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchUserLogout() {
    dispatch(fetchUserLogout());
  },
  loadConsultation(consultation) {
    dispatch(loadConsultation(consultation));
  },
  deletePausedConsultation(id) {
    dispatch(deletePausedConsultation(id));
  },
  setPreparedConsultations(preparedConsultations) {
    dispatch(setPreparedConsultations(preparedConsultations));
  },
  setSalutation(salutation) {
    dispatch(setSalutation(salutation));
  },
  setFirstname(firstname) {
    dispatch(setFirstname(firstname));
  },
  setLastname(lastname) {
    dispatch(setLastname(lastname));
  },
  setEmail(email) {
    dispatch(setEmail(email));
  },
  setCompany(company) {
    dispatch(setCompany(company));
  },
  setConsultingTopic(topic) {
    dispatch(setConsultingTopic(topic));
  },
  setConsultingHasStarted() {
    dispatch(setConsultingHasStarted());
  },
  setInternalID(internalID) {
    dispatch(setInternalID(internalID));
  },
  setConsultingHasStartedDate(date) {
    dispatch(setConsultingHasStartedDate(date));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationsPage);
