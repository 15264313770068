import Image1 from "./files/ABB-TSK.webp";
import Image2 from "./files/ABB-VAA-S.webp";

export default function Anschlussdarstellung() {
  const id = "anschlussdarstellung-svg";
  return (
    <svg
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      id={id}
      viewBox="0 0 533.52 639.39"
    >
      <defs>
        <style>{`#${id} g, #${id} text, #${id} image, #${id} polyline, #${id} path, #${id} rect { pointer-events: none; } #${id} rect.hotspot { pointer-events: all; }#${id} text { font-weight: initial !important; font-family: Averta-Semibold !important; } #${id} use { pointer-events: none; } #${id} .cls-1{fill:#bababa;}#${id} .cls-2{fill:#477a43;}#${id} .cls-3{letter-spacing:-.05em;}#${id} .cls-4{font-family:Averta-Bold, 'Averta-Regular';font-size:14px;font-weight:700;}#${id} .cls-5{letter-spacing:0em;}#${id} .cls-6{letter-spacing:-.02em;}#${id} .cls-7{letter-spacing:0em;}#${id} .cls-8{stroke:#000;}#${id} .cls-8,#${id} .cls-9{fill:none;stroke-miterlimit:10;stroke-width:2px;}#${id} .cls-9{stroke:#477a43;}`}</style>
      </defs>
      <image
        id="ABB-TSA-K230"
        width="386"
        height="540"
        transform="translate(238.27) scale(.35)"
        xlinkHref={Image1}
      />
      <text class="cls-4" transform="translate(9.06 203.24)">
        <tspan class="cls-6" x="0" y="0">
          Kabel NYM 3x1,5
        </tspan>
      </text>
      <text class="cls-4" transform="translate(155.33 223.79)">
        <tspan x="0" y="0">
          N
        </tspan>
      </text>
      <text class="cls-4" transform="translate(157.83 272.47)">
        <tspan x="0" y="0">
          L
        </tspan>
      </text>
      <rect
        class="cls-1"
        x="37.1"
        y="183.79"
        width="52.13"
        height="126.3"
        rx="6"
        ry="6"
        transform="translate(310.08 183.69) rotate(89.98)"
      />
      <text class="cls-4" transform="translate(46.76 251.06)">
        <tspan x="0" y="0">
          230V
        </tspan>
      </text>
      <image
        id="VAA_A_6.230.2.1"
        width="714"
        height="868"
        transform="translate(231.79 290.51) scale(.37)"
        xlinkHref={Image2}
      />
      <polyline
        class="cls-8"
        points="142.52 233.49 198.93 233.49 274.28 233.49 274.28 201.69"
      />
      <path d="m142.7,237.25c-2.08,0-3.76-1.68-3.76-3.76,0-2.08,1.68-3.76,3.76-3.76s3.76,1.68,3.76,3.76c0,2.08-1.68,3.76-3.76,3.76Z" />
      <path d="m278.04,201.88c0-2.08-1.68-3.76-3.76-3.76s-3.76,1.68-3.76,3.76,1.68,3.76,3.76,3.76,3.76-1.68,3.76-3.76Z" />
      <polyline
        class="cls-8"
        points="142.52 255.12 198.93 255.12 274.28 255.12 274.28 281.92"
      />
      <path d="m142.7,258.88c-2.08,0-3.76-1.68-3.76-3.76,0-2.08,1.68-3.76,3.76-3.76s3.76,1.68,3.76,3.76c0,2.08-1.68,3.76-3.76,3.76Z" />
      <path d="m270.52,281.73c0,2.08,1.68,3.76,3.76,3.76s3.76-1.68,3.76-3.76-1.68-3.76-3.76-3.76-3.76,1.68-3.76,3.76Z" />
      <line class="cls-8" x1="294.28" y1="281.92" x2="294.28" y2="201.69" />
      <path d="m298.04,281.73c0,2.08-1.68,3.76-3.76,3.76s-3.76-1.68-3.76-3.76,1.68-3.76,3.76-3.76,3.76,1.68,3.76,3.76Z" />
      <path d="m298.04,201.88c0-2.08-1.68-3.76-3.76-3.76s-3.76,1.68-3.76,3.76,1.68,3.76,3.76,3.76,3.76-1.68,3.76-3.76Z" />
      <line class="cls-9" x1="87.3" y1="635.63" x2="533.52" y2="635.63" />
      <path
        class="cls-2"
        d="m87.49,639.39c-2.08,0-3.76-1.68-3.76-3.76s1.68-3.76,3.76-3.76,3.76,1.68,3.76,3.76c0,2.08-1.68,3.76-3.76,3.76Z"
      />
      <line class="cls-9" x1="450.21" y1="635.63" x2="450.21" y2="595.53" />
    </svg>
  );
}
