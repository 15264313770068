// Must be the first import
if (process.env.NODE_ENV === "development") {
  // Must use require here as import statements are only allowed
  // to exist at the top of a file.
  require("preact/debug");
}
if (typeof window !== "undefined") {
  require("./components/Utils/ScrollOverflow");
}
import App from "./app.js";
import "./style/index.css";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import "react-toastify/dist/ReactToastify.css";
import "react-activity/dist/react-activity.css";

// Sentry.init({
//   dsn: process.env.PREACT_APP_SENTRYDNS,
//   environment: process.env.PREACT_APP_ENV,
//   release: "playbook_pwa@" + COMMITHASH,
//   autoSessionTracking: true,
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
//   // beforeSend(event, hint) {
//   //     // Check if it is an exception, and if so, show the report dialog
//   //     if (event.exception) {
//   //         Sentry.showReportDialog({
//   //             eventId: event.event_id,
//   //             title: "Playbook",
//   //             subtitle: "Unser Team wurde hierüber benachrichtigt.",
//   //             subtitle2: "Wenn Sie uns helfen möchten, teilen Sie uns bitte unten mit, was geschehen ist.",
//   //             labelName: "Name",
//   //             labelEmail: "Email",
//   //             labelComments: "Was ist passiert?",
//   //             labelClose: "Schließen",
//   //             labelSubmit: "Absturzbericht senden",
//   //             errorGeneric: "Beim Einreichen Ihres Berichts ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es erneut.",
//   //             errorFormEntry: "Einige Felder waren ungültig. Bitte korrigieren Sie die Fehler und versuchen Sie es erneut.",
//   //             successMessage: "Ihr Feedback wurde gesendet. Herzlichen Dank!",
//   //         });
//   //     }
//   //     return event;
//   // },
// });

export default App;
