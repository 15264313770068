import { Box, Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";

// Images
import OVRT1T2Image from "../../../../../../assets/neuheiten-2024/abb/OVR-T1-T2/OVR-T1-T2.webp";
import BetterImage from "../../../../../../components/BetterImage";

function OVR() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="OVR T1-T2 25kA High Energy" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  "Anschlussfertiger Kombi-Ableiter T1+2 auf Funkenstreckenbasis",
                  "bestehend aus Basisteil und steckbaren Schutzmodulen",
                  "Schutzmodule sind werkzeuglos wechselbar",
                  "Blitzstromableitvermögen bis 100kA (10/350 μs)",
                  "Zulassung für alle BSK I-IV",
                  "Nennspannungen UC 230V/400V",
                  "TN, TNS, TT Netz-Systeme",
                  "Leckstromfrei: im Vorzählerbereich einsetzbar",
                  "End-of-Life Überwachung aller Schutzmodule",
                  "Generell mit einem Hilfskontakt (TS)",
                  "Doppelte Klemmen für V-Verdrahtung",
                  "2 Klemmen, je eine für PE und für die Erdung",
                  "geeignet für Endgeräteschutz",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}>
              <Box
                height="63dvh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <BetterImage
                  src={OVRT1T2Image}
                  height="38dvh"
                  alt="OVR T1-T2 25kA High Energy"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default OVR;
