export default function ExternalLinkIcon(props) {
  return (
    <svg
      {...props}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      color="#000000"
    >
      <defs>
        <style>{`.cls-63ce7424ea57ea6c8380055b-1{fill:none;stroke:currentColor;stroke-miterlimit:10;}`}</style>
      </defs>
      <polyline
        class="cls-63ce7424ea57ea6c8380055b-1"
        points="22 13 22 22 2 22 2 2 11 2"
      ></polyline>
      <polyline
        class="cls-63ce7424ea57ea6c8380055b-1"
        points="14 2 22 2 22 10"
      ></polyline>
      <line
        class="cls-63ce7424ea57ea6c8380055b-1"
        x1="22"
        y1="2"
        x2="10"
        y2="14"
      ></line>
    </svg>
  );
}
