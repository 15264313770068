// Components
import { Grid } from "@material-ui/core";
import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QBodyText from "../../../../../../components/QBodyText";
import QList from "../../../../../../components/QList";
import CenteredImage from "../../../../../../components/CenteredImage";

// Images
import BackgroundImage from "../../../../../../assets/welcome/2021/0206/bgSmartphone.webp";
import AnbindungSmartphoneImage from "../../../../../../assets/welcome/2021/anwendungen/anbindungSmartphone/uebersicht.webp";

export default function AnbindungSmartphone() {
  return (
    <Slide
      background={{
        src: BackgroundImage,
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          target: "_blank",
          link: "https://www.busch-jaeger.de/online-katalog/detail/2TMA220050W0026",
        },
        {
          type: "pdf",
          img: "preisbeispiel",
          path: "../../../../../../assets/pdf/preisbeispiele/Busch-Welcome/Preisbeispiel Busch-Welcome - Anbindung Smartphone.pdf",
        },
        {
          type: "galery",
          img: "info",
          images: [
            {
              src: AnbindungSmartphoneImage,
              alt: "Anbindung Smartphone",
            },
          ],
        },
      ]}
    >
      <CenteredImage
        src="../../../../../../assets/welcome/2021/dasSystem/innenstationenVideo/innenstationVideo43.webp"
        alt="Innenstation Video"
        height="45vh"
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Welcome<sup>®</sup>
              </>
            }
            subTitle="Anbindung Smartphone"
          />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={4}>
              <QBodyText>
                Schickes Design mit Funktionalität: drahtgebundene Anbindung an
                Busch-Welcome und lokale intuitive Bedienung des Systems
              </QBodyText>
              <QList
                title="Vorteile"
                headline="Vorteile"
                items={[
                  "Aufputz-Installation 12mm",
                  "Kompakte Bauform (114x102 bxh)",
                  "WLAN-Schnittstelle",
                  "Integriertes IP-Gateway für bis zu 8 Endgeräte",
                  "Integrierte Hörschleife",
                  "Grundeinstellung am Display",
                  "Erweiterte Einstellungen per App",
                  "Farbvarianten:  Weiß",
                ]}
                dense
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
