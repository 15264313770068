import {
  setADMEmail,
  setADMFirstname,
  setADMLastname,
  setADMSalutation,
} from "./settingsReducer";
import Cookies from "js-cookie";
import crmDB from "../../databases/crmDB";

export const FETCH_USER_REQUEST = "playbook/user/REQUEST";
export const FETCH_USER_INVALIDATE = "playbook/user/INVALIDATE";
export const FETCH_USER_FAILURE = "playbook/user/FAILURE";
export const FETCH_USER_RECEIVE = "playbook/user/RECEIVE";

//Reducer
export const initialState = {
  isInvalidated: true,
  isFetching: false,
  username: "",
  firstname: "",
  lastname: "",
  email: "",
  gender_iso5218: 1,
  country: "",
  roles: "",
  loggedInAt: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_INVALIDATE:
      return {
        ...initialState,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case FETCH_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        isInvalidated: false,
      };
    case FETCH_USER_RECEIVE:
      return {
        ...state,
        isFetching: false,
        isInvalidated: false,
        username: action.username,
        realname: action.realname,
        firstname: action.firstname,
        lastname: action.lastname,
        email: action.email,
        gender_iso5218: action.gender_iso5218,
        country: action.country,
        roles: action.roles,
        loggedInAt: Date.now ? Date.now() : new Date().getTime(),
      };
    default:
      return state;
  }
}

//Action creators
export const requestUser = () => ({
  type: FETCH_USER_REQUEST,
});

export const invalidateUser = () => ({
  type: FETCH_USER_INVALIDATE,
});

export const fetchUserFailure = () => ({
  type: FETCH_USER_FAILURE,
});

export const writeUserData = (user) => ({
  type: FETCH_USER_RECEIVE,
  username: user.username,
  realname: user.realname,
  firstname: user.firstname,
  lastname: user.lastname,
  email: user.email,
  gender_iso5218: user.gender_iso5218,
  country: user.country,
  roles: user.roles,
});

export const shouldFetchUserData = (state) =>
  (!state.username && !state.email && !state.isFetching) ||
  Cookies.get("access_token") === "";

export const fetchUserDataIfNeeded = () => (dispatch, getState) => {
  if (Cookies.get("access_token") === undefined) {
    dispatch(invalidateUser());
  } else if (shouldFetchUserData(getState().user)) {
    return dispatch(fetchUserData());
  }
};

//Async action creators
export const fetchUserData = () => (dispatch, getState) => {
  dispatch(requestUser());
  let apiUrl = `${process.env.PREACT_APP_PLAYBOOKAPI}api/mybje/user`; //eslint-disable-line no-undef
  let xhr = new XMLHttpRequest();
  xhr.addEventListener("readystatechange", function () {
    if (this.readyState === 4) {
      dispatch(writeUserData(JSON.parse(this.responseText)));
      let user = getState().user;
      dispatch(setADMEmail(user.email));
      dispatch(setADMFirstname(user.firstname));
      dispatch(setADMLastname(user.lastname));
      dispatch(setADMSalutation(user.gender_iso5218 === 1 ? "Herr" : "Frau"));
    } else {
      dispatch(fetchUserFailure());
    }
  });
  xhr.open("GET", apiUrl);
  xhr.setRequestHeader(
    "Authorization",
    `Bearer ${Cookies.get("access_token")}`
  );
  xhr.send();
};

//Async action creators
export const fetchUserLogout = () => {
  Cookies.remove("access_token");
  Cookies.remove("refresh_token");
  crmDB.delete().then(() => crmDB.open());
  return {
    type: FETCH_USER_INVALIDATE,
  };
};

//Selectors
export const getUser = (state) => state.user;
export const getFirstname = (state) => state.user?.firstname;

export const isLoggedIn = (state) =>
  state.user.username !== "" && Cookies.get("access_token") !== undefined;

export const fetchJwtCookie = (username, password) => {
  let xhr = new XMLHttpRequest();
  return new Promise((resolve) => {
    xhr.open(
      "POST",
      `${process.env.PREACT_APP_MYBUILDINGSAPI}user/access-token`,
      true
    );
    xhr.setRequestHeader(
      "Authorization",
      `Basic ${btoa(`${username}:${password}`)}`
    );
    xhr.onload = function () {
      if (this.status === 201) {
        Cookies.set("access_token", "xhr.responseText", { expires: 30 });
        resolve(true);
      } else {
        Cookies.remove("access_token");
        resolve(false);
      }
    };
    xhr.send();
  });
};

export const ssoAuth = (code) => {
  let xhr = new XMLHttpRequest();
  return new Promise((resolve) => {
    let data = new FormData();
    data.append("client_id", process.env.PREACT_APP_SSOCLIENTID);
    data.append("client_secret", process.env.PREACT_APP_SSOCLIENTSECRET);
    data.append("grant_type", "authorization_code");
    data.append("code", code);
    xhr.open("POST", `${process.env.PREACT_APP_SSOURL}token`, true);
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          const response = JSON.parse(xhr.responseText);
          Cookies.set("access_token", response["access_token"], {
            expires: 30,
          });
          Cookies.set("refresh_token", response["refresh_token"], {
            expires: 90,
          });
          resolve(true);
        } else {
          Cookies.remove("access_token");
          Cookies.remove("refresh_token");
          resolve(false);
        }
      }
    });
    xhr.send(data);
  });
};

export const ssoRefresh = () => {
  let xhr = new XMLHttpRequest();
  return new Promise((resolve) => {
    let data = new FormData();
    data.append("client_id", process.env.PREACT_APP_SSOCLIENTID);
    data.append("client_secret", process.env.PREACT_APP_SSOCLIENTSECRET);
    data.append("grant_type", "refresh_token");
    data.append("refresh_token", Cookies.get("refresh_token"));
    xhr.open("POST", `${process.env.PREACT_APP_SSOURL}token`, true);
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          const response = JSON.parse(xhr.responseText);
          Cookies.set("access_token", response["access_token"], {
            expires: 30,
          });
          Cookies.set("refresh_token", response["refresh_token"], {
            expires: 90,
          });
          resolve(response["access_token"]);
        } else {
          Cookies.remove("access_token");
          Cookies.remove("refresh_token");
          resolve(false);
        }
      }
    });
    xhr.send(data);
  });
};
