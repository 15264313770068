// Images Schulung
import ImageSchulung1 from "../../assets/installationsgeraete/Schulung/1.webp";
import ImageSchulung2 from "../../assets/installationsgeraete/Schulung/2.webp";
import ImageSchulung3 from "../../assets/installationsgeraete/Schulung/3.webp";
import ImageSchulung4 from "../../assets/installationsgeraete/Schulung/4.webp";
import ImageSchulung5 from "../../assets/installationsgeraete/Schulung/5.webp";
import ImageSchulung6 from "../../assets/installationsgeraete/Schulung/6.webp";

// Images Marketing
import ImageMarketing1 from "../../assets/installationsgeraete/Marketing/1.webp";
import ImageMarketing2 from "../../assets/installationsgeraete/Marketing/2.webp";
import ImageMarketing3 from "../../assets/installationsgeraete/Marketing/3.webp";
import ImageMarketing4 from "../../assets/installationsgeraete/Marketing/4.webp";
import ImageMarketing5 from "../../assets/installationsgeraete/Marketing/5.webp";

export const installationsgeraeteUnterstuetzungSchulung = {
  title: "Schulung",
  items: [
    {
      title: "Stromkompass",
      img: ImageSchulung1,
      link: "https://new.abb.com/low-voltage/de/service/stromkompass",
      target: "_blank",
    },
    {
      title: "Elektrospicker",
      img: ImageSchulung2,
      link: "https://new.abb.com/low-voltage/de/service/stromkompass/elektrospicker",
      target: "_blank",
    },
    {
      title: (
        <>
          Podcast
          <br />
          Blindleistung
        </>
      ),
      img: ImageSchulung3,
      link: "https://new.abb.com/low-voltage/de/service/stromkompass/blindleistung",
      target: "_blank",
    },
    {
      title: (
        <>
          YouTube
          <br />
          Bedienungsanleitung
        </>
      ),
      img: ImageSchulung4,
      link: "https://www.youtube.com/playlist?list=PLW1uFXqHQgufzBJA0wCbM8R3AQIQk8ukT",
      target: "_blank",
    },
    {
      title: "Wissen in 3 Minuten",
      img: ImageSchulung5,
      link: "https://www.youtube.com/watch?v=JKxMwCyY9Vw&list=PLW1uFXqHQgueSFU3M6__Yd_FBQ12Cqqu9",
      target: "_blank",
    },
    {
      title: (
        <>
          Youtube
          <br />
          Techniktalk
        </>
      ),
      img: ImageSchulung6,
      link: "https://www.youtube.com/playlist?list=PLW1uFXqHQgufhvVQ2v0kmm6xvIsjlF2--",
      target: "_blank",
    },
  ],
};
export const installationsgeraeteUnterstuetzungMarketing = {
  title: "Marketing",
  items: [
    {
      title: (
        <>
          Broschüre
          <br />
          Energieeffizienz
        </>
      ),
      img: ImageMarketing1,
      link: "https://www.busch-jaeger-excellence.de/45/req-ns/506C2/product/5288",
      target: "_blank",
    },
    {
      title: (
        <>
          Flyer
          <br />
          Der FI/LS DS201
        </>
      ),
      img: ImageMarketing2,
      link: "https://library.e.abb.com/public/5b12d64a2c5f45409580ff4ca611cb7d/2CDC420039L0101%20Rev.%20A_DS201_DE_Flyer_062019.pdf?x-sign=lA5IViqpIS0vLPo//VzC5O5WoXabtQ0lanAoAeIqS96IKqFpbyqorg1mzq5z8+uO",
      target: "_blank",
    },
    {
      title: (
        <>
          Broschüre
          <br />
          Elektrospicker
        </>
      ),
      img: ImageMarketing3,
      link: "https://www.busch-jaeger-excellence.de/45/req-ns/C977B/product/5616",
      target: "_blank",
    },
    {
      title: (
        <>
          Flyer
          <br />
          Brandschutzschalter
        </>
      ),
      img: ImageMarketing4,
      link: "https://www.busch-jaeger-excellence.de/45/req-ns/EA157/product/2584",
      target: "_blank",
    },
    {
      title: (
        <>
          Broschüre
          <br />
          Überspannungsschutzgeräte
        </>
      ),
      img: ImageMarketing5,
      link: "https://www.busch-jaeger-excellence.de/400/req-ns/86918/product/5808",
      target: "_blank",
    },
  ],
};
