import { h } from "preact";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function SaveIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M14 3h2.997v5h-2.997v-5zm9 1v20h-22v-24h17.997l4.003 4zm-17 5h12v-7h-12v7zm14 4h-16v9h16v-9z" />
      </svg>
    </SvgIcon>
  );
}
