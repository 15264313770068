import React from "react";
import Grafik from "./files/grafik.webp"
import SechsWegeVentil from "./files/6-wege-ventil.webp"

export default function DetailansichtMischer() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 815 701"
      fill="none"
    >
      <g id="mischer-grafik" clip-path="url(#clip0_0_23)">
        <rect width="815" height="701" fill="white" />
        <g id="Group 44">
          <rect
            id="6-wege-ventil"
            x="430"
            y="26"
            width="266.203"
            height="284"
            fill="url(#pattern0)"
          />
          <g id="Group" clip-path="url(#clip1_0_23)">
            <rect
              id="grafik"
              x="126"
              width="282"
              height="335.463"
              fill="url(#pattern1)"
            />
            <text
              id="Kesselvorlauf"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="18"
              letter-spacing="0px"
            >
              <tspan x="202" y="298.474">
                Kesselvorlauf
              </tspan>
            </text>
            <text
              id="RÃ¼cklauf"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="18"
              letter-spacing="0px"
            >
              <tspan x="119" y="138.474">
                Rücklauf
              </tspan>
            </text>
            <text
              id="Heizungsvorlauf"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="18"
              letter-spacing="0px"
            >
              <tspan x="160" y="23.4736">
                Heizungsvorlauf
              </tspan>
            </text>
          </g>
        </g>
        <g id="Group 43">
          <text
            id="Klemme 13 im HCC"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="18"
            letter-spacing="0px"
          >
            <tspan x="653" y="475.106">
              Klemme 13 im HCC
            </tspan>
          </text>
          <text
            id="Klemme 13 im HCC_2"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="18"
            letter-spacing="0px"
          >
            <tspan x="514.008" y="475.106">
              DC (0)2...10 V
            </tspan>
          </text>
          <text
            id="Klemme 13 im HCC_3"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="18"
            letter-spacing="0px"
          >
            <tspan x="536.402" y="499.106">
              DC 2...10 V
            </tspan>
          </text>
          <text
            id="Klemme 13 im HCC_4"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="18"
            letter-spacing="0px"
          >
            <tspan x="256.568" y="701.106">
              ..R230ASR
            </tspan>
          </text>
          <text
            id="Klemme 13 im HCC_5"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="24"
            letter-spacing="0px"
          >
            <tspan x="0" y="404.809">
              AC 100...240 V
            </tspan>
          </text>
          <text
            id="Klemme 14 im HCC"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="18"
            letter-spacing="0px"
          >
            <tspan x="653" y="499.106">
              Klemme 14 im HCC
            </tspan>
          </text>
          <rect
            id="Rectangle 23"
            x="257"
            y="621"
            width="194"
            height="53"
            stroke="black"
            stroke-width="2"
          />
          <path
            id="Line 67"
            d="M272 621L272 412"
            stroke="black"
            stroke-width="2"
          />
          <path
            id="Line 68"
            d="M304 621L304 412"
            stroke="black"
            stroke-width="2"
          />
          <path
            id="Line 69"
            d="M351 621L351 412"
            stroke="black"
            stroke-width="2"
          />
          <path
            id="Line 70"
            d="M378 621L378 412"
            stroke="black"
            stroke-width="2"
          />
          <path
            id="Line 71"
            d="M406.5 621L406.5 468L493 468"
            stroke="black"
            stroke-width="2"
          />
          <path
            id="Line 72"
            d="M493 491L483 485.226L483 496.774L493 491ZM433.5 491L433.5 490L432.5 490L432.5 491L433.5 491ZM434.5 621L434.5 491L432.5 491L432.5 621H434.5ZM433.5 492L484 492L484 490L433.5 490L433.5 492Z"
            fill="black"
          />
          <rect
            id="Rectangle 24"
            x="259"
            y="548"
            width="189"
            height="21"
            fill="white"
          />
          <rect
            id="Rectangle 25"
            x="259"
            y="520"
            width="189"
            height="12"
            fill="white"
          />
          <path
            id="1"
            d="M272.17 563V555.33L270.434 556.629L269.654 555.426L272.478 553.389H273.797V563H272.17Z"
            fill="black"
          />
          <path
            id="6"
            d="M350.516 562.714V555.044L348.779 556.343L348 555.14L350.823 553.103H352.143V562.714H350.516Z"
            fill="black"
          />
          <path
            id="2"
            d="M303.57 561.517H307.316V563H301.13L301.109 561.92L304.199 558.666C304.65 558.188 304.992 557.771 305.225 557.401C305.45 557.039 305.566 556.67 305.566 556.287C305.566 556.014 305.498 555.761 305.375 555.528C305.252 555.303 305.074 555.125 304.849 554.988C304.623 554.858 304.37 554.79 304.09 554.79C303.304 554.79 302.695 555.173 302.258 555.932L301.089 555.104C301.383 554.551 301.793 554.106 302.312 553.778C302.832 553.45 303.461 553.286 304.206 553.286C304.603 553.286 304.979 553.354 305.341 553.484C305.696 553.614 306.018 553.799 306.298 554.031C306.571 554.271 306.797 554.571 306.961 554.934C307.125 555.296 307.207 555.692 307.207 556.116C307.207 556.39 307.18 556.649 307.125 556.896C307.07 557.142 306.988 557.401 306.865 557.661C306.742 557.928 306.578 558.201 306.373 558.475C306.168 558.755 305.915 559.049 305.614 559.363L303.57 561.489V561.517Z"
            fill="black"
          />
          <path
            id="7"
            d="M377.916 561.23H381.662V562.714H375.476L375.455 561.634L378.545 558.38C378.996 557.901 379.338 557.484 379.57 557.115C379.796 556.753 379.912 556.384 379.912 556.001C379.912 555.728 379.844 555.475 379.721 555.242C379.598 555.017 379.42 554.839 379.194 554.702C378.969 554.572 378.716 554.504 378.436 554.504C377.649 554.504 377.041 554.887 376.604 555.646L375.435 554.818C375.729 554.265 376.139 553.82 376.658 553.492C377.178 553.164 377.807 553 378.552 553C378.948 553 379.324 553.068 379.687 553.198C380.042 553.328 380.363 553.513 380.644 553.745C380.917 553.984 381.143 554.285 381.307 554.647C381.471 555.01 381.553 555.406 381.553 555.83C381.553 556.104 381.525 556.363 381.471 556.609C381.416 556.855 381.334 557.115 381.211 557.375C381.088 557.642 380.924 557.915 380.719 558.188C380.514 558.469 380.261 558.763 379.96 559.077L377.916 561.203V561.23Z"
            fill="black"
          />
          <path
            id="3"
            d="M408.024 558.023C408.339 558.16 408.605 558.345 408.838 558.57C409.07 558.796 409.248 559.062 409.378 559.377C409.508 559.691 409.576 560.033 409.576 560.402C409.576 560.936 409.433 561.414 409.159 561.824C408.886 562.234 408.503 562.556 408.018 562.774C407.532 563 406.985 563.109 406.384 563.109C405.563 563.109 404.859 562.925 404.265 562.549C403.663 562.173 403.246 561.681 403 561.072L404.401 560.45C404.716 561.216 405.338 561.599 406.274 561.599C406.739 561.599 407.129 561.469 407.443 561.202C407.758 560.942 407.922 560.594 407.922 560.17C407.922 559.767 407.765 559.425 407.45 559.151C407.136 558.878 406.698 558.734 406.124 558.734H405.393V557.354H406.274C406.521 557.354 406.732 557.319 406.917 557.251C407.102 557.183 407.245 557.094 407.354 556.971C407.464 556.854 407.539 556.718 407.594 556.567C407.648 556.417 407.676 556.26 407.676 556.082C407.676 555.706 407.532 555.398 407.252 555.152C406.972 554.906 406.63 554.783 406.233 554.783C405.536 554.783 405.01 555.063 404.641 555.617L403.349 554.92C403.581 554.428 403.957 554.031 404.477 553.73C404.989 553.43 405.584 553.279 406.268 553.279C406.835 553.279 407.348 553.382 407.799 553.573C408.25 553.771 408.619 554.065 408.893 554.455C409.166 554.852 409.303 555.31 409.303 555.836C409.303 556.15 409.248 556.451 409.146 556.725C409.043 556.998 408.899 557.244 408.708 557.463C408.517 557.682 408.284 557.859 408.024 558.01V558.023Z"
            fill="black"
          />
          <path
            id="5"
            d="M433.637 556.827C434.047 556.827 434.443 556.902 434.812 557.046C435.182 557.189 435.503 557.388 435.776 557.647C436.05 557.907 436.269 558.235 436.433 558.632C436.59 559.028 436.672 559.459 436.672 559.924C436.672 560.553 436.528 561.106 436.248 561.585C435.968 562.07 435.571 562.446 435.052 562.713C434.532 562.986 433.944 563.116 433.274 563.116C432.604 563.116 431.989 562.986 431.415 562.713C430.841 562.446 430.369 562.063 430 561.558L431.176 560.614C431.388 560.929 431.661 561.168 432.003 561.346C432.338 561.523 432.714 561.612 433.138 561.612C433.712 561.612 434.17 561.455 434.512 561.127C434.854 560.806 435.024 560.396 435.024 559.896C435.024 559.637 434.97 559.404 434.86 559.186C434.751 558.967 434.614 558.796 434.437 558.659C434.259 558.522 434.054 558.42 433.835 558.345C433.616 558.27 433.391 558.229 433.158 558.229C432.529 558.229 432.003 558.399 431.593 558.734L430.711 558.427L430.998 553.389H436.2V554.872H432.516L432.393 557.032C432.864 556.896 433.281 556.827 433.637 556.827Z"
            fill="black"
          />
          <line
            id="Line 73"
            x1="264"
            y1="525.5"
            x2="281"
            y2="525.5"
            stroke="black"
          />
          <line
            id="Line 74"
            x1="296"
            y1="525.5"
            x2="313"
            y2="525.5"
            stroke="black"
          />
          <line
            id="Line 75"
            x1="343"
            y1="525.5"
            x2="360"
            y2="525.5"
            stroke="black"
          />
          <line
            id="Line 76"
            x1="370"
            y1="525.5"
            x2="387"
            y2="525.5"
            stroke="black"
          />
          <line
            id="Line 77"
            x1="398"
            y1="525.5"
            x2="415"
            y2="525.5"
            stroke="black"
          />
          <line
            id="Line 78"
            x1="425"
            y1="525.5"
            x2="442"
            y2="525.5"
            stroke="black"
          />
          <rect
            id="Rectangle 26"
            x="260.5"
            y="583.5"
            width="58"
            height="13"
            rx="6.5"
            stroke="black"
          />
          <rect
            id="Rectangle 27"
            x="337.5"
            y="583.5"
            width="110"
            height="13"
            rx="6.5"
            stroke="black"
          />
          <rect
            id="Rectangle 28"
            x="434"
            y="459"
            width="17"
            height="19"
            fill="white"
          />
          <rect
            id="Rectangle 29"
            x="454"
            y="482"
            width="17"
            height="19"
            fill="white"
          />
          <text
            id="Y"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="18"
            letter-spacing="0px"
          >
            <tspan x="437" y="475.106">
              Y
            </tspan>
          </text>
          <path
            id="N"
            d="M275.002 386H277.173V398.788H274.958L268.771 389.744H268.735V398.788H266.564V386H268.718L274.958 395.176H275.002V386Z"
            fill="black"
          />
          <path
            id="L"
            d="M300.564 398.788V386H302.735V396.837H308.659V398.788H300.564Z"
            fill="black"
          />
          <path
            id="T"
            d="M351.799 386.212V397.049H355.429V399H345.963V397.049H349.628V386.212H351.799Z"
            fill="black"
          />
          <g id="SG..24">
            <path
              d="M373.388 399.069C372.544 399.069 371.718 398.902 370.927 398.568C370.127 398.234 369.397 397.733 368.747 397.065L370.118 395.378C370.566 395.923 371.085 396.345 371.665 396.635C372.245 396.925 372.825 397.065 373.396 397.065C374.082 397.065 374.609 396.934 374.979 396.652C375.339 396.371 375.523 395.993 375.523 395.501C375.523 395.343 375.497 395.202 375.462 395.07C375.427 394.938 375.374 394.824 375.321 394.719C375.26 394.613 375.172 394.517 375.058 394.411C374.935 394.314 374.82 394.227 374.706 394.156C374.592 394.086 374.434 394.016 374.24 393.928C374.047 393.84 373.862 393.77 373.704 393.699C373.537 393.638 373.317 393.559 373.054 393.462C372.737 393.356 372.456 393.251 372.228 393.154C371.99 393.066 371.727 392.943 371.445 392.803C371.164 392.662 370.918 392.521 370.716 392.381C370.514 392.24 370.312 392.064 370.109 391.871C369.898 391.678 369.74 391.467 369.617 391.247C369.494 391.027 369.389 390.772 369.31 390.491C369.23 390.21 369.195 389.902 369.195 389.577C369.195 388.927 369.371 388.32 369.74 387.767C370.101 387.222 370.593 386.791 371.208 386.475C371.823 386.158 372.5 386 373.229 386C374.706 386 376.121 386.519 377.466 387.573L376.174 389.278C375.119 388.408 374.144 387.969 373.229 387.969C372.72 387.969 372.289 388.109 371.955 388.373C371.621 388.637 371.445 389.006 371.445 389.463C371.437 389.902 371.647 390.254 372.078 390.535C372.351 390.711 372.939 390.975 373.836 391.317C374.891 391.739 375.611 392.047 375.98 392.249C376.789 392.697 377.316 393.26 377.571 393.928C377.712 394.314 377.791 394.754 377.791 395.246C377.791 395.756 377.703 396.23 377.527 396.652C377.352 397.083 377.114 397.443 376.824 397.733C376.534 398.032 376.2 398.278 375.813 398.48C375.427 398.691 375.031 398.841 374.627 398.929C374.214 399.025 373.801 399.069 373.388 399.069Z"
              fill="black"
            />
            <path
              d="M386.088 399.043C385.174 399.043 384.321 398.894 383.53 398.595C382.739 398.296 382.062 397.874 381.482 397.329C380.902 396.784 380.454 396.099 380.129 395.272C379.795 394.455 379.637 393.55 379.637 392.557C379.637 391.941 379.698 391.344 379.839 390.772C379.971 390.201 380.164 389.674 380.41 389.19C380.656 388.707 380.973 388.268 381.351 387.872C381.72 387.485 382.142 387.151 382.607 386.87C383.073 386.589 383.592 386.378 384.172 386.229C384.752 386.079 385.358 386 386.009 386C386.94 386 387.846 386.185 388.725 386.536C389.604 386.896 390.359 387.424 391.001 388.118L389.463 389.437C388.514 388.514 387.362 388.048 386 388.048C385.42 388.048 384.884 388.153 384.383 388.364C383.882 388.575 383.451 388.874 383.082 389.261C382.713 389.647 382.432 390.122 382.221 390.685C382.01 391.256 381.904 391.88 381.904 392.557C381.904 393.409 382.071 394.174 382.405 394.851C382.739 395.536 383.231 396.072 383.873 396.468C384.515 396.863 385.253 397.057 386.088 397.057C387.143 397.057 388.092 396.775 388.936 396.204V394.051H385.807V392.1H391.098V397.443C390.5 397.936 389.762 398.331 388.874 398.612C387.986 398.902 387.055 399.043 386.088 399.043Z"
              fill="black"
            />
            <path
              d="M394.675 399.069C394.288 399.069 393.963 398.946 393.699 398.683C393.436 398.419 393.304 398.103 393.304 397.733C393.304 397.496 393.356 397.276 393.479 397.065C393.603 396.854 393.77 396.696 393.98 396.573C394.191 396.45 394.42 396.389 394.675 396.389C395.044 396.389 395.36 396.521 395.624 396.784C395.888 397.048 396.028 397.364 396.028 397.733C396.028 398.103 395.888 398.419 395.624 398.683C395.36 398.946 395.044 399.069 394.675 399.069Z"
              fill="black"
            />
            <path
              d="M399.526 399.069C399.14 399.069 398.814 398.946 398.551 398.683C398.287 398.419 398.155 398.103 398.155 397.733C398.155 397.496 398.208 397.276 398.331 397.065C398.454 396.854 398.621 396.696 398.832 396.573C399.043 396.45 399.271 396.389 399.526 396.389C399.896 396.389 400.212 396.521 400.476 396.784C400.739 397.048 400.88 397.364 400.88 397.733C400.88 398.103 400.739 398.419 400.476 398.683C400.212 398.946 399.896 399.069 399.526 399.069Z"
              fill="black"
            />
            <path
              d="M405.828 397.021H410.645V398.929H402.69L402.664 397.54L406.637 393.356C407.217 392.741 407.656 392.205 407.955 391.73C408.245 391.265 408.395 390.79 408.395 390.298C408.395 389.946 408.307 389.621 408.148 389.322C407.99 389.032 407.762 388.804 407.472 388.628C407.182 388.461 406.856 388.373 406.496 388.373C405.485 388.373 404.703 388.865 404.141 389.841L402.638 388.777C403.016 388.065 403.543 387.494 404.211 387.072C404.879 386.65 405.688 386.439 406.646 386.439C407.155 386.439 407.639 386.527 408.104 386.694C408.562 386.861 408.975 387.099 409.335 387.397C409.687 387.705 409.977 388.092 410.188 388.558C410.398 389.023 410.504 389.533 410.504 390.078C410.504 390.43 410.469 390.764 410.398 391.08C410.328 391.396 410.223 391.73 410.064 392.064C409.906 392.407 409.695 392.759 409.432 393.11C409.168 393.471 408.843 393.849 408.456 394.253L405.828 396.986V397.021Z"
              fill="black"
            />
            <path
              d="M417.254 398.929V396.846H411.849V395.422L417.588 386.571H419.337V394.982H421.06V396.846H419.337V398.929H417.254ZM414.292 395.044H417.333V390.087L414.292 395.044Z"
              fill="black"
            />
          </g>
          <text
            id="U"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="18"
            letter-spacing="0px"
          >
            <tspan x="456" y="498.106">
              U
            </tspan>
          </text>
          <path
            id="Line 79"
            d="M471 468L481 473.774V462.226L471 468ZM493 467H480V469H493V467Z"
            fill="black"
          />
          <path
            id="Line 80"
            d="M378 478L372.226 488H383.774L378 478ZM379 505V487H377V505H379Z"
            fill="black"
          />
          <g id="Group 42">
            <path
              id="Vector"
              d="M196.906 378H193.094L171 415.661L172.907 419H217.095L219 415.661L196.906 378ZM176.769 414.569L195.001 383.494L213.233 414.569H176.769Z"
              fill="black"
            />
            <path id="Vector_2" d="M197 393H193V404H197V393Z" fill="black" />
            <path id="Vector_3" d="M197 408H193V413H197V408Z" fill="black" />
          </g>
        </g>
      </g>
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_0_23"
            transform="scale(0.00187617 0.00176056)"
          />
        </pattern>
        <pattern
          id="pattern1"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image1_0_23"
            transform="scale(0.00177305 0.0014881)"
          />
        </pattern>
        <clipPath id="clip0_0_23">
          <rect width="815" height="701" fill="white" />
        </clipPath>
        <clipPath id="clip1_0_23">
          <rect
            width="289"
            height="335.463"
            fill="white"
            transform="translate(119)"
          />
        </clipPath>
        <image id="image0_0_23" width="533" height="568" xlinkHref={SechsWegeVentil} />
        <image id="image1_0_23" width="564" height="672" xlinkHref={Grafik} />
      </defs>
    </svg>
  );
}
