// Images Schulung
import ImageSchulung1 from "../../assets/renovation-und-neubau/unterstuetzung/1.webp";
import ImageSchulung2 from "../../assets/renovation-und-neubau/unterstuetzung/2.webp";
import ImageSchulung3 from "../../assets/renovation-und-neubau/unterstuetzung/3.webp";
import ImageSchulung4 from "../../assets/renovation-und-neubau/unterstuetzung/4.webp";
import ImageSchulung5 from "../../assets/renovation-und-neubau/unterstuetzung/5.webp";
import ImageSchulung6 from "../../assets/renovation-und-neubau/unterstuetzung/6.webp";
import ImageSchulung7 from "../../assets/renovation-und-neubau/unterstuetzung/7.webp";
import ImageSchulung8 from "../../assets/renovation-und-neubau/unterstuetzung/8.webp";

// Images Marketing
import ImageMarketing1 from "../../assets/renovation-und-neubau/marketing-unterstuetzung/1.webp";
import ImageMarketing2 from "../../assets/renovation-und-neubau/marketing-unterstuetzung/2.webp";
import ImageMarketing3 from "../../assets/renovation-und-neubau/marketing-unterstuetzung/3.webp";
import ImageMarketing4 from "../../assets/renovation-und-neubau/marketing-unterstuetzung/4.webp";
import ImageMarketing5 from "../../assets/renovation-und-neubau/marketing-unterstuetzung/5.webp";
import ImageMarketing6 from "../../assets/renovation-und-neubau/marketing-unterstuetzung/6.webp";

export const moderneElektroinstallationSchulungItems = {
  title: "Schulung",
  items: [
    {
      title: (
        <>
          Virtuelle Ausstellung
          <br />
          Türkommunikation
        </>
      ),
      img: ImageSchulung1,
      link: { href: "www.busch-jaeger.de/produkterlebnis" },
    },
    {
      title: "Busch-Baustellenplaner",
      img: ImageSchulung2,
      link: { href: "https://baustellenplaner.busch-jaeger.de" },
    },
    {
      title: "Smart Home Konfigurator",
      img: ImageSchulung3,
      link: { href: "https://smart-home-configurator.my.busch-jaeger.de" },
    },
    {
      title: "Busch-Jaeger Community",
      img: ImageSchulung4,
      link: { href: "https://community.busch-jaeger.de" },
    },
    {
      title: "Stromkompass",
      img: ImageSchulung5,
      link: { href: "https://stromkompass.de" },
    },
    {
      title: "Lichtschalter 3D LiveView",
      img: ImageSchulung6,
      link: {
        href: "https://apps.apple.com/de/app/3d-liveview/id1410280377",
      },
    },
    {
      title: "Smart Home Explorer",
      img: ImageSchulung7,
      link: {
        href: "https://apps.apple.com/de/app/smart-home-explorer/id1160341481",
      },
    },
    {
      title: "Smarter Gallery",
      img: ImageSchulung8,
      link: {
        href: "https://playbook.lightsail-aws.qmarketing.de/smarter-gallery",
      },
    },
  ],
};
export const moderneElektroinstallationMarketingItems = {
  title: "Marketing",
  items: [
    {
      title: "Smarter Home für mehr Wohnspaß",
      img: ImageMarketing1,
      link: {
        href: "https://www.flipedia.com/de/5119",
      },
    },
    {
      title: "Lebensräume frei gestalten",
      img: ImageMarketing2,
      link: {
        href: "https://www.flipedia.com/de/5004",
      },
    },
    {
      title: "Lichtschalter",
      img: ImageMarketing3,
      link: {
        href: "https://www.flipedia.com/de/4981",
      },
    },
    {
      title: "System Busch-free@home® flex",
      img: ImageMarketing4,
      link: {
        href: "https://www.flipedia.com/de/5095",
      },
    },
    {
      title: "innomag",
      img: ImageMarketing5,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/6C5C0/product/6340",
      },
    },
    {
      title: "Lösungsbroschüre Barrierefreie Elektrotechnik",
      img: ImageMarketing6,
      link: {
        href: "https://www.flipedia.com/de/5054",
      },
    },
  ],
};
