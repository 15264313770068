import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../components/QHeader";

import ZweipunktreglerImage from "../../../../../../../../assets/abbknx/Anwendungen/ClimaEco/anzeigen-und-bedienen/Zweipunkt-Regler.webp";
import ImageComponent from "../../../../../../../../components/ImageComponent";
import BetterImage from "../../../../../../../../components/BetterImage";
import ZweipunktreglerSVG from "./ZweipunktReglerSVG";

export default function Zweipunktregler() {
  return (
    <Slide>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title="Der Zweipunkt-Regler"
            subTitle="Anwendungsbeispiel der Soft SPS im AC/S"
          />
        </Grid>
        <Grid item>
          <BetterImage src={<ZweipunktreglerSVG />} alt="Zweipunkt-Regler" />
        </Grid>
      </Grid>
    </Slide>
  );
}
