import Overview from "./Overview";
import DasPerfekteDuo from "./Devices/DasPerfekteDuo";
import TriLine from "./Devices/TriLine";
import ComfortLine from "./Devices/ComfortLine";
import ComfortLineInnenausbau from "./Devices/ComfortLineInnenausbau";
import MessUndWandlertechnik from "./Devices/MessUndWandlertechnik";
import VMS from "./Devices/VMS";

export default function StriebelOverview() {
  return [
    <Overview key="striebel-john-overview" />,
    <DasPerfekteDuo key="das-perfekte-duo" />,
    <TriLine key="tri-line" />,
    <ComfortLine key="comfort-line" />,
    <ComfortLineInnenausbau key="comfort-line-innenausbau" />,
    <MessUndWandlertechnik key="mess-und-wandlertechnik" />,
    <VMS key="vms" />,
  ];
}
