import { h } from "preact";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function DownloadIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M12 21l-8-9h6v-12h4v12h6l-8 9zm9-1v2h-18v-2h-2v4h22v-4h-2z" />
      </svg>
    </SvgIcon>
  );
}
