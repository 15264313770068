import { h, Component } from "preact";
import { connect } from "react-redux";
import { Dots } from "react-activity";
import { fetchUserLogout } from "../../../redux/modules/userReducer";
import NewsCollapse from "./Components/NewsCollapse";
import { Box, Grid } from "@material-ui/core";
import { setNews, setUnreadAmount } from "../../../redux/modules/newsReducer";
import APIService from "../../../api/index";
import QDialogTitle from "../../QDialogTitle";

class NewsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      isFetching: true,
    };
    this.fetchNews = this.fetchNews.bind(this);
    this.adjustData = this.adjustData.bind(this);
  }

  async componentDidMount() {
    await this.fetchNews();
    this.setState({
      isFetching: false,
    });
    this.props.setUnreadAmount(0);
  }

  async fetchNews() {
    await APIService.get("api/pb/news", (status, data) => {
      this.setState({ news: data });
      this.props.setNews(data);
    });
  }

  adjustData(data) {
    let res = data.reverse().map((news) => {
      const newsDate = new Date(news.timestamp);
      return <NewsCollapse data={news} />;
    });
    return res;
  }

  render() {
    const { isFetching, news } = this.state;
    if (isFetching) {
      return <Dots />;
    }

    if (news === null) {
      return <h1>Keine Neuigkeiten</h1>;
    }

    const renderData = this.adjustData(news);

    return <Box><QDialogTitle title="Neuigkeiten" />{renderData}</Box>;
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchUserLogout() {
    dispatch(fetchUserLogout());
  },
  setNews(news) {
    dispatch(setNews(news));
  },
  setUnreadAmount(amount) {
    dispatch(setUnreadAmount(amount));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsPage);
