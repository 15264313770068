import { Box, Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QTable from "../../../../../../components/QTable";
import ImageComponent from "../../../../../../components/ImageComponent";
import QBodyText from "../../../../../../components/QBodyText";
import QAccordion from "../../../../../../components/QAccordion";

// Images
import backgroundImg from "../../../../../../assets/_backgrounds/neuheiten-2024/3.webp";
import ImageProStudioweiss from "../../../../../../assets/neuheiten-2024/bje/busch-waechter-pro/studioweiss.webp";
import ImageProAnthrazit from "../../../../../../assets/neuheiten-2024/bje/busch-waechter-pro/anthrazit.webp";
import ImageProAlusilber from "../../../../../../assets/neuheiten-2024/bje/busch-waechter-pro/alusilber.webp";
import ImageProBraun from "../../../../../../assets/neuheiten-2024/bje/busch-waechter-pro/braun.webp";

function BuschWaechterPRO() {
  return (
    <Slide
      background={{
        src: backgroundImg,
        alignment: "right",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch-Wächter® PRO" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  "1-Punkt-Montage (Befestigungspunkte abwärtskompatibel)",
                  "2 Designs (Rund/Eckig über Designrahmen)",
                  "3 Montagevarianten: Wand-/Decken- oder Eckmontage über Eckadapter",
                  "4 Funktionslinien (Konventionell BT/Wireless /free@home /KNX)",
                  "5 Farben (weiß, anthrazit, alusilber, edelstahl, braun)",
                  "4 Erfassungslinsen einstellbar (Front/Links/Rechts/Unterlauf)",
                  "3 Erfassungsradien (90/220/280)",
                  "2 Einstellvarianten (Poti/App)",
                  "1 Top-Markenqualität (u.a. WAGO-Kippklemmen)",
                ]}
                dense
              />
              <ImageComponent
                col={3}
                wrapperWidth="80%"
                align="left"
                images={[
                  {
                    src: ImageProStudioweiss,
                    alt: (
                      <>
                        Busch-Wächter<sup>®</sup> PRO | studioweiß
                      </>
                    ),
                  },
                  {
                    src: ImageProAnthrazit,
                    alt: (
                      <>
                        Busch-Wächter<sup>®</sup> PRO | anthrazit
                      </>
                    ),
                  },
                  {
                    src: ImageProAlusilber,
                    alt: (
                      <>
                        Busch-Wächter<sup>®</sup> PRO | alusilber
                      </>
                    ),
                  },
                  ,
                  {
                    src: ImageProBraun,
                    alt: (
                      <>
                        Busch-Wächter<sup>®</sup> PRO | braun
                      </>
                    ),
                  },
                ]}
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default BuschWaechterPRO;
