import { h } from "preact";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function ConsultingIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 44 44" version="1.1">
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="consulting">
            <circle id="Oval" fill="#000000" cx="22" cy="22" r="22" />
            <path
              d="M31.9,15.4 L29.7,15.4 L29.7,25.3 L15.4,25.3 L15.4,27.5 C15.4,28.105 15.895,28.6 16.5,28.6 L28.6,28.6 L33,33 L33,16.5 C33,15.895 32.505,15.4 31.9,15.4 Z M27.5,22 L27.5,12.1 C27.5,11.495 27.005,11 26.4,11 L12.1,11 C11.495,11 11,11.495 11,12.1 L11,27.5 L15.4,23.1 L26.4,23.1 C27.005,23.1 27.5,22.605 27.5,22 Z"
              id="Shape"
              fill="#FFFFFF"
              fill-rule="nonzero"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
