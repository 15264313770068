import { Box, Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import ImageComponent from "../../../../../../components/ImageComponent";
import QBodyText from "../../../../../../components/QBodyText";

// Images
import SicherungsautomatImage from "../../../../../../assets/neuheiten-2024/abb/s300p/s300p.webp";
import BetterImage from "../../../../../../components/BetterImage";

function Sicherungsautomat() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Sicherungsautomat S300P 25 kA" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Die Sicherungsautomaten der Baureihe S300P schützen Kabel und
                Leitungen vor Überlast und Kurzschluss in Industrie- und
                Infrastrukturanwendungen. Der Sicherungsautomat besitzt nach der
                Norm IEC-EN 60947-2 ein durchgängiges Ausschaltvermögen von 25
                kA über alle Bemessungsstromstärken.
              </QBodyText>
              <QBodyText>
                Das Gerät ist für Anwendungen im netzseitigen Anschlussraum im
                Zählerschrank, für Schiffsbau, Energie- und Batteriespeicher,
                Ladeinfrastruktur für Elektromobilität, Schienenfahrzeuge und
                Bahninfrastruktur sowie für Außenverteiler geeignet.
              </QBodyText>
              <QBodyText>
                Die Montage und Demontage auf der Hutschiene ist werkzeugfrei
                und auch aus dem Phasenschienenverbund möglich. Die
                Sicherungsautomaten verfügen über eine frontseitige
                Fehleranzeige zur Unterscheidung zwischen Auslösung im
                Fehlerfall und manuellem Ausschalten. Dies reduziert die
                Wartungszeiten bei einem Systemausfall.
              </QBodyText>
            </Grid>
            <Grid item xs={6}>
              <Box
                height="63dvh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <BetterImage
                  src={SicherungsautomatImage}
                  height="43dvh"
                  alt="Baureihe S300P"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default Sicherungsautomat;
