import { Box, Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QBodyText from "../../../../../../components/QBodyText";

// Images
import NovolinkImage from "../../../../../../assets/neuheiten-2024/abb/Novolink/novolink.webp";
import BetterImage from "../../../../../../components/BetterImage";

function OPCUAGateway() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="OPC UA Gateway für Novolink" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Das smarte Gateway SGWX20-OUA.1 ist ein Busankoppler, der die
                interne Kommunikation des B&R X20 Systems über X2X auf die
                Open-Standard OPC-UA-Kommunikation abbildet. Somit erhält der
                Anwender eine vorgefertigte Lösung für den Zugriff auf
                Novolink-Geräte über OPC UA mittels beliebiger kompatibler
                Lösungen der Steuerungstechnik.
              </QBodyText>
              <QList
                headline="Highlights"
                items={[
                  "OPC Client/Server",
                  "Security built-in mittels verschlüsselter Ethernet Kommunikation nach neuestem Standard",
                  "Kein spezifisches B&R Tool notwendig, Konfiguration über UA Expert",
                  "Kurze Reaktionszeiten, minimale Zykluszeit",
                  "Integrierter Switch zur Daisy-Chain-Verkabelung",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}>
              <Box
                height="63dvh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <BetterImage
                  src={NovolinkImage}
                  height="46dvh"
                  alt="OPC UA Gateway für Novolink"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default OPCUAGateway;
