import { Grid } from "@material-ui/core";
import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QBodyText from "../../../../../../components/QBodyText";
import QList from "../../../../../../components/QList";
import CenteredImage from "../../../../../../components/CenteredImage";

// Images
import BackgroundImage from "../../../../../../assets/welcome/2021/0206/iStock_74577563_XXXLARGE.webp"
import MehrfamilienhausVideoImage from "../../../../../../assets/welcome/2021/0206/MehrfamilienhausVideo.webp"

export default function MehrfamilienhausVideo() {
  return (
    <Slide
      background={{
        src: BackgroundImage,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          target: "_blank",
          link: "https://www.busch-jaeger.de/online-katalog/detail/2CKA008300A0480",
        },
        {
          type: "pdf",
          img: "preisbeispiel",
          path: "../../../../../../assets/pdf/preisbeispiele/Busch-Welcome/Preisbeispiel Busch-Welcome - Mehrfamilienhaus Video.pdf",
        },
        {
          type: "galery",
          img: "info",
          images: [
            {
              src: MehrfamilienhausVideoImage,
              alt: "Anwendung Mehrfamilienhaus Video",
            },
          ],
        },
      ]}
    >
      <CenteredImage
        src="../../../../../../assets/welcome/2021/dasSystem/innenstationenVideo/wc-video-innenstation-5-weiss-vollbild-bje-de-hr-540x450px.webp"
        alt="Außenstation Video"
        height="60vh"
        left="0px"
        top="50px"
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Welcome<sup>®</sup>
              </>
            }
            subTitle="Anwendung Mehrfamilienhaus Video"

          />
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <QBodyText>Funktionales Design mit intuitiver Bedienung</QBodyText>
            <QList
              title="Vorteile"
              headline="Vorteile"
              items={[
                "Einfache 2-Draht-Installation",
                "Außeneinheiten aus Edelstahl, Weiß oder als Hinterbau-Variante",
                "Anlagengröße bis 99 WE",
                "Systemzentrale mit integriertem Türöffnerkontakt, Treppenhausautomat und Systemnetzteil",
              ]}
              dense
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
