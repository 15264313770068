import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../components/QHeader";
import QGalery from "../../../../../../../../components/QGalery";
import QDialog from "../../../../../../../../components/QDialog";
import { useModal } from "mui-modal-provider";
import TemperaturfuehlerImage from "../../../../../../../../assets/abbknx/Anwendungen/ClimaEco/heizkreisregelung/heiz-kuehlkreis-controller/temperaturfuehler.webp";
import TemperaturfuehlerImage2 from "../../../../../../../../assets/abbknx/Anwendungen/ClimaEco/heizkreisregelung/heiz-kuehlkreis-controller/temperaturfuehler-2.webp";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import ABBHCC from "./HeizUndKuehlkreisControllerSVG/files/ABB-HCC-S.webp";
import DetailansichtPumpe from "./DetailansichtPumpe/index";
import DetailansichtMischer from "./DetailansichtMischer";
import HeizenUndKuehlenSVG from "./HeizenUndKuehlenSVG";
import { useState } from "preact/hooks";
import clsx from "clsx";

export default function HeizKuehlkreisController({ swiperRef }) {
  const { showModal } = useModal();
  const [activeLine, setActiveLine] = useState("");
  const id = "heiz-und-kuehlkreiscointroller-svg";
  const dialogTemperaturfuehler = () => {
    const dialog = showModal(QDialog, {
      children: (
        <QGalery
          loop={true}
          images={[
            {
              id: "image-1",
              src: TemperaturfuehlerImage,
              alt: "Temperaturfühler",
            },
            {
              id: "image-2",
              src: TemperaturfuehlerImage2,
              alt: "Heizungsanlage",
            },
          ]}
          closeHandler={() => dialog.hide()}
        />
      ),
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };
  const dialogMischer = () => {
    const dialog = showModal(QDialog, {
      children: (
        <TransformWrapper>
          <TransformComponent>
            <Box
              width={"calc(100dvw - 2rem)"}
              height={"calc(100dvh - 64px - 2rem)"}
            >
              <DetailansichtMischer />
            </Box>
          </TransformComponent>
        </TransformWrapper>
      ),
      alt: "Detailansicht Mischer",
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };
  const dialogPumpe = () => {
    const dialog = showModal(QDialog, {
      children: (
        <TransformWrapper>
          <TransformComponent>
            <Box
              width={"calc(100dvw - 2rem)"}
              height={"calc(100dvh - 64px - 2rem)"}
            >
              <DetailansichtPumpe />
            </Box>
          </TransformComponent>
        </TransformWrapper>
      ),
      alt: "Detailansicht Pumpe",
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };

  const handleLineChange = (e) => {
    const id = e.target.id;
    setActiveLine(activeLine === id ? "" : id);
  };
  return (
    <Slide
      actions={[
        {
          type: "icon",
          img: "laptop",
          target: "_blank",
          link: "https://new.abb.com/products/de/2CDG110219R0011/hcc-s2-1-2-1",
        },
        {
          id: "heizen-und-kuehlen",
          type: "betterGalery",
          label: "Anschluss",
          img: "info",
          images: [
            {
              children: <HeizenUndKuehlenSVG />,
              alt: "Heizen und Kühlen",
            },
          ],
        },
      ]}
    >
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title="Heiz-/Kühlkreis Controller"
            subTitle="HCC/S 2.1.2.1"
          />
        </Grid>
        <Grid item>
          <Box height="70dvh">
            <svg
              style={{ height: "75dvh", width: "100%" }}
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              id={id}
              viewBox="0 0 700 384.23"
            >
              <defs>
                <style>
                  {`
                    #${id} .fontBold {font-family: Averta-Bold !important}
                    #${id} g, #${id} text, #${id} image, #${id} polyline, #${id} path, #${id} rect { pointer-events: none; }
                    #${id} rect.hotspot, #${id} rect.click-me { pointer-events: all; }
                    #${id} .cls-34x { fill: black; }
                    #${id} .active-stroke { stroke-width: 3px; }
                    #${id} text { font-weight: initial !important; font-family: Averta-Regular !important; } #${id} use { pointer-events: none; }#${id} .cls-1{letter-spacing:-.04em;}#${id} .cls-1,#${id} .cls-2,#${id} .cls-3,#${id} .cls-4,#${id} .cls-5,#${id} .cls-6,#${id} .cls-7,#${id} .cls-8,#${id} .cls-9,#${id} .cls-10,#${id} .cls-11,#${id} .cls-12,#${id} .cls-13{font-family:BJEAverta-Regular, 'Averta-Regular';}#${id} .cls-2{letter-spacing:0em;}#${id} .cls-3{letter-spacing:-.01em;}#${id} .cls-4{letter-spacing:-.06em;}#${id} .cls-15,#${id} .cls-16,#${id} .cls-17,#${id} .cls-18{fill:#fff;}#${id} .cls-19{fill:#bf347b;}#${id} .cls-20{fill:#1b7abf;}#${id} .cls-21{fill:#a35503;}#${id} .cls-22{letter-spacing:-.03em;}#${id} .cls-16{filter:url(#drop-shadow-3);}#${id} .cls-16,#${id} .cls-17,#${id} .cls-18{stroke:#b6b6b6;stroke-linecap:round;}#${id} .cls-16,#${id} .cls-17,#${id} .cls-18,#${id} .cls-23,#${id} .cls-24,#${id} .cls-25,#${id} .cls-26{stroke-miterlimit:10;}#${id} .cls-17{filter:url(#drop-shadow-2);}#${id} .cls-18{filter:url(#drop-shadow-1);}#${id} .cls-27{font-size:12px;}#${id} .cls-27,#${id} .cls-28,#${id} .cls-29{font-family:"Averta-Light";}#${id} .cls-30{letter-spacing:-.02em;}#${id} .cls-28{font-size:14px;}#${id} .cls-31{letter-spacing:0em;}#${id} .cls-6{letter-spacing:-.02em;}#${id} .cls-7{letter-spacing:0em;}#${id} .cls-8{font-size:9.49px;}#${id} .cls-9{letter-spacing:0em;}#${id} .cls-23{stroke:#1b7abf;}#${id} .cls-23,#${id} .cls-24,#${id} .cls-25,#${id} .cls-26{fill:none;}#${id} .cls-24{stroke:#000;}#${id} .cls-25{stroke:#a35503;}#${id} .cls-26{stroke:#bf347b;}#${id} .cls-32{font-size:10px;}#${id} .cls-10{letter-spacing:-.01em;}#${id} .cls-33{letter-spacing:-.05em;}#${id} .cls-11{letter-spacing:-.07em;}#${id} .cls-34{letter-spacing:-.08em;}#${id} .cls-35{letter-spacing:-.01em;}#${id} .cls-12{letter-spacing:-.02em;}#${id} .cls-13{letter-spacing:0em;}`}
                </style>
                <filter id="drop-shadow-1" filterUnits="userSpaceOnUse">
                  <feOffset dx="0" dy="2" />
                  <feGaussianBlur result="blur" stdDeviation="3" />
                  <feFlood flood-color="#000" flood-opacity=".45" />
                  <feComposite in2="blur" operator="in" />
                  <feComposite in="SourceGraphic" />
                </filter>
                <filter id="drop-shadow-2" filterUnits="userSpaceOnUse">
                  <feOffset dx="0" dy="2" />
                  <feGaussianBlur result="blur-2" stdDeviation="3" />
                  <feFlood flood-color="#000" flood-opacity=".45" />
                  <feComposite in2="blur-2" operator="in" />
                  <feComposite in="SourceGraphic" />
                </filter>
                <filter id="drop-shadow-3" filterUnits="userSpaceOnUse">
                  <feOffset dx="0" dy="2" />
                  <feGaussianBlur result="blur-3" stdDeviation="3" />
                  <feFlood flood-color="#000" flood-opacity=".45" />
                  <feComposite in2="blur-3" operator="in" />
                  <feComposite in="SourceGraphic" />
                </filter>
              </defs>
              <rect
                class="cls-18"
                x="562.52"
                y="208.13"
                width="108"
                height="30"
                rx="6"
                ry="6"
              />
              <rect
                class="cls-17"
                x="562.52"
                y="241.48"
                width="108"
                height="38"
                rx="6"
                ry="6"
              />
              <rect
                class="cls-16"
                x="562.52"
                y="286.48"
                width="108"
                height="38"
                rx="6"
                ry="6"
              />
              <rect x="597.81" y="170.15" width="7.12" height="117.4" />
              <rect x="647.92" y="170.15" width="7.12" height="184.05" />
              <rect x="597.81" y="321.7" width="7.12" height="32.5" />
              <circle class="cls-15" cx="626.31" cy="156.92" r="27.28" />
              <path
                class="cls-15"
                d="m626.31,120.15c-20.28,0-36.77,16.49-36.77,36.77s16.49,36.77,36.77,36.77,36.77-16.51,36.77-36.77-16.51-36.77-36.77-36.77Zm0,66.42c-16.37,0-29.65-13.31-29.65-29.65s13.28-29.65,29.65-29.65,29.65,13.31,29.65,29.65-13.31,29.65-29.65,29.65Z"
              />
              <path d="m626.31,117.77c-21.59,0-39.14,17.55-39.14,39.14s17.55,39.14,39.14,39.14,39.14-17.55,39.14-39.14-17.58-39.14-39.14-39.14Zm0,75.91c-20.28,0-36.77-16.51-36.77-36.77s16.49-36.77,36.77-36.77,36.77,16.49,36.77,36.77-16.51,36.77-36.77,36.77Z" />
              <circle class="cls-15" cx="626.31" cy="156.92" r="27.28" />
              <path d="m626.31,127.26c-16.37,0-29.65,13.31-29.65,29.65s13.28,29.65,29.65,29.65,29.65-13.31,29.65-29.65-13.31-29.65-29.65-29.65Zm0,56.93c-15.04,0-27.28-12.24-27.28-27.28s12.24-27.28,27.28-27.28,27.28,12.24,27.28,27.28-12.24,27.28-27.28,27.28Z" />
              <rect x="643.17" y="322.14" width="16.61" height="4.74" />
              <rect x="643.17" y="281.81" width="16.61" height="4.74" />
              <rect x="593.06" y="319.32" width="16.61" height="4.74" />
              <rect x="593.06" y="286.11" width="16.61" height="4.74" />
              <path d="m589.04,222.34c-.57-4.2-4.18-7.45-8.54-7.45-4.74,0-8.64,3.87-8.64,8.64s3.89,8.64,8.64,8.64c4.36,0,7.97-3.25,8.54-7.45h17.98v-2.37h-17.98Zm-8.54,7.45c-3.44,0-6.26-2.82-6.26-6.26s2.82-6.26,6.26-6.26,6.26,2.8,6.26,6.26-2.8,6.26-6.26,6.26Z" />
              <path d="m638.86,222.34c-.57-4.2-4.18-7.45-8.54-7.45-4.74,0-8.64,3.87-8.64,8.64s3.89,8.64,8.64,8.64c4.36,0,7.97-3.25,8.54-7.45h17.98v-2.37h-17.98Zm-8.54,7.45c-3.44,0-6.26-2.82-6.26-6.26s2.82-6.26,6.26-6.26,6.26,2.8,6.26,6.26-2.8,6.26-6.26,6.26Z" />
              <path d="m589.15,304.32c-.57-4.2-4.18-7.45-8.54-7.45-4.74,0-8.64,3.87-8.64,8.64s3.89,8.64,8.64,8.64c4.36,0,7.97-3.25,8.54-7.45h15.61v-2.37h-15.61Zm-8.54,7.45c-3.44,0-6.26-2.82-6.26-6.26s2.82-6.26,6.26-6.26,6.26,2.8,6.26,6.26-2.8,6.26-6.26,6.26Z" />
              <rect x="620.34" y="301.37" width="32.16" height="7.12" />
              <rect
                x="611.27"
                y="302.61"
                width="16.61"
                height="4.74"
                transform="translate(924.55 -314.59) rotate(90)"
              />
              <rect
                x="625.5"
                y="302.61"
                width="16.61"
                height="4.74"
                transform="translate(938.78 -328.83) rotate(90)"
              />
              <polygon
                class="cls-15"
                points="608.57 290.71 601.5 302.81 594.43 290.71 608.57 290.71"
              />
              <polygon
                class="cls-15"
                points="608.57 319.63 594.43 319.63 601.5 307.53 608.57 319.63"
              />
              <polygon
                class="cls-15"
                points="615.94 298.11 615.94 312.23 603.85 305.18 615.94 298.11"
              />
              <path
                class="cls-15"
                d="m603.85,305.18l12.1,7.05v-14.12l-12.1,7.07Zm0,0l12.1,7.05v-14.12l-12.1,7.07Z"
              />
              <path d="m618.32,293.99l-13.52,7.9,7.9-13.55h-22.39l9.58,16.42.24.43-.26.43-9.56,16.39h22.39l-7.88-13.52,13.5,7.88v-22.37Zm-23.89-3.27h14.14l-7.07,12.1-7.07-12.1Zm14.14,28.92h-14.14l7.07-12.1,7.07,12.1Zm7.38-7.4l-12.1-7.05,12.1-7.07v14.12Z" />
              <path d="m609.69,249.21v-3.87h-16.61v3.87c-3.58,2.59-5.93,6.78-5.93,11.55s2.35,8.97,5.93,11.55v3.87h16.61v-3.87c3.58-2.59,5.93-6.78,5.93-11.55s-2.35-8.97-5.93-11.55Zm2.04,17.32c-1.16,2.14-2.97,3.84-5.17,4.91h-.14c-1.54.74-3.25,1.14-5.05,1.14s-3.51-.4-5.05-1.14h-.07c-.09-.05-.21-.09-.31-.19-.26-.09-.52-.24-.76-.43-.47-.28-.95-.62-1.38-1-.21-.14-.4-.31-.57-.5-.28-.24-.52-.5-.74-.76-.05-.05-.09-.09-.14-.17-.36-.38-.66-.81-.93-1.23-.31-.45-.59-.95-.81-1.47-.12-.26-.24-.5-.33-.76-.28-.74-.47-1.52-.62-2.3-.07-.4-.12-.81-.12-1.21-.02-.12-.02-.24-.02-.36v-.31c0-.28,0-.55.02-.83-.02-.02-.02-.05,0-.07.02-.28.07-.55.09-.83.12-.9.36-1.78.71-2.61.57-1.52,1.45-2.87,2.56-3.99.38-.43.81-.81,1.26-1.11.21-.19.45-.36.69-.5.24-.17.47-.31.71-.43,1.42-.81,3.01-1.33,4.72-1.49.36-.02.71-.05,1.07-.05.47,0,.93.02,1.38.09,1.38.14,2.66.52,3.82,1.14.14.07.26.14.38.21.57.26,1.14.64,1.64,1.04.24.19.47.38.69.57.21.17.4.36.59.55s.38.4.57.62c.31.36.62.76.88,1.19.5.76.93,1.57,1.23,2.44.07.21.17.45.21.69.09.31.17.62.24.93.17.78.26,1.61.26,2.44,0,1.21-.19,2.4-.55,3.49-.24.81-.57,1.57-.97,2.28Z" />
              <path
                class="cls-15"
                d="m613.24,260.71c0,1.23-.19,2.42-.52,3.53-.24.81-.57,1.57-.97,2.28-1.21,2.14-3.06,3.87-5.31,4.91-1.54.74-3.25,1.14-5.05,1.14s-3.51-.4-5.05-1.14c-.14-.05-.26-.12-.38-.19-.26-.09-.52-.24-.76-.43-.47-.28-.95-.62-1.38-1-.21-.14-.4-.31-.57-.5-.28-.24-.52-.5-.74-.76-.05-.05-.09-.09-.14-.17-.36-.38-.66-.81-.93-1.23-.31-.45-.59-.95-.81-1.47-.12-.26-.24-.5-.33-.76-.28-.74-.47-1.52-.62-2.3-.07-.4-.12-.81-.12-1.21-.02-.12-.02-.24-.02-.36v-.31c0-.28,0-.55.02-.83-.02-.02-.02-.05,0-.07.02-.28.07-.55.09-.83.09-.57.24-1.14.4-1.68.09-.33.19-.62.31-.93.07-.19.17-.36.24-.52.57-1.28,1.35-2.47,2.32-3.46.4-.4.81-.78,1.26-1.11.21-.17.45-.33.69-.5.24-.14.47-.31.71-.43,1.42-.81,3.01-1.33,4.72-1.49.36-.02.71-.05,1.07-.05.47,0,.93.02,1.38.09,1.33.17,2.61.57,3.77,1.14h.05c.14.07.26.14.38.21.31.17.62.33.9.55.26.17.5.31.74.5.24.19.47.38.69.57.21.17.4.36.59.55s.38.4.57.62c.31.36.62.76.88,1.19.5.76.93,1.57,1.23,2.44.07.21.17.45.21.69.09.31.17.62.24.93.14.78.24,1.57.24,2.4Z"
              />
              <polygon points="589.04 262.4 587.48 260.61 601.45 248.46 615.3 260.62 613.74 262.4 601.45 251.61 589.04 262.4" />
              <text class="cls-8" transform="translate(577.9 226.95)">
                <tspan x="0" y="0">
                  T
                </tspan>
              </text>
              <text class="cls-8" transform="translate(627.28 226.95)">
                <tspan x="0" y="0">
                  T
                </tspan>
              </text>
              <text class="cls-8" transform="translate(576.47 309.31)">
                <tspan x="0" y="0">
                  M
                </tspan>
              </text>
              <image
                id="HCC_S_2.1.2.1"
                width="998"
                height="632"
                transform="translate(159 135.67) scale(.32)"
                xlinkHref={ABBHCC}
              />
              <polyline
                className={
                  activeLine === "hotspot-mischer"
                    ? clsx("cls-25", "active-stroke")
                    : "cls-25"
                }
                points="563.35 306.51 501.13 306.51 501.13 109.17 375.97 109.17 375.97 156.65"
              />
              <text class="cls-32" transform="translate(16 149.34)">
                <tspan class="cls-29">Legende:</tspan>
                <tspan
                  className={
                    activeLine === "hotspot-pumpenfreigabe"
                      ? clsx("cls-29", "fontBold")
                      : "cls-29"
                  }
                  x="0"
                  y="30"
                >
                  1/2 Pumpenfreigabe
                </tspan>
                <tspan
                  className={
                    activeLine === "hotspot-vorlauftemperatur"
                      ? clsx("cls-19", "fontBold")
                      : "cls-19"
                  }
                  x="0"
                  y="48"
                >
                  4 Vorlauftemperatur{" "}
                </tspan>
                <tspan
                  className={
                    activeLine === "hotspot-ruecklauftemperatur"
                      ? clsx("cls-19", "fontBold")
                      : "cls-19"
                  }
                  x="0"
                  y="66"
                >
                  5 Rücklauftemperatur{" "}
                </tspan>
                <tspan
                  className={
                    activeLine === "hotspot-gemeinsame-masse"
                      ? clsx("cls-19", "fontBold")
                      : "cls-19"
                  }
                  x="0"
                  y="84"
                >
                  6 Gemeinsame Masse 4/5
                </tspan>
                <tspan
                  className={
                    activeLine === "hotspot-pumpe-betriebsmeldung"
                      ? clsx("cls-20", "fontBold")
                      : "cls-20"
                  }
                  x="0"
                  y="102"
                >
                  7 Pumpe Betriebsmeldung
                </tspan>
                <tspan
                  className={
                    activeLine === "hotspot-pumpe-stoermeldung"
                      ? clsx("cls-20", "fontBold")
                      : "cls-20"
                  }
                  x="0"
                  y="120"
                >
                  8 Pumpe Störmeldung
                </tspan>
                <tspan
                  className={
                    activeLine === "hotspot-gemeinsame-masse_2"
                      ? clsx("cls-20", "fontBold")
                      : "cls-20"
                  }
                  x="0"
                  y="138"
                >
                  9 Gemeinsame Masse 7/8
                </tspan>
                <tspan
                  className={
                    activeLine === "hotspot-mischer"
                      ? clsx("cls-21", "fontBold")
                      : "cls-21"
                  }
                  x="0"
                  y="156"
                >
                  13/14 Mischer 0-10V
                </tspan>
              </text>
              <text class="cls-27" transform="translate(498.45 351.06)">
                230 Volt
                <tspan x="0" y="14.4">
                  Permanent
                </tspan>
              </text>
              <text class="cls-28" transform="translate(202.09 360.72)">
                Anschlüsse für den zweiten Kreislauf
              </text>
              <polyline
                className={
                  activeLine === "hotspot-mischer"
                    ? clsx("cls-25", "active-stroke")
                    : "cls-25"
                }
                points="563.35 306.51 501.13 306.51 501.13 109.17 361.97 109.17 361.97 153.65"
              />
              <polyline
                className={
                  activeLine === "hotspot-gemeinsame-masse_2"
                    ? clsx("cls-23", "active-stroke")
                    : "cls-23"
                }
                points="578.48 265.61 519.31 265.61 519.31 80.93 293.64 80.93 293.64 153.63"
              />
              <polyline
                className={
                  activeLine === "hotspot-pumpe-stoermeldung"
                    ? clsx("cls-23", "active-stroke")
                    : "cls-23"
                }
                points="589.48 265.61 519.31 265.61 519.31 80.93 280.64 80.93 280.64 155.63"
              />
              <polyline
                className={
                  activeLine === "hotspot-pumpe-betriebsmeldung"
                    ? clsx("cls-23", "active-stroke")
                    : "cls-23"
                }
                points="588.48 265.61 519.31 265.61 519.31 80.93 266.64 80.93 266.64 155.63"
              />
              <polyline
                className={
                  activeLine === "hotspot-pumpenfreigabe"
                    ? clsx("cls-24", "active-stroke")
                    : "cls-24"
                }
                points="588.48 257.61 535.31 257.61 535.31 28.93 198.64 28.93 198.64 153.63"
              />
              <polyline
                className="cls-24"
                points="588.48 257.61 543.31 257.61 543.31 18.93 173.08 18.93"
              />
              <polyline
                className={
                  activeLine === "hotspot-pumpenfreigabe"
                    ? clsx("cls-24", "active-stroke")
                    : "cls-24"
                }
                points="183.12 154.46 183.12 37.33 173.08 37.33"
              />
              <text class="cls-28" transform="translate(159.23 24.51)">
                <tspan x="0" y="0">
                  N
                </tspan>
              </text>
              <text class="cls-28" transform="translate(161.23 43.51)">
                <tspan x="0" y="0">
                  L
                </tspan>
              </text>
              <polyline
                class="cls-24"
                points="627.24 259.12 684.47 259.12 684.47 361.26 567.26 361.26"
              />
              <path
                class="cls-19"
                d="m591.15,210.55c0-1.04.84-1.88,1.88-1.88s1.88.84,1.88,1.88-.84,1.88-1.88,1.88-1.88-.84-1.88-1.88Z"
              />
              <path
                d="M593.03 210.46V222.37"
                className={
                  activeLine === "hotspot-gemeinsame-masse"
                    ? clsx("cls-26", "active-stroke")
                    : "cls-26"
                }
              />
              <path
                className={
                  activeLine === "hotspot-vorlauftemperatur"
                    ? clsx("cls-26", "active-stroke")
                    : "cls-26"
                }
                d="M572.5 222H554V48.9805H225.77V153.35"
                stroke="#BF347B"
                stroke-miterlimit="10"
                fill="transparent"
              />
              <path
                d="M587.57 219.42H623.05"
                stroke="#BF347B"
                stroke-miterlimit="10"
                fill="transparent"
              />
              <path
                className={
                  activeLine === "hotspot-ruecklauftemperatur"
                    ? clsx("cls-26", "active-stroke")
                    : "cls-26"
                }
                d="M239.38 153.91V48.9805H565.75V210.42H662.5V219.5H637.5"
                stroke="#BF347B"
                stroke-miterlimit="10"
                fill="transparent"
              />
              <path
                className={
                  activeLine === "hotspot-gemeinsame-masse"
                    ? clsx("cls-26", "active-stroke")
                    : "cls-26"
                }
                d="M593 219.42V210.67H565.39V48.9805H252.83V153.91"
                stroke="#BF347B"
                stroke-miterlimit="10"
                fill="transparent"
              />
              <g id="Kuehlkreis Controller_vereinfacht-hotspots">
                <rect
                  className="click-me pointer"
                  id="hotspot-pumpenfreigabe"
                  x="13"
                  y="167"
                  width="112"
                  height="17"
                  fill="black"
                  fill-opacity="0"
                  onClick={handleLineChange}
                />
                <rect
                  className="click-me pointer"
                  id="hotspot-vorlauftemperatur"
                  x="13"
                  y="184"
                  width="112"
                  height="19"
                  fill="black"
                  fill-opacity="0"
                  onClick={handleLineChange}
                />
                <rect
                  className="click-me pointer"
                  id="hotspot-ruecklauftemperatur"
                  x="13"
                  y="203"
                  width="119"
                  height="18"
                  fill="black"
                  fill-opacity="0"
                  onClick={handleLineChange}
                />
                <rect
                  className="click-me pointer"
                  id="hotspot-gemeinsame-masse"
                  x="13"
                  y="221"
                  width="141"
                  height="17"
                  fill="black"
                  fill-opacity="0"
                  onClick={handleLineChange}
                />
                <rect
                  className="click-me pointer"
                  id="hotspot-pumpe-betriebsmeldung"
                  x="13"
                  y="238"
                  width="141"
                  height="18"
                  fill="black"
                  fill-opacity="0"
                  onClick={handleLineChange}
                />
                <rect
                  className="click-me pointer"
                  id="hotspot-pumpe-stoermeldung"
                  x="13"
                  y="256"
                  width="121"
                  height="18"
                  fill="black"
                  fill-opacity="0"
                  onClick={handleLineChange}
                />
                <rect
                  className="click-me pointer"
                  id="hotspot-gemeinsame-masse_2"
                  x="13"
                  y="274"
                  width="134"
                  height="19"
                  fill="black"
                  fill-opacity="0"
                  onClick={handleLineChange}
                />
                <rect
                  className="click-me pointer"
                  id="hotspot-mischer"
                  x="13"
                  y="293"
                  width="112"
                  height="17"
                  fill="black"
                  fill-opacity="0"
                  onClick={handleLineChange}
                />
              </g>
              <rect
                className="hotspot hotspotHover"
                x="562"
                y="208"
                width="109"
                height="30"
                fill="black"
                fill-opacity="0"
                rx="6"
                ry="6"
                onClick={dialogTemperaturfuehler}
              />
              <rect
                className="hotspot hotspotHover"
                x="562"
                y="241"
                width="109"
                height="39"
                fill="black"
                fill-opacity="0"
                rx="6"
                ry="6"
                onClick={dialogPumpe}
              />
              <rect
                className="hotspot hotspotHover"
                x="562"
                y="286"
                width="109"
                height="39"
                fill="black"
                fill-opacity="0"
                rx="6"
                ry="6"
                onClick={dialogMischer}
              />
            </svg>
          </Box>
        </Grid>
      </Grid>
    </Slide>
  );
}
