const SET_NEWS = "playbook/news/SET_NEWS";
const SET_UNREAD_AMOUNT = "playbook/news/SET_UNREAD_AMOUNT";

const initialState = {
  news: [],
  unreadAmount: 0,
  fetchDate: null,
};

const news = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEWS: {
      return {
        ...state,
        news: action.news,
        fetchDate: new Date(),
      };
    }
    case SET_UNREAD_AMOUNT: {
      return {
        ...state,
        unreadAmount: action.amount,
      };
    }
    default:
      return state;
  }
};

//actions
export const setNews = (news) => ({
  type: SET_NEWS,
  news,
});

export const setUnreadAmount = (amount) => ({
  type: SET_UNREAD_AMOUNT,
  amount,
});

//selector
export const getNewsData = (state) => state.news;
export const getUnreadAmount = (state) => state.news.unreadAmount;

export default news;
