import { h } from "preact";

import { useDispatch, useSelector } from "react-redux";
import {
  setSeminarAppointment,
  setServiceAndToolsNotes,
  getServiceAndToolsData,
  setSeminarNote,
  setAsset as setDispatchAsset,
} from "../../../redux/modules/chapterServiceAndTools";
import { Grid } from "@material-ui/core";
import QIncrementGroup from "../../QIncrementGroup";
import QActionPaper from "../../QActionPaper";
import QDateAction from "../../QDateAction";
import QTextFieldAction from "../../QTextFieldAction";

export default function ServiceAndToolsPage({ context }) {
  const dispatch = useDispatch();

  const {
    seminarAppointment: seminarAppointmentData,
    notes: notesData,
    seminarNote: seminarNoteData,
    assetKatalog: assetKatalogData,
    assetArchitektenBroschuere: assetArchitektenBroschuereData,
    assetProduktkatalog: assetProduktkatalogData,
    assetEnergieverteilung: assetEnergieverteilungData,
    assetZaehlerplatzBerater: assetZaehlerplatzBeraterData,
  } = useSelector(getServiceAndToolsData);

  const seminarAppointment = seminarAppointmentData.value;
  const notes = notesData.value;
  const seminarNote = seminarNoteData.value;
  const assetKatalog = assetKatalogData.value;
  const assetArchitektenBroschuere = assetArchitektenBroschuereData.value;
  const assetProduktkatalog = assetProduktkatalogData.value;
  const assetEnergieverteilung = assetEnergieverteilungData.value;
  const assetZaehlerplatzBerater = assetZaehlerplatzBeraterData.value;

  const setSetupField = (valueSet) => {
    switch (valueSet.id) {
      case "seminarNote":
        {
          dispatch(setSeminarNote(valueSet.value));
        }
        break;
      case "seminarAppointment":
        {
          dispatch(setSeminarAppointment(valueSet.value));
        }
        break;
      case "notes":
        {
          dispatch(setServiceAndToolsNotes(valueSet.value));
        }
        break;
    }
  };

  const setAsset = (id, value) => {
    dispatch(setDispatchAsset(id, value));
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <QActionPaper title="Seminare" context={context}>
          <QDateAction
            date={seminarAppointment}
            dateID="seminarAppointment"
            note={seminarNote}
            noteID="seminarNote"
            setSetupField={setSetupField}
          />
        </QActionPaper>
      </Grid>
      <Grid item>
        <QActionPaper title="Werbung" context={context}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetKatalog}
                valueID="assetKatalog"
                label={assetKatalogData.description}
                mode={1}
                setAsset={setAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetArchitektenBroschuere}
                valueID="assetArchitektenBroschuere"
                label={assetArchitektenBroschuereData.description}
                mode={1}
                setAsset={setAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetProduktkatalog}
                valueID="assetProduktkatalog"
                label={assetProduktkatalogData.description}
                mode={1}
                setAsset={setAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetEnergieverteilung}
                valueID="assetEnergieverteilung"
                label={assetEnergieverteilungData.description}
                mode={1}
                setAsset={setAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetZaehlerplatzBerater}
                valueID="assetZaehlerplatzBerater"
                label={assetZaehlerplatzBeraterData.description}
                mode={1}
                setAsset={setAsset}
              />
            </Grid>
          </Grid>
        </QActionPaper>
      </Grid>
      <Grid item>
        <QActionPaper title="Notizen" context={context}>
          <QTextFieldAction
            value={notes}
            valueID="notes"
            setSetupField={setSetupField}
          />
        </QActionPaper>
      </Grid>
    </Grid>
  );
}
