const SET_SEMINAR_APPOINTMENT = 'playbook/buschflex/SET_SEMINAR_APPOINTMENT'
const SET_NOTES = 'playbook/buschflex/SET_NOTES'
const SET_SEMINAR_NOTE = 'playbook/buschflex/SET_SEMINAR_NOTE'
const SET_ASSET = 'playbook/buschflex/SET_ASSET'

export const initialState = {
  seminarAppointment: {
    description: 'Termin für das Seminar',
    value: null,
    type: 'info',
    orderID: null,
  },
  assetKatalog: {
    description: 'Katalog 2023/24',
    value: 0,
    type: 'order',
    orderID: '2CKA000001A1858',
  },
  assetPlanungshandbuch: {
    description: 'Planungshandbuch',
    value: 0,
    type: 'order',
    orderID: '2CKA000001A1713',
  },
  assetSBBuschWaechterFlex: {
    description: 'Broschüre Busch-Wächter flex',
    value: 0,
    type: 'order',
    orderID: '2CKA000001A1821',
  },
  assetFlexTronicSortimentsbroschuere: {
    description: 'Busch-flexTronics® Sortimentsbroschüre',
    value: 0,
    type: 'order',
    orderID: '2CKA000001A1808',
  },
  seminarNote: {
    description: 'Notizen für das Seminar',
    value: '',
    type: 'info',
    orderID: null,
  },
  notes: {
    description: 'Notizen für das Kapitel',
    value: '',
    type: 'info',
    orderID: null,
  },
}

const buschflex = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEMINAR_APPOINTMENT: {
      return {
        ...state,
        seminarAppointment: {
          ...state.seminarAppointment,
          value: action.seminarAppointment,
        },
      }
    }
    case SET_NOTES: {
      return {
        ...state,
        notes: {
          ...state.notes,
          value: action.notes,
        },
      }
    }
    case SET_SEMINAR_NOTE: {
      return {
        ...state,
        seminarNote: {
          ...state.seminarNote,
          value: action.note,
        },
      }
    }
    case SET_ASSET: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          value: action.value,
        },
      }
    }
    default:
      return state
  }
}

//actions
export const setSeminarAppointment = seminarAppointment => ({
  type: SET_SEMINAR_APPOINTMENT,
  seminarAppointment,
})

export const setAsset = (id, value) => ({
  type: SET_ASSET,
  id,
  value,
})

export const setBuschflexNotes = notes => ({
  type: SET_NOTES,
  notes,
})

export const setSeminarNote = note => ({
  type: SET_SEMINAR_NOTE,
  note,
})

//selector
export const getSeminarAppointment = state => state.buschflex.seminarAppointment
export const getSeminarNote = state => state.buschflex.seminarNote
export const getBuschflexData = state => state.buschflex

export default buschflex
