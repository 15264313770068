import { Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import ImageComponent from "../../../../../../components/ImageComponent";

//Images
import SCU200Image from "../../../../../../assets/neuheiten-2024/abb/scu-200/scu-200.webp";

function SCU200() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title="SCU 200"
            subTitle="Energiemonitoring mit ABB InSite"
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  "2 TE Baubreite",
                  "Steuereinheit der Energiemonitoringlösung InSite für Shops, Gewerbe, Zweckgebäude und Industrieanwendungen",
                  "Integrierter Webserver für lokales Energiemonitoring",
                  "Anschluss von 16 Modbus RTU Geräten, 16 Modbus TCP Geräten und 32 InSite-Bus Geräte",
                  "Modular erweiterbar: Energiemessmodul mit Stromwandlern, Wireless MBus-Modul, Spannungsversorgung, Digitaler Hilfsschalter",
                  "Integrierbar in übergeordnete Energiemanagement-Lösungen wie ABB Ability Energy Manager, ABB Ability Building Analyzer, ABB OPTIMAX oder Eisbär Scada",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}>
              <ImageComponent
                src={SCU200Image}
                alt="SCU 200"
                col={12}
                wrapperHeight="45vh"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default SCU200;
