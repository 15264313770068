import { Box, Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";

// Images
import BuschWelcomeInnenstationImage from "../../../../../../assets/neuheiten-2024/bje/busch-welcome-innenstation/busch-welcome-4-2.webp";
import BetterImage from "../../../../../../components/BetterImage";

function BuschWelcomeInnenstation() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Welcome®
                <br />
                Innenstation 4.3 Basic
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  "Neue Innenstation für Busch-Welcome 2-Draht.",
                  "Basis Variante ohne APP-Anbindung.",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}>
              <Box
                height="50dvh"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <BetterImage
                  align="center"
                  height="auto"
                  width="30dvw"
                  src={BuschWelcomeInnenstationImage}
                  alt="Busch-Welcome© Innenstation, 4.3 Basic"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default BuschWelcomeInnenstation;
