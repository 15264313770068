export function downloadFile(url) {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function (e) {
    if (this.status == 200) {
      var myBlob = this.response;
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(myBlob);
      link.download = url.split("/").pop();
      link.click();
    }
  };
  xhr.send();
}
