import { Box, Typography } from "@material-ui/core";

export default function QDialogTitle({ title, subTitle, disablePaddingTop }) {
  return (
    <Box paddingBottom={3} paddingTop={disablePaddingTop ? 0 : 3}>
      {title && (
        <Typography variant="h3" style={{ fontFamily: "Averta-Bold" }}>
          {title}
        </Typography>
      )}
      {subTitle && <Typography variant="h5">{subTitle}</Typography>}
    </Box>
  );
}
