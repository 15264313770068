import { Box, Grid } from "@material-ui/core";
import backgroundIntro from "../../../../../../assets/_backgrounds/neuheiten-2024/1.webp";
import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import BetterImage from "../../../../../../components/BetterImage";

// Images
import VMSImage from "../../../../../../assets/neuheiten-2024/suj/vms-funktionsgehause/vms-funktionsgehause.webp";

export default function VMS() {
  return (
    <Slide
      background
      actions={[
        {
          type: "icon",
          link: "https://library.e.abb.com/public/162606e7843c427a8be510b159b35643/9AKK108469A1514_Rev_A_VMS_DE_Katalog_202403_72dpi.pdf?x-sign=rxHBOMTlmTe9RNuJ3tS7spOK29Jz7dQxiF2WoGh8DUutjA0pUeC2TlwB2qOfCQ37",
          target: "_blank",
          img: "laptop",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="VMS Funktionsgehäuse" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  "5 modulare Basisgehäuse mit innovativer Verbindungstechnik",
                  "Eine Vielzahl von Funktionsgehäusen, getestet und geprüft gemäß DIN EN 61439 (VDE 0660-600) bis zu 1100 A",
                  "Vorbereitet für eine Vielzahl von ABB Geräten",
                  "Umfangreiches Zubehör wie vorkonfigurierte Sammelschienen",
                  "Sichere Planung mit StriePlan",
                  "100 % recycelbares und halogenfreies Material",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <BetterImage
                  align="center"
                  height="auto"
                  width="60%"
                  src={VMSImage}
                  alt="VMS Funktionsgehäuse"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
