import { h } from "preact";
import { Grid } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";
import ImageComponent from "../../../../../../components/ImageComponent";

export default function Rauchwarnmelder() {
  return (
    <Slide
      actions={[
        {
          type: "pdf",
          img: "preisbeispiel",
          path: "../../../../../../assets/pdf/preisbeispiele/Busch-free-at-home/Preisbeispiel Busch-free@home - Rauchwarnmelder.pdf",
        },
      ]}
      background={{
        width: "60%",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-free@home<sup>®</sup>
              </>
            }
            subTitle="Anwendung Rauchwarnmelder"
          />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={5}>
              <QList
                title="Vorteile"
                items={[
                  "Fluchtwege öffnen, wenn es brennt",
                  "Licht einschalten, wenn es brennt",
                  "Elekt. Fenster öffnen bei CO-Alarm",
                  "Kein Kabelzug notwendig",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={7}>
              <ImageComponent
                wrapperWidth="80%"
                wrapperHeight="60vh"
                src="../../../../../../assets/free-at-home/Anwendungen/anwendung-rauchwarnmelder.webp"
                alt="Anwendung Rauchwarnmelder"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
