// Images Schulung
import ImageSchulung1 from "../../assets/neuheiten-2024/schulung/1.webp";
import ImageSchulung2 from "../../assets/neuheiten-2024/schulung/2.webp";
import ImageSchulung3 from "../../assets/neuheiten-2024/schulung/3.webp";
import ImageSchulung4 from "../../assets/neuheiten-2024/schulung/4.webp";
import ImageSchulung5 from "../../assets/neuheiten-2024/schulung/5.webp";
import ImageSchulung6 from "../../assets/neuheiten-2024/schulung/6.webp";

// Images Marketing
import ImageMarketing1 from "../../assets/neuheiten-2024/marketing/1.webp";
import ImageMarketing2 from "../../assets/neuheiten-2024/marketing/2.webp";
import ImageMarketing3 from "../../assets/neuheiten-2024/marketing/3.jpg";

export const neuheiten2024SchulungItems = {
  title: "Schulung",
  actions: [
    {
      type: "icon",
      img: "youtube",
      label: "ABB Playlist",
      link: "https://www.youtube.com/playlist?list=PLW1uFXqHQgueUmZRyQk9riJLUxXTNbSg3",
      target: "_blank",
    },
    {
      type: "icon",
      img: "youtube",
      label: "Busch-Jaeger Playlist",
      link: "https://www.youtube.com/playlist?list=PLJSuwpTxl86hmOEMLymHXH2HK9MMV8aCG",
      target: "_blank",
    },
  ],
  items: [
    {
      title: (
        <>
          Busch-Jaeger: Unsere Highlights
          <br />
          Light + Building 2024
        </>
      ),
      img: ImageSchulung1,
      link: {
        href: "https://www.youtube.com/watch?v=9fZ62HFl71g",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          Klassische Elektroinstallation
          <br />
          jetzt auch wireless
        </>
      ),
      img: ImageSchulung2,
      link: {
        href: "https://www.youtube.com/watch?v=b0FrIRW_0x4",
        target: "_blank",
      },
    },
    {
      title: "Der Wächter für alle",
      img: ImageSchulung3,
      link: {
        href: "https://www.youtube.com/watch?v=BDVZH6C6y6M",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          ABB: Unsere Highlights
          <br />
          Light + Building 2024
        </>
      ),
      img: ImageSchulung4,
      link: {
        href: "https://www.youtube.com/watch?v=SGos3_etHfs",
        target: "_blank",
      },
    },
    {
      title: "Komplettschränke für Mess- und Wandlertechnik",
      img: ImageSchulung5,
      link: {
        href: "https://www.youtube.com/watch?v=4l1zzrZzKTc",
        target: "_blank",
      },
    },
    {
      title: "Energiemanagement von ABB",
      img: ImageSchulung6,
      link: {
        href: "https://www.youtube.com/watch?v=CkCqx1LajoE",
        target: "_blank",
      },
    },
  ],
};
export const neuheiten2024MarketingItems = {
  title: "Marketing",
  items: [
    {
      title: "Innomag",
      img: ImageMarketing1,
      link: {
        href: "https://view.publitas.com/busch-jaeger-elektro-gmbh/innomag/page/1",
        target: "_blank",
      },
    },
    {
      title: "Bestellunterlage - Neuheiten 2024",
      img: ImageMarketing2,
      pdf: "../../assets/pdf/neuheiten-2024/Bestellunterlage-Neuheiten-2024.pdf",
    },
    {
      title: "Fachinfo Neuheiten 03/2024",
      img: ImageMarketing3,
      link: {
        href: "https://search.abb.com/library/Download.aspx?DocumentID=2CKA000001A1901&LanguageCode=de&DocumentPartId=&Action=Launch",
        target: "_blank",
      }
    },    
  ],
};
