export default function DialogTable() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 511 322"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 41">
        <text
          id="Externes Eingangssignal"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Semibold"
          font-size="10"
          letter-spacing="0em"
        >
          <tspan x="210.366" y="318.837">
            Externes Eingangssignal
          </tspan>
        </text>
        <g id="Group 37">
          <path
            id="Line 50"
            d="M40 299L50 304.774V293.226L40 299ZM240.5 299L230.5 293.226V304.774L240.5 299ZM49 300H231.5V298H49V300Z"
            fill="black"
          />
          <path
            id="Line 51"
            d="M294.5 299L304.5 304.774V293.226L294.5 299ZM496 299L486 293.226V304.774L496 299ZM303.5 300H487V298H303.5V300Z"
            fill="black"
          />
          <line
            id="Line 52"
            x1="228"
            y1="299"
            x2="311"
            y2="299"
            stroke="black"
            stroke-width="2"
          />
          <text
            id="K&#195;&#188;hlen 0,5 V - 4,5 V"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="89.1992" y="292.837">
              K&#xfc;hlen 0,5 V - 4,5 V
            </tspan>
          </text>
          <text
            id="Heizen 5,5 V - 10 V"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="355.475" y="292.837">
              Heizen 5,5 V - 10 V
            </tspan>
          </text>
        </g>
        <text
          id="K&#195;&#188;hlen"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Regular"
          font-size="10"
          letter-spacing="0em"
        >
          <tspan x="157.155" y="125.847">
            K&#xfc;hlen
          </tspan>
        </text>
        <text
          id="Heizen"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Regular"
          font-size="10"
          letter-spacing="0em"
        >
          <tspan x="362.438" y="201.847">
            Heizen
          </tspan>
        </text>
        <text
          id="Volumenstrom"
          transform="matrix(0 -1 1 0 0 163)"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Semibold"
          font-size="10"
          letter-spacing="0em"
        >
          <tspan x="0.0595703" y="8.83691">
            Volumenstrom
          </tspan>
        </text>
        <rect
          id="Rectangle 20"
          x="246"
          y="2"
          width="46"
          height="258"
          fill="black"
        />
        <path
          id="Line 53"
          d="M496 259.5L491 256.613V262.387L496 259.5ZM41 260H491.5V259H41V260Z"
          fill="black"
        />
        <path
          id="Line 54"
          d="M41 2L38.1132 7H43.8868L41 2ZM41.5 260L41.5 6.5H40.5L40.5 260H41.5Z"
          fill="black"
        />
        <text
          id="0 %"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Semibold"
          font-size="8"
          letter-spacing="0em"
        >
          <tspan x="21.6094" y="262.27">
            0 %
          </tspan>
        </text>
        <g id="Group 39">
          <text
            id="0"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="38.9375" y="272.27">
              0
            </tspan>
          </text>
          <text
            id="0.5"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="57.4062" y="272.27">
              0.5
            </tspan>
          </text>
          <text
            id="1.5"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="103.156" y="272.27">
              1.5
            </tspan>
          </text>
          <text
            id="2"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="128.742" y="272.27">
              2
            </tspan>
          </text>
          <text
            id="2.5"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="149.094" y="272.27">
              2.5
            </tspan>
          </text>
          <text
            id="3"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="174.602" y="272.27">
              3
            </tspan>
          </text>
          <text
            id="3.5"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="195.008" y="272.27">
              3.5
            </tspan>
          </text>
          <text
            id="4"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="219.375" y="272.27">
              4
            </tspan>
          </text>
          <text
            id="4.5"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="240.82" y="272.27">
              4.5
            </tspan>
          </text>
          <text
            id="5"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="265.374" y="272.248">
              5
            </tspan>
          </text>
          <text
            id="5.5"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="285.617" y="272.248">
              5.5
            </tspan>
          </text>
          <text
            id="6"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="310.298" y="272.248">
              6
            </tspan>
          </text>
          <text
            id="6.5"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="330.532" y="272.248">
              6.5
            </tspan>
          </text>
          <text
            id="7"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="354.985" y="272.248">
              7
            </tspan>
          </text>
          <text
            id="7.5"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="374.414" y="272.248">
              7.5
            </tspan>
          </text>
          <text
            id="8"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="398.243" y="272.248">
              8
            </tspan>
          </text>
          <text
            id="8.5"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="418.454" y="272.248">
              8.5
            </tspan>
          </text>
          <text
            id="9"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="443.315" y="272.248">
              9
            </tspan>
          </text>
          <text
            id="9.5"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="463.683" y="272.248">
              9.5
            </tspan>
          </text>
          <text
            id="10"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="489.742" y="272.248">
              10
            </tspan>
          </text>
          <text
            id="1"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="84.8047" y="272.27">
              1
            </tspan>
          </text>
        </g>
        <text
          id="10 %"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Semibold"
          font-size="8"
          letter-spacing="0em"
        >
          <tspan x="18.4141" y="236.27">
            10 %
          </tspan>
        </text>
        <text
          id="20 %"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Semibold"
          font-size="8"
          letter-spacing="0em"
        >
          <tspan x="17.3516" y="212.27">
            20 %
          </tspan>
        </text>
        <text
          id="30 %"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Semibold"
          font-size="8"
          letter-spacing="0em"
        >
          <tspan x="17.25" y="186.27">
            30 %
          </tspan>
        </text>
        <text
          id="40 %"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Semibold"
          font-size="8"
          letter-spacing="0em"
        >
          <tspan x="17.0234" y="160.27">
            40 %
          </tspan>
        </text>
        <text
          id="50 %"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Semibold"
          font-size="8"
          letter-spacing="0em"
        >
          <tspan x="17.1719" y="135.27">
            50 %
          </tspan>
        </text>
        <text
          id="50 %_2"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Regular"
          font-size="8"
          letter-spacing="0em"
        >
          <tspan x="133.383" y="142.277">
            50 %
          </tspan>
        </text>
        <text
          id="25 %"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Regular"
          font-size="8"
          letter-spacing="0em"
        >
          <tspan x="180.211" y="205.277">
            25 %
          </tspan>
        </text>
        <text
          id="10 %_2"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Regular"
          font-size="8"
          letter-spacing="0em"
        >
          <tspan x="343.773" y="242.277">
            10 %
          </tspan>
        </text>
        <text
          id="20 %_2"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Regular"
          font-size="8"
          letter-spacing="0em"
        >
          <tspan x="395.617" y="216.277">
            20 %
          </tspan>
        </text>
        <text
          id="30 %_2"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Regular"
          font-size="8"
          letter-spacing="0em"
        >
          <tspan x="446.508" y="191.277">
            30 %
          </tspan>
        </text>
        <text
          id="40 %_2"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Regular"
          font-size="8"
          letter-spacing="0em"
        >
          <tspan x="491.273" y="168.277">
            40 %
          </tspan>
        </text>
        <text
          id="60 %"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Semibold"
          font-size="8"
          letter-spacing="0em"
        >
          <tspan x="16.9609" y="109.27">
            60 %
          </tspan>
        </text>
        <text
          id="70 %"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Semibold"
          font-size="8"
          letter-spacing="0em"
        >
          <tspan x="17.7656" y="84.2695">
            70 %
          </tspan>
        </text>
        <text
          id="75 %"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Regular"
          font-size="8"
          letter-spacing="0em"
        >
          <tspan x="85.4766" y="70.2773">
            75 %
          </tspan>
        </text>
        <text
          id="80 %"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Semibold"
          font-size="8"
          letter-spacing="0em"
        >
          <tspan x="17.0391" y="58.2695">
            80 %
          </tspan>
        </text>
        <text
          id="90 %"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Semibold"
          font-size="8"
          letter-spacing="0em"
        >
          <tspan x="16.9375" y="33.2695">
            90 %
          </tspan>
        </text>
        <text
          id="100 %"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Semibold"
          font-size="8"
          letter-spacing="0em"
        >
          <tspan x="13.3516" y="7.26953">
            100 %
          </tspan>
        </text>
        <path id="Line 55" d="M62 2L246.405 258.707" stroke="black" />
        <path id="Line 56" d="M497 157.5L292 259.5" stroke="black" />
        <path id="Line 57" d="M41 233.5L496 233.5" stroke="black" />
        <path id="Line 58" d="M41 209L496 209" stroke="black" />
        <path id="Line 59" d="M41 183.5L496 183.5" stroke="black" />
        <path id="Line 60" d="M41 158L496 158" stroke="black" />
        <path id="Line 61" d="M41 132.5L496 132.5" stroke="black" />
        <path id="Line 62" d="M41 107L496 107" stroke="black" />
        <path id="Line 63" d="M41 81.5L496 81.5" stroke="black" />
        <path id="Line 64" d="M41 56L496 56" stroke="black" />
        <path id="Line 65" d="M41 30.5L496 30.5" stroke="black" />
        <path id="Line 66" d="M41 2L496 2" stroke="black" />
        <g id="Group 38">
          <rect
            id="Rectangle 21"
            opacity="0.5"
            x="41"
            y="2"
            width="21"
            height="258"
            fill="black"
            fill-opacity="0.6"
          />
          <text
            id="Niedriges oder verlorenes Steuersignal"
            transform="matrix(0 -1 1 0 44 239)"
            fill="white"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Semibold"
            font-size="12"
            letter-spacing="0em"
          >
            <tspan x="0.0957031" y="10.9043">
              Niedriges oder verlorenes Steuersignal
            </tspan>
          </text>
        </g>
        <text
          id="Wechselbereich von K&#195;&#188;hlung zu Heizung"
          transform="matrix(0 -1 1 0 261 246)"
          fill="white"
          xmlSpace="preserve"
          style="white-space: pre"
          font-family="Averta-Semibold"
          font-size="12"
          letter-spacing="0em"
        >
          <tspan x="0" y="10.9043">
            Wechselbereich von K&#xfc;hlung zu Heizung
          </tspan>
        </text>
      </g>
    </svg>
  );
}
