const SET_NOTES = "playbook/flexline/SET_NOTES";

export const initialState = {
  notes: {
    description: "Notizen für das Kapitel",
    value: "",
    type: "info",
    orderID: null,
  },
};

const flexline = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTES: {
      return {
        ...state,
        notes: {
          ...state.notes,
          value: action.notes,
        },
      };
    }
    default:
      return state;
  }
};

//actions
export const setFlexLineNotes = (notes) => ({
  type: SET_NOTES,
  notes,
});

//selector
export const getFlexLineNotes = (state) => state.flexline.notes;
export const getFlexLineData = (state) => state.flexline;

export default flexline;
