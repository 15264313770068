import { Grid } from "@material-ui/core";
import React from "react";
import QHeader from "../../../../../../components/QHeader";
import QTable from "../../../../../../components/QTable";
import Slide from "../../../../../../components/Slide";

const tableContent = [
  {
    name: "Row1",
    values: [
      {
        label: (
          <img
            height="50"
            width="50"
            src="../../../../../../assets/welcome-ip/Anwendungen/Einfamilienhaus/ip-touch.webp"
          />
        ),
      },
      { label: 'IPTouch 7"' },
      { label: "6" },
      { label: "H81381P1-S-03" },
      { label: "5.502,00" },
    ],
  },
  {
    name: "Row2",
    values: [
      {
        label: (
          <img
            height="50"
            width="50"
            src="../../../../../../assets/welcome-ip/Anwendungen/Einfamilienhaus/schaltaktor.webp"
          />
        ),
      },
      { label: 'IP Schaltaktor"' },
      { label: "1" },
      { label: "H8304-03" },
      { label: "133,00" },
    ],
  },
  {
    name: "Row3",
    values: [
      {
        label: (
          <img
            height="50"
            width="50"
            src="../../../../../../assets/welcome-ip/Anwendungen/Einfamilienhaus/sap-lite.webp"
          />
        ),
      },
      { label: "Smart Access Point Lite" },
      { label: "1" },
      { label: "D04012-03" },
      { label: "482,00" },
    ],
  },
  {
    name: "Row4",
    values: [
      {
        label: (
          <img
            height="50"
            width="50"
            src="../../../../../../assets/welcome-ip/Anwendungen/Einfamilienhaus/kameramodul.webp"
          />
        ),
      },
      {
        label: (
          <>
            Busch-Welcome<sup>®</sup> IP Kameramodul 720p
          </>
        ),
      },
      { label: "1" },
      { label: "H851381M-S-03" },
      { label: "1.128,00" },
    ],
  },
  {
    name: "Row5",
    values: [
      {
        label: (
          <img
            height="50"
            width="50"
            src="../../../../../../assets/welcome-ip/Anwendungen/Einfamilienhaus/kameramodul.webp"
          />
        ),
      },
      { label: "Frontplatte Video" },
      { label: "1" },
      { label: "41384CF-S-03" },
      { label: "308,00" },
    ],
  },
  {
    name: "Row6",
    values: [
      {
        label: (
          <img
            height="50"
            width="50"
            src="../../../../../../assets/welcome-ip/Anwendungen/Einfamilienhaus/tastenmodul.webp"
          />
        ),
      },
      {
        label: (
          <>
            Busch-Welcome<sup>®</sup> IP Tastenmodul 3/6-fach
          </>
        ),
      },
      { label: "2" },
      { label: "51381SP4-03" },
      { label: "133,40" },
    ],
  },
  {
    name: "Row7",
    values: [
      {
        label: (
          <img
            height="50"
            width="50"
            src="../../../../../../assets/welcome-ip/Anwendungen/Einfamilienhaus/montagedose.webp"
          />
        ),
      },
      { label: "" },
      { label: "" },
      { label: "" },
      { label: "" },
    ],
  },
  {
    name: "Row8",
    values: [
      { label: "" },
      { label: "" },
      { label: "" },
      {
        label: "Summe",
        fontFamily: "Averta-Bold",
        backgroundColor: "var(--grey-20)",
      },
      {
        label: "9.257,49 €",
        fontFamily: "Averta-Bold",
        backgroundColor: "var(--grey-20)",
      },
    ],
  },
];

export default function PreisbeispielMehrfamilienhaus() {
  return (
    <Slide>
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader title="Preisbeispiel Mehrfamilienhaus" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <QTable
                stickyHeader={true}
                maxHeight="70vh"
                header={[
                  { label: "#" },
                  { label: "Beschreibung" },
                  { label: "Anzahl" },
                  { label: "Artikelnr." },
                  { label: "Summe" },
                ]}
                content={tableContent}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
