import { Box, Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import BetterImage from "../../../../../../components/BetterImage";
import QList from "../../../../../../components/QList";

// Images
import SmartSwitchWirelessImage from "../../../../../../assets/neuheiten-2024/bje/smart-switch-wireless/smart-switch-wireless.webp";

function SmartSwitchWireless() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Smart-Switch wireless" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                items={[
                  <>Smarter Schalter zum Kombinieren mit anderen Busch-flexTronics<sup>®</sup> wireless Geräten</>,
                  "Anbindung an Busch-free@home®",
                  "Batterieloser Schalter, kein Anschluss von Netzspannung (230 V~) erforderlich",
                  "Sendeenergie wird beim Schalten generiert (Energy Harvesting)",
                  "Kombinierbar mit den Busch-Jaeger Schalterprogrammen carat®, Busch-dynasty®, pur edelstahl, solo®, Busch-axcent®, Busch-axcent® pur und future® linear.",
                  "Mittels beiliegendem Klebeband und Befestigungsplatte frei im Raum montierbar",
                  "Inkl. Befestigungsplatte zur Montage auf glatten Wänden oder auf Schalterdosen",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}>
              <Box
                height="75dvh"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <BetterImage
                  align="center"
                  height="auto"
                  width="30dvw"
                  src={SmartSwitchWirelessImage}
                  alt="Smart-Switch wireless"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default SmartSwitchWireless;
