import { h } from "preact";
import { useDispatch, useSelector } from "react-redux";
import {
  setCwaNotes,
  getCwaData,
  setAsset,
} from "../../../redux/modules/chapterCwa";
import QActionPaper from "../../QActionPaper";
import QIncrementGroup from "../../QIncrementGroup";
import QTextFieldAction from "../../QTextFieldAction";
import { Grid } from "@material-ui/core";

export default function LichtschalterPage({ context }) {
  const dispatch = useDispatch();
  const cwaData = useSelector((state) => getCwaData(state));
  const {
    notes,
    assetMustertafelSchalter,
    assetMustertafelElektronik,
    assetMusterkofferSchalter,
    assetKatalogLichtschalter,
    assetKatalogCwa,
    assetFlyerNaeherungsschalter,
    assetFlyerRauchUCOMelder,
    assetRatgeberRenovieren,
    assetBroschuereWohnungsbau,
  } = cwaData;

  const handleSetSetupField = (valueSet) => {
    switch (valueSet.id) {
      case "notes":
        {
          dispatch(setCwaNotes(valueSet.value));
        }
        break;
    }
  };

  const handleSetAsset = (id, value) => {
    dispatch(setAsset(id, value));
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <QActionPaper title="Unterlagen & Werbung" context={context}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetKatalogLichtschalter.value}
                valueID="assetKatalogLichtschalter"
                label="Prospekt Lichtschalter"
                mode={1}
                setAsset={handleSetAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetKatalogCwa.value}
                valueID="assetKatalogCwa"
                label="Katalog 2023/2024"
                mode={1}
                setAsset={handleSetAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetFlyerNaeherungsschalter.value}
                valueID="assetFlyerNaeherungsschalter"
                label="Flyer Näherungsschalter"
                mode={1}
                setAsset={handleSetAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetFlyerRauchUCOMelder.value}
                valueID="assetFlyerRauchUCOMelder"
                label="Prospekt Neubau"
                mode={1}
                setAsset={handleSetAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetRatgeberRenovieren.value}
                valueID="assetRatgeberRenovieren"
                label="Prospekt Ratgeber Renovieren"
                mode={1}
                setAsset={handleSetAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetBroschuereWohnungsbau.value}
                valueID="assetBroschuereWohnungsbau"
                label="Broschüre Wohnungsbau"
                mode={1}
                setAsset={handleSetAsset}
              />
            </Grid>
          </Grid>
        </QActionPaper>
      </Grid>
      <Grid item>
        <QActionPaper title="Notizen" context={context}>
          <QTextFieldAction
            value={notes.value}
            valueID="notes"
            setSetupField={handleSetSetupField}
          />
        </QActionPaper>
      </Grid>
    </Grid>
  );
}
