import HCCS from "./files/HCCS.webp"
import BCIS from "./files/BCIS.webp"

export default function HeizenUndKuehlenSVG() {
  const id = "heizen-und-kuehlen-svg";
  return (
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      id={id}
      viewBox="0 0 785.7 494.29"
    >
      <defs>
        <style>{`#${id} g, #${id} text, #${id} image, #${id} polyline, #${id} path, #${id} rect { pointer-events: none; } #${id} rect.hotspot { pointer-events: all; }#${id} text { font-weight: initial !important; font-family: Averta-Semibold !important; } #${id} use { pointer-events: none; } #${id} .cls-1{letter-spacing:.01em;}#${id} .cls-2{letter-spacing:0em;}#${id} .cls-3{letter-spacing:0em;}#${id} .cls-4{letter-spacing:0em;}#${id} .cls-5{fill:#fff;}#${id} .cls-6{fill:#3d703b;}#${id} .cls-7{letter-spacing:-.03em;}#${id} .cls-8{letter-spacing:-.02em;}#${id} .cls-9{font-size:14px;}#${id} .cls-9,#${id} .cls-10{font-family:Averta-Bold, 'Averta-Regular';font-weight:700;}#${id} .cls-11{letter-spacing:-.01em;}#${id} .cls-12{letter-spacing:-.02em;}#${id} .cls-13{fill:#696969;}#${id} .cls-13,#${id} .cls-14,#${id} .cls-15{stroke-miterlimit:10;}#${id} .cls-13,#${id} .cls-15{stroke:#000;}#${id} .cls-16{letter-spacing:-.02em;}#${id} .cls-14{stroke:#3d703b;stroke-width:2px;}#${id} .cls-14,#${id} .cls-15{fill:none;}#${id} .cls-17{letter-spacing:-.02em;}#${id} .cls-10{font-size:18px;}#${id} .cls-18{letter-spacing:-.01em;}#${id} .cls-19{letter-spacing:0em;}#${id} .cls-20{letter-spacing:0em;}#${id} .cls-21{letter-spacing:-.01em;}#${id} .cls-22{letter-spacing:-.01em;}#${id} .cls-23{letter-spacing:-.01em;}#${id} .cls-24{font-family:Averta-Regular, 'Averta-Regular';font-size:6.65px;}#${id} .cls-25{letter-spacing:-.03em;}`}</style>
      </defs>
      <rect x="493.23" y="288.72" width="292.48" height="26.74" />
      <rect x="551.39" y="348.73" width="234.31" height="23.43" />
      <rect x="532.94" y="152.68" width="4.99" height="82.24" />
      <rect x="568.04" y="152.68" width="4.99" height="195.36" />
      <rect x="532.94" y="258.84" width="4.99" height="29.37" />
      <circle class="cls-5" cx="552.9" cy="143.41" r="19.11" />
      <path
        class="cls-5"
        d="m552.9,117.65c-14.21,0-25.76,11.55-25.76,25.76s11.55,25.76,25.76,25.76,25.76-11.57,25.76-25.76-11.57-25.76-25.76-25.76Zm0,46.53c-11.47,0-20.77-9.32-20.77-20.77s9.31-20.77,20.77-20.77,20.77,9.32,20.77,20.77-9.32,20.77-20.77,20.77Z"
      />
      <path d="m552.9,115.99c-15.12,0-27.42,12.3-27.42,27.42s12.3,27.42,27.42,27.42,27.42-12.3,27.42-27.42-12.31-27.42-27.42-27.42Zm0,53.18c-14.21,0-25.76-11.57-25.76-25.76s11.55-25.76,25.76-25.76,25.76,11.55,25.76,25.76-11.57,25.76-25.76,25.76Z" />
      <circle class="cls-5" cx="552.9" cy="143.41" r="19.11" />
      <path d="m552.9,122.64c-11.47,0-20.77,9.32-20.77,20.77s9.31,20.77,20.77,20.77,20.77-9.32,20.77-20.77-9.32-20.77-20.77-20.77Zm0,39.88c-10.54,0-19.11-8.57-19.11-19.11s8.57-19.11,19.11-19.11,19.11,8.57,19.11,19.11-8.57,19.11-19.11,19.11Z" />
      <rect x="529.61" y="285.43" width="11.63" height="3.32" />
      <rect x="564.82" y="345.57" width="11.63" height="3.32" />
      <rect x="564.72" y="259.15" width="11.63" height="3.32" />
      <rect x="564.72" y="230.9" width="11.63" height="3.32" />
      <rect x="529.61" y="257.18" width="11.63" height="3.32" />
      <rect x="529.61" y="233.92" width="11.63" height="3.32" />
      <path d="m526.8,189.24c-.4-2.94-2.92-5.22-5.98-5.22-3.32,0-6.05,2.71-6.05,6.05s2.73,6.05,6.05,6.05c3.06,0,5.58-2.28,5.98-5.22h12.6v-1.66h-12.6Zm-5.98,5.22c-2.41,0-4.39-1.98-4.39-4.39s1.98-4.39,4.39-4.39,4.39,1.96,4.39,4.39-1.96,4.39-4.39,4.39Z" />
      <path d="m561.7,189.24c-.4-2.94-2.92-5.22-5.98-5.22-3.32,0-6.05,2.71-6.05,6.05s2.73,6.05,6.05,6.05c3.06,0,5.58-2.28,5.98-5.22h12.6v-1.66h-12.6Zm-5.98,5.22c-2.41,0-4.39-1.98-4.39-4.39s1.98-4.39,4.39-4.39,4.39,1.96,4.39,4.39-1.96,4.39-4.39,4.39Z" />
      <path d="m526.87,246.67c-.4-2.94-2.92-5.22-5.98-5.22-3.32,0-6.05,2.71-6.05,6.05s2.73,6.05,6.05,6.05c3.06,0,5.58-2.28,5.98-5.22h10.93v-1.66h-10.93Zm-5.98,5.22c-2.41,0-4.39-1.98-4.39-4.39s1.98-4.39,4.39-4.39,4.39,1.96,4.39,4.39-1.96,4.39-4.39,4.39Z" />
      <rect x="548.72" y="244.6" width="22.53" height="4.99" />
      <rect
        x="542.37"
        y="245.47"
        width="11.63"
        height="3.32"
        transform="translate(795.31 -301.05) rotate(90)"
      />
      <rect
        x="552.34"
        y="245.47"
        width="11.63"
        height="3.32"
        transform="translate(805.28 -311.02) rotate(90)"
      />
      <polygon
        class="cls-5"
        points="540.47 237.14 535.52 245.61 530.57 237.14 540.47 237.14"
      />
      <polygon
        class="cls-5"
        points="540.47 257.39 530.57 257.39 535.52 248.92 540.47 257.39"
      />
      <polygon
        class="cls-5"
        points="545.64 242.32 545.64 252.21 537.17 247.27 545.64 242.32"
      />
      <path
        class="cls-5"
        d="m537.17,247.27l8.48,4.94v-9.89l-8.48,4.95Zm0,0l8.48,4.94v-9.89l-8.48,4.95Z"
      />
      <path d="m547.3,239.43l-9.47,5.53,5.53-9.49h-15.69l6.71,11.5.17.3-.18.3-6.7,11.48h15.69l-5.52-9.47,9.46,5.52v-15.67Zm-16.73-2.29h9.9l-4.95,8.48-4.95-8.48Zm9.9,20.26h-9.9l4.95-8.48,4.95,8.48Zm5.17-5.18l-8.48-4.94,8.48-4.95v9.89Z" />
      <path d="m541.26,208.06v-2.71h-11.63v2.71c-2.51,1.81-4.15,4.75-4.15,8.09s1.65,6.28,4.15,8.09v2.71h11.63v-2.71c2.51-1.81,4.15-4.75,4.15-8.09s-1.65-6.28-4.15-8.09Zm1.43,12.13c-.81,1.5-2.08,2.69-3.62,3.44h-.1c-1.08.52-2.28.8-3.54.8s-2.46-.28-3.54-.8h-.05c-.07-.03-.15-.07-.22-.13-.18-.07-.37-.17-.53-.3-.33-.2-.66-.43-.96-.7-.15-.1-.28-.22-.4-.35-.2-.17-.37-.35-.52-.53-.03-.03-.07-.07-.1-.12-.25-.27-.47-.56-.65-.86-.22-.32-.42-.66-.56-1.03-.08-.18-.17-.35-.23-.53-.2-.52-.33-1.06-.43-1.61-.05-.28-.08-.56-.08-.85-.02-.08-.02-.17-.02-.25v-.22c0-.2,0-.38.02-.58-.02-.02-.02-.03,0-.05.02-.2.05-.38.07-.58.08-.63.25-1.25.5-1.83.4-1.06,1.01-2.01,1.79-2.79.27-.3.57-.56.88-.78.15-.13.32-.25.48-.35.17-.12.33-.22.5-.3,1-.57,2.11-.93,3.31-1.05.25-.02.5-.03.75-.03.33,0,.65.02.96.07.96.1,1.86.37,2.68.8.1.05.18.1.27.15.4.18.8.45,1.15.73.17.13.33.27.48.4.15.12.28.25.42.38s.27.28.4.43c.22.25.43.53.61.83.35.53.65,1.1.86,1.71.05.15.12.32.15.48.07.22.12.43.17.65.12.55.18,1.13.18,1.71,0,.85-.13,1.68-.38,2.44-.17.57-.4,1.1-.68,1.6Z" />
      <path
        class="cls-5"
        d="m543.75,216.12c0,.86-.13,1.7-.37,2.48-.17.57-.4,1.1-.68,1.6-.85,1.5-2.14,2.71-3.72,3.44-1.08.52-2.28.8-3.54.8s-2.46-.28-3.54-.8c-.1-.03-.18-.08-.27-.13-.18-.07-.37-.17-.53-.3-.33-.2-.66-.43-.96-.7-.15-.1-.28-.22-.4-.35-.2-.17-.37-.35-.52-.53-.03-.03-.07-.07-.1-.12-.25-.27-.47-.56-.65-.86-.22-.32-.42-.66-.56-1.03-.08-.18-.17-.35-.23-.53-.2-.52-.33-1.06-.43-1.61-.05-.28-.08-.56-.08-.85-.02-.08-.02-.17-.02-.25v-.22c0-.2,0-.38.02-.58-.02-.02-.02-.03,0-.05.02-.2.05-.38.07-.58.07-.4.17-.8.28-1.18.07-.23.13-.43.22-.65.05-.13.12-.25.17-.37.4-.9.95-1.73,1.63-2.43.28-.28.57-.55.88-.78.15-.12.32-.23.48-.35.17-.1.33-.22.5-.3,1-.57,2.11-.93,3.31-1.05.25-.02.5-.03.75-.03.33,0,.65.02.96.07.93.12,1.83.4,2.64.8h.03c.1.05.18.1.27.15.22.12.43.23.63.38.18.12.35.22.52.35.17.13.33.27.48.4.15.12.28.25.42.38s.27.28.4.43c.22.25.43.53.61.83.35.53.65,1.1.86,1.71.05.15.12.32.15.48.07.22.12.43.17.65.1.55.17,1.1.17,1.68Z"
      />
      <polygon points="526.8 217.31 525.7 216.05 535.49 207.54 545.19 216.05 544.1 217.3 535.49 209.74 526.8 217.31" />
      <rect x="621.01" y="152.68" width="4.99" height="82.24" />
      <rect x="656.12" y="152.68" width="4.99" height="195.36" />
      <rect x="621.01" y="258.84" width="4.99" height="29.37" />
      <circle class="cls-5" cx="640.98" cy="143.41" r="19.11" />
      <path
        class="cls-5"
        d="m640.98,117.65c-14.21,0-25.76,11.55-25.76,25.76s11.55,25.76,25.76,25.76,25.76-11.57,25.76-25.76-11.57-25.76-25.76-25.76Zm0,46.53c-11.47,0-20.77-9.32-20.77-20.77s9.31-20.77,20.77-20.77,20.77,9.32,20.77,20.77-9.32,20.77-20.77,20.77Z"
      />
      <path d="m640.98,115.99c-15.12,0-27.42,12.3-27.42,27.42s12.3,27.42,27.42,27.42,27.42-12.3,27.42-27.42-12.31-27.42-27.42-27.42Zm0,53.18c-14.21,0-25.76-11.57-25.76-25.76s11.55-25.76,25.76-25.76,25.76,11.55,25.76,25.76-11.57,25.76-25.76,25.76Z" />
      <circle class="cls-5" cx="640.98" cy="143.41" r="19.11" />
      <path d="m640.98,122.64c-11.47,0-20.77,9.32-20.77,20.77s9.31,20.77,20.77,20.77,20.77-9.32,20.77-20.77-9.32-20.77-20.77-20.77Zm0,39.88c-10.54,0-19.11-8.57-19.11-19.11s8.57-19.11,19.11-19.11,19.11,8.57,19.11,19.11-8.57,19.11-19.11,19.11Z" />
      <rect x="617.69" y="285.43" width="11.63" height="3.32" />
      <rect x="652.9" y="345.57" width="11.63" height="3.32" />
      <rect x="652.79" y="259.15" width="11.63" height="3.32" />
      <rect x="652.79" y="230.9" width="11.63" height="3.32" />
      <rect x="617.69" y="257.18" width="11.63" height="3.32" />
      <rect x="617.69" y="233.92" width="11.63" height="3.32" />
      <path d="m614.87,189.24c-.4-2.94-2.92-5.22-5.98-5.22-3.32,0-6.05,2.71-6.05,6.05s2.73,6.05,6.05,6.05c3.06,0,5.58-2.28,5.98-5.22h12.6v-1.66h-12.6Zm-5.98,5.22c-2.41,0-4.39-1.98-4.39-4.39s1.98-4.39,4.39-4.39,4.39,1.96,4.39,4.39-1.96,4.39-4.39,4.39Z" />
      <path d="m649.77,189.24c-.4-2.94-2.92-5.22-5.98-5.22-3.32,0-6.05,2.71-6.05,6.05s2.73,6.05,6.05,6.05c3.06,0,5.58-2.28,5.98-5.22h12.6v-1.66h-12.6Zm-5.98,5.22c-2.41,0-4.39-1.98-4.39-4.39s1.98-4.39,4.39-4.39,4.39,1.96,4.39,4.39-1.96,4.39-4.39,4.39Z" />
      <path d="m614.95,246.67c-.4-2.94-2.92-5.22-5.98-5.22-3.32,0-6.05,2.71-6.05,6.05s2.73,6.05,6.05,6.05c3.06,0,5.58-2.28,5.98-5.22h10.93v-1.66h-10.93Zm-5.98,5.22c-2.41,0-4.39-1.98-4.39-4.39s1.98-4.39,4.39-4.39,4.39,1.96,4.39,4.39-1.96,4.39-4.39,4.39Z" />
      <rect x="636.8" y="244.6" width="22.53" height="4.99" />
      <rect
        x="630.44"
        y="245.47"
        width="11.63"
        height="3.32"
        transform="translate(883.39 -389.13) rotate(90)"
      />
      <rect
        x="640.41"
        y="245.47"
        width="11.63"
        height="3.32"
        transform="translate(893.36 -399.1) rotate(90)"
      />
      <polygon
        class="cls-5"
        points="628.55 237.14 623.6 245.61 618.65 237.14 628.55 237.14"
      />
      <polygon
        class="cls-5"
        points="628.55 257.39 618.65 257.39 623.6 248.92 628.55 257.39"
      />
      <polygon
        class="cls-5"
        points="633.72 242.32 633.72 252.21 625.24 247.27 633.72 242.32"
      />
      <path
        class="cls-5"
        d="m625.24,247.27l8.48,4.94v-9.89l-8.48,4.95Zm0,0l8.48,4.94v-9.89l-8.48,4.95Z"
      />
      <path d="m635.38,239.43l-9.47,5.53,5.53-9.49h-15.69l6.71,11.5.17.3-.18.3-6.7,11.48h15.69l-5.52-9.47,9.46,5.52v-15.67Zm-16.73-2.29h9.9l-4.95,8.48-4.95-8.48Zm9.9,20.26h-9.9l4.95-8.48,4.95,8.48Zm5.17-5.18l-8.48-4.94,8.48-4.95v9.89Z" />
      <path d="m629.34,208.06v-2.71h-11.63v2.71c-2.51,1.81-4.15,4.75-4.15,8.09s1.65,6.28,4.15,8.09v2.71h11.63v-2.71c2.51-1.81,4.15-4.75,4.15-8.09s-1.65-6.28-4.15-8.09Zm1.43,12.13c-.81,1.5-2.08,2.69-3.62,3.44h-.1c-1.08.52-2.28.8-3.54.8s-2.46-.28-3.54-.8h-.05c-.07-.03-.15-.07-.22-.13-.18-.07-.37-.17-.53-.3-.33-.2-.66-.43-.96-.7-.15-.1-.28-.22-.4-.35-.2-.17-.37-.35-.52-.53-.03-.03-.07-.07-.1-.12-.25-.27-.47-.56-.65-.86-.22-.32-.42-.66-.56-1.03-.08-.18-.17-.35-.23-.53-.2-.52-.33-1.06-.43-1.61-.05-.28-.08-.56-.08-.85-.02-.08-.02-.17-.02-.25v-.22c0-.2,0-.38.02-.58-.02-.02-.02-.03,0-.05.02-.2.05-.38.07-.58.08-.63.25-1.25.5-1.83.4-1.06,1.01-2.01,1.79-2.79.27-.3.57-.56.88-.78.15-.13.32-.25.48-.35.17-.12.33-.22.5-.3,1-.57,2.11-.93,3.31-1.05.25-.02.5-.03.75-.03.33,0,.65.02.96.07.96.1,1.86.37,2.68.8.1.05.18.1.27.15.4.18.8.45,1.15.73.17.13.33.27.48.4.15.12.28.25.42.38s.27.28.4.43c.22.25.43.53.61.83.35.53.65,1.1.86,1.71.05.15.12.32.15.48.07.22.12.43.17.65.12.55.18,1.13.18,1.71,0,.85-.13,1.68-.38,2.44-.17.57-.4,1.1-.68,1.6Z" />
      <path
        class="cls-5"
        d="m631.82,216.12c0,.86-.13,1.7-.37,2.48-.17.57-.4,1.1-.68,1.6-.85,1.5-2.14,2.71-3.72,3.44-1.08.52-2.28.8-3.54.8s-2.46-.28-3.54-.8c-.1-.03-.18-.08-.27-.13-.18-.07-.37-.17-.53-.3-.33-.2-.66-.43-.96-.7-.15-.1-.28-.22-.4-.35-.2-.17-.37-.35-.52-.53-.03-.03-.07-.07-.1-.12-.25-.27-.47-.56-.65-.86-.22-.32-.42-.66-.56-1.03-.08-.18-.17-.35-.23-.53-.2-.52-.33-1.06-.43-1.61-.05-.28-.08-.56-.08-.85-.02-.08-.02-.17-.02-.25v-.22c0-.2,0-.38.02-.58-.02-.02-.02-.03,0-.05.02-.2.05-.38.07-.58.07-.4.17-.8.28-1.18.07-.23.13-.43.22-.65.05-.13.12-.25.17-.37.4-.9.95-1.73,1.63-2.43.28-.28.57-.55.88-.78.15-.12.32-.23.48-.35.17-.1.33-.22.5-.3,1-.57,2.11-.93,3.31-1.05.25-.02.5-.03.75-.03.33,0,.65.02.96.07.93.12,1.83.4,2.64.8h.03c.1.05.18.1.27.15.22.12.43.23.63.38.18.12.35.22.52.35.17.13.33.27.48.4.15.12.28.25.42.38s.27.28.4.43c.22.25.43.53.61.83.35.53.65,1.1.86,1.71.05.15.12.32.15.48.07.22.12.43.17.65.1.55.17,1.1.17,1.68Z"
      />
      <polygon points="614.87 217.31 613.78 216.05 623.56 207.54 633.27 216.05 632.17 217.3 623.56 209.74 614.87 217.31" />
      <rect x="745.65" y="152.68" width="4.99" height="195.36" />
      <rect x="710.75" y="152.68" width="4.99" height="135.53" />
      <rect x="707.46" y="285.43" width="11.63" height="3.32" />
      <rect x="742.63" y="345.57" width="11.63" height="3.32" />
      <circle class="cls-5" cx="730.72" cy="143.41" r="19.11" />
      <path
        class="cls-5"
        d="m730.72,117.65c-14.21,0-25.76,11.55-25.76,25.76s11.55,25.76,25.76,25.76,25.76-11.57,25.76-25.76-11.57-25.76-25.76-25.76Zm0,46.53c-11.47,0-20.77-9.32-20.77-20.77s9.31-20.77,20.77-20.77,20.77,9.32,20.77,20.77-9.32,20.77-20.77,20.77Z"
      />
      <path d="m730.72,115.99c-15.12,0-27.42,12.3-27.42,27.42s12.3,27.42,27.42,27.42,27.42-12.3,27.42-27.42-12.31-27.42-27.42-27.42Zm0,53.18c-14.21,0-25.76-11.57-25.76-25.76s11.55-25.76,25.76-25.76,25.76,11.55,25.76,25.76-11.57,25.76-25.76,25.76Z" />
      <circle class="cls-5" cx="730.72" cy="143.41" r="19.11" />
      <path d="m730.72,122.64c-11.47,0-20.77,9.32-20.77,20.77s9.31,20.77,20.77,20.77,20.77-9.32,20.77-20.77-9.32-20.77-20.77-20.77Zm0,39.88c-10.54,0-19.11-8.57-19.11-19.11s8.57-19.11,19.11-19.11,19.11,8.57,19.11,19.11-8.57,19.11-19.11,19.11Z" />
      <path d="m351.92,355.66c2.94-.4,5.22-2.92,5.22-5.98,0-3.32-2.71-6.05-6.05-6.05s-6.05,2.73-6.05,6.05c0,3.06,2.28,5.58,5.22,5.98v17.58h1.66v-17.58Zm-5.22-5.98c0-2.41,1.98-4.39,4.39-4.39s4.39,1.98,4.39,4.39-1.96,4.39-4.39,4.39-4.39-1.96-4.39-4.39Z" />
      <path d="m351.92,415.48c2.94-.4,5.22-2.92,5.22-5.98,0-3.32-2.71-6.05-6.05-6.05s-6.05,2.73-6.05,6.05c0,3.06,2.28,5.58,5.22,5.98v17.58h1.66v-17.58Zm-5.22-5.98c0-2.41,1.98-4.39,4.39-4.39s4.39,1.98,4.39,4.39-1.96,4.39-4.39,4.39-4.39-1.96-4.39-4.39Z" />
      <path d="m462.89,370.8h-146.05v-9.97h146.05c4.58,0,8.31-3.73,8.31-8.31v-37.68c0-10.08,8.2-18.28,18.28-18.28h4.64v9.97h-4.64c-4.58,0-8.31,3.73-8.31,8.31v37.68c0,10.08-8.2,18.28-18.28,18.28Z" />
      <rect
        x="310.87"
        y="364.12"
        width="14.96"
        height="3.32"
        transform="translate(684.13 47.43) rotate(90)"
      />
      <rect
        x="450.47"
        y="364.12"
        width="14.96"
        height="3.32"
        transform="translate(823.72 -92.17) rotate(90)"
      />
      <rect
        x="468.55"
        y="346.96"
        width="14.96"
        height="3.32"
        transform="translate(952.06 697.24) rotate(-180)"
      />
      <rect
        x="468.55"
        y="317.05"
        width="14.96"
        height="3.32"
        transform="translate(952.06 637.41) rotate(-180)"
      />
      <rect
        x="484.78"
        y="299.89"
        width="14.96"
        height="3.32"
        transform="translate(793.81 -190.71) rotate(90)"
      />
      <path d="m518.19,429.1h-200.88v-9.97h200.88c4.58,0,8.31-3.73,8.31-8.31v-37.68c0-10.08,8.2-18.28,18.28-18.28h6.3v9.97h-6.3c-4.58,0-8.31,3.73-8.31,8.31v37.68c0,10.08-8.2,18.28-18.28,18.28Z" />
      <rect
        x="310.87"
        y="422.39"
        width="14.96"
        height="3.32"
        transform="translate(742.41 105.7) rotate(90)"
      />
      <rect
        x="505.3"
        y="422.39"
        width="14.96"
        height="3.32"
        transform="translate(936.84 -88.73) rotate(90)"
      />
      <rect
        x="524.05"
        y="405.97"
        width="14.96"
        height="3.32"
        transform="translate(1063.05 815.26) rotate(-180)"
      />
      <rect
        x="524.05"
        y="376.06"
        width="14.96"
        height="3.32"
        transform="translate(1063.05 755.44) rotate(-180)"
      />
      <rect
        x="542.75"
        y="358.16"
        width="14.96"
        height="3.32"
        transform="translate(910.06 -190.4) rotate(90)"
      />
      <rect
        class="cls-13"
        x="253.16"
        y="330.61"
        width="64.31"
        height="137.89"
      />
      <text class="cls-24" transform="translate(518.99 192.47)">
        <tspan x="0" y="0">
          T
        </tspan>
      </text>
      <text class="cls-24" transform="translate(553.58 192.47)">
        <tspan x="0" y="0">
          T
        </tspan>
      </text>
      <text class="cls-24" transform="translate(607.07 192.47)">
        <tspan x="0" y="0">
          T
        </tspan>
      </text>
      <text class="cls-24" transform="translate(641.66 192.47)">
        <tspan x="0" y="0">
          T
        </tspan>
      </text>
      <text class="cls-24" transform="translate(517.99 250.17)">
        <tspan x="0" y="0">
          M
        </tspan>
      </text>
      <text class="cls-24" transform="translate(606.06 250.17)">
        <tspan x="0" y="0">
          M
        </tspan>
      </text>
      <text class="cls-24" transform="translate(349.31 352.27)">
        <tspan x="0" y="0">
          T
        </tspan>
      </text>
      <text class="cls-24" transform="translate(349.31 412.09)">
        <tspan x="0" y="0">
          T
        </tspan>
      </text>
      <path d="m404.33,371.18h2.71v-11.63h-2.71c-1.81-2.51-4.75-4.15-8.09-4.15s-6.28,1.65-8.09,4.15h-2.71v11.63h2.71c1.81,2.51,4.75,4.15,8.09,4.15s6.28-1.65,8.09-4.15Zm-12.13,1.43c-1.5-.81-2.69-2.08-3.44-3.62v-.1c-.52-1.08-.8-2.28-.8-3.54s.28-2.46.8-3.54v-.05c.03-.07.07-.15.13-.22.07-.18.17-.37.3-.53.2-.33.43-.66.7-.96.1-.15.22-.28.35-.4.17-.2.35-.37.53-.52.03-.03.07-.07.12-.1.27-.25.56-.47.86-.65.32-.22.66-.42,1.03-.56.18-.08.35-.17.53-.23.52-.2,1.06-.33,1.61-.43.28-.05.56-.08.85-.08.08-.02.17-.02.25-.02h.22c.2,0,.38,0,.58.02.02-.02.03-.02.05,0,.2.02.38.05.58.07.63.08,1.25.25,1.83.5,1.06.4,2.01,1.01,2.79,1.79.3.27.56.57.78.88.13.15.25.32.35.48.12.17.22.33.3.5.57,1,.93,2.11,1.05,3.31.02.25.03.5.03.75,0,.33-.02.65-.07.96-.1.96-.37,1.86-.8,2.68-.05.1-.1.18-.15.27-.18.4-.45.8-.73,1.15-.13.17-.27.33-.4.48-.12.15-.25.28-.38.42s-.28.27-.43.4c-.25.22-.53.43-.83.61-.53.35-1.1.65-1.71.86-.15.05-.32.12-.48.15-.22.07-.43.12-.65.17-.55.12-1.13.18-1.71.18-.85,0-1.68-.13-2.44-.38-.57-.17-1.1-.4-1.6-.68Z" />
      <path
        class="cls-5"
        d="m396.27,373.67c-.86,0-1.7-.13-2.48-.37-.57-.17-1.1-.4-1.6-.68-1.5-.85-2.71-2.14-3.44-3.72-.52-1.08-.8-2.28-.8-3.54s.28-2.46.8-3.54c.03-.1.08-.18.13-.27.07-.18.17-.37.3-.53.2-.33.43-.66.7-.96.1-.15.22-.28.35-.4.17-.2.35-.37.53-.52.03-.03.07-.07.12-.1.27-.25.56-.47.86-.65.32-.22.66-.42,1.03-.56.18-.08.35-.17.53-.23.52-.2,1.06-.33,1.61-.43.28-.05.56-.08.85-.08.08-.02.17-.02.25-.02h.22c.2,0,.38,0,.58.02.02-.02.03-.02.05,0,.2.02.38.05.58.07.4.07.8.17,1.18.28.23.07.43.13.65.22.13.05.25.12.37.17.9.4,1.73.95,2.43,1.63.28.28.55.57.78.88.12.15.23.32.35.48.1.17.22.33.3.5.57,1,.93,2.11,1.05,3.31.02.25.03.5.03.75,0,.33-.02.65-.07.96-.12.93-.4,1.83-.8,2.64v.03c-.05.1-.1.18-.15.27-.12.22-.23.43-.38.63-.12.18-.22.35-.35.52-.13.17-.27.33-.4.48-.12.15-.25.28-.38.42s-.28.27-.43.4c-.25.22-.53.43-.83.61-.53.35-1.1.65-1.71.86-.15.05-.32.12-.48.15-.22.07-.43.12-.65.17-.55.1-1.1.17-1.68.17Z"
      />
      <polygon points="395.09 356.71 396.34 355.62 404.86 365.41 396.34 375.11 395.09 374.02 402.65 365.41 395.09 356.71" />
      <text class="cls-9" transform="translate(603.07 17.06)">
        <tspan class="cls-21" x="0" y="0">
          Kühldecken
        </tspan>
      </text>
      <text class="cls-9" transform="translate(702.69 17.06)">
        <tspan x="0" y="0">
          Lüftung
        </tspan>
      </text>
      <text class="cls-9" transform="translate(526.36 17.06)">
        <tspan x="0" y="0">
          Umluft-
        </tspan>
        <tspan x="-10.18" y="16.8">
          kühlgeräte
        </tspan>
      </text>
      <text class="cls-9" transform="translate(327.82 468.35)">
        <tspan class="cls-12" x="0" y="0">
          Kälteerzeuger
        </tspan>
      </text>
      <polyline class="cls-14" points="51.46 398.51 4.99 398.51 4.99 7.18" />
      <path
        class="cls-6"
        d="m51.27,394.75c2.08,0,3.76,1.68,3.76,3.76s-1.68,3.76-3.76,3.76-3.76-1.68-3.76-3.76,1.68-3.76,3.76-3.76Z"
      />
      <polygon class="cls-6" points="9.97 8.63 4.99 0 0 8.63 9.97 8.63" />
      <line class="cls-14" x1="237.34" y1="174.65" x2="4.99" y2="174.65" />
      <path
        class="cls-6"
        d="m237.16,170.89c2.08,0,3.76,1.68,3.76,3.76s-1.68,3.76-3.76,3.76-3.76-1.68-3.76-3.76,1.68-3.76,3.76-3.76Z"
      />
      <text class="cls-9" transform="translate(59.55 158.8)">
        <tspan class="cls-21" x="0" y="0">
          Kühlvorlaufsollwert
        </tspan>
      </text>
      <text class="cls-9" transform="translate(33.89 92.66) rotate(-90)">
        <tspan x="0" y="0">
          KNX 2x2x0,8
        </tspan>
      </text>
      <text class="cls-9" transform="translate(128.26 486.86)">
        <tspan class="cls-11" x="0" y="0">
          BCI/S
        </tspan>
      </text>
      <polyline
        class="cls-15"
        points="396.34 355.62 396.34 289.12 151.92 289.12 151.92 342.14"
      />
      <polyline
        class="cls-15"
        points="351.09 345.29 351.09 295.12 161.92 295.12 161.92 336"
      />
      <polyline
        class="cls-15"
        points="351.09 405.11 351.09 390.7 337.08 390.7 337.08 301.12 171.92 301.12 171.92 334.88"
      />
      <polyline
        class="cls-15"
        points="283.5 329.86 283.5 307.12 181.92 307.12 181.92 333.77"
      />
      <image
        width="571"
        height="484"
        transform="translate(66.98 332.35) scale(.28)"
        xlinkHref={BCIS}
      />
      <text class="cls-9" transform="translate(337.67 265.88)">
        <tspan x="0" y="0">
          HCC/S
        </tspan>
      </text>
      <polyline
        class="cls-15"
        points="644.12 185.84 644.12 178.21 607.28 178.21 607.28 54.99 298.44 54.99 298.44 136.1"
      />
      <polyline
        class="cls-15"
        points="603.71 190.01 600.52 190.01 600.52 61.66 327.95 61.66 327.95 137.46"
      />
      <polyline
        class="cls-15"
        points="615.55 216.75 593.33 216.75 593.33 69.13 345.47 69.13 345.47 136.78"
      />
      <polyline
        class="cls-15"
        points="603.78 246.71 587.22 246.71 587.22 76.07 363.12 76.07 363.12 137.46"
      />
      <polyline
        class="cls-15"
        points="556.24 184.84 556.24 177.53 500.3 177.53 500.3 82.74 390.96 82.74 390.96 138.14"
      />
      <polyline
        class="cls-15"
        points="515.83 190.01 492.83 190.01 492.83 90.35 405.9 90.35 405.9 136.1"
      />
      <polyline
        class="cls-15"
        points="526.66 216.75 484.68 216.75 484.68 97.57 423.56 97.57 423.56 134.07"
      />
      <polyline
        class="cls-15"
        points="515.9 246.71 476.39 246.71 476.39 104.39 443.25 104.39 443.25 133.39"
      />
      <image
        id="HCC_S_2.2.2.1"
        width="998"
        height="632"
        transform="translate(248.42 106.66) scale(.22)"
        xlinkHref={HCCS}
      />
      <text class="cls-10" transform="translate(693.58 404.11)">
        <tspan class="cls-22" x="0" y="0">
          Kühlkreise
        </tspan>
      </text>
    </svg>
  );
}
