import { h } from "preact";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function CloseIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 44 44" version="1.1">
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="closeIcon">
            <circle
              id="Oval"
              fill="#FFF"
              fill-rule="nonzero"
              cx="22"
              cy="22"
              r="22"
            ></circle>
            <path
              d="M22.5,21.2116429 L29.4445,14.2659286 C29.6217857,14.0886429 29.8561429,14 30.0892857,14 C30.5798571,14 31,14.3934286 31,14.9095 C31,15.1438571 30.9113571,15.377 30.7340714,15.5555 L23.7883571,22.5 L30.7328571,29.4445 C30.9113571,29.623 31,29.8561429 31,30.0892857 C31,30.6077857 30.5762143,31 30.0892857,31 C29.8561429,31 29.6217857,30.9113571 29.4445,30.7340714 L22.5,23.7895714 L15.5555,30.7340714 C15.3782143,30.9113571 15.1438571,31 14.9107143,31 C14.4237857,31 14,30.6077857 14,30.0892857 C14,29.8561429 14.0886429,29.623 14.2671429,29.4445 L21.2116429,22.5 L14.2659286,15.5555 C14.0886429,15.377 14,15.1438571 14,14.9095 C14,14.3934286 14.4201429,14 14.9107143,14 C15.1438571,14 15.3782143,14.0886429 15.5555,14.2659286 L22.5,21.2116429 Z"
              id="Path"
              fill="#000"
              fill-rule="nonzero"
            ></path>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
