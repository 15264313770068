import { Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";

function KNCBinaereingaenge() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Neue KNX-Binäreingänge" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  <>
                    <b>Sicherheit</b>: Die Geräte unterstützen KNX Data Secure
                  </>,
                  <>
                    <b>Zeitersparnis</b>: Durch Vorlagen reduzieren wir die
                    Inbetriebnahmezeit erheblich.
                  </>,
                  <>
                    <b>Portfolio</b>: Ein großes Portfolio, das allen
                    Anforderungen gerecht wird
                  </>,
                  <>
                    <b>Funktionsvielfalt</b>: Umfassende Anwendungen für jeden
                    Anwendungsfall
                  </>,
                  <>
                    <b>Wiedererkennbarkeit</b>: Eine ETS-Anwendung für mehrere
                    Produktlinien
                  </>,
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default KNCBinaereingaenge;
