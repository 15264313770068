import { h } from "preact";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function FeedbackIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        viewBox="0 0 44 44"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Artboard"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <circle
            id="Oval"
            stroke="#000000"
            stroke-width="2"
            fill="#FFFFFF"
            cx="21.5"
            cy="21.5"
            r="20.5"
          ></circle>
          <g
            id="feedback"
            transform="translate(10.000000, 9.000000)"
            fill="#000000"
            fill-rule="nonzero"
          >
            <path
              d="M5.99998975,24 L0,24 L0,18 L5.99998975,18 L5.99998975,24 Z M13.9999775,15 L7.99997753,15 L7.99997753,24 L13.9999775,24 L13.9999775,15 Z M22,11.000004 L16.0000127,11.000004 L16.0000127,24.000004 L22,24.000004 L22,11.000004 Z M0.0239999494,15.87504 L6.02398969,14.65404 L4.30799331,12.94596 L8.49098049,8.838 L11.4919822,11.840004 L22,1.418004 L20.590003,0 L11.4959422,9.015 L8.50498446,6.021996 L2.8919963,11.534004 L1.22499582,9.873996 L0.0239999494,15.87504 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
