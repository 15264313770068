import { h } from "preact";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function MenuIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 44 44" version="1.1">
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="menu">
            <circle id="Oval" fill="#000000" cx="22" cy="22" r="22" />
            <path
              d="M12.1,28.6 L31.9,28.6 L31.9,26.4 L12.1,26.4 L12.1,28.6 Z M12.1,23.1 L31.9,23.1 L31.9,20.9 L12.1,20.9 L12.1,23.1 Z M12.1,15.4 L12.1,17.6 L31.9,17.6 L31.9,15.4 L12.1,15.4 Z"
              id="Shape"
              fill="#FFFFFF"
              fill-rule="nonzero"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
