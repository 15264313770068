import { h } from "preact";
import MissionToZeroPage from "../components/Beratung/Categories/MissionToZeroPage";
import {
  getMissionToZeroData,
  initialState as MissionToZeroInitialState,
} from "../redux/modules/chapterMissionToZero";

import WelcomePage from "../components/Beratung/Categories/WelcomePage";
import { welcomeApplications as WelcomeApplicationData } from "../routes/Chapters/welcome/sections/Anwendungen/slides/Anwendungen";
import {
  getWelcomeData,
  initialState as WelcomeInitialState,
} from "../redux/modules/chapterWelcome";

import KnxPage from "../components/Beratung/Categories/KnxPage";
import { itemList as KnxApplicationData } from "../routes/Chapters/knx/sections/Anwendungen";
import {
  getKnxData,
  initialState as KNXInitialState,
} from "../redux/modules/chapterKnx";

import ComfortLinePage from "../components/Beratung/Categories/ComfortLinePage";
import {
  getComfortLineData,
  initialState as ComfortLineInitialState,
} from "../redux/modules/chapterComfortLine";

import FreeAtHomePage from "../components/Beratung/Categories/FreeAtHomePage";
import { freeAtHomeApplications as FreeAtHomeApplicationData } from "../routes/Chapters/busch-free-at-home/sections/Anwendungen/slides/Anwendungen";
import {
  getFreeAtHomeData,
  initialState as FreeAtHomeInitialState,
} from "../redux/modules/chapterFreeAtHome";

import InstallationsgeraetePage from "../components/Beratung/Categories/InstallationsgeraetePage";
import {
  getInstallationsGeraeteData,
  initialState as InstallationsgeraeteInitialState,
} from "../redux/modules/chapterInstallationsgeraete";

import BeruerungslosesSchalten from "../components/Beratung/Categories/BeruehrungslosesSchalten";
// import { itemList as BeruerungslosesSchaltenApplicatonDataOutside } from "../routes/Chapters/beruehrungsloses-schalten/sections/AnwendungenAussen/slides/Uebersicht";
// import { itemList as BeruerungslosesSchaltenApplicatonDataInternal } from "../routes/Chapters/beruehrungsloses-schalten/sections/AnwendungenInnen/slides/Uebersicht";
import {
  getBeruehrungslosesSchaltenData,
  initialState as BeruerungslosesSchaltenInitialState,
} from "../redux/modules/chapterBeruehrungslosesSchalten";

import LichtschalterPage from "../components/Beratung/Categories/LichtschalterPage";
import {
  getCwaData,
  initialState as LichtschalterInitialState,
} from "../redux/modules/chapterCwa";

import WelcomeIPPage from "../components/Beratung/Categories/WelcomeIPPage";
import { welcomeIpApplications as WelcomeIPApplicationData } from "../routes/Chapters/welcome-ip/sections/Anwendungen/slides/Anwendungen";
import {
  getWelcomeIPData,
  initialState as WelcomeIPInitialState,
} from "../redux/modules/chapterWelcomeIP";

import ServiceAndToolsPage from "../components/Beratung/Categories/ServiceAndToolsPage";
import {
  getServiceAndToolsData,
  initialState as ServiceAndToolsIntitalState,
} from "../redux/modules/chapterServiceAndTools";

import BuschflexPage from "../components/Beratung/Categories/BuschflexPage";
import {
  getBuschflexData,
  initialState as BuschflexIntitalState,
} from "../redux/modules/chapterBuschFlex";
import { buschFlexApplications as BuschFlexApplicationData } from "../routes/Chapters/busch-flex/sections/Anwendungen/uebersicht";

import {
  getUK600AK600Data,
  initialState as Uk600AK600IntitalState,
} from "../redux/modules/chapterUk600AK600";
import Uk600AK600Page from "../components/Beratung/Categories/Uk600AK600Page";

import {
  getAbbSmarterMobilityData,
  initialState as AbbSmarterMobilityIntitalState,
} from "../redux/modules/chapterAbbSmarterMobility";
import AbbSmarterMobilityPage from "../components/Beratung/Categories/AbbSmarterMobilityPage";

import {
  getLbInnovationsData,
  initialState as LbInnovationsInitialState,
} from "../redux/modules/chapterLBInnovations";
import LbInnovationsPage from "../components/Beratung/Categories/LbInnovationsPage";

import {
  getEnergieMonitoringData,
  initialState as EnergieMOnitoringInitialState,
} from "../redux/modules/chapterEnergiemontoring";
import EnergieMonitoringPage from "../components/Beratung/Categories/EnergieMonitoringPage";
import {
  getModerneElektroinstallationData,
  initialState as ModerneElektroinstallationInitalState,
} from "../redux/modules/chapterModerneElektroinstallation";
import ModerneElektroinstallationPage from "../components/Beratung/Categories/ModerneElektroinstallationPage";

import {
  getUSVData,
  initialState as USVInitialState,
} from "../redux/modules/chapterUSV";
import USVPage from "../components/Beratung/Categories/USVPage";

import {
  getJahresgespraech2024Data,
  initialState as JG24InitialState,
} from "../redux/modules/chapterJahresgespraech2024";
import Jahresgespraech2024Page from "../components/Beratung/Categories/Jahresgespraech2024Page";

import {
  getFlexLineData,
  initialState as FlexLineState,
} from "../redux/modules/chapterFlexLine";
import FlexLinePage from "../components/Beratung/Categories/FlexLinePage";

import {
  getNeuheiten2024Data,
  initialState as Neuheiten2024InitialState,
} from "../redux/modules/chapterNeuheiten2024";
import Neuheiten2024Page from "../components/Beratung/Categories/Neuheiten2024Page";

// Unterstützung
import {
  freeAtHomeUnterstuetzungMarketing,
  freeAtHomeUnterstuetzungSchulung,
} from "./UnterstuetzungData/free-at-home";
import {
  buschWelcomeUnterstuetzungMarketing,
  buschWelcomeUnterstuetzungSchulung,
} from "./UnterstuetzungData/busch-welcome";
import {
  buschWelcomeIPUnterstuetzungMarketing,
  buschWelcomeIPUnterstuetzungSchulung,
} from "./UnterstuetzungData/busch-welcome-ip";
import {
  knxUnterstuetzungMarketing,
  knxUnterstuetzungSchulung,
} from "./UnterstuetzungData/knx";
import {
  beruehrungslosesschaltenMarketingItems,
  beruehrungslosesschaltenSchulungItems,
} from "./UnterstuetzungData/beruehrungslosesschalten";
import {
  schaltenUndSteuernMarketingItems,
  schaltenUndSteuernSchulungItems,
} from "./UnterstuetzungData/schalten-und-steuern";
import {
  energiemonitoringMarketingItems,
  energiemonitoringSchulungItems,
} from "./UnterstuetzungData/energiemonitoring";
import {
  comfortlineMarketingItems,
  comfortlineSchulungItems,
} from "./UnterstuetzungData/comfortline";
import {
  installationsgeraeteUnterstuetzungMarketing,
  installationsgeraeteUnterstuetzungSchulung,
} from "./UnterstuetzungData/installationsgeraete";
import {
  serviceAndToolsMarketingItems,
  serviceAndToolsSchulungItems,
} from "./UnterstuetzungData/serviceAndTools";
import {
  buschFlexUnterstuetzungMarketing,
  buschFlexUnterstuetzungSchulung,
} from "./UnterstuetzungData/busch-flex";
import {
  cylonMarketingItems,
  cylonSchulungItems,
} from "./UnterstuetzungData/cylon";
import { uk600ak600MarketingItems } from "./UnterstuetzungData/uk600ak600";
import { smarterGalleryMarketingItems } from "./UnterstuetzungData/smarter-gallery";
import {
  abbSmarterMobilityMarketingItems,
  abbSmarterMobilitySchulungItems,
} from "./UnterstuetzungData/abb-smarter-mobility";
import {
  neuheiten2022MarketingItems,
  neuheiten2022SchulungItems,
} from "./UnterstuetzungData/neuheiten-2022";
import {
  moderneElektroinstallationSchulungItems,
  moderneElektroinstallationMarketingItems,
} from "./UnterstuetzungData/moderneElektroinstallation.js";
import { flexLineMarketingItems } from "./UnterstuetzungData/flexline.js";
import {
  neuheiten2024MarketingItems,
  neuheiten2024SchulungItems,
} from "./UnterstuetzungData/neuheiten-2024.js";

export const chapters = {
  missiontozero: {
    slug: "missiontozero",
    label: "Mission to Zero",
    link: "/mission-to-zero",
    chapterID: 0,
    company: ["bje"],
    img: "../assets/_chapter-images/mission-to-zero.webp",
    imgSmall: "../assets/_chapter-images/small/mission-to-zero.webp",
    frontpage: {
      titlePosition: {
        pos1: "topleft",
        pos2: "topright",
        pos3: "topleft",
        pos4: "topright",
      },
    },
    actionPage: <MissionToZeroPage />,
    initialState: MissionToZeroInitialState,
    getData: getMissionToZeroData,
  },
  welcome: {
    slug: "welcome",
    label: (
      <>
        Busch-Welcome<sup>®</sup>
      </>
    ),
    shortLabel: "Busch-Welcome",
    link: "/welcome",
    chapterID: 2,
    company: ["bje"],
    img: "../assets/_chapter-images/welcome.webp",
    imgSmall: "../assets/_chapter-images/small/welcome.webp",
    frontpage: {
      titlePosition: {
        pos1: "topleft",
        pos2: "topright",
        pos3: "topleft",
        pos4: "bottomright",
      },
    },
    actionPage: <WelcomePage />,
    initialState: WelcomeInitialState,
    getData: getWelcomeData,
    unterstuetzung: [
      buschWelcomeUnterstuetzungSchulung,
      buschWelcomeUnterstuetzungMarketing,
    ],
  },
  freeathome: {
    slug: "freeathome",
    label: (
      <>
        Busch-free@home<sup>®</sup>
      </>
    ),
    shortLabel: "Busch-free@home",
    link: "/free-at-home",
    chapterID: 1,
    company: ["bje"],
    img: "../assets/_chapter-images/free-at-home.webp",
    imgSmall: "../assets/_chapter-images/small/free-at-home.webp",
    frontpage: {
      titlePosition: {
        pos1: "topleft",
        pos2: "topright",
        pos3: "bottomleft",
        pos4: "bottomright",
      },
    },
    actionPage: <FreeAtHomePage />,
    initialState: FreeAtHomeInitialState,
    getData: getFreeAtHomeData,
    unterstuetzung: [
      freeAtHomeUnterstuetzungSchulung,
      freeAtHomeUnterstuetzungMarketing,
    ],
  },
  welcomeip: {
    slug: "welcomeip",
    label: (
      <>
        Busch-Welcome<sup>®</sup> IP
      </>
    ),
    shortLabel: "Busch-Welcome IP",
    link: "/welcome-ip",
    chapterID: 3,
    company: ["bje"],
    img: "../assets/_chapter-images/welcome-ip.webp",
    imgSmall: "../assets/_chapter-images/small/welcome-ip.webp",
    frontpage: {
      titlePosition: {
        pos1: "topleft",
        pos2: "bottomright",
        pos3: "topleft",
        pos4: "bottomright",
      },
    },
    actionPage: <WelcomeIPPage />,
    initialState: WelcomeIPInitialState,
    getData: getWelcomeIPData,
    unterstuetzung: [
      buschWelcomeIPUnterstuetzungSchulung,
      buschWelcomeIPUnterstuetzungMarketing,
    ],
  },
  knx: {
    slug: "knx",
    label: (
      <>
        ABB i-bus<sup>®</sup> KNX
      </>
    ),
    shortLabel: "ABB i-bus KNX",
    link: "/knx",
    chapterID: 4,
    company: ["abb"],
    img: "../assets/_chapter-images/abb-knx.webp",
    imgSmall: "../assets/_chapter-images/small/abb-knx.webp",
    frontpage: {
      titlePosition: {
        pos1: "bottomleft",
        pos2: "topright",
        pos3: "bottomleft",
        pos4: "bottomright",
      },
    },
    actionPage: <KnxPage />,
    initialState: KNXInitialState,
    getData: getKnxData,
    unterstuetzung: [knxUnterstuetzungSchulung, knxUnterstuetzungMarketing],
  },
  comfortline: {
    slug: "comfortline",
    label: "ComfortLine",
    link: "/comfort-line",
    chapterID: 5,
    company: ["suj"],
    img: "../assets/_chapter-images/comfortline.webp",
    imgSmall: "../assets/_chapter-images/small/comfortline.webp",
    frontpage: {
      titlePosition: {
        pos1: "topleft",
        pos2: "topright",
        pos3: "bottomleft",
        pos4: "bottomright",
      },
    },
    actionPage: <ComfortLinePage />,
    initialState: ComfortLineInitialState,
    getData: getComfortLineData,
    unterstuetzung: [comfortlineSchulungItems, comfortlineMarketingItems],
  },
  installationsgeraete: {
    slug: "installationsgeraete",
    label: "Installationsgeräte Highlights",
    link: "/installationsgeraete",
    chapterID: 6,
    company: ["bje"],
    img: "../assets/_chapter-images/installationsgeraete.webp",
    imgSmall: "../assets/_chapter-images/small/installationsgeraete.webp",
    frontpage: {
      titlePosition: {
        pos1: "topleft",
        pos2: "topright",
        pos3: "bottomleft",
        pos4: "bottomright",
      },
    },
    actionPage: <InstallationsgeraetePage />,
    initialState: InstallationsgeraeteInitialState,
    getData: getInstallationsGeraeteData,
    unterstuetzung: [
      installationsgeraeteUnterstuetzungSchulung,
      installationsgeraeteUnterstuetzungMarketing,
    ],
  },
  beruehrungslosesschalten: {
    slug: "beruehrungslosesschalten",
    label: "Berührungsloses Schalten",
    link: "/beruehrungsloses-schalten",
    chapterID: 7,
    company: ["bje"],
    img: "../assets/_chapter-images/beruehrungsloses-schalten.webp",
    imgSmall: "../assets/_chapter-images/small/beruehrungsloses-schalten.webp",
    frontpage: {
      titlePosition: {
        pos1: "bottomleft",
        pos2: "bottomright",
        pos3: "bottomleft",
        pos4: "bottomright",
      },
    },
    actionPage: <BeruerungslosesSchalten />,
    initialState: BeruerungslosesSchaltenInitialState,
    getData: getBeruehrungslosesSchaltenData,
    unterstuetzung: [
      beruehrungslosesschaltenSchulungItems,
      beruehrungslosesschaltenMarketingItems,
    ],
  },
  cwa: {
    slug: "cwa",
    label: "Schalten und Steuern",
    link: "/cwa",
    chapterID: 8,
    company: ["bje"],
    img: "../assets/_chapter-images/schalten-und-steuern.webp",
    imgSmall: "../assets/_chapter-images/small/schalten-und-steuern.webp",
    frontpage: {
      titlePosition: {
        pos1: "bottomleft",
        pos2: "bottomright",
        pos3: "bottomleft",
        pos4: "bottomright",
      },
    },
    actionPage: <LichtschalterPage />,
    initialState: LichtschalterInitialState,
    getData: getCwaData,
    unterstuetzung: [
      schaltenUndSteuernSchulungItems,
      schaltenUndSteuernMarketingItems,
    ],
  },
  jahresgespraech_2021: {
    slug: "jahresgespraech_2021",
    label: "Jahresgespräch 2021",
    link: "/jahresgespraech-2021",
    chapterID: 9,
    company: ["bje"],
    img: "../assets/_chapter-images/jahresgespraech-2021.webp",
    imgSmall: "../assets/_chapter-images/small/jahresgespraech-2021.webp",
    frontpage: {
      titlePosition: {
        pos1: "topleft",
        pos2: "topright",
        pos3: "topleft",
        pos4: "topright",
      },
    },
    actionPage: null,
    initialState: null,
  },
  branchenkonfiguratorIndustrie: {
    slug: "branchenkonfigurator-industrie",
    label: "Branchenkonfigurator",
    link: "/branchenkonfigurator-industrie",
    chapterID: 10,
    company: ["abb"],
    img: "../assets/_chapter-images/branchenkonfigurator.webp",
    imgSmall: "../assets/_chapter-images/small/branchenkonfigurator.webp",
    frontpage: {
      titlePosition: {
        pos1: "topleft",
        pos2: "topright",
        pos3: "topleft",
        pos4: "topright",
      },
    },
    actionPage: null,
    initialState: null,
  },
  architekten: {
    slug: "architekten",
    label: "Architekten",
    link: "/architekten",
    chapterID: 11,
    company: ["bje"],
    img: "../assets/_chapter-images/architekten.webp",
    imgSmall: "../assets/_chapter-images/small/architekten.webp",
    frontpage: {
      titlePosition: {
        pos1: "bottomleft",
        pos2: "bottomright",
        pos3: "bottomleft",
        pos4: "bottomright",
      },
    },
    actionPage: null,
    initialState: null,
  },
  jahresgespraech_2022: {
    slug: "jahresgespraech_2022",
    label: "Jahresgespräch 2022",
    link: "/jahresgespraech-2022",
    chapterID: 12,
    company: ["bje"],
    img: "../assets/_chapter-images/jahresgespraech-2022.webp",
    imgSmall: "../assets/_chapter-images/small/jahresgespraech-2022.webp",
    frontpage: {
      titlePosition: {
        pos1: "topleft",
        pos2: "topright",
        pos3: "topleft",
        pos4: "topright",
      },
    },
    actionPage: null,
    initialState: null,
  },

  serviceandtools: {
    slug: "serviceandtools",
    label: "Service & Tools",
    link: "/serviceandtools",
    chapterID: 13,
    company: ["bje"],
    img: "../assets/_chapter-images/service-and-tools.webp",
    imgSmall: "../assets/_chapter-images/small/service-and-tools.webp",
    frontpage: {
      titlePosition: {
        pos1: "topleft",
        pos2: "topright",
        pos3: "bottomleft",
        pos4: "topright",
      },
    },
    actionPage: <ServiceAndToolsPage />,
    initialState: ServiceAndToolsIntitalState,
    getData: getServiceAndToolsData,
    unterstuetzung: [
      serviceAndToolsSchulungItems,
      serviceAndToolsMarketingItems,
    ],
  },
  buschflex: {
    slug: "buschflex",
    label: (
      <>
        Busch-flexTronics<sup>®</sup>
      </>
    ),
    shortLabel: "Busch-flexTronics®",
    link: "/busch-flex",
    chapterID: 14,
    company: ["bje"],
    img: "../assets/_chapter-images/busch-flex.webp",
    imgSmall: "../assets/_chapter-images/small/busch-flex.webp",
    frontpage: {
      titlePosition: {
        pos1: "topleft",
        pos2: "topright",
        pos3: "topleft",
        pos4: "topright",
      },
    },
    actionPage: <BuschflexPage />,
    initialState: BuschflexIntitalState,
    getData: getBuschflexData,
    unterstuetzung: [
      buschFlexUnterstuetzungSchulung,
      buschFlexUnterstuetzungMarketing,
    ],
  },
  cylon: {
    slug: "cylon",
    label: (
      <>
        ABB Cylon<sup>®</sup>
        <br />
        Intelligente Energiesteuerung
      </>
    ),
    shortLabel: "ABB Cylon",
    link: "/cylon",
    chapterID: 15,
    company: ["abb"],
    img: "../assets/_chapter-images/abb-cylon.webp",
    imgSmall: "../assets/_chapter-images/small/abb-cylon.webp",
    frontpage: {
      titlePosition: {
        pos1: "topleft",
        pos2: "topright",
        pos3: "topleft",
        pos4: "topright",
      },
    },
    actionPage: null,
    initialState: null,
    unterstuetzung: [cylonSchulungItems, cylonMarketingItems],
  },
  uk600ak600: {
    slug: "uk600ak600",
    label: "UK600 + AK600",
    link: "/uk600-ak600",
    chapterID: 16,
    company: ["suj"],
    img: "../assets/_chapter-images/uk-600.webp",
    imgSmall: "../assets/_chapter-images/small/uk-600.webp",
    frontpage: {
      titlePosition: {
        pos1: "bottomleft",
        pos2: "topright",
        pos3: "bottomleft",
        pos4: "topright",
      },
    },
    actionPage: <Uk600AK600Page />,
    initialState: Uk600AK600IntitalState,
    getData: getUK600AK600Data,
    unterstuetzung: [uk600ak600MarketingItems],
  },
  smarterGallery: {
    slug: "smarterGallery",
    label: "Smarter Gallery",
    link: "/smarter-gallery",
    chapterID: 17,
    company: ["bje"],
    img: "../assets/_chapter-images/smarter-gallery.webp",
    imgSmall: "../assets/_chapter-images/small/smarter-gallery.webp",
    frontpage: {
      titlePosition: {
        pos1: "bottomleft",
        pos2: "bottomright",
        pos3: "bottomleft",
        pos4: "bottomright",
      },
    },
    actionPage: null,
    initialState: null,
    unterstuetzung: [smarterGalleryMarketingItems],
  },
  abbSmarterMobility: {
    slug: "abbSmarterMobility",
    label: "ABB Smarter Mobility",
    link: "/abb-smarter-mobility",
    chapterID: 18,
    company: ["abb"],
    img: "../assets/_chapter-images/abb-smarter-mobility.webp",
    imgSmall: "../assets/_chapter-images/small/abb-smarter-mobility.webp",
    frontpage: {
      titlePosition: {
        pos1: "topleft",
        pos2: "topright",
        pos3: "topleft",
        pos4: "topright",
      },
    },
    actionPage: <AbbSmarterMobilityPage />,
    initialState: AbbSmarterMobilityIntitalState,
    getData: getAbbSmarterMobilityData,
    unterstuetzung: [
      abbSmarterMobilitySchulungItems,
      abbSmarterMobilityMarketingItems,
    ],
  },
  lbInnovations: {
    slug: "lbInnovations",
    label: "Neuheiten 2022",
    link: "/innovations-2022",
    chapterID: 19,
    company: ["bje", "abb"],
    img: "../assets/_chapter-images/lbInnovations.webp",
    imgSmall: "../assets/_chapter-images/small/lbInnovations.webp",
    frontpage: {
      titlePosition: {
        pos1: "bottomleft",
        pos2: "bottomright",
        pos3: "bottomleft",
        pos4: "bottomright",
      },
    },
    actionPage: <LbInnovationsPage />,
    initialState: LbInnovationsInitialState,
    getData: getLbInnovationsData,
    unterstuetzung: [neuheiten2022SchulungItems, neuheiten2022MarketingItems],
  },
  energiemonitoring: {
    slug: "energiemonitoring",
    label: "Energiemonitoring",
    link: "/energiemonitoring",
    chapterID: 20,
    company: ["abb"],
    img: "../assets/_chapter-images/energiemonitoring.webp",
    frontpage: {
      titlePosition: {
        pos1: "topleft",
        pos2: "topright",
        pos3: "topleft",
        pos4: "topright",
      },
    },
    actionPage: <EnergieMonitoringPage />,
    initialState: EnergieMOnitoringInitialState,
    getData: getEnergieMonitoringData,
    unterstuetzung: [
      energiemonitoringSchulungItems,
      energiemonitoringMarketingItems,
    ],
  },
  jahresgespraech_2023: {
    slug: "jahresgespraech_2023",
    label: "Jahresgespräch 2023",
    link: "/jahresgespraech-2023",
    chapterID: 21,
    company: ["bje"],
    img: "../assets/_chapter-images/jahresgespraech-2023.webp",
    frontpage: {
      titlePosition: {
        pos1: "topleft",
        pos2: "topright",
        pos3: "topleft",
        pos4: "topright",
      },
    },
    actionPage: <USVPage />,
    initialState: null,
  },
  moderneElektroinstallation: {
    slug: "moderneElektroinstallation",
    label: "Moderne Elektroinstallation",
    link: "/moderne-elektroinstallation",
    chapterID: 22,
    company: ["bje"],
    img: "../assets/_chapter-images/moderne-elektroinstallation.webp",
    frontpage: {
      titlePosition: {
        pos1: "topleft",
        pos2: "topright",
        pos3: "topleft",
        pos4: "topright",
      },
    },
    actionPage: <ModerneElektroinstallationPage />,
    initialState: ModerneElektroinstallationInitalState,
    getData: getModerneElektroinstallationData,
    unterstuetzung: [
      moderneElektroinstallationSchulungItems,
      moderneElektroinstallationMarketingItems,
    ],
  },
  usv: {
    slug: "usv",
    label: "Unterbrechungsfreie Stromversorgung (USV)",
    link: "/usv",
    chapterID: 23,
    company: ["abb"],
    img: "../assets/_chapter-images/usv.webp",
    frontpage: {
      titlePosition: {
        pos1: "topleft",
        pos2: "topright",
        pos3: "topleft",
        pos4: "topright",
      },
    },
    actionPage: <USVPage />,
    initialState: USVInitialState,
    getData: getUSVData,
  },
  newsUndAktionen: {
    slug: "newsUndAktionen",
    label: "News & Aktionen",
    link: "/news-und-aktionen",
    chapterID: 24,
    company: ["bje", "abb", "suj"],
    img: "../assets/_chapter-images/news.webp",
    frontpage: {
      titlePosition: {
        pos1: "topleft",
        pos2: "bottomright",
        pos3: "topleft",
        pos4: "bottomright",
      },
    },
    actionPage: null,
    initialState: null,
  },
  jahresgespraech_2024: {
    slug: "jahresgespraech_2024",
    label: "Jahresgespräch 2024",
    link: "/jahresgespraech-2024",
    chapterID: 25,
    company: ["bje"],
    img: "../assets/_chapter-images/jahresgespraech-2024.webp",
    frontpage: {
      titlePosition: {
        pos1: "topleft",
        pos2: "topright",
        pos3: "topleft",
        pos4: "topright",
      },
    },
    actionPage: <Jahresgespraech2024Page />,
    initialState: JG24InitialState,
    getData: getJahresgespraech2024Data,
  },
  flexline: {
    slug: "flexline",
    label: (
      <>
        FlexLine<sup>®</sup>
      </>
    ),
    shortLabel: "FlexLine®",
    link: "/flexline",
    chapterID: 26,
    company: ["abb"],
    img: "../assets/_chapter-images/flexline.webp",
    frontpage: {
      titlePosition: {
        pos1: "topleft",
        pos2: "bottomright",
        pos3: "topleft",
        pos4: "bottomright",
      },
    },
    actionPage: <FlexLinePage />,
    initialState: FlexLineState,
    getData: getFlexLineData,
    unterstuetzung: [flexLineMarketingItems],
  },
  neuheiten2024: {
    slug: "neuheiten2024",
    label: "Neuheiten 2024",
    shortLabel: "Neuheiten 2024",
    link: "/neuheiten-2024",
    chapterID: 27,
    company: ["abb", "bje", "suj"],
    img: "../assets/_chapter-images/neuheiten-2024.webp",
    frontpage: {
      titlePosition: {
        pos1: "bottomleft",
        pos2: "bottomright",
        pos3: "bottomleft",
        pos4: "bottomright",
      },
    },
    actionPage: <Neuheiten2024Page />,
    initialState: Neuheiten2024InitialState,
    getData: getNeuheiten2024Data,
    unterstuetzung: [neuheiten2024SchulungItems, neuheiten2024MarketingItems],
  },
};

export const typeOfChapters = {
  products: {
    label: "Kapitel",
    chapters: [
      "missiontozero",
      "architekten",
      "welcome",
      "freeathome",
      "welcomeip",
      "knx",
      "comfortline",
      "installationsgeraete",
      "beruehrungslosesschalten",
      "cwa",
      "jahresgespraech_2021",
      "branchenkonfiguratorIndustrie",
      "jahresgespraech_2022",
      "serviceandtools",
      "buschflex",
      "uk600",
      "cylon",
      "smarterGallery",
      "abbSmarterMobility",
      "lbInnovations",
      "energiemonitoring",
      "jahresgespraech_2023",
      "moderneElektroinstallation",
      "usv",
      "newsUndAktionen",
      "jahresgespraech_2024",
      "flexline",
      "neuheiten-2024",
    ],
  },
  applications: {
    label: "Anwendungen",
    chapters: [
      { id: "welcome", data: WelcomeApplicationData },
      { id: "freeathome", data: FreeAtHomeApplicationData },
      { id: "welcomeip", data: WelcomeIPApplicationData },
      { id: "knx", data: KnxApplicationData },
      { id: "buschflex", data: BuschFlexApplicationData },
    ],
  },
};
