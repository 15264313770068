// Images Schulung
import ImageSchulung1 from "../../assets/free-at-home/Schulung/seminare.webp";
import ImageSchulung2 from "../../assets/free-at-home/Schulung/techniktalk.webp";
import ImageSchulung3 from "../../assets/abb-smarter-mobility/kacheln/schulung/3.webp";
import ImageSchulung4 from "../../assets/abb-smarter-mobility/kacheln/schulung/4.webp";
import ImageSchulung5 from "../../assets/abb-smarter-mobility/kacheln/schulung/5.webp";

// Images Marketing
import ImageMarketing1 from "../../assets/abb-smarter-mobility/kacheln/marketing/1.webp";
import ImageMarketing2 from "../../assets/abb-smarter-mobility/kacheln/marketing/2.webp";
import ImageMarketing3 from "../../assets/abb-smarter-mobility/kacheln/marketing/3.webp";
import ImageMarketing4 from "../../assets/abb-smarter-mobility/kacheln/marketing/4.webp";
import ImageMarketing5 from "../../assets/abb-smarter-mobility/kacheln/marketing/5.webp";

export const abbSmarterMobilitySchulungItems = {
  title: "Schulung",
  items: [
    {
      title: "Seminare",
      img: ImageSchulung1,
      link: {
        href: "https://new.abb.com/ev-charging/de/schulungen",
        target: "_blank",
      },
    },
    {
      title: "Technik-Talk",
      img: ImageSchulung2,
      link: {
        href: "https://www.youtube.com/playlist?list=PLW1uFXqHQgufhvVQ2v0kmm6xvIsjlF2--",
        target: "_blank",
      },
    },
    {
      title: "Online Anleitung",
      img: ImageSchulung3,
      link: {
        href: "https://abb-quickstartguide.s3-eu-west-1.amazonaws.com/tac_quickstart_DE/index.html#/",
        target: "_blank",
      },
    },
    {
      title: "TerraConfig App",
      img: ImageSchulung4,
      link: {
        href: "https://new.abb.com/ev-charging/de/connected-services/digitale-l%C3%B6sungen-terra-ac",
        target: "_blank",
      },
    },
    {
      title: "ChargerSync App",
      img: ImageSchulung5,
      link: {
        href: "https://new.abb.com/ev-charging/de/connected-services/digitale-l%C3%B6sungen-terra-ac",
        target: "_blank",
      },
    },
  ],
};
export const abbSmarterMobilityMarketingItems = {
  title: "Marketing",
  items: [
    {
      title: "Preisliste",
      img: ImageMarketing1,
      link: {
        href: "https://search.abb.com/library/Download.aspx?DocumentID=9AKK107992A6671&LanguageCode=de&DocumentPartId=&Action=Launch",
        target: "_blank",
      },
    },
    {
      title: "Broschüre",
      img: ImageMarketing2,
      link: {
        href: "https://search.abb.com/library/Download.aspx?DocumentID=9AKK107680A4395&LanguageCode=de&DocumentPartId=&Action=Launch",
        target: "_blank",
      },
    },
    {
      title: "Handbuch",
      img: ImageMarketing3,
      link: {
        href: "https://search.abb.com/library/Download.aspx?DocumentID=9AKK107680A8750&LanguageCode=de&DocumentPartId=&Action=Launch",
        target: "_blank",
      },
    },
    {
      title: "Benutzerhandbuch",
      img: ImageMarketing4,
      link: {
        href: "https://search.abb.com/library/Download.aspx?DocumentID=9AKK107680A8811&LanguageCode=de&DocumentPartId=&Action=Launch",
        target: "_blank",
      },
    },
    {
      title: "Anleitungsvideo",
      img: ImageMarketing5,
      link: {
        href: "https://www.youtube.com/watch?app=desktop&v=EUmx0MwLc3Q&list=PLe8krF0EbQi0UF97Q6tF3zQ6Ln9R1YptR",
        target: "_blank",
      },
    },
  ],
};
