import ChapterCard from "../../../../../components/ChapterCard";
import { Box, Grid } from "@material-ui/core";
import QHeader from "../../../../../components/QHeader";
import Slide from "../../../../../components/Slide";

// Anwendungen
import Einzelsteuerung from "./einzelsteuerung";
import Gruppensteuerung from "./gruppensteuerung";
import GruppensteuerungWireless from "./gruppensteuerungWireless";
import GruppensteuerungWirelessWetterstation from "./gruppensteuerungWirelessWetterstation";
import Beleuchtungssteuerung from "./beleuchtungssteuerung";
import BeleuchtungssteuerungBewegungsmelder from "./beleuchtungssteuerungBewegungsmelder";

// Images
import Image1 from "../../../../../assets/busch-flex/anwendungsbeispiele/titelbilder/1.webp";
import Image2 from "../../../../../assets/busch-flex/anwendungsbeispiele/titelbilder/2.webp";
import Image3 from "../../../../../assets/busch-flex/anwendungsbeispiele/titelbilder/3.webp";
import Image4 from "../../../../../assets/busch-flex/anwendungsbeispiele/titelbilder/4.webp";
import Image5 from "../../../../../assets/busch-flex/anwendungsbeispiele/titelbilder/5.webp";
import Image6 from "../../../../../assets/busch-flex/anwendungsbeispiele/titelbilder/6.webp";

export const buschFlexApplications = [
  {
    title: "Jalousie Einzelsteuerung",
    img: Image1,
    link: { href: "/busch-flex#anwendungen/1", target: "_self" },
    menuOverlay: <Einzelsteuerung />,
  },
  {
    title: "Jalousie Gruppensteuerung PlusWire",
    img: Image2,
    link: {
      href: "/busch-flex#anwendungen/2",
      target: "_self",
    },
    menuOverlay: <Gruppensteuerung />,
  },
  {
    title: "Jalousie Gruppensteuerung Wireless",
    img: Image3,
    link: {
      href: "/busch-flex#anwendungen/3",
      target: "_self",
    },
    menuOverlay: <GruppensteuerungWireless />,
  },
  {
    title: "Jalousie Gruppensteuerung Wireless mit Wetterstation",
    img: Image4,
    link: {
      href: "/busch-flex#anwendungen/4",
      target: "_self",
    },
    menuOverlay: <GruppensteuerungWirelessWetterstation />,
  },
  {
    title: "Beleuchtungssteuerung",
    img: Image5,
    link: {
      href: "/busch-flex#anwendungen/5",
      target: "_self",
    },
    menuOverlay: <Beleuchtungssteuerung />,
  },
  {
    title: "Beleuchtungssteuerung mit Bewegungsmeldern und Nebenstelle",
    img: Image6,
    link: {
      href: "/busch-flex#anwendungen/6",
      target: "_self",
    },
    menuOverlay: <BeleuchtungssteuerungBewegungsmelder />,
  },
];

export default function Uebersicht() {
  return (
    <Slide
      background={{
        alignment: "left",
        width: "45%",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <QHeader
          colWidth={12}
          title={
            <>
              Busch-flexTronics<sup>®</sup> |<br />
              Busch-flexTronics<sup>®</sup> Wireless
            </>
          }
          subTitle="Anwendungen"
        />
        <Box px={10}>
          <Grid container spacing={2}>
            {buschFlexApplications.map((e, i) => {
              return (
                <Grid item xs={4} lg={3} xl={2}>
                  <ChapterCard noDialog chapter={e} cardWidth="auto" />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Grid>
    </Slide>
  );
}
