const SET_NOTES = "playbook/energieMonitoring/SET_NOTES";
const SET_ASSET = "playbook/energieMonitoring/SET_ASSET";

export const initialState = {
  assetElektroSpicker: {
    description: "ElektroSpicker",
    value: 0,
    type: "order",
    orderID: "9AKK107991A0792",
  },
  assetUnterverteilung: {
    description: "Unterverteilung",
    value: 0,
    type: "order",
    orderID: "9AKK108467A0363",
  },
  assetB23_312: {
    description: "B23 312-100",
    value: false,
    type: "info",
    orderID: "2CMA100169R1000",
  },
  notes: {
    description: "Notizen für das Kapitel",
    value: "",
    type: "info",
    orderID: null,
  },
};

const energieMonitoring = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTES: {
      return {
        ...state,
        notes: {
          ...state.notes,
          value: action.notes,
        },
      };
    }
    case SET_ASSET: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          value: action.value,
        },
      };
    }
    default:
      return state;
  }
};

//actions
export const setAsset = (id, value) => ({
  type: SET_ASSET,
  id,
  value,
});

export const setEnergieMonitoringNotes = (notes) => ({
  type: SET_NOTES,
  notes,
});

//selector
export const getEnergieMonitoringNotes = (state) => state.energieMonitoring.notes;
export const getEnergieMonitoringData = (state) => state.energieMonitoring;

export default energieMonitoring;
