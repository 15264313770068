import { h } from "preact";

import { useDispatch, useSelector } from "react-redux";
import {
  setSeminarAppointment,
  setWelcomeIPNotes,
  getWelcomeIPData,
  setSeminarNote,
  setAsset,
} from "../../../redux/modules/chapterWelcomeIP";
import { Grid } from "@material-ui/core";
import QActionPaper from "../../QActionPaper";
import QIncrementGroup from "../../QIncrementGroup";
import QTextFieldAction from "../../QTextFieldAction";
import QDateAction from "../../QDateAction";

export default function WelcomeIPPage({ context }) {
  const dispatch = useDispatch();
  const welcomeIPData = useSelector((state) => getWelcomeIPData(state));
  const {
    seminarAppointment,
    notes,
    seminarNote,
    assetFlyer,
    assetBuschWelcomeIPStarterSet,
    assetRollUp,
  } = welcomeIPData;

  const handleSetSetupField = (valueSet) => {
    switch (valueSet.id) {
      case "seminarNote":
        {
          dispatch(setSeminarNote(valueSet.value));
        }
        break;
      case "seminarAppointment":
        {
          dispatch(setSeminarAppointment(valueSet.value));
        }
        break;
      case "notes":
        {
          dispatch(setWelcomeIPNotes(valueSet.value));
        }
        break;
    }
  };

  const handleSetAsset = (id, value) => {
    dispatch(setAsset(id, value));
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <QActionPaper title="Busch-Jaeger Seminare" context={context}>
          <QDateAction
            date={seminarAppointment.value}
            dateID="seminarAppointment"
            note={seminarNote.value}
            noteID="seminarNote"
            setSetupField={handleSetSetupField}
          />
        </QActionPaper>
      </Grid>
      <Grid item>
        <QActionPaper title="Unterlagen" context={context}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetFlyer.value}
                valueID="assetFlyer"
                label="Flyer"
                mode={1}
                setAsset={handleSetAsset}
              />
            </Grid>
          </Grid>
        </QActionPaper>
      </Grid>
      <Grid item>
        <QActionPaper title="Notizen" context={context}>
          <QTextFieldAction
            value={notes.value}
            valueID="notes"
            setSetupField={handleSetSetupField}
          />
        </QActionPaper>
      </Grid>
    </Grid>
  );
}
