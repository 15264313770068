import { h } from "preact";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function CloseIconCircle(props) {
  return (
    <SvgIcon {...props}>
      <svg
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="18" cy="18" r="18" fill="black" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18 16.93L23.719 11.21C23.865 11.064 24.058 10.991 24.25 10.991C24.654 10.991 25 11.315 25 11.74C25 11.933 24.927 12.125 24.781 12.272L19.061 17.991L24.78 23.71C24.927 23.857 25 24.049 25 24.241C25 24.668 24.651 24.991 24.25 24.991C24.058 24.991 23.865 24.918 23.719 24.772L18 19.053L12.281 24.772C12.135 24.918 11.942 24.991 11.75 24.991C11.349 24.991 11 24.668 11 24.241C11 24.049 11.073 23.857 11.22 23.71L16.939 17.991L11.219 12.272C11.073 12.125 11 11.933 11 11.74C11 11.315 11.346 10.991 11.75 10.991C11.942 10.991 12.135 11.064 12.281 11.21L18 16.93Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
}
