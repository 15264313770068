import { h } from "preact";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { sectionStart } from "../../static/fullpageHelpers";
import clsx from "clsx";
import FilledArrowLeft from "../SvgIcons/FilledArrowLeft";

const useStyles = makeStyles(() => ({
  arrowWrap: {
    position: "absolute",
    left: "8px",
    marginTop: "3px",
    top: "-.9em",
    cursor: "pointer",
  },
}));

export default function ArrowBack({ onClick }) {
  const classes = useStyles();

  return (
    onClick && (
      <div id="qBackArrow" className={classes.arrowWrap} onClick={onClick}>
        <FilledArrowLeft color="primary" fontSize="large" />
      </div>
    )
  );
}
