export default function HCEDarstellung() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 457 282"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="hce-darstellung">
        <g id="Group 40">
          <g id="Group 36">
            <text
              id="Konventionelle L&#195;&#182;sung mit vier Durchgangsventilen"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="9"
              letter-spacing="0em"
            >
              <tspan x="0" y="255.452">
                Konventionelle L&#xf6;sung&#10;
              </tspan>
              <tspan x="0" y="266.452">
                mit vier Durchgangsventilen
              </tspan>
            </text>
            <text
              id="Konventionelle L&#195;&#182;sung mit zwei Durchgangsventilen und einem Umschaltventil"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="9"
              letter-spacing="0em"
            >
              <tspan x="166.078" y="255.452">
                Konventionelle L&#xf6;sung mit zwei&#10;
              </tspan>
              <tspan x="166.078" y="266.452">
                Durchgangsventilen und einem&#10;
              </tspan>
              <tspan x="166.078" y="277.452">
                Umschaltventil
              </tspan>
            </text>
            <text
              id="L&#195;&#182;sung mit einem 6-Weg-Regelkugelhahnen"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="9"
              letter-spacing="0em"
            >
              <tspan x="328.719" y="255.452">
                L&#xf6;sung mit einem&#10;
              </tspan>
              <tspan x="328.719" y="266.452">
                6-Weg-Regelkugelhahnen
              </tspan>
            </text>
            <g id="Group 33">
              <g id="Group 31">
                <g id="Group 30">
                  <rect
                    id="Rectangle 11"
                    y="205.02"
                    width="16.0351"
                    height="11.4536"
                    fill="#F58220"
                  />
                  <g id="HC">
                    <path
                      d="M3.43604 213.392V208.51H4.26486V210.691H6.47281V208.51H7.30164V213.392H6.47281V211.436H4.26486V213.392H3.43604Z"
                      fill="black"
                    />
                    <path
                      d="M10.7377 213.436C10.4693 213.436 10.2143 213.402 9.97266 213.335C9.7277 213.268 9.50288 213.168 9.29484 213.03C9.08679 212.892 8.90895 212.728 8.75795 212.533C8.60359 212.339 8.48615 212.111 8.40226 211.842C8.31502 211.574 8.27475 211.285 8.27475 210.97C8.27475 210.691 8.3083 210.426 8.37542 210.174C8.44253 209.923 8.54655 209.691 8.68748 209.483C8.82506 209.275 8.99284 209.097 9.18746 208.943C9.38208 208.788 9.61026 208.671 9.87535 208.584C10.1404 208.5 10.429 208.456 10.7377 208.456C11.0901 208.456 11.4357 208.533 11.7712 208.688C12.1068 208.842 12.4054 209.077 12.6605 209.392L12.0497 209.909C11.8853 209.708 11.6873 209.543 11.4592 209.423C11.231 209.302 10.9894 209.241 10.7377 209.241C10.4223 209.241 10.1471 209.315 9.90555 209.456C9.66395 209.601 9.47604 209.802 9.34182 210.064C9.20759 210.325 9.14048 210.627 9.14048 210.97C9.14048 211.298 9.20759 211.594 9.34517 211.849C9.47939 212.104 9.67066 212.302 9.91226 212.443C10.1539 212.584 10.429 212.654 10.7377 212.654C10.9995 212.654 11.2444 212.597 11.4692 212.476C11.6907 212.355 11.8853 212.194 12.0497 211.99L12.6605 212.51C12.4356 212.792 12.1605 213.017 11.835 213.184C11.5095 213.352 11.1437 213.436 10.7377 213.436Z"
                      fill="black"
                    />
                  </g>
                </g>
                <g id="Group 29">
                  <rect
                    id="Rectangle 12"
                    y="216.474"
                    width="16.0351"
                    height="11.4536"
                    fill="#BDBABA"
                  />
                  <g id="CC">
                    <path
                      d="M5.89901 224.89C5.63057 224.89 5.37555 224.856 5.13395 224.789C4.88899 224.722 4.66417 224.621 4.45612 224.484C4.24808 224.346 4.07024 224.182 3.91924 223.987C3.76488 223.792 3.64744 223.564 3.56355 223.296C3.4763 223.027 3.43604 222.739 3.43604 222.423C3.43604 222.145 3.46959 221.88 3.5367 221.628C3.60381 221.376 3.70784 221.145 3.84877 220.937C3.98635 220.729 4.15412 220.551 4.34875 220.396C4.54337 220.242 4.77155 220.125 5.03664 220.037C5.30172 219.954 5.5903 219.91 5.89901 219.91C6.25135 219.91 6.59697 219.987 6.93253 220.141C7.26808 220.296 7.56673 220.531 7.82175 220.846L7.21104 221.363C7.04661 221.162 6.84864 220.997 6.62046 220.876C6.39228 220.756 6.15068 220.695 5.89901 220.695C5.58359 220.695 5.30844 220.769 5.06684 220.91C4.82524 221.054 4.63732 221.255 4.5031 221.517C4.36888 221.779 4.30177 222.081 4.30177 222.423C4.30177 222.752 4.36888 223.047 4.50646 223.302C4.64068 223.557 4.83195 223.755 5.07355 223.896C5.31515 224.037 5.5903 224.108 5.89901 224.108C6.16075 224.108 6.4057 224.051 6.63053 223.93C6.85199 223.809 7.04661 223.648 7.21104 223.443L7.82175 223.963C7.59693 224.245 7.32177 224.47 6.99628 224.638C6.67079 224.806 6.30504 224.89 5.89901 224.89Z"
                      fill="black"
                    />
                    <path
                      d="M10.8921 224.89C10.6236 224.89 10.3686 224.856 10.127 224.789C9.88206 224.722 9.65724 224.621 9.44919 224.484C9.24115 224.346 9.0633 224.182 8.9123 223.987C8.75795 223.792 8.6405 223.564 8.55662 223.296C8.46937 223.027 8.4291 222.739 8.4291 222.423C8.4291 222.145 8.46266 221.88 8.52977 221.628C8.59688 221.376 8.7009 221.145 8.84184 220.937C8.97942 220.729 9.14719 220.551 9.34182 220.396C9.53644 220.242 9.76462 220.125 10.0297 220.037C10.2948 219.954 10.5834 219.91 10.8921 219.91C11.2444 219.91 11.59 219.987 11.9256 220.141C12.2612 220.296 12.5598 220.531 12.8148 220.846L12.2041 221.363C12.0397 221.162 11.8417 220.997 11.6135 220.876C11.3853 220.756 11.1437 220.695 10.8921 220.695C10.5767 220.695 10.3015 220.769 10.0599 220.91C9.8183 221.054 9.63039 221.255 9.49617 221.517C9.36195 221.779 9.29484 222.081 9.29484 222.423C9.29484 222.752 9.36195 223.047 9.49953 223.302C9.63375 223.557 9.82502 223.755 10.0666 223.896C10.3082 224.037 10.5834 224.108 10.8921 224.108C11.1538 224.108 11.3988 224.051 11.6236 223.93C11.8451 223.809 12.0397 223.648 12.2041 223.443L12.8148 223.963C12.59 224.245 12.3148 224.47 11.9893 224.638C11.6639 224.806 11.2981 224.89 10.8921 224.89Z"
                      fill="black"
                    />
                  </g>
                </g>
              </g>
              <line
                id="Line 32"
                x1="16.0352"
                y1="207.956"
                x2="128.281"
                y2="207.956"
                stroke="#F58220"
              />
              <line
                id="Line 33"
                x1="16.0352"
                y1="219.41"
                x2="128.281"
                y2="219.41"
                stroke="#BDBABA"
              />
              <path
                id="Line 34"
                d="M67.0037 219.337L67.0037 169.514L79.6027 169.514"
                stroke="#BDBABA"
              />
              <path
                id="Line 35"
                d="M89.3942 207.884L89.3942 169.514L80.748 169.514"
                stroke="#F58220"
              />
              <line
                id="Line 36"
                x1="16.0352"
                y1="213.683"
                x2="128.281"
                y2="213.683"
                stroke="#F58220"
                stroke-dasharray="2 2"
              />
              <line
                id="Line 37"
                x1="16.0352"
                y1="225.137"
                x2="128.281"
                y2="225.137"
                stroke="#BDBABA"
                stroke-dasharray="2 2"
              />
              <path
                id="Line 38"
                d="M37.2243 225.637L37.2242 158.633L104.228 158.633"
                stroke="#BDBABA"
                stroke-dasharray="2 2"
              />
              <path
                id="Line 39"
                d="M105.373 158.633H119.69V214.183"
                stroke="#F58220"
                stroke-dasharray="2 2"
              />
              <g id="Group 21">
                <g id="Group 10">
                  <circle
                    id="Ellipse 1"
                    cx="58.4136"
                    cy="187.267"
                    r="3.18609"
                    fill="white"
                    stroke="black"
                    stroke-width="0.5"
                  />
                  <path
                    id="M"
                    d="M59.3852 185.795H59.8861V188.74H59.3872V186.766H59.3811L58.5772 188.173H58.2499L57.4459 186.766H57.4399V188.74H56.9409V185.795H57.4419L58.4115 187.558H58.4196L59.3852 185.795Z"
                    fill="black"
                  />
                </g>
                <path
                  id="Rectangle 14"
                  d="M61.8496 177.531H72.1579L67.5764 187.267L72.1579 195.857H61.8496L66.4311 187.267L61.8496 177.531Z"
                  fill="black"
                />
                <path
                  id="Line 41"
                  d="M61.2769 187.267H67.0037"
                  stroke="black"
                />
              </g>
              <g id="Group 27">
                <g id="Group 10_2">
                  <circle
                    id="Ellipse 1_2"
                    cx="28.6341"
                    cy="187.267"
                    r="3.18609"
                    fill="white"
                    stroke="black"
                    stroke-width="0.5"
                  />
                  <path
                    id="M_2"
                    d="M29.6059 185.795H30.1068V188.74H29.6079V186.766H29.6018L28.7979 188.173H28.4706L27.6666 186.766H27.6606V188.74H27.1616V185.795H27.6626L28.6322 187.558H28.6403L29.6059 185.795Z"
                    fill="black"
                  />
                </g>
                <path
                  id="Rectangle 14_2"
                  d="M32.0701 177.531H42.3783L37.7969 187.267L42.3783 195.857H32.0701L36.6515 187.267L32.0701 177.531Z"
                  fill="black"
                />
                <path
                  id="Line 41_2"
                  d="M31.4976 187.267H37.2244"
                  stroke="black"
                />
              </g>
              <g id="Group 22">
                <g id="Group 11">
                  <circle
                    id="Ellipse 1_3"
                    cx="97.9844"
                    cy="187.267"
                    r="3.18609"
                    fill="white"
                    stroke="black"
                    stroke-width="0.5"
                  />
                  <path
                    id="M_3"
                    d="M98.9562 185.795H99.4572V188.74H98.9582V186.766H98.9522L98.1482 188.173H97.8209L97.017 186.766H97.0109V188.74H96.512V185.795H97.0129L97.9826 187.558H97.9906L98.9562 185.795Z"
                    fill="black"
                  />
                </g>
                <path
                  id="Rectangle 15"
                  d="M84.2402 177.531H94.5485L89.9671 187.267L94.5485 195.857H84.2402L88.8217 187.267L84.2402 177.531Z"
                  fill="black"
                />
                <path
                  id="Line 42"
                  d="M89.3943 187.267H95.1211"
                  stroke="black"
                />
              </g>
              <g id="Group 28">
                <g id="Group 11_2">
                  <circle
                    id="Ellipse 1_4"
                    cx="128.281"
                    cy="187.267"
                    r="3.18609"
                    fill="white"
                    stroke="black"
                    stroke-width="0.5"
                  />
                  <path
                    id="M_4"
                    d="M129.252 185.795H129.753V188.74H129.254V186.766H129.248L128.444 188.173H128.117L127.313 186.766H127.307V188.74H126.808V185.795H127.309L128.279 187.558H128.287L129.252 185.795Z"
                    fill="black"
                  />
                </g>
                <path
                  id="Rectangle 15_2"
                  d="M114.536 177.531H124.845L120.263 187.267L124.845 195.857H114.536L119.118 187.267L114.536 177.531Z"
                  fill="black"
                />
                <path
                  id="Line 42_2"
                  d="M119.69 187.267H125.417"
                  stroke="black"
                />
              </g>
              <rect
                id="Rectangle 17"
                x="77.2393"
                y="93.2747"
                width="31.0702"
                height="42.5238"
                fill="white"
                stroke="black"
              />
              <g id="HCE">
                <text
                  fill="black"
                  xmlSpace="preserve"
                  style="white-space: pre"
                  font-family="Averta-Semibold"
                  font-size="8"
                  letter-spacing="0em"
                >
                  <tspan x="95.3274" y="90.8813">
                    E
                  </tspan>
                </text>
                <text
                  fill="#F58220"
                  xmlSpace="preserve"
                  style="white-space: pre"
                  font-family="Averta-Semibold"
                  font-size="8"
                  letter-spacing="0em"
                >
                  <tspan x="83.6008" y="90.8813">
                    H
                  </tspan>
                </text>
                <text
                  fill="#BDBABA"
                  xmlSpace="preserve"
                  style="white-space: pre"
                  font-family="Averta-Semibold"
                  font-size="8"
                  letter-spacing="0em"
                >
                  <tspan x="89.4914" y="90.8813">
                    C
                  </tspan>
                </text>
              </g>
              <path id="Line 44" d="M80.1755 170.087V136.298" stroke="black" />
              <path
                id="Line 45"
                d="M104.801 159.206V136.299"
                stroke="black"
                stroke-dasharray="2 2"
              />
              <path
                id="Line 46"
                d="M77.312 93.3474L108.237 135.726"
                stroke="black"
              />
              <path
                id="Line 47"
                d="M108.237 93.3474L77.312 135.726"
                stroke="black"
              />
            </g>
            <g id="Group 34">
              <g id="Group 31_2">
                <g id="Group 30_2">
                  <rect
                    id="Rectangle 11_2"
                    x="166.078"
                    y="205.02"
                    width="16.0351"
                    height="11.4536"
                    fill="#F58220"
                  />
                  <g id="HC_2">
                    <path
                      d="M169.514 213.392V208.51H170.342V210.691H172.55V208.51H173.379V213.392H172.55V211.436H170.342V213.392H169.514Z"
                      fill="black"
                    />
                    <path
                      d="M176.815 213.436C176.547 213.436 176.292 213.402 176.05 213.335C175.805 213.268 175.581 213.168 175.372 213.03C175.164 212.892 174.987 212.728 174.836 212.533C174.681 212.339 174.564 212.111 174.48 211.842C174.393 211.574 174.352 211.285 174.352 210.97C174.352 210.691 174.386 210.426 174.453 210.174C174.52 209.923 174.624 209.691 174.765 209.483C174.903 209.275 175.07 209.097 175.265 208.943C175.46 208.788 175.688 208.671 175.953 208.584C176.218 208.5 176.507 208.456 176.815 208.456C177.168 208.456 177.513 208.533 177.849 208.688C178.184 208.842 178.483 209.077 178.738 209.392L178.127 209.909C177.963 209.708 177.765 209.543 177.537 209.423C177.309 209.302 177.067 209.241 176.815 209.241C176.5 209.241 176.225 209.315 175.983 209.456C175.742 209.601 175.554 209.802 175.419 210.064C175.285 210.325 175.218 210.627 175.218 210.97C175.218 211.298 175.285 211.594 175.423 211.849C175.557 212.104 175.748 212.302 175.99 212.443C176.231 212.584 176.507 212.654 176.815 212.654C177.077 212.654 177.322 212.597 177.547 212.476C177.768 212.355 177.963 212.194 178.127 211.99L178.738 212.51C178.513 212.792 178.238 213.017 177.913 213.184C177.587 213.352 177.221 213.436 176.815 213.436Z"
                      fill="black"
                    />
                  </g>
                </g>
                <g id="Group 29_2">
                  <rect
                    id="Rectangle 12_2"
                    x="166.078"
                    y="216.474"
                    width="16.0351"
                    height="11.4536"
                    fill="#BDBABA"
                  />
                  <g id="CC_2">
                    <path
                      d="M171.977 224.89C171.708 224.89 171.453 224.856 171.212 224.789C170.967 224.722 170.742 224.621 170.534 224.484C170.326 224.346 170.148 224.182 169.997 223.987C169.843 223.792 169.725 223.564 169.641 223.296C169.554 223.027 169.514 222.739 169.514 222.423C169.514 222.145 169.547 221.88 169.614 221.628C169.681 221.376 169.785 221.145 169.926 220.937C170.064 220.729 170.232 220.551 170.426 220.396C170.621 220.242 170.849 220.125 171.114 220.037C171.379 219.954 171.668 219.91 171.977 219.91C172.329 219.91 172.675 219.987 173.01 220.141C173.346 220.296 173.644 220.531 173.899 220.846L173.289 221.363C173.124 221.162 172.926 220.997 172.698 220.876C172.47 220.756 172.228 220.695 171.977 220.695C171.661 220.695 171.386 220.769 171.144 220.91C170.903 221.054 170.715 221.255 170.581 221.517C170.447 221.779 170.379 222.081 170.379 222.423C170.379 222.752 170.447 223.047 170.584 223.302C170.718 223.557 170.91 223.755 171.151 223.896C171.393 224.037 171.668 224.108 171.977 224.108C172.238 224.108 172.483 224.051 172.708 223.93C172.93 223.809 173.124 223.648 173.289 223.443L173.899 223.963C173.675 224.245 173.399 224.47 173.074 224.638C172.748 224.806 172.383 224.89 171.977 224.89Z"
                      fill="black"
                    />
                    <path
                      d="M176.97 224.89C176.701 224.89 176.446 224.856 176.205 224.789C175.96 224.722 175.735 224.621 175.527 224.484C175.319 224.346 175.141 224.182 174.99 223.987C174.836 223.792 174.718 223.564 174.634 223.296C174.547 223.027 174.507 222.739 174.507 222.423C174.507 222.145 174.54 221.88 174.607 221.628C174.675 221.376 174.779 221.145 174.919 220.937C175.057 220.729 175.225 220.551 175.419 220.396C175.614 220.242 175.842 220.125 176.107 220.037C176.372 219.954 176.661 219.91 176.97 219.91C177.322 219.91 177.668 219.987 178.003 220.141C178.339 220.296 178.637 220.531 178.892 220.846L178.282 221.363C178.117 221.162 177.919 220.997 177.691 220.876C177.463 220.756 177.221 220.695 176.97 220.695C176.654 220.695 176.379 220.769 176.138 220.91C175.896 221.054 175.708 221.255 175.574 221.517C175.44 221.779 175.372 222.081 175.372 222.423C175.372 222.752 175.44 223.047 175.577 223.302C175.711 223.557 175.903 223.755 176.144 223.896C176.386 224.037 176.661 224.108 176.97 224.108C177.231 224.108 177.476 224.051 177.701 223.93C177.923 223.809 178.117 223.648 178.282 223.443L178.892 223.963C178.668 224.245 178.392 224.47 178.067 224.638C177.741 224.806 177.376 224.89 176.97 224.89Z"
                      fill="black"
                    />
                  </g>
                </g>
              </g>
              <line
                id="Line 32_2"
                x1="182.113"
                y1="207.956"
                x2="294.358"
                y2="207.956"
                stroke="#F58220"
              />
              <line
                id="Line 33_2"
                x1="182.113"
                y1="219.41"
                x2="294.358"
                y2="219.41"
                stroke="#BDBABA"
              />
              <path
                id="Line 34_2"
                d="M233.082 219.337L233.082 169.514L245.681 169.514"
                stroke="#BDBABA"
              />
              <path
                id="Line 35_2"
                d="M255.472 207.884L255.472 169.514L246.826 169.514"
                stroke="#F58220"
              />
              <line
                id="Line 36_2"
                x1="182.113"
                y1="213.683"
                x2="294.358"
                y2="213.683"
                stroke="#F58220"
                stroke-dasharray="2 2"
              />
              <line
                id="Line 37_2"
                x1="182.113"
                y1="225.137"
                x2="294.358"
                y2="225.137"
                stroke="#BDBABA"
                stroke-dasharray="2 2"
              />
              <path
                id="Line 38_2"
                d="M203.302 225.637L203.302 158.633L270.306 158.633"
                stroke="#BDBABA"
                stroke-dasharray="2 2"
              />
              <path
                id="Line 39_2"
                d="M271.451 158.633H285.768V214.183"
                stroke="#F58220"
                stroke-dasharray="2 2"
              />
              <g id="Group 21_2">
                <g id="Group 10_3">
                  <circle
                    id="Ellipse 1_5"
                    cx="224.491"
                    cy="187.267"
                    r="3.18609"
                    fill="white"
                    stroke="black"
                    stroke-width="0.5"
                  />
                  <path
                    id="M_5"
                    d="M225.463 185.795H225.964V188.74H225.465V186.766H225.459L224.655 188.173H224.328L223.524 186.766H223.518V188.74H223.019V185.795H223.52L224.489 187.558H224.497L225.463 185.795Z"
                    fill="black"
                  />
                </g>
                <path
                  id="Rectangle 14_3"
                  d="M227.927 177.531H238.236L233.654 187.267L238.236 195.857H227.927L232.509 187.267L227.927 177.531Z"
                  fill="black"
                />
                <path
                  id="Line 41_3"
                  d="M227.355 187.267H233.082"
                  stroke="black"
                />
              </g>
              <g id="Group 22_2">
                <g id="Group 11_3">
                  <circle
                    id="Ellipse 1_6"
                    cx="264.062"
                    cy="187.267"
                    r="3.18609"
                    fill="white"
                    stroke="black"
                    stroke-width="0.5"
                  />
                  <path
                    id="M_6"
                    d="M265.034 185.795H265.535V188.74H265.036V186.766H265.03L264.226 188.173H263.899L263.095 186.766H263.089V188.74H262.59V185.795H263.091L264.06 187.558H264.068L265.034 185.795Z"
                    fill="black"
                  />
                </g>
                <path
                  id="Rectangle 15_3"
                  d="M250.318 177.531H260.626L256.045 187.267L260.626 195.857H250.318L254.899 187.267L250.318 177.531Z"
                  fill="black"
                />
                <path
                  id="Line 42_3"
                  d="M255.472 187.267H261.199"
                  stroke="black"
                />
              </g>
              <rect
                id="Rectangle 17_2"
                x="243.317"
                y="93.2747"
                width="31.0702"
                height="42.5238"
                fill="white"
                stroke="black"
              />
              <g id="HCE_2">
                <text
                  fill="black"
                  xmlSpace="preserve"
                  style="white-space: pre"
                  font-family="Averta-Semibold"
                  font-size="8"
                  letter-spacing="0em"
                >
                  <tspan x="261.405" y="90.8813">
                    E
                  </tspan>
                </text>
                <text
                  fill="#F58220"
                  xmlSpace="preserve"
                  style="white-space: pre"
                  font-family="Averta-Semibold"
                  font-size="8"
                  letter-spacing="0em"
                >
                  <tspan x="249.679" y="90.8813">
                    H
                  </tspan>
                </text>
                <text
                  fill="#BDBABA"
                  xmlSpace="preserve"
                  style="white-space: pre"
                  font-family="Averta-Semibold"
                  font-size="8"
                  letter-spacing="0em"
                >
                  <tspan x="255.569" y="90.8813">
                    C
                  </tspan>
                </text>
              </g>
              <path
                id="Line 44_2"
                d="M246.253 170.087V136.298"
                stroke="black"
              />
              <path
                id="Line 45_2"
                d="M270.878 159.206V136.299"
                stroke="black"
                stroke-dasharray="2 2"
              />
              <g id="Group 13">
                <g id="Group 12">
                  <circle
                    id="Ellipse 1_7"
                    cx="270.878"
                    cy="166.078"
                    r="3.18609"
                    fill="white"
                    stroke="black"
                    stroke-width="0.5"
                  />
                  <path
                    id="M_7"
                    d="M271.85 164.605H272.351V167.551H271.852V165.577H271.846L271.042 166.984H270.715L269.911 165.577H269.905V167.551H269.406V164.605H269.907L270.877 166.369H270.885L271.85 164.605Z"
                    fill="black"
                  />
                </g>
                <path
                  id="Line 48"
                  d="M270.878 163.214L270.878 158.06"
                  stroke="black"
                />
                <path
                  id="Rectangle 18"
                  d="M262.288 163.787V153.479L270.879 158.06L279.469 153.479V163.787L270.879 159.206L262.288 163.787Z"
                  fill="black"
                />
                <path
                  id="Rectangle 19"
                  d="M270.606 158.133L266.558 150.543H275.199L271.151 158.133H270.606Z"
                  fill="white"
                  stroke="black"
                />
              </g>
              <path
                id="Line 46_2"
                d="M243.39 93.3474L274.314 135.726"
                stroke="black"
              />
              <path
                id="Line 47_2"
                d="M274.314 93.3474L243.39 135.726"
                stroke="black"
              />
            </g>
            <g id="Group 35">
              <g id="Group 31_3">
                <g id="Group 30_3">
                  <rect
                    id="Rectangle 11_3"
                    x="328.719"
                    y="205.02"
                    width="16.0351"
                    height="11.4536"
                    fill="#F58220"
                  />
                  <g id="HC_3">
                    <path
                      d="M332.155 213.392V208.51H332.984V210.691H335.192V208.51H336.021V213.392H335.192V211.436H332.984V213.392H332.155Z"
                      fill="black"
                    />
                    <path
                      d="M339.457 213.436C339.189 213.436 338.933 213.402 338.692 213.335C338.447 213.268 338.222 213.168 338.014 213.03C337.806 212.892 337.628 212.728 337.477 212.533C337.323 212.339 337.205 212.111 337.121 211.842C337.034 211.574 336.994 211.285 336.994 210.97C336.994 210.691 337.028 210.426 337.095 210.174C337.162 209.923 337.266 209.691 337.407 209.483C337.544 209.275 337.712 209.097 337.907 208.943C338.101 208.788 338.329 208.671 338.595 208.584C338.86 208.5 339.148 208.456 339.457 208.456C339.809 208.456 340.155 208.533 340.49 208.688C340.826 208.842 341.125 209.077 341.38 209.392L340.769 209.909C340.605 209.708 340.407 209.543 340.178 209.423C339.95 209.302 339.709 209.241 339.457 209.241C339.142 209.241 338.866 209.315 338.625 209.456C338.383 209.601 338.195 209.802 338.061 210.064C337.927 210.325 337.86 210.627 337.86 210.97C337.86 211.298 337.927 211.594 338.064 211.849C338.199 212.104 338.39 212.302 338.631 212.443C338.873 212.584 339.148 212.654 339.457 212.654C339.719 212.654 339.964 212.597 340.188 212.476C340.41 212.355 340.605 212.194 340.769 211.99L341.38 212.51C341.155 212.792 340.88 213.017 340.554 213.184C340.229 213.352 339.863 213.436 339.457 213.436Z"
                      fill="black"
                    />
                  </g>
                </g>
                <g id="Group 29_3">
                  <rect
                    id="Rectangle 12_3"
                    x="328.719"
                    y="216.474"
                    width="16.0351"
                    height="11.4536"
                    fill="#BDBABA"
                  />
                  <g id="CC_3">
                    <path
                      d="M334.618 224.89C334.35 224.89 334.095 224.856 333.853 224.789C333.608 224.722 333.383 224.621 333.175 224.484C332.967 224.346 332.789 224.182 332.638 223.987C332.484 223.792 332.367 223.564 332.283 223.296C332.196 223.027 332.155 222.739 332.155 222.423C332.155 222.145 332.189 221.88 332.256 221.628C332.323 221.376 332.427 221.145 332.568 220.937C332.706 220.729 332.873 220.551 333.068 220.396C333.263 220.242 333.491 220.125 333.756 220.037C334.021 219.954 334.31 219.91 334.618 219.91C334.971 219.91 335.316 219.987 335.652 220.141C335.987 220.296 336.286 220.531 336.541 220.846L335.93 221.363C335.766 221.162 335.568 220.997 335.34 220.876C335.112 220.756 334.87 220.695 334.618 220.695C334.303 220.695 334.028 220.769 333.786 220.91C333.544 221.054 333.357 221.255 333.222 221.517C333.088 221.779 333.021 222.081 333.021 222.423C333.021 222.752 333.088 223.047 333.226 223.302C333.36 223.557 333.551 223.755 333.793 223.896C334.034 224.037 334.31 224.108 334.618 224.108C334.88 224.108 335.125 224.051 335.35 223.93C335.571 223.809 335.766 223.648 335.93 223.443L336.541 223.963C336.316 224.245 336.041 224.47 335.716 224.638C335.39 224.806 335.024 224.89 334.618 224.89Z"
                      fill="black"
                    />
                    <path
                      d="M339.611 224.89C339.343 224.89 339.088 224.856 338.846 224.789C338.601 224.722 338.376 224.621 338.168 224.484C337.96 224.346 337.783 224.182 337.632 223.987C337.477 223.792 337.36 223.564 337.276 223.296C337.189 223.027 337.148 222.739 337.148 222.423C337.148 222.145 337.182 221.88 337.249 221.628C337.316 221.376 337.42 221.145 337.561 220.937C337.699 220.729 337.866 220.551 338.061 220.396C338.256 220.242 338.484 220.125 338.749 220.037C339.014 219.954 339.303 219.91 339.611 219.91C339.964 219.91 340.309 219.987 340.645 220.141C340.98 220.296 341.279 220.531 341.534 220.846L340.923 221.363C340.759 221.162 340.561 220.997 340.333 220.876C340.105 220.756 339.863 220.695 339.611 220.695C339.296 220.695 339.021 220.769 338.779 220.91C338.538 221.054 338.35 221.255 338.215 221.517C338.081 221.779 338.014 222.081 338.014 222.423C338.014 222.752 338.081 223.047 338.219 223.302C338.353 223.557 338.544 223.755 338.786 223.896C339.027 224.037 339.303 224.108 339.611 224.108C339.873 224.108 340.118 224.051 340.343 223.93C340.564 223.809 340.759 223.648 340.923 223.443L341.534 223.963C341.309 224.245 341.034 224.47 340.709 224.638C340.383 224.806 340.017 224.89 339.611 224.89Z"
                      fill="black"
                    />
                  </g>
                </g>
              </g>
              <line
                id="Line 32_3"
                x1="344.754"
                y1="207.956"
                x2="457"
                y2="207.956"
                stroke="#F58220"
              />
              <line
                id="Line 33_3"
                x1="344.754"
                y1="219.41"
                x2="457"
                y2="219.41"
                stroke="#BDBABA"
              />
              <path
                id="Line 34_3"
                d="M387.706 219.337L387.706 158.633L408.895 158.633"
                stroke="#BDBABA"
              />
              <path
                id="Line 35_3"
                d="M428.995 207.884L428.995 158.633L416.912 158.633"
                stroke="#F58220"
              />
              <line
                id="Line 36_3"
                x1="344.754"
                y1="213.683"
                x2="457"
                y2="213.683"
                stroke="#F58220"
                stroke-dasharray="2 2"
              />
              <line
                id="Line 37_3"
                x1="344.754"
                y1="225.137"
                x2="457"
                y2="225.137"
                stroke="#BDBABA"
                stroke-dasharray="2 2"
              />
              <path
                id="Line 38_3"
                d="M392.287 225.637L392.287 174.668L408.895 174.668"
                stroke="#BDBABA"
                stroke-dasharray="2 2"
              />
              <path
                id="Line 39_3"
                d="M408.895 174.095H424.93V214.183"
                stroke="#F58220"
                stroke-dasharray="2 2"
              />
              <rect
                id="Rectangle 17_3"
                x="405.959"
                y="93.2747"
                width="31.0702"
                height="42.5238"
                fill="white"
                stroke="black"
              />
              <g id="HCE_3">
                <text
                  fill="black"
                  xmlSpace="preserve"
                  style="white-space: pre"
                  font-family="Averta-Semibold"
                  font-size="8"
                  letter-spacing="0em"
                >
                  <tspan x="424.047" y="90.8813">
                    E
                  </tspan>
                </text>
                <text
                  fill="#F58220"
                  xmlSpace="preserve"
                  style="white-space: pre"
                  font-family="Averta-Semibold"
                  font-size="8"
                  letter-spacing="0em"
                >
                  <tspan x="412.32" y="90.8813">
                    H
                  </tspan>
                </text>
                <text
                  fill="#BDBABA"
                  xmlSpace="preserve"
                  style="white-space: pre"
                  font-family="Averta-Semibold"
                  font-size="8"
                  letter-spacing="0em"
                >
                  <tspan x="418.211" y="90.8813">
                    C
                  </tspan>
                </text>
              </g>
              <path
                id="Line 44_3"
                d="M408.895 175.241V136.298"
                stroke="black"
              />
              <path
                id="Line 45_3"
                d="M408.895 174.095V190.703H433.52V136.298"
                stroke="black"
                stroke-dasharray="2 2"
              />
              <g id="Group 13_2">
                <g id="Group 12_2">
                  <circle
                    id="Ellipse 1_8"
                    cx="408.895"
                    cy="166.078"
                    r="3.18609"
                    fill="white"
                    stroke="black"
                    stroke-width="0.5"
                  />
                  <path
                    id="M_8"
                    d="M409.866 164.605H410.367V167.551H409.868V165.577H409.862L409.058 166.984H408.731L407.927 165.577H407.921V167.551H407.422V164.605H407.923L408.893 166.369H408.901L409.866 164.605Z"
                    fill="black"
                  />
                </g>
                <path
                  id="Rectangle 18_2"
                  d="M400.304 163.787V153.479L408.895 158.06L417.485 153.479V163.787L408.895 159.206L400.304 163.787Z"
                  fill="black"
                />
                <path
                  id="Rectangle 19_2"
                  d="M408.622 158.133L404.574 150.543H413.216L409.168 158.133H408.622Z"
                  fill="white"
                  stroke="black"
                />
              </g>
              <g id="Group 32">
                <path
                  id="Rectangle 18_3"
                  d="M400.304 179.822V169.514L408.895 174.095L417.485 169.514V179.822L408.895 175.241L400.304 179.822Z"
                  fill="black"
                />
                <path
                  id="Rectangle 19_3"
                  d="M409.167 175.741L413.215 183.331H404.574L408.622 175.741H409.167Z"
                  fill="white"
                  stroke="black"
                />
              </g>
              <path
                id="Line 46_3"
                d="M406.031 93.3474L436.956 135.726"
                stroke="black"
              />
              <path
                id="Line 47_3"
                d="M436.956 93.3474L406.031 135.726"
                stroke="black"
              />
            </g>
            <path
              id="Line 49"
              d="M392.287 58.9864L398.06 48.9864H386.513L392.287 58.9864ZM65.2856 19.4714V18.4714H64.2856V19.4714H65.2856ZM392.287 19.4714H393.287V18.4714H392.287V19.4714ZM66.2856 59.5591V19.4714H64.2856V59.5591H66.2856ZM65.2856 20.4714L392.287 20.4714V18.4714L65.2856 18.4714V20.4714ZM391.287 19.4714V49.9864H393.287V19.4714H391.287Z"
              fill="black"
            />
            <text
              id="Reduction/Optimizing numbers of the valves"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="9"
              letter-spacing="0em"
            >
              <tspan x="134.008" y="8.05322">
                Reduction/Optimizing numbers of the valves
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}
