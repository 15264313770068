import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function ButtonStack({ children, buttons }) {
  const classes = useStyles();
  return <Box className={classes.root}>{children}</Box>;
}
