import { Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";

//Images
import backgroundImg from "../../../../../../assets/_backgrounds/neuheiten-2024/6.webp";
import KNXHFImage from "../../../../../../assets/neuheiten-2024/bje/knx-hf-praesenzmelder/knx-hf-praesenzmelder.webp";
import BetterImage from "../../../../../../components/BetterImage";

function KNXHFPraesenzmelder() {
  return (
    <Slide
      background={{
        src: backgroundImg,
        backgroundPosition: "center",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="KNX HF Präsenzmelder" />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  "Kleine Größe",
                  "Völlig flaches Design",
                  "Kann in Zwischendecken integriert und versteckt werden, fast unsichtbar",
                  "Genaue bzw. 'echte' Präsenzerfassung (schlafende Personen, etc. 'True Presence')",
                  "Durchdringung von Hindernissen und einigen Materialien (Holz, Glas, dünne Zwischenwände)",
                  "Applikationsseitige Einstellung der Reichweite",
                  "Temperaturunabhängig (keine Reduzierung der Reichweite bei hohen Temperaturen)",
                  "Verfübar in Weiß und Schwarz",
                  <>
                    Advanced Version mit Multisensorik (CO<sub>2</sub>, rH,
                    etc.)
                  </>,
                ]}
                dense
              />
              <BetterImage
                align="left"
                height="auto"
                width="25%"
                src={KNXHFImage}
                alt="KNX HF Präsenzmelder"
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default KNXHFPraesenzmelder;
