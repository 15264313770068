import { Grid } from "@material-ui/core";

import Slide from "../../../../../../../components/Slide";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import QTabs from "../../../../../../../components/QTabs";
import ImageComponent from "../../../../../../../components/ImageComponent";
import QBodyText from "../../../../../../../components/QBodyText";

export default function Tenton() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                ABB i-bus<sup>®</sup> KNX
              </>
            }
            subTitle={
              <>
                Betriebsarten Busch-Tenton®<sup>®</sup>{" "}
              </>
            }

          />
        </Grid>
        <QTabs
          tabs={[
            {
              head: "Halbautomatik",
              content: (
                <>
                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <QBodyText>
                        <strong>
                          Der Präsenzmelder erfüllt zwei Funktionen
                        </strong>
                      </QBodyText>

                      <QList
                        title="Vorteile"
                        items={[
                          "Präsenzerkennung: Beleuchtung wird bei Abwesenheit automatisch abgeschaltet.",
                          "Helligkeitserfassung und Regelung: In Abhängigkeit der Helligkeit auf der Arbeitsfläche wird die künstliche Beleuchtung geregelt, um eine konstante Beleuchtungsstärke zu erzielen. Dies führt zu weniger Ermüdung aufgrund schwankender Beleuchtungsstärke",
                          "Energieeinsparung: Beleuchtungsstärke wird dem Bedarf entsprechend geregelt und somit Energie eingespart.",
                        ]}
                        dense
                      />

                      <QBodyText>* Quick Mounting System (QMS)</QBodyText>
                    </Grid>

                    <Grid item xs={6}>
                      <ImageComponent
                        src="../../../assets/abbknx/Anwendung_Loesung/produkte/tenton-praesenzmelder-gruppe.webp"
                        alt="Tenton"
                        previewImgMaxHeight="50vh"
                      />
                    </Grid>
                  </Grid>
                </>
              ),
            },
            {
              head: "Vollautomatik",
              content: (
                <>
                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <QBodyText>
                        <strong>
                          Der Präsenzmelder erfüllt zwei Funktionen
                        </strong>
                      </QBodyText>

                      <QList
                        title="Vorteile"
                        items={[
                          "Präsenzerkennung: Beleuchtung wird bei Abwesenheit automatisch abgeschaltet",
                          "Helligkeitserfassung und Regelung in Abhängigkeit der Helligkeit auf der Arbeitsfläche wird die künstliche Beleuchtung geregelt, um eine konstante Beleuchtungsstärke zu erzielen. Dies führt zu weniger Ermüdung aufgrund schwankender Beleuchtungsstärke",
                          "Energieeinsparung: Beleuchtungsstärke wird dem Bedarf entsprechend geregelt und somit Energie eingespart",
                        ]}
                        dense
                      />

                      <QBodyText>* Quick Mounting System (QMS)</QBodyText>
                    </Grid>

                    <Grid item xs={6}>
                      <ImageComponent
                        src="../../../assets/abbknx/2021/praesenzmelder.webp"
                        alt="Präsenzmelder"
                        previewImgMaxHeight="50vh"
                      />
                    </Grid>
                  </Grid>
                </>
              ),
            },
          ]}
        />
      </Grid>
    </Slide>
  );
}
