import { h } from "preact";
import { Grid, makeStyles } from "@material-ui/core";

import Slide from "../../../../../../../components/Slide";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import QTabs from "../../../../../../../components/QTabs";
import ImageComponent from "../../../../../../../components/ImageComponent";
import QBodyText from "../../../../../../../components/QBodyText";

export default function Reg() {
  return (
    <Slide
      background={{}}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://new.abb.com/products/de/2CDG110243R0011/usb-s1-2-usb-schnittstelle-reg",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                ABB i-bus<sup>®</sup> KNX
              </>
            }
            subTitle="Produktdetails REG"

          />
        </Grid>
        <QTabs
          tabs={[
            {
              head: "DALI-Gateway KNX",
              content: (
                <>
                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <QBodyText>
                        <strong>DALI-Gateway, Basic, 1-fach, REG</strong>
                      </QBodyText>
                      <QList
                        title="DALI-Gateway KNX"
                        items={[
                          "Gateway",
                          "Einzeladressierung und Gruppenbildung",
                          "Fehlerrückmeldung / Leuchtenausfall",
                        ]}
                        dense
                      />
                      <QList
                        title="DALI-Gateway KNX 2"
                        items={["Notlichtfunktion", "Licht-Szenen"]}
                        dense
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <ImageComponent
                        src="../../../assets/abbknx/2021/dali-gateway.webp"
                        alt="DG/S 1.64.1.1"
                        previewImgMaxHeight="50vh"
                      />
                    </Grid>
                  </Grid>
                </>
              ),
            },
            {
              head: "Netzteil KNX",
              content: (
                <>
                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <QBodyText>
                        <strong>
                          Spannungsversorgung mit Diagnosefunktion, 640mA, REG
                        </strong>
                      </QBodyText>

                      <QList
                        title="Netzteil KNX"
                        items={["Busspannung", "Überlastung", "Bus-Reset"]}
                        dense
                      />

                      <QList
                        title="Netzteil KNX 2"
                        items={[
                          "Erweiterte Diagnose über LED und KNX Telegramme",
                        ]}
                        dense
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <ImageComponent
                        src="../../../../../../../assets/abbknx/Anwendungen/Tageslichtabhaengige-Beleuchtungssteuerung/netzteil-knx.webp"
                        alt="SV/S30.640.5.1"
                        previewImgMaxHeight="50vh"
                      />
                    </Grid>
                  </Grid>
                </>
              ),
            },
            {
              head: "USB-Schnittstelle",
              content: (
                <>
                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <QBodyText>
                        <strong>USB-Schnittstelle REG</strong>
                      </QBodyText>

                      <QList
                        title="REG"
                        items={[
                          "Inbetriebnahme und Parametrisierung mit ETS",
                          "Unterstützt Gruppen-, Busmonitor und KNX-Long Frames für schnelle Programmierung",
                          "Anzeige LED für Telegrammverkehr",
                        ]}
                        dense
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <ImageComponent
                        src="../../../../../../../assets/abbknx/Anwendungen/Tageslichtabhaengige-Beleuchtungssteuerung/usb-schnittstelle.webp"
                        alt="USB/S1.2"
                        previewImgMaxHeight="50vh"
                      />
                    </Grid>
                  </Grid>
                </>
              ),
            },
            {
              head: "Absicherung",
              content: (
                <>
                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <QBodyText>
                        <strong>Sicherungsautomat mit Hilfsschalter</strong>
                      </QBodyText>

                      <QList
                        title="Absicherung"
                        items={[
                          "Leitungsschutz",
                          "Abschaltung der DALI Leitung",
                          "Strukturierte DALI Adressierung durch Stromkreiszuschaltung",
                        ]}
                        dense
                      />

                      <QBodyText>
                        <strong>Querverdrahtungsschiene</strong>
                      </QBodyText>

                      <QList
                        title="Absicherung"
                        items={[
                          "Einspeisung Sicherungsautomaten",
                          "Unterbrechung für Hilfsschalter",
                        ]}
                        dense
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <ImageComponent
                        images={[
                          {
                            src: "../../../../../../../assets/abbknx/Anwendungen/Tageslichtabhaengige-Beleuchtungssteuerung/absicherung-1.webp",
                            alt: "S201-B10",
                          },
                          {
                            src: "../../../../../../../assets/abbknx/Anwendungen/Tageslichtabhaengige-Beleuchtungssteuerung/absicherung-2.webp",
                            alt: "2 Stk. S2C-H6R",
                          },
                        ]}
                        col={6}
                        previewImgMaxHeight="50vh"
                      />
                    </Grid>
                  </Grid>
                </>
              ),
            },
          ]}
        />
      </Grid>
    </Slide>
  );
}
