import { Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import ImageComponent from "../../../../../../components/ImageComponent";

function KNXJalousieAktoren() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Neue KNX Jalousie Aktoren" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  "Neue Typen mit/ohne Fahrzeitmessung in 2/4/8/12 fach Versionen",
                  "Versionen: 4-fach oder 8-fach mit potenzialfreien freien Binäreingängen",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default KNXJalousieAktoren;
