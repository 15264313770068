import { Grid } from "@material-ui/core";
import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QBodyText from "../../../../../../components/QBodyText";
import QList from "../../../../../../components/QList";
import CenteredImage from "../../../../../../components/CenteredImage";

// Images
import BackgroundImage from "../../../../../../assets/welcome/2021/0206/wc_jog_in0079_0129_b1_M22381.webp"
import EinfamilienhausVideoImage from "../../../../../../assets/welcome/2021/anwendungen/einfamilienhausVideo/uebersicht.webp"

export default function EinfamilienhausVideo() {
  return (
    <Slide
      background={{
        src: BackgroundImage,
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          target: "_blank",
          link: "https://www.busch-jaeger.de/online-katalog/detail/2TMA200010X0003",
        },
        {
          type: "pdf",
          img: "preisbeispiel",
          path: "../../../../../../assets/pdf/preisbeispiele/Busch-Welcome/Preisbeispiel Busch-Welcome - Einfamilienhaus Video.pdf",
        },
        {
          type: "galery",
          img: "info",
          images: [
            {
              src: EinfamilienhausVideoImage,
              alt: "Anwendung Einfamilienhaus Video",
            },
          ],
        },
      ]}
    >
      <CenteredImage
        src="../../../../../../assets/welcome/2021/dasSystem/video/f_83121_1-660.webp"
        alt="Außenstation Video"
        height="50vh"
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Welcome<sup>®</sup>
              </>
            }
            subTitle="Anwendung Einfamilienhaus Video"

          />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={5}>
              <QBodyText>Wissen, wer vor der Tür steht</QBodyText>
              <QList
                title="Vorteile"
                headline="Vorteile"
                items={[
                  "Außenstationen 130 oder 180 Grad",
                  "Monitor bis 7“",
                  "Bildspeicher im Monitor",
                  "Einfache 2-Draht-Installation",
                  "Zustandsanzeige im Kameramodul",
                ]}
                dense
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
