import { Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import ImageComponent from "../../../../../../components/ImageComponent";

function SlimRangeAdapterSCC() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Slim Range Adapter SCC" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                paddingLeft
                items={[
                  "0,5 TE Baubreite",
                  "Adapter für den Anbau von Zubehör an",
                  ["SN201", "DS203NC", "S200C", "DS301C"],
                  "Hilfs- und Signalkontakte",
                  "links anbaubar für S200C-Reihe",
                  "bis zu 20.000 Schaltzyklen",
                  "zwischen -25° und +55°C Betriebstemperatur",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default SlimRangeAdapterSCC;
