import { Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import ImageComponent from "../../../../../../components/ImageComponent";

// Images
import ASKIImage from "../../../../../../assets/neuheiten-2024/abb/aski/aski.webp"

function ASKI() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="ASKI Energie- und Lastmanagement" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  "Messung, Überwachung, Steuerung und Regelung des Energieverbrauchs",
                  "Intelligentes Lastspitzenmanagement",
                  "Eigenverbrauchsoptimierung",
                  "Flexibles und skalierbares System",
                  "Herstellerunabhängige Anbindung von Geräten",
                  "Lokale Lösung mit optionaler Cloud-Anbindung",
                  "Gelistet zur Umsetzung der DIN EN ISO50001",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}>
              <ImageComponent
                src={ASKIImage}
                alt="ASKI Energie- und Lastmanagement"
                col={12}
                wrapperHeight="45vh"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default ASKI;
