import Dexie from "dexie";

const crmDB = new Dexie("data");

crmDB.version(1).stores({ data: "id, value" });

crmDB.on("populate", async () => {
  await crmDB.data.add({ id: "fetchDate", value: null });
  await crmDB.data.add({ id: "buffer", value: null });
});

crmDB.open();

export default crmDB;
