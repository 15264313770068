import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";

import AnalogAktor from "../../../../../../../../../assets/abbknx/Anwendungen/ClimaEco/rtr-stellenantrieb/Analogaktor.webp";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

export default function AES() {
  return (
    <Slide background={{ alignment: "left" }}>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="AE/S" />
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <QBodyText>Infos folgen ...</QBodyText>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
