import { h } from "preact";
import { Grid } from "@material-ui/core";

import Slide from "../../../../../../../components/Slide";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import ImageComponent from "../../../../../../../components/ImageComponent";
import QBodyText from "../../../../../../../components/QBodyText";
import AnwendungImage from "../../../../../../../assets/abbknx/2021/praesenzmelder-funktionen.webp";

export default function Anwendungsbeschreibung() {

  return (
  <Slide background >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                ABB i-bus<sup>®</sup> KNX
              </>
            }
            subTitle="Anwendungsbeschreibung"

          />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={5}>
              <QBodyText>
                <strong>Der Präsenzmelder erfüllt zwei Funktionen</strong>
              </QBodyText>

              <QList
                title="Vorteile"
                items={[
                  "Präsenzerkennung: Beleuchtung wird bei Abwesenheit automatisch abgeschaltet",
                  "Helligkeitserfassung und Regelung in Abhängigkeit der Helligkeit auf der Arbeitsfläche wird die künstliche Beleuchtung geregelt, um eine konstante Beleuchtungsstärke zu erzielen. Dies führt zu weniger Ermüdung aufgrund schwankender Beleuchtungsstärke",
                  "Energieeinsparung: Beleuchtungsstärke wird dem Bedarf entsprechend geregelt und somit Energie eingespart",
                ]}
                dense
                
              />

              <QBodyText>* Quick Mounting System (QMS)</QBodyText>
            </Grid>

            <Grid item xs={7}>
              <ImageComponent
                src={AnwendungImage}
                alt="KNX Präsenzmelder Funktionen"
                wrapperHeight="60vh"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
