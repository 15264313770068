import { Grid } from "@material-ui/core";
import React from "react";
import QHeader from "../../../../../../components/QHeader";
import QTable from "../../../../../../components/QTable";
import Slide from "../../../../../../components/Slide";

const tableContent = [
  {
    name: "Row1",
    values: [
      {
        label: (
          <img
            height="50"
            width="50"
            src="../../../../../../assets/welcome-ip/Anwendungen/Einfamilienhaus/ip-touch.webp"
          />
        ),
      },
      { label: 'IP Touch 7"' },
      { label: "1" },
      { label: "H82364-W-03" },
      { label: "917,00" },
    ],
  },
  {
    name: "Row2",
    values: [
      {
        label: (
          <img
            height="50"
            width="50"
            src="../../../../../../assets/welcome-ip/Anwendungen/Einfamilienhaus/schaltaktor.webp"
          />
        ),
      },
      { label: "IP Schaltaktor" },
      { label: "1" },
      { label: "H8304-03" },
      { label: "133,00" },
    ],
  },
  {
    name: "Row4",
    values: [
      {
        label: (
          <img
            height="50"
            width="50"
            src="../../../../../../assets/welcome-ip/Anwendungen/Einfamilienhaus/sap-lite.webp"
          />
        ),
      },
      { label: "Smart Access Point Lite" },
      { label: "1" },
      { label: "D04012-03" },
      { label: "482,00" },
    ],
  },
  {
    name: "Row5",
    values: [
      {
        label: (
          <img
            height="50"
            width="50"
            src="../../../../../../assets/welcome-ip/Anwendungen/Einfamilienhaus/aussenstation.webp"
          />
        ),
      },
      { label: 'Außenstation Video, touch 5"' },
      { label: "1" },
      { label: "H81381T-S-03" },
      { label: "2.563,00" },
    ],
  },
  {
    name: "Row5",
    values: [
      {
        label: (
          <img
            height="50"
            width="50"
            src="../../../../../../assets/welcome-ip/Anwendungen/Einfamilienhaus/montagedose.webp"
          />
        ),
      },
      { label: "Unterputz-Montagedose" },
      { label: "1" },
      { label: "41384F-B-03" },
      { label: "93,00" },
    ],
  },
  {
    name: "Row11",
    values: [
      { label: "" },
      { label: "" },
      { label: "" },
      {
        label: "Summe",
        fontFamily: "Averta-Bold",
        backgroundColor: "var(--grey-20)",
      },
      {
        label: "4.983,72 €",
        fontFamily: "Averta-Bold",
        backgroundColor: "var(--grey-20)",
      },
    ],
  },
];

export default function PreisbeispielGewerbe() {
  return (
    <Slide>
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader title="Preisbeispiel Unternehmen" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <QTable
                header={[
                  { label: "#" },
                  { label: "Beschreibung" },
                  { label: "Anzahl" },
                  { label: "Artikelnr." },
                  { label: "Summe" },
                ]}
                content={tableContent}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
