import { h } from "preact";
import { useDispatch, useSelector } from "react-redux";
import {
  setMissionToZeroNotes,
  getMissionToZeroData,
} from "../../../redux/modules/chapterMissionToZero";
import { Grid } from "@material-ui/core";
import QActionPaper from "../../QActionPaper";
import QTextFieldAction from "../../QTextFieldAction";

export default function MissionToZeroPage({ context }) {
  const dispatch = useDispatch();
  const m2zData = useSelector((state) => getMissionToZeroData(state));
  const { notes } = m2zData;

  const handleSetSetupField = (valueSet) => {
    switch (valueSet.id) {
      case "notes":
        {
          dispatch(setMissionToZeroNotes(valueSet.value));
        }
        break;
    }
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <QActionPaper title="Notizen" context={context}>
          <QTextFieldAction
            value={notes.value}
            valueID="notes"
            setSetupField={handleSetSetupField}
          />
        </QActionPaper>
      </Grid>
    </Grid>
  );
}
