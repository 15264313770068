// source: playbook-adm.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.model.PlaybookAddress', null, global);
goog.exportSymbol('proto.model.PlaybookCompany', null, global);
goog.exportSymbol('proto.model.PlaybookCompanyInfo', null, global);
goog.exportSymbol('proto.model.PlaybookContact', null, global);
goog.exportSymbol('proto.model.PlaybookContacting', null, global);
goog.exportSymbol('proto.model.PlaybookCrm', null, global);
goog.exportSymbol('proto.model.PlaybookStructure', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.PlaybookCrm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.PlaybookCrm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.PlaybookCrm.displayName = 'proto.model.PlaybookCrm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.PlaybookStructure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.PlaybookStructure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.PlaybookStructure.displayName = 'proto.model.PlaybookStructure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.PlaybookAddress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.PlaybookAddress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.PlaybookAddress.displayName = 'proto.model.PlaybookAddress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.PlaybookContacting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.PlaybookContacting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.PlaybookContacting.displayName = 'proto.model.PlaybookContacting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.PlaybookCompany = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.PlaybookCompany.repeatedFields_, null);
};
goog.inherits(proto.model.PlaybookCompany, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.PlaybookCompany.displayName = 'proto.model.PlaybookCompany';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.PlaybookCompanyInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.PlaybookCompanyInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.PlaybookCompanyInfo.displayName = 'proto.model.PlaybookCompanyInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.PlaybookContact = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.PlaybookContact, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.PlaybookContact.displayName = 'proto.model.PlaybookContact';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.PlaybookCrm.prototype.toObject = function(opt_includeInstance) {
  return proto.model.PlaybookCrm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.PlaybookCrm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PlaybookCrm.toObject = function(includeInstance, msg) {
  var f, obj = {
    structuresMap: (f = msg.getStructuresMap()) ? f.toObject(includeInstance, proto.model.PlaybookStructure.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.PlaybookCrm}
 */
proto.model.PlaybookCrm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.PlaybookCrm;
  return proto.model.PlaybookCrm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.PlaybookCrm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.PlaybookCrm}
 */
proto.model.PlaybookCrm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getStructuresMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.model.PlaybookStructure.deserializeBinaryFromReader, "", new proto.model.PlaybookStructure());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.PlaybookCrm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.PlaybookCrm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.PlaybookCrm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PlaybookCrm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructuresMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.model.PlaybookStructure.serializeBinaryToWriter);
  }
};


/**
 * map<string, PlaybookStructure> structures = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.model.PlaybookStructure>}
 */
proto.model.PlaybookCrm.prototype.getStructuresMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.model.PlaybookStructure>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.model.PlaybookStructure));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.model.PlaybookCrm} returns this
 */
proto.model.PlaybookCrm.prototype.clearStructuresMap = function() {
  this.getStructuresMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.PlaybookStructure.prototype.toObject = function(opt_includeInstance) {
  return proto.model.PlaybookStructure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.PlaybookStructure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PlaybookStructure.toObject = function(includeInstance, msg) {
  var f, obj = {
    companiesMap: (f = msg.getCompaniesMap()) ? f.toObject(includeInstance, proto.model.PlaybookCompany.toObject) : [],
    adm: (f = msg.getAdm()) && proto.model.PlaybookContact.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.PlaybookStructure}
 */
proto.model.PlaybookStructure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.PlaybookStructure;
  return proto.model.PlaybookStructure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.PlaybookStructure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.PlaybookStructure}
 */
proto.model.PlaybookStructure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getCompaniesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.model.PlaybookCompany.deserializeBinaryFromReader, 0, new proto.model.PlaybookCompany());
         });
      break;
    case 2:
      var value = new proto.model.PlaybookContact;
      reader.readMessage(value,proto.model.PlaybookContact.deserializeBinaryFromReader);
      msg.setAdm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.PlaybookStructure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.PlaybookStructure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.PlaybookStructure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PlaybookStructure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompaniesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.model.PlaybookCompany.serializeBinaryToWriter);
  }
  f = message.getAdm();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.model.PlaybookContact.serializeBinaryToWriter
    );
  }
};


/**
 * map<int32, PlaybookCompany> companies = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.model.PlaybookCompany>}
 */
proto.model.PlaybookStructure.prototype.getCompaniesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.model.PlaybookCompany>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.model.PlaybookCompany));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.model.PlaybookStructure} returns this
 */
proto.model.PlaybookStructure.prototype.clearCompaniesMap = function() {
  this.getCompaniesMap().clear();
  return this;
};


/**
 * optional PlaybookContact adm = 2;
 * @return {?proto.model.PlaybookContact}
 */
proto.model.PlaybookStructure.prototype.getAdm = function() {
  return /** @type{?proto.model.PlaybookContact} */ (
    jspb.Message.getWrapperField(this, proto.model.PlaybookContact, 2));
};


/**
 * @param {?proto.model.PlaybookContact|undefined} value
 * @return {!proto.model.PlaybookStructure} returns this
*/
proto.model.PlaybookStructure.prototype.setAdm = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.PlaybookStructure} returns this
 */
proto.model.PlaybookStructure.prototype.clearAdm = function() {
  return this.setAdm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.PlaybookStructure.prototype.hasAdm = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.PlaybookAddress.prototype.toObject = function(opt_includeInstance) {
  return proto.model.PlaybookAddress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.PlaybookAddress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PlaybookAddress.toObject = function(includeInstance, msg) {
  var f, obj = {
    land: jspb.Message.getFieldWithDefault(msg, 1, ""),
    city: jspb.Message.getFieldWithDefault(msg, 2, ""),
    street: jspb.Message.getFieldWithDefault(msg, 3, ""),
    zipcode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    district: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.PlaybookAddress}
 */
proto.model.PlaybookAddress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.PlaybookAddress;
  return proto.model.PlaybookAddress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.PlaybookAddress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.PlaybookAddress}
 */
proto.model.PlaybookAddress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLand(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setZipcode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistrict(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.PlaybookAddress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.PlaybookAddress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.PlaybookAddress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PlaybookAddress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLand();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getZipcode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDistrict();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string land = 1;
 * @return {string}
 */
proto.model.PlaybookAddress.prototype.getLand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PlaybookAddress} returns this
 */
proto.model.PlaybookAddress.prototype.setLand = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string city = 2;
 * @return {string}
 */
proto.model.PlaybookAddress.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PlaybookAddress} returns this
 */
proto.model.PlaybookAddress.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string street = 3;
 * @return {string}
 */
proto.model.PlaybookAddress.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PlaybookAddress} returns this
 */
proto.model.PlaybookAddress.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string zipCode = 4;
 * @return {string}
 */
proto.model.PlaybookAddress.prototype.getZipcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PlaybookAddress} returns this
 */
proto.model.PlaybookAddress.prototype.setZipcode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string district = 5;
 * @return {string}
 */
proto.model.PlaybookAddress.prototype.getDistrict = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PlaybookAddress} returns this
 */
proto.model.PlaybookAddress.prototype.setDistrict = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.PlaybookContacting.prototype.toObject = function(opt_includeInstance) {
  return proto.model.PlaybookContacting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.PlaybookContacting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PlaybookContacting.toObject = function(includeInstance, msg) {
  var f, obj = {
    tel: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mobile: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mail: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fax: jspb.Message.getFieldWithDefault(msg, 4, ""),
    homepage: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.PlaybookContacting}
 */
proto.model.PlaybookContacting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.PlaybookContacting;
  return proto.model.PlaybookContacting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.PlaybookContacting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.PlaybookContacting}
 */
proto.model.PlaybookContacting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFax(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHomepage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.PlaybookContacting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.PlaybookContacting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.PlaybookContacting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PlaybookContacting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFax();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHomepage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string tel = 1;
 * @return {string}
 */
proto.model.PlaybookContacting.prototype.getTel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PlaybookContacting} returns this
 */
proto.model.PlaybookContacting.prototype.setTel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string mobile = 2;
 * @return {string}
 */
proto.model.PlaybookContacting.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PlaybookContacting} returns this
 */
proto.model.PlaybookContacting.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string mail = 3;
 * @return {string}
 */
proto.model.PlaybookContacting.prototype.getMail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PlaybookContacting} returns this
 */
proto.model.PlaybookContacting.prototype.setMail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string fax = 4;
 * @return {string}
 */
proto.model.PlaybookContacting.prototype.getFax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PlaybookContacting} returns this
 */
proto.model.PlaybookContacting.prototype.setFax = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string homepage = 5;
 * @return {string}
 */
proto.model.PlaybookContacting.prototype.getHomepage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PlaybookContacting} returns this
 */
proto.model.PlaybookContacting.prototype.setHomepage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.PlaybookCompany.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.PlaybookCompany.prototype.toObject = function(opt_includeInstance) {
  return proto.model.PlaybookCompany.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.PlaybookCompany} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PlaybookCompany.toObject = function(includeInstance, msg) {
  var f, obj = {
    infos: (f = msg.getInfos()) && proto.model.PlaybookCompanyInfo.toObject(includeInstance, f),
    contactsList: jspb.Message.toObjectList(msg.getContactsList(),
    proto.model.PlaybookContact.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.PlaybookCompany}
 */
proto.model.PlaybookCompany.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.PlaybookCompany;
  return proto.model.PlaybookCompany.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.PlaybookCompany} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.PlaybookCompany}
 */
proto.model.PlaybookCompany.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.model.PlaybookCompanyInfo;
      reader.readMessage(value,proto.model.PlaybookCompanyInfo.deserializeBinaryFromReader);
      msg.setInfos(value);
      break;
    case 2:
      var value = new proto.model.PlaybookContact;
      reader.readMessage(value,proto.model.PlaybookContact.deserializeBinaryFromReader);
      msg.addContacts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.PlaybookCompany.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.PlaybookCompany.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.PlaybookCompany} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PlaybookCompany.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfos();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.model.PlaybookCompanyInfo.serializeBinaryToWriter
    );
  }
  f = message.getContactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.model.PlaybookContact.serializeBinaryToWriter
    );
  }
};


/**
 * optional PlaybookCompanyInfo infos = 1;
 * @return {?proto.model.PlaybookCompanyInfo}
 */
proto.model.PlaybookCompany.prototype.getInfos = function() {
  return /** @type{?proto.model.PlaybookCompanyInfo} */ (
    jspb.Message.getWrapperField(this, proto.model.PlaybookCompanyInfo, 1));
};


/**
 * @param {?proto.model.PlaybookCompanyInfo|undefined} value
 * @return {!proto.model.PlaybookCompany} returns this
*/
proto.model.PlaybookCompany.prototype.setInfos = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.PlaybookCompany} returns this
 */
proto.model.PlaybookCompany.prototype.clearInfos = function() {
  return this.setInfos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.PlaybookCompany.prototype.hasInfos = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated PlaybookContact contacts = 2;
 * @return {!Array<!proto.model.PlaybookContact>}
 */
proto.model.PlaybookCompany.prototype.getContactsList = function() {
  return /** @type{!Array<!proto.model.PlaybookContact>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.PlaybookContact, 2));
};


/**
 * @param {!Array<!proto.model.PlaybookContact>} value
 * @return {!proto.model.PlaybookCompany} returns this
*/
proto.model.PlaybookCompany.prototype.setContactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.model.PlaybookContact=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.PlaybookContact}
 */
proto.model.PlaybookCompany.prototype.addContacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.model.PlaybookContact, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.PlaybookCompany} returns this
 */
proto.model.PlaybookCompany.prototype.clearContactsList = function() {
  return this.setContactsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.PlaybookCompanyInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.model.PlaybookCompanyInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.PlaybookCompanyInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PlaybookCompanyInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: (f = msg.getAddress()) && proto.model.PlaybookAddress.toObject(includeInstance, f),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mccode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.PlaybookCompanyInfo}
 */
proto.model.PlaybookCompanyInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.PlaybookCompanyInfo;
  return proto.model.PlaybookCompanyInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.PlaybookCompanyInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.PlaybookCompanyInfo}
 */
proto.model.PlaybookCompanyInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.model.PlaybookAddress;
      reader.readMessage(value,proto.model.PlaybookAddress.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMccode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.PlaybookCompanyInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.PlaybookCompanyInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.PlaybookCompanyInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PlaybookCompanyInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.model.PlaybookAddress.serializeBinaryToWriter
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMccode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional PlaybookAddress address = 1;
 * @return {?proto.model.PlaybookAddress}
 */
proto.model.PlaybookCompanyInfo.prototype.getAddress = function() {
  return /** @type{?proto.model.PlaybookAddress} */ (
    jspb.Message.getWrapperField(this, proto.model.PlaybookAddress, 1));
};


/**
 * @param {?proto.model.PlaybookAddress|undefined} value
 * @return {!proto.model.PlaybookCompanyInfo} returns this
*/
proto.model.PlaybookCompanyInfo.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.PlaybookCompanyInfo} returns this
 */
proto.model.PlaybookCompanyInfo.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.PlaybookCompanyInfo.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.model.PlaybookCompanyInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PlaybookCompanyInfo} returns this
 */
proto.model.PlaybookCompanyInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.model.PlaybookCompanyInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PlaybookCompanyInfo} returns this
 */
proto.model.PlaybookCompanyInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string mccode = 4;
 * @return {string}
 */
proto.model.PlaybookCompanyInfo.prototype.getMccode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PlaybookCompanyInfo} returns this
 */
proto.model.PlaybookCompanyInfo.prototype.setMccode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.PlaybookContact.prototype.toObject = function(opt_includeInstance) {
  return proto.model.PlaybookContact.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.PlaybookContact} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PlaybookContact.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mobile: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fax: jspb.Message.getFieldWithDefault(msg, 6, ""),
    salutation: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.PlaybookContact}
 */
proto.model.PlaybookContact.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.PlaybookContact;
  return proto.model.PlaybookContact.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.PlaybookContact} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.PlaybookContact}
 */
proto.model.PlaybookContact.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFax(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalutation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.PlaybookContact.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.PlaybookContact.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.PlaybookContact} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PlaybookContact.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFax();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSalutation();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string firstname = 1;
 * @return {string}
 */
proto.model.PlaybookContact.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PlaybookContact} returns this
 */
proto.model.PlaybookContact.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string lastname = 2;
 * @return {string}
 */
proto.model.PlaybookContact.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PlaybookContact} returns this
 */
proto.model.PlaybookContact.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string mobile = 3;
 * @return {string}
 */
proto.model.PlaybookContact.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PlaybookContact} returns this
 */
proto.model.PlaybookContact.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone = 4;
 * @return {string}
 */
proto.model.PlaybookContact.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PlaybookContact} returns this
 */
proto.model.PlaybookContact.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.model.PlaybookContact.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PlaybookContact} returns this
 */
proto.model.PlaybookContact.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string fax = 6;
 * @return {string}
 */
proto.model.PlaybookContact.prototype.getFax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PlaybookContact} returns this
 */
proto.model.PlaybookContact.prototype.setFax = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string salutation = 8;
 * @return {string}
 */
proto.model.PlaybookContact.prototype.getSalutation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PlaybookContact} returns this
 */
proto.model.PlaybookContact.prototype.setSalutation = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


goog.object.extend(exports, proto.model);
