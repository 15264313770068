import { Box, Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import ImageComponent from "../../../../../../components/ImageComponent";
import QBodyText from "../../../../../../components/QBodyText";

// Images
import ImageFlex1 from "../../../../../../assets/neuheiten-2024/bje/sensor-schaltaktor/sensor-1-fach.webp";
import ImageFlex2 from "../../../../../../assets/neuheiten-2024/bje/sensor-schaltaktor/sensor-2-fach.webp";

function BuschFlexTronicWireless() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-flexTronics<sup>®</sup> wireless
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  "Funkbasierte smarte Raumlösung für bis zu 32 Geräte mit App-Steuerung",
                  "Kompatibilität zwischen allen flex-Einsätzen, Bedienelementen und Sensoren",
                  "Flache Bauform der UP-Einsätze von 25mm",
                  "Einzelsteuerung über Bluetooth",
                  "Raumsteuerung über 2,4 GHz Mesh-Netzwerk",
                  "Jederzeit ausbaubar",
                  "Fernbedienung und Parametrierung des jeweiligen Einsatzes per App über Bluetooth",
                  "Zeitschaltuhr mit Astro-Funktion",
                  "Presets/Szenen",
                  "Tag-/Nachtbetrieb",
                  "Verfügbar in den meisten Schalterdesigns",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}>
              <Box
                height="60dvh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <ImageComponent
                  col={6}
                  wrapperWidth="80%"
                  align="center"
                  images={[
                    {
                      src: ImageFlex1,
                      alt: <>free@home® flex | Sensor 1-fach</>,
                    },
                    {
                      src: ImageFlex2,
                      alt: <>free@home® flex | Sensor 2-fach</>,
                    },
                  ]}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default BuschFlexTronicWireless;
