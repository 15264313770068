export default function ACSHeizkurve() {
  const id = "acs-heizkurve";
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 624 227"
      id={id}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ACS-Heizkurve-01 1" clip-path="url(#clip-xuwuwuwid)">
        <g id="Tabelle_Grundgeruest">
          <path
            fill="transparent"
            id="Vector"
            d="M623.998 143.612H47.598C47.598 143.612 47.598 74.8118 47.598 6.11182C46.598 6.11182 42.498 6.11182 42.498 6.11182"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_2"
            d="M42.498 108.612H47.898"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_3"
            d="M42.498 143.212H47.898"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_4"
            d="M42.498 75.312H47.898"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_5"
            d="M42.498 40.7119H47.898"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_6"
            d="M50.1973 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_7"
            d="M75.498 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_8"
            d="M100.797 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_9"
            d="M126.098 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_10"
            d="M151.297 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_11"
            d="M176.598 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_12"
            d="M201.896 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_13"
            d="M227.197 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_14"
            d="M252.498 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_15"
            d="M277.797 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_16"
            d="M302.998 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_17"
            d="M328.297 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_18"
            d="M353.598 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_19"
            d="M378.896 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_20"
            d="M404.197 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_21"
            d="M429.498 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_22"
            d="M454.697 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_23"
            d="M479.998 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_24"
            d="M505.297 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_25"
            d="M530.598 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_26"
            d="M555.896 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_27"
            d="M581.197 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_28"
            d="M606.396 149.212V143.912"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_29"
            opacity="0.2"
            d="M50.1973 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_30"
            opacity="0.2"
            d="M75.498 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_31"
            opacity="0.2"
            d="M100.797 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_32"
            opacity="0.2"
            d="M126.098 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_33"
            opacity="0.2"
            d="M151.297 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_34"
            opacity="0.2"
            d="M176.598 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_35"
            opacity="0.2"
            d="M201.896 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_36"
            opacity="0.2"
            d="M227.197 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_37"
            opacity="0.2"
            d="M252.498 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_38"
            opacity="0.2"
            d="M277.797 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_39"
            opacity="0.2"
            d="M302.998 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_40"
            opacity="0.2"
            d="M328.297 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_41"
            opacity="0.2"
            d="M353.598 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_42"
            opacity="0.2"
            d="M378.896 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_43"
            opacity="0.2"
            d="M404.197 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_44"
            opacity="0.2"
            d="M429.498 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_45"
            opacity="0.2"
            d="M454.697 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_46"
            opacity="0.2"
            d="M479.998 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_47"
            opacity="0.2"
            d="M505.297 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_48"
            opacity="0.2"
            d="M530.598 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_49"
            opacity="0.2"
            d="M555.896 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_50"
            opacity="0.2"
            d="M581.197 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_51"
            opacity="0.2"
            d="M606.396 149.212V6.41211"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_52"
            opacity="0.2"
            d="M42.498 108.612H624.498"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_53"
            opacity="0.2"
            d="M42.498 75.312H624.498"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_54"
            opacity="0.2"
            d="M42.498 40.7119H624.498"
            stroke="black"
            stroke-miterlimit="10"
          />
          <path
            id="Vector_55"
            opacity="0.2"
            d="M42.498 6.11182H624.498"
            stroke="black"
            stroke-miterlimit="10"
          />
          <text
            id="80"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="27.7969" y="9.6">
              80
            </tspan>
          </text>
          <text
            id="60"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="27.7168" y="43.8656">
              60
            </tspan>
          </text>
          <text
            id="40"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="27.8867" y="78.1303">
              40
            </tspan>
          </text>
          <text
            id="20"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="28.3574" y="112.396">
              20
            </tspan>
          </text>
          <text
            id="0"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="33.5176" y="146.662">
              0
            </tspan>
          </text>
          <text
            id="60_2"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="45.0156" y="159.969">
              60
            </tspan>
          </text>
          <text
            id="56"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="69.9336" y="159.969">
              56
            </tspan>
          </text>
          <text
            id="52"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="95.8457" y="159.969">
              52
            </tspan>
          </text>
          <text
            id="48"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="120.445" y="159.969">
              48
            </tspan>
          </text>
          <text
            id="44"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="145.809" y="159.969">
              44
            </tspan>
          </text>
          <text
            id="40_2"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="170.469" y="159.969">
              40
            </tspan>
          </text>
          <text
            id="36"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="196.482" y="159.969">
              36
            </tspan>
          </text>
          <text
            id="32"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="222.395" y="159.969">
              32
            </tspan>
          </text>
          <text
            id="28"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="247.275" y="159.969">
              28
            </tspan>
          </text>
          <text
            id="24"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="272.637" y="159.969">
              24
            </tspan>
          </text>
          <text
            id="20_2"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="297.299" y="159.969">
              20
            </tspan>
          </text>
          <text
            id="16"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="324.449" y="159.969">
              16
            </tspan>
          </text>
          <text
            id="12"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="350.361" y="159.969">
              12
            </tspan>
          </text>
          <text
            id="8"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="376.133" y="159.969">
              8
            </tspan>
          </text>
          <text
            id="4"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="401.494" y="159.969">
              4
            </tspan>
          </text>
          <text
            id="0_2"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="426.156" y="159.969">
              0
            </tspan>
          </text>
          <text
            id="-4"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="450.641" y="159.969">
              -4
            </tspan>
          </text>
          <text
            id="-7"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="476.641" y="159.969">
              -7
            </tspan>
          </text>
          <text
            id="-11"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="499.371" y="159.969">
              -11
            </tspan>
          </text>
          <text
            id="-15"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="522.844" y="159.969">
              -15
            </tspan>
          </text>
          <text
            id="-19"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="547.855" y="159.969">
              -19
            </tspan>
          </text>
          <text
            id="-23"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="572.137" y="159.969">
              -23
            </tspan>
          </text>
          <text
            id="-27"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="597.84" y="159.969">
              -27
            </tspan>
          </text>
          <text
            id="Au&#195;&#159;entemperatur (&#194;&#176;C)"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="14"
            font-weight="bold"
            letter-spacing="0em"
          >
            <tspan x="226.02" y="183.565">
              Au&#xdf;entemperatur (&#xb0;C)
            </tspan>
          </text>
          <text
            id="Vorlaufstemperatur (&#194;&#176;C)"
            transform="matrix(0 -1 1 0 0 156.408)"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="14"
            font-weight="bold"
            letter-spacing="0em"
          >
            <tspan x="0" y="13.54">
              Vorlaufstemperatur (&#xb0;C)
            </tspan>
          </text>
          <text
            id="Min. Vorlauftemperatur"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="8"
            letter-spacing="0em"
          >
            <tspan x="52.3203" y="139.824">
              Min. Vorlauftemperatur
            </tspan>
          </text>
        </g>
        <g id="Werte">
          <path
            fill="transparent"
            id="Vector_56"
            d="M622.799 22.9116H554.699L474.299 73.9116L465.899 75.6116C465.899 75.6116 415.899 111.912 394.999 130.312H46.7988"
            stroke="#1B7AC2"
            stroke-miterlimit="10"
          />
          <path
            fill="transparent"
            id="Vector_57"
            d="M47.3984 115.712H350.998C399.898 86.1116 463.698 53.7116 463.698 53.7116L474.298 52.0116L535.198 22.9116H622.798"
            stroke="#A65503"
            stroke-miterlimit="10"
          />
          <path
            fill="transparent"
            id="Vector_58"
            d="M622.798 22.9116H527.498L477.998 43.4116C477.998 43.4116 467.598 45.5116 464.198 46.7116C431.698 58.5116 371.898 89.9116 340.298 108.112C338.698 109.012 333.598 111.112 333.598 111.112H47.5977"
            stroke="#3B786E"
            stroke-miterlimit="10"
          />
          <path
            fill="transparent"
            id="Vector_59"
            d="M47.8984 107.612L323.698 107.012C372.198 79.6116 464.898 41.5116 464.898 41.5116L478.898 38.7116L516.898 22.9116H622.898"
            stroke="#C2347B"
            stroke-width="2"
            stroke-miterlimit="10"
          />
          <g id="Group">
            <g id="Group_2">
              <path
                id="Vector_60"
                d="M42.498 6.11182H47.498"
                stroke="black"
                stroke-miterlimit="10"
              />
              <path
                id="Vector_61"
                d="M57.5977 6.11182H614.398"
                stroke="black"
                stroke-miterlimit="10"
                stroke-dasharray="10.03 10.03"
              />
              <path
                id="Vector_62"
                d="M619.498 6.11182H624.498"
                stroke="black"
                stroke-miterlimit="10"
              />
            </g>
          </g>
          <path
            id="Vector_63"
            d="M467.197 30.7119C469.959 30.7119 472.197 28.4733 472.197 25.7119C472.197 22.9505 469.959 20.7119 467.197 20.7119C464.436 20.7119 462.197 22.9505 462.197 25.7119C462.197 28.4733 464.436 30.7119 467.197 30.7119Z"
            fill="#0A003F"
          />
          <path
            id="Vector_64"
            d="M471.452 40.4567L467.422 36.4263L463.391 40.4567L467.422 44.4872L471.452 40.4567Z"
            fill="#0A003F"
          />
        </g>
        <g id="Group_3">
          <text
            id="Aktueller Wert ohne Optimierungen (Raumaufschaltung)"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="20.3027" y="206.6">
              Aktueller Wert ohne Optimierungen (Raumaufschaltung)
            </tspan>
          </text>
          <text
            id="Aktueller Wert mit Optimierungen (Raumaufschaltung)"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="10"
            letter-spacing="0em"
          >
            <tspan x="20.3027" y="224.6">
              Aktueller Wert mit Optimierungen (Raumaufschaltung)
            </tspan>
          </text>
        </g>
        <path
          id="Vector_65"
          d="M11 208.246C13.7614 208.246 16 206.007 16 203.246C16 200.484 13.7614 198.246 11 198.246C8.23858 198.246 6 200.484 6 203.246C6 206.007 8.23858 208.246 11 208.246Z"
          fill="#0A003F"
        />
        <path
          id="Vector_66"
          d="M14.966 220.964L10.9355 216.933L6.90508 220.964L10.9355 224.994L14.966 220.964Z"
          fill="#0A003F"
        />
      </g>
      <defs>
        <clipPath id="clip-xuwuwuwid">
          <rect width="624" height="227" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
