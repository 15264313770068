import { Box, Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import ImageComponent from "../../../../../../components/ImageComponent";
import QBodyText from "../../../../../../components/QBodyText";
import QAccordion from "../../../../../../components/QAccordion";

// Images
import VDIImage from "../../../../../../assets/neuheiten-2024/bje/vdi/vdi-2.webp";
import BetterImage from "../../../../../../components/BetterImage";

function VDI() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="VDI Multimediaeinsätze" />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  "Modulares System im Keystone-Format",
                  "Individuelles kombinieren der Zwei Auslässe",
                  "Montage im Tragring gerade oder abgewinkelt",
                  "Schalterintegration über Standard TAE-Abdeckung",
                ]}
                dense
              />
              <QList
                headline="Einsatzmodule"
                items={[
                  "HDMI",
                  "USB",
                  "Blind",
                  "3,5 Klinke",
                  "SAT/F-Stecker",
                  "Cat 6a",
                  "Glasfaser LC/ SC",
                  "Bürsten-Auslass für komplizierte Einbausituationen",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}>
              <Box
                height="70dvh"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <BetterImage
                  align="center"
                  height="auto"
                  width="80%"
                  src={VDIImage}
                  alt="VDI Multimediaeinsätze"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default VDI;
