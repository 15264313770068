import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "transparent",
    },
  },
})((props) => (
  <Tabs
    variant="scrollable"
    scrollButtons="auto"
    {...props}
    TabIndicatorProps={{ children: <span /> }}
  />
));

const StyledTab = withStyles(() => ({
  root: {
    textTransform: "none",
    color: "#000",
    background: "#e0e0e0",
    minWidth: (props) => props.minWidth,
    "&.Mui-selected": {
      fontFamily: "Averta-Bold",
    },
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  qTabs: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function QTabs({ tabs, callback, scrollButtons }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [currentContent, setCurrentContent] = React.useState(<></>);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    callback(newValue);
  };

  const tabHeadRender = tabs.map((t, i) => (
    <StyledTab
      key={`tabHeadRender-${i}`}
      label={t.head}
      {...a11yProps(i)}
      minWidth={t.minWidth}
    />
  ));

  React.useEffect(() => {
    setCurrentContent(tabs[value].content);
  }, [value])

  return (
    <div className={classes.root}>
      <StyledTabs value={value} onChange={handleChange} aria-label="qTabs">
        {tabHeadRender}
      </StyledTabs>
      {currentContent}
    </div>
  );
}

QTabs.defaultProps = {
  tabs: [],
  callback: () => {},
};
