import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import ImageComponent from "../../../../../../../../../components/ImageComponent";
import Heizkurve from "../../../../../../../../../assets/abbknx/Anwendungen/ClimaEco/anzeigen-und-bedienen/heizkurve.webp";
import QBodyText from "../../../../../../../../../components/QBodyText";
import BetterImage from "../../../../../../../../../components/BetterImage";
import ACSHeizkurve from "./acs-heizkurve";
import ACSHeizkurveInfo from "./acs-heizkurve-info";
import QList from "../../../../../../../../../components/QList";

export default function Tipps() {
  return (
    <Slide>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Heizkurve Fußbodenheizung" />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={12}>
              <BetterImage
                height="46dvh"
                src={<ACSHeizkurve />}
                alt="Beispiel: Parametrierbare Raumansicht"
              />
            </Grid>
            <Grid item xs={6}>
              <QList
                headline="Gebäudeschutz"
                headlineColor="#1B7AC2"
                paddingLeft
                items={[
                  "Raumtemperatur grenze um die Gebäudehülle vor Frost z.B. zu schützen",
                ]}
              />
              <QList
                headline="Economy"
                headlineColor="#3B786E"
                paddingLeft
                items={[
                  "Temperaturkurve für die nicht Anwesenheit im Gebäude z.B am Wochenende in einem Büro",
                ]}
              />
            </Grid>
            <Grid xs={6}>
              <QList
                headlineColor="#A65503"
                headline="Standby"
                paddingLeft
                items={["Temperaturkurve für die nicht Anwesenheit im Zimmer"]}
              />
              <QList
                headline="Komfort"
                headlineColor="#C2347B"
                paddingLeft
                items={["Temperaturkurve, wenn eine Person im Raum ist"]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
