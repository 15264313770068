import { Grid } from "@material-ui/core";

import QBodyText from "../../../../../../components/QBodyText";
import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";

// Images
import BackgroundImage from "../../../../../../assets/_backgrounds/neuheiten-2024/7.webp";

function FlexLine() {
  return (
    <Slide
      background={{
        src: BackgroundImage,
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                FlexLine<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Beschleunigen Sie Ihre Installationszeit und sparen Sie bis zu
                50 % der Verdrahtungszeit. Neben den abgangsseitigen
                Steckklemmen (Push-in) überzeugt{" "}
                <>
                  FlexLine<sup>®</sup>
                </>{" "}
                zusätzlich mit einer komplett steckbaren Phasenschiene.
              </QBodyText>
              <QBodyText>
                Alle{" "}
                <>
                  FlexLine<sup>®</sup>
                </>{" "}
                Schutzgeräte können flexibel mit nur einer Phasenschiene
                kombiniert werden, da die Phasenfolge immer gleich ist. Dies
                reduziert die Lagerhaltung erheblich.
              </QBodyText>
              <QBodyText>
                Entwickelt auf der Grundlage unserer schmalsten Geräte kann{" "}
                <>
                  FlexLine<sup>®</sup>
                </>{" "}
                z.B. durch den FI/LS in nur einer Modulbaubreite effektiv zur
                Platzeinsparung beitragen.
              </QBodyText>
              <QBodyText>
                Die abgangsseitigen Klemmen sind nach vorne ausgerichtet, sodass
                die Verdrahtung und eine entsprechende Zugprüfung nicht im
                Zwischenraum zweier Hutschienen durchgeführt werden.
              </QBodyText>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default FlexLine;
