export default function KuehldeckeSVG() {
  const id = "kuehldeckexx"
  return (
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 697 390"
      id={id}
    >
      <defs>
        <style>{`#${id} g, #${id} text, #${id} image, #${id} polyline, #${id} path, #${id} rect { pointer-events: none; }#${id} rect.hotspot, #${id} rect.click-me { pointer-events: all; }#${id} .cls-34x { fill: black; }#${id} .active-stroke { stroke-width: 3px; }#${id} text { font-weight: initial !important; font-family: Averta-Regular !important; } #${id} use { pointer-events: none; } #${id} .cls-1{clip-path:url(#clippath-yyy);}#${id} .cls-1,#${id} .cls-2,#${id} .cls-3,#${id} .cls-4{fill:none;}#${id} .cls-5{fill:url(#Unbenannter_Verlauf_42-2);}#${id} .cls-6{fill:url(#Unbenannter_Verlauf_42-3);}#${id} .cls-7{fill:url(#Unbenannter_Verlauf_42-5);}#${id} .cls-8{fill:url(#Unbenannter_Verlauf_42-4);}#${id} .cls-9{fill:url(#Unbenannter_Verlauf_26-2);}#${id} .cls-10{fill:url(#Unbenannter_Verlauf_64-2);}#${id} .cls-11{fill:#eeece6;}#${id} .cls-12{fill:#fafafa;}#${id} .cls-13{fill:#cdcdcd;}#${id} .cls-14{fill:#a9a4ac;}#${id} .cls-15{fill:#c9c8ca;}#${id} .cls-16{fill:#a93c4c;stroke:#490e19;stroke-miterlimit:10;stroke-width:.2px;}#${id} .cls-17{fill:#ccc;filter:url(#drop-shadow-3);}#${id} .cls-18{fill:url(#Unbenannter_Verlauf_120);}#${id} .cls-19{fill:url(#Unbenannter_Verlauf_129);}#${id} .cls-20{fill:url(#Unbenannter_Verlauf_76);}#${id} .cls-21{fill:url(#Unbenannter_Verlauf_96);}#${id} .cls-22{fill:url(#Unbenannter_Verlauf_64);}#${id} .cls-23{fill:url(#Unbenannter_Verlauf_55);}#${id} .cls-24{fill:url(#Unbenannter_Verlauf_17);}#${id} .cls-25{fill:url(#Unbenannter_Verlauf_26);}#${id} .cls-26{fill:url(#Unbenannter_Verlauf_36);}#${id} .cls-27{fill:url(#Unbenannter_Verlauf_42);}#${id} .cls-2{filter:url(#drop-shadow-1);}#${id} .cls-3{filter:url(#drop-shadow-2);}#${id} .cls-4{clip-path:url(#clippath-xxx);}`}</style>
        <clipPath id="clippath-xxx">
          <rect class="cls-11" y="0" width="697" height="390" />
        </clipPath>
        <filter id="drop-shadow-1" filterUnits="userSpaceOnUse">
          <feOffset dx="5" dy="-14" />
          <feGaussianBlur result="blur" stdDeviation="12" />
          <feFlood flood-color="#000" flood-opacity=".5" />
          <feComposite in2="blur" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
        <linearGradient
          id="Unbenannter_Verlauf_96"
          x1="539.57"
          y1="40.02"
          x2="528.83"
          y2="18.74"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".25" stop-color="#9f9ea2" />
          <stop offset=".27" stop-color="#a1a0a4" />
          <stop offset=".28" stop-color="#aaa9ad" />
          <stop offset=".29" stop-color="#b9b8bb" />
          <stop offset=".3" stop-color="#c5c4c7" />
          <stop offset=".3" stop-color="#c4c3c6" />
          <stop offset=".31" stop-color="#b3b2b5" />
          <stop offset=".32" stop-color="#a7a6aa" />
          <stop offset=".33" stop-color="#a09fa3" />
          <stop offset=".37" stop-color="#9f9ea2" />
          <stop offset=".66" stop-color="#a09fa3" />
          <stop offset=".77" stop-color="#a7a6aa" />
          <stop offset=".84" stop-color="#b3b2b5" />
          <stop offset=".9" stop-color="#c4c3c6" />
          <stop offset=".91" stop-color="#c8c7ca" />
          <stop offset=".93" stop-color="#bdbcbe" />
          <stop offset=".98" stop-color="#a09fa1" />
          <stop offset=".98" stop-color="#9e9d9f" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_120"
          x1="657.96"
          y1="254.03"
          x2="653.67"
          y2="240.54"
          gradientTransform="translate(-19.31 60.6) rotate(-5.26)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".26" stop-color="#9f9ea2" />
          <stop offset=".3" stop-color="#a2a1a5" />
          <stop offset=".34" stop-color="#aeadb1" />
          <stop offset=".36" stop-color="#b9b8bc" />
          <stop offset=".4" stop-color="#afaeb2" />
          <stop offset=".44" stop-color="#9f9ea2" />
          <stop offset=".66" stop-color="#a09fa3" />
          <stop offset=".75" stop-color="#a7a6aa" />
          <stop offset=".8" stop-color="#b3b2b5" />
          <stop offset=".85" stop-color="#c4c3c6" />
          <stop offset=".86" stop-color="#c8c7ca" />
          <stop offset=".94" stop-color="#acabae" />
          <stop offset="1" stop-color="#9e9d9f" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_129"
          x1="47.13"
          y1="274.62"
          x2="38.26"
          y2="256.98"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".28" stop-color="#9f9ea2" />
          <stop offset=".3" stop-color="#a1a0a4" />
          <stop offset=".31" stop-color="#aaa9ad" />
          <stop offset=".32" stop-color="#bab9bc" />
          <stop offset=".33" stop-color="#c5c4c7" />
          <stop offset=".33" stop-color="#c4c3c6" />
          <stop offset=".34" stop-color="#b3b2b5" />
          <stop offset=".35" stop-color="#a7a6aa" />
          <stop offset=".36" stop-color="#a09fa3" />
          <stop offset=".39" stop-color="#9f9ea2" />
          <stop offset=".63" stop-color="#a09fa3" />
          <stop offset=".72" stop-color="#a7a6aa" />
          <stop offset=".78" stop-color="#b3b2b5" />
          <stop offset=".83" stop-color="#c4c3c6" />
          <stop offset=".84" stop-color="#c8c7ca" />
          <stop offset=".88" stop-color="#c4c3c6" />
          <stop offset=".92" stop-color="#b8b7ba" />
          <stop offset=".97" stop-color="#a5a4a6" />
          <stop offset=".98" stop-color="#9e9d9f" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_42"
          x1="21.46"
          y1="47.57"
          x2="193.01"
          y2="180.11"
          gradientTransform="translate(9.42 -7.77) rotate(5.14)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".02" stop-color="#9e9ca2" />
          <stop offset=".55" stop-color="#9c999e" />
          <stop offset=".97" stop-color="#968f94" />
          <stop offset="1" stop-color="#968f94" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_17"
          x1="64.61"
          y1="117.01"
          x2="79.53"
          y2="97.08"
          gradientTransform="translate(9.42 -7.77) rotate(5.14)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".27" stop-color="#9f9ea2" />
          <stop offset=".3" stop-color="#a09fa3" />
          <stop offset=".31" stop-color="#a8a7aa" />
          <stop offset=".31" stop-color="#b4b3b6" />
          <stop offset=".32" stop-color="#c5c4c7" />
          <stop offset=".32" stop-color="#c4c3c6" />
          <stop offset=".33" stop-color="#b3b2b5" />
          <stop offset=".34" stop-color="#a7a6aa" />
          <stop offset=".35" stop-color="#a09fa3" />
          <stop offset=".39" stop-color="#9f9ea2" />
          <stop offset=".65" stop-color="#a09fa3" />
          <stop offset=".74" stop-color="#a7a6aa" />
          <stop offset=".81" stop-color="#b3b2b5" />
          <stop offset=".86" stop-color="#c4c3c6" />
          <stop offset=".87" stop-color="#c8c7ca" />
          <stop offset=".98" stop-color="#9e9d9f" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_36"
          x1="190.87"
          y1="52.74"
          x2="208.09"
          y2="34.76"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".33" stop-color="#9f9ea2" />
          <stop offset=".35" stop-color="#a2a1a5" />
          <stop offset=".36" stop-color="#abaaae" />
          <stop offset=".38" stop-color="#bcbbbe" />
          <stop offset=".38" stop-color="#c5c4c7" />
          <stop offset=".38" stop-color="#c4c3c6" />
          <stop offset=".39" stop-color="#b3b2b5" />
          <stop offset=".4" stop-color="#a7a6aa" />
          <stop offset=".41" stop-color="#a09fa3" />
          <stop offset=".44" stop-color="#9f9ea2" />
          <stop offset=".66" stop-color="#a09fa3" />
          <stop offset=".74" stop-color="#a7a6aa" />
          <stop offset=".8" stop-color="#b3b2b5" />
          <stop offset=".84" stop-color="#c4c3c6" />
          <stop offset=".85" stop-color="#c8c7ca" />
          <stop offset=".89" stop-color="#b6b5b8" />
          <stop offset=".94" stop-color="#a4a3a5" />
          <stop offset=".98" stop-color="#9e9d9f" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_42-2"
          x1="194.67"
          y1="17.64"
          x2="278.86"
          y2="92.68"
          gradientTransform="matrix(1,0,0,1,0,0)"
          xlinkHref="#Unbenannter_Verlauf_42"
        />
        <linearGradient
          id="Unbenannter_Verlauf_55"
          x1="515.8"
          y1="199.44"
          x2="534.63"
          y2="184.69"
          gradientTransform="translate(-33.2 13.68) rotate(-2.32) scale(1.05)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".31" stop-color="#9f9ea2" />
          <stop offset=".35" stop-color="#a2a1a5" />
          <stop offset=".37" stop-color="#acabae" />
          <stop offset=".39" stop-color="#b8b7ba" />
          <stop offset=".39" stop-color="#b3b2b5" />
          <stop offset=".4" stop-color="#a7a6aa" />
          <stop offset=".42" stop-color="#a09fa3" />
          <stop offset=".46" stop-color="#9f9ea2" />
          <stop offset=".71" stop-color="#a09fa3" />
          <stop offset=".8" stop-color="#a7a6aa" />
          <stop offset=".87" stop-color="#b3b2b5" />
          <stop offset=".92" stop-color="#c4c3c6" />
          <stop offset=".93" stop-color="#c8c7ca" />
          <stop offset=".94" stop-color="#bdbcbf" />
          <stop offset=".96" stop-color="#acabad" />
          <stop offset=".98" stop-color="#a1a0a2" />
          <stop offset="1" stop-color="#9e9d9f" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_42-3"
          x1="480.36"
          y1="117.97"
          x2="717.64"
          y2="392.47"
          gradientTransform="translate(-33.2 13.68) rotate(-2.32) scale(1.05)"
          xlinkHref="#Unbenannter_Verlauf_42"
        />
        <linearGradient
          id="Unbenannter_Verlauf_42-4"
          x1="52.27"
          y1="220.75"
          x2="281.71"
          y2="398.03"
          xlinkHref="#Unbenannter_Verlauf_42"
        />
        <linearGradient
          id="Unbenannter_Verlauf_26"
          x1="115.01"
          y1="304.43"
          x2="129.2"
          y2="286.36"
          gradientTransform="translate(9.42 -7.77) rotate(5.14)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".34" stop-color="#9f9ea2" />
          <stop offset=".37" stop-color="#a09fa3" />
          <stop offset=".38" stop-color="#a7a6aa" />
          <stop offset=".39" stop-color="#b3b2b5" />
          <stop offset=".4" stop-color="#c4c3c6" />
          <stop offset=".4" stop-color="#c5c4c7" />
          <stop offset=".4" stop-color="#c4c3c6" />
          <stop offset=".4" stop-color="#b3b2b5" />
          <stop offset=".41" stop-color="#a7a6aa" />
          <stop offset=".42" stop-color="#a09fa3" />
          <stop offset=".45" stop-color="#9f9ea2" />
          <stop offset=".67" stop-color="#a09fa3" />
          <stop offset=".75" stop-color="#a7a6aa" />
          <stop offset=".81" stop-color="#b3b2b6" />
          <stop offset=".86" stop-color="#c5c4c7" />
          <stop offset=".87" stop-color="#c8c7ca" />
          <stop offset=".93" stop-color="#b2b1b4" />
          <stop offset="1" stop-color="#9e9d9f" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_42-5"
          x1="551.33"
          y1="-25.7"
          x2="780.78"
          y2="151.57"
          gradientTransform="translate(29.15 -99.31) rotate(14.05)"
          xlinkHref="#Unbenannter_Verlauf_42"
        />
        <linearGradient
          id="Unbenannter_Verlauf_26-2"
          x1="614.08"
          y1="57.97"
          x2="628.26"
          y2="39.9"
          gradientTransform="translate(29.15 -99.31) rotate(14.05)"
          xlinkHref="#Unbenannter_Verlauf_26"
        />
        <linearGradient
          id="Unbenannter_Verlauf_64"
          x1="14.75"
          y1="116.34"
          x2="373.58"
          y2="116.34"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fbfbfb" />
          <stop offset=".08" stop-color="#f7f7f7" />
          <stop offset=".59" stop-color="#e7e6e7" />
          <stop offset=".98" stop-color="#e2e1e2" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_64-2"
          x1="371.84"
          y1="210.2"
          x2="694.87"
          y2="210.2"
          xlinkHref="#Unbenannter_Verlauf_64"
        />
        <linearGradient
          id="Unbenannter_Verlauf_76"
          x1="315.64"
          y1="207.78"
          x2="339.75"
          y2="21.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#d6d7d7" />
          <stop offset=".09" stop-color="#e5e6e6" />
          <stop offset=".21" stop-color="#f1f1f1" />
          <stop offset=".38" stop-color="#f8f8f8" />
          <stop offset=".85" stop-color="#fafafa" />
        </linearGradient>
        <clipPath id="clippath-yyy">
          <polygon
            class="cls-20"
            points="93.17 174.64 373.58 20.45 540.04 226.07 93.17 174.64"
          />
        </clipPath>
        <filter id="drop-shadow-2" filterUnits="userSpaceOnUse">
          <feOffset dx="1" dy="1" />
          <feGaussianBlur result="blur-2" stdDeviation="2" />
          <feFlood flood-color="#000" flood-opacity=".3" />
          <feComposite in2="blur-2" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter id="drop-shadow-3" filterUnits="userSpaceOnUse">
          <feOffset dx="0" dy="-2" />
          <feGaussianBlur result="blur-3" stdDeviation="2" />
          <feFlood flood-color="#000" flood-opacity=".29" />
          <feComposite in2="blur-3" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <rect class="cls-11" y="0" width="697" height="390" />
      <g class="cls-4">
        <g class="cls-2">
          <rect
            class="cls-13"
            x="99.39"
            y="130.79"
            width="23"
            height="342.04"
            transform="translate(-186 178.36) rotate(-47.25)"
          />
          <rect
            class="cls-13"
            x="206.56"
            y="-77.47"
            width="23"
            height="625.72"
            transform="translate(-102.81 236.9) rotate(-47.46)"
          />
          <rect
            class="cls-13"
            x="346.65"
            y="-95.91"
            width="23"
            height="602.32"
            transform="translate(-41.75 307.61) rotate(-44.17)"
          />
          <rect
            class="cls-13"
            x="523.02"
            y="-82.26"
            width="23"
            height="564.96"
            transform="translate(-.27 399.74) rotate(-40.99)"
          />
          <rect
            class="cls-13"
            x="612.88"
            y="-77.88"
            width="23"
            height="347.46"
            transform="translate(80.29 416.8) rotate(-39.26)"
          />
          <rect
            class="cls-13"
            x="-51.55"
            y="128.45"
            width="710"
            height="23"
            transform="translate(-30.69 144.38) rotate(-25.52)"
          />
          <rect
            class="cls-13"
            x="432.14"
            y="258.49"
            width="332.02"
            height="23"
            transform="translate(-57.91 286.11) rotate(-25.7)"
          />
          <polygon
            class="cls-13"
            points="247.64 404.5 29.68 199.39 373.78 20.23 681.87 389.43 247.64 404.5"
          />
        </g>
        <polygon
          class="cls-15"
          points="434.06 55.11 540.66 .97 566.33 .97 440.75 64.6 434.06 55.11"
        />
        <polygon
          class="cls-21"
          points="454.71 83.57 631.08 .97 566.33 .97 440.75 64.6 454.71 83.57"
        />
        <polygon
          class="cls-15"
          points="591.51 254.86 700.58 205.45 709.63 218.63 598.96 264.86 591.51 254.86"
        />
        <polygon
          class="cls-18"
          points="608.07 278.7 713.96 230.25 709.63 218.63 598.96 264.86 608.07 278.7"
        />
        <polygon
          class="cls-19"
          points="-1.06 302.71 98.8 253.43 76.85 239.67 .57 277.99 -1.06 302.71"
        />
        <polygon
          class="cls-15"
          points="69.41 234.83 .57 268.69 .57 277.99 76.85 239.67 69.41 234.83"
        />
        <polygon
          class="cls-27"
          points="-3.82 4.39 171.17 170.89 162.78 176.86 -5.63 18.24 -3.82 4.39"
        />
        <polygon
          class="cls-24"
          points="-5.63 18.24 -8.63 51.59 141.77 186.18 162.78 176.86 -5.63 18.24"
        />
        <polygon
          class="cls-26"
          points="129.03 -.43 241.5 104.78 266.61 91.39 170.34 -.98 129.03 -.43"
        />
        <polygon
          class="cls-5"
          points="184.57 -.98 170.06 -.98 266.61 91.39 273.03 84.97 184.57 -.98"
        />
        <polygon
          class="cls-23"
          points="331.06 .76 684.57 391.05 711.81 389.94 377.28 -2.57 331.06 .76"
        />
        <polygon
          class="cls-6"
          points="386.67 -2.66 377.29 -2.28 711.81 389.94 717.67 389.71 386.67 -2.66"
        />
        <polygon
          class="cls-8"
          points="-.71 169.05 234.63 390.35 226.24 396.32 -2.51 182.9 -.71 169.05"
        />
        <polygon
          class="cls-25"
          points="-2.51 182.9 -5.52 216.25 205.23 405.63 226.24 396.32 -2.51 182.9"
        />
        <polygon
          class="cls-7"
          points="535.74 -44.08 733.95 211 724.75 215.6 531.81 -30.68 535.74 -44.08"
        />
        <polygon
          class="cls-9"
          points="531.81 -30.68 523.67 1.81 702.54 221.55 724.75 215.6 531.81 -30.68"
        />
        <polygon
          class="cls-12"
          points="14.75 217.76 373.58 20.45 685.27 405.47 271.73 455.82 14.75 217.76"
        />
        <polygon
          class="cls-22"
          points="14.75 217.76 14.75 207.29 371.84 14.92 373.58 20.45 14.75 217.76"
        />
        <polygon
          class="cls-10"
          points="685.27 405.47 694.87 405.47 371.84 14.92 373.58 20.45 685.27 405.47"
        />
        <circle class="cls-14" cx="526.31" cy="227.96" r="2.61" />
        <circle class="cls-14" cx="551.92" cy="257.65" r="2.61" />
        <circle class="cls-14" cx="577.22" cy="287.03" r="2.61" />
        <circle class="cls-14" cx="598.18" cy="312.36" r="2.61" />
        <circle class="cls-14" cx="617.11" cy="335.06" r="2.61" />
        <circle class="cls-14" cx="634.69" cy="356.06" r="2.61" />
        <circle class="cls-14" cx="651.73" cy="375.8" r="2.61" />
        <ellipse
          class="cls-14"
          cx="355.98"
          cy="214.37"
          rx="2.56"
          ry="2.55"
          transform="translate(-15.9 28.94) rotate(-4.55)"
        />
        <ellipse
          class="cls-14"
          cx="388.33"
          cy="246.61"
          rx="2.56"
          ry="2.55"
          transform="translate(-18.35 31.61) rotate(-4.55)"
        />
        <ellipse
          class="cls-14"
          cx="416.34"
          cy="278.05"
          rx="2.56"
          ry="2.55"
          transform="translate(-20.76 33.93) rotate(-4.55)"
        />
        <ellipse
          class="cls-14"
          cx="443.69"
          cy="304.98"
          rx="2.56"
          ry="2.55"
          transform="translate(-22.81 36.19) rotate(-4.55)"
        />
        <ellipse
          class="cls-14"
          cx="470.71"
          cy="331.64"
          rx="2.56"
          ry="2.55"
          transform="translate(-24.84 38.42) rotate(-4.55)"
        />
        <ellipse
          class="cls-14"
          cx="493.17"
          cy="354.69"
          rx="2.56"
          ry="2.55"
          transform="translate(-26.6 40.27) rotate(-4.55)"
        />
        <ellipse
          class="cls-14"
          cx="513.44"
          cy="375.34"
          rx="2.56"
          ry="2.55"
          transform="translate(-28.18 41.95) rotate(-4.55)"
        />
        <ellipse
          class="cls-14"
          cx="120.67"
          cy="167.51"
          rx="2.54"
          ry="2.51"
          transform="translate(-34.01 30.78) rotate(-12.76)"
        />
        <ellipse
          class="cls-14"
          cx="165.43"
          cy="191.13"
          rx="2.54"
          ry="2.51"
          transform="translate(-20.14 19.49) rotate(-6.35)"
        />
        <ellipse
          class="cls-14"
          cx="206.34"
          cy="230.9"
          rx="2.54"
          ry="2.51"
          transform="translate(-24.29 24.26) rotate(-6.35)"
        />
        <ellipse
          class="cls-14"
          cx="243.23"
          cy="265.46"
          rx="2.54"
          ry="2.51"
          transform="translate(-27.89 28.55) rotate(-6.35)"
        />
        <ellipse
          class="cls-14"
          cx="276.27"
          cy="296.2"
          rx="2.54"
          ry="2.51"
          transform="translate(-31.09 32.4) rotate(-6.35)"
        />
        <ellipse
          class="cls-14"
          cx="304.98"
          cy="326.29"
          rx="2.54"
          ry="2.51"
          transform="translate(-34.24 35.76) rotate(-6.35)"
        />
        <ellipse
          class="cls-14"
          cx="332.9"
          cy="351.96"
          rx="2.54"
          ry="2.51"
          transform="translate(-36.91 39.01) rotate(-6.35)"
        />
        <ellipse
          class="cls-14"
          cx="360.5"
          cy="377.37"
          rx="2.54"
          ry="2.51"
          transform="translate(-39.55 42.22) rotate(-6.35)"
        />
        <ellipse
          class="cls-14"
          cx="31.01"
          cy="218.54"
          rx="2.42"
          ry="2.39"
          transform="translate(-30.09 6.44) rotate(-7.99)"
        />
        <ellipse
          class="cls-14"
          cx="64.25"
          cy="246.71"
          rx="2.42"
          ry="2.39"
          transform="translate(-33.68 11.33) rotate(-7.99)"
        />
        <ellipse
          class="cls-14"
          cx="104.29"
          cy="283.45"
          rx="2.42"
          ry="2.39"
          transform="translate(-38.4 17.25) rotate(-7.99)"
        />
        <ellipse
          class="cls-14"
          cx="140.35"
          cy="315.32"
          rx="2.42"
          ry="2.39"
          transform="translate(-42.48 22.58) rotate(-7.99)"
        />
        <ellipse
          class="cls-14"
          cx="172.66"
          cy="343.68"
          rx="2.42"
          ry="2.39"
          transform="translate(-46.11 27.35) rotate(-7.99)"
        />
        <ellipse
          class="cls-14"
          cx="200.82"
          cy="371.53"
          rx="2.42"
          ry="2.39"
          transform="translate(-49.71 31.53) rotate(-7.99)"
        />
        <polygon
          class="cls-20"
          points="93.17 174.64 373.58 20.45 540.04 226.07 93.17 174.64"
        />
        <g class="cls-1">
          <g class="cls-3">
            <path
              class="cls-16"
              d="m349.1,87.04c-3.8,2.01-8.03,3.43-12.25,4.1-6.74,1.08-12.85,3.04-18.17,5.85l-192.94,101.89-4.29-4.37,193.36-101.72c6.2-3.26,13.28-5.53,21.04-6.76,3.22-.51,6.49-1.6,9.44-3.17,8.24-4.35,12.08-11.43,8.41-15.49-3.68-4.06-13.77-3.86-22.08.46-2.9,1.51-5.36,3.39-7.12,5.45-3.19,3.73-7.08,6.8-11.54,9.13-33.13,17.31-199.31,104.14-199.31,104.14l-4.31-4.4s166.53-86.68,199.74-103.97c3.62-1.89,6.8-4.41,9.46-7.49,2.31-2.69,5.51-5.13,9.24-7.07,11.74-6.09,25.97-5.77,31.78.67,5.8,6.42,1.13,16.6-10.47,22.75Z"
            />
            <path
              class="cls-16"
              d="m405.81,119.4l-233.9,129.35-4.19-4.27,234.41-129.16c2.51-1.38,2.79-4.59.62-7l-17.51-19.41c-2.2-2.43-6.2-3.34-8.75-1.97l-237.9,127.8-4.25-4.34,238.41-127.6c5.78-3.1,13.95-1.74,18.23,3.01l17.45,19.39c4.23,4.7,3.06,11.06-2.62,14.2Z"
            />
            <path
              class="cls-16"
              d="m379.29,123.52c-3.72,2.04-7.87,3.5-12,4.22-6.61,1.15-12.6,3.17-17.8,6.02l-188.89,103.44-4.21-4.29,189.3-103.29c6.07-3.31,13.01-5.65,20.61-6.96,3.16-.54,6.36-1.67,9.25-3.26,8.08-4.42,11.82-11.5,8.21-15.49-3.61-3.99-13.51-3.68-21.65.72-2.84,1.54-5.25,3.44-6.97,5.5-3.12,3.74-6.92,6.84-11.3,9.21-32.45,17.59-195.12,105.75-195.12,105.75l-4.23-4.32s163.02-88.03,195.54-105.59c3.55-1.92,6.66-4.46,9.26-7.56,2.26-2.7,5.39-5.16,9.05-7.14,11.5-6.19,25.46-6.04,31.17.29,5.7,6.31,1.14,16.48-10.23,22.73Z"
            />
            <path
              class="cls-16"
              d="m442.32,159.82l-228.98,131.22-4.11-4.19,229.47-131.04c2.46-1.4,2.73-4.59.59-6.96l-17.21-19.08c-2.16-2.39-6.09-3.24-8.58-1.85l-232.86,129.75-4.17-4.26,233.36-129.56c5.66-3.14,13.68-1.89,17.88,2.77l17.15,19.05c4.16,4.62,3.02,10.95-2.54,14.14Z"
            />
            <path
              class="cls-16"
              d="m416.33,164.23c-3.64,2.07-7.71,3.57-11.76,4.34-6.47,1.22-12.35,3.3-17.44,6.2l-184.88,104.95-4.13-4.22,185.28-104.8c5.94-3.36,12.74-5.77,20.2-7.16,3.1-.58,6.23-1.73,9.07-3.34,7.91-4.49,11.57-11.57,8.02-15.49-3.55-3.93-13.26-3.49-21.23.97-2.78,1.56-5.14,3.48-6.83,5.54-3.05,3.75-6.77,6.88-11.06,9.28-31.78,17.86-190.97,107.33-190.97,107.33l-4.15-4.24s159.54-89.34,191.38-107.17c3.48-1.95,6.52-4.51,9.06-7.62,2.21-2.71,5.28-5.2,8.86-7.2,11.26-6.29,24.95-6.3,30.57-.08,5.6,6.2,1.15,16.36-9.98,22.71Z"
            />
            <path
              class="cls-16"
              d="m478.21,199.55l-224.19,133.01-4.03-4.12,224.66-132.84c2.41-1.43,2.67-4.59.57-6.92l-16.92-18.75c-2.12-2.35-5.98-3.15-8.42-1.74l-227.97,131.61-4.09-4.18,228.45-131.42c5.55-3.19,13.41-2.04,17.54,2.54l16.86,18.73c4.09,4.54,2.99,10.85-2.46,14.08Z"
            />
            <path
              class="cls-16"
              d="m452.73,204.24c-3.57,2.1-7.55,3.64-11.53,4.45-6.35,1.29-12.1,3.43-17.09,6.36l-180.99,106.39-4.05-4.14,181.37-106.25c5.82-3.41,12.48-5.88,19.79-7.35,3.03-.61,6.11-1.8,8.89-3.43,7.75-4.55,11.32-11.63,7.84-15.49s-13.01-3.31-20.82,1.22c-2.73,1.58-5.04,3.52-6.68,5.59-2.99,3.77-6.63,6.91-10.82,9.36-31.12,18.12-186.94,108.83-186.94,108.83l-4.08-4.16s156.15-90.59,187.34-108.68c3.41-1.98,6.39-4.56,8.87-7.68,2.16-2.72,5.16-5.22,8.67-7.26,11.03-6.38,24.45-6.56,29.98-.44,5.51,6.1,1.16,16.25-9.74,22.68Z"
            />
            <path
              class="cls-16"
              d="m513.5,238.61l-219.53,134.72-3.96-4.04,219.99-134.55c2.36-1.44,2.61-4.6.54-6.89l-16.63-18.44c-2.08-2.31-5.87-3.06-8.26-1.63l-223.2,133.38-4.02-4.1,223.67-133.2c5.44-3.24,13.15-2.19,17.21,2.32l16.58,18.42c4.02,4.46,2.95,10.75-2.39,14.02Z"
            />
            <path
              class="cls-16"
              d="m488.52,243.57c-3.49,2.13-7.4,3.71-11.3,4.56-6.22,1.35-11.86,3.55-16.75,6.52l-177.2,107.77-3.98-4.06,177.57-107.63c5.7-3.46,12.23-5.99,19.4-7.54,2.98-.64,5.99-1.86,8.71-3.51,7.59-4.62,11.08-11.69,7.66-15.48-3.43-3.79-12.77-3.14-20.42,1.46-2.67,1.6-4.93,3.55-6.54,5.63-2.93,3.78-6.49,6.95-10.6,9.42-30.48,18.37-183.02,110.26-183.02,110.26l-4-4.08s152.86-91.78,183.41-110.12c3.33-2,6.26-4.6,8.68-7.73,2.11-2.72,5.05-5.25,8.49-7.31,10.81-6.47,23.97-6.8,29.4-.79,5.42,6,1.17,16.13-9.51,22.65Z"
            />
          </g>
          <polygon
            class="cls-17"
            points="93.17 174.64 540.04 226.07 535.78 221.35 100.02 171.69 93.17 174.64"
          />
        </g>
        <circle class="cls-14" cx="367.81" cy="37.05" r="2.61" />
        <circle class="cls-14" cx="399.1" cy="72.51" r="2.61" />
        <circle class="cls-14" cx="436.37" cy="118.24" r="2.61" />
        <circle class="cls-14" cx="470.13" cy="158.12" r="2.61" />
        <circle class="cls-14" cx="500.39" cy="193.62" r="2.61" />
        <ellipse
          class="cls-14"
          cx="234.63"
          cy="106.16"
          rx="2.56"
          ry="2.55"
          transform="translate(-11.63 30.82) rotate(-7.33)"
        />
      </g>
    </svg>
  );
}
