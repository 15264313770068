import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import QIncrementGroup from "../../QIncrementGroup";
import QActionPaper from "../../QActionPaper";
import QTextFieldAction from "../../QTextFieldAction";
import {
  getModerneElektroinstallationData,
  setModerneElektroinstallationNotes,
  setAsset as setDispatchAsset,
} from "../../../redux/modules/chapterModerneElektroinstallation";
import QActionCheckbox from "../../QActionCheckbox";

export default function ModerneElektroinstallationPage({ context }) {
  const dispatch = useDispatch();

  const {
    notes: notesData,
    assetLichtschalter: assetLichtschalterData,
    assetInnomag: assetInnomagData,
    assetBarrierefreieElektrotechnik: assetBarrierefreieElektrotechnikData,
    assetFlex: assetFlexData,
  } = useSelector(getModerneElektroinstallationData);

  const setSetupField = (valueSet) => {
    switch (valueSet.id) {
      case "notes":
        {
          dispatch(setModerneElektroinstallationNotes(valueSet.value));
        }
        break;
    }
  };

  const setAsset = (id, value) => {
    dispatch(setDispatchAsset(id, value));
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <QActionPaper title="Werbung" context={context}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetLichtschalterData.value}
                valueID="assetLichtschalter"
                label={assetLichtschalterData.description}
                mode={1}
                setAsset={setAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetInnomagData.value}
                valueID="assetInnomag"
                label={assetInnomagData.description}
                mode={1}
                setAsset={setAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetBarrierefreieElektrotechnikData.value}
                valueID="assetBarrierefreieElektrotechnik"
                label={assetBarrierefreieElektrotechnikData.description}
                mode={1}
                setAsset={setAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetFlexData.value}
                valueID="assetFlex"
                label={assetFlexData.description}
                mode={1}
                setAsset={setAsset}
              />
            </Grid>
          </Grid>
        </QActionPaper>
      </Grid>
      <Grid item>
        <QActionPaper title="Notizen" context={context}>
          <QTextFieldAction
            value={notesData.value}
            valueID="notes"
            setSetupField={setSetupField}
          />
        </QActionPaper>
      </Grid>
    </Grid>
  );
}
