// Images Schulung
import ImageSchulung1 from "../../assets/welcome-ip/Schulung/1.webp";
import ImageSchulung2 from "../../assets/welcome-ip/Schulung/2.webp";
import ImageSchulung3 from "../../assets/welcome-ip/Schulung/3.webp";
import ImageSchulung4 from "../../assets/welcome-ip/Schulung/4.webp";
import ImageSchulung5 from "../../assets/welcome-ip/Schulung/5.webp";
import ImageSchulung6 from "../../assets/welcome-ip/Schulung/8.webp";
import ImageSchulung7 from "../../assets/welcome-ip/Schulung/9.webp";

export const buschWelcomeIPUnterstuetzungSchulung = {
  title: "Schulung",
  items: [
    {
      title: "Busch-Jaeger Seminare",
      img: ImageSchulung2,
      link: {
        href: "https://www.busch-jaeger.de/busch-jaeger/termine/busch-jaeger-seminare/busch-welcome-ip",
      },
    },
    {
      title: "Flyer",
      img: ImageSchulung1,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/9BADA/product/5340",
      },
    },
    {
      title: "Digital ausprobieren",
      img: ImageSchulung3,
      link: {
        href: "www.busch-jaeger.de/produkterlebnis",
      },
    },
    {
      title: (
        <>
          Busch-Welcome<sup>®</sup>
          <br />
          Integration myRENZbox
        </>
      ),
      img: ImageSchulung6,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/49AD6/product/6274",
      },
    },
    {
      title: (
        <>
          Busch-Welcome<sup>®</sup>
          <br />
          IP Mini-Außenstation
        </>
      ),
      img: ImageSchulung7,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/426FD/product/6455",
      },
    },
  ],
};

export const buschWelcomeIPUnterstuetzungMarketing = {
  title: "Marketing",
  items: [
    {
      title: "Starterset",
      img: ImageSchulung4,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/108B6/product/5355",
      },
    },
    {
      title: "Roll Up",
      img: ImageSchulung5,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/786A5/product/5376",
      },
    },
  ],
};
