// Components
import { Grid } from "@material-ui/core";
import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QBodyText from "../../../../../../components/QBodyText";
import QList from "../../../../../../components/QList";
import CenteredImage from "../../../../../../components/CenteredImage";

// Images
import BackgroundImage from "../../../../../../assets/welcome/2021/0206/10282_RZ_13_06_Busch-Jaeger_03643.webp"
import EinfamilienhausAudioImage from "../../../../../../assets/welcome/2021/0206/AnlagemitAudio.webp"

export default function EinfamilienhausAudio() {
  return (
    <Slide
      background={{
        src: BackgroundImage,
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          target: "_blank",
          link: "https://www.busch-jaeger.de/online-katalog/detail/2TMA200010A0001",
        },
        {
          type: "pdf",
          img: "preisbeispiel",
          path: "../../../../../../assets/pdf/preisbeispiele/Busch-Welcome/Preisbeispiel Busch-Welcome - Einfamilienhaus Audio.pdf",
        },
        {
          type: "galery",
          img: "info",
          images: [
            {
              src: EinfamilienhausAudioImage,
              alt: "Anwendung Einfamilienhaus Audio",
            },
          ],
        },
      ]}
    >
      <CenteredImage
        src="../../../../../../assets/welcome/2021/0206/A21051P1-A-03wcAussenstationAudio1-fachAluminium.webp"
        alt="Außenstation Audio"
        height="50vh"
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Welcome<sup>®</sup>
              </>
            }
            subTitle="Anwendung Einfamilienhaus Audio"

          />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={5}>
              <QBodyText>
                Der einfache Einstieg in das Thema Sprechanlagen
              </QBodyText>
              <QList
                title="Vorteile"
                headline="Vorteile"
                items={[
                  "Einfache 2-Draht-Installation",
                  "Vor Vandalen geschützte Außenstation",
                  "Intuitive Handhabung",
                  "Zustandsanzeige im Audiomodul",
                ]}
                dense
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
