const SET_NOTES = "playbook/cwa/SET_NOTES";
const SET_ASSET = "playbook/cwa/SET_ASSET";

export const initialState = {
  assetMustertafelSchalter: {
    description: "Mustertafel Schalter",
    value: 0,
    type: "info",
    orderID: "2CKA000002A1240",
  },
  assetMustertafelElektronik: {
    description: "Mustertafel Elektronik",
    value: 0,
    type: "info",
    orderID: "2CKA000002A1307",
  },
  assetMusterkofferSchalter: {
    description: "Musterkoffer Schalter",
    value: 0,
    type: "info",
    orderID: "2CKA000002A1175",
  },
  assetKatalogLichtschalter: {
    description: "Prospekt Lichtschalter",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1674",
  },
  assetKatalogCwa: {
    description: "Katalog 2023/2024",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1858",
  },
  assetFlyerNaeherungsschalter: {
    description: "Flyer Näherungsschalter",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1775",
  },
  assetFlyerRauchUCOMelder: {
    description: "Prospekt Neubau",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1609",
  },
  assetRatgeberRenovieren: {
    description: "Prospekt Ratgeber Renovieren",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1623",
  },
  assetBroschuereWohnungsbau: {
    description: "Broschüre Wohnungsbau",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1630",
  },
  notes: {
    description: "Notizen für das Kapitel",
    value: "",
    type: "info",
    orderID: null,
  },
};

const cwa = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTES: {
      return {
        ...state,
        notes: {
          ...state.notes,
          value: action.notes,
        },
      };
    }
    case SET_ASSET: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          value: action.value,
        },
      };
    }
    default:
      return state;
  }
};

//actions
export const setAsset = (id, value) => ({
  type: SET_ASSET,
  id,
  value,
});

export const setCwaNotes = (notes) => ({
  type: SET_NOTES,
  notes,
});

//selector
export const getCwaNotes = (state) => state.cwa.notes;
export const getAssetMustertafelSchalter = (state) =>
  state.cwa.assetMustertafelSchalter;
export const getAssetMustertafelElektronik = (state) =>
  state.cwa.assetMustertafelElektronik;
export const getAssetKatalogLichtschalter = (state) =>
  state.cwa.assetKatalogLichtschalter;
export const getassetKatalogCwa = (state) => state.cwa.assetKatalogCwa;
export const getAssetFlyerNaeherungsschalter = (state) =>
  state.cwa.assetFlyerNaeherungsschalter;
export const getAssetFlyerRauchUCOMelder = (state) =>
  state.cwa.assetFlyerRauchUCOMelder;
export const getAssetRatgeberRenovieren = (state) =>
  state.cwa.assetRatgeberRenovieren;
export const getAssetBroschuereWohnungsbau = (state) =>
  state.cwa.assetBroschuereWohnungsbau;
export const getCwaData = (state) => state.cwa;

export default cwa;
