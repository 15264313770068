import Overview from "./Overview";

// Globale Slides
import ABBAbility from "./Devices/ABBAbility";
import ASKI from "./Devices/ASKI";
import DigitalerHilfsschalter from "./Devices/DigitalerHilfsschalter";
import F3CAR from "./Devices/F3CAR";
import FILS from "./Devices/FILS";
import FlexLine from "./Devices/FlexLine";
import Hochleistungssicherungsautomat from "./Devices/Hochleistungssicherungsautomat";
import KNCBinaereingaenge from "./Devices/KNCBinaereingaenge";
import KNXJalousieAktoren from "./Devices/KNXJalousieAktoren";
import MultiMessgeraete from "./Devices/MultiMessgeraete";
import OPCUAGateway from "./Devices/OPCUAGateway";
import OVR from "./Devices/OVR";
import S3CMOD from "./Devices/S3CMOD";
import SCU200 from "./Devices/SCU200";
import SEMS from "./Devices/SEMS";
import Sicherungsautomat from "./Devices/Sicherungsautomat";
import SlimRangeAdapterSCC from "./Devices/SlimRangeAdapterSCC";
import VorverdrahteteStromkreisverteiler from "./Devices/VorverdrahteteStromkreisverteiler";

export default function ABBOverview() {
  return [
    <Overview key="abb-overview" />,
    <FlexLine key="abb-flexline" />,
    <FILS key="abb-fils" />,
    <Sicherungsautomat key="abb-sicherungsautomat" />,
    <Hochleistungssicherungsautomat key="abb-hochleistungssicherungsautomat" />,
    <MultiMessgeraete key="abb-multimessgeraete" />,
    <DigitalerHilfsschalter key="abb-digitalerhilfsschalter" />,
    <S3CMOD key="abb-s3cmod" />,
    <OVR key="abb-ovr" />,
    <SCU200 key="abb-scu200" />,
    <SlimRangeAdapterSCC key="abb-slimrangeadapterscc" />,
    <F3CAR key="abb-f3car" />,
    <KNCBinaereingaenge key="abb-kncbinaereingaenge" />,
    <OPCUAGateway key="abb-opcuagateway" />,
    <KNXJalousieAktoren key="abb-knxjalousieaktoren" />,
    <VorverdrahteteStromkreisverteiler key="abb-vorverdrahtetestromkreisverteiler" />,
    <ABBAbility key="abb-abbability" />,
    <ASKI key="abb-aski" />,
    <SEMS key="abb-sems" />,
  ];
}
