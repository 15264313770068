import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../components/QHeader";
import ImageComponent from "../../../../../../../../components/ImageComponent";
import Heizkurve from "../../../../../../../../assets/abbknx/Anwendungen/ClimaEco/anzeigen-und-bedienen/heizkurve.webp";
import QBodyText from "../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../components/QList";
import Tipps from "./HeizkurveFussbodenheizung/tipps";

export default function HeizkurveFussbodenheizung() {
  return (
    <Slide
      actions={[
        {
          type: "dialogWithButton",
          label: "Tipps",
          children: <Tipps />,
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Heizkurve Fußbodenheizung" />
        </Grid>
        <Grid item xs={12}>
          <ImageComponent
            alt="Heizkurve einer Fußbodenheizung"
            src={Heizkurve}
            wrapperWidth="80%"
            wrapperHeight="80dvh"
          />
        </Grid>
      </Grid>
    </Slide>
  );
}
