import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";

import AnalogAktor from "../../../../../../../../../assets/abbknx/Anwendungen/ClimaEco/rtr-stellenantrieb/Analogaktor.webp";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

export default function Analogaktor() {
  return (
    <Slide background={{ alignment: "left" }}>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="AA/A2.1.2 Analogaktor, 2fach" />
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              Sollwertvorgabe
            </QBodyText>
            <QBodyText>Regelung erfolgt durch den Volumenstromregler</QBodyText>
            <QBodyText>
              Spannung für Analog-Ausgang wird aus der Busleitung entnommen
            </QBodyText>

            <QBodyText fontWeight="bold" marginBottom="0px">
              Beispiel:
            </QBodyText>
            <QBodyText>
              Vmin = 0 Volt → 10 m3/h <br />
              Vnenn = 10 Volt → 3000 m3/h
              <br />
              Vmax= → 5040 m3/h
            </QBodyText>

            <QBodyText>
              Analog-Eingang für Rückmeldung des gemessenen Volumenstroms wird
              in gleicher Weise wie der Analog-Ausgang angeschlossen
            </QBodyText>
          </Grid>
          <Grid item xs={6}>
            <ImageComponent
              src={AnalogAktor}
              alt="AA/A2.1.2 Analogaktor, 2fach"
              wrapperWidth="100%"
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
