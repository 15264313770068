import {Button, ButtonGroup, makeStyles} from '@material-ui/core'
import {useState} from 'preact/hooks'
import {
  Document,
  Page,
} from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import useWindowSize from '../../hooks/windowSize'

const useStyles = makeStyles(() => ({
  document: {
    margin: '2rem',
    boxShadow: '0px 0px 10px 2px rgb(1 1 1 / 17%)',
  },
  buttongroup: {
    position: 'absolute',
    bottom: '10px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}))

export default function PDFViewer({path}) {
  const [numPages, setNumPages] = useState(null)
  const [scale, setScale] = useState(1)
  const [windowSize] = useWindowSize()
  const classes = useStyles()

  function onDocumentLoadSuccess({numPages: nextNumPages}) {
    setNumPages(nextNumPages)
  }

  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
  }

  const increaseScale = e => {
    setScale(v => v + 0.1)
  }
  const decreaseScale = e => {
    setScale(v => v - 0.1)
  }

  return (
    <>
        <Document
          file={path}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
          className={classes.document}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              height={windowSize.height * 0.9}
              width={windowSize.width * 0.9}
              scale={scale}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderTextLayer={false}
            />
          ))}
        </Document>
      <ButtonGroup
        className={classes.buttongroup}
        variant="contained"
        color="primary"
        aria-label="contained primary button group"
      >
        <Button onClick={decreaseScale}>-</Button>
        <Button>{(scale * 100).toFixed(0)} %</Button>
        <Button onClick={increaseScale}>+</Button>
      </ButtonGroup>
    </>
  )
}

PDFViewer.defaultProps = {
  path: '',
  height: 400,
}
