import Image1 from "./files/Image1.webp";
import Relais1 from "./files/Relais1.webp";
import Relais2 from "./files/Relais2.webp";
import Image2 from "./files/Image2.webp";
import PumpeDetailansicht from "./files/pumpe-detailansicht.webp"

export default function DetailansichtPumpe() {
  const id = "detailansicht-pumpe";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: "100%", height: "100%"}} viewBox="0 0 875 402" fill="none">
<g id="Grafik-Detailansicht-Pumpe 2" clip-path="url(#clip0_223_2)">
<rect id="Pumpe 1" x="699" y="3" width="159" height="245" fill="url(#pataodiektern0)"/>
<path id="Vector" d="M1.14 394.45L1 7.27C1 3.9 3.92 1.17 7.53 1.17L679.61 1C683.22 1 686.14 3.73 686.14 7.09L686.28 394.28C686.28 397.65 683.36 400.38 679.75 400.38L7.67 400.55C4.06 400.55 1.14 397.82 1.14 394.46V394.45Z" stroke="black" stroke-width="2" stroke-miterlimit="1"/>
<path id="Vector_2" d="M838.668 163.732L838.658 135.722C838.657 132.408 835.97 129.723 832.656 129.724L785.576 129.74C782.263 129.742 779.577 132.429 779.578 135.743L779.588 163.753C779.589 167.066 782.277 169.752 785.59 169.75L832.67 169.734C835.984 169.733 838.669 167.046 838.668 163.732Z" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_3" d="M779.281 149.19H685.631" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
<rect id="Rectangle" x="182.08" y="31.0701" width="81.5" height="109" fill="url(#pataodiektern1)"/>
<text id="Relais 1" fill="black" xmlSpace="preserve" style="white-space: pre" font-family="Averta-Semibold" font-size="14" letter-spacing="0em"><tspan x="198.57" y="22.4817">Relais 1</tspan></text>
<path id="Vector_4" d="M247.04 136.52V198H171.77" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<text id="Betrieb" fill="black" xmlSpace="preserve" style="white-space: pre" font-family="Averta-Semibold" font-size="12" letter-spacing="0em"><tspan x="183.289" y="190.474">Betrieb</tspan></text>
<path id="Vector_5" d="M224.04 136.52V170H171.77" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_6" d="M200.23 177.45C204.649 177.45 208.23 173.868 208.23 169.45C208.23 165.032 204.649 161.45 200.23 161.45C195.812 161.45 192.23 165.032 192.23 169.45C192.23 173.868 195.812 177.45 200.23 177.45Z" fill="white" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_7" d="M194.57 163.79L205.89 175.1" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_8" d="M205.89 163.79L194.57 175.1" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<rect id="Rectangle_2" x="280.08" y="30.0701" width="81.5" height="110.5" fill="url(#pataodiektern2)"/>
<text id="Relais 2" fill="black" xmlSpace="preserve" style="white-space: pre" font-family="Averta-Semibold" font-size="14" letter-spacing="0em"><tspan x="296.721" y="22.4817">Relais 2</tspan></text>
<path id="Vector_9" d="M345.04 136.52V258H271.77" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_10" d="M321.04 136.52V230H271.77" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<text id="Alarm" fill="black" xmlSpace="preserve" style="white-space: pre" font-family="Averta-Semibold" font-size="12" letter-spacing="0em"><tspan x="284.289" y="249.474">Alarm</tspan></text>
<rect id="Rectangle_3" x="55.5801" y="30.5701" width="106" height="109" fill="url(#pataodiektern3)"/>
<path id="Vector_11" d="M145.039 136.52V298H160.179" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_12" d="M121.04 136.52V270H55.7695V288.94" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_13" d="M52.0098 288.76C52.0098 290.84 53.6898 292.52 55.7698 292.52C57.8498 292.52 59.5298 290.84 59.5298 288.76C59.5298 286.68 57.8498 285 55.7698 285C53.6898 285 52.0098 286.68 52.0098 288.76Z" fill="#696969"/>
<path id="Vector_14" d="M160.879 288.33L144.979 316.79V362.84H55.3893V310.93L41.7793 288.86" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_15" d="M45.0809 287.04C43.9909 285.27 41.6709 284.72 39.9109 285.81C38.1409 286.9 37.5909 289.22 38.6809 290.98C39.7709 292.75 42.0909 293.3 43.8509 292.21C45.6209 291.12 46.1709 288.8 45.0809 287.04Z" fill="#696969"/>
<path id="Vector_16" d="M211.95 298.38H170.09V320.15H211.95V298.38Z" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_17" d="M150 307.58H169.25" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_18" d="M51.8593 306.21H30.2793" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_19" d="M30.2793 293.96V318.31" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_20" d="M220.28 253.84H24.6504C21.3367 253.84 18.6504 256.526 18.6504 259.84V372.58C18.6504 375.894 21.3367 378.58 24.6504 378.58H220.28C223.594 378.58 226.28 375.894 226.28 372.58V259.84C226.28 256.526 223.594 253.84 220.28 253.84Z" stroke="#696969" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<text id="EIN / AUS" fill="black" xmlSpace="preserve" style="white-space: pre" font-family="Averta-Semibold" font-size="12" letter-spacing="0em"><tspan x="62.2305" y="304.724">EIN / AUS</tspan></text>
<g id="Group">
<text id="Ãber einen Timer" fill="black" xmlSpace="preserve" style="white-space: pre" font-family="Averta-Semibold" font-size="12" letter-spacing="0em"><tspan x="239.57" y="344.924">Über einen Timer </tspan></text>
<text id="ein-/ausschlaten" fill="black" xmlSpace="preserve" style="white-space: pre" font-family="Averta-Semibold" font-size="12" letter-spacing="0em"><tspan x="239.57" y="359.324">ein-/ausschlaten</tspan></text>
</g>
<rect id="Rectangle_4" x="382.08" y="133.07" width="104.5" height="68" fill="url(#pataodiektern4)"/>
<path id="Vector_21" d="M432.23 193.21V243.17H544.44V238" stroke="#696969" stroke-width="2" stroke-miterlimit="1"/>
<path id="Vector_22" d="M399.859 194.33V272.38H488.279V215.54H464.599V194.33" stroke="#696969" stroke-width="2" stroke-miterlimit="1"/>
<path id="Vector_23" d="M544.44 267.21V272.38H524.65V213.07H670.32" stroke="#696969" stroke-width="2" stroke-miterlimit="1"/>
<text id="Netzanschluss" fill="black" xmlSpace="preserve" style="white-space: pre" font-family="Averta-Semibold" font-size="12" letter-spacing="0em"><tspan x="547.84" y="186.874">Netzanschluss</tspan></text>
<path id="Vector_24" d="M497.391 230.89L510.791 254.33" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_25" d="M543.109 229.17L565.179 242.33H588.899V237.52" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_26" d="M543.109 259.17L565.179 272.33H588.899V267.52" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_27" d="M553.23 234.56V292.84" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_28" d="M557.23 237.56V292.84" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_29" d="M547.23 292.56H563.23" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_30" d="M588.109 229.17L610.179 242.33H670.319" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_31" d="M652.17 232.89H618.51V251.31H652.17V232.89Z" fill="white" stroke="#696969" stroke-width="2" stroke-miterlimit="1"/>
<text id="&lt;I" fill="black" xmlSpace="preserve" style="white-space: pre" font-family="Averta-Semibold" font-size="12" letter-spacing="0em"><tspan x="629.1" y="244.724">&lt;I</tspan></text>
<path id="Vector_32" d="M588.109 252.17L610.179 265.33H670.319" stroke="#696969" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_33" d="M652.17 255.89H618.51V274.31H652.17V255.89Z" fill="white" stroke="#696969" stroke-width="2" stroke-miterlimit="1"/>
<text id="&lt;I_2" fill="black" xmlSpace="preserve" style="white-space: pre" font-family="Averta-Semibold" font-size="12" letter-spacing="0em"><tspan x="629.1" y="267.724">&lt;I</tspan></text>
<path id="Vector_34" d="M569.979 199.49H520.039C516.725 199.49 514.039 202.176 514.039 205.49V298.45C514.039 301.764 516.725 304.45 520.039 304.45H569.979C573.293 304.45 575.979 301.764 575.979 298.45V205.49C575.979 202.176 573.293 199.49 569.979 199.49Z" stroke="#696969" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path id="Vector_35" d="M654.729 199.49H588.039C584.725 199.49 582.039 202.176 582.039 205.49V349.98C582.039 353.294 584.725 355.98 588.039 355.98H654.729C658.043 355.98 660.729 353.294 660.729 349.98V205.49C660.729 202.176 658.043 199.49 654.729 199.49Z" stroke="#696969" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path id="Vector_36" d="M600.699 264.21V298.37" stroke="#696969" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path id="Vector_37" d="M604.779 300.38H631.309" stroke="#696969" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path id="Vector_38" d="M633.35 296.1V276.86" stroke="#696969" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<g id="pictogram">
<path id="Vector_39" d="M619.34 292.62H594.26L593.66 293.22V308.15L594.26 308.75H606.21V310.58C603.53 310.88 601.43 313.16 601.43 315.92L602.03 316.52H611.59L612.19 315.92C612.19 313.16 610.1 310.88 607.41 310.58V308.75H619.36L619.96 308.15V293.22L619.36 292.62H619.34ZM610.94 315.32H602.66C602.95 313.3 604.7 311.74 606.8 311.74C608.9 311.74 610.65 313.3 610.94 315.32Z" fill="black"/>
<path id="Vector_40" d="M618.74 293.82H594.85V307.56H618.74V293.82Z" fill="white"/>
</g>
<path id="Vector_41" d="M619.051 327.34H591.961V344.87H619.051V327.34Z" stroke="black" stroke-miterlimit="10"/>
<path id="Vector_42" d="M596.18 340.83C596.18 339.07 597.61 337.64 599.37 337.64C601.13 337.64 602.56 339.07 602.56 340.83H606.54C606.54 339.07 607.97 337.64 609.73 337.64C611.49 337.64 612.92 339.07 612.92 340.83H614.83" stroke="black" stroke-miterlimit="10"/>
<path id="Vector_43" d="M600.67 332.47C600.67 332.47 601.65 331.23 602.89 331.25C604.13 331.27 604.78 331.81 605.45 332.58C606.12 333.35 606.67 333.98 607.78 334.03C609.06 334.09 610.1 333.23 610.34 332.81" stroke="black" stroke-miterlimit="10"/>
<path id="Vector_44" d="M651.879 327.41H624.789V344.94H651.879V327.41Z" stroke="black" stroke-miterlimit="10"/>
<path id="Vector_45" d="M627.971 334.03H648.691" stroke="black" stroke-miterlimit="10"/>
<path id="Vector_46" d="M627.971 337.21H633.551" stroke="black" stroke-miterlimit="10"/>
<path id="Vector_47" d="M635.289 337.21H642.239" stroke="black" stroke-miterlimit="10"/>
<path id="Vector_48" d="M643.109 337.21H648.689" stroke="black" stroke-miterlimit="10"/>
</g>
<defs>
<pattern id="pataodiektern0" patternContentUnits="objectBoundingBox" width="1" height="1">
<use xlinkHref="#image0_223_2" transform="matrix(0.0021097 0 0 0.00136916 0 -0.00384913)"/>
</pattern>
<pattern id="pataodiektern1" patternContentUnits="objectBoundingBox" width="1" height="1">
<use xlinkHref="#image1_223_2" transform="scale(0.00613497 0.00458716)"/>
</pattern>
<pattern id="pataodiektern2" patternContentUnits="objectBoundingBox" width="1" height="1">
<use xlinkHref="#image2_223_2" transform="scale(0.00613497 0.00452489)"/>
</pattern>
<pattern id="pataodiektern3" patternContentUnits="objectBoundingBox" width="1" height="1">
<use xlinkHref="#image3_223_2" transform="scale(0.00473934 0.00460829)"/>
</pattern>
<pattern id="pataodiektern4" patternContentUnits="objectBoundingBox" width="1" height="1">
<use xlinkHref="#image4_223_2" transform="scale(0.00478469 0.00735294)"/>
</pattern>
<clipPath id="clip0_223_2">
<rect width="874.18" height="401.55" fill="white"/>
</clipPath>
<image id="image0_223_2" data-name="Pumpe.png" width="474" height="736" xlinkHref={PumpeDetailansicht}/>
<image id="image1_223_2" width="163" height="218" xlinkHref={Relais1}/>
<image id="image2_223_2" width="163" height="221" xlinkHref={Relais2}/>
<image id="image3_223_2" width="211" height="217" xlinkHref={Image1}/>
<image id="image4_223_2" width="209" height="136" xlinkHref={Image2}/>
</defs>
</svg>

  );
}
