import { h } from "preact";
import { useDispatch, useSelector } from "react-redux";
import {
  setSeminarAppointment,
  setKnxNotes,
  getKnxData,
  setSeminarNote,
  setAsset,
} from "../../../redux/modules/chapterKnx";
import { Grid } from "@material-ui/core";
import QActionPaper from "../../QActionPaper";
import QDateAction from "../../QDateAction";
import QIncrementGroup from "../../QIncrementGroup";
import QTextFieldAction from "../../QTextFieldAction";
import QActionCheckbox from "../../QActionCheckbox";

export default function KnxPage({ context }) {
  const dispatch = useDispatch();
  const knxData = useSelector((state) => getKnxData(state));
  const {
    seminarAppointment,
    notes,
    seminarNote,
    assetProgrammuebersicht,
    assetSmarterHome,
    assetInnomag,
    assetCombiaktor,
    assetBuschTenton,
    assetControlTouch,
    assetKNXFlyer,
  } = knxData;

  const handleSetSetupField = (valueSet) => {
    switch (valueSet.id) {
      case "seminarNote":
        {
          dispatch(setSeminarNote(valueSet.value));
        }
        break;
      case "seminarAppointment":
        {
          dispatch(setSeminarAppointment(valueSet.value));
        }
        break;
      case "notes":
        {
          dispatch(setKnxNotes(valueSet.value));
        }
        break;
    }
  };

  const handleSetAsset = (id, value) => {
    dispatch(setAsset(id, value));
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <QActionPaper title="ABB Seminare" context={context}>
          <QDateAction
            date={seminarAppointment.value}
            dateID="seminarAppointment"
            note={seminarNote.value}
            noteID="seminarNote"
            setSetupField={handleSetSetupField}
          />
        </QActionPaper>
      </Grid>
      <Grid item>
        <QActionPaper title="Unterlagen" context={context}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetProgrammuebersicht.value}
                valueID="assetProgrammuebersicht"
                label={
                  <>
                    ABB i-bus<sup>®</sup> KNX
                    Einbruchmeldetechnik / Brandmeldetechnik
                  </>
                }
                mode={1}
                setAsset={handleSetAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetSmarterHome.value}
                valueID="assetSmarterHome"
                label="KNX Visualisierungsmöglichkeiten (BJE)"
                mode={1}
                setAsset={handleSetAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetInnomag.value}
                valueID="assetInnomag"
                label="Innomag 2020 (BJE)"
                mode={1}
                setAsset={handleSetAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetKNXFlyer.value}
                valueID="assetKNXFlyer"
                label="ABB KNX - Aktoren Flyer"
                mode={1}
                setAsset={handleSetAsset}
              />
            </Grid>
          </Grid>
        </QActionPaper>
      </Grid>
      <Grid item>
        <QActionPaper title="Notizen" context={context}>
          <QTextFieldAction
            value={notes.value}
            valueID="notes"
            setSetupField={handleSetSetupField}
          />
        </QActionPaper>
      </Grid>
    </Grid>
  );
}
