import { Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import ImageComponent from "../../../../../../components/ImageComponent";

// Images
import ImageF3CAR from "../../../../../../assets/neuheiten-2024/abb/f3c/f3c.webp";

function F3CAR() {
  return (
    <Slide
      background
      actions={[
        {
          type: "icon",
          link: "https://library.e.abb.com/public/4293f80db7864fbaa98375e591661903/9AKK108468A7295_Rev_A_Motorantriebe_DE_Katalog_1123_WEB.pdf?x-sign=nR50Rf1U9oAD6UwCpiLegrD6M4q5QEF06ApPzZXDxvubVVmliZPlzsB/+8C00rb5",
          target: "_blank",
          img: "laptop",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="F3C-AR Fernantriebe" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  "F3C-AR24 mit 24...48V AC/DC",
                  "F3C-AR230 mit 230-240 V AC",
                  "F3C-AR230 D mit 230-240 V AC und 30s Wartezeit",
                  "Anbaumöglichkeiten: F200 (bis zu 100A) und F200B (bis zu 63A)",
                  "kompakte Bauweise in nur 1 TE",
                  "-25°...+60°C Betriebstemperatur",
                  "Anbau eines kompakten Kommunikationsmoduls möglich",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}>
              <ImageComponent
                src={ImageF3CAR}
                alt="F3C-AR Fernantriebe"
                col={12}
                wrapperHeight="60vh"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default F3CAR;
