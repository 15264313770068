import { h } from "preact";
import { useDispatch, useSelector } from "react-redux";
import {
  setSeminarAppointment,
  setInstallationsGeraeteNotes,
  getInstallationsGeraeteData,
  setSeminarNote,
  setAsset,
} from "../../../redux/modules/chapterInstallationsgeraete";
import { Grid } from "@material-ui/core";
import QActionPaper from "../../QActionPaper";
import QDateAction from "../../QDateAction";
import QIncrementGroup from "../../QIncrementGroup";
import QTextFieldAction from "../../QTextFieldAction";

export default function InstallationsgeraetePage({ context }) {
  const dispatch = useDispatch();
  const installationsGeraeteData = useSelector((state) =>
    getInstallationsGeraeteData(state)
  );
  const {
    seminarAppointment,
    notes,
    seminarNote,
    assetEnergieeffizienz,
    assetFlyer,
    assetBasissortiment,
    assetFlyerAfdd,
    assetBroschuereUeberspannungsschutz,
  } = installationsGeraeteData;

  const handleSetSetupField = (valueSet) => {
    switch (valueSet.id) {
      case "seminarNote":
        {
          dispatch(setSeminarNote(valueSet.value));
        }
        break;
      case "seminarAppointment":
        {
          dispatch(setSeminarAppointment(valueSet.value));
        }
        break;
      case "notes":
        {
          dispatch(setInstallationsGeraeteNotes(valueSet.value));
        }
        break;
    }
  };

  const handleSetAsset = (id, value) => {
    dispatch(setAsset(id, value));
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <QActionPaper title="ABB Seminare" context={context}>
          <QDateAction
            date={seminarAppointment.value}
            dateID="seminarAppointment"
            note={seminarNote.value}
            noteID="seminarNote"
            setSetupField={handleSetSetupField}
          />
        </QActionPaper>
      </Grid>
      <Grid item>
        <QActionPaper title="Unterlagen" context={context}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetEnergieeffizienz.value}
                valueID="assetEnergieeffizienz"
                label="Broschüre Energieeffizienz"
                mode={1}
                setAsset={handleSetAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetFlyer.value}
                valueID="assetFlyer"
                label="Flyer Der FI/LS DS201"
                mode={1}
                setAsset={handleSetAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetBasissortiment.value}
                valueID="assetBasissortiment"
                label="Broschüre Basissortiment"
                mode={1}
                setAsset={handleSetAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetFlyerAfdd.value}
                valueID="assetFlyerAfdd"
                label="Flyer AFDD"
                mode={1}
                setAsset={handleSetAsset}
              />
            </Grid>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetBroschuereUeberspannungsschutz.value}
                valueID="assetBroschuereUeberspannungsschutz"
                label="Broschüre Überspannungsschutz"
                mode={1}
                setAsset={handleSetAsset}
              />
            </Grid>
          </Grid>
        </QActionPaper>
      </Grid>
      <Grid item>
        <QActionPaper title="Notizen" context={context}>
          <QTextFieldAction
            value={notes.value}
            valueID="notes"
            setSetupField={handleSetSetupField}
          />
        </QActionPaper>
      </Grid>
    </Grid>
  );
}
