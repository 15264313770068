const SET_SEMINAR_APPOINTMENT = "playbook/comfortline/SET_SEMINAR_APPOINTMENT";
const SET_NOTES = "playbook/comfortline/SET_NOTES";
const SET_SEMINAR_NOTE = "playbook/comfortline/SET_SEMINAR_NOTE";
const SET_ASSET = "playbook/comfortline/SET_ASSET";

export const initialState = {
  assetSmarterHomeBroschuere: {
    description: "Broschüre Smarter Home",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1738",
  },
  assetFutureCatalog: {
    description: "Katalog Zukunft von Anfang an",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1743",
  },
  assetFlyer2019: {
    description: "Kleiner Verteilerberater - Flyer",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1634",
  },
  assetComfortplanerBroschuere: {
    description: "Comfortplaner - Broschüre",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1772",
  },
  seminarNote: {
    description: "Notizen für das Seminar",
    value: "",
    type: "info",
    orderID: null,
  },
  seminarAppointment: {
    description: "Termin für das Seminar",
    value: null,
    type: "info",
    orderID: null,
  },
  notes: {
    description: "Notizen für das Kapitel",
    value: "",
    type: "info",
    orderID: null,
  },
};

const comfortline = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEMINAR_APPOINTMENT: {
      return {
        ...state,
        seminarAppointment: {
          ...state.seminarAppointment,
          value: action.seminarAppointment,
        },
      };
    }
    case SET_NOTES: {
      return {
        ...state,
        notes: {
          ...state.notes,
          value: action.notes,
        },
      };
    }
    case SET_SEMINAR_NOTE: {
      return {
        ...state,
        seminarNote: {
          ...state.seminarNote,
          value: action.note,
        },
      };
    }
    case SET_ASSET: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          value: action.value,
        },
      };
    }
    default:
      return state;
  }
};

//actions
export const setSeminarAppointment = (seminarAppointment) => ({
  type: SET_SEMINAR_APPOINTMENT,
  seminarAppointment,
});

export const setAsset = (id, value) => ({
  type: SET_ASSET,
  id,
  value,
});

export const setComfortLineNotes = (notes) => ({
  type: SET_NOTES,
  notes,
});

export const setSeminarNote = (note) => ({
  type: SET_SEMINAR_NOTE,
  note,
});

//selector
export const getSeminarAppointment = (state) =>
  state.comfortline.seminarAppointment;
export const getComfortLineNotes = (state) => state.comfortline.notes;
export const getSeminarNote = (state) => state.comfortline.seminarNote;
export const getComfortLineData = (state) => state.comfortline;

export default comfortline;
