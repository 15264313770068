import { Grid } from "@material-ui/core";
import backgroundIntro from "../../../../../../assets/_backgrounds/neuheiten-2024/8.webp";
import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QBodyText from "../../../../../../components/QBodyText";

export default function ComfortLine() {
  return (
    <Slide
      background={{
        src: backgroundIntro,
        backgroundPosition: "left",
        alignment: "right",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="ComfortLine Serie jetzt komplett!" />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={6}>
              <QBodyText>
                Die ComfortLine Serie hat Elektroinstallateuren neue
                Möglichkeiten der Effizienzsteigerung und Zeiteinsparung
                eröffnet. Mit den neuen Kleinverteilern für die Unterputz- und
                Hohlwandmontage wird die Produktserie mit hocheffizienten und
                nachhaltigen Lösungen jetzt abgerundet.
              </QBodyText>
              <QList
                headline="Highlights"
                items={[
                  "Dezenter Blendrahmen",
                  "Geringe Einbautiefe",
                  "Einfache Befestigung in Hohlwand oder Mauerwerk",
                  "Schieber für schnelle Leitungseinführung",
                  "N/PE Quick-Steckklemmen",
                  "QMS-Rasttechnik für Einbaufelder",
                  "Intelligente Verpackung",
                  "Wifi-Türen mit Kunststoffeinsatz",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
