// Overlay
import Slide from "../../../../../../../../components/Slide";
import QBodyText from "../../../../../../../../components/QBodyText";
import { Grid } from "@material-ui/core";
import QHeader from "../../../../../../../../components/QHeader";
import { useModal } from "mui-modal-provider";

// Images
import Grafik from "../../../../../../../../assets/abbknx/Anwendungen/ClimaEco/grafik-keller-heizkreisregelung.webp";

export default function KellerHeizkreisregelung({ swiperRef }) {
  const { showModal } = useModal();
  const id = "keller-heizkreisregelung-svg";
  const goToWaermeKaelteErzeugung = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(3);
    }
  };
  const goToApplicationcontroller = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(1);
    }
  };
  const goToHeizkuehlkreisController = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(2);
    }
  };
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="'Keller' Heizkreisregelung" />
        </Grid>
        <Grid item>
          <Grid container spacing={0}>
            <Grid item xs={7}>
              <QBodyText marginBottom="0px" >Durch den Einbau in Standard-Unterverteiler sind die Geräte aufgrund einer einfachen Handhabung durch beispielsweise Facility-Manager bedienbar.</QBodyText>
            </Grid>
            <Grid item xs={12}>
              <svg style={{ height: "65dvh", width: "100%" }} viewBox="0 0 735 406" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <rect width="734" height="405" fill="url(#heizkreis-pattern-xyi)"/>
                <rect className="pointer" onClick={goToApplicationcontroller} x="9" y="94" width="213" height="104" rx="7" fill="black" fill-opacity="0"/>
                <rect className="pointer" onClick={goToWaermeKaelteErzeugung} x="163" y="222" width="280" height="171" rx="7" fill="black" fill-opacity="0"/>
                <rect className="pointer" onClick={goToHeizkuehlkreisController} x="351" y="34" width="297" height="164" rx="7" fill="black" fill-opacity="0"/>
                <defs>
                  <pattern id="heizkreis-pattern-xyi" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image0_225_3" transform="scale(0.000453759 0.000822368)"/>
                  </pattern>
                    <image id="image0_225_3" width="2204" height="1216" xlinkHref={Grafik}/>
                </defs>
              </svg>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
