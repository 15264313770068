import { Box } from "@material-ui/core";
import { useModal } from "mui-modal-provider";
import AbbildungSechsWegeVentil from "../../../../../../../../../assets/abbknx/Anwendungen/ClimaEco/6-Wege-Ventil/abbildung-sechs-wege-ventil.webp";
import FCCS from "../../../../../../../../../assets/abbknx/Anwendungen/ClimaEco/6-Wege-Ventil/fccs.webp";
import Tabelle from "../../../../../../../../../assets/abbknx/Anwendungen/ClimaEco/6-Wege-Ventil/tabelle.webp";
import QDialog from "../../../../../../../../../components/QDialog";
import QGalery from "../../../../../../../../../components/QGalery";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import HCEDarstellung from "./HCEDarstellung/index";
import DialogTable from "./DialogTable/index";
import SechsWegeVentilSVG from "./SechsWegeVentilSVG/index";

export default function SechsWegeVentil() {
  const { showModal } = useModal();

  const dialogSechsWegeVentil = () => {
    const dialog = showModal(QDialog, {
      children: (
        <TransformWrapper>
          <TransformComponent>
            <Box
              display="flex"
              justifyContent="center"
              width={"calc(100dvw - 2rem)"}
              height={"calc(100dvh - 64px - 2rem)"}
            >
              <SechsWegeVentilSVG />
            </Box>
          </TransformComponent>
        </TransformWrapper>
      ),
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };

  const dialogHCE = () => {
    const dialog = showModal(QDialog, {
      children: (
        <TransformWrapper>
          <TransformComponent>
            <Box
              width={"calc(100dvw - 2rem)"}
              height={"calc(100dvh - 64px - 2rem)"}
            >
              <HCEDarstellung />
            </Box>
          </TransformComponent>
        </TransformWrapper>
      ),
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };

  const dialogTable = () => {
    const dialog = showModal(QDialog, {
      children: (
        <TransformWrapper>
          <TransformComponent>
            <Box
              width={"calc(100dvw - 2rem)"}
              height={"calc(100dvh - 64px - 2rem)"}
            >
              <DialogTable />
            </Box>
          </TransformComponent>
        </TransformWrapper>
      ),
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 1072 723"
      fill="none"
    >
      <g id="6-wege-ventil">
        <g id="sechs-wege-ventil">
          <g id="sechs-wege-ventil-rahmen" filter="url(#filter0_d_17_876)">
            <rect x="26" y="17" width="449" height="688" rx="5" fill="white" />
            <rect
              x="26.5"
              y="17.5"
              width="448"
              height="687"
              rx="4.5"
              stroke="#656565"
            />
          </g>
          <g id="sechs-wege-ventil-raw" clip-path="url(#clip0_17_876)">
            <rect
              id="Image 1"
              x="119.468"
              y="33"
              width="342.906"
              height="447.333"
              fill="url(#patternoslejk0)"
            />
            <rect
              id="Image 1_2"
              x="38"
              y="391.459"
              width="276.702"
              height="285.879"
              fill="url(#patternoslejk1)"
            />
            <path
              id="Line"
              d="M140.205 196.677C140.205 196.677 39.9099 220.377 86.0464 303.802C119.468 364.237 95.1977 437.273 95.1977 437.273"
              stroke="#1B1B1D"
              stroke-width="6"
              stroke-linecap="round"
              stroke-dasharray="12 12"
            />
            <text
              id="0 - 10 V"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Bold"
              font-size="12"
              letter-spacing="0px"
            >
              <tspan x="111" y="246.898">
                0 - 10 V
              </tspan>
            </text>
          </g>
        </g>
        <g id="hce-group">
          <g id="hce-rahmen" filter="url(#filter1_d_17_876)">
            <rect x="519" y="17" width="528" height="298" rx="5" fill="white" />
            <rect
              x="519.5"
              y="17.5"
              width="527"
              height="297"
              rx="4.5"
              stroke="#656565"
            />
          </g>
          <g id="hce-raw">
            <g id="Group 36">
              <text
                id="Konventionelle LÃ¶sung mit vier Durchgangsventilen"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="9"
                letter-spacing="0em"
              >
                <tspan x="555" y="280.452">
                  Konventionelle Lösung
                </tspan>
                <tspan x="555" y="291.452">
                  mit vier Durchgangsventilen
                </tspan>
              </text>
              <text
                id="Konventionelle LÃ¶sung mit zwei Durchgangsventilen und einem Umschaltventil"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="9"
                letter-spacing="0em"
              >
                <tspan x="721.078" y="280.452">
                  Konventionelle Lösung mit zwei
                </tspan>
                <tspan x="721.078" y="291.452">
                  Durchgangsventilen und einem
                </tspan>
                <tspan x="721.078" y="302.452">
                  Umschaltventil
                </tspan>
              </text>
              <text
                id="LÃ¶sung mit einem 6-Weg-Regelkugelhahnen"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="9"
                letter-spacing="0em"
              >
                <tspan x="883.719" y="280.452">
                  Lösung mit einem
                </tspan>
                <tspan x="883.719" y="291.452">
                  6-Weg-Regelkugelhahnen
                </tspan>
              </text>
              <g id="Group 33">
                <g id="Group 31">
                  <g id="Group 30">
                    <rect
                      id="Rectangle 11"
                      x="555"
                      y="230.02"
                      width="16.0351"
                      height="11.4536"
                      fill="#F58220"
                    />
                    <g id="HC">
                      <path
                        d="M558.436 238.392V233.51H559.265V235.691H561.473V233.51H562.302V238.392H561.473V236.436H559.265V238.392H558.436Z"
                        fill="black"
                      />
                      <path
                        d="M565.738 238.436C565.469 238.436 565.214 238.402 564.973 238.335C564.728 238.268 564.503 238.168 564.295 238.03C564.087 237.892 563.909 237.728 563.758 237.533C563.604 237.339 563.486 237.111 563.402 236.842C563.315 236.574 563.275 236.285 563.275 235.97C563.275 235.691 563.308 235.426 563.375 235.174C563.443 234.923 563.547 234.691 563.687 234.483C563.825 234.275 563.993 234.097 564.187 233.943C564.382 233.788 564.61 233.671 564.875 233.584C565.14 233.5 565.429 233.456 565.738 233.456C566.09 233.456 566.436 233.533 566.771 233.688C567.107 233.842 567.405 234.077 567.66 234.392L567.05 234.909C566.885 234.708 566.687 234.543 566.459 234.423C566.231 234.302 565.989 234.241 565.738 234.241C565.422 234.241 565.147 234.315 564.906 234.456C564.664 234.601 564.476 234.802 564.342 235.064C564.208 235.325 564.14 235.627 564.14 235.97C564.14 236.298 564.208 236.594 564.345 236.849C564.479 237.104 564.671 237.302 564.912 237.443C565.154 237.584 565.429 237.654 565.738 237.654C565.999 237.654 566.244 237.597 566.469 237.476C566.691 237.355 566.885 237.194 567.05 236.99L567.66 237.51C567.436 237.792 567.16 238.017 566.835 238.184C566.51 238.352 566.144 238.436 565.738 238.436Z"
                        fill="black"
                      />
                    </g>
                  </g>
                  <g id="Group 29">
                    <rect
                      id="Rectangle 12"
                      x="555"
                      y="241.474"
                      width="16.0351"
                      height="11.4536"
                      fill="#BDBABA"
                    />
                    <g id="CC">
                      <path
                        d="M560.899 249.89C560.631 249.89 560.376 249.856 560.134 249.789C559.889 249.722 559.664 249.621 559.456 249.484C559.248 249.346 559.07 249.182 558.919 248.987C558.765 248.792 558.647 248.564 558.564 248.296C558.476 248.027 558.436 247.739 558.436 247.423C558.436 247.145 558.47 246.88 558.537 246.628C558.604 246.376 558.708 246.145 558.849 245.937C558.986 245.729 559.154 245.551 559.349 245.396C559.543 245.242 559.772 245.125 560.037 245.037C560.302 244.954 560.59 244.91 560.899 244.91C561.251 244.91 561.597 244.987 561.933 245.141C562.268 245.296 562.567 245.531 562.822 245.846L562.211 246.363C562.047 246.162 561.849 245.997 561.62 245.876C561.392 245.756 561.151 245.695 560.899 245.695C560.584 245.695 560.308 245.769 560.067 245.91C559.825 246.054 559.637 246.255 559.503 246.517C559.369 246.779 559.302 247.081 559.302 247.423C559.302 247.752 559.369 248.047 559.506 248.302C559.641 248.557 559.832 248.755 560.074 248.896C560.315 249.037 560.59 249.108 560.899 249.108C561.161 249.108 561.406 249.051 561.631 248.93C561.852 248.809 562.047 248.648 562.211 248.443L562.822 248.963C562.597 249.245 562.322 249.47 561.996 249.638C561.671 249.806 561.305 249.89 560.899 249.89Z"
                        fill="black"
                      />
                      <path
                        d="M565.892 249.89C565.624 249.89 565.369 249.856 565.127 249.789C564.882 249.722 564.657 249.621 564.449 249.484C564.241 249.346 564.063 249.182 563.912 248.987C563.758 248.792 563.641 248.564 563.557 248.296C563.469 248.027 563.429 247.739 563.429 247.423C563.429 247.145 563.463 246.88 563.53 246.628C563.597 246.376 563.701 246.145 563.842 245.937C563.979 245.729 564.147 245.551 564.342 245.396C564.536 245.242 564.765 245.125 565.03 245.037C565.295 244.954 565.583 244.91 565.892 244.91C566.244 244.91 566.59 244.987 566.926 245.141C567.261 245.296 567.56 245.531 567.815 245.846L567.204 246.363C567.04 246.162 566.842 245.997 566.614 245.876C566.385 245.756 566.144 245.695 565.892 245.695C565.577 245.695 565.302 245.769 565.06 245.91C564.818 246.054 564.63 246.255 564.496 246.517C564.362 246.779 564.295 247.081 564.295 247.423C564.295 247.752 564.362 248.047 564.5 248.302C564.634 248.557 564.825 248.755 565.067 248.896C565.308 249.037 565.583 249.108 565.892 249.108C566.154 249.108 566.399 249.051 566.624 248.93C566.845 248.809 567.04 248.648 567.204 248.443L567.815 248.963C567.59 249.245 567.315 249.47 566.989 249.638C566.664 249.806 566.298 249.89 565.892 249.89Z"
                        fill="black"
                      />
                    </g>
                  </g>
                </g>
                <line
                  id="Line 32"
                  x1="571.035"
                  y1="232.956"
                  x2="683.281"
                  y2="232.956"
                  stroke="#F58220"
                />
                <line
                  id="Line 33"
                  x1="571.035"
                  y1="244.41"
                  x2="683.281"
                  y2="244.41"
                  stroke="#BDBABA"
                />
                <path
                  id="Line 34"
                  d="M622.004 244.337L622.004 194.514L634.603 194.514"
                  stroke="#BDBABA"
                />
                <path
                  id="Line 35"
                  d="M644.394 232.884L644.394 194.514L635.748 194.514"
                  stroke="#F58220"
                />
                <line
                  id="Line 36"
                  x1="571.035"
                  y1="238.683"
                  x2="683.281"
                  y2="238.683"
                  stroke="#F58220"
                  stroke-dasharray="2 2"
                />
                <line
                  id="Line 37"
                  x1="571.035"
                  y1="250.137"
                  x2="683.281"
                  y2="250.137"
                  stroke="#BDBABA"
                  stroke-dasharray="2 2"
                />
                <path
                  id="Line 38"
                  d="M592.224 250.637L592.224 183.633L659.228 183.633"
                  stroke="#BDBABA"
                  stroke-dasharray="2 2"
                />
                <path
                  id="Line 39"
                  d="M660.373 183.633H674.69V239.183"
                  stroke="#F58220"
                  stroke-dasharray="2 2"
                />
                <g id="Group 21">
                  <g id="Group 10">
                    <circle
                      id="Ellipse 1"
                      cx="613.414"
                      cy="212.267"
                      r="3.18609"
                      fill="white"
                      stroke="black"
                      stroke-width="0.5"
                    />
                    <path
                      id="M"
                      d="M614.385 210.795H614.886V213.74H614.387V211.766H614.381L613.577 213.173H613.25L612.446 211.766H612.44V213.74H611.941V210.795H612.442L613.412 212.558H613.42L614.385 210.795Z"
                      fill="black"
                    />
                  </g>
                  <path
                    id="Rectangle 14"
                    d="M616.85 202.531H627.158L622.576 212.267L627.158 220.857H616.85L621.431 212.267L616.85 202.531Z"
                    fill="black"
                  />
                  <path
                    id="Line 41"
                    d="M616.277 212.267H622.004"
                    stroke="black"
                  />
                </g>
                <g id="Group 27">
                  <g id="Group 10_2">
                    <circle
                      id="Ellipse 1_2"
                      cx="583.634"
                      cy="212.267"
                      r="3.18609"
                      fill="white"
                      stroke="black"
                      stroke-width="0.5"
                    />
                    <path
                      id="M_2"
                      d="M584.606 210.795H585.107V213.74H584.608V211.766H584.602L583.798 213.173H583.471L582.667 211.766H582.661V213.74H582.162V210.795H582.663L583.632 212.558H583.64L584.606 210.795Z"
                      fill="black"
                    />
                  </g>
                  <path
                    id="Rectangle 14_2"
                    d="M587.07 202.531H597.378L592.797 212.267L597.378 220.857H587.07L591.652 212.267L587.07 202.531Z"
                    fill="black"
                  />
                  <path
                    id="Line 41_2"
                    d="M586.498 212.267H592.224"
                    stroke="black"
                  />
                </g>
                <g id="Group 22">
                  <g id="Group 11">
                    <circle
                      id="Ellipse 1_3"
                      cx="652.984"
                      cy="212.267"
                      r="3.18609"
                      fill="white"
                      stroke="black"
                      stroke-width="0.5"
                    />
                    <path
                      id="M_3"
                      d="M653.956 210.795H654.457V213.74H653.958V211.766H653.952L653.148 213.173H652.821L652.017 211.766H652.011V213.74H651.512V210.795H652.013L652.983 212.558H652.991L653.956 210.795Z"
                      fill="black"
                    />
                  </g>
                  <path
                    id="Rectangle 15"
                    d="M639.24 202.531H649.549L644.967 212.267L649.549 220.857H639.24L643.822 212.267L639.24 202.531Z"
                    fill="black"
                  />
                  <path
                    id="Line 42"
                    d="M644.394 212.267H650.121"
                    stroke="black"
                  />
                </g>
                <g id="Group 28">
                  <g id="Group 11_2">
                    <circle
                      id="Ellipse 1_4"
                      cx="683.281"
                      cy="212.267"
                      r="3.18609"
                      fill="white"
                      stroke="black"
                      stroke-width="0.5"
                    />
                    <path
                      id="M_4"
                      d="M684.252 210.795H684.753V213.74H684.254V211.766H684.248L683.444 213.173H683.117L682.313 211.766H682.307V213.74H681.808V210.795H682.309L683.279 212.558H683.287L684.252 210.795Z"
                      fill="black"
                    />
                  </g>
                  <path
                    id="Rectangle 15_2"
                    d="M669.536 202.531H679.845L675.263 212.267L679.845 220.857H669.536L674.118 212.267L669.536 202.531Z"
                    fill="black"
                  />
                  <path
                    id="Line 42_2"
                    d="M674.69 212.267H680.417"
                    stroke="black"
                  />
                </g>
                <rect
                  id="Rectangle 17"
                  x="632.239"
                  y="118.275"
                  width="31.0702"
                  height="42.5238"
                  fill="white"
                  stroke="black"
                />
                <g id="HCE">
                  <text
                    fill="black"
                    xmlSpace="preserve"
                    style="white-space: pre"
                    font-family="Averta-Semibold"
                    font-size="8"
                    letter-spacing="0em"
                  >
                    <tspan x="650.327" y="115.881">
                      E
                    </tspan>
                  </text>
                  <text
                    fill="#F58220"
                    xmlSpace="preserve"
                    style="white-space: pre"
                    font-family="Averta-Semibold"
                    font-size="8"
                    letter-spacing="0em"
                  >
                    <tspan x="638.601" y="115.881">
                      H
                    </tspan>
                  </text>
                  <text
                    fill="#BDBABA"
                    xmlSpace="preserve"
                    style="white-space: pre"
                    font-family="Averta-Semibold"
                    font-size="8"
                    letter-spacing="0em"
                  >
                    <tspan x="644.491" y="115.881">
                      C
                    </tspan>
                  </text>
                </g>
                <path
                  id="Line 44"
                  d="M635.176 195.087V161.298"
                  stroke="black"
                />
                <path
                  id="Line 45"
                  d="M659.801 184.206V161.299"
                  stroke="black"
                  stroke-dasharray="2 2"
                />
                <path
                  id="Line 46"
                  d="M632.312 118.347L663.237 160.726"
                  stroke="black"
                />
                <path
                  id="Line 47"
                  d="M663.237 118.347L632.312 160.726"
                  stroke="black"
                />
              </g>
              <g id="Group 34">
                <g id="Group 31_2">
                  <g id="Group 30_2">
                    <rect
                      id="Rectangle 11_2"
                      x="721.078"
                      y="230.02"
                      width="16.0351"
                      height="11.4536"
                      fill="#F58220"
                    />
                    <g id="HC_2">
                      <path
                        d="M724.514 238.392V233.51H725.342V235.691H727.55V233.51H728.379V238.392H727.55V236.436H725.342V238.392H724.514Z"
                        fill="black"
                      />
                      <path
                        d="M731.815 238.436C731.547 238.436 731.292 238.402 731.05 238.335C730.805 238.268 730.581 238.168 730.372 238.03C730.164 237.892 729.987 237.728 729.836 237.533C729.681 237.339 729.564 237.111 729.48 236.842C729.393 236.574 729.352 236.285 729.352 235.97C729.352 235.691 729.386 235.426 729.453 235.174C729.52 234.923 729.624 234.691 729.765 234.483C729.903 234.275 730.07 234.097 730.265 233.943C730.46 233.788 730.688 233.671 730.953 233.584C731.218 233.5 731.507 233.456 731.815 233.456C732.168 233.456 732.513 233.533 732.849 233.688C733.184 233.842 733.483 234.077 733.738 234.392L733.127 234.909C732.963 234.708 732.765 234.543 732.537 234.423C732.309 234.302 732.067 234.241 731.815 234.241C731.5 234.241 731.225 234.315 730.983 234.456C730.742 234.601 730.554 234.802 730.419 235.064C730.285 235.325 730.218 235.627 730.218 235.97C730.218 236.298 730.285 236.594 730.423 236.849C730.557 237.104 730.748 237.302 730.99 237.443C731.231 237.584 731.507 237.654 731.815 237.654C732.077 237.654 732.322 237.597 732.547 237.476C732.768 237.355 732.963 237.194 733.127 236.99L733.738 237.51C733.513 237.792 733.238 238.017 732.913 238.184C732.587 238.352 732.221 238.436 731.815 238.436Z"
                        fill="black"
                      />
                    </g>
                  </g>
                  <g id="Group 29_2">
                    <rect
                      id="Rectangle 12_2"
                      x="721.078"
                      y="241.474"
                      width="16.0351"
                      height="11.4536"
                      fill="#BDBABA"
                    />
                    <g id="CC_2">
                      <path
                        d="M726.977 249.89C726.708 249.89 726.453 249.856 726.212 249.789C725.967 249.722 725.742 249.621 725.534 249.484C725.326 249.346 725.148 249.182 724.997 248.987C724.843 248.792 724.725 248.564 724.641 248.296C724.554 248.027 724.514 247.739 724.514 247.423C724.514 247.145 724.547 246.88 724.614 246.628C724.681 246.376 724.785 246.145 724.926 245.937C725.064 245.729 725.232 245.551 725.426 245.396C725.621 245.242 725.849 245.125 726.114 245.037C726.379 244.954 726.668 244.91 726.977 244.91C727.329 244.91 727.675 244.987 728.01 245.141C728.346 245.296 728.644 245.531 728.899 245.846L728.289 246.363C728.124 246.162 727.926 245.997 727.698 245.876C727.47 245.756 727.228 245.695 726.977 245.695C726.661 245.695 726.386 245.769 726.144 245.91C725.903 246.054 725.715 246.255 725.581 246.517C725.447 246.779 725.379 247.081 725.379 247.423C725.379 247.752 725.447 248.047 725.584 248.302C725.718 248.557 725.91 248.755 726.151 248.896C726.393 249.037 726.668 249.108 726.977 249.108C727.238 249.108 727.483 249.051 727.708 248.93C727.93 248.809 728.124 248.648 728.289 248.443L728.899 248.963C728.675 249.245 728.399 249.47 728.074 249.638C727.748 249.806 727.383 249.89 726.977 249.89Z"
                        fill="black"
                      />
                      <path
                        d="M731.97 249.89C731.701 249.89 731.446 249.856 731.205 249.789C730.96 249.722 730.735 249.621 730.527 249.484C730.319 249.346 730.141 249.182 729.99 248.987C729.836 248.792 729.718 248.564 729.634 248.296C729.547 248.027 729.507 247.739 729.507 247.423C729.507 247.145 729.54 246.88 729.607 246.628C729.675 246.376 729.779 246.145 729.919 245.937C730.057 245.729 730.225 245.551 730.419 245.396C730.614 245.242 730.842 245.125 731.107 245.037C731.372 244.954 731.661 244.91 731.97 244.91C732.322 244.91 732.668 244.987 733.003 245.141C733.339 245.296 733.637 245.531 733.892 245.846L733.282 246.363C733.117 246.162 732.919 245.997 732.691 245.876C732.463 245.756 732.221 245.695 731.97 245.695C731.654 245.695 731.379 245.769 731.138 245.91C730.896 246.054 730.708 246.255 730.574 246.517C730.44 246.779 730.372 247.081 730.372 247.423C730.372 247.752 730.44 248.047 730.577 248.302C730.711 248.557 730.903 248.755 731.144 248.896C731.386 249.037 731.661 249.108 731.97 249.108C732.231 249.108 732.476 249.051 732.701 248.93C732.923 248.809 733.117 248.648 733.282 248.443L733.892 248.963C733.668 249.245 733.392 249.47 733.067 249.638C732.741 249.806 732.376 249.89 731.97 249.89Z"
                        fill="black"
                      />
                    </g>
                  </g>
                </g>
                <line
                  id="Line 32_2"
                  x1="737.113"
                  y1="232.956"
                  x2="849.358"
                  y2="232.956"
                  stroke="#F58220"
                />
                <line
                  id="Line 33_2"
                  x1="737.113"
                  y1="244.41"
                  x2="849.358"
                  y2="244.41"
                  stroke="#BDBABA"
                />
                <path
                  id="Line 34_2"
                  d="M788.082 244.337L788.082 194.514L800.681 194.514"
                  stroke="#BDBABA"
                />
                <path
                  id="Line 35_2"
                  d="M810.472 232.884L810.472 194.514L801.826 194.514"
                  stroke="#F58220"
                />
                <line
                  id="Line 36_2"
                  x1="737.113"
                  y1="238.683"
                  x2="849.358"
                  y2="238.683"
                  stroke="#F58220"
                  stroke-dasharray="2 2"
                />
                <line
                  id="Line 37_2"
                  x1="737.113"
                  y1="250.137"
                  x2="849.358"
                  y2="250.137"
                  stroke="#BDBABA"
                  stroke-dasharray="2 2"
                />
                <path
                  id="Line 38_2"
                  d="M758.302 250.637L758.302 183.633L825.306 183.633"
                  stroke="#BDBABA"
                  stroke-dasharray="2 2"
                />
                <path
                  id="Line 39_2"
                  d="M826.451 183.633H840.768V239.183"
                  stroke="#F58220"
                  stroke-dasharray="2 2"
                />
                <g id="Group 21_2">
                  <g id="Group 10_3">
                    <circle
                      id="Ellipse 1_5"
                      cx="779.491"
                      cy="212.267"
                      r="3.18609"
                      fill="white"
                      stroke="black"
                      stroke-width="0.5"
                    />
                    <path
                      id="M_5"
                      d="M780.463 210.795H780.964V213.74H780.465V211.766H780.459L779.655 213.173H779.328L778.524 211.766H778.518V213.74H778.019V210.795H778.52L779.489 212.558H779.497L780.463 210.795Z"
                      fill="black"
                    />
                  </g>
                  <path
                    id="Rectangle 14_3"
                    d="M782.927 202.531H793.236L788.654 212.267L793.236 220.857H782.927L787.509 212.267L782.927 202.531Z"
                    fill="black"
                  />
                  <path
                    id="Line 41_3"
                    d="M782.355 212.267H788.082"
                    stroke="black"
                  />
                </g>
                <g id="Group 22_2">
                  <g id="Group 11_3">
                    <circle
                      id="Ellipse 1_6"
                      cx="819.062"
                      cy="212.267"
                      r="3.18609"
                      fill="white"
                      stroke="black"
                      stroke-width="0.5"
                    />
                    <path
                      id="M_6"
                      d="M820.034 210.795H820.535V213.74H820.036V211.766H820.03L819.226 213.173H818.899L818.095 211.766H818.089V213.74H817.59V210.795H818.091L819.06 212.558H819.068L820.034 210.795Z"
                      fill="black"
                    />
                  </g>
                  <path
                    id="Rectangle 15_3"
                    d="M805.318 202.531H815.626L811.045 212.267L815.626 220.857H805.318L809.899 212.267L805.318 202.531Z"
                    fill="black"
                  />
                  <path
                    id="Line 42_3"
                    d="M810.472 212.267H816.199"
                    stroke="black"
                  />
                </g>
                <rect
                  id="Rectangle 17_2"
                  x="798.317"
                  y="118.275"
                  width="31.0702"
                  height="42.5238"
                  fill="white"
                  stroke="black"
                />
                <g id="HCE_2">
                  <text
                    fill="black"
                    xmlSpace="preserve"
                    style="white-space: pre"
                    font-family="Averta-Semibold"
                    font-size="8"
                    letter-spacing="0em"
                  >
                    <tspan x="816.405" y="115.881">
                      E
                    </tspan>
                  </text>
                  <text
                    fill="#F58220"
                    xmlSpace="preserve"
                    style="white-space: pre"
                    font-family="Averta-Semibold"
                    font-size="8"
                    letter-spacing="0em"
                  >
                    <tspan x="804.679" y="115.881">
                      H
                    </tspan>
                  </text>
                  <text
                    fill="#BDBABA"
                    xmlSpace="preserve"
                    style="white-space: pre"
                    font-family="Averta-Semibold"
                    font-size="8"
                    letter-spacing="0em"
                  >
                    <tspan x="810.569" y="115.881">
                      C
                    </tspan>
                  </text>
                </g>
                <path
                  id="Line 44_2"
                  d="M801.253 195.087V161.298"
                  stroke="black"
                />
                <path
                  id="Line 45_2"
                  d="M825.878 184.206V161.299"
                  stroke="black"
                  stroke-dasharray="2 2"
                />
                <g id="Group 13">
                  <g id="Group 12">
                    <circle
                      id="Ellipse 1_7"
                      cx="825.878"
                      cy="191.078"
                      r="3.18609"
                      fill="white"
                      stroke="black"
                      stroke-width="0.5"
                    />
                    <path
                      id="M_7"
                      d="M826.85 189.605H827.351V192.551H826.852V190.577H826.846L826.042 191.984H825.715L824.911 190.577H824.905V192.551H824.406V189.605H824.907L825.877 191.369H825.885L826.85 189.605Z"
                      fill="black"
                    />
                  </g>
                  <path
                    id="Line 48"
                    d="M825.878 188.214L825.878 183.06"
                    stroke="black"
                  />
                  <path
                    id="Rectangle 18"
                    d="M817.288 188.787V178.479L825.879 183.06L834.469 178.479V188.787L825.879 184.206L817.288 188.787Z"
                    fill="black"
                  />
                  <path
                    id="Rectangle 19"
                    d="M825.606 183.133L821.558 175.543H830.199L826.151 183.133H825.606Z"
                    fill="white"
                    stroke="black"
                  />
                </g>
                <path
                  id="Line 46_2"
                  d="M798.39 118.347L829.314 160.726"
                  stroke="black"
                />
                <path
                  id="Line 47_2"
                  d="M829.314 118.347L798.39 160.726"
                  stroke="black"
                />
              </g>
              <g id="Group 35">
                <g id="Group 31_3">
                  <g id="Group 30_3">
                    <rect
                      id="Rectangle 11_3"
                      x="883.719"
                      y="230.02"
                      width="16.0351"
                      height="11.4536"
                      fill="#F58220"
                    />
                    <g id="HC_3">
                      <path
                        d="M887.155 238.392V233.51H887.984V235.691H890.192V233.51H891.021V238.392H890.192V236.436H887.984V238.392H887.155Z"
                        fill="black"
                      />
                      <path
                        d="M894.457 238.436C894.189 238.436 893.933 238.402 893.692 238.335C893.447 238.268 893.222 238.168 893.014 238.03C892.806 237.892 892.628 237.728 892.477 237.533C892.323 237.339 892.205 237.111 892.121 236.842C892.034 236.574 891.994 236.285 891.994 235.97C891.994 235.691 892.028 235.426 892.095 235.174C892.162 234.923 892.266 234.691 892.407 234.483C892.544 234.275 892.712 234.097 892.907 233.943C893.101 233.788 893.329 233.671 893.595 233.584C893.86 233.5 894.148 233.456 894.457 233.456C894.809 233.456 895.155 233.533 895.49 233.688C895.826 233.842 896.125 234.077 896.38 234.392L895.769 234.909C895.605 234.708 895.407 234.543 895.178 234.423C894.95 234.302 894.709 234.241 894.457 234.241C894.142 234.241 893.866 234.315 893.625 234.456C893.383 234.601 893.195 234.802 893.061 235.064C892.927 235.325 892.86 235.627 892.86 235.97C892.86 236.298 892.927 236.594 893.064 236.849C893.199 237.104 893.39 237.302 893.631 237.443C893.873 237.584 894.148 237.654 894.457 237.654C894.719 237.654 894.964 237.597 895.188 237.476C895.41 237.355 895.605 237.194 895.769 236.99L896.38 237.51C896.155 237.792 895.88 238.017 895.554 238.184C895.229 238.352 894.863 238.436 894.457 238.436Z"
                        fill="black"
                      />
                    </g>
                  </g>
                  <g id="Group 29_3">
                    <rect
                      id="Rectangle 12_3"
                      x="883.719"
                      y="241.474"
                      width="16.0351"
                      height="11.4536"
                      fill="#BDBABA"
                    />
                    <g id="CC_3">
                      <path
                        d="M889.618 249.89C889.35 249.89 889.095 249.856 888.853 249.789C888.608 249.722 888.383 249.621 888.175 249.484C887.967 249.346 887.789 249.182 887.638 248.987C887.484 248.792 887.367 248.564 887.283 248.296C887.196 248.027 887.155 247.739 887.155 247.423C887.155 247.145 887.189 246.88 887.256 246.628C887.323 246.376 887.427 246.145 887.568 245.937C887.706 245.729 887.873 245.551 888.068 245.396C888.263 245.242 888.491 245.125 888.756 245.037C889.021 244.954 889.31 244.91 889.618 244.91C889.971 244.91 890.316 244.987 890.652 245.141C890.987 245.296 891.286 245.531 891.541 245.846L890.93 246.363C890.766 246.162 890.568 245.997 890.34 245.876C890.112 245.756 889.87 245.695 889.618 245.695C889.303 245.695 889.028 245.769 888.786 245.91C888.544 246.054 888.357 246.255 888.222 246.517C888.088 246.779 888.021 247.081 888.021 247.423C888.021 247.752 888.088 248.047 888.226 248.302C888.36 248.557 888.551 248.755 888.793 248.896C889.034 249.037 889.31 249.108 889.618 249.108C889.88 249.108 890.125 249.051 890.35 248.93C890.571 248.809 890.766 248.648 890.93 248.443L891.541 248.963C891.316 249.245 891.041 249.47 890.716 249.638C890.39 249.806 890.024 249.89 889.618 249.89Z"
                        fill="black"
                      />
                      <path
                        d="M894.611 249.89C894.343 249.89 894.088 249.856 893.846 249.789C893.601 249.722 893.376 249.621 893.168 249.484C892.96 249.346 892.783 249.182 892.632 248.987C892.477 248.792 892.36 248.564 892.276 248.296C892.189 248.027 892.148 247.739 892.148 247.423C892.148 247.145 892.182 246.88 892.249 246.628C892.316 246.376 892.42 246.145 892.561 245.937C892.699 245.729 892.866 245.551 893.061 245.396C893.256 245.242 893.484 245.125 893.749 245.037C894.014 244.954 894.303 244.91 894.611 244.91C894.964 244.91 895.309 244.987 895.645 245.141C895.98 245.296 896.279 245.531 896.534 245.846L895.923 246.363C895.759 246.162 895.561 245.997 895.333 245.876C895.105 245.756 894.863 245.695 894.611 245.695C894.296 245.695 894.021 245.769 893.779 245.91C893.538 246.054 893.35 246.255 893.215 246.517C893.081 246.779 893.014 247.081 893.014 247.423C893.014 247.752 893.081 248.047 893.219 248.302C893.353 248.557 893.544 248.755 893.786 248.896C894.027 249.037 894.303 249.108 894.611 249.108C894.873 249.108 895.118 249.051 895.343 248.93C895.564 248.809 895.759 248.648 895.923 248.443L896.534 248.963C896.309 249.245 896.034 249.47 895.709 249.638C895.383 249.806 895.017 249.89 894.611 249.89Z"
                        fill="black"
                      />
                    </g>
                  </g>
                </g>
                <line
                  id="Line 32_3"
                  x1="899.754"
                  y1="232.956"
                  x2="1012"
                  y2="232.956"
                  stroke="#F58220"
                />
                <line
                  id="Line 33_3"
                  x1="899.754"
                  y1="244.41"
                  x2="1012"
                  y2="244.41"
                  stroke="#BDBABA"
                />
                <path
                  id="Line 34_3"
                  d="M942.706 244.337L942.706 183.633L963.895 183.633"
                  stroke="#BDBABA"
                />
                <path
                  id="Line 35_3"
                  d="M983.995 232.884L983.995 183.633L971.912 183.633"
                  stroke="#F58220"
                />
                <line
                  id="Line 36_3"
                  x1="899.754"
                  y1="238.683"
                  x2="1012"
                  y2="238.683"
                  stroke="#F58220"
                  stroke-dasharray="2 2"
                />
                <line
                  id="Line 37_3"
                  x1="899.754"
                  y1="250.137"
                  x2="1012"
                  y2="250.137"
                  stroke="#BDBABA"
                  stroke-dasharray="2 2"
                />
                <path
                  id="Line 38_3"
                  d="M947.287 250.637L947.287 199.668L963.895 199.668"
                  stroke="#BDBABA"
                  stroke-dasharray="2 2"
                />
                <path
                  id="Line 39_3"
                  d="M963.895 199.095H979.93V239.183"
                  stroke="#F58220"
                  stroke-dasharray="2 2"
                />
                <rect
                  id="Rectangle 17_3"
                  x="960.959"
                  y="118.275"
                  width="31.0702"
                  height="42.5238"
                  fill="white"
                  stroke="black"
                />
                <g id="HCE_3">
                  <text
                    fill="black"
                    xmlSpace="preserve"
                    style="white-space: pre"
                    font-family="Averta-Semibold"
                    font-size="8"
                    letter-spacing="0em"
                  >
                    <tspan x="979.047" y="115.881">
                      E
                    </tspan>
                  </text>
                  <text
                    fill="#F58220"
                    xmlSpace="preserve"
                    style="white-space: pre"
                    font-family="Averta-Semibold"
                    font-size="8"
                    letter-spacing="0em"
                  >
                    <tspan x="967.32" y="115.881">
                      H
                    </tspan>
                  </text>
                  <text
                    fill="#BDBABA"
                    xmlSpace="preserve"
                    style="white-space: pre"
                    font-family="Averta-Semibold"
                    font-size="8"
                    letter-spacing="0em"
                  >
                    <tspan x="973.211" y="115.881">
                      C
                    </tspan>
                  </text>
                </g>
                <path
                  id="Line 44_3"
                  d="M963.895 200.241V161.298"
                  stroke="black"
                />
                <path
                  id="Line 45_3"
                  d="M963.895 199.095V215.703H988.52V161.298"
                  stroke="black"
                  stroke-dasharray="2 2"
                />
                <g id="Group 13_2">
                  <g id="Group 12_2">
                    <circle
                      id="Ellipse 1_8"
                      cx="963.895"
                      cy="191.078"
                      r="3.18609"
                      fill="white"
                      stroke="black"
                      stroke-width="0.5"
                    />
                    <path
                      id="M_8"
                      d="M964.866 189.605H965.367V192.551H964.868V190.577H964.862L964.058 191.984H963.731L962.927 190.577H962.921V192.551H962.422V189.605H962.923L963.893 191.369H963.901L964.866 189.605Z"
                      fill="black"
                    />
                  </g>
                  <path
                    id="Rectangle 18_2"
                    d="M955.304 188.787V178.479L963.895 183.06L972.485 178.479V188.787L963.895 184.206L955.304 188.787Z"
                    fill="black"
                  />
                  <path
                    id="Rectangle 19_2"
                    d="M963.622 183.133L959.574 175.543H968.216L964.168 183.133H963.622Z"
                    fill="white"
                    stroke="black"
                  />
                </g>
                <g id="Group 32">
                  <path
                    id="Rectangle 18_3"
                    d="M955.304 204.822V194.514L963.895 199.095L972.485 194.514V204.822L963.895 200.241L955.304 204.822Z"
                    fill="black"
                  />
                  <path
                    id="Rectangle 19_3"
                    d="M964.167 200.741L968.215 208.331H959.574L963.622 200.741H964.167Z"
                    fill="white"
                    stroke="black"
                  />
                </g>
                <path
                  id="Line 46_3"
                  d="M961.031 118.347L991.956 160.726"
                  stroke="black"
                />
                <path
                  id="Line 47_3"
                  d="M991.956 118.347L961.031 160.726"
                  stroke="black"
                />
              </g>
              <path
                id="Line 49"
                d="M947.287 83.9864L953.06 73.9864H941.513L947.287 83.9864ZM620.286 44.4714V43.4714H619.286V44.4714H620.286ZM947.287 44.4714H948.287V43.4714H947.287V44.4714ZM621.286 84.5591V44.4714H619.286V84.5591H621.286ZM620.286 45.4714L947.287 45.4714V43.4714L620.286 43.4714V45.4714ZM946.287 44.4714V74.9864H948.287V44.4714H946.287Z"
                fill="black"
              />
              <text
                id="Reduction/Optimizing numbers of the valves"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="9"
                letter-spacing="0em"
              >
                <tspan x="689.008" y="33.0532">
                  Reduction/Optimizing numbers of the valves
                </tspan>
              </text>
            </g>
          </g>
        </g>
        <g id="tabelle-group">
          <g id="tabelle-rahmen" filter="url(#filter2_d_17_876)">
            <rect
              x="519"
              y="359"
              width="528"
              height="346"
              rx="5"
              fill="white"
            />
            <rect
              x="519.5"
              y="359.5"
              width="527"
              height="345"
              rx="4.5"
              stroke="#656565"
            />
          </g>
          <g id="tabelle-raw">
            <text
              id="Externes Eingangssignal"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="10"
              letter-spacing="0em"
            >
              <tspan x="738.366" y="689.837">
                Externes Eingangssignal
              </tspan>
            </text>
            <g id="Group 37">
              <path
                id="Line 50"
                d="M568 670L578 675.774V664.226L568 670ZM768.5 670L758.5 664.226V675.774L768.5 670ZM577 671H759.5V669H577V671Z"
                fill="black"
              />
              <path
                id="Line 51"
                d="M822.5 670L832.5 675.774V664.226L822.5 670ZM1024 670L1014 664.226V675.774L1024 670ZM831.5 671H1015V669H831.5V671Z"
                fill="black"
              />
              <line
                id="Line 52"
                x1="756"
                y1="670"
                x2="839"
                y2="670"
                stroke="black"
                stroke-width="2"
              />
              <text
                id="KÃ¼hlen 0,5 V - 4,5 V"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="10"
                letter-spacing="0em"
              >
                <tspan x="617.199" y="663.837">
                  Kühlen 0,5 V - 4,5 V
                </tspan>
              </text>
              <text
                id="Heizen 5,5 V - 10 V"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="10"
                letter-spacing="0em"
              >
                <tspan x="883.475" y="663.837">
                  Heizen 5,5 V - 10 V
                </tspan>
              </text>
            </g>
            <text
              id="KÃ¼hlen"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Regular"
              font-size="10"
              letter-spacing="0em"
            >
              <tspan x="685.155" y="496.847">
                Kühlen
              </tspan>
            </text>
            <text
              id="Heizen"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Regular"
              font-size="10"
              letter-spacing="0em"
            >
              <tspan x="890.438" y="572.847">
                Heizen
              </tspan>
            </text>
            <text
              id="Volumenstrom"
              transform="matrix(0 -1 1 0 528 534)"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="10"
              letter-spacing="0em"
            >
              <tspan x="0.0595703" y="8.83691">
                Volumenstrom
              </tspan>
            </text>
            <rect
              id="Rectangle 20"
              x="774"
              y="373"
              width="46"
              height="258"
              fill="black"
            />
            <path
              id="Line 53"
              d="M1024 630.5L1019 627.613V633.387L1024 630.5ZM569 631H1019.5V630H569V631Z"
              fill="black"
            />
            <path
              id="Line 54"
              d="M569 373L566.113 378H571.887L569 373ZM569.5 631V377.5H568.5V631H569.5Z"
              fill="black"
            />
            <text
              id="0 %"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="8"
              letter-spacing="0em"
            >
              <tspan x="549.609" y="633.27">
                0 %
              </tspan>
            </text>
            <g id="Group 39">
              <text
                id="0"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="566.938" y="643.27">
                  0
                </tspan>
              </text>
              <text
                id="0.5"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="585.406" y="643.27">
                  0.5
                </tspan>
              </text>
              <text
                id="1.5"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="631.156" y="643.27">
                  1.5
                </tspan>
              </text>
              <text
                id="2"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="656.742" y="643.27">
                  2
                </tspan>
              </text>
              <text
                id="2.5"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="677.094" y="643.27">
                  2.5
                </tspan>
              </text>
              <text
                id="3"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="702.602" y="643.27">
                  3
                </tspan>
              </text>
              <text
                id="3.5"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="723.008" y="643.27">
                  3.5
                </tspan>
              </text>
              <text
                id="4"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="747.375" y="643.27">
                  4
                </tspan>
              </text>
              <text
                id="4.5"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="768.82" y="643.27">
                  4.5
                </tspan>
              </text>
              <text
                id="5"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="793.374" y="643.248">
                  5
                </tspan>
              </text>
              <text
                id="5.5"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="813.617" y="643.248">
                  5.5
                </tspan>
              </text>
              <text
                id="6"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="838.298" y="643.248">
                  6
                </tspan>
              </text>
              <text
                id="6.5"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="858.532" y="643.248">
                  6.5
                </tspan>
              </text>
              <text
                id="7"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="882.985" y="643.248">
                  7
                </tspan>
              </text>
              <text
                id="7.5"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="902.414" y="643.248">
                  7.5
                </tspan>
              </text>
              <text
                id="8"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="926.243" y="643.248">
                  8
                </tspan>
              </text>
              <text
                id="8.5"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="946.454" y="643.248">
                  8.5
                </tspan>
              </text>
              <text
                id="9"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="971.315" y="643.248">
                  9
                </tspan>
              </text>
              <text
                id="9.5"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="991.683" y="643.248">
                  9.5
                </tspan>
              </text>
              <text
                id="10"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="1017.74" y="643.248">
                  10
                </tspan>
              </text>
              <text
                id="1"
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="8"
                letter-spacing="0em"
              >
                <tspan x="612.805" y="643.27">
                  1
                </tspan>
              </text>
            </g>
            <text
              id="10 %"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="8"
              letter-spacing="0em"
            >
              <tspan x="546.414" y="607.27">
                10 %
              </tspan>
            </text>
            <text
              id="20 %"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="8"
              letter-spacing="0em"
            >
              <tspan x="545.352" y="583.27">
                20 %
              </tspan>
            </text>
            <text
              id="30 %"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="8"
              letter-spacing="0em"
            >
              <tspan x="545.25" y="557.27">
                30 %
              </tspan>
            </text>
            <text
              id="40 %"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="8"
              letter-spacing="0em"
            >
              <tspan x="545.023" y="531.27">
                40 %
              </tspan>
            </text>
            <text
              id="50 %"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="8"
              letter-spacing="0em"
            >
              <tspan x="545.172" y="506.27">
                50 %
              </tspan>
            </text>
            <text
              id="50 %_2"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Regular"
              font-size="8"
              letter-spacing="0em"
            >
              <tspan x="661.383" y="513.277">
                50 %
              </tspan>
            </text>
            <text
              id="25 %"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Regular"
              font-size="8"
              letter-spacing="0em"
            >
              <tspan x="708.211" y="576.277">
                25 %
              </tspan>
            </text>
            <text
              id="10 %_2"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Regular"
              font-size="8"
              letter-spacing="0em"
            >
              <tspan x="871.773" y="613.277">
                10 %
              </tspan>
            </text>
            <text
              id="20 %_2"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Regular"
              font-size="8"
              letter-spacing="0em"
            >
              <tspan x="923.617" y="587.277">
                20 %
              </tspan>
            </text>
            <text
              id="30 %_2"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Regular"
              font-size="8"
              letter-spacing="0em"
            >
              <tspan x="974.508" y="562.277">
                30 %
              </tspan>
            </text>
            <text
              id="40 %_2"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Regular"
              font-size="8"
              letter-spacing="0em"
            >
              <tspan x="1019.27" y="539.277">
                40 %
              </tspan>
            </text>
            <text
              id="60 %"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="8"
              letter-spacing="0em"
            >
              <tspan x="544.961" y="480.27">
                60 %
              </tspan>
            </text>
            <text
              id="70 %"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="8"
              letter-spacing="0em"
            >
              <tspan x="545.766" y="455.27">
                70 %
              </tspan>
            </text>
            <text
              id="75 %"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Regular"
              font-size="8"
              letter-spacing="0em"
            >
              <tspan x="613.477" y="441.277">
                75 %
              </tspan>
            </text>
            <text
              id="80 %"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="8"
              letter-spacing="0em"
            >
              <tspan x="545.039" y="429.27">
                80 %
              </tspan>
            </text>
            <text
              id="90 %"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="8"
              letter-spacing="0em"
            >
              <tspan x="544.938" y="404.27">
                90 %
              </tspan>
            </text>
            <text
              id="100 %"
              fill="black"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="8"
              letter-spacing="0em"
            >
              <tspan x="541.352" y="378.27">
                100 %
              </tspan>
            </text>
            <path id="Line 55" d="M590 373L774.405 629.707" stroke="black" />
            <path id="Line 56" d="M1025 528.5L820 630.5" stroke="black" />
            <path id="Line 57" d="M569 604.5L1024 604.5" stroke="black" />
            <path id="Line 58" d="M569 580L1024 580" stroke="black" />
            <path id="Line 59" d="M569 554.5L1024 554.5" stroke="black" />
            <path id="Line 60" d="M569 529L1024 529" stroke="black" />
            <path id="Line 61" d="M569 503.5L1024 503.5" stroke="black" />
            <path id="Line 62" d="M569 478L1024 478" stroke="black" />
            <path id="Line 63" d="M569 452.5L1024 452.5" stroke="black" />
            <path id="Line 64" d="M569 427L1024 427" stroke="black" />
            <path id="Line 65" d="M569 401.5L1024 401.5" stroke="black" />
            <path id="Line 66" d="M569 373L1024 373" stroke="black" />
            <g id="Group 38">
              <rect
                id="Rectangle 21"
                opacity="0.5"
                x="569"
                y="373"
                width="21"
                height="258"
                fill="black"
                fill-opacity="0.6"
              />
              <text
                id="Niedriges oder verlorenes Steuersignal"
                transform="matrix(0 -1 1 0 572 610)"
                fill="white"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="12"
                letter-spacing="0em"
              >
                <tspan x="0.0957031" y="10.9043">
                  Niedriges oder verlorenes Steuersignal
                </tspan>
              </text>
            </g>
            <text
              id="Wechselbereich von KÃ¼hlung zu Heizung"
              transform="matrix(0 -1 1 0 789 617)"
              fill="white"
              xmlSpace="preserve"
              style="white-space: pre"
              font-family="Averta-Semibold"
              font-size="12"
              letter-spacing="0em"
            >
              <tspan x="0" y="10.9043">
                Wechselbereich von Kühlung zu Heizung
              </tspan>
            </text>
          </g>
        </g>
      </g>
      <g id="6-wege-ventil-hotspot">
        <rect
          onClick={dialogSechsWegeVentil}
          id="sechs-wege-ventil-hotspot"
          x="26"
          y="17"
          width="449"
          height="689"
          fill="black"
          fill-opacity="0"
        />
        <rect
          onClick={dialogHCE}
          id="hce-hotspot"
          x="519"
          y="18"
          width="528"
          height="298"
          fill="black"
          fill-opacity="0"
        />
        <rect
          onClick={dialogTable}
          id="tabelle-hotspot"
          x="519"
          y="360"
          width="528"
          height="346"
          fill="black"
          fill-opacity="0"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_17_876"
          x="23"
          y="16"
          width="455"
          height="694"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.454483 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_17_876"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_17_876"
            result="shape"
          />
        </filter>
        <pattern
          id="patternoslejk0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_17_876"
            transform="scale(0.00323625 0.00248139)"
          />
        </pattern>
        <pattern
          id="patternoslejk1"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image1_17_876"
            transform="scale(0.0026738 0.00259067)"
          />
        </pattern>
        <filter
          id="filter1_d_17_876"
          x="516"
          y="16"
          width="534"
          height="304"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.454483 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_17_876"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_17_876"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_17_876"
          x="516"
          y="358"
          width="534"
          height="352"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.454483 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_17_876"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_17_876"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_17_876">
          <rect
            width="424.374"
            height="644.338"
            fill="white"
            transform="translate(38 33)"
          />
        </clipPath>
        <image
          id="image0_17_876"
          width="309"
          height="403"
          xlinkHref={AbbildungSechsWegeVentil}
        />
        <image id="image1_17_876" width="374" height="386" xlinkHref={FCCS} />
      </defs>
    </svg>
  );
}
