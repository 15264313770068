export default function SidebarIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_63_2)">
        <path
          d="M2 5.66667C2 4.69421 2.38631 3.76158 3.07394 3.07394C3.76158 2.38631 4.69421 2 5.66667 2H27.6667C28.6391 2 29.5718 2.38631 30.2594 3.07394C30.947 3.76158 31.3333 4.69421 31.3333 5.66667V27.6667C31.3333 28.6391 30.947 29.5718 30.2594 30.2594C29.5718 30.947 28.6391 31.3333 27.6667 31.3333H5.66667C4.69421 31.3333 3.76158 30.947 3.07394 30.2594C2.38631 29.5718 2 28.6391 2 27.6667V5.66667Z"
          stroke="black"
          stroke-width="2.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.166 2V31.3333"
          stroke="black"
          stroke-width="2.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_63_2">
          <rect width="33" height="33" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
