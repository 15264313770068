import { Grid } from "@material-ui/core";
import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QBodyText from "../../../../../../components/QBodyText";
import QList from "../../../../../../components/QList";
import CenteredImage from "../../../../../../components/CenteredImage";

// Images
import BackgroundImage from "../../../../../../assets/welcome/2021/0206/iStock_74577563_XXXLARGE.webp"
import MehrfamilienhausAudioImage from "../../../../../../assets/welcome/2021/0206/Mehrfamilienhaus_Audio.webp"

export default function MehrfamilienhausAudio() {
  return (
    <Slide
      background={{
        src: BackgroundImage,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          target: "_blank",
          link: "https://www.busch-jaeger.de/online-katalog/detail/2CKA008300A0324",
        },
        {
          type: "pdf",
          img: "preisbeispiel",
          path: "../../../../../../assets/pdf/preisbeispiele/Busch-Welcome/Preisbeispiel Busch-Welcome - Mehrfamilienhaus Audio.pdf",
        },
        {
          type: "galery",
          img: "info",
          images: [
            {
              src: MehrfamilienhausAudioImage,
              alt: "Anwendung Mehrfamilienhaus Audio",
            },
          ],
        },
      ]}
    >
      <CenteredImage
        src="../../../../../../assets/welcome/2021/anwendungen/mehrfamilienhausAudio/aussenstation.webp"
        alt="Außenstation Audio"
        height="60vh"
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Welcome<sup>®</sup>
              </>
            }
            subTitle="Anwendung Mehrfamilienhaus Audio"

          />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={4}>
              <QBodyText>Funktionales Design mit intuitiver Bedienung</QBodyText>
              <QList
                title="Vorteile"
                headline="Vorteile"
                items={[
                  "Außeneinheiten aus Aluminium oder als Hinterbau-Variante",
                  "Anlagengröße bis 99 WE",
                  "Systemzentrale mit integriertem Türöffnerkontakt, Treppenhausautomat und Systemnetzteil",
                ]}
                dense
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
