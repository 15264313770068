import { h } from "preact";
import {
  Grid,
  makeStyles,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Box,
  Popover,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useModal } from "mui-modal-provider";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import root from "window-or-global";
import QDialog from "../QDialog";

const useStyles = makeStyles(() => ({
  card: {
    height: "100%",
  },
  cardText: {
    display: "flex",
    alignItems: "center",
    height: "34px",
    fontFamily: "'Averta-Bold'",
    justifyContent: (props) => props.textAlign,
    textAlign: (props) => props.textAlign,
  },
  imgWrapper: {
    maxHeight: (props) => props.cardHeight,
    overflow: (props) => props.hideOverflow,
    height: (props) => props.innerHeight,
  },
  contentFit: {
    objectFit: (props) => props.objectFitOverride,
    maxHeight: (props) => props.imageMaxHeight,
    height: (props) => props.imageHeight,
  },
}));

export default function QBoxList({
  items,
  colWidthXS,
  colWidthSM,
  colWidthMD,
  colWidthLG,
  colWidthXL,
  cardHeight,
  innerHeight,
  hideOverflow,
  objectFitOverride,
  imageMaxHeight,
  textAlign,
  imageHeight,
  containerWidth,
  alignItems,
}) {
  const classes = useStyles({
    objectFitOverride,
    imageMaxHeight,
    cardHeight,
    hideOverflow,
    textAlign,
    innerHeight,
    imageHeight,
  });
  const { showModal } = useModal();

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      justifyContent="flex-start"
      alignItems="center"
      style={{
        maxWidth: containerWidth ? containerWidth : "90%",
        margin:
          alignItems === "left"
            ? "0px auto 0px 0px"
            : alignItems === "right"
            ? "0px 0px 0px auto"
            : "0px auto",
      }}
    >
      {items.map((item, i) => {
        const action = (e) => {
          if (typeof item.menuOverlay !== "undefined") {
            const dialog = showModal(QDialog, {
              children: item.menuOverlay,
              onConfirm: () => {
                dialog.hide();
              },
              onCancel: () => {
                dialog.hide();
              },
            });
          } else if (Object.prototype.hasOwnProperty.call(item, "callback")) {
            item.callback(item, e);
          } else {
            root.open(item.link, item.target, "noopener");
          }
        };
        const extraStyle = {
          hyphens: item.hyphens ? item.hyphens : "auto",
          padding: "10px",
          display: "inline-block",
          fontSize: item.smFont
            ? "clamp(11px, 1.2vw, 1.5rem)"
            : "clamp(13px, 1.2vw, 1.5rem)",
        };

        return (
          <Grid
            className={classes.card}
            item
            xs={colWidthXS}
            sm={colWidthSM}
            md={colWidthMD}
            lg={colWidthLG}
            xl={colWidthXL}
            key={`item-${i}`}
          >
            {!Object.prototype.hasOwnProperty.call(item, "placeholder") && (
              <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                  <>
                    <Card
                      className={clsx(classes.card, item.new && "new-product")}
                      onClick={action}
                      {...(Object.prototype.hasOwnProperty.call(
                        item,
                        "popover"
                      ) && {
                        ...bindTrigger(popupState),
                      })}
                    >
                      <CardActionArea>
                        <Box className={classes.imgWrapper}>
                          <CardMedia
                            component="img"
                            image={item.img}
                            alt={item.title}
                            className={classes.contentFit}
                          />
                        </Box>
                        <CardContent
                          className={classes.cardText}
                          style={extraStyle}
                        >
                          {item.title}
                        </CardContent>
                      </CardActionArea>
                    </Card>
                    {Object.prototype.hasOwnProperty.call(item, "popover") &&
                      item.popover && (
                        <Popover
                          {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <Box p={2}>
                            <Typography onClick={popupState.close}>
                              {item.popoverContent}
                            </Typography>
                          </Box>
                        </Popover>
                      )}
                  </>
                )}
              </PopupState>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}

QBoxList.defaultProps = {
  cardHeight: "calc(100vh /  3.5 )",
  hideOverflow: "hidden",
  items: [],
  objectFitOverride: "cover",
  imageMaxHeight: "none",
  colWidthXS: 3,
  textAlign: "",
};
