import QSwiperJS from "../../../../../../../../components/QSwiperJS";
import HeizkurveFussbodenheizung from "./HeizkurveFußbodenheizung";
import ParametrierbareRaumansicht from "./ParametrierbareRaumansicht";
import Startpage from "./Startpage";
import WerteVonHeizkreis from "./WerteVonHeizkreis";
import WirkungsweiseDerRaumaufschaltung from "./WirkungsweiseDerRaumaufschaltung";
import Zweipunktregler from "./Zweipunktregler";

export default function ApplicationController() {
  return (
    <QSwiperJS>
      <Startpage />
      <HeizkurveFussbodenheizung />
      <WirkungsweiseDerRaumaufschaltung />
      <WerteVonHeizkreis />
      <ParametrierbareRaumansicht />
      <Zweipunktregler />
    </QSwiperJS>
  );
}
