/* eslint-disable */
import { Component, createRef } from "preact";
import { route } from "preact-router";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import Select from "@material-ui/core/Select";
import Cookies from "js-cookie";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {
  setDate,
  getSelectedDate,
  getSalutation,
  getFirstname,
  getLastname,
  getEmail,
  setCompany,
  setSalutation,
  setLastname,
  setEmail,
  getCompany,
  setFirstname,
} from "../../../redux/modules/setupReducer";
import { connect } from "react-redux";
import { resetConsulting } from "../../../redux/modules/reducers";
import {
  getConsultingStarted,
  setConsultingHasStarted,
  setConsultingTimeTrackingStatus,
  setConsultingTopic,
  getConsultingTopic,
} from "../../../redux/modules/consultingReducer";
import {
  getADMEmail,
  setADMRegion,
  getADMRegion,
} from "../../../redux/modules/settingsReducer";
import { toast } from "react-toastify";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as playbookAdm from "../ADMProfile/playbook-adm_pb";
import crmDB from "../../../databases/crmDB";
import APIService from "../../../api/index";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  setupPage: {
    padding: theme.spacing(4),
    margin: theme.spacing(0),
  },
  formControl: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  btnStack: {
    "& > *": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}));

class SetupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salutationLabelWidth: 0,
      companies: [],
      contacts: [],
      contact: "",
      waiting: false,
    };
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSelectChangeSalutation =
      this.handleSelectChangeSalutation.bind(this);
    this.startConsulting = this.startConsulting.bind(this);
    this.updateConsultation = this.updateConsultation.bind(this);
    this.resetConsulting = this.resetConsulting.bind(this);
    this.inputLabel = createRef();
    this.getSelectedCompanyByName = this.getSelectedCompanyByName.bind(this);
    this.setFoundContactInfo = this.setFoundContactInfo.bind(this);
    this.handleFieldChangeCompany = this.handleFieldChangeCompany.bind(this);
    this.handleFieldChangeEmail = this.handleFieldChangeEmail.bind(this);
    this.resetContact = this.resetContact.bind(this);
    this.sortCompany = this.sortCompany.bind(this);
    this.abortConsulting = this.abortConsulting.bind(this);
    this.handleTopicChange = this.handleTopicChange.bind(this);
    this.handleChangeContact = this.handleChangeContact.bind(this);
  }

  async componentDidMount() {
    this.setState({ waiting: true });
    this.setState({
      salutationLabelWidth: this.inputLabel.current.offsetWidth,
    });
    if (!this.props.consultingStarted) {
      this.handleDateChange(new Date());
    }

    const buffer = await crmDB.data.get("buffer");
    const fetchDate = await crmDB.data.get("fetchDate");

    const currentDate = new Date();

    const timeDifference = currentDate - fetchDate?.value;

    const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;

    if (timeDifference > twentyFourHoursInMilliseconds
      || buffer?.value == null
      || fetchDate?.value == null
      || window.indexedDB.databases === 'undefined') {
      this.fetchCrmProto();
    } else {
      toast.info("CRM Daten werden verarbeitet.");
      if (
        this.props.region.data != {} &&
        this.props.region.name != ""
      ) {
        try {
          const data = playbookAdm.PlaybookCrm.deserializeBinary(buffer.value);
          let companies = [];
          data
            .getStructuresMap()
            .get(this.props.region.name)
            .getCompaniesMap()
            .forEach((company) => {
              companies.push(company);
            });
          this.setState({
            companies,
          });
          this.getSelectedCompanyByName(this.props.company, companies);
          toast.dismiss();
          this.setState({ waiting: false });
        } catch {
          this.fetchCrmProto();
        }
      } else {
        console.debug("no region data");
        this.fetchCrmProto();
      }
    }
  }

  fetchCrmProto() {
    toast.info("CRM Daten werden abgerufen.");
    let headers = new Headers();
    headers.append("Authorization", `Bearer ${Cookies.get("access_token")}`);
    headers.append("Referer", window.location.href);
    let requestOptions = {
      method: "GET",
      headers,
      redirect: "follow",
    };

    fetch(`${process.env.PREACT_APP_BJECRM}structures`, requestOptions).then(
      (res) => {
        if (res.status == 200) {
          res.arrayBuffer().then((buf) => {
            if (buf === null) {
              return;
            }

            crmDB.data.update("fetchDate", { value: new Date() });
            crmDB.data.update("buffer", { value: buf });
            if (
              this.props.region.data != {} &&
              this.props.region.name != ""
            ) {
              const data = playbookAdm.PlaybookCrm.deserializeBinary(buf);
              let companies = [];
              data
                .getStructuresMap()
                .get(this.props.region.name)
                .getCompaniesMap()
                .forEach((company) => {
                  companies.push(company);
                });
              this.setState({
                companies,
              });
              this.getSelectedCompanyByName(this.props.company, companies);
              this.setState({ waiting: false });
            }
          });
        } else {
          toast.error("Keine Regionen verfügbar.");
        }
      }
    );
  }

  handleTopicChange(e) {
    this.props.setConsultingTopic(e.currentTarget.value);
  }

  handleDateChange(v) {
    this.props.setSetupField({
      id: "selectedDate",
      value: v,
    });
  }

  handleFieldChange(e) {
    this.props.setSetupField({
      id: e.currentTarget.id,
      value: e.currentTarget.value,
    });
    if (e.currentTarget.id == "company") {
      this.getSelectedCompanyByName(e.currentTarget.value);
    }
  }

  handleFieldChangeCompany(e, value) {
    this.props.setSetupField({
      id: "company",
      value,
    });
    this.getSelectedCompanyByName(value);
  }

  handleFieldChangeEmail(e, value) {
    let foundContact = this.state.contacts.find(
      (contact) => contact.getEmail() == value
    );
    if (foundContact) {
      this.setFoundContactInfo(foundContact);
    }
  }

  handleChangeContact(e, value) {
    let foundContact = this.state.contacts.find(
      (contact) => `${contact.getFirstname()} ${contact.getLastname()}` == value
    );
    if (foundContact) {
      this.setState({
        contact: `${foundContact.getFirstname()} ${foundContact.getLastname()}`,
      });
      this.setFoundContactInfo(foundContact);
    }
  }

  setFoundContactInfo(foundContact) {
    let salutation = foundContact.getSalutation();
    // in der Anrede kann auch Herrn stehen, daher die Überprüfung
    if (salutation && salutation.includes("Herr")) {
      this.props.setSetupField({
        id: "salutation",
        value: "Herr",
      });
    } else if (salutation && salutation.includes("Frau")) {
      this.props.setSetupField({
        id: "salutation",
        value: "Frau",
      });
    }
    this.props.setSetupField({
      id: "firstname",
      value: foundContact.getFirstname(),
    });
    this.props.setSetupField({
      id: "lastname",
      value: foundContact.getLastname(),
    });
    this.props.setSetupField({
      id: "email",
      value: foundContact.getEmail(),
    });
  }

  handleSelectChangeSalutation(e) {
    this.props.setSetupField({
      id: e.target.name,
      value: e.target.value,
    });
  }

  startConsulting(e) {
    e.preventDefault();
    const salutation =
      typeof window !== "undefined"
        ? document.getElementById("salutation").value
        : this.props.salutation;
    const currentMail =
      typeof window !== "undefined"
        ? document.getElementById("email").value
        : this.props.email;

    this.props.setSetupField({
      id: "email",
      value: currentMail,
    });

    this.props.setSetupField({
      id: "salutation",
      value: salutation,
    });

    let rMail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!currentMail.match(rMail)) {
      toast.error("Keine gültige Kunden E-Mail hinterlegt.");
    }

    if (!this.props.admEmail.match(rMail)) {
      toast.error("Keine gültige ADM E-Mail hinterlegt.");
    }

    if (this.props.email.match(rMail) && this.props.admEmail.match(rMail)) {
      toast.info("Beratung gestartet.");
      this.props.startConsulting();
      this.props.handleDialogClose(e);
      route("/", true);
    }
  }

  async updateConsultation(e) {
    const currentMail =
      typeof window !== "undefined"
        ? document.getElementById("email").value
        : this.props.email;

    let rMail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!currentMail.match(rMail)) {
      toast.error("Keine gültige Kunden E-Mail hinterlegt.");
    }

    if (this.props.email.match(rMail)) {
      await APIService.post(
        `api/consultation/${this.props.id}/customer`,
        {
          salutation: this.props.salutation,
          firstname: this.props.firstname,
          lastname: this.props.lastname,
          email: currentMail,
        },
        "text/plain",
        (status, data) => {
          if (status === 200) {
            // toast.info("Beratung aktualisiert.");
            this.resetConsulting(e);
            this.props.callback(this.props.id);
          }
        }
      );
    }
  }

  abortConsulting(e) {
    e.preventDefault();
    if (this.props.updateCustomer) {
      this.resetConsulting(e);
    } else {
      this.props.switchConsultingState(false);
    }
  }

  resetConsulting(e) {
    e.preventDefault();
    this.props.handleDialogClose(e);
    this.props.resetConsulting();
  }

  getSelectedCompanyByName(namestr, companies) {
    let comp = null;
    if (companies) {
      comp = companies.find(
        (company) =>
          `${company.getInfos().getName()} | (${company
            .getInfos()
            .getAddress()
            .getCity()}) | [${company.getInfos().getMccode()}]` === namestr
      );
    } else {
      comp = this.state.companies.find(
        (company) =>
          `${company.getInfos().getName()} | (${company
            .getInfos()
            .getAddress()
            .getCity()}) | [${company.getInfos().getMccode()}]` === namestr
      );
    }
    if (comp != null && comp != undefined) {
      this.setState({
        contacts: comp.getContactsList(),
        contact: "",
      });
      this.resetContact();
      if (comp.getContactsList().length == 1) {
        const contact = comp.getContactsList()[0];
        this.setState({
          contact: `${contact.getFirstname()} ${contact.getLastname()}`,
        });
        this.setFoundContactInfo(contact);
      }
    } else {
      this.setState({
        contacts: [],
        contact: "",
      });
    }
  }

  resetContact() {
    this.props.setSetupField({
      id: "firstname",
      value: "",
    });
    this.props.setSetupField({
      id: "lastname",
      value: "",
    });
    this.props.setSetupField({
      id: "email",
      value: "",
    });
  }

  sortCompany(a, b) {
    const nameA = a.getInfos().getName().toUpperCase();
    const nameB = b.getInfos().getName().toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  }

  render() {
    const classes = useStyles();
    const { salutationLabelWidth, companies, contacts, contact } = this.state;
    const {
      selectedDate,
      company,
      salutation,
      firstname,
      lastname,
      email,
      consultingStarted,
      topic,
      updateCustomer,
    } = this.props;

    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        style={{ cursor: this.state.waiting ? "wait" : "auto" }}
      >
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box marginTop="1em">
                    <Typography variant="h4">Termin</Typography>
                    <Typography>Wann und bei welchem Unternehmen?</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <DatePicker
                    label="Datum"
                    id="selectedDate"
                    value={selectedDate}
                    onChange={this.handleDateChange}
                    cancelLabel="Abbrechen"
                    format="dd.MM.yyyy"
                    animateYearScrolling
                    fullWidth
                    InputLabelProps={{ variant: "outlined" }}
                    inputVariant="outlined"
                    TextFieldComponent={(props) => <TextField {...props} />}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Autocomplete
                    id="company"
                    freeSolo
                    options={companies
                      .sort(this.sortCompany)
                      .map(
                        (option) =>
                          `${option.getInfos().getName()} | (${option
                            .getInfos()
                            .getAddress()
                            .getCity()}) | [${option.getInfos().getMccode()}]`
                      )}
                    onChange={this.handleFieldChangeCompany}
                    value={company}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Firma"
                        id="company"
                        onBlur={this.handleFieldChange}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box marginTop="1em">
                    <Typography variant="h4">Besuchsthema</Typography>
                    <Typography>Was ist der Anlass des Termins?</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <form className={classes.root} noValidate autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid container item>
                        <Grid item xs={12}>
                          <TextField
                            onBlur={this.handleTopicChange}
                            id="topic"
                            value={topic}
                            fullWidth
                            label="Thema"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box marginTop="1em">
                <Typography variant="h4">Kunde</Typography>
                <Typography>Wen treffen Sie?</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <form className={classes.root} noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid container spacing={2} item>
                    {contacts.length > 1 && (
                      <>
                        <Grid item xs={4} />
                        <Grid item xs={8}>
                          <Autocomplete
                            id="contact"
                            freeSolo
                            options={contacts.map(
                              (option) =>
                                `${option.getFirstname()} ${option.getLastname()}`
                            )}
                            onChange={this.handleChangeContact}
                            value={contact}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`Mögliche Kontakte (${contacts.length})`}
                                id="contactField"
                                onBlur={this.handleChangeContact}
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel
                          htmlFor="salutation"
                          ref={this.inputLabel}
                          id="salutation-select-outlined-label"
                        >
                          Anrede
                        </InputLabel>
                        <Select
                          native
                          labelId="salutation-select-outlined-label"
                          id="salutation"
                          value={salutation}
                          onChange={this.handleSelectChangeSalutation}
                          labelWidth={salutationLabelWidth}
                          inputProps={{
                            name: "salutation",
                            id: "salutation",
                          }}
                        >
                          <option value="Herr">Herr</option>
                          <option value="Frau">Frau</option>
                        </Select>
                      </FormControl>
                      {/* <TextField id="outlined-basic" onChange={this.handleFieldChange} id="salutation" value={salutation} fullWidth={true} label="Anrede" variant="outlined" /> */}
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        label="Vorname"
                        id="firstname"
                        value={firstname}
                        onBlur={this.handleFieldChange}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        label="Nachname"
                        id="lastname"
                        value={lastname}
                        onBlur={this.handleFieldChange}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  <Grid container item>
                    <Grid item xs={12}>
                      <TextField
                        label="E-Mail"
                        type="email"
                        id="email"
                        value={email}
                        onBlur={this.handleFieldChange}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
        {consultingStarted == null && (
          <Box
            className={classes.btnStack}
            display="flex"
            justifyContent="center"
          >
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={this.abortConsulting}
            >
              Zurück
            </Button>
            {updateCustomer ? (
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={this.updateConsultation}
              >
                Aktualisieren
              </Button>
            ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={this.startConsulting}
                >
                  Starten
                </Button>
              )}
          </Box>
        )}
      </Box>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    region: getADMRegion(state),
    selectedDate: getSelectedDate(state),
    company: getCompany(state) || ownProps.company,
    salutation: getSalutation(state) || ownProps.salutation || "Herr",
    firstname: getFirstname(state) || ownProps.firstname || "",
    lastname: getLastname(state) || ownProps.lastname || "",
    email: getEmail(state) || ownProps.email || "",
    admEmail: getADMEmail(state),
    consultingStarted: getConsultingStarted(state),
    region: getADMRegion(state),
    topic: getConsultingTopic(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSetupField(valueSet) {
    switch (valueSet.id) {
      case "selectedDate":
        {
          dispatch(setDate(valueSet.value));
        }
        break;
      case "company":
        {
          dispatch(setCompany(valueSet.value));
        }
        break;
      case "salutation":
        {
          dispatch(setSalutation(valueSet.value));
        }
        break;
      case "firstname":
        {
          dispatch(setFirstname(valueSet.value));
        }
        break;
      case "lastname":
        {
          dispatch(setLastname(valueSet.value));
        }
        break;
      case "email":
        {
          dispatch(setEmail(valueSet.value));
        }
        break;
      case "region":
        {
          dispatch(setADMRegion(valueSet.value));
        }
        break;
    }
  },
  startConsulting() {
    dispatch(setConsultingHasStarted());
    dispatch(setConsultingTimeTrackingStatus(true));
  },
  resetConsulting() {
    dispatch(resetConsulting());
  },
  setADMRegion(region) {
    dispatch(setADMRegion(region));
  },
  setConsultingTopic(topic) {
    dispatch(setConsultingTopic(topic));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SetupPage);
