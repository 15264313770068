import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../components/QHeader";
import BetterImage from "../../../../../../../../components/BetterImage";
import ParametrierbareRaumansichtSVG from "./ParametrierbareRaumansichtSVG/index";

export default function ParametrierbareRaumansicht() {
  return (
    <Slide background={{ alignment: "left" }}>
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader title="Beispiel: Parametrierbare Raumansicht" />
        </Grid>
        <Grid item>
          <BetterImage
            src={<ParametrierbareRaumansichtSVG />}
            alt="Beispiel: Parametrierbare Raumansicht"
          />
        </Grid>
      </Grid>
    </Slide>
  );
}
