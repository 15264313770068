// Images Schulung
import ImageSchulung1 from "../../assets/energiemonitoring/schulung/1.webp";
import ImageSchulung2 from "../../assets/energiemonitoring/schulung/2.webp";
import ImageSchulung3 from "../../assets/energiemonitoring/schulung/3.webp";
import ImageSchulung4 from "../../assets/energiemonitoring/schulung/4.webp";
import ImageSchulung5 from "../../assets/energiemonitoring/schulung/5.webp";
import ImageSchulung6 from "../../assets/energiemonitoring/schulung/6.webp";
import ImageSchulung7 from "../../assets/energiemonitoring/schulung/7.webp";
import ImageSchulung8 from "../../assets/energiemonitoring/schulung/8.webp";

// Images Marketing
import ImageMarketing1 from "../../assets/energiemonitoring/marketing/1.webp";
import ImageMarketing2 from "../../assets/energiemonitoring/marketing/2.webp";
import ImageMarketing3 from "../../assets/energiemonitoring/marketing/3.webp";
import ImageMarketing4 from "../../assets/energiemonitoring/marketing/4.webp";
import ImageMarketing5 from "../../assets/energiemonitoring/marketing/5.webp";
import ImageMarketing6 from "../../assets/energiemonitoring/marketing/6.webp";
import ImageMarketing7 from "../../assets/energiemonitoring/marketing/7.webp";
import ImageMarketing8 from "../../assets/energiemonitoring/marketing/8.webp";
import ImageMarketing9 from "../../assets/energiemonitoring/marketing/9.webp";

export const energiemonitoringSchulungItems = {
  title: "Schulung",
  items: [
    {
      title: "Wissen in 3 Minuten",
      img: ImageSchulung1,
      link: {
        href: "https://www.youtube.com/watch?v=51NuV8cZgL8",
        target: "_blank",
      },
    },
    {
      title: "TechnikTalk",
      img: ImageSchulung2,
      link: {
        href: "https://www.youtube.com/watch?v=hjAxY6Qi60E",
        target: "_blank",
      },
    },
    {
      title: "Messen, Überwachen, Optimieren",
      img: ImageSchulung3,
      link: {
        href: "https://library.e.abb.com/public/083d742fdacd47ee9b7540608db6a1c3/9AKK107991A0792_Rev_C_12_Messen_Ueberwachen_Optimieren_DE_Elektrospicker_102020.pdf?x-sign=IrxC6ZN0ptRPD8HqJwkQuAVbItKNsucZJv3ZbQd3qWBKS752bO6PB0hsIuWBADK1",
        target: "_blank",
      },
    },
    {
      title: "MID vs Drittmengenabgrenzung",
      img: ImageSchulung4,
      link: {
        href: "https://library.e.abb.com/public/3af68e9a235f4f859dc7ca353502c698/9AKK108466A5513_Rev_A_32_MID_Drittmengenabgrenzung_DE_ElektroSpicker_022022.pdf?x-sign=m7bpvo03JyWXKSlv/aPCXfkcLejxwMAwoDfrdRU2x0THAEgEBgSPvZTH3aBF7ogn",
        target: "_blank",
      },
    },
    {
      title: "Energieoptimierung in der Praxis",
      img: ImageSchulung5,
      link: {
        href: "https://stromkompass.de/blindleistung/einsparungspotenziale-erkennen-energieoptimierung-in-der-praxis",
        target: "_blank",
      },
    },
    {
      title: "Inbetriebnahme von InSite und M4M",
      img: ImageSchulung6,
      link: {
        href: "https://www.youtube.com/playlist?list=PLW1uFXqHQgucY4YxG0MZUPbHzV3mnWLdo",
        target: "_blank",
      },
    },
    {
      title: "Inbetriebnahme Energiezähler",
      img: ImageSchulung7,
      link: {
        href: "https://www.youtube.com/watch?v=4fiN9PmFA88&list=PLW1uFXqHQgufzBJA0wCbM8R3AQIQk8ukT&index=8",
        target: "_blank",
      },
    },
    {
      title: "Breakers Wissen Kompakt",
      img: ImageSchulung8,
      link: {
        href: "https://www.youtube.com/playlist?list=PLW1uFXqHQgueuI_dZL2bPv52D-2_WMNcG",
        target: "_blank",
      },
    },
  ],
};

export const energiemonitoringMarketingItems = {
  title: "Marketing",
  items: [
    {
      title: "B23 312-100",
      img: ImageMarketing9,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/0FD83/product/5296",
        target: "_blank",
      },
    },
    {
      title: "Energiemonitoring-Sortiment",
      img: ImageMarketing1,
      link: {
        href: "https://search.abb.com/library/Download.aspx?DocumentID=9AKK107992A3558&LanguageCode=de&DocumentPartId=&Action=Launch",
        target: "_blank",
      },
    },
    {
      title: "Katalog Messen, Überwachen, Optimieren",
      img: ImageMarketing2,
      link: {
        href: "https://search.abb.com/library/Download.aspx?DocumentID=2CDC512074C0105&LanguageCode=de&DocumentPartId=&Action=Launch",
        target: "_blank",
      },
    },
    {
      title: "Messen, Überwachen, Optimieren",
      img: ImageMarketing3,
      link: {
        href: "https://library.e.abb.com/public/083d742fdacd47ee9b7540608db6a1c3/9AKK107991A0792_Rev_C_12_Messen_Ueberwachen_Optimieren_DE_Elektrospicker_102020.pdf?x-sign=ZWjDXHtYcQ+d3eKsYkk+kBeVMq6+8RMOgXEsGgZ7Uua7bK5N4V10N0M4V/ppSMvR",
        target: "_blank",
      },
    },
    {
      title: "Planungshandbuch",
      img: ImageMarketing4,
      link: {
        href: "https://www.busch-jaeger.de/mediathek?tx_solr%5Bfilter%5D%5B0%5D=media_type%3Akataloge-broschueren&tx_solr%5Bq%5D=planungshandbuch",
        target: "_blank",
      },
    },
    {
      title: "Energiemonitoring für die Unterverteilung",
      img: ImageMarketing5,
      link: {
        href: "https://search.abb.com/library/Download.aspx?DocumentID=9AKK108467A0363&LanguageCode=de&DocumentPartId=&Action=Launch",
        target: "_blank",
      },
    },
    {
      title: "ABB Ability™ Niederspannungsangebot",
      img: ImageMarketing6,
      link: {
        href: "https://search.abb.com/library/Download.aspx?DocumentID=9AKK108466A2610&LanguageCode=de&DocumentPartId=&Action=Launch",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          Broschüre
          <br />
          DIN VDE 0100-801
        </>
      ),
      img: ImageMarketing7,
      link: {
        href: "https://search.abb.com/library/Download.aspx?DocumentID=2CDC002198B0101&LanguageCode=de&DocumentPartId=&Action=Launch",
        target: "_blank",
      },
    },
    {
      title: "Energiemonitoringlösung InSite",
      img: ImageMarketing8,
      link: {
        href: "https://new.abb.com/low-voltage/de/produkte/installationsgeraete/messgeraete",
        target: "_blank",
      },
    },
  ],
};
