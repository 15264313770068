import { h } from "preact";
import { forwardRef } from "preact/compat";
import { useState } from "preact/hooks";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Box, DialogContent, DialogTitle } from "@material-ui/core";
import QAppBar from "../QAppBar";
const margin = "2rem";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: 0,
    margin: margin,
  },
  previewImage: {
    maxWidth: "100%",
    maxHeight: "100%",
  }
}));

const Transition = forwardRef((props, ref, height) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function BetterImage({ src, alt, height, width, align }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  function openDialog(e) {
    setOpen(true);
  }

  const closeDialog = (e) => {
    setOpen(false);
  };

  const imageObject =
    typeof src === "string" ? <img className={classes.previewImage} src={src} alt={alt} /> : src;

  return (
    <>
      <Box
        sx={{
          maxWidth: "100%",
          height: height || "63dvh",
          width: width || "initial",
          display: align ? "flex" : "inherit",
          justifyContent: align || "inherit",
        }}
        onClick={openDialog}
      >
        {imageObject}
      </Box>
      <Dialog
        id="betterImageDialog"
        fullScreen
        open={open}
        onClose={closeDialog}
        TransitionComponent={Transition}
      >
        <QAppBar text={alt} closeHandler={closeDialog} />
        <DialogContent className={classes.dialogContent}>
          <TransformWrapper>
            <TransformComponent>
              <Box
                width={`calc(100dvw - 4rem)`}
                height={`calc(100dvh - 64px - 4rem)`}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {imageObject}
              </Box>
            </TransformComponent>
          </TransformWrapper>
        </DialogContent>
      </Dialog>
    </>
  );
}
