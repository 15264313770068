import React from "react";
import PDFPrinter from "./PDFPrinter";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";

// Icons
import DeleteIcon from "@material-ui/icons/Delete";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import GetAppIcon from "@material-ui/icons/GetApp";
import SidebarIcon from "../SvgIcons/SidebarIcon";
import clsx from "clsx";
import ShareIcon from "../SvgIcons/ShareIcon";
import PrintIcon from "../SvgIcons/PrintIcon";
import { downloadFile } from "../../utils/downloadFunction";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: theme.shadows[10],
  },
  center: {
    display: "flex",
    alignItems: "center",
  },
  right: {
    display: "flex",
    alignItems: "right",
  },
  container: {
    zIndex: 100,
    position: "fixed",
    bottom: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    left: "24px",
    right: "24px",
    bottom: "24px",
  },
}));

export default function ControlPanel(props) {
  const classes = useStyles();
  const { pageNumber, numPages, setPageNumber, scale, setScale } = props;
  const buttonSize = "medium";

  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;

  const firstPageClass = isFirstPage ? "disabled" : "clickable";
  const lastPageClass = isLastPage ? "disabled" : "clickable";

  const goToFirstPage = () => {
    if (!isFirstPage) setPageNumber(1);
  };
  const goToPreviousPage = () => {
    if (!isFirstPage) setPageNumber(pageNumber - 1);
  };
  const goToNextPage = () => {
    if (!isLastPage) setPageNumber(pageNumber + 1);
  };
  const goToLastPage = () => {
    if (!isLastPage) setPageNumber(numPages);
  };

  const onPageChange = (e) => {
    const { value } = e.target;
    setPageNumber(Number(value));
  };

  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOutClass = isMinZoom ? "disabled" : "clickable";
  const zoomInClass = isMaxZoom ? "disabled" : "clickable";

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  const shareFile = () => {
    if (navigator.share) {
      navigator
        .share({
          title: props.path.split("/").pop(),
          url: props.path,
        })
        .catch(console.error);
    } else {
      // fallback
    }
  };

  function printFile() {
    const newWindow = window.open(props.path);
    newWindow.onload = function () {
      newWindow.print();
    };
  }

  return (
    <Box className={classes.container}>
      {numPages > 1 ? (
        <Box className={clsx(classes.root, classes.left)} p={1}>
          <IconButton
            color="primary"
            size={buttonSize}
            onClick={() => props.toggleDrawer()}
          >
            <SidebarIcon height="24" width="24" />
          </IconButton>
        </Box>
      ) : (
        <Box width={navigator.share ? "160px" : "112"}></Box>
      )}
      <Box className={clsx(classes.root, classes.center)} p={1}>
        {numPages > 1 && (
          <>
            <IconButton
              color="primary"
              onClick={goToFirstPage}
              disabled={isFirstPage}
            >
              <SkipPreviousIcon />
            </IconButton>
            <IconButton
              color="primary"
              size={buttonSize}
              onClick={goToPreviousPage}
              disabled={isFirstPage}
            >
              <NavigateBeforeIcon />
            </IconButton>
            <Typography>
              {pageNumber} / {numPages}
            </Typography>
            <IconButton
              color="primary"
              size={buttonSize}
              onClick={goToNextPage}
              disabled={isLastPage}
            >
              <NavigateNextIcon />
            </IconButton>
            <IconButton
              color="primary"
              size={buttonSize}
              onClick={goToLastPage}
              disabled={isLastPage}
            >
              <SkipNextIcon />
            </IconButton>
            <Divider orientation="vertical" flexItem />
          </>
        )}
        <IconButton
          color="primary"
          size={buttonSize}
          onClick={zoomOut}
          disabled={isMinZoom}
        >
          <ZoomOutIcon />
        </IconButton>
        <Typography>{(scale * 100).toFixed()} %</Typography>
        <IconButton
          color="primary"
          size={buttonSize}
          onClick={zoomIn}
          disabled={isMaxZoom}
        >
          <ZoomInIcon />
        </IconButton>
      </Box>
      <Box className={clsx(classes.root, classes.right)} p={1}>
        {window.navigator.standalone ? (
          <IconButton
            color="primary"
            onClick={() => downloadFile(props.path)}
            size={buttonSize}
          >
            <ShareIcon width="24px" height="24px" />
          </IconButton>
        ) : (
          <>
            <IconButton color="primary" onClick={printFile} size={buttonSize}>
              <PrintIcon width="20px" height="20px" />
            </IconButton>
            <IconButton
              color="primary"
              href={props.path}
              download={true}
              size={buttonSize}
            >
              <GetAppIcon />
            </IconButton>
            {navigator.share && (
              <IconButton color="primary" size={buttonSize} onClick={shareFile}>
                <ShareIcon width="24px" height="24px" />
              </IconButton>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
