const SET_SEMINAR_APPOINTMENT = "playbook/welcomeip/SET_SEMINAR_APPOINTMENT";
const SET_NOTES = "playbook/welcomeip/SET_NOTES";
const SET_SEMINAR_NOTE = "playbook/welcomeip/SET_SEMINAR_NOTE";
const SET_ASSET = "playbook/welcomeip/SET_ASSET";

export const initialState = {
  seminarAppointment: {
    description: "Termin für das Seminar",
    value: null,
    type: "info",
    orderID: null,
  },
  assetFlyer: {
    description: "Flyer",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1719",
  },
  assetBuschWelcomeIPStarterSet: {
    description: "Busch-Welcome® IP Starterset",
    value: 0,
    type: "info",
    orderID: null,
  },
  assetRollUp: {
    description: "Roll Up",
    value: 0,
    type: "info",
    orderID: null,
  },
  seminarNote: {
    description: "Notizen für das Seminar",
    value: "",
    type: "info",
    orderID: null,
  },
  notes: {
    description: "Notizen für das Kapitel",
    value: "",
    type: "info",
    orderID: null,
  },
};

const welcomeip = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEMINAR_APPOINTMENT: {
      return {
        ...state,
        seminarAppointment: {
          ...state.seminarAppointment,
          value: action.seminarAppointment,
        },
      };
    }
    case SET_NOTES: {
      return {
        ...state,
        notes: {
          ...state.notes,
          value: action.notes,
        },
      };
    }
    case SET_SEMINAR_NOTE: {
      return {
        ...state,
        seminarNote: {
          ...state.seminarNote,
          value: action.note,
        },
      };
    }
    case SET_ASSET: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          value: action.value,
        },
      };
    }
    default:
      return state;
  }
};

//actions
export const setSeminarAppointment = (seminarAppointment) => ({
  type: SET_SEMINAR_APPOINTMENT,
  seminarAppointment,
});

export const setAsset = (id, value) => ({
  type: SET_ASSET,
  id,
  value,
});

export const setWelcomeIPNotes = (notes) => ({
  type: SET_NOTES,
  notes,
});

export const setSeminarNote = (note) => ({
  type: SET_SEMINAR_NOTE,
  note,
});

//selector
export const getSeminarAppointment = (state) =>
  state.welcomeip.seminarAppointment;
export const getWelcomeIPNotes = (state) => state.welcomeip.notes;
export const getSeminarNote = (state) => state.welcomeip.seminarNote;
export const getAssetFlyer = (state) => state.welcomeip.assetFlyer;
export const getAssetBuschWelcomeIPStarterSet = (state) =>
  state.welcomeip.assetBuschWelcomeIPStarterSet;
export const getAssetRollUp = (state) => state.welcomeip.assetRollUp;
export const getWelcomeIPData = (state) => state.welcomeip;

export default welcomeip;
