// Images Schulung
import ImageSchulung1 from "../../assets/lbInnovations/schulung/1.webp";
import ImageSchulung2 from "../../assets/lbInnovations/schulung/2.webp";
import ImageSchulung3 from "../../assets/lbInnovations/schulung/3.webp";
import ImageSchulung4 from "../../assets/lbInnovations/schulung/4.webp";
import ImageSchulung5 from "../../assets/lbInnovations/schulung/5.webp";
import ImageSchulung6 from "../../assets/lbInnovations/schulung/6.webp";

// Images Marketing
import ImageMarketing1 from "../../assets/lbInnovations/marketing/1.webp";
import ImageMarketing2 from "../../assets/lbInnovations/marketing/2.webp";
import ImageMarketing3 from "../../assets/lbInnovations/marketing/3.webp";

export const neuheiten2022SchulungItems = {
  title: "Schulung",
  items: [
    {
      title: "Busch-flexTronics",
      img: ImageSchulung1,
      link: {
        href: "https://www.youtube.com/watch?v=vSml8INtihQ&list=PLJSuwpTxl86hmOEMLymHXH2HK9MMV8aCG&index=4",
      },
    },
    {
      title: "Lichtschalter",
      img: ImageSchulung2,
      link: {
        href: "https://www.youtube.com/watch?v=2schd8bYApg&list=PLJSuwpTxl86hmOEMLymHXH2HK9MMV8aCG&index=5",
      },
    },
    {
      title: "Türkommunikation",
      img: ImageSchulung3,
      link: {
        href: "https://www.youtube.com/watch?v=XcYtf8a_0zo&list=PLJSuwpTxl86hmOEMLymHXH2HK9MMV8aCG&index=6",
      },
    },
    {
      title: "Busch free@home & Flex",
      img: ImageSchulung4,
      link: {
        href: "https://www.youtube.com/watch?v=UlJ1nrYu6KQ&list=PLJSuwpTxl86hmOEMLymHXH2HK9MMV8aCG&index=9",
      },
    },
    {
      title: "Busch-SmartTouch 10“",
      img: ImageSchulung5,
      link: {
        href: "https://www.youtube.com/watch?v=X8eD6RF1kAY&list=PLJSuwpTxl86hmOEMLymHXH2HK9MMV8aCG&index=11",
      },
    },
    {
      title: "Busch-Tenton",
      img: ImageSchulung6,
      link: {
        href: "https://www.youtube.com/watch?v=al5lURa8fIo&list=PLJSuwpTxl86hmOEMLymHXH2HK9MMV8aCG&index=7",
      },
    },
  ],
};
export const neuheiten2022MarketingItems = {
  title: "Marketing",
  items: [
    {
      title: "Innomag",
      img: ImageMarketing1,
      link: {
        href: "https://www.busch-jaeger.de/media/download/Broschueren/de/bje/1866_BJE_Innomag_2022.pdf",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          Broschüre
          <br />
          Verantwortung leben
        </>
      ),
      img: ImageMarketing2,
      link: {
        href: "https://www.flipedia.com/de/5120/aktuell/",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          Broschüre
          <br />
          Smarter Home
        </>
      ),
      img: ImageMarketing3,
      link: {
        href: "https://www.flipedia.com/de/00005119/aktuell/",
        target: "_blank",
      },
    },
  ],
};
