import AktorUPWireless from './Devices/AktorUPWireless'
import BuschArtLinear from './Devices/BuschArtLinear'
import BuschFlexTronicWireless from './Devices/BuschFlexTronicWireless'
import BuschOneTouch7 from './Devices/BuschOneTouch7'
import BuschVoiceControl from './Devices/BuschVoiceControl'
import BuschWaechterPRO from './Devices/BuschWaechterPRO'
import BuschWelcome from './Devices/BuschWelcome'
import BuschWelcomeIP from './Devices/BuschWelcomeIP'
import BuschWelcomeInnenstation from './Devices/BuschWelcomeInnenstation'
import ComfortPlaner from './Devices/ComfortPlaner'
import ElektrischeRaumtemperaturregler from './Devices/ElektrischeRaumtemperaturregler'
import KNXAppControl from './Devices/KNXAppControl'
import KNXHFPraesenzmelder from './Devices/KNXHFPraesenzmelder'
import SmartSwitchWireless from './Devices/SmartSwitchWireless'
import VDI from './Devices/VDI'
import Overview from './Overview'

export default function BJEOverview() {
  return [
    <Overview key="bje-overview" />,
    <BuschArtLinear key="bje-busch-art-linear" />,
    <ElektrischeRaumtemperaturregler key="bje-elektrische-raumtemperaturregler" />,
    <BuschWaechterPRO key="bje-busch-waechter-pro" />,
    <VDI key="bje-vdi" />,
    <SmartSwitchWireless key="bje-smart-switch-wireless" />,
    <AktorUPWireless key="bje-aktor-up-wireless" />,
    <BuschFlexTronicWireless key="bje-busch-flex-tronic-wireless" />,
    <BuschWelcome key="bje-busch-welcome" />,
    <BuschWelcomeInnenstation key="bje-busch-welcome-innenstation" />,
    <BuschOneTouch7 key="bje-busch-one-touch-7" />,
    <BuschWelcomeIP key="bje-busch-welcome-ip" />,
    <BuschVoiceControl key="bje-busch-voice-control" />,
    <ComfortPlaner key="bje-comfort-planer" />,
    <KNXAppControl key="bje-knx-app-control" />,
    <KNXHFPraesenzmelder key="bje-knx-hf-praesenzmelder" />,
  ]
}
