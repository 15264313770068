import { Grid } from "@material-ui/core";
import Slide from "../../../../../components/Slide";
import QHeader from "../../../../../components/QHeader";
import QBodyText from "../../../../../components/QBodyText";
import QList from "../../../../../components/QList";

export default function Systemvorteile() {
  return (
    <Slide background>
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader title="Systemvorteile KNX" subTitle="Informationen" />
        </Grid>
        <Grid item xs={9}>
          <QBodyText>
            ClimaECO ist die durchgängige Automatisierungs- lösung für Heizung,
            Lüftung und Klima (HLK) in Zweckgebäuden, basierend auf dem
            bewährten ABB i-bus<sup>®</sup> KNX System. Eine Lösung, die Raum-
            automatisierung und HLK-Primäranlagen nahtlos in ein System
            integriert.
          </QBodyText>
          <QBodyText>
            Dadurch ergibt sich nur ein Ansprechpartner für die gesamte Gebäude-
            systemtechnik innerhalb des Gebäudes. KNX gibt es seit 1990, ist 100
            % abwärtskompatibel seit Markteinführung, derzeit werden bestehende
            KNX-Anlage mit ClimaECO erweitert.
          </QBodyText>
        </Grid>
        <Grid item xs={10}>
          <QList
            paddingLeft
            items={[
              "Dezentrale Analogein- / ausgänge ohne kritische Leitungslängen",
              "Analogausgänge werden aus der Busleitung, ohne separate Spannungsversorgung gespeist",
              "Brandschutzklappen über KNX",
              "Ein System für Feldebene und HVAC mit einem Ansprechpartner, z.B. Elektroinstallateur oder Systemintegartor",
              "Mit der Projektdatei der ETS kann die Wartung durch jeden KNX-Projektanten erfolgen",
              "Alles von einem Hersteller",
              "Notbedienung aus UV über Folientaster der Komponenten (Bedienung fast wie beim Sicherungsautomat)",
            ]}
          />
        </Grid>
      </Grid>
    </Slide>
  );
}
