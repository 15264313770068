import { h } from "preact";
import { Grid, makeStyles } from "@material-ui/core";

import Slide from "../../../../../../../components/Slide";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import QTabs from "../../../../../../../components/QTabs";
import ImageComponent from "../../../../../../../components/ImageComponent";
import QBodyText from "../../../../../../../components/QBodyText";

const useStyles = makeStyles(() => ({
  image: {
    marginTop: "-100px",
    marginLeft: "40px",
  },
}));

export default function Sensorik() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                ABB i-bus<sup>®</sup> KNX
              </>
            }
            subTitle="Produktdetails Sensorik"

          />
        </Grid>
        <QTabs
          tabs={[
            {
              head: "Busch-Präsenzmelder",
              minWidth: "auto",
              content: (
                <>
                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <QList
                        title="Präsenzmelder"
                        items={[
                          "Präsenzerfassung",
                          "Konstantlichtregelung",
                          "Flache Bauform",
                        ]}
                        dense
                      />
                      <QList
                        title="Präsenzmelder 2"
                        items={["Heizungsregelung"]}
                        dense
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <ImageComponent
                        src="../../../assets/abbknx/2021/praesenzmelder.webp"
                        alt="Präsenzmelder"
                        previewImgMaxHeight="50vh"
                      />
                    </Grid>
                  </Grid>
                </>
              ),
            },
            {
              head: "Busch-Tenton",
              minWidth: "auto",
              content: (
                <>
                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <QBodyText>
                        <strong>
                          Raumtemperaturregler Nebenstelle mit Bedienfunktion
                          6-fach Busch-Tenton
                        </strong>
                      </QBodyText>

                      <QList
                        title="Busch-Tenton"
                        items={["Bedienfunktion", "Beschriftung", "Status LED"]}
                        dense
                      />

                      <QList
                        title="Busch-Tenton 2"
                        items={[
                          "Raumtemperaturregler-Nebenstelle",
                          "Temperaturfühler",
                          "Anzeigedisplay",
                        ]}
                        dense
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <ImageComponent
                        src=".../../../assets/abbknx/Anwendung_Loesung/produkte/tenton.webp"
                        alt="Busch-Tenton"
                        previewImgMaxHeight="50vh"
                      />
                    </Grid>
                  </Grid>
                </>
              ),
            },
            {
              head: "Busch-Taster BA",
              minWidth: "auto",
              content: (
                <>
                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <QBodyText>
                        <strong>Tasterankopplung 1/2-fach KNX</strong>
                      </QBodyText>

                      <QList
                        title="Busch-Taster BA"
                        items={[
                          "Bedienfunktion",
                          "Status LED",
                          "Intuitive Bedienung",
                          "Integrierter Busankoppler",
                        ]}
                        dense
                      />

                      <QList
                        title="Busch-Taster BA 2"
                        items={["Temperaturfühler"]}
                        dense
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <ImageComponent
                        src="../../../assets/abbknx/Anwendung_Loesung/produkte/busch-taster.webp"
                        alt="Busch-Taster"
                        previewImgMaxHeight="50vh"
                      />
                    </Grid>
                  </Grid>
                </>
              ),
            },
          ]}
        />
      </Grid>
    </Slide>
  );
}
