import { useState, useEffect } from "react";

function getStoragedFilterValue() {
  // getting stored value
  const saved = localStorage.getItem("filter");
  const initial = JSON.parse(saved);
  return (
    initial || {
      field: "date",
      sort: "asc",
      company: "*",
      onlyFavorite: false,
    }
  );
}

export const useLocalFilterStorage = (defaultValue) => {
  const [value, setValue] = useState(() => {
    return getStoragedFilterValue(defaultValue);
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem("filter", JSON.stringify(value));
  }, [value]);

  return [value, setValue];
};
