const SET_SEMINAR_APPOINTMENT =
  "playbook/serviceAndTools/SET_SEMINAR_APPOINTMENT";
const SET_NOTES = "playbook/serviceAndTools/SET_NOTES";
const SET_SEMINAR_NOTE = "playbook/serviceAndTools/SET_SEMINAR_NOTE";
const SET_ASSET = "playbook/serviceAndTools/SET_ASSET";

export const initialState = {
  seminarAppointment: {
    description: "Termin für das Seminar",
    value: null,
    type: "info",
    orderID: null,
  },
  assetKatalog: {
    description: "Katalog 2023/24",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1858",
  },
  assetArchitektenBroschuere: {
    description: "Architekten Broschüre",
    value: 0,
    type: "info",
    orderID: "2CKA000001A1811",
  },
  assetProduktkatalog: {
    description:
      "Produktkatalog Gebäudesystemtechnik, ABB i-bus KNX, Einbruch-/Brandmeldetechnik",
    value: 0,
    type: "info",
    orderID: "2CKA000001A1697",
  },
  assetEnergieverteilung: {
    description: "Basissortiment Energieverteilung",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1804",
  },
  assetZaehlerplatzBerater: {
    description: "Zählerplatz Berater",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1731",
  },
  seminarNote: {
    description: "Notizen für das Seminar",
    value: "",
    type: "info",
    orderID: null,
  },
  notes: {
    description: "Notizen für das Kapitel",
    value: "",
    type: "info",
    orderID: null,
  },
};

const serviceAndTools = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEMINAR_APPOINTMENT: {
      return {
        ...state,
        seminarAppointment: {
          ...state.seminarAppointment,
          value: action.seminarAppointment,
        },
      };
    }
    case SET_NOTES: {
      return {
        ...state,
        notes: {
          ...state.notes,
          value: action.notes,
        },
      };
    }
    case SET_SEMINAR_NOTE: {
      return {
        ...state,
        seminarNote: {
          ...state.seminarNote,
          value: action.note,
        },
      };
    }
    case SET_ASSET: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          value: action.value,
        },
      };
    }
    default:
      return state;
  }
};

//actions
export const setSeminarAppointment = (seminarAppointment) => ({
  type: SET_SEMINAR_APPOINTMENT,
  seminarAppointment,
});

export const setAsset = (id, value) => ({
  type: SET_ASSET,
  id,
  value,
});

export const setServiceAndToolsNotes = (notes) => ({
  type: SET_NOTES,
  notes,
});

export const setSeminarNote = (note) => ({
  type: SET_SEMINAR_NOTE,
  note,
});

//selector
export const getSeminarAppointment = (state) =>
  state.serviceAndTools.seminarAppointment;
export const getSeminarNote = (state) => state.serviceAndTools.seminarNote;
export const getServiceAndToolsData = (state) => state.serviceAndTools;

export default serviceAndTools;
