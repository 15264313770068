// Images Schulung
import ImageSchulung1 from "../../assets/serviceandtools/schulung/seminare-bje.webp";
import ImageSchulung2 from "../../assets/_defaultAssets/Playbook/Unterstuetzung/Schulung/schulung-abb.webp";
import ImageSchulung3 from "../../assets/serviceandtools/schulung/frozen-musik.webp";
import ImageSchulung4 from "../../assets/serviceandtools/schulung/innovation-show.webp";
import ImageSchulung5 from "../../assets/serviceandtools/schulung/rundum-erfolgreich.webp";
import ImageSchulung6 from "../../assets/serviceandtools/schulung/betriebsvergleich.webp";
import ImageSchulung7 from "../../assets/serviceandtools/schulung/azubi-gesucht.webp";
import ImageSchulung8 from "../../assets/serviceandtools/schulung/suchmaschinenoptimierung.webp";

// Images Marketing
import ImageMarketing1 from "../../assets/serviceandtools/marketing/katalog.webp";
import ImageMarketing2 from "../../assets/serviceandtools/marketing/planungshandbuch.webp";
import ImageMarketing3 from "../../assets/serviceandtools/marketing/architekten-broschüre.webp";
import ImageMarketing4 from "../../assets/serviceandtools/marketing/produktkatalog-gebaeudesystemtechnik.webp";
import ImageMarketing5 from "../../assets/serviceandtools/marketing/basissortiment-energieverteilung.webp";
import ImageMarketing6 from "../../assets/serviceandtools/marketing/zaehlerplatzberater.webp";

export const serviceAndToolsSchulungItems = {
  title: "Schulung",
  items: [
    {
      title: "Seminare Busch Jaeger",
      img: ImageSchulung1,
      link: {
        href: "https://www.busch-jaeger.de/busch-jaeger/termine/busch-jaeger-seminare",
        target: "_blank",
      },
    },
    {
      title: "Seminare ABB",
      img: ImageSchulung2,
      link: {
        href: "https://new.abb.com/service/de/training/abb-university/germany/seminare",
        target: "_blank",
      },
    },
    {
      title: "Frozen Music",
      img: ImageSchulung3,
      link: {
        href: "https://www.busch-jaeger.de/frozen-music",
        target: "_blank",
      },
    },
    {
      title: "Innovation Show",
      img: ImageSchulung4,
      link: {
        href: "https://www.cya.live/channel/busch-jaeger/replays",
        target: "_blank",
      },
    },
    {
      title: "Rundum erfolgreich",
      img: ImageSchulung5,
      link: {
        href: "https://www.busch-jaeger-excellence.de/400/req-ns/F6A2D/product/5907",
        target: "_blank",
      },
    },
    {
      title: "Betriebsvergleich",
      img: ImageSchulung6,
      link: {
        href: "https://www.busch-jaeger-excellence.de/400/req-ns/55A01/product/5465",
        target: "_blank",
      },
    },
    {
      title: "Azubi gesucht",
      img: ImageSchulung7,
      link: {
        href: "https://www.busch-jaeger-excellence.de/400/req-ns/55A01/offset/2/product/5179",
        target: "_blank",
      },
    },
    {
      title: "Suchmaschinenoptimierung",
      img: ImageSchulung8,
      link: {
        href: "https://www.busch-jaeger-excellence.de/400/req-ns/55A01/offset/2/product/5178",
        target: "_blank",
      },
    },
  ],
};
export const serviceAndToolsMarketingItems = {
  title: "Marketing",
  items: [
    {
      title: "Katalog",
      img: ImageMarketing1,
      link: {
        href: "https://www.busch-jaeger-excellence.de/400/req-ns/F6A2D/product/5629",
        target: "_blank",
      },
    },
    {
      title: "Formen, die verbinden",
      img: ImageMarketing2,
      link: {
        href: "https://www.busch-jaeger.de/media/download/Broschueren/de/bje/14270_Designbroschuere_V35-Doppelseite_01.pdf",
        target: "_blank",
      },
    },
    {
      title: "Architekten Broschüre",
      img: ImageMarketing3,
      link: {
        href: "https://www.busch-jaeger-excellence.de/400/req-ns/55A01/product/5913",
        target: "_blank",
      },
    },
    {
      title: "Produktkatalog Gebäudesystemtechnik",
      img: ImageMarketing4,
      link: {
        href: "https://www.busch-jaeger-excellence.de/400/req-ns/F6A2D/product/5792",
        target: "_blank",
      },
    },
    {
      title: "Basissortiment Energieverteilung",
      img: ImageMarketing5,
      link: {
        href: "https://www.busch-jaeger-excellence.de/400/req-ns/F6A2D/product/5910",
        target: "_blank",
      },
    },
    {
      title: "ZählerplatzBerater",
      img: ImageMarketing6,
      link: {
        href: "https://www.busch-jaeger-excellence.de/400/req-ns/F6A2D/product/5580",
        target: "_blank",
      },
    },
  ],
};
