import { createRef, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { cloneElement, Children } from "preact/compat";
import ReactFullpage from "@fullpage/react-fullpage";
import { Box, Fade, Grid, Link, makeStyles } from "@material-ui/core";
import NextChapter from "../NextChapter";
import Section from "../Section";
import { useDispatch, useSelector } from "react-redux";
import { getIsADM } from "../../redux/modules/settingsReducer";
import QHeader from "../QHeader";
import { setActiveChapter } from "../../redux/modules/consultingReducer";
import Slide from "../Slide";
import QScrollBody from "../QScrollBody";
import FilledArrowLeft from "../SvgIcons/FilledArrowLeft";
import { sectionStart } from "../../static/fullpageHelpers";
import FilledHome from "../SvgIcons/FilledHome";
import { route } from "preact-router";

const useStyles = makeStyles(() => ({
  actions: {
    position: "absolute",
    bottom: "25px",
    "& img": {
      height: "25px",
      widht: "auto",
    },
  },
  logo: {
    position: "absolute",
    bottom: "12px",
    right: "12px",
    zIndex: 200,
  },
}));

export default function ChapterWrapper(props) {
  const [showBackArrow, setShowBackArrow] = useState(false);
  const [showHomeButton, setShowHomeButton] = useState(true);
  const [currentSlideState, setCurrentSlideState] = useState({});
  const fullpageRef = createRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  const section = props.chapterData.link.slice(1);
  const children = Children.toArray(props.children);

  const isAdm = useSelector((state) => getIsADM(state));
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.log("Mount chapter: ", section);
    }
    // eslint-disable-next-line no-undef
    fullpage_api.reBuild();
    dispatch(setActiveChapter(props.chapterData.chapterID));
    return () => {
      if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line no-console
        console.log("Unmount chapter: ", section);
      }
      // eslint-disable-next-line no-undef
      fullpage_api.destroy("all");
    };
  }, [dispatch, props.chapterData.chapterID, section]);

  let anchors = [];

  const slides = children.map((slide) => {
    const { anchor } = slide.props;
    anchors.push(anchor);

    const actions =
      slide.props.actions !== undefined ? slide.props.actions : [];

    const actionItems = actions.map((action, i) => (
      <Grid item key={`action-${i}`}>
        <Link href={action.link}>
          <img src={action.imgSrc} />
        </Link>
      </Grid>
    ));

    return (
      <Section key={`${section}-${anchor}`} selector={section} {...slide.props}>
        <Box style={{ height: "inherit" }} id="slideWrapper">
          {slide}
          {actionItems.length > 0 && (
            <Grid className={classes.actions} container spacing={2}>
              {actionItems}
            </Grid>
          )}
        </Box>
      </Section>
    );
  });

  const actionPageWithProps = cloneElement(props.chapterData.actionPage, {
    context: "fullpage",
  });

  const actionSection = (
    <Section selector={section}>
      <Slide
        background={{
          alignment: "left",
          width: "100%",
          height: "100%",
        }}
      >
        <Grid container direction="column" spacing={2} wrap="nowrap">
          <Grid item>
            <QHeader
              title={props.chapterData.label}
              subTitle={props.aktionslisteTitle || "Aktionsliste"}
            />
          </Grid>
          <Grid item>
            <QScrollBody maxHeight="70vh">{actionPageWithProps}</QScrollBody>
          </Grid>
        </Grid>
      </Slide>
    </Section>
  );

  if (isAdm && props.chapterData.actionPage !== null) {
    anchors.push("aktionsliste");
  }

  anchors.push("themenwahl");

  const onLeave = (origin, destination, direction) => {
    // SEKTION wird verlassen

    // AOS Animationen
    var aosElement = document.querySelectorAll("[data-aos]");
    for (var el of aosElement) {
      el.style.opacity = "0";
      el.classList.remove("aos-animate");
    }
    // zurück Button
    if (destination.anchor in currentSlideState)
      if (currentSlideState[destination.anchor] == 0) setShowBackArrow(false);
      else setShowBackArrow(true);
    else setShowBackArrow(false);

    // Home Button
    setShowHomeButton(destination.index === 0);
  };
  const onSlideLeave = (section, origin, destination, direction) => {
    var aosElement = document.querySelectorAll("[data-aos]");
    for (var el of aosElement) {
      el.style.opacity = "0";
      el.classList.remove("aos-animate");
    }
    if (destination.index == 0) setShowBackArrow(false);
    else if (destination.index > 0) setShowBackArrow(true);

    const toCompair = { [section.anchor]: destination.index };
    const temp = { ...currentSlideState, ...toCompair };

    setCurrentSlideState(temp);
  };
  const afterLoad = (origin, destination, direction) => {
    var aosElement = document.querySelectorAll("[data-aos]");
    for (var el of aosElement) {
      el.style.opacity = 1;
      el.classList.add("aos-animate");
    }
  };
  const afterSlideLoad = (section, origin, destination, direction) => {
    var aosElement = document.querySelectorAll("[data-aos]");
    for (var el of aosElement) {
      el.style.opacity = 1;
      el.classList.add("aos-animate");
    }
  };

  const routeToStartpage = () => {
    route("/", true);
  };

  return (
    <>
      <ReactFullpage
        ref={fullpageRef}
        licenseKey="82375DBB-66484CC6-A59E8B65-42EC72CD"
        navigation
        navigationPosition={"right"}
        anchors={anchors}
        onLeave={onLeave}
        onSlideLeave={onSlideLeave}
        afterLoad={afterLoad}
        afterSlideLoad={afterSlideLoad}
        showActiveTooltip={false}
        slidesNavigation
        slidesNavPosition={"bottom"}
        continuousVertical={false}
        touchSensitivity={10}
        scrollOverflowOptions={{
          click: true,
        }}
        autoScrolling
        verticalCentered={false}
        scrollingSpeed={600}
        css3
        easingcss3={"ease"}
        normalScrollElements=".MuiDialog-root, .MuiDialog-scrollPaper, .MuiAutocomplete-popper, #consultationDialog, .autoScroll, #QSrollBody"
        sectionSelector={`.${section}`}
        render={() => (
          <ReactFullpage.Wrapper>
            {slides}
            {isAdm && props.chapterData.actionPage !== null && actionSection}
            {!props.disableNextChapter && <NextChapter section={section} />}
          </ReactFullpage.Wrapper>
        )}
      />
      <Fade in={showBackArrow}>
        <Box
          position="absolute"
          top={10}
          left={10}
          padding="12px"
          zIndex={10}
          style={{ cursor: "pointer" }}
        >
          <FilledArrowLeft
            color="primary"
            fontSize="large"
            onClick={sectionStart}
          />
        </Box>
      </Fade>
      <Fade in={showHomeButton}>
        <Box
          position="absolute"
          top={10}
          left={10}
          padding="12px"
          zIndex={10}
          style={{ cursor: "pointer" }}
        >
          <FilledHome
            color="primary"
            fontSize="large"
            onClick={routeToStartpage}
          />
        </Box>
      </Fade>
      {props.logo !== null && <Box className={classes.logo}>{props.logo}</Box>}
    </>
  );
}

ChapterWrapper.defaultProps = {
  anchors: [],
  section: "",
  actions: null,
  normalScrollElements: [],
  logo: null,
};
