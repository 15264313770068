import Nebenstelle from "./files/Raumtemperaturregler_Nebenstelle.webp";
import Schalter from "./files/Schalter.webp";
import Tenton from "./files/Tenton.webp";
import TSK from "./files/TSK.webp";
import VAAS from "./files/VAAS.webp";

export default function UebersichtEinzelraumregelung() {
  const id = "uebersicht-einzelraumregelung";
  return (
    <svg
      style={{ width: "100%", height: "100%" }}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      id={id}
      viewBox="0 0 511.12 311.72"
    >
      <defs>
        <style>{`#${id} g, #${id} text, #${id} image, #${id} polyline, #${id} path, #${id} rect { pointer-events: none; }#${id} rect.hotspot, #${id} rect.click-me { pointer-events: all; }#${id} .cls-34x { fill: black; }#${id} .active-stroke { stroke-width: 3px; }#${id} text { font-weight: initial !important; font-family: Averta-Regular !important; } #${id} use { pointer-events: none; } #${id} .cls-3{stroke:#000;stroke-width:3px;}#${id} .cls-3,#${id} .cls-4{fill:none;stroke-miterlimit:10;}#${id} .cls-8{font-family:'Averta-Regular';font-size:12px;}#${id} .cls-9{fill:#fff;}#${id} .cls-15{font-family:"Averta-Bold" !important;font-size:14px;font-weight:700;}#${id} .cls-4{stroke:#e02233;stroke-width:2px;}`}</style>
      </defs>
      <image
        id="VAA_S_6.230.2.1"
        width="714"
        height="868"
        transform="translate(286.98 7.16) scale(.09)"
        xlinkHref={VAAS}
      />
      <image
        id="TSA_K230.2"
        width="386"
        height="540"
        transform="translate(365.21 33.76) scale(.1)"
        xlinkHref={TSK}
      />
      <text class="cls-15" transform="translate(256.54 106.76)">
        <tspan x="0" y="0">
          Heizungsaktor / Stellantrieb
        </tspan>
      </text>
      <text class="cls-15" transform="translate(416.07 261.85)">
        <tspan class="cls-12" x="0" y="0">
          Temperatur-
        </tspan>
        <tspan x="0" y="16.8">
          messung
        </tspan>
      </text>
      <text class="cls-8" transform="translate(192.84 59.87)">
        <tspan class="cls-2" x="0" y="0">
          VAA/S 6.230.2.1
        </tspan>
      </text>
      <text class="cls-8" transform="translate(409.66 59.87)">
        <tspan x="0" y="0">
          TSA/K230.2
        </tspan>
      </text>
      <g id="Raumtemperaturregler_Hauptstelle">
        <image
          width="1473"
          height="1805"
          transform="translate(181.19 151.01) scale(.05)"
          xlinkHref={Tenton}
        />
        <text class="cls-15" transform="translate(186.79 261.56)">
          <tspan class="cls-22" x="0" y="0">
            Raumtemperaturregler{" "}
          </tspan>
          <tspan x="0" y="16.8">
            Hauptstelle
          </tspan>
        </text>
        <text class="cls-8" transform="translate(186.79 296.64)">
          <tspan x="0" y="0">
            SBC/U6.0.11-84
          </tspan>
        </text>
      </g>
      <g id="Raumtemperaturbedienung_Nebenstelle">
        <text class="cls-15" transform="translate(16.51 261.97)">
          <tspan class="cls-22" x="0" y="0">
            Raumtemperatur-
          </tspan>
          <tspan x="0" y="16.8">
            bedienung Nebenstelle
          </tspan>
        </text>
        <text class="cls-8" transform="translate(16.51 296.64)">
          <tspan x="0" y="0">
            H8237-4B-03
          </tspan>
        </text>
        <image
          id="Raumtemperaturregler_Nebenstelle"
          width="2176"
          height="1600"
          transform="translate(16.51 133.47) scale(.07)"
          xlinkHref={Nebenstelle}
        />
      </g>
      <image
        id="Schalter"
        width="736"
        height="733"
        transform="translate(416.07 194.96) scale(.07)"
        xlinkHref={Schalter}
      />
      <path
        class="cls-3"
        d="m303.61,143.52c9.89-11.04,24.26-18,40.25-18,13.78,0,26.35,5.16,35.89,13.65"
      />
      <polygon points="371.94 140.69 371.87 139.19 379.26 138.85 378.39 131.51 379.88 131.33 380.94 140.28 371.94 140.69" />
      <path
        class="cls-3"
        d="m393.21,161.99c3.73,14.35,1.56,30.16-7.29,43.48-7.63,11.48-18.88,19.09-31.24,22.34"
      />
      <polygon points="357.75 220.47 359.03 221.25 355.23 227.59 361.83 230.92 361.15 232.26 353.11 228.2 357.75 220.47" />
      <path
        class="cls-3"
        d="m337.91,228.2c-14.67-2.16-28.38-10.32-37.2-23.66-7.6-11.5-10.22-24.83-8.39-37.47"
      />
      <polygon points="297.89 172.74 296.67 173.63 292.32 167.65 286.67 172.43 285.7 171.28 292.58 165.46 297.89 172.74" />
      <path d="m490.94,231.81v-18.98c0-2.63-2.14-4.77-4.77-4.77s-4.77,2.14-4.77,4.77v18.98c-1.64,1.4-2.61,3.45-2.61,5.62,0,4.07,3.31,7.38,7.38,7.38,1.97,0,3.82-.77,5.22-2.16,1.4-1.39,2.16-3.25,2.16-5.22,0-2.17-.96-4.23-2.61-5.62Zm-4.77,11.59c-3.29,0-5.97-2.68-5.97-5.97,0-2,1.02-3.84,2.61-4.93v-19.67c0-1.85,1.51-3.35,3.36-3.35s3.35,1.51,3.35,3.35v19.67c1.6,1.09,2.61,2.92,2.61,4.93,0,3.29-2.67,5.97-5.97,5.97Z" />
      <path d="m487.14,233.98v-21.56h-1.94v21.56c-1.51.42-2.61,1.81-2.61,3.45,0,1.97,1.6,3.58,3.58,3.58s3.58-1.6,3.58-3.58c0-1.64-1.11-3.02-2.61-3.45Z" />
      <circle class="cls-9" cx="486.19" cy="237.39" r="2.14" />
      <line class="cls-4" x1="492.61" y1="212.85" x2="500.61" y2="212.85" />
      <line class="cls-4" x1="492.61" y1="216.85" x2="500.61" y2="216.85" />
      <line class="cls-4" x1="492.61" y1="220.85" x2="496.61" y2="220.85" />
    </svg>
  );
}
