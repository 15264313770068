// Images Schulung
import ImageSchulung1 from "../../assets/cylon/schulung/1.webp";
import ImageSchulung2 from "../../assets/cylon/schulung/2.webp";
import ImageSchulung3 from "../../assets/cylon/schulung/3.webp";
import ImageSchulung4 from "../../assets/cylon/schulung/4.webp";

// Images Marketing
import ImageMarketing1 from "../../assets/cylon/marketing/1.webp";
import ImageMarketing2 from "../../assets/cylon/marketing/2.webp";
import ImageMarketing3 from "../../assets/cylon/marketing/3.webp";

export const cylonSchulungItems = {
  title: "Schulung",
  items: [
    {
      title: "Trailer",
      img: ImageSchulung1,
      link: {
        href: "https://www.youtube.com/watch?v=W-qyu_ah-oE&list=TLGGF3a_h9S1V5QyNTA1MjAyMg",
        target: "_blank",
      },
    },
    {
      title: "Webinarunterlagen",
      img: ImageSchulung2,
      link: {
        href: "https://playbook-pdfs.s3.eu-central-1.amazonaws.com/pdfs/Cylon-Webinar.pdf",
        target: "_blank",
      },
    },
    {
      title: "FusionAir Video",
      img: ImageSchulung4,
      link: {
        href: "https://www.youtube.com/watch?v=oXvKiveYBnU",
        target: "_blank",
      },
    },
  ],
};
export const cylonMarketingItems = {
  title: "Marketing",
  items: [
    {
      title: (
        <>
          Broschüre
          <br />
          Artikelliste
        </>
      ),
      img: ImageMarketing1,
      link: {
        href: "https://search.abb.com/library/Download.aspx?DocumentID=9AKK107992A7011&LanguageCode=de&DocumentPartId=&Action=Launch",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          Broschüre
          <br />
          Intelligente Gebäudeautomation
        </>
      ),
      img: ImageMarketing2,
      link: {
        href: "https://search.abb.com/library/Download.aspx?DocumentID=9AKK107992A7009&LanguageCode=de&DocumentPartId=&Action=Launch",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          Broschüre
          <br />
          Fusion Air Smart Sensor
        </>
      ),
      img: ImageMarketing3,
      link: {
        href: "https://search.abb.com/library/Download.aspx?DocumentID=9AKK108466A2148&LanguageCode=de&DocumentPartId=&Action=Launch",
        target: "_blank",
      },
    },
  ],
};
