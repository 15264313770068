import { Box, Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import ImageComponent from "../../../../../../components/ImageComponent";

//Images
import backgroundImg from "../../../../../../assets/_backgrounds/neuheiten-2024/5.webp";
import BuschOneTouchSchwarz from "../../../../../../assets/neuheiten-2024/bje/busch-onetouch7/busch-onetouch-7-schwarz.webp";
import BuschOneTouchWeiss from "../../../../../../assets/neuheiten-2024/bje/busch-onetouch7/busch-onetouch-7-weiss.webp";

function BuschOneTouch7() {
  return (
    <Slide
      background={{
        src: backgroundImg,
        backgroundPosition: "right",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title='Busch-OneTouch 7"' />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  <>
                    Neues Panel für Busch-Welcome (2-Draht) mit free@home
                    wireless/flex Anbindung, Matter Unterstützung sowie
                    eingebautem Busch-free@home<sup>®</sup> AccessPoint
                  </>,
                ]}
                dense
              />
              <Box marginTop="30px">
                <ImageComponent
                  col={5}
                  wrapperWidth="80%"
                  align="left"
                  images={[
                    {
                      src: BuschOneTouchSchwarz,
                      alt: <>Busch-OneTouch 7" | Schwarz</>,
                    },
                    {
                      src: BuschOneTouchWeiss,
                      alt: <>Busch-OneTouch 7" | Weiss</>,
                    },
                  ]}
                />
              </Box>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default BuschOneTouch7;
