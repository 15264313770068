import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  isLoggedIn,
  ssoAuth,
  fetchUserLogout,
  fetchUserDataIfNeeded,
  ssoRefresh,
} from "./redux/modules/userReducer";
import APIService from "./api/index";
import { fetchCRMData } from "./api/requests";
import {
  getCategoriesData,
  setCategories,
} from "./redux/modules/categoriesReducer";
import {
  getCrmEnabled,
  getIsADM,
  resetCustomCategories,
  setCrmEnabled,
} from "./redux/modules/settingsReducer";
import * as R from "ramda";
import root from "window-or-global";
import { toast } from "react-toastify";
import { route } from "preact-router";
import {
  getNewsData,
  setUnreadAmount,
  setNews,
} from "./redux/modules/newsReducer";
import LoadingScreen from "./routes/Chapters/LoadingScreen";
import Menu from "./components/Menu";

export default function AppWrap({ children, ...props }) {
  const dispatch = useDispatch();
  const isLoggedin = useSelector((state) => isLoggedIn(state));
  const isCrmEnabled = useSelector((state) => getCrmEnabled(state));
  const isAdm = useSelector((state) => getIsADM(state));
  const categoriesData = useSelector((state) => getCategoriesData(state));
  const newsData = useSelector((state) => getNewsData(state));
  const [isLoading, setIsLoading] = useState(true);

  const fetchActiveCategories = async () => {
    await APIService.get("api/pb/settings", (status, data) => {
      if (status === 200) {
        if (
          process.env.NODE_ENV !== "development" &&
          !R.equals(data.activeChapters, categoriesData.categories)
        ) {
          dispatch(setCategories(data.activeChapters));
          dispatch(resetCustomCategories());
          toast.info("Kapitel wurden aktualisiert");
        }

        if (!R.equals(data.crmEnabled, isCrmEnabled)) {
          dispatch(setCrmEnabled(data.crmEnabled));
        }
      }
    });
  };

  const fetchNews = async () =>
    await APIService.get("api/pb/news", (status, data) => {
      if (status === 200) {
        if (data.length > newsData.news.length) {
          dispatch(setUnreadAmount(data.length - newsData.news.length));
          dispatch(setNews(data));
        } else {
          dispatch(setUnreadAmount(0));
        }
      }
    });

  useEffect(async () => {
    if (isLoggedin && isAdm) {
      ssoRefresh();
      fetchNews();
    }
  }, [isLoggedin && isAdm]);

  useEffect(async () => {
    fetchActiveCategories();
    dispatch(fetchUserDataIfNeeded());

    if (root) {
      const urlParams = new URLSearchParams(root.location.search);
      const code = urlParams.get("code");
      if (code && code != "") {
        await ssoAuth(code).then((resp) => {
          if (resp) {
            dispatch(fetchUserDataIfNeeded());
            toast.success("Anmeldung erfolgreich.");
            fetchCRMData();
          } else {
            fetchUserLogout();
            toast.error("Anmeldung fehlgeschlagen.");
          }
          route("/", true);
        });
      }
    }

    return () => {};
  }, []);

  return (
    <Box>
      {isLoading && <LoadingScreen />}
      <Menu setIsLoading={setIsLoading} />
      {children}
    </Box>
  );
}
