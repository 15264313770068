import { h } from "preact";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Link,
  makeStyles,
} from "@material-ui/core";
import ExternalLinkIcon from "../../components/SvgIcons/ExternalLinkIcon";

const useStyles = makeStyles(() => ({
  font: {
    "& span": {
      fontSize: "16px",
      lineHeight: "1.2em",
    },
  },
  absolute: {
    position: "absolute",
    "& svg": {
      padding: "10px",
      transform: "translateX(-8px) translateY(-14px)",
    },
  },
}));

export default function QActionCheckbox({
  value,
  valueID,
  label,
  setAsset,
  dense,
  url,
}) {
  const classes = useStyles();
  const toggle = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAsset(valueID, !value);
  };

  return (
    <FormControl component="fieldset" className={classes.font}>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            value={value}
            id={valueID}
            color="primary"
          />
        }
        label={
          url ? (
            <>
              {label}
              <Link href={url} target="_blank">
                <sup className={classes.absolute}>
                  <ExternalLinkIcon height="10px" width="10px" />
                </sup>
              </Link>
            </>
          ) : (
            label
          )
        }
        onChange={toggle}
      />
    </FormControl>
  );
}
