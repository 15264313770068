// Bildelemente
import { Link } from "@material-ui/core";
import ClimaEcoGesamtuebersichtImage from "./files/ClimaEcoGesamtuebersicht.webp";

// Verlinkungen
const BuschTentonLink =
  "https://www.busch-jaeger.de/online-katalog/detail/2CKA006330A0053";
const SplitUnitLink =
  "https://new.abb.com/products/de/2CDG110207R0011/sug-u1-1";
const ElektronischerSchaltaktorLink =
  "https://new.abb.com/products/de/2CDG110116R0011/vaa-s-6-230-2-1";
const AnalogaktorLink =
  "https://new.abb.com/products/de/2CDG110086R0011/ae-a-2-1";
const ACSLink = "https://new.abb.com/products/de/2CDG110205R0011/ac-s1-1-1";
const HCCSLink = "https://new.abb.com/products/de/2CDG110219R0011/hcc-s2-1-2-1";
const BCISLink = "https://new.abb.com/products/de/2CDG110222R0011/bci-s1-1-1";

export default function ClimaEcoGesamtuebersichtPNG() {
  const id = "climaeco-gesamtuebersicht-svg";
  return (
        <svg style={{ height: "65dvh" }}viewBox="0 0 1272 675" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="grafik-gesamtuebersicht-gesamtloesung 2" clip-path="url(#clip0_221_552)">
                <rect id="ClimaEcoGesamtuebersicht 1" width="1272" height="675" fill="url(#pattern0asefdafawd)"/>
            <Link href={BuschTentonLink} target="_blank">
                <rect id="raumbediengeraete-click" x="249" y="84" width="224" height="52" rx="6" fill="black" fill-opacity="0"/>
            </Link>
            <Link href={SplitUnitLink} target="_blank">
                <rect id="split-unit-click" x="10" y="8" width="197" height="47" rx="6" fill="black" fill-opacity="0"/>
            </Link>
            <Link href={ACSLink} target="_blank">
                <rect id="acs-click" x="562" y="79" width="55" height="57" rx="6" fill="black" fill-opacity="0"/>
            </Link>
            <Link href={HCCSLink} target="_blank">
                <rect id="hccs-click" x="851" y="134" width="86" height="63" rx="6" fill="black" fill-opacity="0"/>
            </Link>
            <Link href={BCISLink} target="_blank">
                <rect id="bcis-click" x="765" y="229" width="70" height="63" rx="6" fill="black" fill-opacity="0"/>
            </Link>
            <Link href={AnalogaktorLink} target="_blank">
                <rect id="variabler-volumenstromregler-click" x="246" y="552" width="227" height="51" rx="6" fill="black" fill-opacity="0"/>
            </Link>
            <Link href={ElektronischerSchaltaktorLink} target="_blank">
                <rect id="heizkoerper-click" x="31" y="220" width="88" height="67" rx="6" fill="black" fill-opacity="0"/>
            </Link>
        </g>
        <defs>
        <pattern id="pattern0asefdafawd" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image0_221_552c" transform="matrix(0.000524109 0 0 0.000987654 0 -0.000246914)"/>
        </pattern>
        <clipPath id="clip0_221_552">
            <rect width="1271.66" height="674.67" fill="white"/>
        </clipPath>
        <image id="image0_221_552c" data-name="ClimaEcoGesamtuebersicht.png" width="1908" height="1013" xlinkHref={ClimaEcoGesamtuebersichtImage}/>
        </defs>
    </svg>
  );
}
