import { h } from "preact";
import { Grid } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";
import ImageComponent from "../../../../../../components/ImageComponent";

export default function LichtsteuerungDALI() {
  return (
    <Slide
    actions={[
      {
        type: "pdf",
        img: "preisbeispiel",
        path: "../../../../../../assets/pdf/preisbeispiele/Busch-free-at-home/Preisbeispiel Busch-free@home - Beleuchtungssteuerung.pdf",
      },
    ]}
      background={{
        width: "60%",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-free@home<sup>®</sup>
              </>
            }
            subTitle="Anwendung Lichtsteuerung"

          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={5}>
              <QList
                title="Vorteile"
                items={[
                  "Optimiertes LED-Dimmverhalten",
                  "Gruppenbildung",
                  "Kanalbündelung am Dimmaktor",
                  "Zeitfunktion",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={7}>
              <ImageComponent
                wrapperWidth="80%"
                wrapperHeight="60vh"
                src="../../../../../../assets/free-at-home/Anwendungen/anwendung-lichtsteuerung-dali.webp"
                alt="Anwendung Lichtsteuerung"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
