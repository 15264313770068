import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QList from "../../../../../../../../../components/QList";
import QHeader from "../../../../../../../../../components/QHeader";
import ImageComponent from "../../../../../../../../../components/ImageComponent";
import QGalery from "../../../../../../../../../components/QGalery";
import QDialog from "../../../../../../../../../components/QDialog";
import { useModal } from "mui-modal-provider";
import Analogaktor from "./Analogaktor";
import Brandschutzklappen from "./Brandschutzklappen";
import AES from "./AES";

export default function Volumenstromregelung({ swiperRef }) {
  const { showModal } = useModal();
  const goToSlide = (id) => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(id);
    }
  };

  const dialogGrafikVolumenstromregelung = () => {
    const d = showModal(QDialog, {
      children: (
        <QGalery
          closeHandler={() => d.hide()}
          images={[
            {
              src: "../../../../../../../../../assets/abbknx/Anwendungen/ClimaEco/rtr-stellenantrieb/diagramm-volumenstromregelung.webp",
              alt: "Diagramm einer Volumenstromregelung",
            },
          ]}
        />
      ),
    });
  };

  const dialogAnalogaktor = () => {
    const d = showModal(QDialog, {
      children: <Analogaktor />,
      onConfirm: () => {
        d.hide();
      },
      onCancel: () => {
        d.hide();
      },
    });
  };

  const dialogBrandschutzkappe = () => {
    const d = showModal(QDialog, {
      children: <Brandschutzklappen />,
      onConfirm: () => {
        d.hide();
      },
      onCancel: () => {
        d.hide();
      },
    });
  };

  const dialogAES = () => {
    const d = showModal(QDialog, {
      children: <AES />,
      onConfirm: () => {
        d.hide();
      },
      onCancel: () => {
        d.hide();
      },
    });
  };

  return (
    <Slide>
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        <Grid item>
          <QHeader
            title="Volumenstromregelung"
            subTitle="Steuern und Regeln einzelner Volumenstromboxen"
          />
        </Grid>
        <Grid item xs={8}>
          <QList
            headline="Messen"
            paddingLeft
            items={[
              "Dokumentation der Soll und Ist-Wertes durch den AC/S mittelst Datenaufzeichnung als Argumentationshilfe gegenüber dem Lüftungsbauer",
            ]}
          />
          <QList
            headline="Regeln"
            paddingLeft
            items={[
              <>
                CO<sub>2</sub> Werte werden vom Tenton im Raum gemessen.
              </>,
              <>
                Der CO<sub>2</sub> Regler im Tenton berechnet die Stellgröße.
                Diese dient als Sollwert (0-10V) für den Volumenstromregler in
                der Zu/Ab-Luft des Raumes
              </>,
            ]}
          />
        </Grid>
      </Grid>
    </Slide>
  );

  /*
  return (
    <Slide background={{ alignment: "left" }}>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title="Volumenstromregelung"
            subTitle="Steuern und Regeln einzelner Volumenstromboxen"
          />
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <QList
                headline="Messen"
                paddingLeft
                items={[
                  "Übertragen des Differenzdrucks (Wirkdrucks)",
                  "Anzeige über die analoge Schnittstelle an der Regelkomponente",
                  "Darstellung z.B. im AC/S (Trend) Echtzeitwerte",
                ]}
              />
              <QList
                headline="Vergleichen"
                paddingLeft
                items={[
                  <>
                    CO<sub>2</sub> Werte werden im Raum gemessen, wirken auf den
                    PI Regler und der ermittelt dann die Stellgröße.
                  </>,
                ]}
              />
              <QList
                headline="Stellen"
                paddingLeft
                items={[
                  "Übermittelte Stellgröße wirkt auf die Regelung in der Volumenbox. Darüber wird der Volumenstrom im Bereich der vorgegebenen Werte q vmax und q vmin verändert.",
                ]}
              />
            </Grid>
            <Grid item xs={7}>
              <div
                style={{
                  transform: "scale(1)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  width="85vw"
                  height="65vh"
                  viewBox="0 0 1214 1343"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <image
                    height="100%"
                    width="100%"
                    xlinkHref="../../../../../../../../../assets/abbknx/Anwendungen/ClimaEco/rtr-stellenantrieb/Volumenstromregelung-Grafik.webp"
                  />
                  <g
                    id="Volumenstromregelung-Grafik-Verlinkung"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                    fill-opacity="0"
                  >
                    <rect
                      onClick={dialogGrafikVolumenstromregelung}
                      class="hotspot"
                      id="rc1"
                      fill="#000000"
                      x="18"
                      y="163.5"
                      width="486"
                      height="172"
                      rx="5"
                    ></rect>
                    <rect
                      onClick={dialogAnalogaktor}
                      class="hotspot"
                      id="rc2"
                      fill="#000000"
                      x="134"
                      y="662"
                      width="253"
                      height="251"
                      rx="5"
                    ></rect>
                    <rect
                      onClick={dialogAnalogaktor}
                      class="hotspot"
                      id="rc3"
                      fill="#000000"
                      x="926"
                      y="662"
                      width="253"
                      height="251"
                      rx="5"
                    ></rect>
                    <rect
                      onClick={dialogAES}
                      class="hotspot"
                      id="rc4"
                      fill="#000000"
                      x="134"
                      y="972"
                      width="253"
                      height="251"
                      rx="5"
                    ></rect>
                    <rect
                      onClick={dialogBrandschutzkappe}
                      class="hotspot"
                      id="rc5"
                      fill="#000000"
                      x="926"
                      y="972"
                      width="253"
                      height="251"
                      rx="5"
                    ></rect>
                  </g>
                </svg>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
  */
}
