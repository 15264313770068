import { h } from "preact";
import { useEffect } from "preact/hooks";
import { useSelector } from "react-redux";
import { isLoggedIn } from "../../redux/modules/userReducer";
import root from "window-or-global";

function PrivateRoute({ component: Component, ...rest }) {
  const auth = useSelector((state) => isLoggedIn(state));

  useEffect(() => {
    if (process.env.NODE_ENV !== "development" && !auth) {
      root.location = `${process.env.PREACT_APP_SSOURL}authorize?response_type=code&client_id=${process.env.PREACT_APP_SSOCLIENTID}&popup=1`;
    }
  }, [auth]);

  if (process.env.NODE_ENV === "development") {
    return <Component {...rest} />;
  }

  return auth === true ? <Component {...rest} /> : <></>;
}

export default PrivateRoute;
