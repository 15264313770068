import { Grid } from "@material-ui/core";

import Slide from "../../../../../components/Slide";
import QHeader from "../../../../../components/QHeader";
import CenteredImage from "../../../../../components/CenteredImage";
import QList from "../../../../../components/QList";
import ImageComponent from "../../../../../components/ImageComponent";
import PDFViewer from "../../../../../components/PDFViewer";

export default function Einzelsteuerung() {
  return (
    <Slide
      actions={[
        {
          type: "pdf",
          img: "preisbeispiel",
          path: "../../../../../assets/pdf/preisbeispiele/Busch-flexTronics/Preisbeispiel Busch-flexTronics - Jalousie Einzelsteuerung.pdf",
        },
      ]}
      background={{
        width: "60%",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-flexTronics<sup>®</sup> Wireless
              </>
            }
            subTitle="Jalousie Einzelsteuerung"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={5}>
              <QList
                items={[
                  "Manuelle Bedienung",
                  "Zeitschaltuhr inkl. Astrofunktion",
                  "Presetfunktion über rechte Wippe",
                  "App-Konfiguration & Bedienung über Bluetooth Verbindung",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={7} lg={6} xl={7}>
              <ImageComponent
                alt="Anwendungsbeispiel"
                src="../../../../../assets/busch-flex/anwendungsbeispiele/1.webp"
                wrapperWidth="80%"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
