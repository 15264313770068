const SET_NOTES = "playbook/beruehrungslosesschalten/SET_NOTES";
const SET_ASSET = "playbook/beruehrungslosesschalten/SET_ASSET";

export const initialState = {
  assetFlorpostVkfBlattBuschWaechter220Masterline: {
    description: "Florpost VKF-Blatt Busch-Wächter® 220 MasterLINE",
    value: 0,
    type: "order",
    orderID: "2CKA000001A0992",
  },
  assetMusterKnxPraesenzmelder: {
    description: "Muster KNX-Präsenzmelder",
    value: 0,
    type: "info",
    orderID: null,
  },
  assetPosterWaechteraktion: {
    description: "Poster Wächteraktion",
    value: 0,
    type: "order",
    orderID: "2CKA000002A1449",
  },
  assetFlorpostWaechteraktion: {
    description: "Florpost Wächteraktion",
    value: 0,
    type: "order",
    orderID: "2CKA000002A1450",
  },
  assetTischaufsteller: {
    description: "Tischaufsteller",
    value: 0,
    type: "info",
    orderID: null,
  },
  assetBroschuereBewegeungsmelder: {
    description: "Broschüre Bewegungsmelder",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1699",
  },
  assetBroschuereEnergieSparenLeichtGemacht: {
    description: "Broschüre Energie sparen leicht gemacht",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1635",
  },
  assetFlyerSicherheitMitKomfort: {
    description: "Flyer Sicherheit mit Komfort",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1648",
  },
  notes: {
    description: "Notizen für das Kapitel",
    value: "",
    type: "info",
    orderID: null,
  },
};

const beruehrungslosesschalten = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTES: {
      return {
        ...state,
        notes: {
          ...state.notes,
          value: action.notes,
        },
      };
    }
    case SET_ASSET: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          value: action.value,
        },
      };
    }
    default:
      return state;
  }
};

//actions
export const setAsset = (id, value) => ({
  type: SET_ASSET,
  id,
  value,
});

export const setBeruehrungslosesSchaltenNotes = (notes) => ({
  type: SET_NOTES,
  notes,
});

//selector
export const getBeruehrungslosesSchaltenNotes = (state) =>
  state.comfortline.notes;
export const getBeruehrungslosesSchaltenData = (state) =>
  state.beruehrungslosesschalten;

export default beruehrungslosesschalten;
