import { Box, Grid } from "@material-ui/core";
import ImageComponent from "../../../../../../../../components/ImageComponent";
import QHeader from "../../../../../../../../components/QHeader";
import QList from "../../../../../../../../components/QList";
import Slide from "../../../../../../../../components/Slide";
import Volumenstromregelung from "../../Einzelraumregelung/rtr-und-stellenantrieb/Volumenstromregelung";
import Image from "../../../../../../../../assets/abbknx/DasSystem/sensorik/Busch-Tenton/tenton.webp";
import Anschlussdarstellung from "./Anschlussdarstellung/index";

import Fussbodenheizung from "../../../../../../../../assets/abbknx/Anwendungen/ClimaEco/rtr-stellenantrieb/heizen-2.webp";
import KuehldeckeSVG from "./KuehldeckeSVG";

export default function BuschTenton() {
  return (
    <Slide
      background
      actions={[
        {
          id: "katalog",
          type: "icon",
          img: "laptop",
          label: "Katalog",
          target: "_blank",
          link: "https://www.busch-jaeger.de/online-katalog/detail/2CKA006330A0053",
        },
        {
          id: "anschluss",
          type: "betterGalery",
          label: "Anschluss",
          img: "info",
          images: [
            {
              children: <Anschlussdarstellung />,
              alt: "Anschlussdarstellung",
            },
          ],
        },
        {
          id: "lueften",
          type: "dialog",
          label: "Lüften",
          img: "info",
          children: <Volumenstromregelung />,
        },
        {
          id: "heizen",
          type: "galery",
          label: "Heizen",
          images: [
            {
              src: Fussbodenheizung,
              alt: "Fußbodenheizung",
            },
          ],
        },
        {
          id: "kuehlen",
          type: "betterGalery",
          label: "Kühlen",
          img: "galery",
          images: [
            {
              children: <KuehldeckeSVG />,
              alt: "Kühldecke",
            },
          ],
        },
      ]}
    >
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        <Grid item>
          <QHeader title="Busch-Tenton" />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={6}>
              <QList
                paddingLeft
                items={[
                  "Raumtemperaturregler: Heizen und Kühlen",
                  <>CO<sub>2</sub>-Fühler/-Regler</>,
                  "Feuchte-Senor mit Taupunkttemperatur-berechnung",
                  "Beleuchtung: Schalten und Dimmen",
                  "Jalousiesteuerung: Auf- und Abfahren sowie Lamellenverstellung",
                  "Szenensteuerung, z.B. in Konferenzräumen für „Vortrag mit Beamer“ oder „Diskussion“, ...",
                  "Taktile Rückmeldung",
                  "Integrierter Busankoppler",
                  "Eine UP-Dose",
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <ImageComponent
                src={Image}
                alt="Busch-Tenton"
                previewImgMaxHeight="60vh"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
