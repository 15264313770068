import { Box, Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import BetterImage from "../../../../../../components/BetterImage";

import BuschWelcomeIcon from "../../../../../../assets/neuheiten-2024/bje/busch-welcome/busch-welcome-icon.webp";

function BuschWelcome() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch-Welcome® (2 Draht)" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                items={[
                  "Komplettierung der Frontplatten von Busch-Welcome, sodass das Sortiment durchgängig ist.",
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <Box
                height="75dvh"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <BetterImage
                  align="center"
                  height="auto"
                  width="20dvw"
                  src={BuschWelcomeIcon}
                  alt="Busch-Welcome®"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default BuschWelcome;
