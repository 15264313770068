import { h } from "preact";
import { useEffect, useRef } from "react";
import { Navigation, Pagination } from 'swiper';
import { Swiper } from "swiper/react/swiper-react";

const SwiperComponent = (props) => {
  SwiperComponent.defaultProps = {
    containerClass: "customized-swiper-container",
    wrapperClass: "",
    slideClass: "",
    autoHeight: false,
    color: "#009fe3",
    pagination: true,
    arrows: true,
  };
  let params = {};
  if (props.pagination) {
    params.pagination = {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    };
  }
  if (props.arrows) {
    params.navigation = {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    };
    params.renderPrevButton = () => (
      <button
        className="swiper-button-prev"
        style={{
          color: props.color,
          backgroundColor: "#ffffff00",
          border: "none",
        }}
      ></button>
    );
    params.renderNextButton = () => (
      <button
        className="swiper-button-next"
        style={{
          color: props.color,
          backgroundColor: "#ffffff00",
          border: "none",
        }}
      ></button>
    );
  }

  return (
    <Swiper
      {...params}
      containerClass={props.containerClass}
      {...props.params}
      autoHeight={props.autoHeight}
    >
      {props.children}
    </Swiper>
  );
};

export default SwiperComponent;
