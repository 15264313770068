import {
  Box,
  CircularProgress,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loadingBox: {
    backgroundColor: "white",
    height: "100dvh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    zIndex: "100",
    "& h2": {
      fontFamily: "Averta-SemiBold",
      marginBottom: ".6em",
    },
  },
}));

export default function LoadingScreen() {
  const classes = useStyles();

  return (
    <Box className={classes.loadingBox}>
      <Typography variant="h2">ADM Playbook</Typography>
      <CircularProgress color="primary" />
    </Box>
  );
}
