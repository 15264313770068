import { h } from "preact";
import { Grid } from "@material-ui/core";

import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";
import QScrollBody from "../../../../../../components/QScrollBody";
import QAccordion from "../../../../../../components/QAccordion";
import QTable from "../../../../../../components/QTable";
import ImageComponent from "../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../components/CenteredImage";
const itemlist = [
  {
    title: "Vorteile",
    content: (
      <QList
        items={[
          "Gewohnte Installation einer strukturierten EDV-Verkabelung",
          <>
            Dimensionierung der Anlage
            <br />
            (999 Gebäude, 64 Etagen, ...)
          </>,
          "Hohe Bildqualität (1024 x 600px)",
          "Interkomfunktion",
        ]}
      />
    ),
  },
  {
    title: "Türstation",
    content: (
      <QList
        items={[
          "Touch",
          "Namensliste",
          "Zutrittskontrolle mit Codeschloss",
          "RFID Leser",
        ]}
      />
    ),
  },
  {
    title: "Panel",
    content: (
      <QList
        items={[
          <>
            Anbindung an Busch-free@home<sup>®</sup> oder KNX möglich
          </>,
          "Integriertes IP-Gateway für Fernzugriff (ohne zusätzliche Kosten für zusätzliche Hardware)",
          "2 Varianten LAN/LAN und LAN/WLAN",
        ]}
      />
    ),
  },
];

export default function Einfamilienhaus() {
  return (
    <Slide
      actions={[
        {
          type: "galery",
          img: "info",
          images: [
            {
              src: "../../../../../../assets/welcome-ip/Netzwerkstruktur.webp",
              alt: "Anwendung Einfamilienhaus",
            },
          ],
        },
        {
          type: "pdf",
          img: "preisbeispiel",
          path: "../../../../../../assets/pdf/preisbeispiele/Busch-Welcome-IP/Preisbeispiel Busch-Welcome IP - Einfamilienhaus.pdf",
        },
      ]}
      background={{
        src: "../../../../../../assets/welcome-ip/Anwendungen/background/efh.webp",
      }}
    >
      <CenteredImage
        src="../../../../../../assets/welcome-ip/Anwendungen/Produkte/aussenstation-1-fach.webp"
        alt="Außenstation 1-fach"
        height="55vh"
        left="0"
        top="30px"
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Welcome<sup>®</sup> IP
              </>
            }
            subTitle="Anwendung Einfamilienhaus"
          />
        </Grid>
        <Grid container spacing={10}>
          <Grid item xs={5}>
            <QScrollBody maxHeight="60vh">
              <QAccordion items={itemlist} />
            </QScrollBody>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
