import { h, Component } from "preact";

import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import {
  getBeruehrungslosesSchaltenData,
  setBeruehrungslosesSchaltenNotes,
  setAsset,
} from "../../../redux/modules/chapterBeruehrungslosesSchalten";
import QActionPaper from "../../QActionPaper";
import QIncrementGroup from "../../QIncrementGroup";
import QTextFieldAction from "../../QTextFieldAction";

class BeruerungslosesSchalten extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      assetFlorpostVkfBlattBuschWaechter220Masterline,
      assetMusterKnxPraesenzmelder,
      assetPosterWaechteraktion,
      assetFlorpostWaechteraktion,
      assetTischaufsteller,
      assetBroschuereBewegeungsmelder,
      assetBroschuereEnergieSparenLeichtGemacht,
      assetFlyerSicherheitMitKomfort,
      notes,
      context,
    } = this.props;

    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <QActionPaper title="Unterlagen" context={context}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <QIncrementGroup
                  value={assetFlorpostVkfBlattBuschWaechter220Masterline}
                  valueID="assetFlorpostVkfBlattBuschWaechter220Masterline"
                  label={
                    <>
                      Florpost VKF-Blatt Busch-Wächter<sup>®</sup> 220
                      MasterLINE
                    </>
                  }
                  mode={1}
                  setAsset={this.props.setAsset}
                />
              </Grid>
              <Grid item xs={6}>
                <QIncrementGroup
                  value={assetTischaufsteller}
                  valueID="assetTischaufsteller"
                  label="Tischaufsteller"
                  mode={1}
                  setAsset={this.props.setAsset}
                />
              </Grid>
              <Grid item xs={6}>
                <QIncrementGroup
                  value={assetBroschuereBewegeungsmelder}
                  valueID="assetBroschuereBewegeungsmelder"
                  label="Broschüre Bewegungsmelder"
                  mode={1}
                  setAsset={this.props.setAsset}
                />
              </Grid>
              <Grid item xs={6}>
                <QIncrementGroup
                  value={assetPosterWaechteraktion}
                  valueID="assetPosterWaechteraktion"
                  label="Poster Wächteraktion"
                  mode={1}
                  setAsset={this.props.setAsset}
                />
              </Grid>
              <Grid item xs={6}>
                <QIncrementGroup
                  value={assetBroschuereEnergieSparenLeichtGemacht}
                  valueID="assetBroschuereEnergieSparenLeichtGemacht"
                  label="Broschüre Energie sparen leicht gemacht"
                  mode={1}
                  setAsset={this.props.setAsset}
                />
              </Grid>
              <Grid item xs={6}>
                <QIncrementGroup
                  value={assetFlorpostWaechteraktion}
                  valueID="assetFlorpostWaechteraktion"
                  label="Florpost Wächteraktion"
                  mode={1}
                  setAsset={this.props.setAsset}
                />
              </Grid>
              <Grid item xs={6}>
                <QIncrementGroup
                  value={assetFlyerSicherheitMitKomfort}
                  valueID="assetFlyerSicherheitMitKomfort"
                  label="Flyer Sicherheit mit Komfort"
                  mode={1}
                  setAsset={this.props.setAsset}
                />
              </Grid>
            </Grid>
          </QActionPaper>
        </Grid>
        <Grid item>
          <QActionPaper title="Notizen" context={context}>
            <QTextFieldAction
              value={notes}
              valueID="notes"
              setSetupField={this.props.setSetupField}
            />
          </QActionPaper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    assetFlorpostVkfBlattBuschWaechter220Masterline,
    assetMusterKnxPraesenzmelder,
    assetPosterWaechteraktion,
    assetFlorpostWaechteraktion,
    assetTischaufsteller,
    assetBroschuereBewegeungsmelder,
    assetBroschuereEnergieSparenLeichtGemacht,
    assetFlyerSicherheitMitKomfort,
    notes,
  } = getBeruehrungslosesSchaltenData(state);
  return {
    assetFlorpostVkfBlattBuschWaechter220Masterline:
      assetFlorpostVkfBlattBuschWaechter220Masterline.value,
    assetMusterKnxPraesenzmelder: assetMusterKnxPraesenzmelder.value,
    assetPosterWaechteraktion: assetPosterWaechteraktion.value,
    assetFlorpostWaechteraktion: assetFlorpostWaechteraktion.value,
    assetTischaufsteller: assetTischaufsteller.value,
    assetBroschuereBewegeungsmelder: assetBroschuereBewegeungsmelder.value,
    assetBroschuereEnergieSparenLeichtGemacht:
      assetBroschuereEnergieSparenLeichtGemacht.value,
    assetFlyerSicherheitMitKomfort: assetFlyerSicherheitMitKomfort.value,
    notes: notes.value,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSetupField(valueSet) {
    switch (valueSet.id) {
      case "notes":
        {
          dispatch(setBeruehrungslosesSchaltenNotes(valueSet.value));
        }
        break;
    }
  },
  setAsset(id, value) {
    dispatch(setAsset(id, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BeruerungslosesSchalten);
