import { Box, Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";

// Images
import FILSImage from "../../../../../../assets/neuheiten-2024/abb/fils/fils.webp";
import BetterImage from "../../../../../../components/BetterImage";
import QBodyText from "../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../components/ImageComponent";

function FILS() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                FI/LS 1 TE DS301C A,
                <br />
                DS301C AT Traction
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                FI/LS werden in Anwendungen eingesetzt, in denen der Schutz
                gegen Überstrom (Überlast und Kurzschluss) und gleichzeitig der
                Schutz bei Fehlerströmen notwendig ist.
              </QBodyText>

              <QBodyText>
                Der neue DS301C überzeugt mit seiner kompakten Bauform und sehr
                geringer Verlustleistung. Durch die VDE-Prüfung wird der DS301C
                zur einfachsten und kompaktesten Wahl wenn es um die Nachrüstung
                aber auch den Neubau von Wohn- und Zweckgebäuden geht.
              </QBodyText>
              <QBodyText>
                Kontinuierlicher Betrieb: Wenn einen Stromkreis einen Fehler
                aufweist, kann nach einer Auslösung durch die blaue
                Fehlerstrom-Auslöse-Anzeige zwischen einer Auslösung von
                Fehlerstrom und einer Auslösung von Überstrom (LS) unterschieden
                werden. Ein schneller Weg, um die Wartung zu beschleunigen.
              </QBodyText>
              <QBodyText>
                Für den Einsatz im Bahnverkehr und in Schienenfahrzeugen gibt es
                die dedizierte Variante DS301C AT, die die EN45545 5 (Brand-und
                Rauchschutznorm) und IEC 61373 (Schock- und
                Vibrationsfestigkeit) erfüllt.
              </QBodyText>
            </Grid>
            <Grid item xs={6}>
              <Box
                height="63dvh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <BetterImage
                  src={FILSImage}
                  height="43dvh"
                  alt="FI/LS 1 TE DS301C A,"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default FILS;
