import * as React from "react";
import { Box, Grid } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.min.css";
import "swiper/";
import QAppBar from "../QAppBar";
import { Zoom, Navigation, Pagination } from "swiper";

export default function QGalery({ images, closeHandler, startAt }) {
  const [headline, setHeadline] = React.useState(images[0].alt);

  const slideChange = (slide) => {
    setHeadline(images[slide.realIndex].alt);
  };

  return (
    <Grid
      container
      style={{
        width: "100vw",
        height: "100vh",
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12} style={{ height: "100%" }}>
        <QAppBar text={headline} closeHandler={closeHandler} />
        <Swiper
          onSlideChange={slideChange}
          modules={[Zoom, Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          zoom={{ maxRatio: 3, minRatio: 1 }}
          initialSlide={startAt}
          style={{ height: "calc(100vh - 64px)" }}
        >
          {images.map((e, i) => {
            return (
              <SwiperSlide zoom={true} style={{ height: "calc(100vh - 64px)" }}>
                <img loading="lazy" src={e.src} alt={e.alt} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Grid>
    </Grid>
  );
}
