import { Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QTable from "../../../../../../components/QTable";
import ImageComponent from "../../../../../../components/ImageComponent";

function VorverdrahteteStromkreisverteiler() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Vorverdrahtete Stromkreisverteiler Tafel 204" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                title="Eigenschaften"
                headline="Eigenschaften"
                items={[
                  "Vorverdrahtete Verteiler ab Werk, Varianten in AP- bzw. UP-Verteiler mit einem Bestellcode",
                  "Spart bereits Zeit bei der Planung, Bestellung und Installation",
                  "Größter Verdrahtungsraum auf dem Markt (bis zu 130 % beim AK600/bis zu 200 % beim UK600)",
                  "Variante mit Klemmbock: nur noch die Zuleitung und Stromkreise anschließen 'Plug&Play'",
                  "Optimaler Überspannungsschutz, Personenschutz, sowie Kurz- und Überlastschutz",
                  "4-reihig mit FI und LS",
                  "3-reihig mit der Kombination FI/LS",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default VorverdrahteteStromkreisverteiler;
