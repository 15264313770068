// Images Schulung
import ImageSchulung1 from "../../assets/busch-flex/schulung/1.webp";
import ImageSchulung2 from "../../assets/busch-flex/schulung/2.webp";
import ImageSchulung3 from "../../assets/busch-flex/schulung/3.webp";
import ImageSchulung4 from "../../assets/busch-flex/schulung/4.webp";

// Images Marketig
import ImageMarketing1 from "../../assets/busch-flex/marketing/1.webp";
import ImageMarketing2 from "../../assets/busch-flex/marketing/2.webp";
import ImageMarketing3 from "../../assets/busch-flex/marketing/3.webp";
import ImageMarketing4 from "../../assets/busch-flex/marketing/4.webp";

export const buschFlexUnterstuetzungSchulung = {
  title: "Schulung",
  items: [
    {
      title: "Webseite",
      img: ImageSchulung1,
      link: {
        href: "https://www.busch-jaeger.de/systeme/busch-freeathome-flex",
        target: "_blank",
      },
    },
    {
      title: "Smart Home Konfigurator",
      img: ImageSchulung2,
      link: {
        href: "https://smart-home-configurator.my.busch-jaeger.de/",
        target: "_blank",
      },
    },
    {
      title: "Busch-Jaeger Community",
      img: ImageSchulung3,
      link: {
        href: "https://community.busch-jaeger.de/",
        target: "_blank",
      },
    },
    {
      title: "Baustellenplaner",
      img: ImageSchulung4,
      link: {
        href: "https://www.busch-jaeger.de/konfiguratoren/baustellenplaner",
        target: "_blank",
      },
    },
  ],
};
export const buschFlexUnterstuetzungMarketing = {
  title: "Marketing",
  items: [
    {
      title: "Katalog 2023/2024",
      img: ImageMarketing1,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/5C609/product/6420",
        target: "_blank",
      },
    },
    {
      title: "Planungshandbuch",
      img: ImageMarketing2,
      link: {
        href: "https://www.busch-jaeger-excellence.de/11/product/5932",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          Broschüre
          <br />
          Busch-Wächter® flex
        </>
      ),
      img: ImageMarketing3,
      link: {
        href: "https://www.busch-jaeger-excellence.de/11/product/5939",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          Broschüre
          <br />
          Der neue Standard für flexible Installationen
        </>
      ),
      img: ImageMarketing4,
      link: {
        href: "https://www.busch-jaeger-excellence.de/11/product/6162",
        target: "_blank",
      },
    },
  ],
};
