import { h, Component, createRef } from "preact";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import {
  setADMFirstname,
  setADMLastname,
  setADMEmail,
  setADMPhoneNumber,
  setADMFaxNumber,
  setADMStreetAndNumber,
  setADMZipcode,
  setADMPlace,
  getSettingsData,
  setADMCompany,
  setADMSalutation,
  setADMRegion,
  getCrmEnabled,
} from "../../../redux/modules/settingsReducer";
import { getConsultingStarted } from "../../../redux/modules/consultingReducer";
import {
  isLoggedIn,
  fetchUserLogout,
  fetchUserDataIfNeeded,
} from "../../../redux/modules/userReducer";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { resetApp } from "../../../redux/modules/reducers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as playbookAdm from "./playbook-adm_pb";
import ChangeLogModal from "./ChangeLogModal";
import * as Sentry from "@sentry/react";
import crmDB from "../../../databases/crmDB";
import QDialogTitle from "../../../components/QDialogTitle";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  setupPage: {
    padding: theme.spacing(4),
    margin: theme.spacing(0),
  },
  resetButton: {
    display: "flex !important",
    margin: "30px auto",
  },
  formControl: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  noUnderline: {
    borderBottom: "none",
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  resetApp: {
    display: "flex !important",
    margin: "30px auto",
    float: "left",
  },
  version: {
    float: "right",
  },
}));

class ADMProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salutationLabelWidth: 0,
      regions: [],
      storageEstimate: null,
      localStorageEstimate: null,
      isFetchingCrmProto: true,
      companiesCount: 0,
      customerCount: 0,
      regionCount: 0,
      openDelete: false,
      waiting: false,
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleFieldChangeRegion = this.handleFieldChangeRegion.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.logout = this.logout.bind(this);
    this.inputLabelSalutation = createRef();
    this.inputLabelRegion = createRef();
    this.refreshCacheAndReload = this.refreshCacheAndReload.bind(this);
    this.fetchCrmProto = this.fetchCrmProto.bind(this);
    this.storageEstimateWrapper = this.storageEstimateWrapper.bind(this);
    this.formatBytes = this.formatBytes.bind(this);
    this.localStorageEstimate = this.localStorageEstimate.bind(this);
    this.feedback = this.feedback.bind(this);
    this.processCRMData = this.processCRMData.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
    this.handleDeleteTrueClose = this.handleDeleteTrueClose.bind(this);
  }

  async componentDidMount() {
    this.setState({
      salutationLabelWidth: this.inputLabelSalutation.current.offsetWidth,
      storageEstimate: await this.storageEstimateWrapper(),
      localStorageEstimate: this.localStorageEstimate(),
      waiting: true,
      isStale: false,
    });

    this.props.fetchUserDataIfNeeded();

    const buffer = await crmDB.data.get("buffer");
    const fetchDate = await crmDB.data.get("fetchDate");

    const currentDate = new Date();

    const timeDifference = currentDate - fetchDate?.value;

    const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;

    if (window.indexedDB.databases == 'undefined') {
      this.setState({ isStale: true })
    }

    if (this.props.isLoggedin && this.props.crmEnabled) {
      if (timeDifference > twentyFourHoursInMilliseconds
        || buffer?.value == null
        || fetchDate?.value == null
        || window.indexedDB.databases === 'undefined') {
        this.fetchCrmProto();
      } else {
        toast.info("CRM Daten werden verarbeitet.");
        if (
          fetchDate?.value !== null &&
          this.props.region.data != {} &&
          this.props.region.name != ""
        ) {
          this.processCRMData(buffer.value)
        } else {
          this.fetchCrmProto();
        }
      }
    }
  }

  storageEstimateWrapper() {
    if ("storage" in navigator && "estimate" in navigator.storage) {
      // We've got the real thing! Return its response.
      return navigator.storage.estimate();
    }

    if (
      "webkitTemporaryStorage" in navigator &&
      "queryUsageAndQuota" in navigator.webkitTemporaryStorage
    ) {
      // Return a promise-based wrapper that will follow the expected interface.
      return new Promise((resolve, reject) => {
        navigator.webkitTemporaryStorage.queryUsageAndQuota((usage, quota) => {
          resolve({ usage, quota });
        }, reject);
      });
    }

    // If we can't estimate the values, return a Promise that resolves with NaN.
    return Promise.resolve({ usage: NaN, quota: NaN });
  }

  localStorageEstimate() {
    let results = [];
    let _lsTotal = 0,
      _xLen,
      _x;
    for (_x in localStorage) {
      if (!localStorage.hasOwnProperty(_x)) {
        continue;
      }
      _xLen = (localStorage[_x].length + _x.length) * 2;
      _lsTotal += _xLen;
      results.push(`${_x.substr(0, 50)} = ${this.formatBytes(_xLen)}`);
    }
    return results;
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  fetchCrmProto() {
    this.setState({
      isStale: false
    })
    toast.info("CRM Daten werden abgerufen.");
    let headers = new Headers();
    headers.append("Authorization", `Bearer ${Cookies.get("access_token")}`);
    headers.append("Referer", window.location.href);
    let requestOptions = {
      method: "GET",
      headers,
      redirect: "follow",
    };

    fetch(`${process.env.PREACT_APP_BJECRM}structures`, requestOptions).then(
      (res) => {
        if (res.status == 200) {
          res.arrayBuffer().then((buf) => {
            if (buf === null) {
              return;
            }

            crmDB.data.update("fetchDate", { value: new Date() });
            crmDB.data.update("buffer", { value: buf });

            this.processCRMData(buf);
          });
        } else {
          toast.error("Keine Regionen verfügbar.");
        }
      }
    );
  }

  processCRMData(buf) {
    let regions = [];
    let region;
    const data = playbookAdm.PlaybookCrm.deserializeBinary(buf);

    try {
      data.getStructuresMap().getEntryList().forEach((regionName) => {
        region = data.getStructuresMap().get(regionName[0]);

        if (region.getCompaniesMap().getLength() != 0) {
          regions.push({
            name: regionName[0],
            renderName: `${regionName[0]} | ${region
              ?.getAdm()
              ?.getFirstname()} ${region?.getAdm()?.getLastname()}`,
            data: region,
          });
        }
      });

      let index = 0;
      if (this.props.lastname != "" && this.props.lastname != undefined) {
        index = regions.findIndex(obj => obj.renderName.includes(this.props.lastname));

        if (index != -1) {
          this.props.setSetupField({
            id: "region",
            value: regions[index],
          })
        } else {
          this.props.setSetupField({
            id: "region",
            value: regions[0],
          })
        }
      }

      let companiesCount = 0;
      let customerCount = 0;
      regions.map((region) => {
        companiesCount += region.data.getCompaniesMap().getLength();
        region.data.getCompaniesMap().forEach((company) => {
          customerCount += company.getContactsList().length;
        });
      });
      this.setState({
        regions,
        isFetchingCrmProto: false,
        regionCount: data.getStructuresMap().getLength(),
        companiesCount,
        customerCount,
      });
      toast.dismiss();
      this.setState({ waiting: false });
    } catch (error) {
      console.error(error);
      this.fetchCrmProto();
    }
  }

  handleFieldChange(e) {
    this.props.setSetupField({
      id: e.currentTarget.id,
      value: e.currentTarget.value,
    });
  }

  handleFieldChangeRegion(e) {
    const region = this.state.regions.find(
      (region) => region.renderName === e.currentTarget.value
    );
    if (region) {
      this.props.setSetupField({
        id: "region",
        value: region,
      });
    } else {
      this.props.setSetupField({
        id: "region",
        value: {
          name: e.currentTarget.value,
          renderName: e.currentTarget.value,
          data: {},
        },
      });
    }
  }

  handleSelectChange(e) {
    this.props.setSetupField({
      id: e.target.name,
      value: e.target.value,
    });
  }

  logout() {
    this.props.fetchUserLogout();
    toast.success("Abmeldung erfolgreich.");
  }

  refreshCacheAndReload() {
    this.setState({ openDelete: true });
  }

  feedback(e) {
    this.props.handleDialogClose(e);
    e.preventDefault();
    e.stopPropagation();

    Sentry.showReportDialog({
      eventId: Sentry.captureMessage("User Feedback Submitted"),
      title: "Playbook",
      subtitle: "",
      subtitle2:
        "Wenn Sie uns helfen möchten, teilen Sie uns bitte unten mit, was geschehen ist.",
      labelName: "Name",
      labelEmail: "Email",
      labelComments: "Was ist passiert?",
      labelClose: "Schließen",
      labelSubmit: "Feedback senden",
      errorGeneric:
        "Beim Einreichen Ihres Berichts ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      errorFormEntry:
        "Einige Felder waren ungültig. Bitte korrigieren Sie die Fehler und versuchen Sie es erneut.",
      successMessage: "Ihr Feedback wurde gesendet. Herzlichen Dank!",
      user: {
        email: this.props.email,
        name: `${this.props.firstname} ${this.props.lastname}`,
      },
    });
  }

  handleDeleteClose() {
    this.setState({ openDelete: false });
  }

  handleDeleteTrueClose() {
    this.setState({ openDelete: false });
    this.props.resetApp();
    this.props.fetchUserLogout();
  }

  render() {
    const classes = useStyles();
    const {
      salutationLabelWidth,
      regions,
      isFetchingCrmProto,
      storageEstimate,
      localStorageEstimate,
      regionCount,
      companiesCount,
      customerCount,
    } = this.state;
    const {
      salutation,
      firstname,
      lastname,
      email,
      phonenr,
      faxnr,
      streetandnr,
      zipcode,
      place,
      company,
      consultingStarted,
      zvphonenr,
      region,
      isLoggedin,
      crmEnabled,
    } = this.props;

    return (
      <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between" style={{ cursor: this.state.waiting ? "wait" : "auto" }}>
        <Box>
          <Box>
            <QDialogTitle title="Ihre Kontaktdaten" />
            <Grid container spacing={2}>
              <Grid item xs>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    htmlFor="salutation"
                    ref={this.inputLabelSalutation}
                    id="salutation-select-outlined-label"
                  >
                    Anrede
                  </InputLabel>
                  <Select
                    native
                    labelId="salutation-select-outlined-label"
                    id="salutation"
                    value={salutation}
                    onChange={this.handleSelectChange}
                    labelWidth={salutationLabelWidth}
                    fullWidth
                    inputProps={{
                      name: "salutation",
                      id: "salutation",
                    }}
                  >
                    <option value="Herr">Herr</option>
                    <option value="Frau">Frau</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  onBlur={this.handleFieldChange}
                  id="firstname"
                  value={firstname}
                  fullWidth
                  label="Vorname"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  onBlur={this.handleFieldChange}
                  id="lastname"
                  value={lastname}
                  fullWidth
                  label="Nachname"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  onBlur={this.handleFieldChange}
                  id="email"
                  type="email"
                  value={email}
                  disabled={isLoggedin}
                  fullWidth
                  label="E-Mail"
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  onBlur={this.handleFieldChange}
                  id="phonenr"
                  type="tel"
                  value={phonenr}
                  fullWidth
                  label="Telefon"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  onBlur={this.handleFieldChange}
                  id="faxnr"
                  type="number"
                  pattern="\d*"
                  value={faxnr}
                  fullWidth
                  label="Fax"
                  variant="outlined"
                />
              </Grid>
              {isLoggedin && crmEnabled && !isFetchingCrmProto && (
                < Grid item container>
                  <Grid item xs>
                    <Autocomplete
                      id="region"
                      options={regions.map((region) => region.renderName)}
                      value={region.renderName}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Region"
                          id="region"
                          onBlur={this.handleFieldChangeRegion}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
          <Box>
            <Grid item container>
              <Grid item xs={5}>
                <QDialogTitle title="Firma & Anschrift" />
                <Typography paragraph>
                  <span style={{ fontFamily: "Averta-Bold" }}>{company}</span>
                  <br />
                  {streetandnr}
                  <br />
                  {zipcode} {place}
                </Typography>
                <Typography paragraph>Tel.: {zvphonenr}</Typography>
              </Grid>
              {(regionCount <= 0 && this.state.isStale) && (
                <Grid item xs={7}>
                  <h1>Ihre Kundendaten sind nicht aktuell: </h1>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    style={{ marginLeft: "1rem" }}
                    onTouchStart={this.fetchCrmProto}
                    onClick={this.fetchCrmProto}
                  >
                    Kundendaten abrufen
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {isLoggedin && (
            <Typography paragraph style={{ marginBottom: 0 }}>
              Regionen: {regionCount} | Firmen: {companiesCount} | Kunden:{" "}
              {customerCount}
            </Typography>
          )}
          <Box>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={this.refreshCacheAndReload}
            >
              Anwendungsspeicher leeren
            </Button>
            {isLoggedin && (
              <Button
                variant="outlined"
                color="primary"
                size="large"
                style={{ marginLeft: "1rem" }}
                onTouchStart={this.fetchCrmProto}
                onClick={this.fetchCrmProto}
              >
                Kundendaten abrufen
              </Button>
            )}
            {isLoggedin && email?.includes("@qmarketing.de") && (
              <ChangeLogModal />
            )}
          </Box>
        </Box>
        <Dialog
          open={this.state.openDelete}
          onClose={this.handleDeleteClose}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Anwendungsspeicher leeren
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Durch das Leeren des Anwendungsspeichers werden auch alle
              pausierten Beratungen gelöscht.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={this.handleDeleteClose}
              color="primary"
              className="plaintext"
            >
              Abbrechen
            </Button>
            <Button
              onClick={this.handleDeleteTrueClose}
              color="primary"
              className="plaintext"
            >
              Löschen
            </Button>
          </DialogActions>
        </Dialog>
      </Box >
    );
  }
}

const mapStateToProps = (state) => {
  const {
    salutation,
    firstname,
    lastname,
    email,
    phonenr,
    faxnr,
    streetandnr,
    zipcode,
    place,
    company,
    zvphonenr,
    region,
  } = getSettingsData(state);
  const isLoggedin = isLoggedIn(state);
  const consultingStarted = getConsultingStarted(state);
  const crmEnabled = getCrmEnabled(state);
  return {
    salutation,
    firstname,
    lastname,
    email,
    phonenr,
    faxnr,
    streetandnr,
    zipcode,
    place,
    company,
    zvphonenr,
    region,
    consultingStarted,
    isLoggedin,
    crmEnabled,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSetupField(valueSet) {
    switch (valueSet.id) {
      case "salutation":
        {
          dispatch(setADMSalutation(valueSet.value));
        }
        break;
      case "firstname":
        {
          dispatch(setADMFirstname(valueSet.value));
        }
        break;
      case "lastname":
        {
          dispatch(setADMLastname(valueSet.value));
        }
        break;
      case "email":
        {
          dispatch(setADMEmail(valueSet.value));
        }
        break;
      case "phonenr":
        {
          dispatch(setADMPhoneNumber(valueSet.value));
        }
        break;
      case "faxnr":
        {
          dispatch(setADMFaxNumber(valueSet.value));
        }
        break;
      case "streetandnr":
        {
          dispatch(setADMStreetAndNumber(valueSet.value));
        }
        break;
      case "zipcode":
        {
          dispatch(setADMZipcode(valueSet.value));
        }
        break;
      case "place":
        {
          dispatch(setADMPlace(valueSet.value));
        }
        break;
      case "company": {
        dispatch(setADMCompany(valueSet.value));
      }
      case "region":
        {
          dispatch(setADMRegion(valueSet.value));
        }
        break;
    }
  },
  fetchUserLogout() {
    dispatch(fetchUserLogout());
  },
  resetApp() {
    dispatch(resetApp());
  },
  fetchUserDataIfNeeded() {
    dispatch(fetchUserDataIfNeeded());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ADMProfile);
