const SET_NOTES = "playbook/moderneElektroinstallation/SET_NOTES";
const SET_ASSET = "playbook/moderneElektroinstallation/SET_ASSET";

export const initialState = {
  assetLichtschalter: {
    description: "Lichtschalter Sortimentsbroschüre",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1674",
  },
  assetInnomag: {
    description: "BJE Innomag 2022",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1866",
  },
  assetBarrierefreieElektrotechnik: {
    description: "Broschüre zum Thema barrierefreie Elektrotechnik",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1832",
  },
  assetFlex: {
    description: "Busch-free@home® flex Sortimentsbroschüre",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1809",
  },
  notes: {
    description: "Notizen für das Kapitel",
    value: "",
    type: "info",
    orderID: null,
  },
};

const moderneElektroinstallation = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTES: {
      return {
        ...state,
        notes: {
          ...state.notes,
          value: action.notes,
        },
      };
    }
    case SET_ASSET: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          value: action.value,
        },
      };
    }
    default:
      return state;
  }
};

//actions
export const setAsset = (id, value) => ({
  type: SET_ASSET,
  id,
  value,
});

export const setModerneElektroinstallationNotes = (notes) => ({
  type: SET_NOTES,
  notes,
});

//selector
export const getModerneElektroinstallationNotes = (state) =>
  state.moderneElektroinstallation.notes;
export const getModerneElektroinstallationData = (state) =>
  state.moderneElektroinstallation;

export default moderneElektroinstallation;
