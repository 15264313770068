import { Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";

function DigitalerHilfsschalter() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Digitaler Hilfsschalter INS-S/H" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  "0,5 TE Baubreite",
                  "unterscheidet zwischen Fehlerauslösung und manuellem Schalten",
                  "misst die Temperatur des Schutzgerätes",
                  "via Flachbandkabel (InSite-Bus) an das Energiemonitoringsystem InSite integrierbar",
                  "erhöht die Transparenz der elektrischen Anlage",
                  "Alarmmeldungen und Automationen für Fehlerfälle können in InSite eingerichtet und ausgelöst werden",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default DigitalerHilfsschalter;
