import { Grid } from "@material-ui/core";
import backgroundIntro from "../../../../../../assets/_backgrounds/neuheiten-2024/9.webp";
import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QBodyText from "../../../../../../components/QBodyText";

export default function ComfortLineInnenausbau() {
  return (
    <Slide
      background={{
        src: backgroundIntro,
        alignment: "right",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title="Die nächste Stufe der Elektroinstallation"
            subTitle="ComfortLine Innenausbau"
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Mit dem ComfortLine Innenausbausystem kommt jetzt der nächste
                Evolutionsschritt in der Zählerschrankinstallation. Eine
                beispiellose Montagefreundlichkeit und hohe Zukunftsfähigkeit
                zeichnen das neue System aus.
              </QBodyText>
              <QList
                headline="Highlights"
                items={[
                  "50 % Zeitersparnis bei der Verdrahtung intelligenter Messsysteme",
                  "Neue doppelt isolierte Patchkabel und RJ45-Buchsen",
                  "In der Zählertragplatte integrierte Hauptleitungsabzweigklemmen",
                  "Neue Verdrahtungskanäle für das intelligente Messsystem",
                  "Ein Patchpanel für E-DAT- und Keystone-Module",
                  "50 % größere Flansche",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
