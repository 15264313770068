import { Grid } from "@material-ui/core";
import backgroundIntro from "../../../../../../assets/_backgrounds/neuheiten-2024/10.webp";
import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QBodyText from "../../../../../../components/QBodyText";

export default function MessUndWandlertechnik() {
  return (
    <Slide
      background={{
        src: backgroundIntro,
        alignment: "right",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Mess- und Wandlertechnik" subTitle="nach FNN" />
        </Grid>
        <Grid item container>
          <Grid item xs={6}>
            <QBodyText>
              Um die Energiewende schneller bestreiten zu können, sind Standards
              ein wichtiger Erfolgsfaktor für den reibungslosen Ablauf. Die
              Experten vom VDE FNN arbeiten daher an einem Regelwerk, das was
              eine Standardisierung der Mess- und Wandleranlagen beschreibt (FNN
              Hinweis).
            </QBodyText>
            <QList
              headline="Highlights"
              items={[
                "Als Standard, keine EVU spezifischen Unterscheidungen mehr",
                "Fest definierte Funktionsflächen",
                "Fest definierte Primär- und Sekundärverdrahtung",
                "Fest definierte Spannungs-, Strom- und Prüfklemmen",
              ]}
              dense
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
