import { useState } from "preact/hooks";
import { Box, Grid } from "@material-ui/core";
import { useModal } from "mui-modal-provider";

import Slide from "../../../../../../../../components/Slide";
import QTabs from "../../../../../../../../components/QTabs";
import QHeader from "../../../../../../../../components/QHeader";
import ImageComponent from "../../../../../../../../components/ImageComponent";
import PDFViewer from "../../../../../../../../components/PDFViewer";
import QSwiperJS from "../../../../../../../../components/QSwiperJS";
import BetterImage from "../../../../../../../../components/BetterImage";
import BuschTenton from "../../_GlobalSlides/DasSystem/BuschTenton";

// Busch-Tenton
import BuschTentonImage from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/Tenton/uebersicht.webp";
import BuschTentonSchaltplan from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/Tenton/schaltplan.webp";
import BuschTentonTippsUndTricks1 from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/Tenton/tipps-und-tricks.webp";
import BuschTentonTippsUndTricks2 from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/ETS/1.webp";
import BuschTentonTippsUndTricks3 from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/ETS/2.webp";

// Tasterankopplung
import TasterankopplungImage from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/Tasterankopplung/uebersicht.webp";
import TasterankopplungSchaltplan1 from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/Tasterankopplung/schaltplan.webp";
import TasterankopplungTippsUndTricks1 from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/Tasterankopplung/tipps-und-tricks.webp";
import TasterankopplungTippsUndTricks2 from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/ETS/1.webp";
import TasterankopplungTippsUndTricks3 from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/ETS/2.webp";

// Hauptstelle / Nebenstelle
import HauptstelleNebenstelleImage from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/Nebenstelle/uebersicht.webp";
import HauptstelleNebenstelleSchaltplan from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/Nebenstelle/schaltplan.webp";
import HauptstelleNebenstelleTippsUndTricks1 from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/Nebenstelle/tipps-und-tricks.webp";
import HauptstelleNebenstelleTippsUndTricks2 from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/ETS/1.webp";
import HauptstelleNebenstelleTippsUndTricks3 from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/ETS/2.webp";

// Raumtemperaturregler
import RaumtemperaturreglerImage from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/Objektregler/uebersicht.webp";
import RaumtemperaturreglerSchaltplan from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/ObjektreglerVorOrt/schaltplan.webp";
import RaumtemperaturreglerTippsUndTricks1 from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/Objektregler/tipps-und-tricks.webp";
import RaumtemperaturreglerTippsUndTricks2 from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/ETS/1.webp";
import RaumtemperaturreglerTippsUndTricks3 from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/ETS/2.webp";

// Objektregler uP
import ObjektreglerUPImage from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/ObjektreglerVorOrt/uebersicht.webp";
import ObjektreglerUPSchaltplan from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/Objektregler/schaltplan.webp";
import ObjektreglerUPTippsUndTricks1 from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/ObjektreglerVorOrt/tipps-und-tricks.webp";
import ObjektreglerUPTippsUndTricks2 from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/ETS/1.webp";
import ObjektreglerUPTippsUndTricks3 from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/ETS/2.webp";

// Präsenzmelder / APP
import PraesenzmelderAppImage from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/Praesenzmelder/uebersicht.webp";
import PraesenzmelderAppSchaltplan from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/Praesenzmelder/schaltplan.webp";
import PraesenzmelderAppTippsUndTricks1 from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/Praesenzmelder/tipps-und-tricks.webp";
import PraesenzmelderAppTippsUndTricks2 from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/ETS/1.webp";
import PraesenzmelderAppTippsUndTricks3 from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/ETS/2.webp";

// 6 Wege Ventil

// Volumenstromregler

// Volumenstromregelung
import WerteVonHeizkreis from "../../_GlobalSlides/ApplicationController/WerteVonHeizkreis";

// Einzelraumvolumenstromregelung
import Volumenstromregelung from "./Volumenstromregelung";
import SechsWegeVentil from "./SVG/SechsWegeVentil";
import VolumenstromreglerSVG from "./SVG/Volumenstromregler";

const centered = {
  maxHeight: "90vh",
  maxWidth: "90vw",
  margin: "0px auto",
  display: "block",
};

export default function Einzelraumtemperaturregelung() {
  const [activeTab, setActiveTab] = useState(0);
  const { showModal } = useModal();

  const qTabsCallback = (a) => {
    setActiveTab(a);
  };

  let actions;
  switch (activeTab) {
    case 0:
      {
        //Busch-Tenton
        actions = [
          {
            type: "pdf",
            label: "Preisbeispiel",
            path: "../../../../../../../../assets/pdf/preisbeispiele/KNX/ClimaECO/Preisbeispiel KNX ClimaECO - Tenton.pdf",
          },
          {
            type: "dialogWithButton",
            children: (
              <img
                loading="lazy"
                style={centered}
                alt="Schaltplan"
                src={BuschTentonSchaltplan}
              />
            ),
            label: "Schaltplan",
          },
          {
            type: "dialogWithButton",
            children: (
              <img
                loading="lazy"
                style={centered}
                alt="Tipps und Tricks"
                src={BuschTentonTippsUndTricks1}
              />
            ),
            label: "Tipps & Tricks",
          },
          {
            type: "dialogWithButton",
            children: (
              <QSwiperJS>
                <img
                  loading="lazy"
                  style={centered}
                  alt="Tipps und Tricks"
                  src={BuschTentonTippsUndTricks2}
                />
                <img
                  loading="lazy"
                  style={centered}
                  alt="Tipps und Tricks"
                  src={BuschTentonTippsUndTricks3}
                />
              </QSwiperJS>
            ),
            label: "ETS",
          },
          {
            type: "dialogWithButton",
            children: <BuschTenton />,
            label: "Tenton",
          },
        ];
      }
      break;
    case 1:
      {
        //Tasterankopplung
        actions = [
          {
            type: "pdf",
            label: "Preisbeispiel",
            path: "../../../../../../../../assets/pdf/preisbeispiele/KNX/ClimaECO/Preisbeispiel KNX ClimaECO - Taster BA.pdf",
          },
          {
            type: "dialogWithButton",
            children: (
              <img
                loading="lazy"
                style={centered}
                alt="Schaltplan"
                src={TasterankopplungSchaltplan1}
              />
            ),
            label: "Schaltplan",
          },
          {
            type: "dialogWithButton",
            children: (
              <img
                loading="lazy"
                style={centered}
                alt="Tipps und Tricks"
                src={TasterankopplungTippsUndTricks1}
              />
            ),
            label: "Tipps & Tricks",
          },
          {
            type: "dialogWithButton",
            children: (
              <QSwiperJS>
                <img
                  loading="lazy"
                  style={centered}
                  alt="Tipps und Tricks"
                  src={TasterankopplungTippsUndTricks2}
                />
                <img
                  loading="lazy"
                  style={centered}
                  alt="Tipps und Tricks"
                  src={TasterankopplungTippsUndTricks3}
                />
              </QSwiperJS>
            ),
            label: "ETS",
          },
        ];
      }
      break;
    case 2:
      {
        // Hauptstelle / Nebenstelle
        actions = [
          {
            type: "pdf",
            label: "Preisbeispiel",
            path: "../../../../../../../../assets/pdf/preisbeispiele/KNX/ClimaECO/Preisbeispiel KNX ClimaECO - Haupt- und Nebenstelle.pdf",
          },
          {
            type: "dialogWithButton",
            children: (
              <img
                loading="lazy"
                style={centered}
                alt="Schaltplan"
                src={HauptstelleNebenstelleSchaltplan}
              />
            ),
            label: "Schaltplan",
          },
          {
            type: "dialogWithButton",
            children: (
              <img
                loading="lazy"
                style={centered}
                alt="Tipps und Tricks"
                src={HauptstelleNebenstelleTippsUndTricks1}
              />
            ),
            label: "Tipps & Tricks",
          },
          {
            type: "dialogWithButton",
            children: (
              <QSwiperJS>
                <img
                  loading="lazy"
                  style={centered}
                  alt="Tipps und Tricks"
                  src={HauptstelleNebenstelleTippsUndTricks2}
                />
                <img
                  loading="lazy"
                  style={centered}
                  alt="Tipps und Tricks"
                  src={HauptstelleNebenstelleTippsUndTricks3}
                />
              </QSwiperJS>
            ),
            label: "ETS",
          },
          {
            type: "dialogWithButton",
            children: <BuschTenton />,
            label: "Tenton",
          },
        ];
      }
      break;
    case 3:
      {
        //Raumtemperaturregler
        actions = [
          {
            type: "pdf",
            label: "Preisbeispiel",
            path: "../../../../../../../../assets/pdf/preisbeispiele/KNX/ClimaECO/Preisbeispiel KNX ClimaECO - Raumtemperaturregler.pdf",
          },
          {
            type: "dialogWithButton",
            children: (
              <img
                loading="lazy"
                style={centered}
                alt="Schaltplan"
                src={RaumtemperaturreglerSchaltplan}
              />
            ),
            label: "Schaltplan",
          },
          {
            type: "dialogWithButton",
            children: (
              <img
                loading="lazy"
                style={centered}
                alt="Tipps und Tricks"
                src={RaumtemperaturreglerTippsUndTricks1}
              />
            ),
            label: "Tipps & Tricks",
          },
          {
            type: "dialogWithButton",
            children: (
              <QSwiperJS>
                <img
                  loading="lazy"
                  style={centered}
                  alt="Tipps und Tricks"
                  src={RaumtemperaturreglerTippsUndTricks2}
                />
                <img
                  loading="lazy"
                  style={centered}
                  alt="Tipps und Tricks"
                  src={RaumtemperaturreglerTippsUndTricks3}
                />
              </QSwiperJS>
            ),
            label: "ETS",
          },
          {
            type: "dialogWithButton",
            children: <BuschTenton />,
            label: "Tenton",
          },
        ];
      }
      break;
    case 4:
      {
        // Objektregler uP
        actions = [
          {
            type: "pdf",
            label: "Preisbeispiel",
            path: "../../../../../../../../assets/pdf/preisbeispiele/KNX/ClimaECO/Preisbeispiel KNX ClimaECO - Objektregler uP.pdf",
          },
          {
            type: "dialogWithButton",
            children: (
              <img
                loading="lazy"
                style={centered}
                alt="Schaltplan"
                src={ObjektreglerUPSchaltplan}
              />
            ),
            label: "Schaltplan",
          },
          {
            type: "dialogWithButton",
            children: (
              <img
                loading="lazy"
                style={centered}
                alt="Tipps und Tricks"
                src={ObjektreglerUPTippsUndTricks1}
              />
            ),
            label: "Tipps & Tricks",
          },
          {
            type: "dialogWithButton",
            children: (
              <QSwiperJS>
                <img
                  loading="lazy"
                  style={centered}
                  alt="Tipps und Tricks"
                  src={ObjektreglerUPTippsUndTricks2}
                />
                <img
                  loading="lazy"
                  style={centered}
                  alt="Tipps und Tricks"
                  src={ObjektreglerUPTippsUndTricks3}
                />
              </QSwiperJS>
            ),
            label: "ETS",
          },
        ];
      }
      break;
    case 5:
      {
        //Präsenzmelder / APP
        actions = [
          {
            type: "pdf",
            label: "Preisbeispiel",
            path: "../../../../../../../../assets/pdf/preisbeispiele/KNX/ClimaECO/Preisbeispiel KNX ClimaECO - Präsenzmelder-APP.pdf",
          },
          {
            type: "dialogWithButton",
            children: (
              <img
                loading="lazy"
                style={centered}
                alt="Schaltplan"
                src={PraesenzmelderAppSchaltplan}
              />
            ),
            label: "Schaltplan",
          },
          {
            type: "dialogWithButton",
            children: (
              <>
                <img
                  loading="lazy"
                  style={centered}
                  alt="Tipps und Tricks"
                  src={PraesenzmelderAppTippsUndTricks1}
                />
              </>
            ),
            label: "Tipps & Tricks",
          },
          {
            type: "dialogWithButton",
            children: (
              <QSwiperJS>
                <img
                  loading="lazy"
                  style={centered}
                  alt="Tipps und Tricks"
                  src={PraesenzmelderAppTippsUndTricks2}
                />
                <img
                  loading="lazy"
                  style={centered}
                  alt="Tipps und Tricks"
                  src={PraesenzmelderAppTippsUndTricks3}
                />
              </QSwiperJS>
            ),
            label: "ETS",
          },
          {
            type: "dialogWithButton",
            children: <BuschTenton />,
            label: "Tenton",
          },
        ];
      }
      break;
    case 7: {
      actions = [
        {
          type: "dialogWithButton",
          label: "Tipps & Tricks",
          children: <Volumenstromregelung />,
        },
        {
          type: "dialogWithButton",
          children: <BuschTenton />,
          label: "Tenton",
        },
        {
          type: "dialog",
          img: "info",
          children: <WerteVonHeizkreis />,
        },
      ];
    }
  }

  return (
    <Slide actions={actions}>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                ABB i-bus<sup>®</sup> KNX
              </>
            }
            subTitle="Das System"
          />
        </Grid>
        <Grid item container>
          <Grid item xs={12}>
            <QTabs
              callback={qTabsCallback}
              tabs={[
                {
                  head: "Busch-Tenton",
                  content: (
                    <ImageComponent
                      src={BuschTentonImage}
                      alt="tenton"
                      wrapperHeight="55vh"
                    />
                  ),
                },
                {
                  head: "Tasterankopplung",
                  content: (
                    <ImageComponent
                      src={TasterankopplungImage}
                      alt="tasterankopplung"
                      wrapperHeight="55vh"
                    />
                  ),
                },
                {
                  head: "Hauptstelle / Nebenstelle",
                  content: (
                    <ImageComponent
                      src={HauptstelleNebenstelleImage}
                      alt="universalschnittstelle"
                      wrapperHeight="55vh"
                    />
                  ),
                },
                {
                  head: "Raumtemperaturregler",
                  content: (
                    <ImageComponent
                      src={RaumtemperaturreglerImage}
                      alt="tastsensor-solo"
                      wrapperHeight="55vh"
                    />
                  ),
                },
                {
                  head: "Objektregler uP",
                  content: (
                    <ImageComponent
                      src={ObjektreglerUPImage}
                      alt="tastsensor-solo"
                      wrapperHeight="55vh"
                    />
                  ),
                },
                {
                  head: "Präsenzmelder/APP",
                  content: (
                    <ImageComponent
                      src={PraesenzmelderAppImage}
                      alt="tastsensor-solo"
                      wrapperHeight="55vh"
                    />
                  ),
                },
                {
                  head: "6 Wege Ventil",
                  content: (
                    <Box height="54dvh">
                      <SechsWegeVentil />
                    </Box>
                  ),
                },
                {
                  head: "Volumenstromregler",
                  content: (
                    <BetterImage
                      alt="Volumenstromregler"
                      height="54dvh"
                      src={<VolumenstromreglerSVG />}
                    />
                  ),
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
