import { Grid } from "@material-ui/core";
import React from "react";
import QHeader from "../../../../../../../../components/QHeader";
import QTable from "../../../../../../../../components/QTable";
import Slide from "../../../../../../../../components/Slide";

const tableContent = [
  {
    name: "Row1",
    values: [
      { label: "DALI-Gateway, Basic, 1-fach, REG" },
      { label: "1" },
      { label: "DG/S1.64.1.1" },
      { label: "491,00 €" },
    ],
  },
  {
    name: "Row2",
    values: [
      { label: "Sicherungsautomat B-Char., 6kA, 10 A, 1P" },
      { label: "1" },
      { label: "S201-B10" },
      { label: "12,00 €" },
    ],
  },
  {
    name: "Row3",
    values: [
      { label: "Hilfsschalter 1 Wechsler" },
      { label: "2" },
      { label: "S2C-H6R" },
      { label: "33,40 €" },
    ],
  },
  {
    name: "Row4",
    values: [
      {
        label: "Spannungsversorgung mit Diagnosefunktion, 640mA, REG",
      },
      { label: "1" },
      { label: "SV/S30.640.5.1" },
      { label: "359,00 €" },
    ],
  },
  {
    name: "Row5",
    values: [
      { label: "USB-Schnittstelle, REG " },
      { label: "1" },
      { label: "USB/S1.2" },
      { label: "233,00 €" },
    ],
  },
  {
    name: "Row6",
    values: [
      {
        label: "Zwischensumme für zentrale Geräte",
        fontFamily: "Averta-Bold",
        backgroundColor: "var(--grey-20)",
      },
      { label: "", backgroundColor: "var(--grey-20)" },
      { label: "", backgroundColor: "var(--grey-20)" },
      {
        label: "1.128,40 €",
        fontFamily: "Averta-Bold",
        backgroundColor: "var(--grey-20)",
      },
    ],
  },
  {
    name: "Row7",
    values: [
      {
        label:
          "Raumtemperaturregler Nebenstelle mit Bedienfunktion 6-fach, Busch-Tenton®",
      },
      { label: "1" },
      { label: "SBS/U6.0.11-84" },
      { label: "134,00 €" },
    ],
  },
  {
    name: "Row8",
    values: [
      { label: "Busch-Präsenzmelder Premium KNX, 12 Meter" },
      { label: "1" },
      { label: "6131/31-24" },
      { label: "178,00 €" },
    ],
  },
  {
    name: "Row9",
    values: [
      {
        label: "Zwischensumme für raumbezogenen Geräte",
        fontFamily: "Averta-Bold",
        backgroundColor: "var(--grey-20)",
      },
      { label: "", backgroundColor: "var(--grey-20)" },
      { label: "", backgroundColor: "var(--grey-20)" },
      {
        label: "312,00 €",
        fontFamily: "Averta-Bold",
        backgroundColor: "var(--grey-20)",
      },
    ],
  },
  {
    name: "Row10",
    values: [
      { label: "" },
      { label: "" },
      {
        label: (
          <>
            {" "}
            Preis <br />
            zzgl. MwSt.{" "}
          </>
        ),
        fontFamily: "Averta-Bold",
        backgroundColor: "var(--grey-20)",
      },
      {
        label: (
          <>
            {" "}
            1.440,40 € <br />
            273,20 €{" "}
          </>
        ),
        fontFamily: "Averta-Bold",
        backgroundColor: "var(--grey-20)",
      },
    ],
  },
  {
    name: "Row11",
    values: [
      { label: "" },
      { label: "" },
      {
        label: "Summe",
        fontFamily: "Averta-Bold",
        backgroundColor: "var(--grey-20)",
      },
      {
        label: "1.713,60 €",
        fontFamily: "Averta-Bold",
        backgroundColor: "var(--grey-20)",
      },
    ],
  },
];

export default function PreisbeispielBuschTenton() {
  return (
    <Slide>
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader colWidth={12} title="Preisbeispiel | Busch-Tenton®" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <QTable
                header={[
                  { label: "Beschreibung" },
                  { label: "Anzahl" },
                  { label: "Artikelnr." },
                  { label: "Summe" },
                ]}
                content={tableContent}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
