import {cloneElement} from 'preact'
import {useRef} from 'preact/hooks'

import Grid from '@material-ui/core/Grid'
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react'
import {Pagination} from 'swiper'
export default function QSwiperJS({children}) {
  const swiperRef = useRef()

  const params = {
    slidesPerView: 1,
    centeredSlides: true,
    keyboard: {
      enabled: true,
      onlyInViewport: true,
      pageUpDown: false,
    },
  }

  const SlidesWithSwiper = props => cloneElement(props.children, {swiperRef})

  const slides = children.map((slide, i) => (
    <SwiperSlide key={i} style={{height: '100vh', width: '100vw'}}>
      <SlidesWithSwiper>{slide}</SlidesWithSwiper>
    </SwiperSlide>
  ))

  return (
    <Grid
      container
      style={{
        width: '100vw',
        height: '100vh',
      }}
      alignItems="center"
      justify="center"
    >
      <Grid item xs={12}>
        <Swiper
          ref={swiperRef}
          pagination={{clickable: true, enabled: true}}
          modules={[Pagination]}
          {...params}
        >
          {slides}
        </Swiper>
      </Grid>
    </Grid>
  )
}

QSwiperJS.defaultProps = {
  children: [],
}
