import { Grid } from "@material-ui/core";
import backgroundIntro from "../../../../../../assets/_backgrounds/neuheiten-2024/1.webp";
import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QBodyText from "../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../components/ImageComponent";

export default function DasPerfekteDuo() {
  return (
    <Slide
      actions={[
        {
          type: "icon",
          link: "https://www.striebelundjohn.com/service-und-downloads/news/die-komplette-loesung",
          target: "_blank",
          img: "laptop",
        },
      ]}
      background={{
        src: backgroundIntro,
        alignment: "right",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Das perfekte DUO
                <br />
                AK600 und UK600
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Der neue AK600 und der bewährte UK600 sind das perfekte Duo für
                die moderne Aufputz- und Unterputzmontage. Sie vereinen alle
                Vorteile, auf die Installateure Wert legen. Neben maximaler
                Flexibilität teilen beide das gleiche Zubehör, was die
                Lagerhaltung wesentlich vereinfacht.
              </QBodyText>
              <QList
                headline="Highlights"
                items={[
                  "Maximaler Verdrahtungsraum",
                  "Bis 5-reihig verfügbar",
                  "Gleiches Zubehör",
                  "Stromkreis- und Mediaverteiler",
                  "Wifi-Türen mit Kunststoffeinsatz",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
