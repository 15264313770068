// Images Schulung
import schulungImage1 from "../../assets/free-at-home/Schulung/seminare.webp";
import schulungImage2 from "../../assets/abbknx/schulung/0411_MUC13_Inte_IN_12090_b1_01_comp2.webp";
import schulungImage3 from "../../assets/free-at-home/Schulung/techniktalk.webp";
import schulungImage4 from "../../assets/abbknx/schulung/Tutorial_ABB i-bus.webp";
import schulungImage5 from "../../assets/abbknx/schulung/KNXstarterset.webp";

// Images Marketing
import marketingImage1 from "../../assets/abbknx/marketing/PreislisteKNX.webp";
import marketingImage2 from "../../assets/abbknx/marketing/1640_BJE_KNX-Visualisierungsmoegl_11_19 1.webp";
import marketingImage3 from "../../assets/abbknx/marketing/inomag2020.webp";
import marketingImage4 from "../../assets/abbknx/marketing/SAH_S16.16.7.1_9PAA00000003629_master.webp";
import marketingImage5 from "../../assets/abbknx/marketing/ClimaECO_SBS_U6.0.1-84_6f-RTR.webp";
import marketingImage6 from "../../assets/abbknx/marketing/control-touch.webp";

export const knxUnterstuetzungSchulung = {
  title: "Schulung",
  items: [
    {
      title: "Busch-Jaeger Seminare",
      img: schulungImage1,
      link: {
        href: "https://www.busch-jaeger.de/busch-jaeger/termine",
        target: "_blank",
      },
    },
    {
      title: "ABB Seminare",
      img: schulungImage2,
      link: {
        href: "https://new.abb.com/service/de/training/abb-university/germany/seminare",
        target: "_blank",
      },
    },
    {
      title: "Technik-Talk",
      img: schulungImage3,
      link: {
        href: "https://new.abb.com/low-voltage/de/service/stromkompass/techniktalk",
        target: "_blank",
      },
    },
    {
      title: "Tutorials",
      img: schulungImage4,
      link: {
        href: "https://www.youtube.com/watch?v=abJDZc0tY1g&list=PLWxF8v6M9tYT4Sodayr7lwzSU5GlTt1Nn",
        target: "_blank",
      },
    },
    {
      title: "KNX Starter-Set",
      img: schulungImage5,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/94EEC/product/5605",
        target: "_blank",
      },
    },
  ],
};

export const knxUnterstuetzungMarketing = {
  title: "Marketing",
  items: [
    {
      title: (
        <>
          Vertriebsmuster
          <br />
          Combiaktor
        </>
      ),
      img: marketingImage4,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/3DEBE/product/5385",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          Vertriebsmuster
          <br />
          Busch-Tenton
        </>
      ),
      img: marketingImage5,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/02AE8/product/5360",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          Vertriebsmuster
          <br />
          Control Touch
        </>
      ),
      img: marketingImage6,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/FAB07/product/2461",
        target: "_blank",
      },
    },
    {
      title: "Broschüre Smarter Home",
      img: marketingImage2,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/2F39B/product/4070",
        target: "_blank",
      },
    },
    {
      title: "Broschüre Innomag 2020",
      img: marketingImage3,
      link: {
        href: "https://www.busch-jaeger-excellence.de/400/req-ns/D9026/product/5447",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          ABB i-bus<sup>®</sup> KNX
        </>
      ),
      img: marketingImage1,
      link: {
        href: "https://www.busch-jaeger-excellence.de/400/req-ns/CE895/product/5175%0D",
        target: "_blank",
      },
    },
  ],
};
