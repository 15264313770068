import { Grid } from "@material-ui/core";
import Slide from "../../../../../components/Slide";
import QHeader from "../../../../../components/QHeader";
import ChapterCard from "../../../../../components/ChapterCard";

// Images
import Image1 from "../../../../../assets/neuheiten-2024/abb/kacheln/1.webp";
import Image2 from "../../../../../assets/neuheiten-2024/abb/kacheln/2.webp";
import Image3 from "../../../../../assets/neuheiten-2024/abb/kacheln/3.webp";
import Image4 from "../../../../../assets/neuheiten-2024/abb/kacheln/4.webp";
import Image5 from "../../../../../assets/neuheiten-2024/abb/kacheln/5.webp";
import Image6 from "../../../../../assets/neuheiten-2024/abb/kacheln/6.webp";
import Image7 from "../../../../../assets/neuheiten-2024/abb/kacheln/7.webp";
import Image8 from "../../../../../assets/neuheiten-2024/abb/kacheln/8.webp";
import Image9 from "../../../../../assets/neuheiten-2024/abb/kacheln/9.webp";
import Image10 from "../../../../../assets/neuheiten-2024/abb/kacheln/10.webp";
import Image11 from "../../../../../assets/neuheiten-2024/abb/kacheln/11.webp";
import Image12 from "../../../../../assets/neuheiten-2024/abb/kacheln/12.webp";
import Image13 from "../../../../../assets/neuheiten-2024/abb/kacheln/13.webp";
import Image14 from "../../../../../assets/neuheiten-2024/abb/kacheln/14.webp";
import Image15 from "../../../../../assets/neuheiten-2024/abb/kacheln/15.webp";
import Image16 from "../../../../../assets/neuheiten-2024/abb/kacheln/16.webp";
import Image17 from "../../../../../assets/neuheiten-2024/abb/kacheln/17.webp";
import Image18 from "../../../../../assets/neuheiten-2024/abb/kacheln/18.webp";

export default function Overview() {
  const items = [
    {
      title: (
        <>
          FlexLine<sup>®</sup>
        </>
      ),
      img: Image1,
      link: {
        href: "#abb/1",
        target: "_self",
      },
    },
    {
      title: "FI/LS 1 TE DS301C A/AT Traction",
      img: Image2,
      link: {
        href: "#abb/2",
        target: "_self",
      },
    },
    {
      title: "Sicherungsautomat S300P 25 kA",
      img: Image3,
      link: {
        href: "#abb/3",
        target: "_self",
      },
    },
    {
      title: "Hochleistungs-Sicherungsautomat S800P",
      img: Image4,
      link: {
        href: "#abb/4",
        target: "_self",
      },
    },
    {
      title: "Multi-Messgeräte D1M",
      img: Image5,
      link: {
        href: "#abb/5",
        target: "_self",
      },
    },
    {
      title: "Digitaler Hilfsschalter INS-S/H",
      img: Image6,
      link: {
        href: "#abb/6",
        target: "_self",
      },
    },
    {
      title: "S3C-MOD Motorantriebe",
      img: Image7,
      link: {
        href: "#abb/7",
        target: "_self",
      },
    },
    {
      title: "OVR T1-T2 25kA High Energy",
      img: Image8,
      link: {
        href: "#abb/8",
        target: "_self",
      },
    },
    {
      title: "SCU 200",
      img: Image10,
      link: {
        href: "#abb/9",
        target: "_self",
      },
    },
    {
      title: "Slim Range Adapter SCC",
      img: Image11,
      link: {
        href: "#abb/10",
        target: "_self",
      },
    },
    {
      title: "F3C-AR Fernantriebe",
      img: Image12,
      link: {
        href: "#abb/11",
        target: "_self",
      },
    },
    {
      title: "Neue KNX-Binäreingänge",
      img: Image14,
      link: {
        href: "#abb/12",
        target: "_self",
      },
    },
    {
      title: "OPC UA Gateway für Novolink",
      img: Image13,
      link: {
        href: "#abb/13",
        target: "_self",
      },
    },
    {
      title: "Neue KNX Jalousie Aktoren",
      img: Image15,
      link: {
        href: "#abb/14",
        target: "_self",
      },
    },
    {
      title: "Vorverdrahtete Stromkreisverteiler Tafel 204",
      img: Image9,
      link: {
        href: "#abb/15",
        target: "_self",
      },
    },
    {
      title: "ABB Abillity™ Building Analyzer",
      img: Image16,
      link: {
        href: "#abb/16",
        target: "_self",
      },
    },
    {
      title: "ASKI Energie- & Lastmanagement",
      img: Image17,
      link: {
        href: "#abb/17",
        target: "_self",
      },
    },
    {
      title: "SEMS",
      img: Image18,
      link: {
        href: "#abb/18",
        target: "_self",
      },
    },
  ];

  return (
    <Slide
      background={{
        width: "50%",
      }}
    >
      <Grid container direction="column" wrap="nowrap" spacing={4}>
        <Grid item>
          <QHeader title="Neuheiten 2024" subTitle="ABB" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {items.map((e, i) => {
              return (
                <Grid item xs={2}>
                  <ChapterCard chapter={e} cardWidth="auto" />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
