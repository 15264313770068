import {h} from 'preact'

import {useDispatch, useSelector} from 'react-redux'
import {
  getNeuheiten2024Data,
  setNeuheiten2024Notes,
  setAsset as setDispatchAsset,
} from '../../../redux/modules/chapterNeuheiten2024'
import {Grid} from '@material-ui/core'
import QIncrementGroup from '../../QIncrementGroup'
import QActionPaper from '../../QActionPaper'
import QTextFieldAction from '../../QTextFieldAction'

export default function Neuheiten2024Page({context}) {
  const dispatch = useDispatch()

  const {notes: notesData, assetInnomag: assetInnomagData} =
    useSelector(getNeuheiten2024Data)

  const notes = notesData.value
  const assetInnomag = assetInnomagData.value

  const setSetupField = valueSet => {
    switch (valueSet.id) {
      case 'notes':
        {
          dispatch(setNeuheiten2024Notes(valueSet.value))
        }
        break
    }
  }

  const setAsset = (id, value) => {
    dispatch(setDispatchAsset(id, value))
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <QActionPaper title="Werbung" context={context}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QIncrementGroup
                value={assetInnomag}
                valueID="assetInnomag"
                label={assetInnomagData.description}
                mode={1}
                setAsset={setAsset}
              />
            </Grid>
          </Grid>
        </QActionPaper>
      </Grid>
      <Grid item>
        <QActionPaper title="Notizen" context={context}>
          <QTextFieldAction
            value={notes}
            valueID="notes"
            setSetupField={setSetupField}
          />
        </QActionPaper>
      </Grid>
    </Grid>
  )
}