import { Grid } from "@material-ui/core";
import Slide from "../../../../../components/Slide";
import QHeader from "../../../../../components/QHeader";
import ChapterCard from "../../../../../components/ChapterCard";

// Images
import Image1 from "../../../../../assets/neuheiten-2024/suj/kacheln/1.webp";
import Image2 from "../../../../../assets/neuheiten-2024/suj/kacheln/2.webp";
import Image3 from "../../../../../assets/neuheiten-2024/suj/kacheln/3.webp";
import Image4 from "../../../../../assets/neuheiten-2024/suj/kacheln/4.webp";
import Image5 from "../../../../../assets/neuheiten-2024/suj/kacheln/5.webp";
import Image6 from "../../../../../assets/neuheiten-2024/suj/kacheln/6.webp";

export default function Overview() {
  const items = [
    {
      title: "Das perfekte DUO AK600 und UK600",
      img: Image1,
      link: {
        href: "#striebel-john/1",
        target: "_self",
      },
    },
    {
      title: "TriLine C Reihenschaltschränke",
      img: Image2,
      link: {
        href: "#striebel-john/2",
        target: "_self",
      },
    },
    {
      title: "ComfortLine Serie jetzt komplett!",
      img: Image3,
      link: {
        href: "#striebel-john/3",
        target: "_self",
      },
    },
    {
      title: (
        <>
          ComfortLine Innenausbau
          <br />
          Die nächste Stufe der Elektroinstallation
        </>
      ),
      img: Image4,
      link: {
        href: "#striebel-john/4",
        target: "_self",
      },
    },
    {
      title: "Mess- und Wandlertechnik (nach FNN)",
      img: Image5,
      link: {
        href: "#striebel-john/5",
        target: "_self",
      },
    },
    {
      title: "VMS Funktionsgehäuse",
      img: Image6,
      link: {
        href: "#striebel-john/6",
        target: "_self",
      },
    },
  ];

  return (
    <Slide
      background={{
        width: "50%",
      }}
    >
      <Grid container direction="column" wrap="nowrap" spacing={4}>
        <Grid item>
          <QHeader title="Neuheiten 2024" subTitle="Striebel & John" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {items.map((e, i) => {
              return (
                <Grid item xs={4}>
                  <ChapterCard chapter={e} cardWidth="auto" />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
