import { Grid } from "@material-ui/core";
import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QBodyText from "../../../../../../components/QBodyText";
import QList from "../../../../../../components/QList";
import CenteredImage from "../../../../../../components/CenteredImage";

// Images
import BackgroundImage from "../../../../../../assets/welcome/2021/0206/bgSmartphone.webp";
import EinfamilienhausSmartphoneImage from "../../../../../../assets/welcome/2021/0206/Anwendung_Einfamilienhaus_Smartphone.webp";

export default function EinfamilienhausSmartphone() {
  return (
    <Slide
      background={{
        src: BackgroundImage,
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          target: "_blank",
          link: "https://www.busch-jaeger.de/online-katalog/detail/2TMA200010X0003",
        },
        {
          type: "pdf",
          img: "preisbeispiel",
          path: "../../../../../../assets/pdf/preisbeispiele/Busch-Welcome/Preisbeispiel Busch-Welcome - Einfamilienhaus Smartphone.pdf",
        },
        {
          type: "galery",
          img: "info",
          images: [
            {
              src: EinfamilienhausSmartphoneImage,
              alt: "Anwendung Einfamilienhaus Smartphone",
            },
          ],
        },
      ]}
    >
      <CenteredImage
        src="../../../../../../assets/welcome/2021/0206/iphoneX_12_welcome_app_eingangstuer.webp"
        alt="Smartphone"
        height="50vh"
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Welcome<sup>®</sup>
              </>
            }
            subTitle="Anwendung Einfamilienhaus Smartphone"
          />
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <QBodyText>
              Wissen, was zuhause los ist: Besucher zu Hause, im Garten oder von
              unterwegs willkommen heißen.
            </QBodyText>
            <QList
              title="Vorteile"
              headline="Vorteile"
              items={[
                "IP-Gateway ermöglicht die Anbindung vom Smartphone (auch aus der Ferne)",
                "Audio & Videoübertragung zum Smartphone",
                "Bildspeicher in der App",
                "Einfache 2-Draht-Installation",
              ]}
              dense
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
