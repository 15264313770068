// Images Marketing
import Image1 from "../../assets/smarter-gallery/Marketing/ratgeber-neubau.webp";
import Image2 from "../../assets/smarter-gallery/Marketing/sortimentsbroschuere-lichtschalter.webp";
import Image3 from "../../assets/smarter-gallery/Marketing/ratgeber-renovieren.webp";

export const smarterGalleryMarketingItems = {
  title: "Marketing",
  items: [
    {
      title: "Lösungsbroschüre Neubau/Ratgeber",
      img: Image1,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/F5082/product/2615",
        target: "_blank",
      },
    },
    {
      title: "Lichtschalter",
      img: Image2,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/22BB9/product/5035",
        target: "_blank",
      },
    },
    {
      title: "Ratgeber Renovieren",
      img: Image3,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/F5082/product/5336",
        target: "_blank",
      },
    },
  ],
};
