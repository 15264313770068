const SET_NOTES = "playbook/usv/SET_NOTES";

export const initialState = {
  notes: {
    description: "Notizen für das Kapitel",
    value: "",
    type: "info",
    orderID: null,
  },
};

const usv = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTES: {
      return {
        ...state,
        notes: {
          ...state.notes,
          value: action.notes,
        },
      };
    }
    default:
      return state;
  }
};

//actions
export const setUSVNotes = (notes) => ({
  type: SET_NOTES,
  notes,
});

//selector
export const getUSVNotes = (state) => state.usv.notes;
export const getUSVData = (state) => state.usv;

export default usv;
