import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../components/Slide";
import QList from "../../../../../../../../components/QList";
import QHeader from "../../../../../../../../components/QHeader";
import ImageComponent from "../../../../../../../../components/ImageComponent";
import QBodyText from "../../../../../../../../components/QBodyText";
import AnwendungImage from "../../../../../../../../assets/abbknx/Anwendungen/Einzelraumregelung/anwendungsbeschreibung.webp";
import BetterImage from "../../../../../../../../components/BetterImage";
import UebersichtEinzelraumregelung from "./SVG/UebersichtEinzelraumregelung";

export default function Anwendungsbeschreibung({ swiperRef }) {
  /*
  const goToSlide = (id) => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(id);
    }
  };
  */

  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Einzelraumregelung" subTitle="RTR und Stellantrieb" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={5}>
              <QBodyText>
                Die Einzelraumregelung erfolgt in 3 Stufen: Temperaturmessung,
                Regelung und Ansteuerung des Heizkreises/Kühlkreises oder
                kombiniert.
              </QBodyText>
              <QBodyText>
                Einige KNX-Geräte können Funktionalitäten wie Temperaturmessung,
                Regelung und Aktorik in einem Gerät kombinieren.
              </QBodyText>

              <QList
                title="Vorteile"
                items={[
                  "Tenton SBR/U6.0.11-84: Messung und Regelung",
                  "Ventilantriebs-Controller VC/S4.1.11 : Regelung und Aktorik",
                  "Tasterankopplung 6108/06: Messung",
                  "Raumtemperaturregler UP 6109/05: Regelung",
                  "Heizungsaktor ES/S4.1.2.1, 8-fach: Aktorik",
                ]}
                dense
                variant="dot"
              />

              <QBodyText>
                Die Raumtemperaturregler (Hauptstelle) können von
                Nebenstellen-Geräten „fernbedient“ werden. Über sie können
                Sollwerte oder Betriebsarten in der Hauptstelle verändert und
                Zustände visualisiert werden.
              </QBodyText>
            </Grid>

            <Grid item xs={7}>
              <BetterImage
                alt="Einzelraumregelung | RTR und Stellantrieb"
                src={<UebersichtEinzelraumregelung />}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
