import { h, Component } from "preact";

import { connect } from "react-redux";
import {
  setSeminarAppointment,
  setFreeAtHomeNotes,
  getFreeAtHomeData,
  setSeminarNote,
  setAsset,
} from "../../../redux/modules/chapterFreeAtHome";
import { Grid } from "@material-ui/core";
import QIncrementGroup from "../../QIncrementGroup";
import QActionPaper from "../../QActionPaper";
import QDateAction from "../../QDateAction";
import QActionCheckbox from "../../QActionCheckbox";
import QTextFieldAction from "../../QTextFieldAction";
class FreeAtHomePage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      seminarAppointment,
      notes,
      seminarNote,
      assetSortimentsbroschüre,
      assetSystemhandbuch,
      assetKomplettübersicht,
      assetFlorPostHeizkörperthermostate,
      assetVermarktungspaket,
      assetWerbebanner,
      assetSondermustertafel,
      assetMusterkoffer,
      assetFlorpostBJE,
      context,
    } = this.props;

    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <QActionPaper title="Seminare" context={context}>
            <QDateAction
              date={seminarAppointment}
              dateID="seminarAppointment"
              note={seminarNote}
              noteID="seminarNote"
              setSetupField={this.props.setSetupField}
            />
          </QActionPaper>
        </Grid>
        <Grid item>
          <QActionPaper title="Unterlagen" context={context}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <QIncrementGroup
                  value={assetSortimentsbroschüre}
                  valueID="assetSortimentsbroschüre"
                  label="Sortimentsbroschüre"
                  mode={1}
                  setAsset={this.props.setAsset}
                />
              </Grid>
              <Grid item xs={6}>
                <QIncrementGroup
                  value={assetSystemhandbuch}
                  valueID="assetSystemhandbuch"
                  label="Systemhandbuch"
                  mode={1}
                  setAsset={this.props.setAsset}
                />
              </Grid>
              <Grid item xs={6}>
                <QIncrementGroup
                  value={assetKomplettübersicht}
                  valueID="assetKomplettübersicht"
                  label="Komplettübersicht"
                  mode={1}
                  setAsset={this.props.setAsset}
                />
              </Grid>
            </Grid>
          </QActionPaper>
        </Grid>
        <Grid item>
          <QActionPaper title="Werbung" context={context}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <QIncrementGroup
                  value={assetFlorPostHeizkörperthermostate}
                  valueID="assetFlorPostHeizkörperthermostate"
                  label="Florpost Heizkörperthermostate"
                  mode={1}
                  setAsset={this.props.setAsset}
                />
              </Grid>
              <Grid item xs={6}>
                <QIncrementGroup
                  value={assetWerbebanner}
                  valueID="assetWerbebanner"
                  label="Werbebanner 18/1"
                  mode={1}
                  setAsset={this.props.setAsset}
                />
              </Grid>
              <Grid item xs={6}>
                <QIncrementGroup
                  value={assetVermarktungspaket}
                  valueID="assetVermarktungspaket"
                  label="Vermarktungspaket"
                  mode={1}
                  setAsset={this.props.setAsset}
                />
              </Grid>
              <Grid item xs={6}>
                <QIncrementGroup
                  value={assetFlorpostBJE}
                  valueID="assetFlorpostBJE"
                  label={
                    <>
                      Florpost Buschfree@home<sup>®</sup>
                    </>
                  }
                  mode={1}
                  setAsset={this.props.setAsset}
                />
              </Grid>
            </Grid>
          </QActionPaper>
        </Grid>
        <Grid item>
          <QActionPaper title="Notizen" context={context}>
            <QTextFieldAction
              value={notes}
              valueID="notes"
              setSetupField={this.props.setSetupField}
            />
          </QActionPaper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    seminarAppointment,
    notes,
    seminarNote,
    assetSortimentsbroschüre,
    assetSystemhandbuch,
    assetKomplettübersicht,
    assetFlorPostHeizkörperthermostate,
    assetVermarktungspaket,
    assetWerbebanner,
    assetSondermustertafel,
    assetMusterkoffer,
    assetFlorpostBJE,
  } = getFreeAtHomeData(state);
  return {
    seminarAppointment: seminarAppointment.value,
    notes: notes.value,
    seminarNote: seminarNote.value,
    assetSortimentsbroschüre: assetSortimentsbroschüre.value,
    assetSystemhandbuch: assetSystemhandbuch.value,
    assetKomplettübersicht: assetKomplettübersicht.value,
    assetFlorPostHeizkörperthermostate:
      assetFlorPostHeizkörperthermostate.value,
    assetVermarktungspaket: assetVermarktungspaket.value,
    assetWerbebanner: assetWerbebanner.value,
    assetSondermustertafel: assetSondermustertafel.value,
    assetMusterkoffer: assetMusterkoffer.value,
    assetFlorpostBJE: assetFlorpostBJE.value,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSetupField(valueSet) {
    switch (valueSet.id) {
      case "seminarNote":
        {
          dispatch(setSeminarNote(valueSet.value));
        }
        break;
      case "seminarAppointment":
        {
          dispatch(setSeminarAppointment(valueSet.value));
        }
        break;
      case "notes":
        {
          dispatch(setFreeAtHomeNotes(valueSet.value));
        }
        break;
    }
  },
  setAsset(id, value) {
    dispatch(setAsset(id, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FreeAtHomePage);
