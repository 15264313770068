import { Box, Grid } from "@material-ui/core";

import QBodyText from "../../../../../../components/QBodyText";
import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QTable from "../../../../../../components/QTable";
import ImageComponent from "../../../../../../components/ImageComponent";

//Images
import backgroundImg from "../../../../../../assets/_backgrounds/neuheiten-2024/2.webp";
import GlassBlackGoldMirror from "../../../../../../assets/neuheiten-2024/bje/busch-art-linear/glass-black-gold-mirror.webp";
import GlassBlueMattFrontal from "../../../../../../assets/neuheiten-2024/bje/busch-art-linear/glass-blue-matt-frontal.webp";
import GlassStructuredGlossyFrontal from "../../../../../../assets/neuheiten-2024/bje/busch-art-linear/glass-structured-glossy-frontal.webp";
import WoodOakMattFrontal from "../../../../../../assets/neuheiten-2024/bje/busch-art-linear/wood-oak-matt-frontal.webp";

function BuschArtLinear() {
  return (
    <Slide
      background={{
        src: backgroundImg,
        alignment: "right",
      }}
      actions={[
        {
          type: "icon",
          link: "https://www.busch-jaeger.de/produkte/schalterprogramme/busch-art-linear",
          target: "_blank",
          img: "laptop",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch-art linear®" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  "Zweite-Stufe-Portfolio",
                  "Nachhaltiges Design",
                  "Klare Linien, leichte Formen, minimalistische Gestaltung",
                  "Ergänzung des Sortiments u.a. Hotelanwendungen, casambai, Friends of hue, SCHUKO® Steckdosen, eBs, flat,...",
                  "82 % weniger CO2",
                  "98 %/92 % Verwendung von Rezyklaten für die Farben schwarz/weiß",
                ]}
                dense
              />
              <Box marginTop="30px">
                <ImageComponent
                  col={3}
                  wrapperWidth="80%"
                  align="left"
                  images={[
                    {
                      src: GlassBlackGoldMirror,
                      alt: (
                        <>
                          Busch-art linear<sup>®</sup> | Schwarz matt, Gold
                          verspiegelt
                        </>
                      ),
                    },
                    {
                      src: GlassBlueMattFrontal,
                      alt: (
                        <>
                          Busch-art linear<sup>®</sup> | Schwarz matt, Blau
                        </>
                      ),
                    },
                    {
                      src: GlassStructuredGlossyFrontal,
                      alt: (
                        <>
                          Busch-art linear<sup>®</sup> | Schwarz matt, Schwarz
                          strukturiert
                        </>
                      ),
                    },
                    {
                      src: WoodOakMattFrontal,
                      alt: (
                        <>
                          Busch-art linear<sup>®</sup> | Studioweiß Eiche
                        </>
                      ),
                    },
                  ]}
                />
              </Box>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default BuschArtLinear;
