import { h } from "preact";
import { Grid } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";
import ImageComponent from "../../../../../../components/ImageComponent";

export default function RenovationHeizung() {
  return (
    <Slide
      actions={[
        {
          type: "pdf",
          img: "preisbeispiel",
          path: "../../../../../../assets/pdf/preisbeispiele/Busch-free-at-home/Preisbeispiel Busch-free@home - Renovation Heizung.pdf",
        },
      ]}
      background={{
        width: "60%",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-free@home<sup>®</sup>
              </>
            }
            subTitle="Anwendung Renovation Heizung"
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={5}>
              <QList
                title="Vorteile"
                items={[
                  "Zeitschaltuhr je Antrieb",
                  "Geofencing",
                  "Kein Kabelzug durch Batteriegeräte",
                  "Regler im Stallantrieb integriert oder als sep. Bedienelement für den Schaltereinbau",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={7}>
              <ImageComponent
                wrapperWidth="80%"
                wrapperHeight="60vh"
                src="../../../../../../assets/free-at-home/Anwendungen/anwendung-renovation-heizung.webp"
                alt="Anwendung Renovation Heizung"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
