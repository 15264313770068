import { Box, Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import ImageComponent from "../../../../../../components/ImageComponent";
import QList from "../../../../../../components/QList";

//Images
import BuschVoiceControlImage from "../../../../../../assets/neuheiten-2024/bje/matter-eve/matter-eve.webp";

function BuschVoiceControl() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch VoiceControl mit Matter Gateway" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  "Neue Gateway Funktion zwischen KNX und Matter",
                  "Die in Busch-VoiceControl® definierten KNX-Funktionen können dem MATTER-Universum zur Verfügung gestellt und über Apps wie Apple Home, Samsung Smart Things Google Home und Amazon Alexa gesteuert werden.",
                  "Matter Geräte wie Thermostate, intelligente Steckdosen, Bewegungssensoren oder Fenstersensoren können in KNX integriert werden und werden Teil des Ökosystems.",
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <ImageComponent
                src={BuschVoiceControlImage}
                alt="Busch VoiceControl mit Matter Gateway"
                col={12}
                wrapperHeight="45vh"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default BuschVoiceControl;
