import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../components/QHeader";
import BetterImage from "../../../../../../../../components/BetterImage";
import WirkungsweiseDerRaumaufschaltungSVG from "./WirkungsWeiseDerRaumaufschaltungSVG/index";

export default function WirkungsweiseDerRaumaufschaltung() {
  const id = "wirkungsweise-der-raumaufschaltung-svg";
  return (
    <Slide>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Wirkungsweise der Raumaufschaltung" />
        </Grid>
        <Grid item>
          <BetterImage
            src={<WirkungsweiseDerRaumaufschaltungSVG />}
            alt="Wirkungsweise der Raumaufschaltung"
          />
        </Grid>
      </Grid>
    </Slide>
  );
}
