const SET_PREPARED_CONSULTATION =
  "playbook/preparedConsultations/SET_PREPARED_CONSULTATION";

const initialState = {
  consultations: [],
  fetchDate: null,
};

const preparedConsultations = (state = initialState, action) => {
  switch (action.type) {
    case SET_PREPARED_CONSULTATION: {
      return {
        ...state,
        consultations: action.consultations,
        fetchDate: new Date(),
      };
    }
    default:
      return state;
  }
};

//actions
export const setPreparedConsultations = (consultations) => ({
  type: SET_PREPARED_CONSULTATION,
  consultations,
});

//selector
export const getPreparedConsultations = (state) => state.preparedConsultations;
export default preparedConsultations;
