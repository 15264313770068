import { Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QBoxList from "../../../../../../components/QBoxList";
import Gewerbe from "./Gewerbe";
import Einfamilienhaus from "./Einfamilienhaus";
import Mehrfamilienhaus from "./Mehrfamilienhaus";

export const welcomeIpApplications = [
  {
    title: "Einfamilienhaus",
    img: "../../../../../../assets/welcome-ip/Anwendungen/efh.webp",
    link: "/welcome-ip#anwendungen/1",
    target: "_self",
    menuOverlay: <Einfamilienhaus />,
  },
  {
    title: "Mehrfamilienhaus",
    img: "../../../../../../assets/welcome-ip/Anwendungen/mfh.webp",
    link: "/welcome-ip#anwendungen/2",
    target: "_self",
    menuOverlay: <Mehrfamilienhaus />,
  },
  {
    title: "Gewerbe",
    img: "../../../../../../assets/welcome-ip/Anwendungen/gewerbe.webp",
    link: "/welcome-ip#anwendungen/3",
    target: "_self",
    menuOverlay: <Gewerbe />,
  },
];

export default function Anwendungen() {
  return (
    <Slide
      actions={[
        {
          type: "galery",
          img: "info",
          images: [
            {
              src: "../../../../../../assets/welcome-ip/Anwendungen/Diagramme/overview.webp",
              alt: "Übersicht",
            },
          ],
        },
      ]}
      background={{
        alignment: "left",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Welcome<sup>®</sup> IP
              </>
            }
            subTitle="Anwendungen"
          />
        </Grid>
        <Grid item container>
          <Grid item xs={12}>
            <QBoxList colWidthXS={4} items={welcomeIpApplications} />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
