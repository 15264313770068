// Images Schulung
import ImageSchulung1 from "../../assets/cwa/schulung/live-view-app.webp";
import ImageSchulung2 from "../../assets/cwa/schulung/mustertafel.webp";
import ImageSchulung3 from "../../assets/cwa/schulung/launch-flyer.webp";
import ImageSchulung4 from "../../assets/cwa/schulung/live-view-app-scann.webp";
import ImageSchulung5 from "../../assets/cwa/schulung/vertriebsmuster.webp";
import ImageSchulung6 from "../../assets/cwa/schulung/baustellenplaner.webp";
import ImageSchulung7 from "../../assets/cwa/schulung/fan-schalter.webp";
import ImageSchulung8 from "../../assets/cwa/schulung/datenblaetter.webp";

// Images Marketing
import MarketingImage1 from "../../assets/cwa/marketing/sortimentsbroschuere-lichtschalter.webp";
import MarketingImage2 from "../../assets/cwa/marketing/katalog-2023-2024.webp";
import MarketingImage3 from "../../assets/cwa/marketing/ratgeber-neubau.webp";
import MarketingImage4 from "../../assets/cwa/marketing/ratgeber-wohnungsbau.webp";
import MarketingImage5 from "../../assets/cwa/marketing/wohnungsbau.webp";
import MarketingImage6 from "../../assets/cwa/marketing/grossflaeche.webp";
import MarketingImage7 from "../../assets/cwa/marketing/broschuere-busch-art-linear.webp";

export const schaltenUndSteuernSchulungItems = {
  title: "Schulung",
  items: [
    {
      title: "3D LiveView App",
      img: ImageSchulung1,
      link: {
        href: "https://www.busch-jaeger.de/mediathek?tx_solr%5Bfilter%5D%5B0%5D=media_type%3Aapps",
      },
    },
    {
      title: "Mustertafel",
      img: ImageSchulung2,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/C561C/offset/5/product/1896",
      },
    },
    {
      title: "Launch-Flyer",
      img: ImageSchulung3,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/95844/product/5902",
      },
    },
    {
      title: "Tracking-Motiv, 3D LiveView App",
      img: ImageSchulung4,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/FD226/product/2154",
      },
    },
    {
      title: "Vertriebsmuster",
      img: ImageSchulung5,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/C561C/offset/5/product/1896",
      },
    },
    {
      title: "Busch-Baustellenplaner",
      img: ImageSchulung6,
      link: {
        href: "https://baustellenplaner.busch-jaeger.de",
      },
    },
    {
      title: "Fan-Schalter",
      img: ImageSchulung7,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/FD226/product/3660",
      },
    },
    {
      title: "Datenblätter",
      img: ImageSchulung8,
      link: {
        href: "https://www.busch-jaeger.de/fuer-profis/service-tools/datenblaetter",
      },
    },
  ],
};
export const schaltenUndSteuernMarketingItems = {
  title: "Marketing",
  items: [
    {
      title: "Sortimentsbroschüre Lichtschalter",
      img: MarketingImage1,
      link: {
        href: "https://www.busch-jaeger-excellence.de/400/req-ns/11712/product/5035",
        target: "_blank",
      },
    },
    {
      title: "Katalog 2023/2024",
      img: MarketingImage2,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/5C609/product/6420",
        target: "_blank",
      },
    },
    {
      title: "Broschüre Ratgeber Neubau",
      img: MarketingImage3,
      link: {
        href: "https://www.busch-jaeger.de/media/download/Broschueren/de/bje/2CKA000001A1609_Ratgeber_Neubau_6_21.pdf",
        target: "_blank",
      },
    },
    {
      title: "Broschüre Ratgeber Wohnungsbau",
      img: MarketingImage4,
      link: {
        href: "https://www.busch-jaeger-excellence.de/400/req-ns/11712/product/5336",
        target: "_blank",
      },
    },
    {
      title: "Broschüre Wohnungsbau",
      img: MarketingImage5,
      link: {
        href: "https://www.busch-jaeger-excellence.de/400/req-ns/11712/product/5335",
        target: "_blank",
      },
    },
    {
      title: "18/1 Grossfläche",
      img: MarketingImage6,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/05391/product/5282",
        target: "_blank",
      },
    },
    {
      title: (
        <>
          Broschüre Busch-art linear<sup>®</sup>
        </>
      ),
      img: MarketingImage7,
      link: {
        href: "../../assets/pdf/cwa/Bestellunterlage-Busch-art-linear.pdf",
        target: "_blank",
      },
    },
  ],
};
