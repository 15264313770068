import { Box, Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import ImageComponent from "../../../../../../components/ImageComponent";

import BuschWelcomeIPIcon from "../../../../../../assets/neuheiten-2024/bje/busch-welcome-ip/busch-welcome-ip-icon.webp";
import BetterImage from "../../../../../../components/BetterImage";

function BuschWelcomeIP() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch-Welcome® IP 2.0" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  "Neuer Systemaufbau",
                  "Neue Parametrierung",
                  "API Anbindung",
                  "SIP Anbindung",
                  "Vereinfachte Installation ohne SmartAP für das Einfamilienhaus",
                  'Neue GUI für IP Touch 7" & 10" mittels Firmware-Update',
                  'Neues IP Touch Lite 7" Panel',
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}>
              <Box
                height="75dvh"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <BetterImage
                  align="center"
                  height="auto"
                  width="20dvw"
                  src={BuschWelcomeIPIcon}
                  alt="Busch-Welcome® IP 2.0"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default BuschWelcomeIP;
