// Images Marketing
import Image1 from "../../assets/flexline/marketing/1.webp";
import Image2 from "../../assets/flexline/marketing/2.webp";

export const flexLineMarketingItems = {
  title: "Marketing",
  items: [
    {
      title: "Video",
      img: Image1,
      link: {
        href: "https://www.youtube.com/watch?v=KBGPF8vCNY0&t=5s",
        target: "_blank",
      },
    },
    {
      title: "Broschüre",
      img: Image2,
      link: {
        href: "https://search.abb.com/library/Download.aspx?DocumentID=9AKK108468A1272&LanguageCode=de&DocumentPartId=_DE&Action=Launch",
        target: "_blank",
      },
    },
  ],
};
