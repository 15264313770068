import { Grid } from "@material-ui/core";

import Slide from "../../../../../components/Slide";
import QHeader from "../../../../../components/QHeader";
import CenteredImage from "../../../../../components/CenteredImage";
import QList from "../../../../../components/QList";
import ImageComponent from "../../../../../components/ImageComponent";
import PDFViewer from "../../../../../components/PDFViewer";

export default function GruppensteuerungWireless() {
  return (
    <Slide
      actions={[
        {
          type: "pdf",
          img: "preisbeispiel",
          path: "../../../../../assets/pdf/preisbeispiele/Busch-flexTronics/Preisbeispiel Busch-flexTronics - Jalousie Gruppensteuerung Wireless.pdf",
        },
      ]}
      background={{
        width: "60%",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-flexTronics<sup>®</sup> Wireless
              </>
            }
            subTitle="Jalousie Gruppensteuerung Wireless"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={5} xl={6}>
              <QList
                items={[
                  "Manuelle Bedienung an jeder Bedienstelle möglich",
                  "Zentrale Jalousie Gruppensteuerung über kabellose Vernetzung",
                  "Zeitschaltuhr inkl. Astrofunktion",
                  "App-Konfiguration & Bedienung über Bluetooth Verbindung",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={7} lg={5} xl={6}>
              <ImageComponent
                alt="Anwendungsbeispiel Jalousie Gruppensteuerung Wireless"
                src="../../../../../assets/busch-flex/anwendungsbeispiele/3.webp"
                wrapperWidth="80%"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
