import { Box, Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import BetterImage from "../../../../../../components/BetterImage";

// Images
import S3CImage from "../../../../../../assets/neuheiten-2024/abb/S3C/S3C.webp";

function S3CMOD() {
  return (
    <Slide
      background
      actions={[
        {
          type: "icon",
          link: "https://library.e.abb.com/public/4293f80db7864fbaa98375e591661903/9AKK108468A7295_Rev_A_Motorantriebe_DE_Katalog_1123_WEB.pdf?x-sign=nR50Rf1U9oAD6UwCpiLegrD6M4q5QEF06ApPzZXDxvubVVmliZPlzsB/+8C00rb5",
          target: "_blank",
          img: "laptop",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="S3C-MOD Motorantriebe" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  "S3C-MOD24 mit 24...48V AC/DC",
                  "S3C-MOD230 mit 230-240 V AC",
                  "Anbaumöglichkeiten: S200 und S300, F200 und F200B, DS201 und SD200",
                  "kompakte Bauweise in nur 1 TE",
                  "-25°...+60°C Betriebstemperatur",
                  "Anbau eines kompakten Kommunikationsmoduls möglich",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}>
              <Box
                height="63dvh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <BetterImage
                  src={S3CImage}
                  height="38dvh"
                  alt="S3C-MOD Motorantriebe"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default S3CMOD;
