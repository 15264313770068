import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../components/QHeader";
import ImageComponent from "../../../../../../../../components/ImageComponent";
import DiagrammHeizkreis from "../../../../../../../../assets/abbknx/Anwendungen/ClimaEco/anzeigen-und-bedienen/Diagramm-Heizkreis.webp";

export default function WerteVonHeizkreis() {
  return (
    <Slide>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Wertaufzeichnung von Temperatur und Stellgröße." />
        </Grid>
        <Grid item xs={12}>
          <ImageComponent
            alt="Wertaufzeichnung von Temperatur und Stellgröße."
            src={DiagrammHeizkreis}
            wrapperHeight="70vh"
          />
        </Grid>
      </Grid>
    </Slide>
  );
}
