import { Box, Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import ImageComponent from "../../../../../../components/ImageComponent";

//Images
import SchaltaktorImage from "../../../../../../assets/neuheiten-2024/bje/schaltaktor/schaltaktor.webp";
import JalousieaktorImage from "../../../../../../assets/neuheiten-2024/bje/schaltaktor/jalousieaktor.webp";
import DimmaktorImage from "../../../../../../assets/neuheiten-2024/bje/schaltaktor/dimmaktor.webp";

function AktorUPWireless() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title="Sensor/Schaltaktor 1/1-fach"
            subTitle="UP, wireless"
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  <>
                    Zur Kombination mit dem Smart-Switch wireless als
                    konventionelle Funklösung für Busch-flexTronics<sup>®</sup>{" "}
                    wireless und Busch-free@home® wireless
                  </>,
                  "Als dezentrale Unterputzaktorik nutzbar",
                  "Schaltaktor 1-fach, UP",
                  "Dimmaktor 1-fach, UP",
                  "Jalousieaktor 1-fach, UP",
                  "Zur Unterputzmontage in Standard Schalterabzweigdose",
                  "Nebenstelleneingang für Taster z.B. 2020 US",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}>
              <ImageComponent
                col={12}
                wrapperWidth="30%"
                wrapperHeight="20vh"
                align="center"
                images={[
                  {
                    src: JalousieaktorImage,
                    alt: "62831 U-WL Jalousieaktor 1-fach, UP, WL",
                  },
                  {
                    src: DimmaktorImage,
                    alt: "62851 U-WL Dimmaktor 1-fach, UP, WL",
                  },
                  {
                    src: SchaltaktorImage,
                    alt: "62811 U-WL Schaltaktor 1-fach, UP, WL",
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default AktorUPWireless;
