// Components
import { Box, Grid } from "@material-ui/core";
import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import ChapterCard from "../../../../../../components/ChapterCard";

// Anwendungen
import Jalousiesteuerung from "./Jalousiesteuerung";
import Gartenbeleuchtung from "./Gartenbeleuchtung";
import Rauchwarnmelder from "./Rauchwarnmelder";
import RenovationHeizung from "./RenovationHeizung";
import LichtsteuerungDALI from "./LichtsteuerungDALI";

// Images
import Image1 from "../../../../../../assets/free-at-home/Anwendungen/jalousiesteuerung.webp";
import Image2 from "../../../../../../assets/free-at-home/Anwendungen/gartenbeleuchtung.webp";
import Image3 from "../../../../../../assets/free-at-home/Anwendungen/busch-rauchalarm.webp";
import Image4 from "../../../../../../assets/free-at-home/Anwendungen/renovationHeizung.webp";
import Image5 from "../../../../../../assets/free-at-home/Anwendungen/lichtsteuerungDali.webp";

export const freeAtHomeApplications = [
  {
    title: "Jalousiesteuerung",
    img: Image1,
    link: {
      href: "/free-at-home#anwendungen/1",
      target: "_self",
    },
    menuOverlay: <Jalousiesteuerung />,
  },
  {
    title: "Gartenbeleuchtung",
    img: Image2,
    link: {
      href: "/free-at-home#anwendungen/2",
      target: "_self",
    },
    menuOverlay: <Gartenbeleuchtung />,
  },
  {
    title: "Busch-Rauchalarm",
    img: Image3,
    link: {
      href: "/free-at-home#anwendungen/3",
      target: "_self",
    },
    menuOverlay: <Rauchwarnmelder />,
  },
  {
    title: "Renovation Heizung",
    img: Image4,
    link: {
      href: "/free-at-home#anwendungen/4",
      target: "_self",
    },
    menuOverlay: <RenovationHeizung />,
  },
  {
    title: "Lichtsteuerung/DALI",
    img: Image5,
    link: {
      href: "/free-at-home#anwendungen/5",
      target: "_self",
    },
    menuOverlay: <LichtsteuerungDALI />,
  },
];

export default function Anwendungen() {
  return (
    <Slide
      background={{
        alignment: "left",
        width: "45%",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <QHeader
          title={
            <>
              Busch-free@home<sup>®</sup>
            </>
          }
          subTitle="Anwendungen"
        />
        <Box px={10}>
          <Grid container spacing={2}>
            {freeAtHomeApplications.map((e, i) => {
              return (
                <Grid item xs={4} lg={3} xl={2}>
                  <ChapterCard noDialog chapter={e} cardWidth="auto" />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Grid>
    </Slide>
  );
}
