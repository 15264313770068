import Slide from "../../../../../components/Slide/index";
import { Box, Grid } from "@material-ui/core";
import QHeader from "../../../../../components/QHeader";

import KuehlkurveImage from "../../../../../assets/abbknx/Anwendungen/ClimaEco/kuehlkurve.webp";
import HeizkurveImage from "../../../../../assets/abbknx/Anwendungen/ClimaEco/anzeigen-und-bedienen/heizkurve.webp";
import Systemvorteile from "./Systemvorteile";
import ClimaEcoGesamtuebersichtPNG from "../Anwendungen/slides/_GlobalSlides/ClimaEcoGesamtuebersichtPNG";

export default function ClimaEcoGesamtuebersicht({ noInteraction }) {
  return (
    <Slide
      actions={[
        {
          id: "info",
          type: "dialogWithButton",
          label: "Systemvorteile KNX",
          children: <Systemvorteile />,
        },
        {
          id: "kuehlkurve",
          type: "galery",
          img: "graph",
          label: "Kühlen",
          images: [
            {
              src: KuehlkurveImage,
              alt: "Kühlkurve",
            },
          ],
        },
        {
          id: "heizkurve",
          type: "galery",
          img: "graph",
          label: "Heizen",
          images: [
            {
              src: HeizkurveImage,
              alt: "Heizkurve",
            },
          ],
        },
      ]}
      background={{
        alignment: "left",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Gesamtlösung" subTitle="ClimaEco" />
        </Grid>
        <Grid item xs={12}>
          <Box width="100%" display="flex" justifyContent="center">
            <Box height="65vh">
              <ClimaEcoGesamtuebersichtPNG />
              {/* <ClimaEcoGesamtuebersichtSVG /> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Slide>
  );
}
