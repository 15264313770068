import { Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QTable from "../../../../../../components/QTable";
import ImageComponent from "../../../../../../components/ImageComponent";
import QBodyText from "../../../../../../components/QBodyText";
import QAccordion from "../../../../../../components/QAccordion";

// Images
import backgroundImg from "../../../../../../assets/_backgrounds/neuheiten-2024/4.webp";

function ComfortPlaner() {
  return (
    <Slide
      actions={[
        {
          type: "icon",
          img: "konfiguratoren",
          target: "_blank",
          link: "https://comfortplaner.de.abb.com",
        },
      ]}
      background={{
        src: backgroundImg,
        alignment: "right",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="ComfortPlaner" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Der innovative ComfortPlaner ist die perfekte Ergänzung zu den
                neuen ComfortLine A Zählerschränken.
              </QBodyText>
              <QList
                headline="Eigenschaften"
                items={[
                  "Einfache, intuitive Visualisierung",
                  "Schnelle funktionale Planung",
                  "Bis zu 100 Zählerplätze planen",
                  "Planung unabhängig von Endgeräten",
                  "Speichern von Favoriten",
                  "Schnittstelle zu StriePlan zur Feinplanung",
                  "ELBRIDGE-Schnittstelle",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default ComfortPlaner;
