const SET_SEMINAR_APPOINTMENT = "playbook/knx/SET_SEMINAR_APPOINTMENT";
const SET_NOTES = "playbook/knx/SET_NOTES";
const SET_SEMINAR_NOTE = "playbook/knx/SET_SEMINAR_NOTE";
const SET_ASSET = "playbook/knx/SET_ASSET";

export const initialState = {
  seminarAppointment: {
    description: "Termin für das Seminar",
    value: null,
    type: "info",
    orderID: null,
  },
  assetProgrammuebersicht: {
    description: "ABB i-bus® KNX Einbruchmeldetechnik / Brandmeldetechnik",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1697",
  },
  assetSmarterHome: {
    description: "KNX Visualisierungsmöglichkeiten (BJE)",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1640",
  },
  assetInnomag: {
    description: "Innomag 2020 (BJE)",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1738",
  },
  assetKNXFlyer: {
    description: "ABB KNX - Aktoren Flyer",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1724",
  },
  assetCombiaktor: {
    description: "Combiaktor",
    value: false,
    type: "info",
    orderID: null,
  },
  assetBuschTenton: {
    description: "Busch-Tenton",
    value: false,
    type: "info",
    orderID: null,
  },
  assetControlTouch: {
    description: "Control Touch",
    value: false,
    type: "info",
    orderID: null,
  },
  seminarNote: {
    description: "Notizen für das Seminar",
    value: "",
    type: "info",
    orderID: null,
  },
  notes: {
    description: "Notizen für das Kapitel",
    value: "",
    type: "info",
    orderID: null,
  },
};

const knx = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEMINAR_APPOINTMENT: {
      return {
        ...state,
        seminarAppointment: {
          ...state.seminarAppointment,
          value: action.seminarAppointment,
        },
      };
    }
    case SET_NOTES: {
      return {
        ...state,
        notes: {
          ...state.notes,
          value: action.notes,
        },
      };
    }
    case SET_SEMINAR_NOTE: {
      return {
        ...state,
        seminarNote: {
          ...state.seminarNote,
          value: action.note,
        },
      };
    }
    case SET_ASSET: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          value: action.value,
        },
      };
    }
    default:
      return state;
  }
};

//actions
export const setSeminarAppointment = (seminarAppointment) => ({
  type: SET_SEMINAR_APPOINTMENT,
  seminarAppointment,
});

export const setAsset = (id, value) => ({
  type: SET_ASSET,
  id,
  value,
});

export const setKnxNotes = (notes) => ({
  type: SET_NOTES,
  notes,
});

export const setSeminarNote = (note) => ({
  type: SET_SEMINAR_NOTE,
  note,
});

//selector
export const getSeminarAppointment = (state) => state.knx.seminarAppointment;
export const getKnxNotes = (state) => state.knx.notes;
export const getSeminarNote = (state) => state.knx.seminarNote;
export const getAssetTechnDaten = (state) => state.knx.assetTechnDaten;
export const getAssetProgrammuebersicht = (state) =>
  state.knx.assetProgrammuebersicht;
export const getAssetSmarterHome = (state) => state.knx.assetSmarterHome;
export const getAssetInnomag = (state) => state.knx.assetInnomag;
export const getAssetCombiaktor = (state) => state.knx.assetCombiaktor;
export const getAssetIotDashboard = (state) => state.knx.assetIotDashboard;
export const getAssetBuschTenton = (state) => state.knx.assetBuschTenton;
export const getAssetControlTouch = (state) => state.knx.assetControlTouch;
export const getKnxData = (state) => state.knx;

export default knx;
