const SET_DATE = "playbook/setup/SET_DATE";
const SET_COMPANY = "playbook/setup/SET_COMPANY";
const SET_SALUTATION = "playbook/setup/SET_SALUTATION";
const SET_FIRSTNAME = "playbook/setup/SET_FIRSTNAME";
const SET_LASTNAME = "playbook/setup/SET_LASTNAME";
const SET_EMAIL = "playbook/setup/SET_EMAIL";

const initialState = {
  selectedDate: new Date().toISOString(),
  company: "",
  salutation: "",
  firstname: "",
  lastname: "",
  email: "",
};

const setup = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATE: {
      return {
        ...state,
        selectedDate: action.selectedDate,
      };
    }
    case SET_COMPANY: {
      return {
        ...state,
        company: action.company,
      };
    }
    case SET_SALUTATION: {
      return {
        ...state,
        salutation: action.salutation ? action.salutation : "Herr",
      };
    }
    case SET_FIRSTNAME: {
      return {
        ...state,
        firstname: action.firstname,
      };
    }
    case SET_LASTNAME: {
      return {
        ...state,
        lastname: action.lastname,
      };
    }
    case SET_EMAIL: {
      return {
        ...state,
        email: action.email.toLowerCase().trim(),
      };
    }
    default:
      return state;
  }
};

//actions
export const setDate = (selectedDate) => ({
  type: SET_DATE,
  selectedDate,
});

export const setCompany = (company) => ({
  type: SET_COMPANY,
  company,
});

export const setSalutation = (salutation) => ({
  type: SET_SALUTATION,
  salutation,
});

export const setFirstname = (firstname) => ({
  type: SET_FIRSTNAME,
  firstname,
});

export const setLastname = (lastname) => ({
  type: SET_LASTNAME,
  lastname,
});

export const setEmail = (email) => ({
  type: SET_EMAIL,
  email,
});

//selector
export const getSelectedDate = (state) => state.setup.selectedDate;
export const getCompany = (state) => state.setup.company;
export const getSalutation = (state) => state.setup.salutation;
export const getFirstname = (state) => state.setup.firstname;
export const getLastname = (state) => state.setup.lastname;
export const getEmail = (state) => state.setup.email;
export const getSetupData = (state) => state.setup;

export default setup;
