import { Box, CircularProgress, Drawer, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { useEffect } from "preact/hooks";
import { useState } from "react";
import { Document, Outline, Page } from "react-pdf";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDrawer-paper": {
      overflowX: "hidden",
      width: "calc(200px + (16px * 2))",
      zIndex: 1500,
    },
    "& .react-pdf__Page__canvas": {
      borderRadius: "8px",
      margin: "0px auto",
      width: "auto !important",
      maxHeight: "176px",
      maxWidth: "176px",
      height: "auto !important",
    },
  },
  pageBox: {
    width: "176px",
    maxHeight: "176px",
    margin: theme.spacing(2),
    borderRadius: "10px",
    padding: theme.spacing(1),
    border: "4px solid",
    cursor: "pointer",
    "&:hover": {
      borderColor: `${grey[500]} !important`,
    },
  },
}));

export default function PDFDrawer({ open, onClose, pdf }) {
  const [isLoading, setIsLoading] = useState(true);
  const [pagesRendered, setPagesRendered] = useState(null);
  const classes = useStyles();
  const array = [];
  for (let i = 1; i < pdf.numPages; i++) {
    array.push({ pageNumber: i, loaded: false });
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setIsLoading(false);
    setPagesRendered(0);
  };

  const onRenderSuccess = (instance) => {
    setPagesRendered((v) => v + 1);
  };

  return (
    <Drawer
      ModalProps={{ disableScrollLock: true }}
      anchor="left"
      open={open}
      onClose={onClose}
      className={classes.root}
    >
      {isLoading && (
        <Box
          width="100%"
          height="100dvh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress color="primary" />
        </Box>
      )}
      <Document
        options={pdf.options}
        onLoadSuccess={onDocumentLoadSuccess}
        file={pdf.file}
        loading={<></>}
      >
        {Array.from(new Array(pagesRendered + 1), (el, index) => {
          const isCurrentlyRendering = pagesRendered + 1 === index + 1;
          const isLastPage = pdf.numPages === index + 1;
          const needsCallbackToRenderNextPage =
            isCurrentlyRendering && !isLastPage;
          return (
            <Box
              onClick={() => pdf.setPageNumber(index + 1)}
              borderColor={`${
                grey[pdf.pageNumber == index + 1 ? 700 : 300]
              } !important`}
              className={classes.pageBox}
            >
              <Page
                key={`page_${index + 1}`}
                onRenderSuccess={
                  needsCallbackToRenderNextPage ? onRenderSuccess : null
                }
                pageNumber={index + 1}
              />
            </Box>
          );
        })}
      </Document>
    </Drawer>
  );
}
