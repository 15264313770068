import { h } from "preact";
import { Box, Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  header: {
    fontFamily: "Averta-Regular",
    fontSize: "clamp(14px, 1vw, 1.125rem)",
  },
  ml: {
    marginLeft: "1.5%",
  },
  content: {
    fontSize: "clamp(18px, 1vw, 1.125rem)",
  },
}));

export default function QActionPaper({ title, context, children, padding }) {
  const classes = useStyles();
  return (
    <Box p={padding}>
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <Typography
            className={clsx({
              [classes.header]: true,
              [classes.ml]: context === "dialog",
            })}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Paper square elevation={3}>
            <Box p={1} className={classes.content}>
              {children}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

QActionPaper.defaultProps = {
  title: "",
  context: "dialog",
  children: [],
  padding: 2,
};
