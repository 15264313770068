import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "sticky",
    backgroundColor: "var(--grey-20)",
    color: "black",
  },
  toolbar: {
      display: "flex",
      justifyContent: "space-between"
  }
}));

export default function QAppBar({ text, closeHandler }) {
  const classes = useStyles();
  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolbar} >
        <Typography variant="h6" className={classes.title}>
          {text}
        </Typography>
        <IconButton autoFocus color="inherit" onClick={closeHandler}>
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
