const SET_SEMINAR_APPOINTMENT = "playbook/welcome/SET_SEMINAR_APPOINTMENT";
const SET_NOTES = "playbook/welcome/SET_NOTES";
const SET_SEMINAR_NOTE = "playbook/welcome/SET_SEMINAR_NOTE";
const SET_ASSET = "playbook/welcome/SET_ASSET";

export const initialState = {
  seminarAppointment: {
    description: "Termin für das Seminar",
    value: null,
    type: "info",
    orderID: null,
  },
  assetSortimentsbroschüre: {
    description: "Sortimentsbroschüre",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1673",
  },
  assetSystemhandbuch: {
    description: "Systemhandbuch",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1614",
  },
  assetSondermustertafel: {
    description: "Sondermustertafel",
    value: false,
    type: "info",
    orderID: "2CKA000002A1306",
  },
  assetMusterkofferWelcome: {
    description: "Musterkoffer Welcome",
    value: false,
    type: "info",
    orderID: "2CKA000002A1336",
  },
  assetBuschWelcomeFlyerDINLang: {
    description: "Busch-Welcome Flyer DIN Lang",
    value: 0,
    type: "order",
    orderID: "2CKA000001A1602",
  },
  seminarNote: {
    description: "Notizen für das Seminar",
    value: "",
    type: "info",
    orderID: null,
  },
  notes: {
    description: "Notizen für das Kapitel",
    value: "",
    type: "info",
    orderID: null,
  },
};

const welcome = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEMINAR_APPOINTMENT: {
      return {
        ...state,
        seminarAppointment: {
          ...state.seminarAppointment,
          value: action.seminarAppointment,
        },
      };
    }
    case SET_NOTES: {
      return {
        ...state,
        notes: {
          ...state.notes,
          value: action.notes,
        },
      };
    }
    case SET_SEMINAR_NOTE: {
      return {
        ...state,
        seminarNote: {
          ...state.seminarNote,
          value: action.note,
        },
      };
    }
    case SET_ASSET: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          value: action.value,
        },
      };
    }
    default:
      return state;
  }
};

//actions
export const setSeminarAppointment = (seminarAppointment) => ({
  type: SET_SEMINAR_APPOINTMENT,
  seminarAppointment,
});

export const setAsset = (id, value) => ({
  type: SET_ASSET,
  id,
  value,
});

export const setWelcomeNotes = (notes) => ({
  type: SET_NOTES,
  notes,
});

export const setSeminarNote = (note) => ({
  type: SET_SEMINAR_NOTE,
  note,
});

//selector
export const getSeminarAppointment = (state) =>
  state.welcome.seminarAppointment;
export const getWelcomeNotes = (state) => state.welcome.notes;
export const getSeminarNote = (state) => state.welcome.seminarNote;
export const getAssetSortimentsbroschüre = (state) =>
  state.welcome.assetSortimentsbroschüre;
export const getAssetSystemhandbuch = (state) =>
  state.welcome.assetSystemhandbuch;
export const getAssetSonderustertafel = (state) =>
  state.welcome.assetSondermustertafel;
export const getWelcomeData = (state) => state.welcome;

export default welcome;
