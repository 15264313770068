// Images free@homeSchulung
import ImageSchulung1 from "../../assets/free-at-home/Schulung/seminare.webp";
import ImageSchulung2 from "../../assets/free-at-home/Schulung/techniktalk.webp";
import ImageSchulung3 from "../../assets/free-at-home/Schulung/raumbuch.webp";
import ImageSchulung4 from "../../assets/free-at-home/Schulung/5.webp";
import ImageSchulung5 from "../../assets/free-at-home/Schulung/smarthomekonfigurator.webp";
import ImageSchulung6 from "../../assets/free-at-home/Schulung/community.webp";
import ImageSchulung7 from "../../assets/free-at-home/Schulung/elektrospicker-09.webp";

// Images free@home Marketing
import ImageMarketing1 from "../../assets/free-at-home/Schulung/mustertafel.webp";
import ImageMarketing2 from "../../assets/free-at-home/Marketing/sortimentsbroschuere.webp";
import ImageMarketing3 from "../../assets/free-at-home/Marketing/systemhandbuch.webp";
import ImageMarketing4 from "../../assets/free-at-home/Marketing/vermarktungspaket.webp";
import ImageMarketing5 from "../../assets/free-at-home/Marketing/4.webp";
import ImageMarketing6 from "../../assets/free-at-home/Marketing/grossflaeche.webp";
import ImageMarketing7 from "../../assets/free-at-home/Marketing/6.webp";

export const freeAtHomeUnterstuetzungMarketing = {
  title: "Marketing",
  items: [
    {
      title: "Mustertafel/Musterkoffer",
      img: ImageMarketing1,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/44FB6/product/2593",
        target: "_blank",
      },
    },
    {
      title: "Sortimentsbroschüre",
      img: ImageMarketing2,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/05D71/product/4068",
        target: "_blank",
      },
    },
    {
      title: "Systemhandbuch",
      img: ImageMarketing3,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/6EB0E/product/4012",
        target: "_blank",
      },
    },
    {
      title: "Vermarktungspaket",
      img: ImageMarketing4,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/B5687/product/5306",
        target: "_blank",
      },
    },
    {
      title: "Clever durch den Winter",
      img: ImageMarketing5,
      link: {
        href: "https://www.busch-jaeger.de/media/download/Broschueren/de/bje/2CKA000001A1861_8-seiter_Energieeffizienz_12_10.pdf",
        target: "_blank",
      },
    },
    {
      title: "18/1 Grossfläche",
      img: ImageMarketing6,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/05391/product/5282",
        target: "_blank",
      },
    },
    {
      title: "Smarter Home für mehr Wohnspaß",
      img: ImageMarketing7,
      link: {
        href: "https://www.flipedia.com/de/00005119/aktuell/",
        target: "_blank",
      },
    },
  ],
};

export const freeAtHomeUnterstuetzungSchulung = {
  title: "Schulung",
  items: [
    {
      title: "Seminare",
      img: ImageSchulung1,
      link: {
        href: "https://www.busch-jaeger.de/busch-jaeger/termine",
        target: "_blank",
      },
    },
    {
      title: "Technik-Talk",
      img: ImageSchulung2,
      link: {
        href: "https://www.youtube.com/playlist?list=PLW1uFXqHQgufhvVQ2v0kmm6xvIsjlF2--",
        target: "_blank",
      },
    },
    {
      title: "Heizkörpertherm. Raumbuch",
      img: ImageSchulung3,
      link: {
        href: "https://www.busch-jaeger-excellence.de/45/req-ns/2CF74/product/5239",
        target: "_blank",
      },
    },
    {
      title: "How-To",
      img: ImageSchulung4,
      link: {
        href: "https://www.busch-jaeger.de/files/files_ONLINE/HowTo%20%20Erste%20Schritte.pdf",
        target: "_blank",
      },
    },
    {
      title: "Konfigurator Smart Home",
      img: ImageSchulung5,
      link: {
        href: "https://smart-home-configurator.my.busch-jaeger.de/",
        target: "_blank",
      },
    },
    {
      title: "Busch-Jaeger Community",
      img: ImageSchulung6,
      link: {
        href: "https://community.busch-jaeger.de/",
        target: "_blank",
      },
    },
    {
      title: "ElektroSpicker 09",
      img: ImageSchulung7,
      link: {
        href: "https://library.e.abb.com/public/755414e7d8354d078b8cbb0499a2282a/9AKK107680A7839_Rev_B_09_Smart_Home_Beratung_DE_Elektrospicker_102020.pdf?x-sign=G+Nsujki2KKoUxGOknFbScG0B8OjhFChFgNr+an1mrammLZowwpsbKdgKAJSasJV",
        target: "_blank",
      },
    },
  ],
};
