import { h } from "preact";

export default function checkicon() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="150"
        viewBox="0 0 500 500"
        width="150"
        style=""
      >
        <g fill="none" fill-rule="evenodd" stroke-linejoin="bevel">
          <path
            d="m105 350 109 110 181-182"
            stroke="#000"
            stroke-width="20"
            class="PkwKLzSu_0"
          />
        </g>

        <style data-made-with="vivus-instant">
          {`
          .PkwKLzSu_0{stroke-dasharray:412 414;stroke-dashoffset:413;animation:PkwKLzSu_draw 1000ms ease 0ms forwards;}@keyframes PkwKLzSu_draw{100%{stroke-dashoffset:0;}}@keyframes PkwKLzSu_fade{0%{stroke-opacity:1;}93.54838709677419%{stroke-opacity:1;}100%{stroke-opacity:0;}}`}
        </style>
      </svg>
    </div>
  );
}
