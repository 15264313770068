import { combineReducers } from "redux";
import news from "./newsReducer";
import categories from "./categoriesReducer";
import consulting from "./consultingReducer";
import setup from "./setupReducer";
import settings from "./settingsReducer";
import freeathome from "./chapterFreeAtHome";
import missiontozero from "./chapterMissionToZero";
import welcome from "./chapterWelcome";
import welcomeip from "./chapterWelcomeIP";
import knx from "./chapterKnx";
import comfortline from "./chapterComfortLine";
import installationsgeraete from "./chapterInstallationsgeraete";
import beruehrungslosesschalten from "./chapterBeruehrungslosesSchalten";
import jahresgespraech2021 from "./chapterJahresgespraech2021";
import cwa from "./chapterCwa";
import jahresgespraech2022 from "./chapterJahresgespraech2022";
import serviceAndTools from "./chapterServiceAndTools";
import buschflex from "./chapterBuschFlex";
import uk600ak600 from "./chapterUk600AK600";
import abbSmarterMobility from "./chapterAbbSmarterMobility";
import lbInnovations from "./chapterLBInnovations";
import energieMonitoring from "./chapterEnergiemontoring";
import jahresgespraech2023 from "./chapterJahresgespraech2023";
import moderneElektroinstallation from "./chapterModerneElektroinstallation";
import usv from "./chapterUSV";
import jahresgespraech2024 from "./chapterJahresgespraech2024";
import flexline from "./chapterFlexLine";
import neuheiten2024 from "./chapterNeuheiten2024";
import user from "./userReducer";
import consultationStore from "./consultationStore";
import preparedConsultations from "./preparedConsultations";
import chapterSelections from "./chapterSelections";
import crmDB from "../../databases/crmDB";

const RESET_CONSULTING = "playbook/rootReducer/RESET_CONSULTING";
const RESET_APP = "playbook/rootReducer/RESET_APP";
const LOAD_CONSULTATION = "playbook/rootReducer/LOAD_CONSULTATION";

const reducers = {
  news,
  categories,
  consulting,
  setup,
  settings,
  freeathome,
  missiontozero,
  welcome,
  welcomeip,
  knx,
  comfortline,
  installationsgeraete,
  beruehrungslosesschalten,
  jahresgespraech2021,
  cwa,
  jahresgespraech2022,
  serviceAndTools,
  buschflex,
  uk600ak600,
  abbSmarterMobility,
  lbInnovations,
  energieMonitoring,
  jahresgespraech2023,
  moderneElektroinstallation,
  usv,
  jahresgespraech2024,
  flexline,
  neuheiten2024,
  user,
  consultationStore,
  preparedConsultations,
  chapterSelections,
};

const appReducer = combineReducers({
  ...reducers,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_CONSULTING) {
    const { settings, categories, user, consultationStore } = state;
    state = { settings, categories, user, consultationStore };
  }

  if (action.type === RESET_APP) {
    crmDB.delete().then(() => crmDB.open());
    state = undefined;
  }

  if (action.type === LOAD_CONSULTATION) {
    const { consultationStore, user, settings, categories } = state;
    let consultationStoreNew = {
      consultations: [],
    };
    consultationStoreNew.consultations = consultationStore.consultations.filter(
      (c) =>
        c.consulting.internalID !== action.consultation.consulting.internalID
    );

    state = {
      ...action.consultation,
      consultationStore: consultationStoreNew,
      user,
      settings,
      categories,
    };
  }

  return appReducer(state, action);
};

export const resetConsulting = () => ({
  type: RESET_CONSULTING,
});

export const resetApp = () => ({
  type: RESET_APP,
});

export const loadConsultation = (consultation) => ({
  type: LOAD_CONSULTATION,
  consultation,
});

export default rootReducer;
