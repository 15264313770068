export default function WirkungsweiseDerRaumaufschaltung() {
  const id = "wirkungsweise-der-raumaufschaltung-svg";
  return (
    <svg
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      id={id}
      viewBox="0 0 753.97 344.39"
    >
      <defs>
        <style>{`#${id} g, #${id} text, #${id} image, #${id} polyline, #${id} path, #${id} rect { pointer-events: none; } #${id} rect.hotspot { pointer-events: all; }#${id} text { font-weight: initial !important; font-family: Averta-Semibold !important; } #${id} use { pointer-events: none; } #${id} .cls-1{letter-spacing:-.01em;}#${id} .cls-2{stroke-dasharray:0 0 0 4;}#${id} .cls-2,#${id} .cls-3{stroke:#5e5e5e;stroke-linecap:round;}#${id} .cls-2,#${id} .cls-3,#${id} .cls-4{stroke-width:2px;}#${id} .cls-2,#${id} .cls-3,#${id} .cls-4,#${id} .cls-5{fill:none;stroke-miterlimit:10;}#${id} .cls-6{letter-spacing:-.02em;}#${id} .cls-7{letter-spacing:0em;}#${id} .cls-8{letter-spacing:0em;}#${id} .cls-9{letter-spacing:-.01em;}#${id} .cls-10{letter-spacing:0em;}#${id} .cls-11{letter-spacing:0em;}#${id} .cls-12{fill:#fff;}#${id} .cls-13{letter-spacing:-.01em;}#${id} .cls-14,#${id} .cls-15,#${id} .cls-16{font-family:Averta-Bold, 'Averta-Regular';font-weight:700;}#${id} .cls-14,#${id} .cls-17{font-size:12px;}#${id} .cls-18{font-family:Averta-Regular, 'Averta-Regular';}#${id} .cls-19{letter-spacing:-.02em;}#${id} .cls-15{font-size:14px;}#${id} .cls-20{letter-spacing:0em;}#${id} .cls-21{letter-spacing:0em;}#${id} .cls-4,#${id} .cls-5{stroke:#000;}#${id} .cls-22{letter-spacing:-.02em;}#${id} .cls-5{stroke-width:4px;}#${id} .cls-23{letter-spacing:-.03em;}#${id} .cls-24{letter-spacing:0em;}#${id} .cls-25{letter-spacing:-.01em;}#${id} .cls-26{letter-spacing:-.02em;}#${id} .cls-27{letter-spacing:-.08em;}#${id} .cls-28{letter-spacing:0em;}#${id} .cls-29{letter-spacing:-.01em;}#${id} .cls-30{letter-spacing:-.01em;}`}</style>
      </defs>
      <line class="cls-3" x1="244.12" y1="1" x2="244.12" y2="1" />
      <line class="cls-2" x1="244.12" y1="5" x2="244.12" y2="303" />
      <line class="cls-3" x1="244.12" y1="305" x2="244.12" y2="305" />
      <line class="cls-3" x1="516.12" y1="1" x2="516.12" y2="1" />
      <line class="cls-2" x1="516.12" y1="5" x2="516.12" y2="303" />
      <line class="cls-3" x1="516.12" y1="305" x2="516.12" y2="305" />
      <path d="m31.06,237.74l-4-4v-120.68l1.18-2.88L109.94,27.17l2.92-1.22h14.9l2.99,1.3,28.11,30.02v-12.67l4-4h28.05l4,4v51.18l13.54,14.46,1.11,2.81v120.69l-4,4H31.06Zm2.76-123.6v116.83h168.97v-116.88L126.6,32.71h-12.62L33.82,114.14Zm131.8-50.06l22.53,24.52v-41.25h-22.53v16.73Z" />
      <path
        class="cls-12"
        d="m127.45,27.45l2.42,1.05,25.3,27.02,5.19,5.54v-15.85l3.12-3.12h26.8l3.12,3.12v51.16l.81.87,12.94,13.82.9,2.28v119.78l-3.12,3.12H31.68l-3.12-3.12v-119.76l.95-2.33L110.79,28.43l2.37-.99h14.29M32.32,232.47h171.97v-118.97l-.81-.87-75.34-80.48-.89-.95h-13.9l-.88.9L33.18,112.65l-.86.88v118.95M189.65,92.45v-46.6h-25.53v18.81l.79.86,19.53,21.25,5.21,5.67m-61.58-68.01h-15.51l-3.48,1.45L26.96,109.33l-1.4,3.43v121.6l4.88,4.88h175.74l4.88-4.88v-121.6l-1.32-3.34-13.33-14.23v-51.21l-4.88-4.88h-29.29l-4.88,4.88v9.49l-25.73-27.48-3.56-1.54h0ZM35.32,229.47v-114.72L114.6,34.21h11.34l75.34,80.48v114.79H35.32ZM186.65,84.75l-19.53-21.25v-14.64h19.53v35.9h0Z"
      />
      <text class="cls-14" transform="translate(104.22 82.72)">
        <tspan x="0" y="0">
          Ohne{" "}
        </tspan>
        <tspan class="cls-1" x="-37.51" y="14.4">
          Raumaufschaltung
        </tspan>
      </text>
      <text class="cls-17" transform="translate(48 148.75)">
        <tspan class="cls-18">
          <tspan x="0" y="0">
            Stellgröße:{" "}
          </tspan>
        </tspan>
        <tspan class="cls-16">
          <tspan x="0" y="14.4">
            5 %
          </tspan>
        </tspan>
      </text>
      <g id="pictogram">
        <path d="m43.53,34.15c-.95-3.52-3.4-6.48-6.57-8.25-1.75-4.67-6.22-7.78-11.23-7.78-6.62,0-12,5.38-12,12,0,2.12.57,4.2,1.62,6.02-4.23.2-7.62,3.7-7.62,7.98s3.59,8,8,8h27c4.96,0,9-4.04,9-9,0-4.69-3.61-8.56-8.2-8.97Zm-17.8-14.03c3.45,0,6.6,1.78,8.41,4.59-1.25-.38-2.57-.59-3.91-.59-6.39,0-11.94,4.53-13.24,10.85-.82-1.48-1.26-3.15-1.26-4.85,0-5.51,4.49-10,10-10Zm17,30H15.73c-3.31,0-6-2.69-6-6s2.69-6,6-6h2l.99-.88.24-1.92c1.08-5.33,5.82-9.2,11.27-9.2s10.46,3.95,11.52,9.2l.98.8c3.86,0,7,3.14,7,7s-3.14,7-7,7Z" />
        <rect x="24.73" y="12.12" width="2" height="4" />
        <rect x="7.73" y="29.12" width="4" height="2" />
        <rect
          x="13.53"
          y="16.94"
          width="2"
          height="3.96"
          transform="translate(-9.12 15.81) rotate(-45)"
        />
        <rect
          x="34.95"
          y="17.92"
          width="3.96"
          height="2"
          transform="translate(-2.56 31.65) rotate(-45)"
        />
      </g>
      <text class="cls-15" transform="translate(13.37 68.86)">
        <tspan x="0" y="0">
          -5°C
        </tspan>
      </text>
      <text class="cls-17" transform="translate(149.99 174.07)">
        <tspan class="cls-18">
          <tspan x="0" y="0">
            Sollwert:{" "}
          </tspan>
        </tspan>
        <tspan class="cls-16">
          <tspan x="0" y="14.4">
            21,0°C
          </tspan>
        </tspan>
      </text>
      <text class="cls-17" transform="translate(149.99 209.05)">
        <tspan class="cls-18">
          <tspan x="0" y="0">
            Istwert:{" "}
          </tspan>
        </tspan>
        <tspan class="cls-16">
          <tspan class="cls-1" x="0" y="14.4">
            24,0°C
          </tspan>
        </tspan>
      </text>
      <path d="m115.29,182.72v42H49.29v-42h66m2-2H47.29v46h70v-46h0Z" />
      <path d="m111.29,186.79v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m103.29,186.79v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m95.29,186.79v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m87.29,186.79v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m79.29,186.79v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m71.29,186.79v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m63.29,186.79v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m55.29,186.79v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <rect x="44.3" y="184.79" width="3" height="10" />
      <rect x="44.3" y="212.79" width="3" height="10" />
      <rect x="117.3" y="212.79" width="3" height="10" />
      <rect x="117.3" y="186.79" width="4" height="6" />
      <polygon
        class="cls-4"
        points="121.47 182.23 134.73 183.77 135.84 193.26 121.33 195.07 121.47 182.23"
      />
      <polyline
        class="cls-4"
        points="121.3 186.79 129.33 186.74 129.33 182.46"
      />
      <polygon points="135.01 185.16 137.1 185.3 137.8 191.16 135.84 191.72 135.01 185.16" />
      <polygon points="123.42 194.65 123.42 196.88 134.87 195.21 134.87 193.26 123.42 194.65" />
      <line class="cls-4" x1="45.7" y1="215.58" x2="30.26" y2="215.58" />
      <line class="cls-4" x1="45.7" y1="219.58" x2="30.26" y2="219.58" />
      <line class="cls-5" x1="31.03" y1="113.16" x2="205.73" y2="113.16" />
      <path d="m9.06,256.92h2.1l2.46,7.21h.03l2.46-7.21h2.06l-3.68,9.94h-1.74l-3.7-9.94Z" />
      <path d="m19.61,266.53c-.55-.3-.98-.72-1.28-1.26-.3-.54-.46-1.15-.46-1.83s.15-1.29.46-1.82c.3-.53.73-.95,1.28-1.25.55-.3,1.19-.46,1.9-.46s1.33.15,1.88.44.98.71,1.29,1.25c.31.54.46,1.15.46,1.84s-.15,1.3-.46,1.84c-.31.54-.74.96-1.29,1.25s-1.18.45-1.88.45-1.35-.15-1.9-.46Zm3.16-1.73c.34-.35.51-.81.51-1.36s-.17-1.01-.5-1.36c-.34-.35-.76-.53-1.26-.53s-.95.18-1.28.54c-.33.36-.5.81-.5,1.35s.17.99.5,1.35c.33.36.76.54,1.28.54.5,0,.91-.18,1.25-.53Z" />
      <path d="m26.58,260.01h1.83v.99h.03c.26-.35.57-.61.92-.81.35-.19.73-.29,1.13-.29h.14v2c-.13,0-.29-.01-.49-.01-.55,0-.98.17-1.28.5-.3.33-.46.76-.46,1.29v3.16h-1.83v-6.85Z" />
      <path d="m31.88,256.56h1.83v10.3h-1.83v-10.3Z" />
      <path d="m36.8,266.55c-.49-.29-.88-.71-1.16-1.25-.28-.54-.42-1.15-.42-1.84s.14-1.29.42-1.83c.28-.54.66-.96,1.15-1.26.49-.3,1.04-.46,1.65-.46.4,0,.78.08,1.13.23.35.15.65.38.89.67h.03v-.8h1.83v6.85h-1.75v-.81h-.03c-.5.62-1.18.94-2.06.94-.63,0-1.19-.15-1.68-.44Zm3.26-1.73c.34-.34.5-.79.5-1.34s-.17-1.01-.5-1.37c-.33-.36-.74-.55-1.24-.55s-.92.17-1.25.53c-.33.35-.5.81-.5,1.39s.16,1,.49,1.34c.33.35.75.52,1.26.52.49,0,.9-.17,1.23-.52Z" />
      <path d="m43.94,264.14v-4.13h1.82v3.65c0,.62.1,1.05.29,1.29s.51.37.95.37.78-.18,1.07-.53.44-.84.44-1.46v-3.33h1.85v6.85h-1.85v-.81h-.03c-.25.29-.55.53-.9.69s-.72.25-1.11.25c-1.68,0-2.52-.95-2.52-2.84Z" />
      <path d="m52.36,261.54h-.71v-1.53h.71v-1.16c0-.79.19-1.4.56-1.81.37-.41.91-.62,1.6-.62.37,0,.71.05,1.02.15.3.1.55.22.75.35l-.38,1.43c-.43-.21-.77-.31-1.01-.31-.46,0-.69.31-.69.92v1.04h1.51v1.53h-1.51v5.32h-1.85v-5.32Z" />
      <path d="m60.13,265.6l3.29-4.03v-.04h-3.04v-1.51h5.52v1.25l-3.33,4.06v.03h3.25v1.51h-5.68v-1.26Z" />
      <path d="m67.2,264.14v-4.13h1.82v3.65c0,.62.1,1.05.29,1.29s.51.37.95.37.78-.18,1.07-.53.44-.84.44-1.46v-3.33h1.85v6.85h-1.85v-.81h-.03c-.25.29-.55.53-.9.69s-.72.25-1.11.25c-1.68,0-2.52-.95-2.52-2.84Z" />
      <path d="m78.97,256.56h1.83v4.28h.01c.28-.31.61-.54.99-.69s.8-.23,1.25-.23c.78,0,1.4.22,1.84.67.44.45.67,1.17.67,2.17v4.1h-1.83v-3.64c0-.61-.11-1.04-.33-1.29-.22-.25-.55-.38-1-.38-.5,0-.9.16-1.18.47s-.42.77-.42,1.38v3.46h-1.83v-10.3Z" />
      <path d="m88.63,266.53c-.55-.3-.98-.72-1.28-1.26-.3-.54-.46-1.15-.46-1.83s.15-1.29.46-1.82c.3-.53.73-.95,1.28-1.25.55-.3,1.19-.46,1.9-.46s1.33.15,1.88.44.98.71,1.29,1.25c.31.54.46,1.15.46,1.84s-.15,1.3-.46,1.84c-.31.54-.74.96-1.29,1.25s-1.18.45-1.88.45-1.35-.15-1.9-.46Zm3.16-1.73c.34-.35.51-.81.51-1.36s-.17-1.01-.5-1.36c-.34-.35-.76-.53-1.26-.53s-.95.18-1.28.54c-.33.36-.5.81-.5,1.35s.17.99.5,1.35c.33.36.76.54,1.28.54.5,0,.91-.18,1.25-.53Z" />
      <path d="m96.94,266.54c-.54-.3-.96-.71-1.26-1.25-.3-.53-.46-1.14-.46-1.83s.15-1.32.46-1.85c.3-.53.73-.95,1.27-1.25.54-.3,1.16-.45,1.86-.45.56,0,1.1.1,1.61.31.51.21.94.52,1.29.92l-1.2,1.13c-.19-.22-.42-.4-.69-.53s-.56-.2-.88-.2c-.55,0-1,.17-1.34.53-.34.35-.51.8-.51,1.36s.17,1.03.51,1.37c.34.35.79.52,1.34.52.34,0,.64-.07.92-.2s.51-.31.69-.55l1.19,1.15c-.67.83-1.67,1.25-2.98,1.25-.67,0-1.28-.15-1.81-.45Z" />
      <path d="m103,256.56h1.83v4.28h.01c.28-.31.61-.54.99-.69s.8-.23,1.25-.23c.78,0,1.4.22,1.84.67.44.45.67,1.17.67,2.17v4.1h-1.83v-3.64c0-.61-.11-1.04-.33-1.29-.22-.25-.55-.38-1-.38-.5,0-.9.16-1.18.47s-.42.77-.42,1.38v3.46h-1.83v-10.3Z" />
      <path d="m111.42,261.84c-.22-.22-.34-.49-.34-.81s.11-.57.34-.79c.23-.22.5-.33.82-.33s.57.11.8.33c.22.22.34.48.34.79s-.11.59-.34.81c-.22.22-.49.33-.8.33-.33,0-.6-.11-.83-.33Zm0,4.82c-.22-.22-.34-.49-.34-.8s.11-.57.34-.79c.23-.22.5-.33.82-.33s.57.11.8.33c.22.22.34.48.34.79s-.11.59-.34.8c-.22.22-.49.33-.8.33-.33,0-.6-.11-.83-.33Z" />
      <path d="m10.6,280.25c-.28-.28-.42-.61-.42-1.01s.14-.74.42-1.02.62-.41,1.01-.41.74.14,1.03.41c.28.28.43.61.43,1.02s-.14.74-.43,1.01c-.29.28-.63.41-1.03.41s-.73-.14-1.01-.41Z" />
      <path d="m27.99,273.72h6.09v1.11h-4.87v3.33h3.99v1.11h-3.99v3.29h5.17v1.11h-6.38v-9.94Z" />
      <path d="m36.13,276.84h1.13v1.23h.01c.27-.42.62-.74,1.05-.97.43-.23.91-.34,1.43-.34.76,0,1.35.24,1.79.72.44.48.66,1.19.66,2.14v4.05h-1.13v-3.72c0-1.43-.56-2.14-1.68-2.14-.65,0-1.17.22-1.55.66-.38.44-.57,1.04-.57,1.81v3.4h-1.13v-6.82Z" />
      <path d="m50.24,280.41c0,.07-.01.13-.01.18h-5.32c.07.64.32,1.16.74,1.54.42.38.95.57,1.6.57.82,0,1.48-.3,1.97-.91l.8.67c-.66.85-1.6,1.27-2.81,1.27-.66,0-1.26-.14-1.78-.43-.52-.29-.93-.7-1.22-1.23s-.44-1.14-.44-1.82.14-1.27.43-1.81c.29-.53.69-.95,1.2-1.25s1.08-.45,1.73-.45c.61,0,1.15.14,1.62.43.48.29.85.69,1.11,1.2.27.51.4,1.09.4,1.74,0,.11,0,.21-.01.28Zm-4.56-2.11c-.4.34-.65.79-.75,1.37h4.19c-.08-.57-.3-1.02-.66-1.37s-.81-.51-1.36-.51-1.02.17-1.42.5Z" />
      <path d="m51.92,276.84h1.13v1.25h.03c.25-.42.57-.75.95-.98s.8-.35,1.27-.35c.12,0,.21,0,.28.01v1.26l-.45-.03c-.63,0-1.14.22-1.51.66s-.56,1.01-.56,1.71v3.29h-1.13v-6.82Z" />
      <path d="m56.89,285.77l.28-1.01c.43.24.83.42,1.2.54.37.12.79.17,1.25.17.74,0,1.3-.19,1.69-.57.39-.38.59-.87.59-1.48v-1.09h-.03c-.26.37-.59.67-.99.88s-.86.32-1.37.32c-.62,0-1.18-.14-1.68-.42-.5-.28-.9-.68-1.19-1.19-.29-.51-.43-1.1-.43-1.76s.14-1.23.41-1.74c.27-.52.66-.92,1.16-1.22.5-.29,1.06-.44,1.69-.44.49,0,.94.1,1.36.31.42.21.76.5,1.04.87h.03v-1.09h1.13v6.31c0,1.04-.28,1.85-.83,2.46-.55.6-1.4.9-2.53.9-1.03,0-1.95-.25-2.77-.74Zm3.95-3.6c.34-.21.61-.49.8-.85.19-.36.29-.77.29-1.22s-.1-.85-.3-1.2c-.2-.35-.47-.62-.82-.81-.34-.19-.73-.29-1.15-.29-.46,0-.86.1-1.21.3-.35.2-.62.48-.81.83-.19.35-.29.75-.29,1.19,0,.47.1.88.3,1.24.2.36.48.63.83.83.35.19.74.29,1.17.29s.84-.1,1.19-.31Z" />
      <path d="m65.12,275.36c-.16-.16-.24-.35-.24-.57s.08-.42.24-.57c.16-.16.35-.24.59-.24s.43.08.59.24.24.35.24.57-.08.42-.24.57-.35.24-.59.24-.43-.08-.59-.24Zm.01,1.48h1.13v6.82h-1.13v-6.82Z" />
      <path d="m74.53,280.41c0,.07-.01.13-.01.18h-5.32c.07.64.32,1.16.74,1.54.42.38.95.57,1.6.57.82,0,1.48-.3,1.97-.91l.8.67c-.66.85-1.6,1.27-2.81,1.27-.66,0-1.26-.14-1.78-.43-.52-.29-.93-.7-1.22-1.23s-.44-1.14-.44-1.82.14-1.27.43-1.81c.29-.53.69-.95,1.2-1.25s1.08-.45,1.73-.45c.61,0,1.15.14,1.62.43.48.29.85.69,1.11,1.2.27.51.4,1.09.4,1.74,0,.11,0,.21-.01.28Zm-4.56-2.11c-.4.34-.65.79-.75,1.37h4.19c-.08-.57-.3-1.02-.66-1.37s-.81-.51-1.36-.51-1.02.17-1.42.5Z" />
      <path d="m75.17,276.84h1.23l2.09,5.38h.01l2.09-5.38h1.22l-2.77,6.82h-1.09l-2.77-6.82Z" />
      <path d="m88.85,280.41c0,.07-.01.13-.01.18h-5.32c.07.64.32,1.16.74,1.54.42.38.95.57,1.6.57.82,0,1.48-.3,1.97-.91l.8.67c-.66.85-1.6,1.27-2.81,1.27-.66,0-1.26-.14-1.78-.43-.52-.29-.93-.7-1.22-1.23s-.44-1.14-.44-1.82.14-1.27.43-1.81c.29-.53.69-.95,1.2-1.25s1.08-.45,1.73-.45c.61,0,1.15.14,1.62.43.48.29.85.69,1.11,1.2.27.51.4,1.09.4,1.74,0,.11,0,.21-.01.28Zm-4.56-2.11c-.4.34-.65.79-.75,1.37h4.19c-.08-.57-.3-1.02-.66-1.37s-.81-.51-1.36-.51-1.02.17-1.42.5Z" />
      <path d="m90.53,276.84h1.13v1.25h.03c.25-.42.57-.75.95-.98s.8-.35,1.27-.35c.12,0,.21,0,.28.01v1.26l-.45-.03c-.63,0-1.14.22-1.51.66s-.56,1.01-.56,1.71v3.29h-1.13v-6.82Z" />
      <path d="m95.56,273.36h1.13v10.3h-1.13v-10.3Z" />
      <path d="m99.42,283.04c-.45-.47-.67-1.18-.67-2.13v-4.06h1.13v3.74c0,.75.14,1.29.41,1.62s.69.5,1.25.5c.37,0,.71-.1,1.02-.31.31-.21.55-.5.73-.89.18-.39.27-.84.27-1.37v-3.3h1.13v6.82h-1.13v-1.18h-.01c-.25.4-.58.71-.97.93s-.82.33-1.28.33c-.81,0-1.44-.24-1.89-.71Z" />
      <path d="m106.17,282.78l.57-.87c.28.27.58.48.91.62.33.14.72.21,1.18.21.4,0,.72-.09.95-.26.23-.17.35-.39.35-.67,0-.19-.06-.34-.18-.46-.12-.12-.27-.22-.43-.29-.17-.07-.5-.2-1.01-.38-.67-.23-1.18-.5-1.53-.79-.35-.29-.52-.73-.52-1.29,0-.34.1-.66.29-.94.2-.28.46-.5.81-.66.34-.16.72-.24,1.14-.24.88,0,1.66.28,2.35.84l-.59.9c-.52-.49-1.12-.73-1.81-.73-.28,0-.53.08-.74.22s-.31.35-.31.6.11.44.32.59c.21.15.54.31.97.47l.34.13c.51.2.9.37,1.17.51.27.14.48.33.64.57.16.23.24.54.24.91,0,.57-.21,1.04-.63,1.41-.42.37-1.03.56-1.82.56-1.04,0-1.92-.32-2.66-.97Z" />
      <path d="m113.04,281.63v-3.81h-.88v-.98h.88v-2.32l1.13-.13v2.45h2.25v.98h-2.25v3.64c0,.43.06.74.18.93.12.19.34.29.64.29.35,0,.73-.12,1.16-.35l.29.99c-.22.13-.46.23-.71.31s-.56.11-.94.11c-1.18,0-1.76-.71-1.76-2.11Z" />
      <path d="m10.6,297.05c-.28-.28-.42-.61-.42-1.01s.14-.74.42-1.02.62-.41,1.01-.41.74.14,1.03.41c.28.28.43.61.43,1.02s-.14.74-.43,1.01c-.29.28-.63.41-1.03.41s-.73-.14-1.01-.41Z" />
      <path d="m27.22,299.58l.57-.87c.28.27.58.48.91.62.33.14.72.21,1.18.21.4,0,.72-.09.95-.26.23-.17.35-.39.35-.67,0-.19-.06-.34-.18-.46-.12-.12-.27-.22-.43-.29-.17-.07-.5-.2-1.01-.38-.67-.23-1.18-.5-1.53-.79-.35-.29-.52-.73-.52-1.29,0-.34.1-.66.29-.94.2-.28.46-.5.81-.66.34-.16.72-.24,1.14-.24.88,0,1.66.28,2.35.84l-.59.9c-.52-.49-1.12-.73-1.81-.73-.28,0-.53.08-.74.22s-.31.35-.31.6.11.44.32.59c.21.15.54.31.97.47l.34.13c.51.2.9.37,1.17.51.27.14.48.33.64.57.16.23.24.54.24.91,0,.57-.21,1.04-.63,1.41-.42.37-1.03.56-1.82.56-1.04,0-1.92-.32-2.66-.97Z" />
      <path d="m35.16,300.1c-.52-.29-.92-.7-1.21-1.23-.29-.53-.43-1.13-.43-1.81s.14-1.29.43-1.81c.29-.53.69-.94,1.22-1.24.52-.3,1.12-.45,1.79-.45.52,0,1.03.11,1.52.32s.9.51,1.23.9l-.77.69c-.22-.26-.5-.47-.83-.63-.33-.16-.69-.24-1.08-.24-.7,0-1.26.23-1.69.69-.43.46-.64,1.05-.64,1.78s.21,1.32.64,1.77c.43.45.99.68,1.68.68.4,0,.77-.08,1.11-.24.34-.16.62-.37.83-.63l.78.71c-.31.36-.7.65-1.16.87s-1.02.32-1.65.32-1.24-.15-1.76-.44Z" />
      <path d="m41.24,290.16h1.13v4.68h.01c.27-.41.62-.73,1.06-.95.43-.22.92-.33,1.46-.33.76,0,1.37.23,1.81.7s.67,1.17.67,2.11v4.09h-1.13v-3.77c0-1.4-.56-2.1-1.68-2.1-.68,0-1.22.21-1.61.64-.39.43-.59,1.03-.59,1.79v3.43h-1.13v-10.3Z" />
      <path d="m49.46,290.16h1.13v10.3h-1.13v-10.3Z" />
      <path d="m58.85,297.21c0,.07-.01.13-.01.18h-5.32c.07.64.32,1.16.74,1.54.42.38.95.57,1.6.57.82,0,1.48-.3,1.97-.91l.8.67c-.66.85-1.6,1.27-2.81,1.27-.66,0-1.26-.14-1.78-.43-.52-.29-.93-.7-1.22-1.23s-.44-1.14-.44-1.82.14-1.27.43-1.81c.29-.53.69-.95,1.2-1.25s1.08-.45,1.73-.45c.61,0,1.15.14,1.62.43.48.29.85.69,1.11,1.2.27.51.4,1.09.4,1.74,0,.11,0,.21-.01.28Zm-4.56-2.11c-.4.34-.65.79-.75,1.37h4.19c-.08-.57-.3-1.02-.66-1.37s-.81-.51-1.36-.51-1.02.17-1.42.5Z" />
      <path d="m61.74,300.1c-.52-.29-.92-.7-1.21-1.23-.29-.53-.43-1.13-.43-1.81s.14-1.29.43-1.81c.29-.53.69-.94,1.22-1.24.52-.3,1.12-.45,1.79-.45.52,0,1.03.11,1.52.32s.9.51,1.23.9l-.77.69c-.22-.26-.5-.47-.83-.63-.33-.16-.69-.24-1.08-.24-.7,0-1.26.23-1.69.69-.43.46-.64,1.05-.64,1.78s.21,1.32.64,1.77c.43.45.99.68,1.68.68.4,0,.77-.08,1.11-.24.34-.16.62-.37.83-.63l.78.71c-.31.36-.7.65-1.16.87s-1.02.32-1.65.32-1.24-.15-1.76-.44Z" />
      <path d="m67.82,290.16h1.13v4.68h.01c.27-.41.62-.73,1.06-.95.43-.22.92-.33,1.46-.33.76,0,1.37.23,1.81.7s.67,1.17.67,2.11v4.09h-1.13v-3.77c0-1.4-.56-2.1-1.68-2.1-.68,0-1.22.21-1.61.64-.39.43-.59,1.03-.59,1.79v3.43h-1.13v-10.3Z" />
      <path d="m76.11,298.43v-3.81h-.88v-.98h.88v-2.32l1.13-.13v2.45h2.25v.98h-2.25v3.64c0,.43.06.74.18.93.12.19.34.29.64.29.35,0,.73-.12,1.16-.35l.29.99c-.22.13-.46.23-.71.31s-.56.11-.94.11c-1.18,0-1.76-.71-1.76-2.11Z" />
      <path d="m84.82,293.64h1.13v1.25h.03c.25-.42.57-.75.95-.98s.8-.35,1.27-.35c.12,0,.21,0,.28.01v1.26l-.45-.03c-.63,0-1.14.22-1.51.66s-.56,1.01-.56,1.71v3.29h-1.13v-6.82Z" />
      <path d="m95.58,297.21c0,.07-.01.13-.01.18h-5.32c.07.64.32,1.16.74,1.54.42.38.95.57,1.6.57.82,0,1.48-.3,1.97-.91l.8.67c-.66.85-1.6,1.27-2.81,1.27-.66,0-1.26-.14-1.78-.43-.52-.29-.93-.7-1.22-1.23s-.44-1.14-.44-1.82.14-1.27.43-1.81c.29-.53.69-.95,1.2-1.25s1.08-.45,1.73-.45c.61,0,1.15.14,1.62.43.48.29.85.69,1.11,1.2.27.51.4,1.09.4,1.74,0,.11,0,.21-.01.28Zm-4.56-2.11c-.4.34-.65.79-.75,1.37h4.19c-.08-.57-.3-1.02-.66-1.37s-.81-.51-1.36-.51-1.02.17-1.42.5Z" />
      <path d="m97.5,302.57l.28-1.01c.43.24.83.42,1.2.54.37.12.79.17,1.25.17.74,0,1.3-.19,1.69-.57.39-.38.59-.87.59-1.48v-1.09h-.03c-.26.37-.59.67-.99.88s-.86.32-1.37.32c-.62,0-1.18-.14-1.68-.42-.5-.28-.9-.68-1.19-1.19-.29-.51-.43-1.1-.43-1.76s.14-1.23.41-1.74c.27-.52.66-.92,1.16-1.22.5-.29,1.06-.44,1.69-.44.49,0,.94.1,1.36.31.42.21.76.5,1.04.87h.03v-1.09h1.13v6.31c0,1.04-.28,1.85-.83,2.46-.55.6-1.4.9-2.53.9-1.03,0-1.95-.25-2.77-.74Zm3.95-3.6c.34-.21.61-.49.8-.85.19-.36.29-.77.29-1.22s-.1-.85-.3-1.2c-.2-.35-.47-.62-.82-.81-.34-.19-.73-.29-1.15-.29-.46,0-.86.1-1.21.3-.35.2-.62.48-.81.83-.19.35-.29.75-.29,1.19,0,.47.1.88.3,1.24.2.36.48.63.83.83.35.19.74.29,1.17.29s.84-.1,1.19-.31Z" />
      <path d="m111.71,297.21c0,.07-.01.13-.01.18h-5.32c.07.64.32,1.16.74,1.54.42.38.95.57,1.6.57.82,0,1.48-.3,1.97-.91l.8.67c-.66.85-1.6,1.27-2.81,1.27-.66,0-1.26-.14-1.78-.43-.52-.29-.93-.7-1.22-1.23s-.44-1.14-.44-1.82.14-1.27.43-1.81c.29-.53.69-.95,1.2-1.25s1.08-.45,1.73-.45c.61,0,1.15.14,1.62.43.48.29.85.69,1.11,1.2.27.51.4,1.09.4,1.74,0,.11,0,.21-.01.28Zm-4.56-2.11c-.4.34-.65.79-.75,1.37h4.19c-.08-.57-.3-1.02-.66-1.37s-.81-.51-1.36-.51-1.02.17-1.42.5Z" />
      <path d="m113.48,290.16h1.13v10.3h-1.13v-10.3Z" />
      <path d="m118.94,300.2c-.42-.23-.75-.55-1.01-.97h-.03v1.23h-1.08v-10.3h1.13v4.68h.01c.26-.41.6-.73,1.02-.95.42-.22.89-.33,1.4-.33.63,0,1.2.15,1.71.46.5.31.9.73,1.18,1.27.28.54.43,1.14.43,1.81s-.14,1.27-.43,1.79c-.29.52-.69.93-1.19,1.22-.5.29-1.07.43-1.69.43-.55,0-1.03-.11-1.45-.34Zm2.5-1.01c.36-.21.63-.5.83-.86.2-.36.29-.78.29-1.23,0-.49-.1-.93-.31-1.31s-.49-.67-.85-.88c-.35-.21-.75-.32-1.18-.32s-.85.11-1.2.33-.63.52-.83.9c-.2.38-.29.81-.29,1.27s.1.88.31,1.25c.21.37.49.65.85.85.36.2.75.3,1.18.3s.85-.11,1.2-.32Z" />
      <path d="m126.59,300.11c-.5-.29-.9-.7-1.19-1.22s-.43-1.12-.43-1.79.14-1.26.43-1.81c.28-.54.68-.97,1.18-1.27.5-.31,1.07-.46,1.71-.46.5,0,.97.11,1.39.34.42.23.75.55.99.96h.03v-1.22h1.13v6.82h-1.09v-1.23h-.01c-.25.42-.59.74-1,.97-.42.23-.89.34-1.44.34-.62,0-1.19-.14-1.69-.43Zm3.01-.9c.34-.2.62-.49.83-.85.21-.37.31-.79.31-1.25s-.1-.89-.29-1.27-.47-.68-.81-.9c-.35-.22-.74-.33-1.18-.33s-.82.1-1.18.32c-.35.21-.64.5-.85.88s-.32.81-.32,1.31c0,.46.1.87.29,1.23.2.36.47.65.83.86s.76.32,1.22.32c.42,0,.8-.1,1.15-.3Z" />
      <path d="m133.87,293.64h1.13v1.25h.03c.25-.42.57-.75.95-.98s.8-.35,1.27-.35c.12,0,.21,0,.28.01v1.26l-.45-.03c-.63,0-1.14.22-1.51.66s-.56,1.01-.56,1.71v3.29h-1.13v-6.82Z" />
      <path d="m572.48,237.74l-4-4v-120.68l1.18-2.88,81.71-83.01,2.92-1.22h14.9l2.99,1.3,28.11,30.02v-12.67l4-4h28.05l4,4v51.18l13.54,14.46,1.11,2.81v120.69l-4,4h-174.5Zm2.76-123.6v116.83h168.98v-116.88l-76.19-81.39h-12.62l-80.16,81.43Zm131.8-50.06l22.53,24.52v-41.25h-22.53v16.73Z" />
      <path
        class="cls-12"
        d="m668.87,27.45l2.42,1.05,25.3,27.02,5.19,5.54v-15.85l3.12-3.12h26.8l3.12,3.12v51.16l.81.87,12.94,13.82.9,2.28v119.78l-3.12,3.12h-173.25l-3.12-3.12v-119.76l.95-2.33,81.28-82.58,2.37-.99h14.29m-95.13,205.03h171.97v-118.97l-.81-.87-75.34-80.48-.89-.95h-13.9l-.88.9-79.29,80.55-.86.88v118.95m157.33-140.02v-46.6h-25.53v18.81l.79.86,19.53,21.25,5.21,5.67m-61.58-68.01h-15.51l-3.48,1.45-82.12,83.43-1.4,3.43v121.6l4.88,4.88h175.74l4.88-4.88v-121.6l-1.32-3.34-13.33-14.23v-51.21l-4.88-4.88h-29.29l-4.88,4.88v9.49l-25.73-27.48-3.56-1.54h0Zm-92.75,205.03v-114.72l79.29-80.55h11.34l75.34,80.48v114.79h-165.97Zm151.33-144.72l-19.53-21.25v-14.64h19.53v35.9h0Z"
      />
      <text class="cls-14" transform="translate(651.94 82.72)">
        <tspan class="cls-1" x="0" y="0">
          Mit
        </tspan>
        <tspan class="cls-1" x="-43.81" y="14.4">
          Raumaufschaltung
        </tspan>
      </text>
      <g id="pictogram-2">
        <path d="m584.94,34.15c-.95-3.52-3.4-6.48-6.57-8.25-1.75-4.67-6.22-7.78-11.23-7.78-6.62,0-12,5.38-12,12,0,2.12.57,4.2,1.62,6.02-4.23.2-7.62,3.7-7.62,7.98s3.59,8,8,8h27c4.96,0,9-4.04,9-9,0-4.69-3.61-8.56-8.2-8.97Zm-17.8-14.03c3.45,0,6.6,1.78,8.41,4.59-1.25-.38-2.57-.59-3.91-.59-6.39,0-11.94,4.53-13.24,10.85-.82-1.48-1.26-3.15-1.26-4.85,0-5.51,4.49-10,10-10Zm17,30h-27c-3.31,0-6-2.69-6-6s2.69-6,6-6h2l.99-.88.24-1.92c1.08-5.33,5.82-9.2,11.27-9.2s10.46,3.95,11.52,9.2l.98.8c3.86,0,7,3.14,7,7s-3.14,7-7,7Z" />
        <rect x="566.15" y="12.12" width="2" height="4" />
        <rect x="549.15" y="29.12" width="4" height="2" />
        <rect
          x="554.95"
          y="16.94"
          width="2"
          height="3.96"
          transform="translate(149.46 398.65) rotate(-45)"
        />
        <rect
          x="576.36"
          y="17.92"
          width="3.96"
          height="2"
          transform="translate(156.02 414.49) rotate(-45)"
        />
      </g>
      <text class="cls-15" transform="translate(554.79 68.86)">
        <tspan x="0" y="0">
          -5°C
        </tspan>
      </text>
      <line class="cls-5" x1="572.45" y1="113.16" x2="747.15" y2="113.16" />
      <path d="m549.57,256.94h2.1l2.46,7.21h.03l2.46-7.21h2.06l-3.68,9.94h-1.74l-3.7-9.94Z" />
      <path d="m560.13,266.55c-.55-.3-.98-.72-1.28-1.26-.3-.54-.46-1.15-.46-1.83s.15-1.29.46-1.82c.3-.53.73-.95,1.28-1.25.55-.3,1.19-.46,1.9-.46s1.33.15,1.88.44.98.71,1.29,1.25c.31.54.46,1.15.46,1.84s-.15,1.3-.46,1.84c-.31.54-.74.96-1.29,1.25s-1.18.45-1.88.45-1.35-.15-1.9-.46Zm3.16-1.73c.34-.35.51-.81.51-1.36s-.17-1.01-.5-1.36c-.34-.35-.76-.53-1.26-.53s-.95.18-1.28.54c-.33.36-.5.81-.5,1.35s.17.99.5,1.35c.33.36.76.54,1.28.54.5,0,.91-.18,1.25-.53Z" />
      <path d="m567.1,260.04h1.83v.99h.03c.26-.35.57-.61.92-.81.35-.19.73-.29,1.13-.29h.14v2c-.13,0-.29-.01-.49-.01-.55,0-.98.17-1.28.5-.3.33-.46.76-.46,1.29v3.16h-1.83v-6.85Z" />
      <path d="m572.39,256.58h1.83v10.3h-1.83v-10.3Z" />
      <path d="m577.32,266.57c-.49-.29-.88-.71-1.16-1.25-.28-.54-.42-1.15-.42-1.84s.14-1.29.42-1.83c.28-.54.66-.96,1.15-1.26.49-.3,1.04-.46,1.65-.46.4,0,.78.08,1.13.23.35.15.65.38.89.67h.03v-.8h1.83v6.85h-1.75v-.81h-.03c-.5.62-1.18.94-2.06.94-.63,0-1.19-.15-1.68-.44Zm3.26-1.73c.34-.34.5-.79.5-1.34s-.17-1.01-.5-1.37c-.33-.36-.74-.55-1.24-.55s-.92.17-1.25.53c-.33.35-.5.81-.5,1.39s.16,1,.49,1.34c.33.35.75.52,1.26.52.49,0,.9-.17,1.23-.52Z" />
      <path d="m584.46,264.17v-4.13h1.82v3.65c0,.62.1,1.05.29,1.29s.51.37.95.37.78-.18,1.07-.53.44-.84.44-1.46v-3.33h1.85v6.85h-1.85v-.81h-.03c-.25.29-.55.53-.9.69s-.72.25-1.11.25c-1.68,0-2.52-.95-2.52-2.84Z" />
      <path d="m592.87,261.56h-.71v-1.53h.71v-1.16c0-.79.19-1.4.56-1.81.37-.41.91-.62,1.6-.62.37,0,.71.05,1.02.15.3.1.55.22.75.35l-.38,1.43c-.43-.21-.77-.31-1.01-.31-.46,0-.69.31-.69.92v1.04h1.51v1.53h-1.51v5.32h-1.85v-5.32Z" />
      <path d="m602.42,266.55c-.55-.3-.98-.72-1.28-1.26-.3-.54-.46-1.15-.46-1.83s.15-1.29.46-1.82c.3-.53.73-.95,1.28-1.25.55-.3,1.19-.46,1.9-.46s1.33.15,1.88.44.98.71,1.29,1.25c.31.54.46,1.15.46,1.84s-.15,1.3-.46,1.84c-.31.54-.74.96-1.29,1.25s-1.18.45-1.88.45-1.35-.15-1.9-.46Zm3.16-1.73c.34-.35.51-.81.51-1.36s-.17-1.01-.5-1.36c-.34-.35-.76-.53-1.26-.53s-.95.18-1.28.54c-.33.36-.5.81-.5,1.35s.17.99.5,1.35c.33.36.76.54,1.28.54.5,0,.91-.18,1.25-.53Z" />
      <path d="m609.39,260.04h1.83v.78h.03c.24-.29.54-.51.9-.66s.74-.22,1.16-.22c.61,0,1.16.15,1.65.46.49.3.88.72,1.16,1.26.28.54.42,1.15.42,1.83s-.14,1.3-.42,1.84c-.28.54-.67.95-1.17,1.25-.5.29-1.07.44-1.7.44-.84,0-1.51-.3-2-.9h-.03v3.54h-1.83v-9.62Zm4.8,4.79c.33-.35.49-.8.49-1.34,0-.58-.17-1.04-.5-1.39-.34-.35-.76-.53-1.26-.53s-.95.18-1.28.54c-.33.36-.5.82-.5,1.38s.17,1,.51,1.34c.34.35.77.52,1.28.52s.93-.18,1.26-.53Z" />
      <path d="m618.67,266.43c-.34-.39-.52-.95-.52-1.7v-3.16h-.81v-1.53h.81v-2.23l1.83-.2v2.42h1.85v1.53h-1.85v2.9c0,.31.04.53.13.67.09.14.24.21.46.21.26,0,.55-.08.87-.24l.46,1.47c-.26.14-.52.25-.78.32-.26.07-.6.11-1.02.11-.61,0-1.08-.19-1.43-.58Z" />
      <path d="m623.3,258.77c-.22-.22-.33-.49-.33-.81s.11-.57.33-.79c.22-.22.5-.33.83-.33s.6.11.83.33c.22.22.34.48.34.79s-.11.59-.34.81c-.22.22-.5.33-.83.33s-.61-.11-.83-.33Zm-.1,1.27h1.83v6.85h-1.83v-6.85Z" />
      <path d="m626.93,260.04h1.83v.9h.01c.27-.33.58-.57.94-.74.35-.17.72-.25,1.11-.25.95,0,1.62.36,2.02,1.08h.03c.28-.35.62-.62,1.04-.8.41-.18.86-.27,1.36-.27.76,0,1.36.24,1.78.73.42.49.63,1.24.63,2.25v3.96h-1.83v-3.53c0-1.19-.4-1.78-1.19-1.78-.4,0-.74.17-1,.5s-.4.8-.4,1.39v3.42h-1.83v-3.53c0-.57-.1-1.01-.29-1.32-.2-.31-.5-.46-.91-.46s-.78.17-1.04.5c-.27.33-.4.8-.4,1.39v3.42h-1.83v-6.85Z" />
      <path d="m640.58,266.57c-.49-.29-.88-.71-1.16-1.25-.28-.54-.42-1.15-.42-1.84s.14-1.29.42-1.83c.28-.54.66-.96,1.15-1.26.49-.3,1.04-.46,1.65-.46.4,0,.78.08,1.13.23.35.15.65.38.89.67h.03v-.8h1.83v6.85h-1.75v-.81h-.03c-.5.62-1.18.94-2.06.94-.63,0-1.19-.15-1.68-.44Zm3.26-1.73c.34-.34.5-.79.5-1.34s-.17-1.01-.5-1.37c-.33-.36-.74-.55-1.24-.55s-.92.17-1.25.53c-.33.35-.5.81-.5,1.39s.16,1,.49,1.34c.33.35.75.52,1.26.52.49,0,.9-.17,1.23-.52Z" />
      <path d="m647.93,256.58h1.83v10.3h-1.83v-10.3Z" />
      <path d="m651.8,261.87c-.22-.22-.34-.49-.34-.81s.11-.57.34-.79c.23-.22.5-.33.82-.33s.57.11.8.33c.22.22.34.48.34.79s-.11.59-.34.81c-.22.22-.49.33-.8.33-.33,0-.6-.11-.83-.33Zm0,4.82c-.22-.22-.34-.49-.34-.8s.11-.57.34-.79c.23-.22.5-.33.82-.33s.57.11.8.33c.22.22.34.48.34.79s-.11.59-.34.8c-.22.22-.49.33-.8.33-.33,0-.6-.11-.83-.33Z" />
      <path d="m551.11,280.28c-.28-.28-.42-.61-.42-1.01s.14-.74.42-1.02.62-.41,1.01-.41.74.14,1.03.41c.28.28.43.61.43,1.02s-.14.74-.43,1.01c-.29.28-.63.41-1.03.41s-.73-.14-1.01-.41Z" />
      <path d="m568.51,273.74h6.09v1.11h-4.87v3.33h3.99v1.11h-3.99v3.29h5.17v1.11h-6.38v-9.94Z" />
      <path d="m576.64,276.87h1.13v1.23h.01c.27-.42.62-.74,1.05-.97.43-.23.91-.34,1.43-.34.76,0,1.35.24,1.79.72.44.48.66,1.19.66,2.14v4.05h-1.13v-3.72c0-1.43-.56-2.14-1.68-2.14-.65,0-1.17.22-1.55.66-.38.44-.57,1.04-.57,1.81v3.4h-1.13v-6.82Z" />
      <path d="m590.75,280.44c0,.07-.01.13-.01.18h-5.32c.07.64.32,1.16.74,1.54.42.38.95.57,1.6.57.82,0,1.48-.3,1.97-.91l.8.67c-.66.85-1.6,1.27-2.81,1.27-.66,0-1.26-.14-1.78-.43-.52-.29-.93-.7-1.22-1.23s-.44-1.14-.44-1.82.14-1.27.43-1.81c.29-.53.69-.95,1.2-1.25s1.08-.45,1.73-.45c.61,0,1.15.14,1.62.43.48.29.85.69,1.11,1.2.27.51.4,1.09.4,1.74,0,.11,0,.21-.01.28Zm-4.56-2.11c-.4.34-.65.79-.75,1.37h4.19c-.08-.57-.3-1.02-.66-1.37s-.81-.51-1.36-.51-1.02.17-1.42.5Z" />
      <path d="m592.43,276.87h1.13v1.25h.03c.25-.42.57-.75.95-.98s.8-.35,1.27-.35c.12,0,.21,0,.28.01v1.26l-.45-.03c-.63,0-1.14.22-1.51.66s-.56,1.01-.56,1.71v3.29h-1.13v-6.82Z" />
      <path d="m597.4,285.8l.28-1.01c.43.24.83.42,1.2.54.37.12.79.17,1.25.17.74,0,1.3-.19,1.69-.57.39-.38.59-.87.59-1.48v-1.09h-.03c-.26.37-.59.67-.99.88s-.86.32-1.37.32c-.62,0-1.18-.14-1.68-.42-.5-.28-.9-.68-1.19-1.19-.29-.51-.43-1.1-.43-1.76s.14-1.23.41-1.74c.27-.52.66-.92,1.16-1.22.5-.29,1.06-.44,1.69-.44.49,0,.94.1,1.36.31.42.21.76.5,1.04.87h.03v-1.09h1.13v6.31c0,1.04-.28,1.85-.83,2.46-.55.6-1.4.9-2.53.9-1.03,0-1.95-.25-2.77-.74Zm3.95-3.6c.34-.21.61-.49.8-.85.19-.36.29-.77.29-1.22s-.1-.85-.3-1.2c-.2-.35-.47-.62-.82-.81-.34-.19-.73-.29-1.15-.29-.46,0-.86.1-1.21.3-.35.2-.62.48-.81.83-.19.35-.29.75-.29,1.19,0,.47.1.88.3,1.24.2.36.48.63.83.83.35.19.74.29,1.17.29s.84-.1,1.19-.31Z" />
      <path d="m605.63,275.38c-.16-.16-.24-.35-.24-.57s.08-.42.24-.57c.16-.16.35-.24.59-.24s.43.08.59.24.24.35.24.57-.08.42-.24.57-.35.24-.59.24-.43-.08-.59-.24Zm.01,1.48h1.13v6.82h-1.13v-6.82Z" />
      <path d="m615.04,280.44c0,.07-.01.13-.01.18h-5.32c.07.64.32,1.16.74,1.54.42.38.95.57,1.6.57.82,0,1.48-.3,1.97-.91l.8.67c-.66.85-1.6,1.27-2.81,1.27-.66,0-1.26-.14-1.78-.43-.52-.29-.93-.7-1.22-1.23s-.44-1.14-.44-1.82.14-1.27.43-1.81c.29-.53.69-.95,1.2-1.25s1.08-.45,1.73-.45c.61,0,1.15.14,1.62.43.48.29.85.69,1.11,1.2.27.51.4,1.09.4,1.74,0,.11,0,.21-.01.28Zm-4.56-2.11c-.4.34-.65.79-.75,1.37h4.19c-.08-.57-.3-1.02-.66-1.37s-.81-.51-1.36-.51-1.02.17-1.42.5Z" />
      <path d="m622.77,280.44c0,.07-.01.13-.01.18h-5.32c.07.64.32,1.16.74,1.54.42.38.95.57,1.6.57.82,0,1.48-.3,1.97-.91l.8.67c-.66.85-1.6,1.27-2.81,1.27-.66,0-1.26-.14-1.78-.43-.52-.29-.93-.7-1.22-1.23s-.44-1.14-.44-1.82.14-1.27.43-1.81c.29-.53.69-.95,1.2-1.25s1.08-.45,1.73-.45c.61,0,1.15.14,1.62.43.48.29.85.69,1.11,1.2.27.51.4,1.09.4,1.74,0,.11,0,.21-.01.28Zm-4.56-2.11c-.4.34-.65.79-.75,1.37h4.19c-.08-.57-.3-1.02-.66-1.37s-.81-.51-1.36-.51-1.02.17-1.42.5Z" />
      <path d="m624.45,276.87h1.13v1.25h.03c.25-.42.57-.75.95-.98s.8-.35,1.27-.35c.12,0,.21,0,.28.01v1.26l-.45-.03c-.63,0-1.14.22-1.51.66s-.56,1.01-.56,1.71v3.29h-1.13v-6.82Z" />
      <path d="m628.79,282.8l.57-.87c.28.27.58.48.91.62.33.14.72.21,1.18.21.4,0,.72-.09.95-.26.23-.17.35-.39.35-.67,0-.19-.06-.34-.18-.46-.12-.12-.27-.22-.43-.29-.17-.07-.5-.2-1.01-.38-.67-.23-1.18-.5-1.53-.79-.35-.29-.52-.73-.52-1.29,0-.34.1-.66.29-.94.2-.28.46-.5.81-.66.34-.16.72-.24,1.14-.24.88,0,1.66.28,2.35.84l-.59.9c-.52-.49-1.12-.73-1.81-.73-.28,0-.53.08-.74.22s-.31.35-.31.6.11.44.32.59c.21.15.54.31.97.47l.34.13c.51.2.9.37,1.17.51.27.14.48.33.64.57.16.23.24.54.24.91,0,.57-.21,1.04-.63,1.41-.42.37-1.03.56-1.82.56-1.04,0-1.92-.32-2.66-.97Z" />
      <path d="m635.51,276.87h1.13v1.2h.01c.26-.41.6-.73,1.02-.95.42-.22.89-.34,1.4-.34.63,0,1.2.15,1.71.46.5.31.9.73,1.18,1.27.28.54.43,1.14.43,1.81s-.14,1.27-.43,1.79c-.29.52-.69.93-1.19,1.22-.5.29-1.07.43-1.69.43-.53,0-1-.11-1.41-.33-.41-.22-.75-.53-1.01-.93h-.01v3.95h-1.13v-9.59Zm4.62,5.55c.36-.21.63-.5.83-.86.2-.36.29-.78.29-1.23,0-.49-.1-.93-.31-1.31s-.49-.67-.85-.88c-.35-.21-.75-.32-1.18-.32s-.85.11-1.2.33-.63.52-.83.9c-.2.38-.29.81-.29,1.27s.1.88.31,1.25c.21.37.49.65.85.85.36.2.75.3,1.18.3s.85-.11,1.2-.32Z" />
      <path d="m645.28,283.33c-.5-.29-.9-.7-1.19-1.22s-.43-1.12-.43-1.79.14-1.26.43-1.81c.28-.54.68-.97,1.18-1.27.5-.31,1.07-.46,1.71-.46.5,0,.97.11,1.39.34.42.23.75.55.99.96h.03v-1.22h1.13v6.82h-1.09v-1.23h-.01c-.25.42-.59.74-1,.97-.42.23-.89.34-1.44.34-.62,0-1.19-.14-1.69-.43Zm3.01-.9c.34-.2.62-.49.83-.85.21-.37.31-.79.31-1.25s-.1-.89-.29-1.27-.47-.68-.81-.9c-.35-.22-.74-.33-1.18-.33s-.82.1-1.18.32c-.35.21-.64.5-.85.88s-.32.81-.32,1.31c0,.46.1.87.29,1.23.2.36.47.65.83.86s.76.32,1.22.32c.42,0,.8-.1,1.15-.3Z" />
      <path d="m652.56,276.87h1.13v1.25h.03c.25-.42.57-.75.95-.98s.8-.35,1.27-.35c.12,0,.21,0,.28.01v1.26l-.45-.03c-.63,0-1.14.22-1.51.66s-.56,1.01-.56,1.71v3.29h-1.13v-6.82Z" />
      <path d="m657.5,276.87h1.13v1.23h.01c.27-.42.62-.74,1.05-.97.43-.23.91-.34,1.43-.34.76,0,1.35.24,1.79.72.44.48.66,1.19.66,2.14v4.05h-1.13v-3.72c0-1.43-.56-2.14-1.68-2.14-.65,0-1.17.22-1.55.66-.38.44-.57,1.04-.57,1.81v3.4h-1.13v-6.82Z" />
      <path d="m665.64,275.38c-.16-.16-.24-.35-.24-.57s.08-.42.24-.57c.16-.16.35-.24.59-.24s.43.08.59.24.24.35.24.57-.08.42-.24.57-.35.24-.59.24-.43-.08-.59-.24Zm.01,1.48h1.13v6.82h-1.13v-6.82Z" />
      <path d="m668.4,282.8l.57-.87c.28.27.58.48.91.62.33.14.72.21,1.18.21.4,0,.72-.09.95-.26.23-.17.35-.39.35-.67,0-.19-.06-.34-.18-.46-.12-.12-.27-.22-.43-.29-.17-.07-.5-.2-1.01-.38-.67-.23-1.18-.5-1.53-.79-.35-.29-.52-.73-.52-1.29,0-.34.1-.66.29-.94.2-.28.46-.5.81-.66.34-.16.72-.24,1.14-.24.88,0,1.66.28,2.35.84l-.59.9c-.52-.49-1.12-.73-1.81-.73-.28,0-.53.08-.74.22s-.31.35-.31.6.11.44.32.59c.21.15.54.31.97.47l.34.13c.51.2.9.37,1.17.51.27.14.48.33.64.57.16.23.24.54.24.91,0,.57-.21,1.04-.63,1.41-.42.37-1.03.56-1.82.56-1.04,0-1.92-.32-2.66-.97Z" />
      <path d="m551.11,297.08c-.28-.28-.42-.61-.42-1.01s.14-.74.42-1.02.62-.41,1.01-.41.74.14,1.03.41c.28.28.43.61.43,1.02s-.14.74-.43,1.01c-.29.28-.63.41-1.03.41s-.73-.14-1.01-.41Z" />
      <path d="m568.58,302.6l.28-1.01c.43.24.83.42,1.2.54.37.12.79.17,1.25.17.74,0,1.3-.19,1.69-.57.39-.38.59-.87.59-1.48v-1.09h-.03c-.26.37-.59.67-.99.88s-.86.32-1.37.32c-.62,0-1.18-.14-1.68-.42-.5-.28-.9-.68-1.19-1.19-.29-.51-.43-1.1-.43-1.76s.14-1.23.41-1.74c.27-.52.66-.92,1.16-1.22.5-.29,1.06-.44,1.69-.44.49,0,.94.1,1.36.31.42.21.76.5,1.04.87h.03v-1.09h1.13v6.31c0,1.04-.28,1.85-.83,2.46-.55.6-1.4.9-2.53.9-1.03,0-1.95-.25-2.77-.74Zm3.95-3.6c.34-.21.61-.49.8-.85.19-.36.29-.77.29-1.22s-.1-.85-.3-1.2c-.2-.35-.47-.62-.82-.81-.34-.19-.73-.29-1.15-.29-.46,0-.86.1-1.21.3-.35.2-.62.48-.81.83-.19.35-.29.75-.29,1.19,0,.47.1.88.3,1.24.2.36.48.63.83.83.35.19.74.29,1.17.29s.84-.1,1.19-.31Z" />
      <path d="m577.26,299.86c-.45-.47-.67-1.18-.67-2.13v-4.06h1.13v3.74c0,.75.14,1.29.41,1.62s.69.5,1.25.5c.37,0,.71-.1,1.02-.31.31-.21.55-.5.73-.89.18-.39.27-.84.27-1.37v-3.3h1.13v6.82h-1.13v-1.18h-.01c-.25.4-.58.71-.97.93s-.82.33-1.28.33c-.81,0-1.44-.24-1.89-.71Z" />
      <path d="m584.76,298.45v-3.81h-.88v-.98h.88v-2.32l1.13-.13v2.45h2.25v.98h-2.25v3.64c0,.43.06.74.18.93.12.19.34.29.64.29.35,0,.73-.12,1.16-.35l.29.99c-.22.13-.46.23-.71.31s-.56.11-.94.11c-1.18,0-1.76-.71-1.76-2.11Z" />
      <path d="m593.47,293.67h1.13v1.25h.03c.25-.42.57-.75.95-.98s.8-.35,1.27-.35c.12,0,.21,0,.28.01v1.26l-.45-.03c-.63,0-1.14.22-1.51.66s-.56,1.01-.56,1.71v3.29h-1.13v-6.82Z" />
      <path d="m604.24,297.24c0,.07-.01.13-.01.18h-5.32c.07.64.32,1.16.74,1.54.42.38.95.57,1.6.57.82,0,1.48-.3,1.97-.91l.8.67c-.66.85-1.6,1.27-2.81,1.27-.66,0-1.26-.14-1.78-.43-.52-.29-.93-.7-1.22-1.23s-.44-1.14-.44-1.82.14-1.27.43-1.81c.29-.53.69-.95,1.2-1.25s1.08-.45,1.73-.45c.61,0,1.15.14,1.62.43.48.29.85.69,1.11,1.2.27.51.4,1.09.4,1.74,0,.11,0,.21-.01.28Zm-4.56-2.11c-.4.34-.65.79-.75,1.37h4.19c-.08-.57-.3-1.02-.66-1.37s-.81-.51-1.36-.51-1.02.17-1.42.5Z" />
      <path d="m606.15,302.6l.28-1.01c.43.24.83.42,1.2.54.37.12.79.17,1.25.17.74,0,1.3-.19,1.69-.57.39-.38.59-.87.59-1.48v-1.09h-.03c-.26.37-.59.67-.99.88s-.86.32-1.37.32c-.62,0-1.18-.14-1.68-.42-.5-.28-.9-.68-1.19-1.19-.29-.51-.43-1.1-.43-1.76s.14-1.23.41-1.74c.27-.52.66-.92,1.16-1.22.5-.29,1.06-.44,1.69-.44.49,0,.94.1,1.36.31.42.21.76.5,1.04.87h.03v-1.09h1.13v6.31c0,1.04-.28,1.85-.83,2.46-.55.6-1.4.9-2.53.9-1.03,0-1.95-.25-2.77-.74Zm3.95-3.6c.34-.21.61-.49.8-.85.19-.36.29-.77.29-1.22s-.1-.85-.3-1.2c-.2-.35-.47-.62-.82-.81-.34-.19-.73-.29-1.15-.29-.46,0-.86.1-1.21.3-.35.2-.62.48-.81.83-.19.35-.29.75-.29,1.19,0,.47.1.88.3,1.24.2.36.48.63.83.83.35.19.74.29,1.17.29s.84-.1,1.19-.31Z" />
      <path d="m620.36,297.24c0,.07-.01.13-.01.18h-5.32c.07.64.32,1.16.74,1.54.42.38.95.57,1.6.57.82,0,1.48-.3,1.97-.91l.8.67c-.66.85-1.6,1.27-2.81,1.27-.66,0-1.26-.14-1.78-.43-.52-.29-.93-.7-1.22-1.23s-.44-1.14-.44-1.82.14-1.27.43-1.81c.29-.53.69-.95,1.2-1.25s1.08-.45,1.73-.45c.61,0,1.15.14,1.62.43.48.29.85.69,1.11,1.2.27.51.4,1.09.4,1.74,0,.11,0,.21-.01.28Zm-4.56-2.11c-.4.34-.65.79-.75,1.37h4.19c-.08-.57-.3-1.02-.66-1.37s-.81-.51-1.36-.51-1.02.17-1.42.5Z" />
      <path d="m622.13,290.18h1.13v10.3h-1.13v-10.3Z" />
      <path d="m627.59,300.23c-.42-.23-.75-.55-1.01-.97h-.03v1.23h-1.08v-10.3h1.13v4.68h.01c.26-.41.6-.73,1.02-.95.42-.22.89-.33,1.4-.33.63,0,1.2.15,1.71.46.5.31.9.73,1.18,1.27.28.54.43,1.14.43,1.81s-.14,1.27-.43,1.79c-.29.52-.69.93-1.19,1.22-.5.29-1.07.43-1.69.43-.55,0-1.03-.11-1.45-.34Zm2.5-1.01c.36-.21.63-.5.83-.86.2-.36.29-.78.29-1.23,0-.49-.1-.93-.31-1.31s-.49-.67-.85-.88c-.35-.21-.75-.32-1.18-.32s-.85.11-1.2.33-.63.52-.83.9c-.2.38-.29.81-.29,1.27s.1.88.31,1.25c.21.37.49.65.85.85.36.2.75.3,1.18.3s.85-.11,1.2-.32Z" />
      <path d="m635.24,300.13c-.5-.29-.9-.7-1.19-1.22s-.43-1.12-.43-1.79.14-1.26.43-1.81c.28-.54.68-.97,1.18-1.27.5-.31,1.07-.46,1.71-.46.5,0,.97.11,1.39.34.42.23.75.55.99.96h.03v-1.22h1.13v6.82h-1.09v-1.23h-.01c-.25.42-.59.74-1,.97-.42.23-.89.34-1.44.34-.62,0-1.19-.14-1.69-.43Zm3.01-.9c.34-.2.62-.49.83-.85.21-.37.31-.79.31-1.25s-.1-.89-.29-1.27-.47-.68-.81-.9c-.35-.22-.74-.33-1.18-.33s-.82.1-1.18.32c-.35.21-.64.5-.85.88s-.32.81-.32,1.31c0,.46.1.87.29,1.23.2.36.47.65.83.86s.76.32,1.22.32c.42,0,.8-.1,1.15-.3Z" />
      <path d="m642.52,293.67h1.13v1.25h.03c.25-.42.57-.75.95-.98s.8-.35,1.27-.35c.12,0,.21,0,.28.01v1.26l-.45-.03c-.63,0-1.14.22-1.51.66s-.56,1.01-.56,1.71v3.29h-1.13v-6.82Z" />
      <text class="cls-17" transform="translate(590.14 148.77)">
        <tspan class="cls-18">
          <tspan x="0" y="0">
            Stellgröße:{" "}
          </tspan>
        </tspan>
        <tspan class="cls-16">
          <tspan x="0" y="14.4">
            ca. 50-90 %
          </tspan>
        </tspan>
      </text>
      <text class="cls-17" transform="translate(692.13 174.09)">
        <tspan class="cls-18">
          <tspan x="0" y="0">
            Sollwert:{" "}
          </tspan>
        </tspan>
        <tspan class="cls-16">
          <tspan x="0" y="14.4">
            21,0°C
          </tspan>
        </tspan>
      </text>
      <text class="cls-17" transform="translate(692.13 209.07)">
        <tspan class="cls-18">
          <tspan x="0" y="0">
            Istwert:{" "}
          </tspan>
        </tspan>
        <tspan class="cls-16">
          <tspan x="0" y="14.4">
            21,0°C
          </tspan>
        </tspan>
      </text>
      <path d="m657.42,182.74v42h-66v-42h66m2-2h-70v46h70v-46h0Z" />
      <path d="m653.42,186.81v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m645.42,186.81v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m637.42,186.81v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m629.42,186.81v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m621.42,186.81v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m613.42,186.81v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m605.42,186.81v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m597.42,186.81v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <rect x="586.43" y="184.81" width="3" height="10" />
      <rect x="586.43" y="212.81" width="3" height="10" />
      <rect x="659.43" y="212.81" width="3" height="10" />
      <rect x="659.43" y="186.81" width="4" height="6" />
      <polygon
        class="cls-4"
        points="663.61 182.26 676.86 183.79 677.98 193.28 663.47 195.09 663.61 182.26"
      />
      <polyline
        class="cls-4"
        points="663.43 186.81 671.47 186.77 671.47 182.49"
      />
      <polygon points="677.14 185.19 679.24 185.33 679.93 191.19 677.98 191.74 677.14 185.19" />
      <polygon points="665.56 194.67 665.56 196.91 677 195.23 677 193.28 665.56 194.67" />
      <line class="cls-4" x1="587.84" y1="215.6" x2="572.4" y2="215.6" />
      <line class="cls-4" x1="587.84" y1="219.6" x2="572.4" y2="219.6" />
      <path d="m301.11,237.74l-4-4v-120.68l1.18-2.88,81.7-83.01,2.92-1.22h14.9l2.99,1.3,28.11,30.02v-12.67l4-4h28.05l4,4v51.18l13.54,14.46,1.11,2.81v120.69l-4,4h-174.5Zm2.76-123.6v116.83h168.97v-116.88l-76.19-81.39h-12.62l-80.16,81.43Zm131.8-50.06l22.53,24.52v-41.25h-22.53v16.73Z" />
      <path
        class="cls-12"
        d="m397.5,27.45l2.42,1.05,25.3,27.02,5.19,5.54v-15.85l3.12-3.12h26.8l3.12,3.12v51.16l.81.87,12.94,13.82.9,2.28v119.78l-3.12,3.12h-173.25l-3.12-3.12v-119.76l.95-2.33,81.28-82.58,2.37-.99h14.29m-95.13,205.03h171.97v-118.97l-.81-.87-75.34-80.48-.89-.95h-13.9l-.88.9-79.29,80.55-.86.88v118.95m157.33-140.02v-46.6h-25.53v18.81l.79.86,19.53,21.25,5.21,5.67m-61.58-68.01h-15.51l-3.48,1.45-82.12,83.43-1.4,3.43v121.6l4.88,4.88h175.74l4.88-4.88v-121.6l-1.32-3.34-13.33-14.23v-51.21l-4.88-4.88h-29.29l-4.88,4.88v9.49l-25.73-27.48-3.56-1.54h0Zm-92.75,205.03v-114.72l79.29-80.55h11.34l75.34,80.48v114.79h-165.97Zm151.33-144.72l-19.53-21.25v-14.64h19.53v35.9h0Z"
      />
      <text class="cls-14" transform="translate(374.28 82.72)">
        <tspan x="0" y="0">
          Ohne
        </tspan>
        <tspan class="cls-1" x="-37.51" y="14.4">
          Raumaufschaltung
        </tspan>
      </text>
      <g id="pictogram-3">
        <path d="m313.58,34.15c-.95-3.52-3.4-6.48-6.57-8.25-1.75-4.67-6.22-7.78-11.23-7.78-6.62,0-12,5.38-12,12,0,2.12.57,4.2,1.62,6.02-4.23.2-7.62,3.7-7.62,7.98s3.59,8,8,8h27c4.96,0,9-4.04,9-9,0-4.69-3.61-8.56-8.2-8.97Zm-17.8-14.03c3.45,0,6.6,1.78,8.41,4.59-1.25-.38-2.57-.59-3.91-.59-6.39,0-11.94,4.53-13.24,10.85-.82-1.48-1.26-3.15-1.26-4.85,0-5.51,4.49-10,10-10Zm17,30h-27c-3.31,0-6-2.69-6-6s2.69-6,6-6h2l.99-.88.24-1.92c1.08-5.33,5.82-9.2,11.27-9.2s10.46,3.95,11.52,9.2l.98.8c3.86,0,7,3.14,7,7s-3.14,7-7,7Z" />
        <rect x="294.78" y="12.12" width="2" height="4" />
        <rect x="277.78" y="29.12" width="4" height="2" />
        <rect
          x="283.58"
          y="16.94"
          width="2"
          height="3.96"
          transform="translate(69.98 206.77) rotate(-45)"
        />
        <rect
          x="305"
          y="17.92"
          width="3.96"
          height="2"
          transform="translate(76.54 222.61) rotate(-45)"
        />
      </g>
      <text class="cls-15" transform="translate(283.42 68.86)">
        <tspan x="0" y="0">
          -5°C
        </tspan>
      </text>
      <line class="cls-5" x1="301.08" y1="113.16" x2="475.78" y2="113.16" />
      <path d="m277.2,256.94h2.1l2.46,7.21h.03l2.46-7.21h2.06l-3.68,9.94h-1.74l-3.7-9.94Z" />
      <path d="m287.76,266.55c-.55-.3-.98-.72-1.28-1.26-.3-.54-.46-1.15-.46-1.83s.15-1.29.46-1.82c.3-.53.73-.95,1.28-1.25.55-.3,1.19-.46,1.9-.46s1.33.15,1.88.44.98.71,1.29,1.25c.31.54.46,1.15.46,1.84s-.15,1.3-.46,1.84c-.31.54-.74.96-1.29,1.25s-1.18.45-1.88.45-1.35-.15-1.9-.46Zm3.16-1.73c.34-.35.51-.81.51-1.36s-.17-1.01-.5-1.36c-.34-.35-.76-.53-1.26-.53s-.95.18-1.28.54c-.33.36-.5.81-.5,1.35s.17.99.5,1.35c.33.36.76.54,1.28.54.5,0,.91-.18,1.25-.53Z" />
      <path d="m294.73,260.04h1.83v.99h.03c.26-.35.57-.61.92-.81.35-.19.73-.29,1.13-.29h.14v2c-.13,0-.29-.01-.49-.01-.55,0-.98.17-1.28.5-.3.33-.46.76-.46,1.29v3.16h-1.83v-6.85Z" />
      <path d="m300.02,256.58h1.83v10.3h-1.83v-10.3Z" />
      <path d="m304.95,266.57c-.49-.29-.88-.71-1.16-1.25-.28-.54-.42-1.15-.42-1.84s.14-1.29.42-1.83c.28-.54.66-.96,1.15-1.26.49-.3,1.04-.46,1.65-.46.4,0,.78.08,1.13.23.35.15.65.38.89.67h.03v-.8h1.83v6.85h-1.75v-.81h-.03c-.5.62-1.18.94-2.06.94-.63,0-1.19-.15-1.68-.44Zm3.26-1.73c.34-.34.5-.79.5-1.34s-.17-1.01-.5-1.37c-.33-.36-.74-.55-1.24-.55s-.92.17-1.25.53c-.33.35-.5.81-.5,1.39s.16,1,.49,1.34c.33.35.75.52,1.26.52.49,0,.9-.17,1.23-.52Z" />
      <path d="m312.09,264.17v-4.13h1.82v3.65c0,.62.1,1.05.29,1.29s.51.37.95.37.78-.18,1.07-.53.44-.84.44-1.46v-3.33h1.85v6.85h-1.85v-.81h-.03c-.25.29-.55.53-.9.69s-.72.25-1.11.25c-1.68,0-2.52-.95-2.52-2.84Z" />
      <path d="m320.51,261.56h-.71v-1.53h.71v-1.16c0-.79.19-1.4.56-1.81.37-.41.91-.62,1.6-.62.37,0,.71.05,1.02.15.3.1.55.22.75.35l-.38,1.43c-.43-.21-.77-.31-1.01-.31-.46,0-.69.31-.69.92v1.04h1.51v1.53h-1.51v5.32h-1.85v-5.32Z" />
      <path d="m328.28,265.62l3.29-4.03v-.04h-3.04v-1.51h5.52v1.25l-3.33,4.06v.03h3.25v1.51h-5.68v-1.26Z" />
      <path d="m335.35,264.17v-4.13h1.82v3.65c0,.62.1,1.05.29,1.29s.51.37.95.37.78-.18,1.07-.53.44-.84.44-1.46v-3.33h1.85v6.85h-1.85v-.81h-.03c-.25.29-.55.53-.9.69s-.72.25-1.11.25c-1.68,0-2.52-.95-2.52-2.84Z" />
      <path d="m347.12,260.04h1.83v.88h.03c.27-.32.59-.56.97-.73s.78-.25,1.21-.25c.77,0,1.38.23,1.82.69.44.46.66,1.19.66,2.19v4.06h-1.83v-3.6c0-.62-.11-1.07-.32-1.32-.21-.26-.55-.38-.99-.38-.48,0-.85.16-1.13.48-.28.32-.41.79-.41,1.39v3.43h-1.83v-6.85Z" />
      <path d="m355.54,258.77c-.22-.22-.33-.49-.33-.81s.11-.57.33-.79c.22-.22.5-.33.83-.33s.6.11.83.33c.22.22.34.48.34.79s-.11.59-.34.81c-.22.22-.5.33-.83.33s-.61-.11-.83-.33Zm-.1,1.27h1.83v6.85h-1.83v-6.85Z" />
      <path d="m365.5,264.06h-4.8c.11.41.33.73.64.97s.7.35,1.16.35c.69,0,1.25-.26,1.67-.77l1.19,1.11c-.67.87-1.67,1.3-2.98,1.3-.69,0-1.31-.15-1.86-.44s-.97-.71-1.27-1.25c-.3-.54-.46-1.16-.46-1.86s.15-1.29.44-1.83c.29-.54.71-.95,1.23-1.25s1.13-.44,1.8-.44c.63,0,1.2.15,1.7.45.5.3.89.71,1.16,1.23s.41,1.12.41,1.78c0,.21-.01.43-.04.66Zm-4.22-2.21c-.28.21-.46.51-.57.89h3.01c-.09-.37-.26-.67-.51-.88-.25-.21-.57-.32-.96-.32s-.7.1-.97.31Z" />
      <path d="m368.17,266.57c-.5-.29-.89-.71-1.17-1.25-.28-.54-.42-1.15-.42-1.84s.14-1.29.43-1.83c.28-.54.67-.96,1.16-1.26.49-.3,1.04-.46,1.64-.46.42,0,.81.08,1.16.23s.66.38.91.67h.03v-4.26h1.82v10.3h-1.75v-.84h-.01c-.5.64-1.2.97-2.11.97-.62,0-1.19-.15-1.69-.44Zm3.3-1.73c.34-.34.51-.79.51-1.34s-.17-1.01-.5-1.37-.77-.55-1.29-.55-.92.17-1.25.53c-.33.35-.5.81-.5,1.39,0,.54.16.99.49,1.34.33.35.75.53,1.26.53s.94-.17,1.28-.52Z" />
      <path d="m375.55,260.04h1.83v.99h.03c.26-.35.57-.61.92-.81.35-.19.73-.29,1.13-.29h.14v2c-.13,0-.29-.01-.49-.01-.55,0-.98.17-1.28.5-.3.33-.46.76-.46,1.29v3.16h-1.83v-6.85Z" />
      <path d="m380.95,258.77c-.22-.22-.33-.49-.33-.81s.11-.57.33-.79c.22-.22.5-.33.83-.33s.6.11.83.33c.22.22.34.48.34.79s-.11.59-.34.81c-.22.22-.5.33-.83.33s-.61-.11-.83-.33Zm-.1,1.27h1.83v6.85h-1.83v-6.85Z" />
      <path d="m384.88,269.08l.43-1.55c.37.21.73.38,1.08.48s.73.16,1.15.16c.61,0,1.08-.14,1.41-.43.34-.29.5-.65.5-1.08v-.69h-.03c-.5.52-1.17.78-1.99.78-.62,0-1.17-.14-1.67-.43-.5-.28-.89-.68-1.17-1.19-.28-.51-.42-1.09-.42-1.74s.14-1.26.41-1.79.65-.94,1.13-1.23,1.02-.44,1.62-.44c.38,0,.75.07,1.11.2.36.14.67.34.94.61h.03v-.71h1.83v6.06c0,1.15-.28,2.05-.85,2.7-.57.65-1.48.97-2.74.97-1.06,0-2-.23-2.8-.69Zm4.14-4.47c.32-.33.48-.76.48-1.28s-.16-.93-.49-1.25c-.33-.32-.74-.48-1.23-.48s-.93.16-1.25.49c-.32.33-.48.75-.48,1.26s.16.96.49,1.28c.33.32.74.48,1.25.48s.92-.17,1.24-.5Z" />
      <path d="m393.14,261.87c-.22-.22-.34-.49-.34-.81s.11-.57.34-.79c.23-.22.5-.33.82-.33s.57.11.8.33c.22.22.34.48.34.79s-.11.59-.34.81c-.22.22-.49.33-.8.33-.33,0-.6-.11-.83-.33Zm0,4.82c-.22-.22-.34-.49-.34-.8s.11-.57.34-.79c.23-.22.5-.33.82-.33s.57.11.8.33c.22.22.34.48.34.79s-.11.59-.34.8c-.22.22-.49.33-.8.33-.33,0-.6-.11-.83-.33Z" />
      <path d="m278.74,280.28c-.28-.28-.42-.61-.42-1.01s.14-.74.42-1.02.62-.41,1.01-.41.74.14,1.03.41c.28.28.43.61.43,1.02s-.14.74-.43,1.01c-.29.28-.63.41-1.03.41s-.73-.14-1.01-.41Z" />
      <path d="m302.02,280.44c0,.07-.01.13-.01.18h-5.32c.07.64.32,1.16.74,1.54.42.38.95.57,1.6.57.82,0,1.48-.3,1.97-.91l.8.67c-.66.85-1.6,1.27-2.81,1.27-.66,0-1.26-.14-1.78-.43-.52-.29-.93-.7-1.22-1.23s-.44-1.14-.44-1.82.14-1.27.43-1.81c.29-.53.69-.95,1.2-1.25s1.08-.45,1.73-.45c.61,0,1.15.14,1.62.43.48.29.85.69,1.11,1.2.27.51.4,1.09.4,1.74,0,.11,0,.21-.01.28Zm-4.56-2.11c-.4.34-.65.79-.75,1.37h4.19c-.08-.57-.3-1.02-.66-1.37s-.81-.51-1.36-.51-1.02.17-1.42.5Z" />
      <path d="m302.67,276.87h1.23l2.09,5.38h.01l2.09-5.38h1.22l-2.77,6.82h-1.09l-2.77-6.82Z" />
      <path d="m310.12,283.54c-.16-.16-.25-.35-.25-.59s.08-.43.25-.59c.16-.16.36-.24.6-.24s.43.08.59.24c.16.16.24.35.24.59s-.08.43-.24.59-.36.24-.59.24-.43-.08-.6-.24Z" />
      <path d="m317.52,277.85h-.83v-.98h.83v-1.39c0-.72.17-1.26.5-1.64.33-.37.81-.56,1.42-.56.58,0,1.11.15,1.58.45l-.25.97c-.47-.24-.86-.36-1.19-.36-.62,0-.92.42-.92,1.26v1.27h1.93v.98h-1.93v5.84h-1.13v-5.84Z" />
      <path d="m327.77,280.44c0,.07-.01.13-.01.18h-5.32c.07.64.32,1.16.74,1.54.42.38.95.57,1.6.57.82,0,1.48-.3,1.97-.91l.8.67c-.66.85-1.6,1.27-2.81,1.27-.66,0-1.26-.14-1.78-.43-.52-.29-.93-.7-1.22-1.23s-.44-1.14-.44-1.82.14-1.27.43-1.81c.29-.53.69-.95,1.2-1.25s1.08-.45,1.73-.45c.61,0,1.15.14,1.62.43.48.29.85.69,1.11,1.2.27.51.4,1.09.4,1.74,0,.11,0,.21-.01.28Zm-4.56-2.11c-.4.34-.65.79-.75,1.37h4.19c-.08-.57-.3-1.02-.66-1.37s-.81-.51-1.36-.51-1.02.17-1.42.5Z" />
      <path d="m329.45,273.38h1.13v4.68h.01c.27-.41.62-.73,1.06-.95.43-.22.92-.33,1.46-.33.76,0,1.37.23,1.81.7s.67,1.17.67,2.11v4.09h-1.13v-3.77c0-1.4-.56-2.1-1.68-2.1-.68,0-1.22.21-1.61.64-.39.43-.59,1.03-.59,1.79v3.43h-1.13v-10.3Z" />
      <path d="m337.67,273.38h1.13v10.3h-1.13v-10.3Z" />
      <path d="m347.06,280.44c0,.07-.01.13-.01.18h-5.32c.07.64.32,1.16.74,1.54.42.38.95.57,1.6.57.82,0,1.48-.3,1.97-.91l.8.67c-.66.85-1.6,1.27-2.81,1.27-.66,0-1.26-.14-1.78-.43-.52-.29-.93-.7-1.22-1.23s-.44-1.14-.44-1.82.14-1.27.43-1.81c.29-.53.69-.95,1.2-1.25s1.08-.45,1.73-.45c.61,0,1.15.14,1.62.43.48.29.85.69,1.11,1.2.27.51.4,1.09.4,1.74,0,.11,0,.21-.01.28Zm-4.56-2.11c-.4.34-.65.79-.75,1.37h4.19c-.08-.57-.3-1.02-.66-1.37s-.81-.51-1.36-.51-1.02.17-1.42.5Z" />
      <path d="m348.74,276.87h1.13v1.23h.01c.27-.42.62-.74,1.05-.97.43-.23.91-.34,1.43-.34.76,0,1.35.24,1.79.72.44.48.66,1.19.66,2.14v4.05h-1.13v-3.72c0-1.43-.56-2.14-1.68-2.14-.65,0-1.17.22-1.55.66-.38.44-.57,1.04-.57,1.81v3.4h-1.13v-6.82Z" />
      <path d="m358,283.33c-.51-.29-.91-.7-1.2-1.22s-.44-1.12-.44-1.79.14-1.26.43-1.81.69-.97,1.2-1.27c.51-.31,1.08-.46,1.7-.46.51,0,.98.11,1.41.34.42.23.77.55,1.03.96h.01v-4.7h1.13v10.3h-1.09v-1.26h-.01c-.25.42-.59.75-1.01.99s-.91.36-1.45.36c-.62,0-1.19-.14-1.7-.43Zm3.03-.9c.35-.2.63-.49.84-.85.21-.37.31-.79.31-1.25s-.1-.89-.29-1.27c-.2-.38-.47-.68-.82-.9-.35-.22-.75-.33-1.2-.33s-.82.1-1.18.32c-.36.21-.64.5-.85.88s-.31.81-.31,1.31c0,.46.1.87.29,1.23.2.36.47.65.83.86.35.21.76.32,1.22.32.43,0,.82-.1,1.18-.3Z" />
      <path d="m371.43,280.44c0,.07-.01.13-.01.18h-5.32c.07.64.32,1.16.74,1.54.42.38.95.57,1.6.57.82,0,1.48-.3,1.97-.91l.8.67c-.66.85-1.6,1.27-2.81,1.27-.66,0-1.26-.14-1.78-.43-.52-.29-.93-.7-1.22-1.23s-.44-1.14-.44-1.82.14-1.27.43-1.81c.29-.53.69-.95,1.2-1.25s1.08-.45,1.73-.45c.61,0,1.15.14,1.62.43.48.29.85.69,1.11,1.2.27.51.4,1.09.4,1.74,0,.11,0,.21-.01.28Zm-4.56-2.11c-.4.34-.65.79-.75,1.37h4.19c-.08-.57-.3-1.02-.66-1.37s-.81-.51-1.36-.51-1.02.17-1.42.5Z" />
      <path d="m373.11,276.87h1.13v1.25h.03c.25-.42.57-.75.95-.98s.8-.35,1.27-.35c.12,0,.21,0,.28.01v1.26l-.45-.03c-.63,0-1.14.22-1.51.66s-.56,1.01-.56,1.71v3.29h-1.13v-6.82Z" />
      <path d="m381.86,276.87h1.13v1.23h.01c.27-.42.62-.74,1.05-.97.43-.23.91-.34,1.43-.34.76,0,1.35.24,1.79.72.44.48.66,1.19.66,2.14v4.05h-1.13v-3.72c0-1.43-.56-2.14-1.68-2.14-.65,0-1.17.22-1.55.66-.38.44-.57,1.04-.57,1.81v3.4h-1.13v-6.82Z" />
      <path d="m391.77,283.42l-2.9-6.55h1.23l2.21,5.18h.03l2.06-5.18h1.19l-3.86,9.59h-1.18l1.22-3.04Z" />
      <path d="m397.84,283.33c-.51-.29-.91-.7-1.2-1.22s-.44-1.12-.44-1.79.14-1.26.43-1.81.69-.97,1.2-1.27c.51-.31,1.08-.46,1.7-.46.51,0,.98.11,1.41.34.42.23.77.55,1.03.96h.01v-4.7h1.13v10.3h-1.09v-1.26h-.01c-.25.42-.59.75-1.01.99s-.91.36-1.45.36c-.62,0-1.19-.14-1.7-.43Zm3.03-.9c.35-.2.63-.49.84-.85.21-.37.31-.79.31-1.25s-.1-.89-.29-1.27c-.2-.38-.47-.68-.82-.9-.35-.22-.75-.33-1.2-.33s-.82.1-1.18.32c-.36.21-.64.5-.85.88s-.31.81-.31,1.31c0,.46.1.87.29,1.23.2.36.47.65.83.86.35.21.76.32,1.22.32.43,0,.82-.1,1.18-.3Z" />
      <path d="m405.23,276.87h1.13v1.25h.03c.25-.42.57-.75.95-.98s.8-.35,1.27-.35c.12,0,.21,0,.28.01v1.26l-.45-.03c-.63,0-1.14.22-1.51.66s-.56,1.01-.56,1.71v3.29h-1.13v-6.82Z" />
      <path d="m409.2,283.54c-.16-.16-.25-.35-.25-.59s.08-.43.25-.59c.16-.16.36-.24.6-.24s.43.08.59.24c.16.16.24.35.24.59s-.08.43-.24.59-.36.24-.59.24-.43-.08-.6-.24Z" />
      <path d="m419.37,273.74h1.22l3.78,9.94h-1.27l-.9-2.42h-4.44l-.88,2.42h-1.27l3.77-9.94Zm2.45,6.45l-1.83-4.98h-.03l-1.81,4.98h3.67Z" />
      <path d="m427.9,283.43c-.42-.23-.75-.55-1.01-.97h-.03v1.23h-1.08v-10.3h1.13v4.68h.01c.26-.41.6-.73,1.02-.95.42-.22.89-.33,1.4-.33.63,0,1.2.15,1.71.46.5.31.9.73,1.18,1.27.28.54.43,1.14.43,1.81s-.14,1.27-.43,1.79c-.29.52-.69.93-1.19,1.22-.5.29-1.07.43-1.69.43-.55,0-1.03-.11-1.45-.34Zm2.5-1.01c.36-.21.63-.5.83-.86.2-.36.29-.78.29-1.23,0-.49-.1-.93-.31-1.31s-.49-.67-.85-.88c-.35-.21-.75-.32-1.18-.32s-.85.11-1.2.33-.63.52-.83.9c-.2.38-.29.81-.29,1.27s.1.88.31,1.25c.21.37.49.65.85.85.36.2.75.3,1.18.3s.85-.11,1.2-.32Z" />
      <path d="m434.61,285.8l.28-1.01c.43.24.83.42,1.2.54.37.12.79.17,1.25.17.74,0,1.3-.19,1.69-.57.39-.38.59-.87.59-1.48v-1.09h-.03c-.26.37-.59.67-.99.88s-.86.32-1.37.32c-.62,0-1.18-.14-1.68-.42-.5-.28-.9-.68-1.19-1.19-.29-.51-.43-1.1-.43-1.76s.14-1.23.41-1.74c.27-.52.66-.92,1.16-1.22.5-.29,1.06-.44,1.69-.44.49,0,.94.1,1.36.31.42.21.76.5,1.04.87h.03v-1.09h1.13v6.31c0,1.04-.28,1.85-.83,2.46-.55.6-1.4.9-2.53.9-1.03,0-1.95-.25-2.77-.74Zm3.95-3.6c.34-.21.61-.49.8-.85.19-.36.29-.77.29-1.22s-.1-.85-.3-1.2c-.2-.35-.47-.62-.82-.81-.34-.19-.73-.29-1.15-.29-.46,0-.86.1-1.21.3-.35.2-.62.48-.81.83-.19.35-.29.75-.29,1.19,0,.47.1.88.3,1.24.2.36.48.63.83.83.35.19.74.29,1.17.29s.84-.1,1.19-.31Z" />
      <path d="m442.86,273.38h1.13v10.3h-1.13v-10.3Z" />
      <path d="m452.25,280.44c0,.07-.01.13-.01.18h-5.32c.07.64.32,1.16.74,1.54.42.38.95.57,1.6.57.82,0,1.48-.3,1.97-.91l.8.67c-.66.85-1.6,1.27-2.81,1.27-.66,0-1.26-.14-1.78-.43-.52-.29-.93-.7-1.22-1.23s-.44-1.14-.44-1.82.14-1.27.43-1.81c.29-.53.69-.95,1.2-1.25s1.08-.45,1.73-.45c.61,0,1.15.14,1.62.43.48.29.85.69,1.11,1.2.27.51.4,1.09.4,1.74,0,.11,0,.21-.01.28Zm-4.56-2.11c-.4.34-.65.79-.75,1.37h4.19c-.08-.57-.3-1.02-.66-1.37s-.81-.51-1.36-.51-1.02.17-1.42.5Z" />
      <path d="m454,275.38c-.16-.16-.24-.35-.24-.57s.08-.42.24-.57c.16-.16.35-.24.59-.24s.43.08.59.24.24.35.24.57-.08.42-.24.57-.35.24-.59.24-.43-.08-.59-.24Zm.01,1.48h1.13v6.82h-1.13v-6.82Z" />
      <path d="m458.57,283.33c-.52-.29-.92-.7-1.21-1.23-.29-.53-.43-1.13-.43-1.81s.14-1.29.43-1.81c.29-.53.69-.94,1.22-1.24.52-.3,1.12-.45,1.79-.45.52,0,1.03.11,1.52.32s.9.51,1.23.9l-.77.69c-.22-.26-.5-.47-.83-.63-.33-.16-.69-.24-1.08-.24-.7,0-1.26.23-1.69.69-.43.46-.64,1.05-.64,1.78s.21,1.32.64,1.77c.43.45.99.68,1.68.68.4,0,.77-.08,1.11-.24.34-.16.62-.37.83-.63l.78.71c-.31.36-.7.65-1.16.87s-1.02.32-1.65.32-1.24-.15-1.76-.44Z" />
      <path d="m464.65,273.38h1.13v4.68h.01c.27-.41.62-.73,1.06-.95.43-.22.92-.33,1.46-.33.76,0,1.37.23,1.81.7s.67,1.17.67,2.11v4.09h-1.13v-3.77c0-1.4-.56-2.1-1.68-2.1-.68,0-1.22.21-1.61.64-.39.43-.59,1.03-.59,1.79v3.43h-1.13v-10.3Z" />
      <path d="m278.74,297.08c-.28-.28-.42-.61-.42-1.01s.14-.74.42-1.02.62-.41,1.01-.41.74.14,1.03.41c.28.28.43.61.43,1.02s-.14.74-.43,1.01c-.29.28-.63.41-1.03.41s-.73-.14-1.01-.41Z" />
      <path d="m295.37,299.6l.57-.87c.28.27.58.48.91.62.33.14.72.21,1.18.21.4,0,.72-.09.95-.26.23-.17.35-.39.35-.67,0-.19-.06-.34-.18-.46-.12-.12-.27-.22-.43-.29-.17-.07-.5-.2-1.01-.38-.67-.23-1.18-.5-1.53-.79-.35-.29-.52-.73-.52-1.29,0-.34.1-.66.29-.94.2-.28.46-.5.81-.66.34-.16.72-.24,1.14-.24.88,0,1.66.28,2.35.84l-.59.9c-.52-.49-1.12-.73-1.81-.73-.28,0-.53.08-.74.22s-.31.35-.31.6.11.44.32.59c.21.15.54.31.97.47l.34.13c.51.2.9.37,1.17.51.27.14.48.33.64.57.16.23.24.54.24.91,0,.57-.21,1.04-.63,1.41-.42.37-1.03.56-1.82.56-1.04,0-1.92-.32-2.66-.97Z" />
      <path d="m303.3,300.13c-.52-.29-.92-.7-1.21-1.23-.29-.53-.43-1.13-.43-1.81s.14-1.29.43-1.81c.29-.53.69-.94,1.22-1.24.52-.3,1.12-.45,1.79-.45.52,0,1.03.11,1.52.32s.9.51,1.23.9l-.77.69c-.22-.26-.5-.47-.83-.63-.33-.16-.69-.24-1.08-.24-.7,0-1.26.23-1.69.69-.43.46-.64,1.05-.64,1.78s.21,1.32.64,1.77c.43.45.99.68,1.68.68.4,0,.77-.08,1.11-.24.34-.16.62-.37.83-.63l.78.71c-.31.36-.7.65-1.16.87s-1.02.32-1.65.32-1.24-.15-1.76-.44Z" />
      <path d="m309.39,290.18h1.13v4.68h.01c.27-.41.62-.73,1.06-.95.43-.22.92-.33,1.46-.33.76,0,1.37.23,1.81.7s.67,1.17.67,2.11v4.09h-1.13v-3.77c0-1.4-.56-2.1-1.68-2.1-.68,0-1.22.21-1.61.64-.39.43-.59,1.03-.59,1.79v3.43h-1.13v-10.3Z" />
      <path d="m317.6,290.18h1.13v10.3h-1.13v-10.3Z" />
      <path d="m327,297.24c0,.07-.01.13-.01.18h-5.32c.07.64.32,1.16.74,1.54.42.38.95.57,1.6.57.82,0,1.48-.3,1.97-.91l.8.67c-.66.85-1.6,1.27-2.81,1.27-.66,0-1.26-.14-1.78-.43-.52-.29-.93-.7-1.22-1.23s-.44-1.14-.44-1.82.14-1.27.43-1.81c.29-.53.69-.95,1.2-1.25s1.08-.45,1.73-.45c.61,0,1.15.14,1.62.43.48.29.85.69,1.11,1.2.27.51.4,1.09.4,1.74,0,.11,0,.21-.01.28Zm-4.56-2.11c-.4.34-.65.79-.75,1.37h4.19c-.08-.57-.3-1.02-.66-1.37s-.81-.51-1.36-.51-1.02.17-1.42.5Z" />
      <path d="m329.89,300.13c-.52-.29-.92-.7-1.21-1.23-.29-.53-.43-1.13-.43-1.81s.14-1.29.43-1.81c.29-.53.69-.94,1.22-1.24.52-.3,1.12-.45,1.79-.45.52,0,1.03.11,1.52.32s.9.51,1.23.9l-.77.69c-.22-.26-.5-.47-.83-.63-.33-.16-.69-.24-1.08-.24-.7,0-1.26.23-1.69.69-.43.46-.64,1.05-.64,1.78s.21,1.32.64,1.77c.43.45.99.68,1.68.68.4,0,.77-.08,1.11-.24.34-.16.62-.37.83-.63l.78.71c-.31.36-.7.65-1.16.87s-1.02.32-1.65.32-1.24-.15-1.76-.44Z" />
      <path d="m335.97,290.18h1.13v4.68h.01c.27-.41.62-.73,1.06-.95.43-.22.92-.33,1.46-.33.76,0,1.37.23,1.81.7s.67,1.17.67,2.11v4.09h-1.13v-3.77c0-1.4-.56-2.1-1.68-2.1-.68,0-1.22.21-1.61.64-.39.43-.59,1.03-.59,1.79v3.43h-1.13v-10.3Z" />
      <path d="m344.26,298.45v-3.81h-.88v-.98h.88v-2.32l1.13-.13v2.45h2.25v.98h-2.25v3.64c0,.43.06.74.18.93.12.19.34.29.64.29.35,0,.73-.12,1.16-.35l.29.99c-.22.13-.46.23-.71.31s-.56.11-.94.11c-1.18,0-1.76-.71-1.76-2.11Z" />
      <path d="m352.97,293.67h1.13v1.25h.03c.25-.42.57-.75.95-.98s.8-.35,1.27-.35c.12,0,.21,0,.28.01v1.26l-.45-.03c-.63,0-1.14.22-1.51.66s-.56,1.01-.56,1.71v3.29h-1.13v-6.82Z" />
      <path d="m363.73,297.24c0,.07-.01.13-.01.18h-5.32c.07.64.32,1.16.74,1.54.42.38.95.57,1.6.57.82,0,1.48-.3,1.97-.91l.8.67c-.66.85-1.6,1.27-2.81,1.27-.66,0-1.26-.14-1.78-.43-.52-.29-.93-.7-1.22-1.23s-.44-1.14-.44-1.82.14-1.27.43-1.81c.29-.53.69-.95,1.2-1.25s1.08-.45,1.73-.45c.61,0,1.15.14,1.62.43.48.29.85.69,1.11,1.2.27.51.4,1.09.4,1.74,0,.11,0,.21-.01.28Zm-4.56-2.11c-.4.34-.65.79-.75,1.37h4.19c-.08-.57-.3-1.02-.66-1.37s-.81-.51-1.36-.51-1.02.17-1.42.5Z" />
      <path d="m365.65,302.6l.28-1.01c.43.24.83.42,1.2.54.37.12.79.17,1.25.17.74,0,1.3-.19,1.69-.57.39-.38.59-.87.59-1.48v-1.09h-.03c-.26.37-.59.67-.99.88s-.86.32-1.37.32c-.62,0-1.18-.14-1.68-.42-.5-.28-.9-.68-1.19-1.19-.29-.51-.43-1.1-.43-1.76s.14-1.23.41-1.74c.27-.52.66-.92,1.16-1.22.5-.29,1.06-.44,1.69-.44.49,0,.94.1,1.36.31.42.21.76.5,1.04.87h.03v-1.09h1.13v6.31c0,1.04-.28,1.85-.83,2.46-.55.6-1.4.9-2.53.9-1.03,0-1.95-.25-2.77-.74Zm3.95-3.6c.34-.21.61-.49.8-.85.19-.36.29-.77.29-1.22s-.1-.85-.3-1.2c-.2-.35-.47-.62-.82-.81-.34-.19-.73-.29-1.15-.29-.46,0-.86.1-1.21.3-.35.2-.62.48-.81.83-.19.35-.29.75-.29,1.19,0,.47.1.88.3,1.24.2.36.48.63.83.83.35.19.74.29,1.17.29s.84-.1,1.19-.31Z" />
      <path d="m379.86,297.24c0,.07-.01.13-.01.18h-5.32c.07.64.32,1.16.74,1.54.42.38.95.57,1.6.57.82,0,1.48-.3,1.97-.91l.8.67c-.66.85-1.6,1.27-2.81,1.27-.66,0-1.26-.14-1.78-.43-.52-.29-.93-.7-1.22-1.23s-.44-1.14-.44-1.82.14-1.27.43-1.81c.29-.53.69-.95,1.2-1.25s1.08-.45,1.73-.45c.61,0,1.15.14,1.62.43.48.29.85.69,1.11,1.2.27.51.4,1.09.4,1.74,0,.11,0,.21-.01.28Zm-4.56-2.11c-.4.34-.65.79-.75,1.37h4.19c-.08-.57-.3-1.02-.66-1.37s-.81-.51-1.36-.51-1.02.17-1.42.5Z" />
      <path d="m381.62,290.18h1.13v10.3h-1.13v-10.3Z" />
      <path d="m387.09,300.23c-.42-.23-.75-.55-1.01-.97h-.03v1.23h-1.08v-10.3h1.13v4.68h.01c.26-.41.6-.73,1.02-.95.42-.22.89-.33,1.4-.33.63,0,1.2.15,1.71.46.5.31.9.73,1.18,1.27.28.54.43,1.14.43,1.81s-.14,1.27-.43,1.79c-.29.52-.69.93-1.19,1.22-.5.29-1.07.43-1.69.43-.55,0-1.03-.11-1.45-.34Zm2.5-1.01c.36-.21.63-.5.83-.86.2-.36.29-.78.29-1.23,0-.49-.1-.93-.31-1.31s-.49-.67-.85-.88c-.35-.21-.75-.32-1.18-.32s-.85.11-1.2.33-.63.52-.83.9c-.2.38-.29.81-.29,1.27s.1.88.31,1.25c.21.37.49.65.85.85.36.2.75.3,1.18.3s.85-.11,1.2-.32Z" />
      <path d="m394.74,300.13c-.5-.29-.9-.7-1.19-1.22s-.43-1.12-.43-1.79.14-1.26.43-1.81c.28-.54.68-.97,1.18-1.27.5-.31,1.07-.46,1.71-.46.5,0,.97.11,1.39.34.42.23.75.55.99.96h.03v-1.22h1.13v6.82h-1.09v-1.23h-.01c-.25.42-.59.74-1,.97-.42.23-.89.34-1.44.34-.62,0-1.19-.14-1.69-.43Zm3.01-.9c.34-.2.62-.49.83-.85.21-.37.31-.79.31-1.25s-.1-.89-.29-1.27-.47-.68-.81-.9c-.35-.22-.74-.33-1.18-.33s-.82.1-1.18.32c-.35.21-.64.5-.85.88s-.32.81-.32,1.31c0,.46.1.87.29,1.23.2.36.47.65.83.86s.76.32,1.22.32c.42,0,.8-.1,1.15-.3Z" />
      <path d="m402.02,293.67h1.13v1.25h.03c.25-.42.57-.75.95-.98s.8-.35,1.27-.35c.12,0,.21,0,.28.01v1.26l-.45-.03c-.63,0-1.14.22-1.51.66s-.56,1.01-.56,1.71v3.29h-1.13v-6.82Z" />
      <text class="cls-17" transform="translate(318.15 148.77)">
        <tspan class="cls-18">
          <tspan x="0" y="0">
            Stellgröße:{" "}
          </tspan>
        </tspan>
        <tspan class="cls-16">
          <tspan x="0" y="14.4">
            100 %
          </tspan>
        </tspan>
      </text>
      <text class="cls-17" transform="translate(420.14 174.09)">
        <tspan class="cls-18">
          <tspan x="0" y="0">
            Sollwert:{" "}
          </tspan>
        </tspan>
        <tspan class="cls-16">
          <tspan x="0" y="14.4">
            21,0°C
          </tspan>
        </tspan>
      </text>
      <text class="cls-17" transform="translate(420.14 209.07)">
        <tspan class="cls-18">
          <tspan x="0" y="0">
            Istwert:{" "}
          </tspan>
        </tspan>
        <tspan class="cls-16">
          <tspan x="0" y="14.4">
            18,0°C
          </tspan>
        </tspan>
      </text>
      <path d="m385.43,182.74v42h-66v-42h66m2-2h-70v46h70v-46h0Z" />
      <path d="m381.43,186.81v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m373.43,186.81v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m365.43,186.81v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m357.43,186.81v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m349.43,186.81v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m341.43,186.81v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m333.43,186.81v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <path d="m325.43,186.81v34h-2v-34h2m2-2h-6v38h6v-38h0Z" />
      <rect x="314.44" y="184.81" width="3" height="10" />
      <rect x="314.44" y="212.81" width="3" height="10" />
      <rect x="387.44" y="212.81" width="3" height="10" />
      <rect x="387.44" y="186.81" width="4" height="6" />
      <polygon
        class="cls-4"
        points="391.62 182.26 404.87 183.79 405.99 193.28 391.48 195.09 391.62 182.26"
      />
      <polyline
        class="cls-4"
        points="391.44 186.81 399.48 186.77 399.48 182.49"
      />
      <polygon points="405.15 185.19 407.25 185.33 407.94 191.19 405.99 191.74 405.15 185.19" />
      <polygon points="393.57 194.67 393.57 196.91 405.01 195.23 405.01 193.28 393.57 194.67" />
      <line class="cls-4" x1="315.85" y1="215.6" x2="300.41" y2="215.6" />
      <line class="cls-4" x1="315.85" y1="219.6" x2="300.41" y2="219.6" />
      <text class="cls-15" transform="translate(84.64 340.23)">
        <tspan x="0" y="0">
          Durch die Aufschaltung (Stellgrößen) aller Räume wird die Vorlaufzeit
          optimal angepasst.
        </tspan>
      </text>
    </svg>
  );
}
