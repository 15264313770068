import { h } from "preact";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function StartIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M3 22v-20l18 10-18 10z" />
      </svg>
    </SvgIcon>
  );
}
