import { Button, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../components/Slide";
import QList from "../../../../../../../../components/QList";
import QHeader from "../../../../../../../../components/QHeader";
import ImageComponent from "../../../../../../../../components/ImageComponent";
import QBodyText from "../../../../../../../../components/QBodyText";
import AnwendungImage from "../../../../../../../../assets/abbknx/Anwendungen/ClimaEco/anzeigen-und-bedienen/ac-s-1.1.1.webp";
import HeizkurveFussbodenheizung from "./HeizkurveFußbodenheizung";
import { useModal } from "mui-modal-provider";
import WirkungsweiseDerRaumaufschaltung from "./WirkungsweiseDerRaumaufschaltung";
import WerteVonHeizkreis from "./WerteVonHeizkreis";
import QDialog from "../../../../../../../../components/QDialog";
import Zweipunktregler from "./Zweipunktregler";
import QSwiperJS from "../../../../../../../../components/QSwiperJS";
import ParametrierbareRaumansicht from "./ParametrierbareRaumansicht";

export default function Startpage({ swiperRef, openInDialog }) {
  const { showModal } = useModal();
  const goToSlide = (id) => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(id);
    }
  };

  const dialogHeizkurveFussbodenheizung = () => {
    const dialog = showModal(QDialog, {
      children: <HeizkurveFussbodenheizung />,
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };

  const dialogWirkungsweiseDerRaumaufschaltung = () => {
    const dialog = showModal(QDialog, {
      children: <WirkungsweiseDerRaumaufschaltung />,
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };

  const dialogWerteVonHeizkreis = () => {
    const dialog = showModal(QDialog, {
      children: <WerteVonHeizkreis />,
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };

  const dialogZweipunktregler = () => {
    const dialog = showModal(QDialog, {
      children: (
        <QSwiperJS>
          <ParametrierbareRaumansicht />
          <Zweipunktregler />
        </QSwiperJS>
      ),
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };

  return (
    <Slide
      background
      actions={
        openInDialog
          ? [
              {
                type: "icon",
                img: "laptop",
                target: "_blank",
                link: "https://new.abb.com/products/de/2CDG110205R0011/ac-s1-1-1",
              },
              {
                type: "button",
                onClick: dialogHeizkurveFussbodenheizung,
                label: "Heizkurve",
              },
              {
                type: "button",
                onClick: dialogWirkungsweiseDerRaumaufschaltung,
                label: "Wirkungsweise",
              },
              {
                type: "button",
                onClick: dialogWerteVonHeizkreis,
                label: "Diagramm",
              },
              {
                type: "button",
                onClick: dialogZweipunktregler,
                label: "Beispiele",
              },
            ]
          : [
              {
                type: "icon",
                img: "laptop",
                target: "_blank",
                link: "https://new.abb.com/products/de/2CDG110205R0011/ac-s1-1-1",
              },
              {
                type: "button",
                onClick: () => goToSlide(1),
                label: "Heizkurve",
              },
              {
                type: "button",
                onClick: () => goToSlide(2),
                label: "Wirkungsweise",
              },
              {
                type: "button",
                onClick: () => goToSlide(3),
                label: "Diagramm",
              },
              {
                type: "button",
                onClick: () => goToSlide(4),
                label: "Beispiele",
              },
            ]
      }
    >
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader title="AC/S1.1.1 Application Controller" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Automationsmodule wie zum Beispiel Wärmebedarfsberechnung,
                Zeitpläne sowie Wertaufzeichnung. Eigene Automatisierungsmodule
                können mit einem grafischen Logik-Editor erstellt werden. Das
                Gerät besitzt zur Anzeige und Bedienung eine Weboberfläche, die
                automatisch erzeugt wird.
              </QBodyText>
              <QBodyText>
                Die komplette Parametrierung erfolgt über die ETS. Folgende
                Funktionen stehen zur Verfügung
              </QBodyText>

              <QList
                items={[
                  "Es können bis zu 15 Heizkurven integriert werden.",
                  "Die Optimierung der Vorlauftemperatur durch Aufschaltung der einzelnen Räume.",
                  "Erstellen von Zeit und Wochenplänen",
                  "Bis zu 50 (200) Räume lassen sich grafisch darstellen",
                  "Erstellung von Logikfunktionen (Soft SPS)",
                  "Taupunktemperaturgeführte Vorlauftemperaturberechnung",
                  "Als Bacnet Variante mit 500 Datenpunkte verfügbar.",
                ]}
                dense
                paddingLeft
                variant="dot"
              />
            </Grid>

            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                src={AnwendungImage}
                alt="AC/S1.1.1 Application Controller"
                wrapperHeight="fit-content"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
