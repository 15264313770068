import Cookies from "js-cookie";
import crmDB from "../databases/crmDB";

export const fetchCRMData = () => {
  let headers = new Headers();
  headers.append("Authorization", `Bearer ${Cookies.get("access_token")}`);
  headers.append("Referer", window.location.href);
  let requestOptions = {
    method: "GET",
    headers,
    redirect: "follow",
  };

  fetch(`${process.env.PREACT_APP_BJECRM}structures`, requestOptions).then(
    (res) => {
      if (res.status === 200) {
        res.arrayBuffer().then((buf) => {
          if (buf === null) {
            return;
          }
          crmDB.data.update("fetchDate", { value: new Date() });
          crmDB.data.update("buffer", { value: buf });
        });
      }
    }
  );
};
