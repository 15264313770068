import { Box, Grid } from "@material-ui/core";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import ImageComponent from "../../../../../../components/ImageComponent";

// Images
import M1MImage from "../../../../../../assets/neuheiten-2024/abb/D1M/D1M.webp";
import BetterImage from "../../../../../../components/BetterImage";

function MultiMessgeraete() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Multi-Messgeräte D1M" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QList
                headline="Eigenschaften"
                items={[
                  "3 TE Baubreite",
                  "Messgenauigkeit Klasse 1 (D1M 15) bzw. Klasse 0,5 (D1M 20)",
                  "Integrierte digitale Ein- und Ausgänge",
                  "Wahlweise Modbus RTU oder Modbus TCP Schnittstelle",
                  "Integration in Energiemonitoringsysteme (z.B. InSite Energiemonitoring)",
                  "Geeignet für Messungen im Zweckbau und für Industrieanwendungen (nicht für Abrechnungszwecke, da keine MID-Zulassung!)",
                ]}
                dense
              />
            </Grid>
            <Grid item xs={6}>
              <Box
                height="63dvh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <BetterImage
                  src={M1MImage}
                  height="38dvh"
                  alt="Multi-Messgeräte D1M"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default MultiMessgeraete;
