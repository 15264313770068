import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QList from "../../../../../../../../../components/QList";
import Heizkurve from "../../../../../../../../../assets/abbknx/Anwendungen/ClimaEco/anzeigen-und-bedienen/heizkurve.webp";
import QBodyText from "../../../../../../../../../components/QBodyText";
import BetterImage from "../../../../../../../../../components/BetterImage";
import ACSHeizkurve from "./acs-heizkurve";

export default function ACSHeizkurveInfo() {
  return (
    <Slide>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Heizkurve Fußbodenheizung" />
        </Grid>
        <Grid item xs={12}>
          <QList
            headline="Gebäudeschutz"
            paddingLeft
            items={[
              "Raumtemperatur grenze um die Gebäudehülle vor Frost z.B. zu schützen",
            ]}
          />
          <QList
            headline="Economy"
            paddingLeft
            items={[
              "Temperaturkurve für die nicht Anwesenheit im Gebäude z.B am Wochenende in einem Büro",
            ]}
          />
          <QList
            headline="Standby"
            paddingLeft
            items={["Temperaturkurve für die nicht Anwesenheit im Zimmer"]}
          />
          <QList
            headline="Komfort"
            paddingLeft
            items={["Temperaturkurve, wenn eine Person im Raum ist"]}
          />
        </Grid>
      </Grid>
    </Slide>
  );
}
