import {h} from 'preact'

import {useDispatch, useSelector} from 'react-redux'
import {Grid} from '@material-ui/core'
import QActionPaper from '../../QActionPaper'
import QTextFieldAction from '../../QTextFieldAction'
import {
  getAbbSmarterMobilityData,
  setAbbSmarterMobilityNotes,
} from '../../../redux/modules/chapterAbbSmarterMobility'

export default function AbbSmarterMobilityPage({context}) {
  const dispatch = useDispatch()

  const {notes: notesData} = useSelector(getAbbSmarterMobilityData)
  const notes = notesData.value

  const setSetupField = valueSet => {
    dispatch(setAbbSmarterMobilityNotes(valueSet.value))
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <QActionPaper title="Notizen" context={context}>
          <QTextFieldAction
            value={notes}
            valueID="notes"
            setSetupField={setSetupField}
            rowsMin="8"
          />
        </QActionPaper>
      </Grid>
    </Grid>
  )
}
