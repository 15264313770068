// Images Marketing
import Image1 from "../../assets/uk600/marketing/1.webp";
import Image2 from "../../assets/uk600/marketing/2.webp";
import Image3 from "../../assets/uk600/marketing/3.webp";
import Image4 from "../../assets/uk600/marketing/4.webp";
import Image5 from "../../assets/uk600/marketing/5.webp";
import Image6 from "../../assets/uk600/marketing/6.webp";
import Image7 from "../../assets/uk600/marketing/7.webp";

export const uk600ak600MarketingItems = {
  title: "Marketing",
  items: [
    {
      title: "Präsentation",
      img: Image1,
      link: {
        href: "https://search.abb.com/library/Download.aspx?DocumentID=9AKK108468A0511&LanguageCode=de&DocumentPartId=&Action=Launch",
        target: "_blank",
      },
    },
    {
      title: "Broschüre",
      img: Image2,
      link: {
        href: "https://search.abb.com/library/Download.aspx?DocumentID=2CKA0000001A1880&LanguageCode=de&DocumentPartId=&Action=Launch",
        target: "_blank",
      },
    },
    {
      title: "Flyer",
      img: Image3,
      link: {
        href: "https://search.abb.com/library/Download.aspx?DocumentID=2CKA0000001A1881&LanguageCode=de&DocumentPartId=&Action=Launch",
        target: "_blank",
      },
    },
    {
      title: "Projektierungshinweise AK600",
      img: Image4,
      link: {
        href: "https://search.abb.com/library/Download.aspx?DocumentID=9AKK108468A1789&LanguageCode=de&DocumentPartId=&Action=Launch",
        target: "_blank",
      },
    },
    {
      title: "Landingpage",
      img: Image5,
      link: {
        href: "https://www.striebelundjohn.com/service-und-downloads/news/das-perfekte-duo",
        target: "_blank",
      },
    },
    {
      title: "Video AK600",
      img: Image6,
      link: {
        href: "https://www.youtube.com/watch?v=vaxRVJa_sz0",
        target: "_blank",
      },
    },
    {
      title: "Video UK600",
      img: Image7,
      link: {
        href: "https://www.youtube.com/watch?v=DoEuP7o3Lf8",
        target: "_blank",
      },
    },
  ],
};
